<div [ngClass]="{'get-above-footer' : aviaService?.mobile_mode}" class="aviaUniversalAdd" id="universal-add">
		<!--
			****************************************************************************************
			NOTE: Please keep this component in sync with class::UniversalAdd_Types
			****************************************************************************************
		-->
	<div [@fadeIn]="fadeIn" [@fadeOut]="fadeOut" class="pr-2 pb-2 floater" *ngIf="aviaService.universal_add_active">
		<!-- ADD TOPIC -->
		<app-add-topic-modal #TopicAddModal
			[data]="new_topic_blank"
			[mode]="E_Modal_Operating_Mode.ADD"
			[title_text]="'Add a New'"
			(_close)="aviaService.universal_add_active = false"
			(save)="submitAddTopic($event)"
			>
			<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Add Topic</div>
		</app-add-topic-modal>
		<!--(close)="aviaService.universal_add_active = false" -->

		<!-- ADD CONTENT -->
		<app-add-content-modal #ActContentModal
			*ngIf="content_add_key?.c"
			(close)="aviaService.universal_add_active = false">
			<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Add Content</div>
		</app-add-content-modal>

		<!-- ADD CONTENT LOGO -->
		<app-add-content-logo-modal #ContentLogoModal
			*ngIf="admin_key?.e"
			(cancel)="aviaService.universal_add_active = false"
			(save)="submitAddContentLogo($event)"
			>
			<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Add Content Logo</div>
		</app-add-content-logo-modal>

		<!-- ADD WORKSPACE -->
		<!-- <app-add-activity-modal #ActAddModal
			*ngIf="activity_add_key?.c"
			[data]="new_activity_blank"
			[fullscreen]="true"
			[mode]="E_Modal_Operating_Mode.ADD"
			[title_text]="'Add a New'"
			(close)="aviaService.universal_add_active = false"
			(save)="submitAddActivity($event);"
			> -->
			<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable" (click)="aviaService.goToNewGroups({open: 'groups_modal'})">Add Group</div>
		<!-- </app-add-activity-modal> -->

		<!-- ADD COMMUNITY -->
		<app-add-edit-cm #CommunityAddModal
			*ngIf="admin_key?.e"
			[fullscreen]="true"
			[mode]="E_Modal_Operating_Mode.ADD"
			(cancel)="aviaService.universal_add_active = false"
			(save)="submitAddCommunity($event)"
			>
			<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Add Community</div>
		</app-add-edit-cm>



		<!-- ADD ORG -->
		<app-avia-add-org-modal #AddOrgModal
			*ngIf="admin_key?.e"
			[fullscreen]='true'
			(cancel)="aviaService.universal_add_active = false"
			>
			<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Add Org</div>
		</app-avia-add-org-modal>


		<!-- ISSUE REPORTING & FEEDBACK -->
		<!-- <div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Report An Issue</div> -->
		<!-- <div [hidden]="false" (click)="aviaService.universal_add_active = false" class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">
			<span>HELLO</span>
		</div> -->


		<!-- USER INVITE -->
		<app-avia-user-invite-modal #UserInviteModalComponent
		[akey]="org_demo_key"
		[org]="aviaService?.session?.org"
		(click)="aviaService.universal_add_active = false"
		>
		<div class="tag px-2 py-1 mb-1 position-relative pointer float-right text-white unselectable">Invite New User</div>
	</app-avia-user-invite-modal>

	</div>
	<!-- UNIVERSAL ADD BUTTON -->
	<!-- <div class="float-right unselectable">
		<i *ngIf="!aviaService.universal_add_active"
			class="material-icons text-gray-400 pointer"
			[@rotateIn]="rotateIn"
			(click)="aviaService.universal_add_active = true"
		>add_circle</i>
		<i *ngIf="aviaService.universal_add_active"
			class="material-icons text-gray-400 pointer"
			[@rotateIn]="rotateIn"
			(click)="aviaService.universal_add_active = false"
		>cancel</i>
	</div> -->

	<!-- VIEW ARCHIVE OF CURRENT COMPONENT -->
	<!-- <div class="float-right unselectable">
		<i
			*ngIf="show_archives && !aviaService?.mobile_mode && is_avia_key?.r"
			data-toggle="tooltip"
			ngbTooltip="View Archive"
			placement="left"
			(click)="navigateToArchive()"
			class="material-icons text-gray-400 pointer">
				archive
		</i>
	</div> -->

	<!-- View Reports Button -->
	<div class="float-right unselectable">
		<i
			*ngIf="show_reports && !aviaService?.mobile_mode && is_avia_key?.r"
			data-toggle="tooltip"
			ngbTooltip="View Reports"
			placement="left"
			(click)="navigateToReports()"
			class="material-icons text-gray-400 pointer">
				insert_chart
		</i>
	</div>

	<!-- View Reports Button -->
	<div class="float-right unselectable">
		<i
			*ngIf="router.url.includes('intelligence/km') &&  !aviaService?.mobile_mode && is_avia_key?.r"
			data-toggle="tooltip"
			ngbTooltip="Topic Visualizer"
			placement="left"
			(click)="navigateToVisualizer()"
			class="material-icons text-gray-400 pointer">
				timeline
		</i>
	</div>
</div>
