import { TourService } from './_services/tour.service';
import { fadeIn, fadeOut } from 'ng-animate';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Subscription } from 'rxjs';
// core angular imports
import { Component, HostListener, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, NavigationCancel, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// node_module imports
import { DeviceDetectorModule } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';

// AVIA imports
import { environment } from '../environments/environment';
import { Common } from './common';
import { AVIAConnectService } from './avia-connect.service';

import { E_SomethingHappened, User, Color_Library } from '../class';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', useAnimation(fadeIn, { params: { timing: .45 } }))]), //:enter is an alias for void => *
    trigger('fadeOut', [transition(':leave', useAnimation(fadeOut, { params: { timing: .35 } }))]), //:leave is an alias for * => void
  ]
})

export class AppComponent implements OnInit, OnDestroy {
  @ViewChild ('AviaContentViewer', { static: true }) aviaContentViewer;
  @ViewChild ('OnboardingModal', { static: true }) onboarding_modal;

  fadeIn: Animation;
  fadeOut: Animation;
  is_test_user: boolean = false;

  private device_os: string = '';
  component_loading:            boolean = false;
  component_loading_false_once: boolean = true;
  isCollapsed:                  boolean = true;
  location:                     string;  // Current location in AVIA Connect derived from Location class set/get
  login_page:                   boolean = false;
  org:                          any;
  term:                         string  = "";
  title:                        string  = 'AVIA Connect';
  user:                         User;

  notify = {
    options: {
      position: ["bottom", "right"],
      timeOut: 5000,
      lastOnBottom: true,
      clickToClose: true,
      maxStack: 5,
      animate: "fromRight",
      icons: {
        success: '',
        error: ''
      }
    },
    created: ($event) => {
      //console.log($event);
    },
    destroyed: ($event) => {
      //console.log($event);
    }
  }

  // login_alert_subscription: Subscription;

  constructor(
    public  aviaService: AVIAConnectService,
    private router: Router,
    private titleService: Title,
    public tourService: TourService,
  ) {
    router.events
      .pipe( filter(event => event instanceof NavigationStart) )
      .subscribe((event: NavigationStart) => {
        //if (event.url !== '/signin') {
          // no setTimeout on true, immediately show before module starts to load
          // (when loading a module, browser delays the setTimeout till after module is loaded... yuk)
          this.component_loading = true;
        //}
      });

    router.events
      .pipe( filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel) )
      .subscribe((event: any) => {
        // no event.url checks needed here, always smash to false on nav end
        // (catches the case when we type in a URL (i.e. first load) that isn't in our routing table, and anglar redirects)
        // there is no NavigationEnd after a angular router redirect (apparently)

        if (this.component_loading_false_once) {
          this.component_loading_false_once = false;
          // setTimeout to prevent crashy - Expression has changed after it was invoked
          // seems to happen when first opening the app only, but never again, so we do a "once" variable here. :)
          setTimeout( () => this.component_loading = false, 0 );
        } else {
          this.component_loading = false;
        }
        this.aviaService.current_location.setLocation(router.url);
        this.location = this.aviaService.current_location.getLocation();
      });

      // this.login_alert_subscription = this.aviaService.somethingHappened.subscribe(event => {
      //   if(event.type === E_SomethingHappened.ONBOARDING_COMPLETED || event.type === E_SomethingHappened.USER_SIGNED_IN) {
      //     this.loginAlert();
      //   }
      // });
  }

  private isMobile():boolean {
    return window.innerWidth < Common.WINDOW_WIDTH_MOBILE_BREAKPOINT ? true : false;
  };

  ngOnInit() {
    this.init();
  }


  // async initSocialIntent() {
  //   let session = await this.aviaService.getSessionSupport();
  //   await window["onSIApiReady"];
  // }


  ngOnDestroy() {
    // this.login_alert_subscription.unsubscribe();
  }

  async init() {
    await this.aviaService.init();
    this.setTitle(environment.production ? "AVIA Connect" : "dev mode");
    this.device_os = this.aviaService.deviceInfo.os; //NOTE: We only want to read this once, during init so that I can work around Chrome spoofing the OS when in dev mode
    this.aviaService.mobile_mode = this.isMobile();
    this.aviaService.window_height = window.innerHeight;
    this.aviaService.window_width = window.innerWidth;
    this.aviaService.aviaContentViewer = this.aviaContentViewer;
    let session = await this.aviaService.getSessionSupport();
    if(session && session.org) {
      this.org = session.org;
      this.tourService.init();
    }
    // this.loginAlert();
  }

  // New feature alert
  // Commented out for now, we can reuse this for login campaigns
  // loginAlert() {
  //   let html = `
  //   <div class='heading-size-16 mb-2'>Newsfeed Updates!</div>
  //   <div class='text-left px-4 font-size-12'>We've updated AVIA Connect so you can share and collaborate with the network in new and better ways!</div>
  //   <ul class='mt-2 font-size-12'>
  //     <li class='text-left font-weight-light'><span class='font-weight-bold'>Posting and Q&A - </span>post updates or ask questions to the network and have it show up right in the newsfeed.</li>
  //     <li class='text-left font-weight-light'><span class='font-weight-bold'>Personalization - </span>in addition to articles, you’ll now see posts and questions related to your interests in your newsfeed.</li>
  //     <li class='text-left font-weight-light'><span class='font-weight-bold'>@ tagging - </span>to help your posts get seen by the right people, you can now @ mention topics, companies, products, or people.</li>
  //   </ul>
  //   `

  //   if ( this.aviaService.session && this.aviaService.session.user && this.aviaService.session.user.login_alert === 0 && this.aviaService.session.user.onboarding_start === 1 && !window.location.pathname.includes("/pulse")) {
  //     this.aviaService.notify(
  //       '',
  //       '',
  //       '.',
  //       {
  //         html: html,
  //         showConfirmButton: true,
  //         confirmButtonText: 'Check it out',
  //         confirmButtonColor: Color_Library.primary,
  //         showCancelButton: true,
  //         cancelButtonText: 'Close',
  //         cancelButtonColor: Color_Library.gray_400,
  //         showCloseButton: true
  //       }
  //     ).then(res => {
  //       this.aviaService.updateUser(this.aviaService.session.user.id, {login_alert: 1});
  //       if (res.value) {
  //         this.router.navigate(['start']);
  //       }
  //     });
  //   }

  // }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // UI & Device Info
  @HostListener('window:resize', ['$event']) // NOTE: This MUST be immediately followed by its event handler
  onResize(event) {
    this.aviaService.mobile_mode = this.isMobile();
    this.aviaService.window_height = event.target.innerHeight;
    this.aviaService.window_width = event.target.innerWidth;
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Mobile Nav

  onReloadMobileHome() {
    if(this.router.url === '/start') {
      this.aviaService.somethingHappened.emit({
        type: E_SomethingHappened.NEWSFEED_REFRESHED,
        data: undefined
      })
    } else {
      this.router.navigate(['start']);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Router Outlet
  onActivate($event) {  }

  onDeactivate($event) {
  }

  preventDefaultAndStopPropagation($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  delayKillTheServerDownBar( timeout ) {
    setTimeout( () => this.aviaService.browserProblem=false, timeout );
  }
}
