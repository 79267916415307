<div class="notification-item">
	<!-- ICON -->
		<div class="avatar my-0 mr-2"><img class="img-responsive avatar-img rounded" src="{{ notification?.type_obj?.icon || notification?.options?.icon }}" /></div>
	<!-- DATA -->
		<button class="message btn text-left p-0" [ngClass]="{'font-weight-normal': notification.dismissed}" (click)="goTo.emit({'notification':notification});">
			<div [ngSwitch]="notification?.type_obj?.name" class="title" [innerHtml]="notification?.type_obj?.text || notification?.options?.text" [ngClass]="{'font-weight-normal': notification?.dismissed == 1}">
			</div>
			<div class="date">{{ notification?.created | date: "MMM dd, y" }}</div>
		</button>
		<!-- DISMISS -->
		<button *ngIf="show_dismiss" class="dismiss btn pointer" (click)="dismiss.emit({'notification':notification}); $event.preventDefault(); $event.stopPropagation();">
			<i class="material-icons">cancel</i>
		</button>
	</div>
