import { AviaQuickModalComponent } from './../../avia-quick-modal/avia-quick-modal.component';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AccessKey } from '../../../../class';
import { SearchService } from '../../../_services/search.service';


@Component({
  selector: 'app-avia-orgs-search',
  templateUrl: './avia-orgs-search.component.html',
  styleUrls: [
    './avia-orgs-search.component.scss',
    '../avia-search-bar.scss'
  ],
  providers: [
    SearchService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaOrgsSearchComponent),
      multi: true
    }
  ]
})
export class AviaOrgsSearchComponent implements ControlValueAccessor, OnInit {
  public crunchbase_count: number = 0;
  public crunchbase_loading: boolean = false;
  public is_open: boolean = false;
  @Input() crunchbase_search = false;
  @Input() editing: boolean = false;
  @Input() error_class: string = '';
  @Input() recommendations: object[];
  @Input() search_type: string = 'org' // Pass in parameters for more specific search EX: solco, uSolco, uOrg
  @Input() org_types: number[];
  @Input() // options
    set options(inputData: Orgs_Search_Options) {
      this._options = new Orgs_Search_Options();
      for (let i in inputData) {
        this._options[i] = inputData[i];
      }
    };
    get options(): Orgs_Search_Options {
      return this._options;
    };
    _options: Orgs_Search_Options = new Orgs_Search_Options();

  @Output('done') done = new EventEmitter();

  @ViewChild('searchBox', { static: false }) searchBox;

  // MODAL STUFF
  @ViewChild(AviaQuickModalComponent, { static: false }) crunchbase_modal: AviaQuickModalComponent;
  @ViewChild('OrgsSearch', { static: true }) content: NgbModal;
  modal_ref: NgbModalRef;

  constructor(public sso: SearchService, private modalService: NgbModal) { }

  // Control Value Accessor - These functions must be declared even if empty

  writeValue(value: any) {
    //this.selected_list = value;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}


  // Init

  ngOnInit() {
    this.sso.initSearch(this.search_type, { include_avia: 1 } );
  }

  // Search Engine Stuff

  async updateSearch($event) {
    if(this.org_types) {
      $event = { ...$event, type: this.org_types };
    }
    this.sso.updateSearch($event);
    if(this.crunchbase_search){
      // Limit to 0 because we just want the count of results, not the actual results
      let searchObj = { 'filter': this.sso.term, 'start':  0, 'count': 0 };
      this.crunchbase_loading = true;
      let crunchbase_result_res = await this.sso.aviaService.getCrunchbase( searchObj );
      this.crunchbase_count = crunchbase_result_res.body.count_total;
      this.crunchbase_loading = false;
    }

  }

  resetSearch() {
    this.sso.resetSearch();
    this.sso.searching = false;
    this.crunchbase_count = 0;
  }

  // Data Manipulation & View stuff

  add(d) {
    let obj = {
      action: 'add',
      type: 'org',
      values: [d.id]
    };

    // Crunchbase call differs from org return call. Add data accordingly
    if(d.result){
      obj['data'] = d.result
    } else {
      obj['data'] = d
    }
    this.done.emit(obj);
  }

  addFromCrunchbase($event) {
    this.add($event);
    this.closeCrunchbaseModal();
  }

  addAndClose(d) {
    this.add(d)
    this.resetSearch();
    this.closeModal();
  }

  toggleView($event) {
    if (this.sso.aviaService.mobile_mode && this.sso.searching) this.closeModal();
    this.sso.toggleView($event);
  }


  // === MODAL STUFF ===

  closeModal(): void {
    if (this.sso.aviaService.mobile_mode) {
      if(!this.crunchbase_search){
        this.resetSearch();
      }
      this.modal_ref.close();
    }
  };

  openModal() {
    if (this.sso.aviaService.mobile_mode) {
      let options: NgbModalOptions = {
        size: 'lg',
        windowClass: 'avia-modal-backdrop'
      };
      this.modal_ref = this.modalService.open(this.content, options);
      // TODO: Implement 'auto-focus' for the input when in Modal mode
      //setTimeout( () => {
      //  this.search_input.focusSearchBox();
      //}, 50);
    }
  };

  openCrunchbaseModal(){
    this.crunchbase_modal._open();
  }

  closeCrunchbaseModal() {
    this.crunchbase_modal._close();
    if(this.sso.aviaService.mobile_mode){
      this.modal_ref.close();
    }
    this.resetSearch();
  }

  delete(x:any) {console.log( "angular8: this function was missing when building prod: 'Property does not exist'. Called by the template.... so we added it", x ); }
}



export class Orgs_Search_Options {
  constructor(
    public show_recommendations: boolean = false,
    public text:                 string  = 'Search',
    public include_avia:         number  = 0
  ) { }
}
