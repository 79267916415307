<ng-template #OnboardingModal class="onboardingModal d-block w-100" let-c="close" let-d="dismiss">
	<div class="avia-modal-full-screen avia-modal-full-screen-inverse temp-modal-fix">

		<div class="page container-fluid bg-white">
			<app-avia-header-row></app-avia-header-row>

			<div class="editblock justify-content-sm-center mx-0 row">
				<div class="col-12 col-sm-10 pl-2 pr-4 px-sm-0 px-1 px-sm-0">

					<ng-container *ngIf="!sob.sc_onboarding_only">
						<app-onboarding-welcome *ngIf="sob.active_component === 0" class="h-100"></app-onboarding-welcome>
						<app-onboarding-name-form *ngIf="sob.active_component === 1" class="h-100"></app-onboarding-name-form>
						<app-onboarding-title-form *ngIf="sob.active_component === 2" class="h-100" ></app-onboarding-title-form>
						<app-onboarding-profile-picture-form *ngIf="sob.active_component === 3" class="h-100"></app-onboarding-profile-picture-form>
						<app-onboarding-user-interests *ngIf="sob.active_component === 4" class="h-100"></app-onboarding-user-interests>
						<app-onboarding-connections *ngIf="sob.active_component === 5 && sob.type === E_OnboardingModalTypes.GENERAL" class="h-100"></app-onboarding-connections>
						<app-onboarding-complete *ngIf="(sob.active_component === 6 && sob.type === E_OnboardingModalTypes.GENERAL) || (sob.active_component === 5 && sob.type !== E_OnboardingModalTypes.GENERAL) " class="h-100"></app-onboarding-complete>
					</ng-container>

					<ng-container *ngIf="sob.type === E_OnboardingModalTypes.SOLCO && !sob.solco_ob_started">
						<app-onboarding-solco-keys *ngIf="sob.active_component === 6" class="h-100"></app-onboarding-solco-keys>
						<app-onboarding-solco-profile *ngIf="sob.active_component === 7" class="h-100"></app-onboarding-solco-profile>
						<app-onboarding-solco-products *ngIf="sob.active_component === 8" class="h-100"></app-onboarding-solco-products>
						<app-onboarding-solco-congratulations *ngIf="sob.active_component === 9" class="h-100"></app-onboarding-solco-congratulations>
					</ng-container>

					<ng-container *ngIf="sob.sc_onboarding_only">
						<app-onboarding-solco-keys *ngIf="sob.active_component === 0" class="h-100"></app-onboarding-solco-keys>
						<app-onboarding-solco-profile *ngIf="sob.active_component === 1" class="h-100"></app-onboarding-solco-profile>
						<app-onboarding-solco-products *ngIf="sob.active_component === 2" class="h-100"></app-onboarding-solco-products>
						<app-onboarding-solco-congratulations *ngIf="sob.active_component === 3" class="h-100"></app-onboarding-solco-congratulations>
					</ng-container>


				</div>
			</div>

			<div class="footer d-flex align-items-center">
				<!-- Button for getting started (not ngIf-ed because the buttons were re-rendering and the text "keyboard_arrow_left" would flash in making the button sizes bounce) -->
				<div *ngIf="sob?.active_component === 0" class="m-auto">
					<button aria-label="Onboarding start" class="btn py-1 px-2 btn-blue-dark font-size-14" type="button" (click)="sob.onNavigate({next: true, back: undefined, previous_slide: undefined })">Get Started</button>
				</div>

				<!-- Buttons for middle -->
				<div *ngIf="sob?.active_component !== 0 && (sob?.active_component < sob?.total_components - 1)" class="mr-auto pl-4 pl-md-0 m-md-auto">
					<button
						aria-label="Onboarding back"
						class="btn pl-1 pr-2 py-1 btn-white border-1 border-gray-400 d-flex align-items-center justify-content-center text-gray-600"
						type="button"
						(click)="sob.onNavigate({back: true, next: undefined, previous_slide: undefined})">
						<i *ngIf="!loading" class="material-icons text-gray-600 md-24">arrow_left</i>
						<span class="text-gray-600 font-size-14">&nbsp;Back</span>
					</button>
				</div>
				<div *ngIf="sob.active_component !== 0 && (sob.active_component < sob.total_components - 1)" class="ml-auto pr-4 pr-md-0 m-md-auto">
					<button
						[disabled]="!sob.form_valid"
						aria-label="Onboarding next"
						class="btn pl-2 pr-1 py-1 d-flex align-items-center justify-content-center font-size-14"
						type="submit"
						[ngClass]="{'btn-gray-400': !sob.form_valid, 'btn-blue-dark': sob.form_valid}"
						(click)="sob.onNavigate({next: true, back: undefined, previous_slide: undefined})">Next&nbsp;<i class="material-icons md-24">arrow_right</i>
					</button>
				</div>

				<!-- Button for ending onboarding -->
				<div *ngIf="sob.active_component === sob.total_components -1" class="m-auto">
					<button aria-label="Onboarding Complete" class="btn p-2 btn-blue-dark font-size-14" type="button" (click)="complete()">Go to AVIA connect</button>
				</div>

			</div>

		</div>
	</div>
</ng-template>
