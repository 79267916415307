import { Router } from '@angular/router';
import { E_SomethingHappened } from '../../../class';
import { Component, Input, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { OnboardingService } from './onboarding.service';
import { E_OnboardingModalTypes } from '../../../class';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  providers: [OnboardingService]
})
export class OnboardingComponent {
  readonly VERBOSE: boolean = false;
  readonly E_OnboardingModalTypes = E_OnboardingModalTypes; // NOTE: This line is only here so that this static class can be used in the HTML template
  loading: any; // angular8: added to fix error when building prod: "Property does not exist"

  // Modal Stuff
  modal_ref:     NgbModalRef;
  modal_options: NgbModalOptions;

  active_component: number = 0;

  onComplete: Function;

  @ViewChild("OnboardingModal", { static: true }) modalRefTemplate: TemplateRef<any>;

  constructor(public sob: OnboardingService, private modalService: NgbModal, private router: Router) {
    this.sob.aviaService.onboarding = this;

    this.sob.init();
  }

  // === MODAL STUFF ===

  open( onComplete:Function ): void {
    let modal_options: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      windowClass: 'avia-modal-fullscreen',
    };

    if(this.modal_ref) this.modal_ref.close();
    this.modal_ref = this.modalService.open( this.modalRefTemplate, modal_options );
    this.onComplete = onComplete;
  }

  openScOnboardingOnly( onComplete:Function ): void {
    this.sob.sc_onboarding_only = true;
    this.sob.handleDifferentOnboarding(this.sob.aviaService.session.user.org.type)
    this.open( () => {})
  }

  async complete() {
    await this.sob.updateUser( {onboarding_start: 1} );

    this.sob.aviaService.createAnalyticNodeEvent({
      actions:[{
        action_type:"ONBOARDING_COMPLETE",
        eventkeytype:"ONBOARDING_COMPLETE",
        eventkeydivisor:1000,
        routeParams: this.sob.aviaService.currentRouteParams,
        //target: user_id, get it on back end
        attributes: {
          page_type: "onboarding",
          // status
        },
      }]
    })

    if(this.sob.show_company_ob) {
      await this.sob.updateOrg( {onboard: 1} );
    }

    await this.sob.aviaService.getSessionSupport(true);

    if ( this.onComplete !== undefined ) this.onComplete();

    this.deepLinkingForNewUsers();

    if(!this.sob.aviaService.go_to_pulse_instead_of_home) this.sob.aviaService.goToNewApp();
    this.sob.aviaService.go_to_pulse_instead_of_home = false;

    this.modal_ref.close();

    this.sob.aviaService.somethingHappened.emit({
      type: E_SomethingHappened.NEWSFEED_REFRESHED,
      data: { quick_links: true, action_tiles: true, suggested: true }
    });

    this.sob.aviaService.somethingHappened.emit({
      type: E_SomethingHappened.ONBOARDING_COMPLETED,
      data: {}
    })
  }

  // only for new users
  async deepLinkingForNewUsers() {
    if(!this.sob.aviaService.redirectUrl) return
    let link = this.sob.aviaService.redirectUrl;
    if(this.sob.aviaService.redirectUrlParams) {
      link += '?' + (new URLSearchParams( this.sob.aviaService.redirectUrlParams )).toString();
    }
    location.href = link;
  }
}
