import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AVIAConnectService } from './../../avia-connect.service';

import { AccessKey, Channel, Session } from './../../../class';

// AVIA Email Settings
// @example <app-avia-email-settings></app-avia-email-settings>
@Component({
  selector: 'app-avia-email-settings',
  templateUrl: './avia-email-settings.component.html',
  styleUrls: ['./avia-email-settings.component.scss']
})
export class AviaEmailSettingsComponent {
  @Input()
    get session(): Session     { return this._session; }
    set session(data: Session) { this._session = data; this.getWorkspaces(); }
  _session: Session;

  user_workspaces: Channel[] = [];
  keychain_email: any = {};

  @Output('updateEmail') updateEmail = new EventEmitter();

  constructor(public aviaService:AVIAConnectService) {

  }

  ngOnInit() {
  }

  async getWorkspaces() {
    if(!this._session) return;
    this.user_workspaces = (await this.aviaService.getChannels({user: this._session.user.id})).body;
  }

  update($event) {
    this.updateEmail.emit($event);
  }

  async updateWsChat($event, ws_id) {
    this.user_workspaces = (await this.aviaService.updateChannelUser(ws_id, this._session.user.id, $event)).body.result;
  }
}
