import { AVIAConnectService } from 'src/app/avia-connect.service';
import { User, Color_Library, KnowledgeCard, Session, E_SomethingHappened } from '../../../../class';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { pullAllBy } from 'lodash';

@Component({
  selector: 'app-suggested-page',
  templateUrl: './suggested-page.component.html',
  styleUrls: ['./suggested-page.component.scss']
})
export class SuggestedPageComponent implements OnInit {
  suggested_topics: KnowledgeCard[] = [];
  suggested_connections: User[] = [];
  blue_pale: string = Color_Library.blue_pale;
  blue_light: string = '#B3D8D5';
  session: Session;
  user: User;
  route_fragment: string;

  @ViewChild("suggestedConnections", {static: false}) suggestedConnections: ElementRef;

  constructor(
    public router: Router,
    private location: Location,
    public aviaService: AVIAConnectService,
    private route: ActivatedRoute) {
      this.route.fragment.subscribe(fragment => {
        this.route_fragment = fragment;
      })
    }

  async ngOnInit() {
    // ERIC TODO
    this.suggested_topics = await this.aviaService.getKMSuggested(); // res.status is checked in AVIA Connect Service
    this.session = await this.aviaService.getSessionSupport(true);
    this.user = this.session.user;

    let topics;
    if(this.user.interests_topics && this.user.interests_topics.length > 0){
      topics =  this.user.interests_topics
    } else {
      let topics_res = await this.aviaService.getKMSuggested()
      topics = topics_res.topic_ids
    }
    let topics_users_res = await this.aviaService.getTopicsUsers({topic_ids: topics, limit: 20});  // This will result in 403 for partners and solcos. THX I KNOW.
    if (topics_users_res.status == 200 && topics_users_res.body && topics_users_res.body.users) {
      this.suggested_connections = topics_users_res.body.users;
      this.suggested_connections = pullAllBy(this.suggested_connections, [{...this.user, 'id': this.user.id}], 'id');
    }
  }

  ngAfterViewInit() {
    try {
      if(this.route_fragment) {
        // I tried to scroll after the promises were resolved but that still didn't scroll it into view
        // Tried different lifecycle hooks, but it's a combination of async data, DOM getting painted, and height variation while loading.
        // Best attempt at what we wanted to do.
        setTimeout(() => {
          document.querySelector('#' + this.route_fragment).scrollIntoView({behavior: "smooth"});
        }, 500)
      }
    } catch(e) {

    }
  }

  goBack() {
    if(this.location) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.aviaService.somethingHappened.emit({
      type: E_SomethingHappened.NEWSFEED_REFRESHED,
      data: { quick_links: true, suggested: true }
    });
  }
}
