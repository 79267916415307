<div class='top-notification d-flex row no-gutters w-100'>
	<!-- Server Down -->
	<app-server-down
		class="get-on-top-5 w-100"
		*ngIf="!aviaService.backendTest || aviaService.serverDownQueue.length > 0"
		[color]='"warning"'
		[type]='0'
		[use_timer]='true'
	></app-server-down>
	<!-- Browser Incompatibility -->
	<app-server-down
		class="get-on-top-5 w-100"
		*ngIf="aviaService.browserProblem"
		[color]='"warning"'
		[msg]='"Some features may not work on your browser. For the best experience, we recommend "+aviaService.suggestedBrowser'
		[show_dismiss]='true'
		[type]='1'
		(click)='delayKillTheServerDownBar(1)'
	></app-server-down>
	<!-- Backend Test -->
	<app-server-down
		class="get-on-top-5 w-100"
		*ngIf="!aviaService.backendTest"
		[color]='"error"'
		[msg]='"Cannot reach the data server " + aviaService.baseUrl + ". Please check your firewall settings. "'
		[type]='1'
		[use_timer]='true'
		(timeout)='aviaService.testForBackend()'
	></app-server-down>
	<!-- New Version (reload) -->
	<!--
	<app-server-down
		class="get-on-top-5 w-100"
		*ngIf="aviaService.shouldReloadNewVersion"
		[color]='"info"'
		[msg]='"New version of AVIA Connect is available. <b>Reload now</b> to get the latest."'
		[type]='1'
		[use_pointer]='true'
		(click)='aviaService.reload()'
	></app-server-down>
	-->
</div>
<app-crash-screen *ngIf="aviaService.crashed"></app-crash-screen>

<app-incompatible-browser-nag *ngIf="aviaService.browserProblemSevere && !aviaService.browser_nag_dismissed"></app-incompatible-browser-nag>

<div class="body-container" (dragover)="preventDefaultAndStopPropagation($event)" (dragleave)="preventDefaultAndStopPropagation($event)" (drop)="preventDefaultAndStopPropagation($event)">
	<app-global-navbar class="w-100" *ngIf="aviaService.loggedIn && aviaService.service_inited && !aviaService.crashed"></app-global-navbar>
	<!-- Content -->
	<div *ngIf="!aviaService.crashed" class="body-content mx-auto h-100"
		[ngStyle]="{'margin': aviaService.loggedIn ? '' : '0px', 'padding': aviaService.loggedIn ? '' : '0px'}"
		[ngClass]="{'login-page-body-overrides': !aviaService.loggedIn}"
		>
		<div *ngIf="aviaService.universal_add_active" [@fadeIn]="fadeIn" [@fadeOut]="fadeOut" class="fade-bg position-absolute w-100 h-100" (click)="aviaService.universal_add_active = false"></div>
		<app-onboarding *ngIf="aviaService?.loggedIn" #onboarding></app-onboarding>
		<app-eulabox #eula_box></app-eulabox>
		<div class="router-outlet h-100">
			<div *ngIf="component_loading" class="component-loading bg-white get-on-top-5 d-flex h-100 position-fixed w-100">
				<app-loader class="align-items-center d-flex h-100 w-100"></app-loader>
			</div>
			<router-outlet (activate)='onActivate($event)' (deactivate)='onDeactivate($event)'>
				<app-universal-add *ngIf="aviaService.loggedIn && aviaService.service_inited"></app-universal-add>
			</router-outlet>
		</div>
	</div>
</div>

<!-- outlet for modals -->
<div (dragleave)="preventDefaultAndStopPropagation($event)" (dragover)="preventDefaultAndStopPropagation($event)" (drop)="preventDefaultAndStopPropagation($event)">
	<ng-template ngbModalContainer></ng-template>
</div>

<!-- content viewer -->
<avia-content-viewer #AviaContentViewer></avia-content-viewer>

<input hidden id="current_url" readonly type="text" value="">
