<ng-template #QuickModal let-modal>
	<div class="modal-header p-2 bg-gray-200 d-flex align-items-center">

		<div *ngIf="logo" class="d-flex pr-2" [ngStyle]="{'background': color}">
			<img class="img rounded align-self-center mx-auto"
				[src]="logo"
				onerror="this.src='/assets/placeholder-company.jpg'"
			/>
		</div>

		<div class="title w-100 font-weight-bold d-flex text-break"> {{ title }}  </div>
		<button type="button" class="close p-0 m-0" aria-label="Close" (click)="modal.dismiss()">
			<i class="material-icons text-gray-700"> close </i>
		</button>
	</div>

	<!-- Remove padding from quick view if not needed -->
	<div
		class="modal-body pb-0 pb-lg-4" 
		[ngClass]="{
			'p-0': no_padding,
			'pb-2': !no_padding,
			'auto': auto
		}"
	>
		<ng-content select="[view]">
			<!-- - - - MODAL VIEW - - - -->
		</ng-content>
	</div>
</ng-template>

<div class="quick-modal-row" (click)="_open();$event.preventDefault(); $event.stopPropagation();">
	<ng-content select="[icon]">
		<!-- - - - ICON - - - -->
	</ng-content>
</div>
