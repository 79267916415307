import { startWith, distinct, flatMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, interval, Subscription } from 'rxjs';
import { AVIAConnectService } from './../../avia-connect.service';
import { Injectable } from '@angular/core';

import {
  AccessKeychain_Dashboard,
  AccessKeychain_Shell,
  Color_Library,
  Community,
  E_SomethingHappened,
  Org,
} from './../../../class';

@Injectable({
  providedIn: 'root'
})
export class GlobalNavbarService {
  tabs: {name: string, active: boolean}[] = [
    {
      name: 'Home',
      active: true
    },
    {
      name: 'Discover',
      active: false
    },
    {
      name: 'Organization',
      active: false
    },
    {
      name: 'View Profile',
      active: false
    },
    {
      name: 'Notifications',
      active: false
    }
  ]

  community:        Community;
  pinned_locations: Object[];

  active_tab: { name:string, active:boolean} = this.tabs[0];
  notifications_count:number = 0;
  show_mobile_drawer: boolean = false;
  show_mobile_notifications: boolean = false;
  subscription: any;
  drawer_change_subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  blue_dark:   string = Color_Library.blue_dark;
  violet_dark: string = Color_Library.violet_dark;
  teal_dark:   string = Color_Library.teal_dark;
  blue_mid:    string = Color_Library.blue_pale;
  green_dark:  string = Color_Library.green_dark;
  orange_gold: string = Color_Library.orange_gold;
  pulse_blue:  string = Color_Library.blue_pulse;
  red_dark:    string = Color_Library.red_dark;
  red_light:   string = Color_Library.red_light;

  user_org_id: number;
  user_id: number;
  node_user_org_id: number;
  node_user_id: number;
  org: Org;
  show_checkin:   boolean = false;
  show_reports:   boolean = false;
  show_dashboard: boolean = false;
  show_my_org:    boolean = true;
  show_groups:    boolean = true;

  keychain: AccessKeychain_Shell = new AccessKeychain_Shell();
  keychain_org: AccessKeychain_Dashboard = new AccessKeychain_Dashboard();

  location_subscription: Subscription;

  constructor(public aviaService: AVIAConnectService) {
    this.drawer_change_subject.subscribe((value) => {
      this.show_mobile_drawer = value;
    })
    // this.location_subscription = this.aviaService.somethingHappened.subscribe(event => {
    //   if(event.type === E_SomethingHappened.PINNED_LOCATION_ADDED) {
    //     this.getPinnedLocations();
    //   }
    // })
  }

  async init() {
    if(this.aviaService.service_inited) {
      this.keychain = this.aviaService.keychain_shell;
      this.user_org_id = this.aviaService.session.user.org;
      this.user_id = this.aviaService.session.user.id;


      if (this.aviaService.session.user.org_obj.type == 1) this.determineMyOrgDropdown();
      if (this.aviaService.session.user.org_obj.type == 5) {
        this.aviaService.getCommunities({org: this.aviaService.session.org.id}).then(res => {
          if (res.status === 200 && res.body && res.body[0]) {
            this.community = res.body[0];
          }
          this.determineMyOrgDropdown();
        })
      }

      if (this.aviaService.session.org.type == 2 || this.aviaService.session.org.type == 5) {
        this.aviaService.getUserActivities(this.user_id).then(res => {
          if (res.status === 200 && res.body && res.body.activities) {
            let workspaces = res.body.activities;
            this.show_groups = workspaces.length > 0 ?  true : false;
          }
        })
      }
      // Check for notification updates
      this.subscription = this.getNotificationsCountOnInterval().subscribe(data => {
        try {
          // this.notifications = this.sortNotifications(data.notifications);
          // this.notifications_count = this.notifications.length;
        } catch (err) {
          this.aviaService.newrelicError( err );
        }
      });
    }
  }

  async getPinnedLocations() {
    let pinned_locations_res = await this.aviaService.getUserPinnedLocations();
    if (pinned_locations_res.status == 200) {
      this.pinned_locations = pinned_locations_res.body;
    }
  }

  getNotificationsCountOnInterval(): Observable<any> {
    return interval(60000)
      .pipe( startWith(0) )
      .pipe( distinct() )
      .pipe( flatMap( async ():Promise<any> => {
        // Periodically check for new notifications
        this.getNotificationsCount();
      }));
  }

  async getNotificationsCount() {
    let notifications_count_res = await this.aviaService.getNotificationsCount();
    if(notifications_count_res.status = 200 && notifications_count_res.body){
      this.notifications_count = notifications_count_res.body
    } else {
      this.notifications_count = 0
    }
  }

  onClickTab(tab: {name: string, active: boolean}) {
    this.tabs.map(t => {
      // Order matters here - please test before changing
      if(t.name == tab.name) {  // found the tab clicked
        if(tab.name != 'Home') {
          (this.active_tab == t && this.show_mobile_drawer) ? this.closeMobileDrawer() : this.openMobileDrawer(t)
        } else {
          this.aviaService.somethingHappened.emit({type: E_SomethingHappened.HOME_CLICKED, data: {} });
          this.closeMobileDrawer();
          t.active = true;
        }

        // We have toggled the correct active tab by here
        if (t.active) {  // Set active tab var
          this.active_tab = t;
        }
      } else {
        t.active = false;
      }
    });
    this.drawer_change_subject.next(this.show_mobile_drawer);
  }

  deselectAllTabs() {
    this.tabs.map(t => {
      if (t === this.active_tab) this.active_tab = undefined;
      if(t.name != 'Home') {
        t.active = false
      }
    });
  }

  closeMobileDrawer() {
    this.show_mobile_drawer = false;
    this.drawer_change_subject.next(this.show_mobile_drawer);
    this.deselectAllTabs();
  }

  openMobileDrawer(tab: {name: string, active:boolean}) {
    this.show_mobile_drawer = true;
    this.drawer_change_subject.next(this.show_mobile_drawer);
    this.deselectAllTabs();
    tab.active = true;
  }

  determineMyOrgDropdown() {
    // Checkin tab for HS
    if(this.aviaService.session.org.type == 1) {
      this.aviaService.getHealthcareSystems({id: this.aviaService.session.org.id}).then((res) => {
        if(res.status === 200 && res.body && res.body.results && res.body.results[0]) {
          this.org = res.body.results[0];
          this.keychain_org.checkin = this.aviaService.hasAccess(this.aviaService.session, 'org', 'checkin', 'r', this.org['rd'] );
          if(this.keychain_org.checkin.r && this.org.checkin_vis === 1) this.show_checkin = true;
        }
      })
    }

    // Dashboard for Partner
    // Builds accessKey specific for partner RD.
    // Partners have a situation where they may not have anything to show in the my org dropdown and should have it hidden
    // Checks below to see if we should show dropdown button or not
    if(this.aviaService.session.org.type == 5) {
      this.aviaService.getPartners(undefined, this.aviaService.session.org.id).then((res) => {
        this.show_my_org = false;
        if(res.status === 200 && res.body && res.body.results && res.body.results[0]) {
          this.org = res.body.results[0];
          this.keychain_org.org_demo = this.aviaService.hasAccess(this.aviaService.session, 'np', 'org_demo', 'rw', this.org['rd'] );
          if (this.keychain.dashboard_feature.e && this.keychain_org.org_demo.w && !this.aviaService.mobile_mode) this.show_reports = true;
          if (this.keychain.dashboard_feature.e) this.show_dashboard = true;
          if ((this.community && this.community.landing_page) || this.show_reports || this.show_dashboard) this.show_my_org = true;
        }
      })
    }
  }

  async updateNotificationsCount(count) {
    this.notifications_count = count;
  }


  avatarIsCloudfront() {
    return /cloudfront.net\/user\/avatar/.test(this.aviaService.session.user.avatar)
  }

}
