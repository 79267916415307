<app-avia-edit-modal
	class="d-flex align-items-center"
	[assetColor]="'gray'"
	[form]="product_form"
	[modalTitle]="mode === E_Modal_Operating_Mode.ADD ? 'Add Product' : 'Edit Product'"
	(revertsignal)="setupFormData()"
	(savesignal)="onSubmit()"
	>

	<form [formGroup]="product_form" #formElem="ngForm" modal-body>

		<!-- NAME -->
		<div class="form-group">
			<label for="name">Name</label>
			<span class="float-right">
				<small><i
					[ngClass]="{'text-danger': product_form.touched && product_form.controls['name'].touched && product_form.controls['name'].hasError('required'),
											'text-gray-600': (product_form.untouched && product_form.controls['name'].untouched) || !product_form.controls['name'].hasError('required')}"
					class="font-italic">required
				</i></small>
			</span>
			<input id="name"
				class="form-control"
				formControlName="name"
				placeholder="{{'Product Name'}}"
				type="text"
				[ngClass]="{'is-invalid': product_form.touched && product_form.controls['name'].touched && product_form.controls['name'].invalid}"
				>
		</div>

		<!-- SHORT DESC -->
		<div class="form-group">
			<label for="short_desc">Short Description</label>
			<span class="float-right">
				<small><i
					[ngClass]="{'text-danger': product_form.touched && product_form.controls['short_desc'].touched && product_form.controls['short_desc'].hasError('required'),
											'text-gray-600': (product_form.untouched && product_form.controls['short_desc'].untouched) || !product_form.controls['short_desc'].hasError('required')}"
					class="font-italic">required
				</i></small>
			</span>
			<textarea id="short_desc"
				class="form-control"
				formControlName="short_desc"
				placeholder="{{'Product Tagline'}}"
				rows="2"
				[ngClass]="{'is-invalid': product_form.touched && product_form.controls['short_desc'].touched && product_form.controls['short_desc'].invalid}">
			</textarea>
			<small *ngIf="product_form.controls.short_desc.hasError('maxlength')" class="form-text text-danger">
				Maximum of 280 characters
			</small>
		</div>

		<!-- DESCRIPTION -->
		<div class="form-group">
			<label for="description">Description</label>
			<span class="float-right">
				<small><i
					[ngClass]="{'text-danger': product_form.touched && product_form.controls['description'].touched && product_form.controls['description'].hasError('required'),
											'text-gray-600': (product_form.untouched && product_form.controls['description'].untouched) || !product_form.controls['description'].hasError('required')}"
					class="font-italic">required
				</i></small>
			</span>
			<textarea id="description"
				class="form-control"
				formControlName="description"
				placeholder="Enter a description for the product here."
				rows="6"
				[ngClass]="{'is-invalid': product_form.touched && product_form.controls['description'].touched && product_form.controls['description'].invalid}">
			</textarea>

		</div>

		<!-- SOLUTIONS -->
		<div class="form-group">
			<label for="description">Solution Tags</label>
			<span>&nbsp;(type 2 letters to see available options)</span>
			<span class="float-right">
				<small><i
					[ngClass]="{'text-danger': product_form.controls['solutions'].hasError('required') || product_form.controls.solutions.invalid}"
					class="font-italic">required
				</i></small>
			</span>
			<div class="p-0 col-12" [ngClass]="{'highlight-danger':!product_form.controls['solutions'].hasError('required') && product_form.controls.solutions.invalid}">
				<app-avia-form-search-topics
					formControlName="solutions"
					ngDefaultControl
					[show_no_matches]="true"
					[multi_select]="true"
					[options]="{ search_add_ons: {type: 2}, show_recommendations: true, text: 'Link a solution (up to 3 total)' }"
					[prompt_string]="'Select a Topic'"
					[recommendations]="recommendations"
					[validation]="[
					{
						condition: !product_form.controls['solutions'].hasError('required') && product_form.controls.solutions.invalid,
						message: 'Maximum of 3 solutions per product...'
					}
				]"
				></app-avia-form-search-topics>
			</div>
		</div>

		<div class="form-group">
			<label>Does this product address COVID-19?</label>
			<app-avia-form-checkbox
				ngDefaultControl
				formControlName="is_covid"
				[label]="'Yes'"
			></app-avia-form-checkbox>
		</div>

		<div class="form-group" *ngIf="product_form.controls.is_covid.value == true">
			<label>Provide a description on how the product is applied to COVID-19.</label>
			<span class="float-right">
				<small><i
					[ngClass]="{'text-danger': product_form?.touched && product_form?.controls['covid_desc'].touched && product_form.controls['covid_desc'].invalid,
											'text-gray-600': (product_form?.untouched && product_form?.controls['covid_desc'].untouched) || !product_form.controls['covid_desc'].invalid}"
					class="font-italic">required
				</i></small>
			</span>
			<textarea
				class="resize-none form-control"
				rows="6"
				[ngClass]="{'is-invalid': product_form.touched && product_form.controls['covid_desc'].touched && product_form.controls['covid_desc'].invalid}"
				formControlName="covid_desc">
			</textarea>
		</div>
	</form>

	<div *ngIf="mode === E_Modal_Operating_Mode.ADD" class="btn font-size-12 supplied-btn" [ngClass]="{'btn-blue-super-light': !blue_btn, 'btn-blue-dark': blue_btn}" supplied-btn>+ Add Product</div>
	<div *ngIf="mode === E_Modal_Operating_Mode.EDIT && onboarding == true && !missing_field" supplied-btn>
		<button class="btn edit-btn p-0">
			<img
				class="edit-icon pointer"
				[src]="'/assets/edit-gray-on.svg'">
		</button>
	</div>

	<div *ngIf="mode === E_Modal_Operating_Mode.EDIT && onboarding == true && missing_field" class="w-100 d-flex p-1 bg-gray-200 mr-2" supplied-btn>
		<div class="mx-auto d-flex align-items-center w-100">
			<div class="field-missing text-center">
				<i class="material-icons text-orange-light md-14 mr-1">warning</i>
				<span class="font-size-13 font-weight-bold">Missing a {{missing_field}}. </span>
				<span class="font-size-13">This is required in order for your product to be visible to potential buyers.</span>
			</div>
		</div>
	</div>
</app-avia-edit-modal>
