<div #content id="content-wrapper"
	[ngClass]="{'drop-zone-active': isHovering}"
	(dragleave)="preventDefaultAndStopPropagation($event)"
	(dragover)="onDragFileOverStart($event)"
	>
  <div [ngClass]="{'fadeout': isHovering}" (dragleave)="onDragFileOverEnd($event)" (drop)="onDragFileDrop($event)">
    <ng-content></ng-content>

    <div *ngIf="isHovering" class="inner">
      <div class="center" (dragleave)="preventDefaultAndStopPropagation($event)" (dragover)="preventDefaultAndStopPropagation($event)">
        <div class="icon"><i class="material-icons">cloud_upload</i></div>
        <div class="text">Drop to add file</div>
      </div>
    </div>
  </div>
</div>
