import { Component, OnInit } from '@angular/core';
import { AVIAConnectService } from '../../avia-connect.service';
import { Router } from '@angular/router';
import { Notification } from '../../../class';


@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})

export class NotificationsPageComponent implements OnInit {
  session:             Object;
  notifications:       Notification[] = [];
  notifications_count: number;

  notificationCountMap: { [k: string]: string } = {
    '=0': '',
    '>99': '99+',
    'other': '#'
  };

  constructor(public aviaService: AVIAConnectService, private router: Router) { }

  async ngOnInit() {
    this.session = await this.aviaService.getSessionSupport();
    this.getNotifications();
  }

  async getNotifications():  Promise<any> {
    let data = await this.aviaService.getNotifications(this.session['user'].id);

    this.notifications = [];
    if(data.body && data.body.qa) {
      this.notifications = this.notifications.concat(data.body.qa);
    }
    if(data.body && data.body.notifications) {
      this.notifications = this.notifications.concat(data.body.notifications);
    }

    this.notifications = this.notifications.sort( (a,b) => {
      if(new Date(a.created) > new Date(b.created)) {
        return -1;
      }
      else {
        return 1;
      }
    });

    this.notifications_count = this.notifications.length;
  }

  goToCard($event): void {
    const notification = $event.notification;
    let queryParams;
    let url;
    switch(notification.type_obj.name) {
      case 'Added to Group':
      case 'Added to Workspace':
        window.location.href = notification.options.url;
        break;
      case 'HS Action':
        queryParams = {};
        url = notification.options.path;
        break;

      case 'KM card comment':
      case 'KM chat':
        if (notification.comment_id == null && notification.km_obj == null) {
          url = '/intelligence/km/start';
        } else {
          queryParams = { mode: 1, id: notification.km_obj.id }
          if (notification.comment_id) {
            queryParams['target'] = notification.comment_id;
          }
          url = '/intelligence/km/graph';
        }
        break;

      // case 'KM chat':
      //   queryParams = { mode: 1, id: notification.km_obj.id, chat: true }
      //   if (notification.comment_id) {
      //     queryParams['target'] = notification.comment_id;
      //   }
      //   url = '/intelligence/km/graph';
      //   break;

      case 'CL comment':
        queryParams = { mode: 1, id: notification.content_obj.id };
        if( notification.comment_id) {
          queryParams['target'] = notification.comment_id;
        }
        url = '/intelligence/cl/search';
        break;

      case 'Request to join channel':
      case 'Pulse Comment':
      case 'Info request':
      case 'Info request acknowledgment':
          queryParams = {};
          url = notification.options.url;
          break;
      case 'Channel chat':
      case 'Group chat':
        queryParams = { mode: 1, id: notification.channel_obj.id, chat: true };
        if (notification.comment_id) {
          queryParams['target'] = notification.comment_id;
        }
        url = '/ws/channel/' + notification.channel_obj.id;
        break;

      default: break;
    }

    if (url) { this.router.navigate([url], { queryParams });}
    this.dismiss({ notification });
  }

  async goToMemberProfile($event) {
    let notification = $event.notification;
    await this.dismiss({notification:notification});
    if( notification.options && notification.options.accepting ) {
      this.router.navigate(['/profiles/in/' + notification.action_user_obj.id]);
    } else {
      this.router.navigate(['/profiles/in/' + notification.action_user_obj.id] );
    }
  }

  goToLink($event) {
    const notification = $event.notification;
    const url = notification?.url || notification?.options?.url ;
    window.location.href = url;
    this.dismiss($event);
  }

  async dismiss(event) {

    let notification = event.notification;
    this.aviaService.updateNotification(notification.id, { dismissed: 1 });

    if(event.notification.type_obj.name === "question") {
      await this.aviaService.getPosts({
        filters:{
          ids: notification.ids
        }
      })

      await this.getNotifications();
    }
    else if (event.notification.type_obj.name === "answer") {
      await this.aviaService.getPosts({
        filters:{
          ids: notification.answer_ids
        }
      })

      await this.getNotifications();
    }
  }

  closePage() {
    window.history.back();
  }

}
