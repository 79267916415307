
<div class="comment-wrapper pt-3 position-relative">
		<comment-item
			[comment]="comment"
			[avatar_size]="'lg'"
			[thread_visibility]="thread_visibility"
    > <!-- ANSwER TEXT -->
      <div post-header *ngIf="comment?.type == 5 && is_top">
        <span class="center-btn-text text-gray-600">TOP ANSWER&nbsp;</span>
        <i class="material-icons md-14 text-green-lime center-btn-text">verified_user</i>
      </div>
      <div post-header *ngIf="comment?.type == 5 && !is_top" class="text-gray-600">
        ANSWER
      </div>
    </comment-item>
	<div class="reacts d-flex">
		<comment-react
      [modified_date]="comment?.created"
      [show_help_text]="page_config?.show?.help_text"
      [like_text]="page_config?.placeholders.comment_react || 'Like'"
			(likeClicked)="likeClicked()"
			(unlikeClicked)="unlikeClicked()"
			(replyClicked)="replyClicked(comment)"
			[liked]="comment.data.user_reactions && comment.data.user_reactions[0] !== undefined"
		>
    </comment-react>
		<div class="ml-auto">
			<comment-reactions
				[like_count]="comment?.likes_count"
				[comment_count]="comment?.data?.reply_count">
			</comment-reactions>
		</div>
	</div>
</div>

<button class="btn qa-indent-plus-x-space mt-2 p-0 d-flex align-items-center" *ngIf="(comment?.data?.reply_count - (comment?.data?.replies?comment?.data?.replies?.length:0)) > 0" (click)="getReplies()">
	<div _ngcontent-cad-c59="" class="material-icons" style="
		font-size: 14px;
		width: 30px;
		font-weight: bold;
	"> subdirectory_arrow_right </div>
	<div class="q-and-a-default-button m-0 text-lowercase font-size-12">
		{{comment?.data?.reply_count - (comment?.data?.replies?comment?.data?.replies?.length:0)}} more {{((comment?.data?.reply_count - (comment?.data?.replies?comment?.data?.replies?.length:0)) > 1)?"replies":"reply"}}
	</div>
</button>
<div class="ml-7 mt-4 position-relative" *ngFor="let reply of comment?.data?.replies">
	<!-- [show_a]="comment?.type == 5" -->
	<comment-item
		class="pb-4"
		[comment]="reply"
		[avatar_size]="'md'"
		[thread_visibility]="thread_visibility"
  ></comment-item>
  <div class="reacts d-flex qa-sub-indent">
		<comment-react
			[modified_date]="reply?.created"
			(likeClicked)="likeClicked(reply)"
			(unlikeClicked)="unlikeClicked(reply)"
			(replyClicked)="replyClicked(reply)"
			[liked]="reply.data.user_reactions && reply.data.user_reactions[0] !== undefined"
		>
		</comment-react>
		<div class="ml-auto">
			<comment-reactions
				[like_count]="reply?.likes_count"
				[comment_count]="0">
			</comment-reactions>
		</div>
	</div>
</div>

<div *ngIf="show_reply_box" class="ml-7 mt-4 d-flex">
	<app-reply
		#reply
		class="w-100"
		[mode]="'create'"
		[parent_id]="comment?.id"
		[reply_owner]="reply_item?.data?.user"
		[type]="reply_item?.type"
		[thread_visibility]="thread_visibility"
		[auto_tag]="true"
		[show_avatar]="true"
		(updateItem)="addReply($event)"
		>
  </app-reply>
</div>
