import { Component, OnDestroy, OnInit } from '@angular/core';

import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';

import { AVIAConnectService } from '../../avia-connect.service';
import { Common } from '../../common';

import { Org } from '../../../class';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnDestroy, OnInit {
  readonly DEBUG_MODE: boolean = false;
  readonly VERBOSE:    boolean = false;

  private subsc_route: Subscription;
  org_type:            string = '';
  sub_header:          string = '';
  not_found_text:      string = '';

  // FORM STUFF
  fg_email:   FormGroup;
  fg_happy:   FormGroup;
  fg_failure: FormGroup;

  private fc_email: AbstractControl;
  text: any; // angular8: added to fix error when building prod: "Property does not exist"
  white: any; // angular8: added to fix error when building prod: "Property does not exist"

  // Email Form
  orgtype_filter: number[] = [];

  // Failure Form
  domain: string = '';

  // Happy Form
  orgs: Org[] = [];


  constructor( private aviaService: AVIAConnectService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router ) {}

  ngOnInit() {
    if ( !this.subsc_route ){
      this.subsc_route = this.route.url.subscribe( (r) => {
        let type = r[r.length - 1].path;

        switch(type) {
          case 'hs':
            this.orgtype_filter = [1];
          case 'other':
            if (this.orgtype_filter.length === 0) this.orgtype_filter = [4,5];
            this.org_type = 'organization';
            this.sub_header = 'AVIA Connect is open to hospitals and health systems. You will need a valid hospital or health system email to register.';
            this.not_found_text = 'Sometimes we don\'t have all of the email domains associated with an organization. Fill out the form below to get help with setting up your profile.'
          break;

          case 'solco':
            this.orgtype_filter = [2];
            this.org_type = 'company';
            this.sub_header = 'AVIA Connect is open to companies that address healthcare needs. You will need a valid company email to register.';
            this.not_found_text = 'Fill out the form below to get help with setting up your profile.';
          break;
        }
      });
    }

    // Create the form group
    this.fg_email = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.fc_email = this.fg_email.controls.email;

    this.fg_happy = this.fb.group({
      org: [null, Validators.min(1)]
    });
    this.fg_happy.disable();

    this.fg_failure = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname:  ['', [Validators.required]],
      orgname:   ['', [Validators.required]],
      website:   ['', [Validators.required]],
    });
    this.fg_failure.disable();
  }

  ngOnDestroy() {
    this.subsc_route.unsubscribe();
    this.subsc_route = undefined;

    this.orgtype_filter = [];
  }

  onBackToEmail($event: any): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.fg_failure.disable();
    this.fg_failure.reset();
    this.domain = '';

    this.orgs = [];
    this.fg_happy.disable();
    this.fg_happy.reset();

    this.fg_email.enable();
  }


  async onSubmitEmail(): Promise<void> {
    this.fg_email.disable();

    let domain_res: any = await this.aviaService.signupDomainLookup(this.fc_email.value, this.orgtype_filter);
    this.VERBOSE && console.log('domain_res', domain_res);

    let results: any[] = domain_res.body;
    if ( domain_res.status == 200 && results && results.length > 0 ) {
      if ( results[0].user_id ) {
        // we already exist
        this.fg_happy.disable();
        this.aviaService.notifyWarning(`The email address "${this.fc_email.value}" is already registered in AVIA Connect`);
        this.router.navigate(['']);
      } else {
        this.orgs = results;

        let patched_id: number = 0;
        if (this.orgs.length && this.orgs.length === 1) patched_id = this.orgs[0].id;

        this.fg_happy.controls.org.patchValue(patched_id);
        this.fg_happy.enable();
      }
    } else {
      this.domain = this.fc_email.value.replace(/^.+@/, '');
      this.fg_failure.enable();
    }
  }

  async onSubmitFailure(): Promise<void> {
    this.fg_failure.disable();

    let user_info = {
      email: this.fc_email.value,
      firstname: this.fg_failure.controls.firstname.value,
      lastname: this.fg_failure.controls.lastname.value,
      orgname: this.fg_failure.controls.orgname.value,
      website: this.fg_failure.controls.website.value,
    };

    let fail_res = await this.aviaService.selfAddHelpEmail(user_info);

    if ( fail_res['status'] == 200 && fail_res['body'].message === 'success' ) {
      this.aviaService.notify('success', 'Help is on the way.', 'We\'ll reach out to you soon with next steps on completing your profile.', {showConfirmButton: true});
    } else {
      //this.aviaService.notifyFailure(`Sorry, we could not process your request, please email "${Common.EMAILADDY_SUPPORT}" for assistance.`);
    }

    this.router.navigate(['']);
  }

  async onSubmitHappy(): Promise<void> {
    this.fg_happy.disable();

    let org_id: number = this.fg_happy.controls.org.value;
    let selfadd_res: any = await this.aviaService.selfAddUser(this.fc_email.value, org_id);

    if ( selfadd_res['status'] == 200 && selfadd_res['body'].message === 'success') {
      this.router.navigate(['signup','check-email']);
    } else {
      this.aviaService.notifyFailure('Something went wrong! Could not add user.');
    }
  }

}
