<div class="main-panel">
	<app-postmaker *ngIf="fs?.config && config.create.types.length > 0 && !config.create.turn_off_postmaker_override" [mode]="'create'"></app-postmaker>

	<div *ngIf="fs?.new_posts" class="position-relative">
		<div
			class="py-1 px-2 btn btn-refresh text-white position-absolute"
			(click)="getNewPosts()">
			<div class="d-flex align-items-center justify-content-center">
				<span class="material-icons pr-1 md-20">arrow_upward</span>
				New Posts
			</div>
		</div>
	</div>

	<ng-container *ngIf="show_filters">
		<div class="line" *ngIf="fs?.config && config.create.types.length > 0 && !config.create.turn_off_postmaker_override"></div>
		<div class=my-2>
			<app-avia-tab-pills
				[emit_on_init]="false"
				[nav_bar]="fs?.nav_bar"
				[mobile_collapse]="false"
				(tab_selected)="fs?.filter($event)">
			</app-avia-tab-pills>
		</div>
	</ng-container>

	<div *ngIf="fs?.items && fs?.items.length > 0" class="feed"
		infinite-scroll
		[fromRoot]="true"
		[infiniteScrollDistance]="scrollDistance"
		[infiniteScrollUpDistance]="scrollUpDistance"
		[infiniteScrollThrottle]="throttle"
		(scrolled)="onScrollDown($event)"
		(scrolledUp)="onScrollUp($event)">

		<!-- USED WHEN NEW MESSAGES ARE AVAILABLE -->

		<ng-container *ngFor="let item of fs?.items">
			<!-- app-post will be used for posts and QA -->
			<app-post
				[post]="item"
				[active_id]="active_id"
				[content_types]="content_types"
				[show_appears_in]="show_appears_in"
				[white_background]="white_background"
				[gray_border]="gray_border"
				[allow_recommending]="allow_recommending"
				(onContentDelete)="onContentDelete.emit(true)"
				(onRecommendedChange)="onRecommendedChange.emit(true)"
				(onBookmark)="onBookmark.emit()"
				(onSetActiveID)="setActiveCard($event)"
			></app-post>
			<!-- content -->
			<!-- events -->
			<!-- add -->
		</ng-container>

	</div>

	<div class="py-6" *ngIf="!fs?.items || fs?.items.length == 0">
		<div *ngIf="!fs?.loading" class="w-100 d-flex pb-4 empty-img mx-auto"><img class="w-100 p-2" src="/assets/empty-state.svg" alt="empty-state"></div>
		<div *ngIf="!fs?.loading">
			<div *ngIf="fs?.config?.create?.types?.includes(2) && !fs?.config?.create?.types?.includes(4)" class="text-center heading-size-16">No questions or posts here, yet.</div>
			<div *ngIf="fs?.config?.create?.types?.includes(4) && !fs?.config?.create?.types?.includes(2)" class="text-center heading-size-16">No questions here, yet.</div>
			<div *ngIf="!fs?.config?.create?.types?.includes(4) && !fs?.config?.create?.types?.includes(2)" class="text-center heading-size-16">Nothing has been posted here, yet.</div>
		</div>
	</div>
</div>

