<ng-template [ngIf]="static_page === false" [ngIfElse]="AviaConnectionsModal"><span class="d-none"></span></ng-template>

<ng-template #AviaConnectionsModal>
	<!-- TITLE BLOCK -->
	<app-avia-header-row *ngIf="!static_page" class="sticky-top w-100">
		<div class="backblock col">
			<i class="material-icons close-icon md-24 pointer" (click)="close()">close</i>
		</div>
	</app-avia-header-row>
	<div
		class="avia-connections-modal"
		[ngClass]="{
			'avia-modal-full-screen avia-modal-full-screen-inverse': (fullscreen && static_page === false),
			'avia-modal': (!fullscreen && static_page === false)
		}">
		<!-- Connections -->
		<div class="container pt-4">
			<div class="row justify-content-center">
				<ng-container *ngFor="let u of suggested_connections; index as i">
					<div class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
						<app-avia-suggested-connection-card [location]="'suggested page'" [user]="u"></app-avia-suggested-connection-card>
					</div>
				</ng-container>
			</div>
		</div>

	</div>
</ng-template>


<!-- === ACTIVATION BUTTONS === -->
<div (click)="open()">
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
</div>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
