<div (click)="recordClickAnalytics($event)" class="card mb-4 w-100 shadow-none border border-gray-300" [ngClass]="{'pinned-post':post?.data?.pin}">
	<div *ngIf="post?.data?.rootTag?.showRoot && post?.data?.content_item == null" class="card-header bg-gray-100 border-bottom border-gray-300 text-gray-600">
		<span class="text-capitalize">{{fs?.support?.comment_types_dict[post?.type]?.name}}</span>
		<span> posted in </span>
		<a [href]='post?.data?.rootTag?.url.replace("Questions","Feed")' target="_blank" class=""><strong class="text-gray-800">{{post?.data?.rootTag?.name}}</strong></a>
	</div>

	<div class="">
		<div class="pt-4 px-4">
			<div *ngIf="!post?.data?.content_item">
				<div class="d-flex">
					<app-user-tag [size]="'lg'"
						[created]="post?.created"
						[user]="post?.data?.user"
						[prepend_date_text]="page_config?.placeholders?.question_date">
					</app-user-tag>

					<div *ngIf="!this.fs.aviaService.mobile_mode" class="vis-tag position-absolute right-2 rounded text-white bg-blue-pulse font-size-10" [ngClass]="{'vis-tag-anyone':post?.data?.visibility.length === 0, 'right-3': post?.data?.muted }">
							<div *ngIf="post?.data?.visibility.length > 0"
								ngbTooltip="This post is visible to Providers and AVIA"
								data-toggle="tooltip"
								placement="top"
							>
								<span class="material-icons md-12 align-text-bottom text-white mr-1">
									visibility
								</span>
								<span> PROVIDERS AND AVIA</span>
							</div>
							<div *ngIf="post?.data?.visibility.length === 0"
								ngbTooltip="This post is visible to all users{{post?.data?.rootTag?.workspace_id?' in this Group':''}}"
								data-toggle="tooltip"
								placement="top"
							>
								<span class="material-icons md-12 align-text-bottom text-white mr-1">
									visibility
								</span>
								<span>{{post?.data?.rootTag?.workspace_id? 'GROUP':'ANYONE'}}</span>
							</div>
          </div>
          <!-- MUTE ICON -->
          <div *ngIf="post?.data?.muted" class="position-absolute right-2">
            <i class="material-icons md-24 pr-1">notifications_off</i>
          </div>

					<!-- DROP DOWN -->
					<div class="position-absolute more pointer">
						<div ngbDropdown placement="bottom-right">
							<div ngbDropdownToggle>
								<i class="material-icons md-24">more_vert</i>
							</div>
							<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right p-0">
								<ng-container *ngIf="post?.data?.user?.is_owner">
									<button class="dropdown-item" (click)="editPost()">
										<i class="material-icons md-24 pr-1">edit</i>Edit
									</button>
                </ng-container>
                <ng-container *ngIf="post?.data?.user?.is_owner || post?.data?.pinnable">
									<button class="dropdown-item" (click)="openAlertModal()">
										<i class="material-icons md-24 pr-1">remove_circle</i>Delete
									</button>
                </ng-container>
                <ng-container>
									<button  class="dropdown-item" (click)="toggleMute()">
										<span *ngIf="!post?.data?.muted">
											<i class="material-icons md-24 pr-1">notifications_off</i> Mute
										</span>
										<span *ngIf="post?.data?.muted">
											<i class="material-icons md-24 pr-1">notifications</i> Unmute
										</span>
									</button>
								</ng-container>
								<ng-container *ngIf="post?.data?.pinnable">
									<button class="dropdown-item" (click)="togglePin()">
										<span *ngIf="!post?.data?.pin">
											<i class="material-icons md-24 pr-1">push_pin</i>Pin
										</span>
										<span *ngIf="post?.data?.pin">
											<i class="material-icons md-24 pr-1">remove_circle</i>Remove Pin
										</span>
									</button>
									<button *ngIf="post?.data?.pin" class="dropdown-item" (click)="sendPinToTop()">
										<span>
											<i class="material-icons md-24 pr-1">vertical_align_top</i>Send Pin To Top
										</span>
									</button>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
				<div class="d-flex mt-1">
					<div *ngIf="this.fs.aviaService.mobile_mode" class="vis-tag rounded text-white bg-blue-pulse font-size-10" [ngClass]="{'vis-tag-anyone':post?.data?.visibility.length === 0}">
							<div *ngIf="post?.data?.visibility.length > 0"
								ngbTooltip="This post is visible to Providers and AVIA"
								data-toggle="tooltip"
								placement="right"
							>
								<span class="material-icons md-12 align-text-bottom text-white mr-1">
									visibility
								</span>
								<span> PROVIDERS AND AVIA</span>
							</div>
							<div *ngIf="post?.data?.visibility.length === 0"
								ngbTooltip="This post is visible to all users{{post?.data?.rootTag?.workspace_id ? ' in this Group':''}}"
								data-toggle="tooltip"
								placement="right"
							>
								<span class="material-icons md-12 align-text-bottom text-white mr-1">
									visibility
								</span>
								<span>{{post?.data?.rootTag?.workspace_id ? 'GROUP':'ANYONE'}}</span>
							</div>
					</div>
			</div>
				<!-- TEXT -->
				<div class="pt-4">
					<div *ngIf="page_config?.show?.q" class="post-header text-gray-600">QUESTION</div>
					<div class="content-text truncate-overflow heading-size-16 fw-500 pt-2" [ngClass]="{'read-more':!read_more}" [innerHTML]="post?.data?.content"></div>
				</div>
				<!-- EDIT POST -->
				<app-postmaker
					*ngIf="editing"
					#postmaker
					[mode]="'update'"
					[post]="post"
					(updateItem)="updatePost($event)"
				></app-postmaker>
				<strong class="show-more-text pointer"
					(click)="read_more = !read_more"
				>Show {{read_more ? 'less' : 'more'}}</strong>
			</div>

			<div *ngIf="post?.data?.content_item" style="
			margin-left: -1rem;
			margin-right: -1rem;
			margin-top: -1rem;
			position: relative;">
				<app-avia-content-card
					[active_id]="active_id"
					[content]="post?.data?.content_item"
					[content_types]="content_types"
					[show_appears_in]="show_appears_in"
					[white_background]="white_background"
					[gray_border]="gray_border"
					[allow_recommending]="allow_recommending"
					(onSetActiveID)="onSetActiveID.emit($event)"
					(onContentDelete)="onContentDelete.emit(true)"
					(onRecommendedChange)="onRecommendedChange.emit(true)"
					(onBookmark)="onBookmark.emit()"
					>
				</app-avia-content-card>
			</div>

			<!-- TAGS -->
			<div *ngIf="read_more && page_config?.show?.tags && post?.data?.tags.length > 0" class="d-flex pt-2 flex-wrap">
				<ng-container *ngFor="let tag of post?.data?.tags">
					<a [href]='tag?.url?.replace("Questions","Feed")' target="_blank" class="post-tags mr-1 mb-1 p-1 bg-gray-100 font-size-12 rounded text-blue-light font-weight-bold">
						@{{tag.name}}
					</a>
				</ng-container>
			</div>

			<div class="media-link mt-4 mb-4" *ngIf="post?.data?.media">
				<a href="{{post?.data?.media?.url}}" target="_blank"><post-media [media]="post?.data?.media"></post-media></a>
			</div>

			<div class="position-relative" [ngClass]="{'mt-4': votes == 0 && post?.data?.reply_count == 0 }">
				<post-reactions
					[like_count]="votes"
					[comment_count]="post?.data?.reply_count"
					[comment_text]="page_config?.placeholders?.comment_text"
					(commentsClicked)="getReplies()"
				>
				</post-reactions>
			</div>
		</div>
			<div class="w-100">
				<post-react
				  [post]="post"
					[i_like]="i_like"
					[i_follow]="post?.data?.user_followed"
					[i_bookmark]="post?.data?.content_item?.interested"
					[showBookmark]="post?.data?.content_item?.permitted && post?.type == 7"
					[showFollow]="post?.type == 4 || post?.type == 2"
					[showShare]="true"
					[textComment]="page_config?.placeholders?.comment_button"
					(commentClicked)="commentClicked()"
					(followClicked)="followClicked()"
					(likeClicked)="likeClicked()"
					(shareClicked)="shareClicked()"
					(bookmarkClicked)="bookmarkClicked()"
				>
				</post-react>
		</div>
		<div class="pb-4 px-4">
			<!-- COMMENTS -->
			<button *ngIf="(post.data.reply_count - (post.data.replies?post.data.replies.length:0)) > 0"
				class="mt-4 mb-0 font-size-14 text-bold no-upper btn q-and-a-default-button p-0"
				(click)="getReplies()">See {{post.data.reply_count - (post.data.replies?post.data.replies.length:0)}} more {{post_type == 4?"answer":"comment"}}{{((post.data.reply_count - (post.data.replies?post.data.replies.length:0)) > 1)?"s":""}}</button>
			<ng-container *ngFor="let comment of post?.data?.replies">
				<app-comment
					[thread_visibility]="post?.data?.visibility"
					[is_top]="isTop(comment.id, post?.data?.replies)"
					[comment_type]="post_type"
					[comment]="comment"
					[page_config]="page_config"
					(updateItem)="updatePost($event)">
				</app-comment>
			</ng-container>

			<!-- COMMENT INPUT BOX FOR STATUS -->
			<div *ngIf="show_reply_box || post?.data?.reply_count > 0" class="pt-4">
				<app-reply
					#reply
					[mode]="'create'"
					[parent_id]="post?.id"
					[reply_owner]="post?.data?.user"
					[type]="post?.type"
					[placeholder]="page_config?.placeholders?.comment_placeholder"
					(updateItem)="addReply($event)"
					[avatar_size]="'lg'"
				></app-reply>
			</div>
		</div>
	</div>
</div>

<app-avia-alert-modal
	#alertModal
	[button_confirm_text]="'Yes, I am sure.'"
	[modal_title]="'Are you sure you want to delete your ' + fs?.support?.comment_types_dict[post?.type].name + '?'"
	[show_cancel_button]="true"
	[type]="E_AlertModalTypes.ERROR"
	(confirmEmit)="deletePost($event)"
	>
	<div class="row justify-content-center">
		<div class="col-12 text-center">
			All data will be lost.
		</div>
	</div>
</app-avia-alert-modal>
