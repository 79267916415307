<div class="form-check avia-check">
	<label class="form-check-label pl-1" [ngClass]="{'pointer': !disabled}">
		<input #checkbox
			class="form-check-input"
			type="checkbox"
			[checked]="_value"
			[disabled]="disabled"
			[ngClass]="{'pointer': !disabled}"
			(change)="writeValue(checkbox.checked)"
		/>
		<span class="checkmark"></span>{{label}}
	</label>
</div>
