import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnboardingService } from '../../onboarding.service';
import { Subscription } from 'rxjs';
import { Common } from '../../../../common';

@Component({
  selector: 'app-onboarding-title-form',
  templateUrl: './onboarding-title-form.component.html',
  styleUrls: ['./onboarding-title-form.component.scss']
})
export class OnboardingTitleFormComponent implements OnInit {
  readonly VERBOSE: boolean = false;

  data: User;
  title_form: FormGroup;
  nav_sub: Subscription;

  constructor(public sob: OnboardingService, private fb: FormBuilder) {
    this.nav_sub = this.sob.navClick.subscribe(e => {
      if(e.next) {
        this.onSubmit();
      }
    });
    this.createForm();
  }

  ngOnInit() {
    this.data = this.sob.user;
    this.setupFormData();
  }

  createForm(): void {
    this.title_form = this.fb.group({
      title: [null, [Validators.pattern(Common.REGEX_NAME), Validators.required]],
      dept:  [null, [Validators.pattern(Common.REGEX_NAME), Validators.required]]
    });
    this.title_form.valueChanges.subscribe(v => {
      this.sob.form_valid = this.title_form.valid;
    })
  };

  setupFormData(): void {
    let common_fields = {
      title:  this.data.title ? this.data.title : null,
      dept:   this.data.dept  ? this.data.dept  : null
    };

    this.title_form.reset( common_fields );
    this.title_form.markAsPristine();
    this.title_form.markAsUntouched();
    this.sob.form_valid = this.title_form.valid;
  };

  async onSubmit() {
    if (this.title_form && this.title_form.valid && this.title_form.touched) {
      await this.sob.updateUser(this.title_form.value);
      this.nav_sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if(this.nav_sub) this.nav_sub.unsubscribe();
    this.sob.form_valid = false;
  }

}
