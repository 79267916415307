<ng-template #NetworkModal let-c="close" let-d="dismiss">
	<div class="modal-header p-2 bg-gray-200 d-flex">

		<div *ngIf="logo" class="d-flex pr-2" [ngStyle]="{'background': color}">
			<img
				class="img rounded align-self-center mx-auto"
				onerror="this.src='/assets/placeholder-company.jpg'"
				[src]="logo"
			/>
		</div>

		<div class="font-weight-bold w-100">{{ title }}</div>
		<button type="button" class="close p-0 m-0" aria-label="Close" (click)="c()">
			<i class="material-icons"> close </i>
		</button>
	</div>

	<div class="modal-body p-0" [ngClass]="{'pt-2 pt-lg-4': (priorities?.length === 0) || (inventories?.length === 0)}">
		<div class="d-table mx-auto py-0 py-lg-2" *ngIf="(priorities?.length > 0) && (inventories?.length > 0)">
			<app-avia-tab-pills [nav_bar]="nav_bar"></app-avia-tab-pills>
		</div>
		<div class="d-flex align-items-center justify-content-center custom-height" [ngClass]="{'h-100': (priorities?.length === 0) || (inventories?.length === 0)}">
			<img *ngIf="(priorities?.length > 1) || (inventories?.length > 1)" class="img-fluid pl-4 d-none d-lg-block pointer" src="/assets/arrow-left.svg" (click)="back()">
			<div class="w-100 px-2 px-lg-4 h-100">
				<ng-container *ngIf="nav_bar.active_tab === 0">
					<div [ngClass]="{'w-100 h-100': i === pri_index}" *ngFor="let pri_id of priorities; let i = index;">
						<div class="h-100" *ngIf="i === pri_index" [@fadeIn]="fadeIn">
							<app-avia-pri-network-view
								[org_id]="org_id"
								[pri_id]="pri_id"
								(closeModal)="c()"
								(onConnect)="onConnect.emit()"
							></app-avia-pri-network-view>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="nav_bar.active_tab === 1">
					<div [ngClass]="{'w-100 h-100': i === inv_index}" *ngFor="let inv_id of inventories; let i = index;">
						<div class="h-100" *ngIf="i === inv_index" [@fadeIn]="fadeIn">
							<app-avia-inv-network-view
								[inv_id]="inv_id"
								(closeModal)="c()"
								(onConnect)="onConnect.emit()"
							></app-avia-inv-network-view>
						</div>
					</div>
				</ng-container>
			</div>
			<img *ngIf="(priorities?.length > 1) || (inventories?.length > 1)" class="img-fluid pr-4 d-none d-lg-block pointer" src="/assets/arrow-right.svg" (click)="next()">
		</div>
	</div>
	<div class="modal-footer d-block">
		<div class="d-flex" *ngIf="(priorities?.length > 1) || (inventories?.length > 1)">
			<div class="w-25 pointer d-lg-none" (click)="back()"><img class="float-left" src="/assets/arrow-left.svg"></div>
			<ng-container *ngIf="nav_bar.active_tab === 0">
				<div class="mx-auto text-sm d-flex">
					<i *ngFor="let pri_id of priorities; let i = index"
						[ngClass]="{
							'text-gray-200': i !== pri_index,
							'text-gray-600': i === pri_index
						}"
						class="material-icons align-self-center px-1"
					>lens</i>
				</div>
			</ng-container>
			<ng-container *ngIf="nav_bar.active_tab === 1">
				<div class="mx-auto text-sm d-flex">
					<i *ngFor="let inv_id of inventories; let i = index"
						[ngClass]="{
							'text-gray-200': i !== inv_index,
							'text-gray-600': i === inv_index
						}"
						class="material-icons align-self-center px-1"
					>lens</i>
				</div>
			</ng-container>
			<div class="w-25 pointer d-lg-none" (click)="next()"><img class="float-right" src="/assets/arrow-right.svg"></div>
		</div>
	</div>
</ng-template>

<div (click)="openModal()">
	<ng-content></ng-content>
</div>

<!-- JAW -->
