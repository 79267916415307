import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AVIAConnectService } from '../../avia-connect.service';

@Component({
  selector: 'app-avia-add-org-modal',
  templateUrl: './avia-add-org-modal.component.html',
  styleUrls: ['./avia-add-org-modal.scss']
})

export class AviaAddOrgModalComponent implements OnInit {
  readonly VERBOSE = false;

  @Input() //fullscreen
    get fullscreen():      boolean { return this._fullscreen }
    set fullscreen( value: boolean ) { this._fullscreen = value; }
    _fullscreen:           boolean = true;
  @Input() //hide_activate_btn
    get hide_activate_btn():      boolean { return this._hide_activate_btn; }
    set hide_activate_btn( value: boolean ) { this._hide_activate_btn = value; }
    _hide_activate_btn:           boolean = false;
  @Input() static_page: boolean = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  // VARS
  org_types: any = []

  // FORM STUFF
  add_org_form: FormGroup;

  // MODAL STUFF
  @ViewChild('AddOrg', { static: true }) content: NgbModal;
  modal_ref: NgbModalRef;


  constructor(private aviaService: AVIAConnectService, private fb: FormBuilder, private modalService: NgbModal, private router: Router) {
    this.createForm();
  }

  async ngOnInit() {
    this.org_types = (await this.aviaService.getOrgsSupport()).org_types;
    this.org_types = this.org_types.filter(ot => ot.id !== 2 && ot.id !== 3); //DO NOT show 'Solution Company' or 'AVIA' categories
    this.add_org_form.controls.type.patchValue(0)
  }

  async onSubmit() {
    this.VERBOSE && console.log('avia-add-org-modal::onSubmit called with: ', this.add_org_form.value);
    const new_org = this.add_org_form.value;
    this.add_org_form.reset()
    await this.aviaService.addNewOrg(new_org);
    this.close()
    this.aviaService.universal_add_active = false
  };

  createForm(): void {
    let logo:          string = '/assets/placeholder-org.jpg';
    let org_type:      number;

    this.add_org_form = this.fb.group({
      name:          ['', [ Validators.required ] ],
      logo:          [logo, [ Validators.required ] ],
      salesforce_id: [''],
      type:          [org_type, [ Validators.required, Validators.min(1) ] ],
      aha_id:        [null]
    })
  }


   // === MODAL STUFF ===

   close(): void {
    this.cancel.emit();
    this.modal_ref.close();
  };

  async open() {

    let options: NgbModalOptions = {
      'backdrop': 'static',
      size: 'lg',
    };
    if (this._fullscreen) options['windowClass'] = 'avia-modal-fullscreen';

    this.modal_ref = this.modalService.open( this.content, options );
  };

}
