<form class="pb-4" [formGroup]="sc_form" #formElem="ngForm" (ngSubmit)="onSubmit()">
	<div class="row justify-content-center p-4">
		<div class="col-10">
			<h2 class="heading-size-36 text-center mt-4">Review Your Company Profile</h2>
		</div>
	</div>
	<div class="flex-row row justify-content-center pb-4">

		<!-- Logo -->
		<div class="col-12 col-md-4 text-center pt-0 pt-md-4">
			<div class="avatar mb-2">
				<div class="avatar-img-wrapper position-relative mx-auto mt-1 d-flex align-items-center">
					<img (click)="sob.editing_avatar = true" class="img-responsive avatar-img" [src]="sc_form?.value?.logo ? sc_form?.value?.logo : '/assets/placeholder-org.jpg'" />
						<button class="px-0 position-absolute remove-logo border-0 p-0 m-0" type="button" (click)="$event.preventDefault(); $event.stopPropagation();removeLogoLink()">
							<i class="material-icons text-danger">remove_circle</i>
						</button>
					</div>
					<div class="logo-msg text-gray-600 font-size-12 font-italic mx-auto">Your logo in Crunchbase is shown by default unless one is provided</div>

				<avia-modal-fullscreen #imagecropmodal *ngIf="sob.editing_avatar" [additionalCssClasses]="['get-on-top-3']">
					<avia-image-crop-modal (submit)="onChangeLogo( $event ); imagecropmodal.close();" (cancel)="sob.editing_avatar = false; imagecropmodal.close();"></avia-image-crop-modal>
				</avia-modal-fullscreen>
			</div>
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<button type="button" class="btn btn-outline-gray-400 mb-4 mt-0 mt-sm-4" (click)="$event.preventDefault(); sob.editing_avatar = true;">Add Profile Photo</button>
				</div>
			</div>
		</div>

		<div class="col-12 col-md-8 col-lg-6">
			<div class="row">
				<div class="form-group col-12 col-md-6 mb-4">
					<div class="font-weight-bold text-left">Company Name
						<span class="float-right">
							<small><i
								[ngClass]="{'text-danger': sc_form.touched && sc_form.controls['name'].touched && sc_form.controls['name'].hasError('required'),
								'text-gray-600': (sc_form.untouched && sc_form.controls['name'].untouched) || !sc_form.controls['name'].hasError('required')}"
								class="font-italic">required
							</i></small>
						</span>
					</div>
					<input
						type="text"
						class="mt-1 w-100 form-control"
						formControlName="name"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['name'].touched && sc_form.controls['name'].invalid}"/>
					<span *ngIf="sc_form.touched && sc_form.controls['name'].touched && sc_form.controls['name'].hasError('pattern')">
						<small><i class="font-italic text-danger">Must have at least 2 characters</i></small>
					</span>
				</div>

				<div class="form-group col-12 col-md-6 mb-4">
					<div class="font-weight-bold text-left">Website
						<span class="float-right">
							<small>
								<i [ngClass]="{'text-danger': sc_form.touched && sc_form.controls['web_addr'].touched && sc_form.controls['web_addr'].hasError('required'),
															'text-gray-600': (sc_form.untouched && sc_form.controls['web_addr'].untouched) || !sc_form.controls['web_addr'].hasError('required')}"
									class="font-italic">required
								</i>
							</small>
						</span>
					</div>
					<input
						type="text"
						class="mt-1 w-100 form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['web_addr'].touched && sc_form.controls['web_addr'].invalid}"
						formControlName="web_addr"/>
					<span *ngIf="sc_form.touched && sc_form.controls['web_addr'].touched && sc_form.controls['web_addr'].hasError('pattern')">
						<small><i class="font-italic text-danger">Website must begin with http(s)://www.</i></small>
					</span>
				</div>

				<div class="form-group col-12">
					<div class="font-weight-bold pb-1">Description
						<span class="float-right">
							<small><i
								[ngClass]="{'text-danger': sc_form.touched && sc_form.controls['description'].touched && sc_form.controls['description'].hasError('required'),
								'text-gray-600': (sc_form.untouched && sc_form.controls['description'].untouched) || !sc_form.controls['description'].hasError('required')}"
								class="font-italic">required
							</i></small>
						</span>
					</div>
					<textarea
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['description'].touched && sc_form.controls['description'].invalid}"
						class="w-100 form-control unlocked-border"
						formControlName="description"
						placeholder="Tell us about your company."
						rows="8"
					></textarea>
				</div>

				<!-- AVIA ADDRESS -->
				<!-- street -->
				<div class="form-group col-12 col-md-6" formGroupName="hq_addr">
					<div class="font-weight-bold text-left pb-1">Address</div>
					<input id="street"
						class="form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['hq_addr']['controls']['street'].touched && sc_form.controls['hq_addr']['controls']['street'].invalid}"
						formControlName="street"
						placeholder="Street address"
						type="text"/>
						<small *ngIf="sc_form.touched && sc_form.controls['hq_addr']['controls']['street'].touched && sc_form.controls['hq_addr']['controls']['street'].invalid">
							<i class="font-italic text-danger">Requres at least 2 characters
						</i></small>
				</div>

				<!-- city -->
				<div class="form-group col-12 col-md-6" formGroupName="hq_addr">
					<div class="font-weight-bold text-left pb-1">City</div>
					<input id="city"
						class="form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['hq_addr']['controls']['city'].touched && sc_form.controls['hq_addr']['controls']['city'].invalid}"
						formControlName="city"
						placeholder="City"
						type="text"/>
						<small *ngIf="sc_form.touched && sc_form.controls['hq_addr']['controls']['city'].touched && sc_form.controls['hq_addr']['controls']['city'].invalid">
							<i class="font-italic text-danger">Requres at least 2 characters
						</i></small>
				</div>

				<div class="form-group col-6" formGroupName="hq_addr">
					<div class="font-weight-bold pb-1">State</div>
					<input id="state"
						class="form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['hq_addr']['controls']['state'].touched && sc_form.controls['hq_addr']['controls']['state'].invalid}"
						formControlName="state"
						placeholder="State Code"
						type="text"/>
					<small *ngIf="sc_form.touched && sc_form.controls['hq_addr']['controls']['state'].touched && sc_form.controls['hq_addr']['controls']['state'].invalid">
						<i class="font-italic text-danger">Two-letter US state abbreviation
					</i></small>
				</div>

				<div class="form-group col-6" formGroupName="hq_addr">
					<div class="font-weight-bold pb-1">Postal Code</div>
					<input id="postalCode"
						class="form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['hq_addr']['controls']['postalCode'].touched && sc_form.controls['hq_addr']['controls']['postalCode'].invalid}"
						formControlName="postalCode"
						placeholder="US Postal code"
						type="text"/>
					<small *ngIf="sc_form.touched && sc_form.controls['hq_addr']['controls']['postalCode'].touched && sc_form.controls['hq_addr']['controls']['postalCode'].invalid">
						<i class="font-italic text-danger">6 or 9 digit US postal code
					</i></small>
				</div>

				<!-- Email -->
				<!-- <div class="col-12 pb-4">
					<div class="font-weight-bold pb-1">Company Email
						<span class="float-right">
						</span>
					</div>
					<input id="email"
						class="form-control"
						[ngClass]="{'is-invalid':sc_form.controls['email']?.invalid}"
						formControlName="email"
						placeholder="info@yourcompany.com"
						type="email"
					/>
				</div> -->

				<!-- LinkedIn -->
				<div class="form-group col-12 col-md-6">
					<div class="font-weight-bold text-left">LinkedIn</div>
					<input
						class="form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['url_linkedin'].touched && sc_form.controls['url_linkedin'].invalid}"
						formControlName="url_linkedin"
						type="text"/>
					<small *ngIf="sc_form.touched && sc_form.controls['url_linkedin'].touched && sc_form.controls['url_linkedin'].invalid">
						<i class="font-italic text-danger">Enter a valid LinkedIn URL e.g. https://www.mycomany.com/company
					</i></small>
				</div>

				<!-- Twitter -->
				<div class="form-group col-12 col-md-6">
					<div class="font-weight-bold text-left">Twitter</div>
					<input
						class="form-control"
						[ngClass]="{'is-invalid': sc_form.touched && sc_form.controls['url_twitter'].touched && sc_form.controls['url_twitter'].invalid}"
						formControlName="url_twitter"
						type="text"/>
					<small *ngIf="sc_form.touched && sc_form.controls['url_twitter'].touched && sc_form.controls['url_twitter'].invalid">
						<i class="font-italic text-danger">Enter a valid Twitter URL e.g. https://www.twitter.com/company
					</i></small>
				</div>
			</div>


		</div>

	</div>
</form>

<!-- DEBUGGING DISPLAY (sc_form) -->
<span *ngIf="false">
	<!-- Display the form values and state during development -->
	<p>Form value: {{ sc_form.value | json }}</p>
	<p>Form status: {{ sc_form.status | json }}</p>
	<p>Description: {{ sc_form.controls['description']</p>
	<p class="mb-6">Pristine: <span class="text-uppercase">{{ sc_form.pristine }}</span></p>
</span>
