<!-- (keydown.enter)="returnSubmit()" -->
<div class="wrapper">
	<div class="w-100 h-100">
		<div class="input-box">
			<div [ngClass]="{'input-box-lg': text_box_size === 'lg'}">
				<quill-editor
					#new_reply
					class="w-100"
					theme="snow"
					[placeholder]="placeholder"
					[modules]="modules"
					[formats]="formats"
					[sanitize]="true"
					(onFocus)="onQuillFocus($event)"
					[(ngModel)]="content"
					>
					<!-- (keydown.enter)="captureReturn($event)" -->
				</quill-editor>
			</div>
			<div *ngIf="(media || url) && media_preview" class="mt-2">
				<post-media #media_component [media]="media" [edit]="true" [url]="url"></post-media>
			</div>
			<div class="d-flex justify-content-between add_on_ops pt-2">
				<div *ngIf="tags?.length < 1 && show_buttons" class="d-flex align-items-center add-tags-text">
					<div *ngIf="!fs.aviaService.mobile_mode && !hide_verbose_tags" class="d-flex">Add tags so more people see this</div>
					<button (click)="addTag()" class="btn post-tags d-flex align-items-center p-1 bg-gray-100 font-size-12 rounded text-blue-light font-weight-bold">
						<span class="material-icons pointer" style="font-size: 14px;right: 0;">
							add
						</span>
						Add Tags
					</button>
				</div>
				<div *ngIf='tags' class="d-flex pt-2 flex-wrap">
					<ng-container *ngFor="let tag of tags">
						<div class="post-tags d-flex align-items-center mr-1 mb-1 p-1 bg-gray-100 font-size-12 rounded text-blue-light font-weight-bold text-truncate">
							@{{tag.name}}
							<span class="material-icons pointer" style="font-size: 14px;right: 0;" (click)="removeTag(tag)">
								close
							</span>
						</div>
					</ng-container>
				</div>
				<div *ngIf='show_buttons' class="d-flex align-items-center">
					<app-post-textarea-link-modal (create)="addLink($event)" (open)="_showAddLink = true;" (close)="_showAddLink = false;">
						<button class="btn p-0 post-buttons">
							<span class="material-icons text-gray-400">
								link
								</span>
						</button>
					</app-post-textarea-link-modal>
					<button (click)="showEmojiMart()" class="btn p-0 post-buttons">
						<span class="material-icons text-gray-400">
							mood
							</span>
					</button>
					<emoji-mart (blur)="showEmojiMart()" (emojiSelect)="selectEmoji($event)" *ngIf="_showEmojiMart" [emoji]="''" [title]="'Choose an emoji'" [autoFocus]="true" [isNative]="true"
					class="position-absolute right-0 top-0 get-on-top"></emoji-mart>
					<button *ngIf="send_button" (click)="submitText()" class="btn p-0 post-buttons">
						<span class="material-icons" [ngClass]="{'text-gray-400':value?.text?.length < 1, 'text-blue-light':value?.text?.length > 0}">
							send
							</span>
					</button>
				</div>
			</div>
		</div>
		<app-post-recommendations
			*ngIf="tags.length > 0 || fs?.config?.create?.tags?.length > 0"
			[current_tags]="tags"
			[current_root_tags]="fs?.config?.create?.tags"
			(addTag)="addTagFromRec($event)"
		></app-post-recommendations>
	</div>
</div>
