
<div class="control">
  <div class="item pointer" *ngFor="let d of data" (click)="click( d.id )">
    <div class="over1" *ngIf="d.selected"><i class="selection-circle material-icons">lens</i></div>
    <div class="over1" *ngIf="!d.selected">&nbsp;</div>
    <div class="over2" *ngIf="d.selected"></div>
    <div class="over2" *ngIf="!d.selected"></div>
    <div class="mid"><div *ngIf="!d.selected" class="text">{{d.id}}</div><div *ngIf="d.selected" class="textbold">{{d.id}}</div></div>
    <div class="under"><div class="text">{{d.txt}}</div></div>
  </div>
</div>
