import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ContentObserver } from '@angular/cdk/observers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-avia-masonry',
  templateUrl: './avia-masonry.component.html',
  styleUrls: ['./avia-masonry.component.scss']
})
export class AviaMasonryComponent implements OnInit {

  @ViewChild('wall', { static: true }) wallContainer: any;
  @HostListener('window:resize', []) onResize(){
    this.computeSizeCategory();
    this.setNumCols();
    this.order();
  }

  private _options: Avia_Masonry_Options = new Avia_Masonry_Options();
  @Input() // options
    set options( inputData: Avia_Masonry_Options ) {
      this._options = new Avia_Masonry_Options();
      for (let i in inputData) {
        this._options[i] = inputData[i];
      }
    };
    get options(): Avia_Masonry_Options {
      return this._options;
    };

  columns = [];
  current_num_cols = 1;
  dataChanges:Subscription = null;
  extra_long = 25; // to ease wrapping
  size = 'xs';
  column_pad = 14; // pixels between columns
  image_listeners = [];

  constructor(private obs: ContentObserver) { }

  async ngOnInit() {
    await this.build();
  }

  ngAfterViewInit() {
    this.dataChanges = this.obs.observe(this.wallContainer.nativeElement)
      .subscribe(async(event: MutationRecord[]) => {
      //rebuild if the ng-content changes
      await this.build();
    });

    let images = this.wallContainer.nativeElement.querySelectorAll("img");
    let images_promises = [];
    for(let image of images) {
      if(image.complete == false && image.naturalHeight == 0) {
        images_promises.push(new Promise((resolve, reject)=>{
          let handler = function(event) {
            this.removeEventListener('load', handler, false);
            return resolve(true);
          }
          let target = image;
          let event = 'load';
          target.addEventListener('load', handler);
          this.image_listeners.push({
            'target': target,
            'event': event,
            'handler': handler
          })
        }))
      }
    }

    Promise.all(images_promises).then(async()=>{
      //images all loaded
      return await this.build();
    })
  }

  ngOnDestroy(){
    this.dataChanges.unsubscribe();

    for(let listener of this.image_listeners) {
      listener.target.removeEventListener(listener.event, listener.handler, false);
    }
  }

  initializeColumnBreakpoints() {
    let running_num_cols = this._options.num_cols || 1;
    if(this._options.num_cols_sm > 0){
      running_num_cols = this._options.num_cols_sm;
    } else {
      this._options.num_cols_sm = running_num_cols;
    }
    if(this._options.num_cols_md > 0){
      running_num_cols = this._options.num_cols_md;
    } else {
      this._options.num_cols_md = running_num_cols;
    }
    if(this._options.num_cols_lg > 0){
      running_num_cols = this._options.num_cols_lg;
    } else {
      this._options.num_cols_lg = running_num_cols;
    }
    if(this._options.num_cols_xl <= 0){
      this._options.num_cols_xl = running_num_cols;
    }
  }

  computeSizeCategory() {
    let width = parseInt(window.getComputedStyle(this.wallContainer.nativeElement).width);
    if(width<576){
      this.size = 'xs';
    } else if(width<768){
      this.size = 'sm';
    } else if(width<992){
      this.size = 'md';
    } else if(width<1200){
      this.size = 'lg';
    } else {
      this.size = 'xl';
    }
  }

  setNumCols() {
    switch(this.size){
      case 'xs':
        this.current_num_cols = this._options.num_cols;
        break;
      case 'sm':
        this.current_num_cols = this._options.num_cols_sm;
        break;
      case 'md':
        this.current_num_cols = this._options.num_cols_md;
        break;
      case 'lg':
        this.current_num_cols = this._options.num_cols_lg;
        break;
      case 'xl':
        this.current_num_cols = this._options.num_cols_xl;
        break;
      default:
        this.current_num_cols = 1;
    }
  }

  order() {
    this.wallContainer.nativeElement.style.maxHeight = null;
    this.wallContainer.nativeElement.style.minHeight = null;

    // Initialize items and remove padding from any previous ordering
    let masonry_items = this.wallContainer.nativeElement.children;
    for(let i=0; i<masonry_items.length; i++){
      masonry_items[i].style.paddingBottom = "0px";
      // masonry_items[i].style.overflow = "hidden";
    }

    if(this.current_num_cols > masonry_items.length){
      if(masonry_items.length > 1){
        this.current_num_cols = masonry_items.length;
      } else {
        this.current_num_cols = 2;
      }
    }

    // Initialize columns
    this.columns = [];
    for(let i=0; i<this.current_num_cols; i++){
      this.columns[i] = {
        tallness: 0,
        elements: []
      }
    }

    // Prepare width
    let wallWidth = parseInt(window.getComputedStyle(this.wallContainer.nativeElement).width);
    let fakeOneHundred = 100*(wallWidth+this.column_pad)/wallWidth;
    let fractionalWidth = (fakeOneHundred / this.current_num_cols) + "%";

    // Assign items to columns
    for(let i=0; i<masonry_items.length; i++){
      // Find which column is shortest (adjusted for left-preference)
      let preferred_column = 0;
      for(let j=1; j<this.current_num_cols; j++){
        if((((this.columns[j].tallness+this._options.left_priority_px) < this.columns[j-1].tallness)
          && ((this.columns[j].tallness+this._options.left_priority_px) < this.columns[preferred_column].tallness))
          || (this.columns[j].tallness < this.columns[j-1].tallness && (i+1) == masonry_items.length)){
          preferred_column = j;
        }
      }

      // Put item into selected column and give it width
      masonry_items[i].style.order = preferred_column;
      masonry_items[i].style.width = fractionalWidth;
      masonry_items[i].style.paddingRight = this.column_pad + "px";

      // Update columns data
      let item_height = parseInt(window.getComputedStyle(masonry_items[i]).height) +
                        parseInt(window.getComputedStyle(masonry_items[i]).marginBottom) +
                        parseInt(window.getComputedStyle(masonry_items[i]).marginTop);
      this.columns[preferred_column].tallness += item_height;
      this.columns[preferred_column].elements.push(i);
    }

    // Set height of container
    let col_effective_tallnesses = this.columns.map((c) => c.tallness);
    let longcat = Math.max(...col_effective_tallnesses);
    this.wallContainer.nativeElement.style.minHeight = longcat+"px";
    if(this.current_num_cols > 1){
      this.wallContainer.nativeElement.style.maxHeight = (longcat+this.extra_long)+"px";
    }

    // Add margins to shorter columns to prevent awkward wrapping
    for(let i=0; i<this.current_num_cols; i++){
      let this_col = this.columns[i];
      if(this_col.tallness < longcat){
        let this_col_last_item = this_col.elements.slice(-1)[0];
        if(this_col_last_item){
          masonry_items[this_col_last_item].style.paddingBottom = (longcat - this_col.tallness) + "px";
        }
      }
    }
  }

  async build() {
    await this.initializeColumnBreakpoints();
    await this.computeSizeCategory();
    await this.setNumCols();
    this.order();
  }
}

export class Avia_Masonry_Options {
  constructor(
    public left_priority_px:  number = 0,
    public num_cols:          number = 1,
    public num_cols_sm:       number = 0,
    public num_cols_md:       number = 0,
    public num_cols_lg:       number = 0,
    public num_cols_xl:       number = 0
  ) {  }
}
