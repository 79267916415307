import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessagesService } from '../../messages.service';
import { AVIAConnectService } from '../../../avia-connect.service';


@Component({
  selector: 'app-discussion-list',
  templateUrl: './discussion-list.component.html',
  styleUrls: ['./discussion-list.component.scss'],
})
export class DiscussionListComponent implements OnInit {
  @Input() discussions;
  @Input() as_node_ids;
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  constructor( public sm: MessagesService, public aviaService: AVIAConnectService ) { }

  ngOnInit() {
    if(!this.aviaService.mobile_mode && !this.sm.loadCommentGroupId && !this.sm.selectedDiscussion && this.discussions.length > 0 && !this.as_node_ids) this.selectDiscussion(this.discussions[0]);
  }
  getOtherParticipant(d) {
    let user_id = d.user_id;
    let session = this.aviaService.session;
    if(!session) {
      return {id:0,avatar:null}
    }
    if((d.user_id == null || d.user_id == session.user.id) && d.other_participants && d.other_participants.length > 0) user_id = d.other_participants[0].id;
    for(let p of d.other_participants) {
      if(p.id !== user_id) {
        return p;
      }
    }
  }

  getParticipantNames(discussion) {
    if(discussion.name) {
      return discussion.name;
    }

    let participantNames = [];
    if(discussion.other_participants.length === 1) {
      return discussion.other_participants[0].firstname + " " + discussion.other_participants[0].lastname;
    }
    let session = this.aviaService.session;
    discussion.other_participants.forEach((p)=>{
      if(p.id !== session.user.id) {
        participantNames.push(p.firstname);
      }
    })
    return participantNames.join(", ");
  }

  getLastComment(d) {
    let session = this.aviaService.session;
    if(d.user_id && session.user && d.user_id == session.user.id) {
      return `You: ${d.content}`
    }

    if(d.user_id) {
      return `${d.firstname}: ${d.content}`
    }
  }

  selectDiscussion(d) {
    this.select.emit(d);
  }
}
