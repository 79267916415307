import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Common as c } from '../../../common';
import { AccessKey, AccessKeychain_Solco, E_Modal_Operating_Mode } from '../../../../class';


@Component({
  //selector: 'app-sc-edit',
  selector: 'app-sc-add-edit-import-modal',
  templateUrl: './sc-add-edit-import-modal.component.html',
  styleUrls: ['./sc-add-edit-import-modal.component.scss']
})
export class ScAddEditImportModal implements OnInit {
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template
  readonly VERBOSE = false;

  @Input() akey:                  AccessKeychain_Solco;
  @Input() data:                  any;
  @Input() data_fallback:         any;
  @Input() //fullscreen
    get fullscreen():             boolean { return this._fullscreen }
    set fullscreen( value:        boolean ) { this._fullscreen = value; }
    _fullscreen:                  boolean = false;
  @Input() //hide_activate_btn
    get hide_activate_btn():      boolean { return this._hide_activate_btn; }
    set hide_activate_btn( value: boolean ) { this._hide_activate_btn = value; }
    _hide_activate_btn:           boolean = false;
  @Input() mode:                  number;
  @Input() title_text:            string;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save:   EventEmitter<any> = new EventEmitter();

  // FORM CONTROLS
  solco_form: FormGroup;

  // MODAL STUFF
  @ViewChild('ScAddEditImport', { static: true }) content: NgbModal;
  modal_ref:                             NgbModalRef;

  // Fallback data display
  fallback_flags: Object = {};

  /*
  TESTING
  http://localhost:4200/sc/cbsearch?add=a367b036-5952-5435-7541-ad7ee8869e24
  */

  constructor( private fb: FormBuilder, private modalService: NgbModal ) {
    this.createForm();
  };


  // === INIT ===

  createForm() {
    this.solco_form = this.fb.group({
      name:                   ['', [ Validators.required, Validators.pattern( c.REGEX_NAME ) ] ],
      crunchbase_id:          '',
      crunchbase_uuid:        ['', [ Validators.pattern( /[A-Z0-9\-]{32}/i ) ] ],
      description:            '',
      headquarters_locations: '',
      phonenum:               ['', [ Validators.pattern(/[0-9]{10,11}/)]],
      email:                  ['',[ Validators.pattern(c.REGEX_EMAIL)] ],
      url_crunchbase:         '',
      url_linkedin:           ['', [ Validators.pattern( /^https?:\/\/(www\.)?linkedin.com\/company\/[\w\-.]+\/?$/i ) ] ],
      //url_news:               ['', [ Validators.pattern( c.REGEX_WEBSITE ) ] ],
      url_twitter:            ['', [ Validators.pattern( /^https?:\/\/(www\.)?twitter.com\/[\w\-.]+\/?$/i ) ] ],
      web_addr:               ['', [ Validators.pattern( c.REGEX_WEBSITE ) ] ],
      hq_addr: this.fb.group({
        city:       ['', [ Validators.pattern( c.REGEX_NAME ) ] ],
        state:      ['', [ Validators.pattern( /\b[A-Z]{2}\b/i ) ] ],
        street:     ['', [ Validators.pattern( c.REGEX_NAME ) ] ],
        postalCode: ['', [ Validators.pattern( /^[0-9]{5}(-[0-9]{4})?$/ ) ] ],
      })
    });
  };

  setupFallbackData() {
    // Prep the fallback data for easier testing in the View
    if (this.data_fallback === undefined) return;
    for (const key of Object.keys(this.data_fallback)) {
      let value = this.data_fallback[key];
      if (value === undefined || value === null || value === '') this.data_fallback[key] = null;
      this.VERBOSE && console.log('data_fallback:', key, '=', this.data_fallback[key]);
    }

    if (this.data['crunchbase_id'] === undefined || this.data['crunchbase_id'] === null || this.data['crunchbase_id'] === ''){
      if (this.data_fallback['id'] !== null) {
        this.fallback_flags['crunchbase_id'] = true;
      }
    }
    if (this.data['description'] === undefined || this.data['description'] === null || this.data['description'] === ''){
      if (this.data_fallback['company_description'] !== null) {
        this.fallback_flags['description'] = true;
      }
    }
    if (this.data['name'] === undefined || this.data['name'] === null || this.data[''] === 'name'){
      if (this.data_fallback['company_name'] !== null) {
        this.fallback_flags['name'] = true;
      }
    }
    if (this.data['url_linkedin'] === undefined || this.data['url_linkedin'] === null || this.data['url_linkedin'] === ''){
      if (this.data_fallback['linkedin'] !== null) {
        this.fallback_flags['url_linkedin'] = true;
      }
    }
    if (this.data['url_twitter'] === undefined || this.data['url_twitter'] === null || this.data['url_twitter'] === ''){
      if (this.data_fallback['twitter'] !== null) {
        this.fallback_flags['url_twitter'] = true;
      }
    }
    if (this.data['web_addr'] === undefined || this.data['web_addr'] === null || this.data['web_addr'] === ''){
      if (this.data_fallback['website'] !== null) {
        this.fallback_flags['web_addr'] = true;
      }
    }
  };

  setupFormData() {
    this.VERBOSE && console.log('sc-edit::setupFormData operating mode = ', E_Modal_Operating_Mode.getPropName(this.mode) );
    let fields = {};

    // Populate the Fields using the primary data
    let avia_fields = {
      crunchbase_id: this.data['crunchbase_id'] ? this.data['crunchbase_id']                 : '',
      description:   this.data['description']   ? this.data['description']                   : '',
      email:         this.data['email']         ? this.data['email']                         : '',
      name:          this.data['name']          ? this.data['name']                          : '',
      phonenum:      this.data['phonenum']      ? this.data['phonenum']                      : '',
      url_linkedin:  this.data['url_linkedin']  ? this.data['url_linkedin']                  : '',
      url_twitter:   this.data['url_twitter']   ? this.data['url_twitter']                   : '',
      web_addr:      this.data['web_addr']      ? this.data['web_addr'].replace( /\/$/, '' ) : '',
    };

    let cb_import_fields = {
      crunchbase_id: this.data['id']                  ? this.data['id']                           : '',
      description:   this.data['company_description'] ? this.data['company_description']          : '',
      email:         this.data['email']               ? this.data['email']                        : '',
      name:          this.data['company_name']        ? this.data['company_name']                 : '',
      phonenum:      this.data['phonenum']            ? this.data['phonenum']                     : '',
      url_linkedin:  this.data['linkedin']            ? this.data['linkedin']                     : '',
      url_twitter:   this.data['twitter']             ? this.data['twitter']                      : '',
      web_addr:      this.data['website']             ? this.data['website'].replace( /\/$/, '' ) : '',
    };

    let common_fields = {
      crunchbase_uuid:        this.data['uuid']                   ? this.data['uuid']                   : '',
      headquarters_locations: this.data['headquarters_locations'] ? this.data['headquarters_locations'] : '',
      url_crunchbase:         this.data['crunchbase']             ? this.data['crunchbase']             : '',
      //url_news:               this.data['news']                   ? this.data['news']                   : '',
      hq_addr: {
        city:       this.data['hq_addr'] && this.data.hq_addr['city']       ? this.data.hq_addr.city       : '',
        state:      this.data['hq_addr'] && this.data.hq_addr['state']      ? this.data.hq_addr.state      : '',
        street:     this.data['hq_addr'] && this.data.hq_addr['street']     ? this.data.hq_addr.street     : '',
        postalCode: this.data['hq_addr'] && this.data.hq_addr['postalCode'] ? this.data.hq_addr.postalCode : ''
      },
    };
    if (common_fields.headquarters_locations === '') {
      if (
        this.data_fallback !== undefined &&
        this.data_fallback['headquarters_locations'] !== undefined &&
        this.data_fallback['headquarters_locations'] !== null
      ) {
        common_fields.headquarters_locations = this.data_fallback['headquarters_locations'];
      };
    };

    switch (this.mode) {
      case E_Modal_Operating_Mode.ADD:
      case E_Modal_Operating_Mode.EDIT:
        fields = Object.assign(fields, common_fields, avia_fields);
      break

      case E_Modal_Operating_Mode.IMPORT:
        fields = Object.assign(fields, common_fields, cb_import_fields);
      break;

      default: return;
    }

    this.VERBOSE && console.log('****** FIELDS', fields);
    this.solco_form.setValue( fields );
  };

  ngOnInit() {
    this.VERBOSE && console.log('mode =', E_Modal_Operating_Mode.getPropName(this.mode));

    // Allow all access by default because not all instances of the Modal have perms
    if (!this.akey) {
      let all_perms: AccessKey = new AccessKey(true, true, true, true, true);
      this.akey = new AccessKeychain_Solco( all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms, all_perms );
      this.VERBOSE && console.log(`INITIALIZING akey to all TRUE`, this.akey);
    }
  };


  // === DATA HANDLING ===

  onSubmit() {
    // Process the crunchbase_id
    let crunchbase_id = parseInt(this.solco_form.get('crunchbase_id').value);
    crunchbase_id = isNaN( crunchbase_id ) ? null : crunchbase_id;

    // Process the address
    let hq_addr = this.solco_form.get('hq_addr');
    hq_addr.patchValue({ 'city':   hq_addr.get('city').value.trim() });         // Remove leading and trailing spaces
    hq_addr.patchValue({ 'state':  hq_addr.get('state').value.toUpperCase() }); // Force data to Uppercase
    hq_addr.patchValue({ 'street': hq_addr.get('street').value.trim() });       // Remove leading and trailing spaces

    // Apply the patches
    this.solco_form.patchValue({ 'crunchbase_id': crunchbase_id });                            // Needs to be an Int for our backend
    this.solco_form.patchValue({ 'name':          this.solco_form.get('name').value.trim() }); // Remove leading and trailing spaces

    this.save.emit( this.solco_form.value );
  };

  revert() {
    this.setupFormData();
  };


  // === MODAL STUFF ===

  close() {
    this.cancel.emit();
    this.modal_ref.close();
  };

  // NOTE: You can either set the Inputs 'data' and 'data_fallback' first, then call this method
  // OR, simply supply them with the calling of this method
  open( data = this.data, data_fallback = this.data_fallback ) {
    this.data = data;
    this.data_fallback = data_fallback;

    let options: NgbModalOptions = {
      'backdrop': 'static',
      size: 'lg',
    };
    if (this._fullscreen) options['windowClass'] = 'avia-modal-fullscreen';

    this.modal_ref = this.modalService.open( this.content, options );
    this.setupFallbackData();
    this.setupFormData();
  };

};
