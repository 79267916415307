import { Component, OnInit, Input } from '@angular/core';

import { Router } from '@angular/router';

// AVIA NoResults
// @example <app-avia-no-results>No Results</app-avia-no-results>
@Component({
  selector: 'app-avia-no-results',
  templateUrl: './avia-no-results.component.html',
  styleUrls: ['./avia-no-results.component.scss']
})
export class AviaNoResultsComponent implements OnInit {
  @Input() img_url: string = '/assets/circle-icons/search.jpg';
  @Input() suggestion;
  constructor(private router: Router) {
  }

  ngOnInit() {
  }
  searchSuggestion() {
    let url = new URL(location.href);
    url.searchParams.set("term",this.suggestion.text);
    location.href = url.href;
  }
}
