import { EventEmitter, Component, OnInit, Input, Output } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'comment-react',
  templateUrl: './comment-react.component.html',
  styleUrls: ['./comment-react.component.scss']
})
export class CommentReactComponent implements OnInit {
  @Input() show_help_text: boolean = false;
  @Input() like_text: string = 'Like';
  @Input() liked: boolean = false;
  @Input() reply_text: string = 'Reply';
  @Input()
  set modified_date(data: any) { this._modified_date = moment(data).fromNow(); ; }
  get modified_date()          { return this._modified_date; }
  _modified_date: any;
  @Output() likeClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() unlikeClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output() replyClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  likeButtonClicked() {
    if(!this.liked) {
      this.likeClicked.emit()
    }
    else {
      this.unlikeClicked.emit()
    }
  }

}
