import { User, Session, AnalyticEvent } from './../../../../class';
import { Component, OnInit } from '@angular/core';
import { AVIAConnectService } from 'src/app/avia-connect.service';
import { pullAllBy } from 'lodash';
@Component({
  selector: 'app-suggested-connections',
  templateUrl: './suggested-connections.component.html',
  styleUrls: ['./suggested-connections.component.scss']
})
export class SuggestedConnectionsComponent implements OnInit {
  suggested_connections: User[] = [];
  user: User;
  session: Session;

  constructor(public aviaService: AVIAConnectService) { }

  async ngOnInit() {
    this.session = await this.aviaService.getSessionSupport(true);
    this.user = this.session.user;

    let topics;
    if(this.user.interests_topics && this.user.interests_topics.length > 0){
      topics =  this.user.interests_topics
    } else {
      let topics_res = await this.aviaService.getKMCardsPopular()
      topics = topics_res.topic_ids
    }
    let topics_users_res = await this.aviaService.getTopicsUsers({topic_ids: topics, limit: 50});
    if (topics_users_res.status == 200 && topics_users_res.body && topics_users_res.body.users) {
      this.suggested_connections = topics_users_res.body.users;
      this.suggested_connections = pullAllBy(this.suggested_connections, [{...this.user, 'id': this.user.id}], 'id')
    }
  }

  userMessageButton(u) {
    let obj = new AnalyticEvent(
      'suggested_connection_row_message_button',
      {user_id: u.id}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

  userConnectButton(u) {
    let obj = new AnalyticEvent(
      'suggested_connection_row_connect_button',
      {user_id: u.id}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

}
