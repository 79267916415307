import { Component } from '@angular/core';


@Component({
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  //styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent {

  constructor() {}

}
