<div class="cb-search container-fluid h-100 pt-4 px-4 pb-sm-4">
	<div class="row mb-4">
		<div class="col-12 col-sm-11">
			<div class="cb-label mb-4 text-center text-sm-left">Search for companies in Crunchbase</div>
			<app-avia-search #search_bar
				[options]="searchbar_options"
				[searching]="searching"
				[term]="term"
				(enter_pressed)="termSubmitted($event)"
				(update_search)="updateSearch($event)">
			</app-avia-search>
		</div>
	</div>

	<div *ngIf="searching"><app-avia-loader [options]="{type: 'generic'}"></app-avia-loader></div>
	<div *ngIf="term !== '' && (search_results | async)" class="results-container w-100">
		<span *ngIf="!searching" class="border border-left-0 border-right-0 border-top-0 d-flex justify-content-between mt-sm-4 pb-3 pb-sm-2 row no-gutters">
			<div class="cb-label col-12 col-sm-6 mb-3 mb-sm-0 mt-sm-1 text-center text-sm-left">Crunchbase search results ({{count_total}})</div>
			<div class="col-12 col-sm-6 d-flex justify-content-end">
				<span class="cb-label mr-2 mt-sm-1 text-right">
					<img class="tiny-avatar-img mr-1" src="../../assets/crunchbase-grey.svg" onerror="this.src='/assets/placeholder-company.jpg'" />
					Still don't see what you're looking for?
				</span>
				<app-sc-add-edit-import-modal #ScAddModal
					[data]="new_solco_blank"
					[mode]="E_Modal_Operating_Mode.ADD"
					[title_text]="'Add a Company'"
					(save)="submitAddFromCrunchbase($event);"
				></app-sc-add-edit-import-modal>
			</div>
		</span>
		<div *ngIf="(!searching) && (no_results); else show_results" class="mt-4"><app-avia-no-results>No Results</app-avia-no-results></div>
		<!-- search results for crunchbase has data -->
		<ng-template #show_results>
			<app-users-orgs-list *ngIf="!searching" class="users-orgs-list d-block w-100"
				[data]="(search_results | async)"
				[reset_buffer]="data_reset"
				[route_path]="'/sc'"
				[search_end_reached]="search_end_reached"
				[show_cb_add]="true"
				[show_noresults]="true"
				[type]="'crunchbase'"
				(add_from_cb)="openAddFromCrunchbase($event)"
				(update_list)="updateSearch($event)"
				>
			</app-users-orgs-list>
		</ng-template>
	</div>
</div>

<app-sc-add-edit-import-modal #ScImportModal
	[hide_activate_btn]="true"
	[mode]="E_Modal_Operating_Mode.IMPORT"
	[title_text]="'Add from Crunchbase'"
	(save)="submitImportFromCrunchbase($event);"
></app-sc-add-edit-import-modal>
