import * as moment from 'moment';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { cloneDeep, uniq } from 'lodash';

import { AVIAConnectService } from '../../../../../../avia-connect.service';
import { Common } from '../../../../../../common';
import { CommonValidators } from '../../../../../../common-validators';

import {
  AccessKey,
  Content,
  KmSupport_IdName,
  Nav_Tab,
  Nav_Tab_Pills,
  OrgsSupport,
} from '../../../../../../../class';


@Component({
  selector: 'app-content-edit-modal',
  templateUrl: './content-edit-modal.component.html',
  styleUrls: ['./content-edit-modal.component.scss']
})

export class ContentEditModalComponent implements OnDestroy {
  private readonly VERBOSE: boolean = false;

  @Input() content_id: number;
  @Input() content_types: KmSupport_IdName;
  @Input() // membership_akey
    get membership_akey() { return this._membership_akey; }
    set membership_akey(data: AccessKey) {
      if (data == undefined) return;
      this._membership_akey = data;
      if(!this._membership_akey.w && this.fc_member_type_arr) {
        this.fc_member_type_arr.disable();
      }
    }
  _membership_akey: AccessKey = new AccessKey();

  @Input() card_akey: AccessKey = new AccessKey();

  @Output() onContentDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() onContentSubmitted: EventEmitter<any> = new EventEmitter();

  content: Content = new Content();
  editing: boolean = false;
  can_disable_org_content: boolean;

  // Form
  content_form: FormGroup;
  fc_description: AbstractControl;
  fc_file_name: AbstractControl;
  fc_groups: AbstractControl;
  fc_is_public: AbstractControl;
  fc_link: AbstractControl;
  fc_member_type_arr: AbstractControl;
  fc_name: AbstractControl;
  fc_org_type_arr: AbstractControl;
  fc_produced_by: AbstractControl;
  fc_programs: AbstractControl;
  fc_published_date: AbstractControl;
  fc_s3_filename: AbstractControl;
  fc_special_content: AbstractControl;
  fc_type: AbstractControl;


  // Tabs
  nav_bar: Nav_Tab_Pills;


  // File Upload
  error: string;
  file: any;
  file_uploaded: boolean = false;
  progress: number = 0.0;
  upload_started: boolean = false;
  current_upload_abort: () => any = undefined;


  // Membership, Org Types, Programs
  avia_member_types: any[] = [];
  avia_member_focus: any[] = [];
  avia_org_types: any[] = [];
  show_orgtypes: boolean = false;
  show_membership: boolean = false;
  show_programs: boolean = false;

  subsc_orgtypes: Subscription;
  subsc_specialcontent: Subscription;


  constructor(public aviaService: AVIAConnectService, private fb: FormBuilder) {
    this.nav_bar = new Nav_Tab_Pills([
      new Nav_Tab('Link', true),
      new Nav_Tab('Upload'),
    ]);

    this.createForm();
  }

  ngOnDestroy(): void {
    this.subsc_orgtypes.unsubscribe();
    delete this.subsc_orgtypes;

    this.subsc_specialcontent.unsubscribe();
    delete this.subsc_specialcontent;
  }

  async startEditing(): Promise<void> {
    let promises = [];
    if(this.membership_akey && this.membership_akey.w) {
      promises.push( this.getOrgTypes() );
      promises.push( this.getMemberFocus() );
      promises.push( this.getMemberTypes() );
    }
    if (this.card_akey && this.card_akey.w ) promises.push( this.getContent() );
    if (promises.length > 0) await Promise.all(promises);
    this.editing = true;
    this.setInitialActiveTab();
    this.setupFormData();
  }

  setInitialActiveTab(): void {
    if (this.content_form.get('s3_filename').value) {
      this.nav_bar.switchTab(1);
    } else {
      this.nav_bar.switchTab(0);
    }
  }

  resetSpecialContent(val: number): void {
    this.memberTypeArray.clear();
    this.fc_member_type_arr.setValidators([]);
    for(let t of this.avia_member_types) {
      this.memberTypeArray.push(this.fb.control(false));
    }
    this.fc_member_type_arr.markAsPristine();

    this.orgTypeArray.clear();
    this.fc_org_type_arr.setValidators([]);
    this.fc_is_public.patchValue( this.content.is_public ? true : false );
    for(let t of this.avia_org_types) {
      this.orgTypeArray.push(this.fb.control( this.fc_is_public.value ));
    }
    this.fc_org_type_arr.markAsPristine();

    this.fc_programs.patchValue([]);
    this.fc_programs.setValidators([]);
    this.fc_programs.markAsPristine();

    switch(val) {
      case 0:  // Org Types
        this.fc_org_type_arr.setValidators([CommonValidators.minLengthTruthyArray(1)]);
      break;

      case 1:  // Core
        this.fc_member_type_arr.setValidators([CommonValidators.minLengthTruthyArray(1)]);
      break;

      case 2:  // Programs and Strategic Initiatives
        this.fc_programs.setValidators([CommonValidators.minLengthArray(1)]);
      break;
    }

    this.fc_member_type_arr.updateValueAndValidity();
    this.fc_org_type_arr.updateValueAndValidity();
    this.fc_programs.updateValueAndValidity();
  }

  async getContent(): Promise<void> {
    let result = await this.aviaService.getContentById(this.content_id);
    if(result.status === 200) {
      this.content = result.body[0];
    }
  }

  async getOrgTypes(): Promise<void> {
    let result:OrgsSupport = await this.aviaService.getOrgsSupport();

    if(result && result.org_types) {
      this.avia_org_types = [];
      for (let ot of result.org_types) {
        if (ot.id !== 3) this.avia_org_types.push(ot);
      }
    }
  }

  async getMemberTypes(): Promise<void> {
    let result = await this.aviaService.getMemberTypes();
    if(result.status === 200) {
      this.avia_member_types = result.body;
    }
  }

  async getMemberFocus(): Promise<void> {
    let result = await this.aviaService.getMemberFocus();
    if(result.status === 200) {
      this.avia_member_focus = result.body;
    }
  }

  revertForm(): void {
    if (this.file_uploaded) {
      this.cancelFileUpload(this.file)
    }
    this.setupFormData();
  }

  updateContentType($event: any): void {
    let type = $event.type.selected && $event.type.selected.length && $event.type.selected[0] ? $event.type.selected[0].id : null;
    if (type) {
      this.fc_type.patchValue(type);
      this.fc_type.markAsDirty();
      this.fc_type.markAsTouched();
    }
  }

  setSpecialContentValue():number {
    let sc_val: number = null;

    if (this.fc_produced_by.value == 1){
      if ( this.fc_is_public.value || (this.content.org_types && this.content.org_types.length) ) {
        sc_val = 0;
      } else if (this.content.member_types && this.content.member_types.length) {
        sc_val = 1;
      } else if (this.content.member_focus && this.content.member_focus.length) {
        sc_val = 2;
      } else {
        sc_val = 1; // Default to 'Core Members' like we did before
      }
    }

    return sc_val;
  }

  updateProducedBy($event: any): void {
    this.fc_produced_by.patchValue( ($event.value) ? this.aviaService.session.org.id : null );
    this.fc_produced_by.markAsDirty();
    this.fc_produced_by.markAsTouched();

    if ( !$event.value ) this.fc_is_public.patchValue(true);

    this.fc_special_content.patchValue( this.setSpecialContentValue() );
  }

  async fileChosenFromBrowse(file: any): Promise<void> {
    this.file = file; //TODO: Is this really necessary? Can it be removed?
    this.file = await this.uploadfile(file);
    if (this.file.err) {
      return this.aviaService.notifyFailure(`Upload to S3 failed. \n${this.file.err}`);
    } else {
      this.content_form.patchValue({
        link: this.file.link,
        s3_filename: this.file.s3_filename,
        file_name: this.file.name
      });
      this.fc_link.markAsDirty();
      this.fc_link.markAsTouched();
      this.fc_s3_filename.markAsDirty();
      this.fc_s3_filename.markAsTouched();
      this.fc_file_name.markAsDirty();
      this.fc_file_name.markAsTouched();
    }
  }

  async uploadfile(file: any): Promise<any> {
    if (file == undefined) { this.error = "choose a file"; return; }

    this.progress = 0.0;
    let s3filename = Common.StrongerUUID();

    this.upload_started = true;

    const s3_upload = await this.aviaService.uploadToS3(
      s3filename,
      file.name,
      file,
      file.type,//'application/octet-stream',
      undefined,
      (s, p, err, abort) => {
        this.progress = (p / s);
        this.error = err;
        this.current_upload_abort = abort;
      });
    if (s3_upload && s3_upload.message == "success" && s3_upload.url && !!file) {
      this.file_uploaded = true;
      this.upload_started = false;
      file.link = s3_upload.url;
      file.s3_filename = s3_upload.file_name;
      return file;
    } else {
      this.error = "upload failed: " + s3_upload.info;
      return {err: this.error};
    }
  }

  async cancelFileUpload(file: any): Promise<void> {
    if(this.upload_started && !this.file_uploaded && this.current_upload_abort) {  // If we are in the middle of uploading a file (the xhr didn't finish on uploadToS3), abort
      this.current_upload_abort();
    }

    if(file && file.link) {
      if(this.progress) {
        await this.aviaService.undoUploadToS3(Common.justFilename(file.link));
      }
    }
  }

  async onSubmit(): Promise<void> {
    try {
      let update_obj = cloneDeep(this.content_form.value);
      for(let key in update_obj) {
        if(update_obj[key] === '') {
          update_obj[key] = null;
        }
      }

      if(update_obj.link) {
        update_obj.link = Common.safeHttp(update_obj.link);
      } else {
        update_obj['link'] = this.content.link;
      }

      if(update_obj.groups) {
        update_obj.group_ids = update_obj.groups.map(group => group.content);
        update_obj.group_ids = uniq(update_obj.group_ids);
        delete update_obj.groups;
      }

      if(update_obj.published_date) {
        update_obj.published_date = new Date( update_obj.published_date.replace(/-/g, '\/') );
      }

      // Don't send falsey to the backend for 'produced_by'
      if (!update_obj.produced_by) update_obj.produced_by = null;

      if (this.nav_bar.active_tab === 0) {
        update_obj['file_type'] = 'link';
      } else if (this.nav_bar.active_tab === 1) {
        update_obj['file_type'] = 'upload';
      }

      update_obj.is_public = (update_obj.is_public) ? 1 : 0;
      let focus_ids: number[] = [];
      let member_type_ids: number[] = [];
      let org_type_ids: number[] = [];
      if(this._membership_akey.w) {
        // We should update with an empty array if not AVIA produced OR update member types if we have selected type or strategic initiative

        // Connect User types, Member Types and Member Focus
        if (this.fc_produced_by.value === 1){
          switch (this.fc_special_content.value) {
            case 0:
              for (let i = 0; i < this.avia_org_types.length; i++) {
                if (this.orgTypeArray.controls[i].value === true) {
                  org_type_ids.push(this.avia_org_types[i].id);
                }
              }
              // If all the Org types are checked, we are considered public and there is no need to record all the org types
              if (org_type_ids.length === this.avia_org_types.length) {
                org_type_ids = [];
              }
            break;

            case 1:
              for(let i = 0; i < this.avia_member_types.length; i++) {
                if(this.memberTypeArray.controls[i].value === true) {
                  member_type_ids.push(this.avia_member_types[i].id);
                }
              }
            break;

            case 2:
              focus_ids = this.fc_programs.value.map(f => f.id);
            break;
          }
        }
      }

      await Promise.all([
        this.aviaService.setContentOrgType(this.content.id, {org_type_ids}),
        this.aviaService.setContentMemberType(this.content.id, {type_ids: member_type_ids}),
        this.aviaService.setContentMemberFocus(this.content.id, {focus_ids}),
      ]);

      let res = await this.aviaService.updateContent(this.content.id, update_obj);
      if(res.status == 200) {
        this.content = res.body.updated_item;
      }
      this.checkLink(this.content.link);
      this.VERBOSE && console.log("AviaContentCard::updateContent ", this.content);
      this.setupFormData();
      this.onContentSubmitted.emit();
    } catch (err) {
      console.error('ERROR - ContentEditModalComponent::onSubmit',err);
      this.aviaService.notifyFailure('Sorry, it looks like something went wrong and we could not update your content. Please contact an administrator for assistance.');
    }
  }

  switchSpecialContent(sc_val: number): void {
    this.show_membership = false;
    this.fc_member_type_arr.setValidators([]);

    this.show_orgtypes = false;
    this.fc_org_type_arr.setValidators([]);

    this.show_programs = false;
    this.fc_programs.setValidators([]);

    switch(sc_val) {
      case 0:  // Org Types
        this.show_orgtypes = true;
        this.fc_org_type_arr.setValidators( [CommonValidators.minLengthTruthyArray(1)] );
      break;

      case 1:  // Core Membership
        this.show_membership = true;
        this.fc_member_type_arr.setValidators( [CommonValidators.minLengthTruthyArray(1)] );
        this.fc_is_public.patchValue(false);
      break;

      case 2:  // Programs and Strategic Initiatives
        this.show_programs = true;
        this.fc_programs.setValidators( [CommonValidators.minLengthArray(1)] );
        this.fc_is_public.patchValue(false);
      break;
    }

    this.fc_member_type_arr.updateValueAndValidity();
    this.fc_org_type_arr.updateValueAndValidity();
    this.fc_programs.updateValueAndValidity();
  }

  createForm(): void {
    this.content_form = this.fb.group({
      name:              [null, [Validators.required]],
      file_name:         [null],
      s3_filename:       [null],
      link:              [null, [Validators.required]],
      author:            [null],
      published_date:    [null],
      produced_by:       0,
      description:       [null, [Validators.required]],
      type:              [null, [Validators.required]],
      groups:            [null, [Validators.required]],
      is_public:         true,
      special_content:   [null],
      member_type_array: this.fb.array([]),
      org_type_array:    this.fb.array([]),
      programs:          new FormControl([], { updateOn: "change" })
    });

    // Form Control (Abstract Control shortcuts)
    this.fc_description = this.content_form.controls.description;
    this.fc_groups = this.content_form.controls.groups;
    this.fc_is_public = this.content_form.controls.is_public;
    this.fc_link = this.content_form.controls.link;
    this.fc_member_type_arr = this.content_form.controls.member_type_array;
    this.fc_name = this.content_form.controls.name;
    this.fc_org_type_arr = this.content_form.controls.org_type_array;
    this.fc_produced_by = this.content_form.controls.produced_by;
    this.fc_programs = this.content_form.controls.programs;
    this.fc_special_content = this.content_form.controls.special_content;
    this.fc_type = this.content_form.controls.type;
    this.fc_s3_filename = this.content_form.controls.s3_filename;
    this.fc_file_name = this.content_form.controls.file_name;

    this.resetSpecialContent(this.fc_special_content.value);

    // Changes to Org Types check boxes
    this.subsc_orgtypes = this.fc_org_type_arr.valueChanges.subscribe(
      (val) => {
        if (this.fc_special_content.value === 0) {
          if (val.length === 0) {
            this.fc_is_public.patchValue(true);
          } else {
            let pub:boolean = true;
            for (let i of val) {
              if (!i) {
                pub = false;
                break;
              }
            }
            this.fc_is_public.patchValue(pub);
          }
        }
        this.VERBOSE && console.log('org types val: ', val, ' - is_public: ', this.fc_is_public.value);
      },
      (err) => { console.error('Edit Content Modal - "Org Types" Subscription Error: ', err); }
    );

    // Changes to Special Content radio buttons
    this.subsc_specialcontent = this.fc_special_content.valueChanges.subscribe(
      (val) => { this.switchSpecialContent(val); },
      (err) => { console.error('Edit Content Modal - "Special Content" Subscription Error: ', err) }
    );
  }

  get orgTypeArray(): FormArray {
    return this.fc_org_type_arr as FormArray;
  }

  get memberTypeArray(): FormArray {
    //return this.content_form.get('memberTypeArray') as FormArray;
    return this.fc_member_type_arr as FormArray;
  }

  setupFormData(): void {
    let fields = {
      name:              this.content.name,
      file_name:         this.content.file_name,
      s3_filename:       this.content.s3_filename,
      link:              this.content.link,
      author:            this.content.author,
      published_date:    this.content.published_date ? moment(this.content.published_date).format('YYYY-MM-DD') : null,
      produced_by:       this.content.produced_by,
      description:       this.content.description,
      type:              this.content.type_obj.id,
      groups:            this.content.groups,
      is_public:         (this.content.is_public) ? true : false,
      special_content:   null,
      org_type_array:    this.fb.array([]),
      member_type_array: this.fb.array([]),
      programs:          this.content.member_focus
    };

    // Initialize the Form
    this.content_form.reset(fields);

    // File and Link
    this.file = undefined;
    this.file_uploaded = false;
    this.progress = 0.0;
    this.upload_started = false;
    this.checkLink( this.fc_link.value );

    // Add back in Org type checkboxes form fields (having 'is_public' set to 1 is equivalent to having all of these checked)
    if ( this.avia_org_types && this.avia_org_types.length ) {
      this.orgTypeArray.clear();
      for(let t of this.avia_org_types) {
        if ( !this.content.org_types ) {
          this.orgTypeArray.push( this.fb.control( this.fc_is_public.value ) );
        } else {
          this.orgTypeArray.push( this.fb.control(this.content.org_types.includes(t['id'])) );
        }
      }
    }

    // Add back in member type checkboxes form fields
    if ( this.avia_member_types && this.avia_member_types.length ) {
      let membertype_ids = this.content.member_types ? this.content.member_types.map(type => type.id) : [];
      this.memberTypeArray.clear();
      for(let t of this.avia_member_types) {
        if ( !this.content.member_types ) {
          this.memberTypeArray.push( this.fb.control(false) );
        } else {
          this.memberTypeArray.push( this.fb.control(membertype_ids.includes(t['id'])) );
        }
      }
    }

    // Determine which Special Content list to show
    this.fc_special_content.patchValue( this.setSpecialContentValue() );

    this.content_form.markAsPristine();
    this.content_form.markAsUntouched();
  }

  // If link is uploaded or hosted on our S3 it must be a org content
  checkLink(link: string): void {
    let regex_1 = new RegExp(`^(https:\/\/aviaconnectcontent.s3.amazonaws.com\/)(.+)$`);
    let regex_2 = new RegExp(`^(https:\/\/s3-us-west-2.amazonaws.com\/aviaconnectcontent\/)(.+)$`);
    let regex_3 = new RegExp(`^(https:\/\/aviaconnectcontent-test.s3.amazonaws.com\/)(.+)$`);
    let regex_4 = new RegExp(`^(https:\/\/s3-us-west-2.amazonaws.com\/aviaconnectcontent-test\/)(.+)$`);

    if(regex_1.test(link) || regex_2.test(link) || regex_3.test(link) || regex_4.test(link)) {
      this.can_disable_org_content = false;
    } else {
      this.can_disable_org_content = true;
    }
  }

}
