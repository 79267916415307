import {
  EventEmitter,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

import { AVIAConnectService } from '../../avia-connect.service';


@Component({
  selector: 'app-server-down',
  templateUrl: './server-down.component.html',
  styleUrls: ['./server-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServerDownComponent implements OnInit, OnDestroy {
  static DEFAULT_TIME: number = 10;

  timeleft: number = ServerDownComponent.DEFAULT_TIME;
  timer:    any;
  VERBOSE:  boolean = false;

  @Input() color:        string;
  @Input() msg:          string = '';
  @Input() show_dismiss: boolean = false;
  @Input() type:         number; //<0 | 1 | 2>
  @Input() use_pointer:  boolean = false;
  @Input() use_timer:    boolean = false;

  @Output('timeout') timeout = new EventEmitter(); // called on timeout


  constructor( public aviaService: AVIAConnectService ) { }

  ngOnInit() {
    this.VERBOSE && console.log('ngOnInit');
    if (this.use_timer)
      this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(async () => {
      --this.timeleft;
      if(this.timeleft <= 0) {
        clearInterval(this.timer);
        await this.aviaService.serverDownQueueProcess();
        this.timeleft = ServerDownComponent.DEFAULT_TIME;
        this.startTimer();
        this.timeout.emit();
      }
    }, 1000);
  }

  tryNow() {
    this.timeleft = 0;
  }

  ngOnDestroy() {
    this.VERBOSE && console.log('ngOnDestroy');
    if (this.use_timer) {
      clearInterval(this.timer);
      delete this.timer;
    }
  }
}
