<ng-template [ngIf]="static_page === false" [ngIfElse]="CmAddEdit"><span class="d-none"></span></ng-template>

<ng-template #CmAddEdit class="ws-add-edit-modal d-block w-100">
	<div [ngClass]="{
			'avia-modal-full-screen avia-modal-full-screen-inverse': (fullscreen && static_page === false),
			'avia-modal': (!fullscreen && static_page === false)
		}"
		>
		<div class="page container-fluid bg-white">

			<div class="headerblock row" *ngIf="!static_page">
				<div class="backblock col-6 pl-0">
					<span class="backlink pointer" (click)="close()">
						<i class="material-icons close-icon md-24">close</i>
					</span>
				</div>
			</div>

			<div class="titleblock row my-4 text-center" *ngIf="!static_page">
				<div class="heading-size-24 col-12">Community</div>
			</div>

			<div class="editblock row mx-0" [ngClass]="{'justify-content-sm-center':!static_page}">
				<div class="col-12 col-sm-10 col-md-8 pl-2 pr-4 px-sm-0 px-1 px-sm-0">
					<form [formGroup]="cm_form" (ngSubmit)="onSubmit()">

						<!-- NAME -->
						<div class="form-group">
							<label for="name">Name</label>
							<input id="name" class="form-control"
								[ngClass]="{'is-invalid':!cm_form.pristine && cm_form.controls['name']?.invalid}"
								formControlName="name"
								placeholder="{{'Community Name'}}"
								type="text"
							>
							<small *ngIf="!cm_form.pristine && cm_form.controls['name']?.hasError('pattern')" class="form-text text-danger">
								'Name' must be at least 3 characters long and not start or end with a space.
							</small>
							<small *ngIf="!cm_form.pristine && cm_form.controls['name']?.hasError('required')" class="form-text text-danger">
								'Name' is required.
							</small>
						</div>

						<!-- LOGO TEXTS -->
						<div class="d-none d-md-flex row mb-4">
							<!-- Logo Text -->
							<div class="col-6">
								<div class="font-weight-bold pb-2">Logo</div>
								<div>Choose a square logo at least 500 x 500 pixels in size.</div>
							</div>
							<!-- White Logo Text -->
							<div class="col-6">
								<div class="font-weight-bold pb-2">White Logo</div>
								<div>Choose a white logo at least 500 pixels in height less than 1mb in size.</div>
							</div>
						</div>
						<!-- LOGOS -->
						<div class="row mb-4">
							<!-- Logo -->
							<div class="col-12 col-md-6 mb-4 mb-md-0">
								<div class="d-md-none font-weight-bold pb-2">Logo</div>
								<div class="d-block d-md-none pb-3">Choose a square logo at least 500 x 500 pixels in size.</div>
								<div class="d-flex align-items-end">
									<div class="my-0 position-relative">
										<img class="img-responsive logo-img rounded" [src]="cm_form.value.logo ? cm_form.value.logo : '/assets/placeholder-org.jpg'"/>
										<div class="pointer position-absolute px-0 remove-logo" (click)="removeLogoLink()">
											<i class="material-icons text-danger">remove_circle</i>
										</div>
									</div>
									<div class="btn btn-gray-200 ml-4" (click)='editing_logo = true'>Upload Image</div>
								</div>
								<avia-modal-fullscreen #imagecropmodal *ngIf='editing_logo'>
									<avia-image-crop-modal
										(submit)="onChangeLogo($event); imagecropmodal.close();"
										(cancel)="editing_logo = false; imagecropmodal.close();"
									></avia-image-crop-modal>
								</avia-modal-fullscreen>
							</div>
							<!-- White Logo -->
							<div class="col-12 col-md-6">
								<div class="d-md-none font-weight-bold pb-2">White Logo</div>
								<div class="d-block d-md-none pb-3">Choose a white logo at least 500 pixels in height less than 1mb in size.</div>
								<div class="d-flex align-items-end">
									<div class="my-0 position-relative">
										<img class="bg-black img-responsive logo-img rounded" [src]="cm_form.value.white_logo ? cm_form.value.white_logo : '/assets/placeholder-org.jpg'"/>
										<div class="pointer position-absolute px-0 remove-logo" (click)="removeWhiteLogoLink()">
											<i class="material-icons text-danger">remove_circle</i>
										</div>
									</div>
									<div [ngClass]="{'is-invalid':!cm_form.pristine && cm_form.controls['white_logo']?.invalid}">
										<input #fileInput id="white_logo" class="d-none" type="file" accept="image/*" (change)="fileChosenFromBrowse($event.target.files[0]); fileInput.value = null"/>
										<div class="btn btn-gray-200 ml-4" (click)='fileInput.click()'>Upload Image</div>
									</div>
								</div>
								<p class="mt-4">
									<ngb-progressbar *ngIf="file && progress > 0" type="success" [value]="progress * 100" [animated]="true">{{progress | percent}}</ngb-progressbar>
								</p>
								<div *ngIf="progress === 0 && file" class="progress mb-2">
									<div class="progress-bar progress-bar-striped progress-bar-animated bg-green-dark w-100" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100"></div>
								</div>
								<!-- Alternate Uploader using the Image Cropper (works with SVG's, but only if images are 1:1)
								<div class="d-flex align-items-end">
									<div class="my-0 position-relative">
										<img class="bg-black img-responsive logo-img rounded" [src]="cm_form.value.white_logo ? cm_form.value.white_logo : '/assets/placeholder-org.jpg'"/>
										<div class="px-0 pointer position-absolute remove-logo" (click)="removeWhiteLogoLink()">
											<i class="material-icons text-danger">remove_circle</i>
										</div>
									</div>
									<div class="btn btn-gray-200 ml-4" (click)='editing_white_logo = true'>Upload Image</div>
								</div>
								<avia-modal-fullscreen #imagecropmodalwhite *ngIf='editing_white_logo'>
									<avia-image-crop-modal
										(submit)="onChangeWhiteLogo($event); imagecropmodalwhite.close();"
										(cancel)="editing_white_logo = false; imagecropmodalwhite.close();"
									></avia-image-crop-modal>
								</avia-modal-fullscreen>
								-->
							</div>
						</div>

						<!-- COLOR -->
						<h6 class="line-row pb-3">Choose Brand Color</h6>
						<p>Please select your brand's color. This will be applied to the headers of your Dashboard and Profile.</p>
						<div>
							<input #colorPicker class="mr-2" type="color" id="brand" name="color" value="#{{cm_form.value.color}}" (input)="changeColor(colorPicker.value)"/>
							<label for="brand">Brand Color
								<span *ngIf="cm_form?.value.color">: #{{cm_form.value.color}}</span>
							</label>
						</div>

						<!-- VISIBILITY -->
						<div class="form-group">
							<label for="visibility">Visibility</label>
							<select id="visibility" class="form-control text-capitalize" formControlName="visibility">
								<option *ngFor="let type of visibility_types" [ngValue]="type.id">{{type.name}}</option>
							</select>
						</div>

						<!-- STATUS -->
						<div *ngIf="mode === E_Modal_Operating_Mode.ADD" class="form-group">
							<label for="status">Publishing status</label>
							<select id="status" class="form-control text-capitalize" formControlName="status">
								<option *ngFor="let type of status_types" [ngValue]="type.id">{{type.name}}</option>
							</select>
						</div>

						<!-- DESCRIPTION -->
						<div class="form-group">
							<label for="description">Description</label>
							<textarea id="description"
								class="form-control"
								formControlName="description"
								placeholder="Enter a description for the community, here."
								rows="6"
							></textarea>
						</div>

						<!-- HOST -->
						<div class="form-group mb-4">
							<label class="w-100" for="hosts">Hosts</label>
							<app-avia-form-search-orgs
								formControlName="hosts"
								ngDefaultControl
								[multi_select]="false"
								[prompt_string]="'Add a Host Organization'"
								[validation]="[{
									condition: !cm_form.pristine && cm_form.controls['hosts'].hasError('required'),
									message: 'Please select a Host Organization.'
								}]"
							></app-avia-form-search-orgs>
						</div>

						<!-- OWNER -->
						<div class="form-group mb-4">
							<label for="owner_objs">Owners</label>
							<app-avia-form-search-users
								formControlName="owner_objs"
								ngDefaultControl
								[prompt_string]="'Add an Owner'"
								[org_id]="[host_id, 1]"
								[validation]="[{
									condition: !cm_form.pristine && cm_form.controls['owner_objs'].hasError('required'),
									message: 'Please select an Owner or Owners.'
								}]"
							></app-avia-form-search-users>
						</div>

						<!-- === BUTTONS === -->
						<div class="form-group d-flex justify-content-center mt-7">
							<button class="btn btn-white border mr-2"
								type="button"
								(click)="cancelFileUpload(); close();"
							>Cancel</button>
							<button class="btn btn-primary ml-2"
								type="submit"
								[disabled]="(cm_form.status !== 'VALID' || cm_form.pristine) ? 'true' : undefined"
							>{{(mode === E_Modal_Operating_Mode.ADD ? 'Add' : 'Save')}}</button>
						</div>

						<!-- === DEBUGGING DISPLAY === -->
						<span *ngIf="false">
							<!-- Display the form values and state during development -->
							<p>Form value: {{ cm_form.value | json }}</p>
							<p>Form status: {{ cm_form.status | json }}</p>
							<p>Pristine: <span class="text-uppercase">{{ cm_form.pristine }}</span></p>
						</span>
					</form>
				</div>
			</div>

		</div>
	</div>
</ng-template>


<!-- === ACTIVATION BUTTONS === -->
<div *ngIf="mode === E_Modal_Operating_Mode.ADD && !hide_activate_btn && static_page === false"
	class="ws-add-button"
	(click)="open()"
	>
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedAddBtn.children.length === 0"
		class="material-icons md-32 default"
		data-toggle="tooltip"
		placement="left"
		ngbTooltip="Add Group"
	>add_circle</i>
</div>

<span *ngIf="mode === E_Modal_Operating_Mode.EDIT && !hide_activate_btn && static_page === false"
	class="ws-edit-button"
	(click)="open()"
	>
	<span #SuppliedEditBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedEditBtn.children.length === 0"
		class="material-icons ws-edit-btn default"
		data-toggle="tooltip"
		placement="bottom"
		nbgTooltip="Edit"
	>edit</i>
</span>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
