<div
	class="d-flex justify-content-between align-items-center sticky-banner banner-container w-100 mb-4"
	[ngStyle]="{'background-color': bg_color}"
	[ngClass]="{'flex-column': aviaService?.mobile_mode}"
>
	<img
		*ngIf="logo_src?.trim().length > 0"
		[ngClass]="{'ml-3': !aviaService?.mobile_mode, 'my-3': aviaService?.mobile_mode}"
		[src]="logo_src"
		alt="banner_logo"
		style='height: 40px; width: auto;'
	>
	<div class="text-white main-text pl-5" [ngClass]="{'text-center px-2': aviaService?.mobile_mode}">{{main_text}}</div>
	<button
		(click)="onBtnClick()"
		class="btn py-2 px-3 banner-btn"
		[ngStyle]="{'color': bg_color ? bg_color : '#44A4DB' }"
		[ngClass]="{'m-3': !aviaService?.mobile_mode, 'my-3': aviaService?.mobile_mode}"
	>
		{{btn_text}}
	</button>
</div>
