<div *ngIf="media_loading" class="media-loader-wrapper">
	<app-avia-loader class="media-loader" [options]="{type: 'generic'}">
	</app-avia-loader>
</div>
<div *ngIf="media && media_loading == false && media?.type == 1" class="media-container w-100" [ngClass]="{'media-portrait':(media?.aspect == 'portrait'), 'media-landscape':(media?.aspect == 'landscape')}">
	<div #image *ngIf="media?.image" class="media-image" [ngClass]="{'media-image-landscape':(media?.aspect == 'landscape'), 'media-image-portrait':(media?.aspect == 'portrait')}" [ngStyle]="{'background-image': 'url(' + media.image + ')'}">
	</div>
	<div class="p-2 bg-gray-100 w-100">
		<div class="d-flex">
			<div *ngIf="media?.logo" class="media-logo mr-2" [ngStyle]="{'background-image': 'url(' + media.logo + ')'}"></div>
			<div class="media-hostname">{{media?.hostname}}</div>
			<div *ngIf="media?.date && formatDate(media.date) != 'invalid date'" class="media-date">&nbsp;•&nbsp;{{formatDate(media.date)}}</div>
		</div>
		<div class="media-title text-truncate-2">
			{{media?.title}}
		</div>
		<div class="media-description text-truncate-3">
			{{media?.description}}
		</div>
	</div>
	<button *ngIf="edit" (click)="clearMedia()" class="close-btn border-0 d-flex align-items-center justify-content-center py-1 m-2">
		<i class="material-icons md-24 text-gray-700 font-size-14" style="vertical-align: top;">close</i>
	</button>
</div>
<div *ngIf="media && media_loading == false && media?.type == 2" class="media-container w-100 media-landscape">
	<div #image *ngIf="media?.image" class="media-image position-relative d-flex justify-content-center">
		<img class="media-image-picture" [src]="media.image">
	</div>
	<button *ngIf="edit" (click)="clearMedia()" class="close-btn border-0 d-flex align-items-center justify-content-center py-1 m-2">
		<i class="material-icons md-24 text-gray-700 font-size-14" style="vertical-align: top;">close</i>
	</button>
</div>

