import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';

import { environment } from './../../../environments/environment.prod';
import { Common } from './../../common';
import { AVIAConnectService } from './../../avia-connect.service';
import { AppComponent } from './../../app.component';
import { AnalyticEvent } from '../../../class';

// Feedbackform
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  @Input() templateloader: Function;
  @Input() template: any;
  @Input() previousUrl: string = '';
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  isOpen:boolean = false;
  isSubmitted:boolean = false;
  error:string = '';
  files:Array<any> = [];

  data: any;// = { id: -1, data: [] };

  constructor( private modalService: NgbModal, private aviaService: AVIAConnectService, private router: Router, public route: ActivatedRoute ) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.init( params['id'] );
    });
  }

  // optional id param for which feedback to load from backend, if not present, then load supplied template.
  async init( id ) {
    // if the URL has id in it, then
    if (id !== undefined) {
      // load in previously filled out template
      let r = await this.aviaService.xhrAuth( "GET",
        "/feedback/results?id=" + id,
        { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {}
      );

      if (Common.isValidHTTPResponse( r.status ) && r.body !== undefined && r.body[0] !== undefined) {
        this.data = r.body[0].result;
      } else {
        this.error = "Could not load the previously logged feedback.  DATA: " + JSON.stringify( r.body );
      }
    } else {
      // load in blank template
      if (this.templateloader !== undefined) {
        //console.log( "feedback form initializing via template loader" );
        //console.log( this.templateloader );
        let r = await this.templateloader();
        this.template = r;
        this.data = r['template'];
      } else if (this.template !== undefined) {
        //console.log( "feedback form initializing via template" );
        //console.log( this.template );
        this.data = _.cloneDeep( this.template );
      }
      this.isOpen = true;
    }
    //console.log( 'data: ' + JSON.stringify( this.data ) );
  }

  destroy() {
    if (this.isOpen) {
      this.removeAllFiles();
      this.isOpen = false;
      this.isSubmitted = false;
    }
  }

  public onClose() {
    this.destroy();
    this.submit.emit();
  }

  public onCancel() {
    this.destroy();
    this.cancel.emit();
  }


  dropFile( e ) {
    this.removeFile( e.file.name ); // prevent duplicates
    let file_data = { file: e.file, progress: 0, link: '' };
    this.files.push( file_data );
    let s3filename = Common.StrongerUUID();
    this.aviaService.uploadToS3( s3filename, e.file.name, e.file, e.file.type, undefined, (size,progress,err) => { file_data.progress = progress/size; } ).then( r => file_data.link = r.url );
  }
  // angular8: this function didn't exist.  npm run build-prod gave "Property 'uploadFile' does not exist"
  uploadFile( e ) {
    this.removeFile( e.file.name );
    let file_data = { file: e.file, progress: 0, link: '' };
    this.files.push( file_data );
    let s3filename = Common.StrongerUUID();
    this.aviaService.uploadToS3( s3filename, e.file.name, e.file, e.file.type, undefined, (size,progress,err) => { file_data.progress = progress/size; } ).then( r => file_data.link = r.url );
  }
  removeAllFiles() : Promise<any> {
    console.log( "clearing files" );
    console.log( this.files );
    let p: Array<Promise<any>> = [];
    for (let file_data of this.files) {
      console.log( file_data );
      console.log( "clearing file:" + file_data['file'].name );
      p.push( this.removeFile( file_data['file'].name ) );
    }
    this.files = [];
    return Promise.all( p );
  }
  removeFile( name ) : Promise<any> {
    let item = this.files.find( file_data => name === file_data.file.name );
    if (item) {
      return this.aviaService.undoUploadToS3( Common.justFilename( item.link ) ).then( r => {
        this.files = this.files.filter( file_data => name !== file_data.file.name );
        console.log( "removed " + name + " from s3" );
      });
    }
    return new Promise((rs,rj) => rs());
  }

  async onSubmit() {
    let obj = new AnalyticEvent(
      'feedback_submit',
      {previousUrl: this.previousUrl}
    );
    this.aviaService.createAnalyticEvent(obj);

    let allAnswered:boolean = true;
    for (let q of this.data['questions']) {
      if (q.result === '' && q.isRequired === true) {
        allAnswered = false;
      }
    }
    this.data['version'] = environment.version;
    this.data['browserinfo'] = this.aviaService.deviceInfo;
    this.data['previousURL'] = this.previousUrl !== undefined && this.previousUrl !== null && this.previousUrl.length > 0 ? this.previousUrl : 'not set';
    this.data['files'] = this.files.map( f => f.link );
    //console.log(this.data);

    // post the result, change state when done
    if (allAnswered) {
      let r = this.aviaService.xhrAuth( "POST",
        "/feedback/result?notify=1",
        { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        { template_id: this.template['id'], user_id: this.aviaService.session.user.id, result: this.data }
      );
      this.isSubmitted = true;
      this.files = [];
    } else
      this.error = "Please answer all required (*) questions";
  }
}
