import { KnowledgeCard } from './../../../class';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { User, UsersSupport, E_OnboardingModalTypes, Org, E_Modal_Operating_Mode, Product } from '../../../class';
import { AVIAConnectService } from '../../avia-connect.service';

@Injectable()
export class OnboardingService {
  readonly VERBOSE: boolean = false;
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template

  @Output() continue: EventEmitter<boolean> = new EventEmitter();

  @Output() navClick: EventEmitter<Object> = new EventEmitter();

  // User Data
  user:   User;
  topics: KnowledgeCard[] = [];
  use_popular_topics: boolean = true;
  is_company_user: boolean = false;

  // Org Data
  org: Org;
  products: Product[] = [];
  one_complete_product: boolean = false;

  // Page tracking
  type: E_OnboardingModalTypes = E_OnboardingModalTypes.GENERAL;
  active_component: number = 0;
  total_components: number = 7;

  form_valid: boolean = false;

  editing_avatar: boolean = false;
  show_company_ob: boolean = false;
  solco_ob_started: boolean = false;
  sc_onboarding_only: boolean = false;
  loading: boolean = false;

  // Support Data
  users_support: UsersSupport;

  //connection cards
  topics_users: User[];

  constructor(public aviaService: AVIAConnectService) { }

  async init() {
    this.loading = true;
    let user_p, topics_p;
    [
      user_p,
      this.users_support,
      topics_p
    ] = await Promise.all([
      this.aviaService.getSessionSupport(),
      this.aviaService.getUsersSupport(),
      this.aviaService.getTopFollowedTopics({limit: 50})
    ]);
    if(topics_p.status === 200 && topics_p.body) {
      this.topics = topics_p.body;
    }
    this.user = user_p.user;

    await this.handleDifferentOnboarding(this.user.org_obj.type);
    this.loading = false;

    this.VERBOSE && console.log("OnboardingService::user", this.user);
    this.VERBOSE && console.log("OnboardingService::users_support", this.users_support);
    this.VERBOSE && console.log("OnboardingService:: is_company_user ", this.is_company_user);
    this.VERBOSE && console.log("OnboardingService::show_company_ob", this.show_company_ob);
    this.VERBOSE && console.log("OnboardingService::total_components", this.total_components);
  }

  onNavigate(obj: Nav_Obj) {
    obj.previous_slide = this.active_component;
    this.navClick.emit(obj);
    if(obj.next) {
      this.active_component += 1;
    } else if (obj.back) {
      this.active_component -= 1;
    }
  }

  async handleDifferentOnboarding(user_org_type: number) {
    // Currently onboarding has 5 different situations
    // 1. SC onboarding has not been completed and user is not new
    // 2. SC onboarding where SC_onboarding has been completed, but user is new
    // 3. SC onboarding where SC_onboarding has not been completed and user is new
    // 4. Partner onboarding
    // 5. HS/AVIA onboarding

    if (this.sc_onboarding_only) {
      // Situation 1
      this.type = E_OnboardingModalTypes.SOLCO;
      this.total_components = 4;
      this.is_company_user = true;
    } else {
      switch(user_org_type) {
        case 2:  // SOLCO
          // Situation 2/3
          this.type = E_OnboardingModalTypes.SOLCO;
          this.total_components = 6;
          this.is_company_user = true;

          // Situation 3
          if (!this.sc_onboarding_only && this.aviaService.session.user.is_owner && this.aviaService.session.org.onboarding == null) {
            await this.getSolco();
            this.show_company_ob = true;
            this.total_components += 4;
          }
          break;

        case 5: // PARTNER
          // Situation 4
          this.type = E_OnboardingModalTypes.PARTNER;
          this.total_components = 6;
          break;

        default:  // GENERAL
          // Situation 5
          this.type = E_OnboardingModalTypes.GENERAL;
          break;
      }
    }
  }

  async getSolco() {
    let solutionsFilter = (product, badged = false)=>{
      let solutions = product.solutions
      let badges = product.badges;
      return solutions.filter((s) => {
        for(let b of badges) {
          if(b.solution_id === s.id) {
            return badged;
          }
        }
        return !badged;
      });
    }

    let products_p, solco_p;
    [
      solco_p,
      products_p
    ] = await Promise.all([
      this.aviaService.getSolCoById(this.user.org_obj.id),
      this.aviaService.getProductsBySolco(this.user.org_obj.id)
    ])
    if(solco_p.status === 200 && solco_p.body.results) this.org = solco_p.body.results[0];
    if (products_p.status === 200) {
      this.products = products_p.body;
      for(let p of this.products) {
        if(p.is_product_complete) {
          this.one_complete_product = true;
          break;
        }
      }
      this.products = this.products.map(
        (p) => ({
          ...p,
          solutions: solutionsFilter(p)
        })
      )
    }
  }

  async refreshUser() {
    this.loading = true;
    this.VERBOSE && console.log("OnboardingService::refreshUser", this.user);
    this.user = (await this.aviaService.getSessionSupport(true)).user;
    if (this.type === E_OnboardingModalTypes.GENERAL) {
      // if this.user.interests_topics.length is 0, query top 5
      let topics ;
      if(this.user.interests_topics.length > 0){
        topics =  this.user.interests_topics
        this.use_popular_topics = false
      } else {
        let topics_res = await this.aviaService.getKMCardsPopular()
        topics = topics_res.topic_ids
      }
      let topics_users_res = await this.aviaService.getTopicsUsers({topic_ids: topics, limit: 100});
      if (topics_users_res.status == 200) {
        this.topics_users = topics_users_res.body.users;
      }
    }
    this.loading = false;
  }

  async updateUser(update_obj:any) {
    this.VERBOSE && console.log( `OnboardingService::updateUser ${this.user.id} data:`, update_obj);
    let res = await this.aviaService.updateUser(this.user.id, update_obj);
    if(res.message && res.message.includes('success')) {
      this.user = res.result.user;
    }
  }

  async updateOrg(update_obj:any) {
    this.VERBOSE && console.log( `OnboardingService::updateOrg ${this.org.id} data:`, update_obj);
    let res = await this.aviaService.updateOrg(this.org.id, update_obj);
  }
}

class Nav_Obj{
  constructor(
    public back:           boolean = null,
    public next:           boolean = null,
    public previous_slide: number = null
  ) { }
}
