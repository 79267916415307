import { Component, OnInit } from '@angular/core';
import { Org, Product } from '../../../../../../class';
import { Router } from '@angular/router';
import { OnboardingService } from '../../../onboarding.service';

@Component({
  selector: 'app-onboarding-solco-products',
  templateUrl: './onboarding-solco-products.component.html',
  styleUrls: ['./onboarding-solco-products.component.scss']
})
export class OnboardingSolcoProductsComponent implements OnInit {

  readonly VERBOSE: boolean = false;
  data:             Org;

  // logo
  remove_logo:     boolean = false;
  editing_logo:    boolean = false;

  new_product_blank: Product = new Product();

  constructor(public sob: OnboardingService, public router: Router) { }

  ngOnInit() {
    this.data = this.sob.org;
    if(!this.sob.products || (this.sob.products && this.sob.products.length === 0) || !this.sob.one_complete_product) {
      this.sob.form_valid = false;
    } else {
      this.sob.form_valid = true;
    }
  }

  async submitAddEditProduct() {
    await this.sob.getSolco();
    if(this.sob.products && this.sob.products.length > 0 && this.sob.one_complete_product) {
      this.sob.form_valid = true;
    }
  }

  ngOnDestroy() {
    this.sob.form_valid = false;
  }

}
