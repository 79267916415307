import { debounceTime } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { Directive, OnInit, HostListener, Output, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[DebounceClick]'
})
export class DebounceClick implements OnInit {
  @Output() debounceClick = new EventEmitter();
  @Input() debounceTime: number;
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceClick.emit(e));
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    // event.preventDefault();
    // event.stopPropagation();
    this.clicks.next(event);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}

