import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../../onboarding.service';

@Component({
  selector: 'app-onboarding-connections',
  templateUrl: './onboarding-connections.component.html',
  styleUrls: ['./onboarding-connections.component.scss']
})
export class OnboardingConnectionsComponent implements OnInit {
  topic_text: string;

  constructor(public sob: OnboardingService) { }

  ngOnInit() {
    this.sob.form_valid = true;
    this.topic_text = this.sob.use_popular_topics ? "trending topics" : "topics you're interested in"
  }

}
