import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { pull, uniqBy } from 'lodash';
import { Router } from '@angular/router';

import { AVIAConnectService } from '../../avia-connect.service';
import { CommonValidators } from '../../../app/common-validators';

import {
  E_Modal_Operating_Mode,
  Session,
  Product
} from '../../../class';


@Component({
  selector: 'app-add-edit-product-modal',
  templateUrl: './add-edit-product-modal.component.html',
  styleUrls: ['./add-edit-product-modal.component.scss']
})
export class AddEditProductModalComponent implements OnInit {
  readonly DEBUG_MODE: boolean = false;
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template
  readonly VERBOSE:boolean = false;

  @Input() mode: number = E_Modal_Operating_Mode.ADD;
  @Input() static_page: boolean = false;
  @Input() title_text: string;
  @Input() solco_id: number;

  @Input() missing_field: string;
  @Input() blue_btn: boolean = false;
  @Input() onboarding: boolean = false;

  private _data: Product;
  @Input() //data
    get data():Product { return this._data; }
    set data(value: Product) {
      if ( value === undefined ) return;
      this._data = value;
      this.setupFormData();
    };

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  product_form: FormGroup;
  recommendations: object[] = [];
  session: Session = new Session;


  constructor( private aviaService: AVIAConnectService, private fb: FormBuilder, public router: Router, private modalService: NgbModal ) {
    this.createForm();
  }

  async ngOnInit() {
    this.VERBOSE && console.log('mode =', E_Modal_Operating_Mode.getPropName(this.mode));
    let promise_arr = [
      this.aviaService.getSessionSupport(),
    ];
    let promise_res = await Promise.all( promise_arr );
    this.session = promise_res[0];
    if (this.solco_id) {
      let res = await this.aviaService.getSolcoRecCapabilities(this.solco_id); //updates the recmomended product solutions
      if (res.status === 200) {
        this.recommendations = res.body;
      }

    }
  }

  createForm(): void {
    this.product_form = this.fb.group({
      description: [null, [ Validators.required ] ],
      name: [null, [ Validators.required ] ],
      short_desc: [null, [ Validators.required, Validators.maxLength(280) ] ],
      solutions: [[], [ Validators.required, CommonValidators.maxLengthArray(3) ] ],
      is_covid: null,
      covid_desc: null
    });
    this.handleCovid();
  };

  setupFormData(): void {
    this.VERBOSE && console.log('add-product-modal::setupFormData operating mode = ', E_Modal_Operating_Mode.getPropName(this.mode) );
    let common_fields = {
      description: this.data['description'] ? this.data['description'] : null,
      name: this.data['name'] ? this.data['name'] : null,
      short_desc: this.data['short_desc'] ? this.data['short_desc'] : null,
      solutions: this.data['solutions'] ? this.data['solutions'] : [],
      is_covid: this.data['is_covid'] ? this.data['is_covid'] : null,
      covid_desc: this.data['covid_desc'] ? this.data['covid_desc'] : null
    };

    this.product_form.reset( common_fields );
    this.product_form.markAsPristine();
    this.product_form.markAsUntouched();
  };

  handleCovid() {
    const covidDesc = this.product_form.get('covid_desc');

    this.product_form.get('is_covid').valueChanges.subscribe(covid => {
      if(covid == true) {
        this.product_form.controls['covid_desc'].setValidators([Validators.required])
      } else {
        this.product_form.controls['covid_desc'].setValidators([])
        this.product_form.patchValue({covid_desc: null})
      }
      covidDesc.updateValueAndValidity();
    });
  }

  async onSubmit() {
    this.VERBOSE && console.log('add-product-modal::onSubmit called with: ', this.product_form.value);
    try {
      let product_obj = this.product_form.value;
      product_obj.solutions = this.product_form.get('solutions').value.map(solution => solution.id)
      if(!product_obj.is_covid) product_obj.is_covid = 0;


      let res;
      if (this.mode === this.E_Modal_Operating_Mode.ADD) {
        res = await this.aviaService.createProduct(this.solco_id, product_obj);
      }
      if (this.mode === this.E_Modal_Operating_Mode.EDIT) {
        res = await this.aviaService.updateProduct(this.solco_id, this.data.id, product_obj)
      }

      if (res.status === 200) {
        this.save.emit(res.body.result);
        this.setupFormData();

        let open_irs, ir_ids, requestors, ack_sent = 0;
        let session = await this.aviaService.getSessionSupport();

        // Resolve outstanding info requests (solco profile)
        open_irs = await this.aviaService.getInfoRequests_SolCoProfile(this.solco_id);
        open_irs = open_irs.body;
        if(open_irs.length > 0){
          requestors = uniqBy(open_irs, 'requestor').map(r => r['requestor']);
          ir_ids = open_irs.map(r => r.id);
          this.aviaService.closeInfoRequests(this.solco_id, 0, ir_ids);
          let options_obj_sc = {
            requestors: requestors,
            user: session.user
          };
          let x = await this.aviaService.notifyInfoRequestors_SolCo(this.solco_id, options_obj_sc);
          if(x.body && x.body.message=='error'){
            ack_sent = 2;
          } else {
            ack_sent = 1;
          }
        }

        // Resolve outstanding info requests (product profile)
        if (this.mode === this.E_Modal_Operating_Mode.EDIT && !!this.data.id) {
          if(product_obj.description && product_obj.short_desc && product_obj.solutions.length > 0){
            open_irs = await this.aviaService.getInfoRequests_ProductProfile(this.solco_id, this.data.id);
            open_irs = open_irs.body;
            if(open_irs.length > 0){
              requestors = uniqBy(open_irs, 'requestor').map(r => r['requestor']);
              ir_ids = open_irs.map(r => r.id);
              this.aviaService.closeInfoRequests(this.solco_id, this.data.id, ir_ids);
              let options_obj_p = {
                requestors: requestors,
                user: session.user,
                sc_id: this.solco_id,
                prod_name: product_obj.name,
                type: 2 // 2: product profile request
              };
              let x = await this.aviaService.notifyInfoRequestors_Prod(this.solco_id, this.data.id, options_obj_p);
              if(x.body && x.body.message=='error'){
                ack_sent = 2;
              } else {
                ack_sent = 1;
              }
            }
          }
        }
        if(ack_sent==1){
          this.aviaService.notify('success', `The health system users who requested this information have been notified.`, '');
        } else if(ack_sent==2) {
          this.aviaService.notify('error', 'Something went wrong. The health system users who requested this information have not been notified.', '');
        }
      }
    } catch (err) {
      console.error('submitAddProduct ERROR:', err);
      this.aviaService.notify('warning', 'Warning!', 'Product not added.', { showConfirmButton: true, timer: null });
    }
  }

  revert(): void {
    this.setupFormData();
  }

  // Solutions selecting
  async updateTopics($event) {

    switch ($event.action) {
      case 'add':
        // prevent duplicates
        let is_in_selected: boolean = false;
        if ( this.product_form.get('solutions').value.length > 0 ) {
          if ( this.product_form.get('solutions').value.map(s => s.id).indexOf($event.data.id) >= 0 ) is_in_selected = true;
        }

        if ( !is_in_selected ) this.product_form.get('solutions').value.push($event.data);
        break;
      case 'delete':
        pull(this.product_form.get('solutions').value, $event.data);
        break;
    }
  }

}
