import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnboardingService } from '../../onboarding.service';
import { Subscription } from 'rxjs';
import { Common } from '../../../../common';
import { CommonValidators } from '../../../../common-validators';

@Component({
  selector: 'app-onboarding-name-form',
  templateUrl: './onboarding-name-form.component.html',
  styleUrls: ['./onboarding-name-form.component.scss']
})
export class OnboardingNameFormComponent implements OnInit {
  readonly VERBOSE: boolean = false;

  data: User;
  name_form: FormGroup;
  nav_sub: Subscription;

  constructor(public sob: OnboardingService, private fb: FormBuilder) {
    this.nav_sub = this.sob.navClick.subscribe(e => {
      if(e.next) {
        this.onSubmit();
      }
    });
    this.createForm();
  }

  ngOnInit() {
    this.data = this.sob.user;
    this.data['clinical_creds_ids'] = this.data.clinical_creds.map(cred => cred.id)
    this.setupFormData();
  }

  createForm(): void {
    this.name_form = this.fb.group({
      firstname:            [null, [Validators.required, Validators.pattern(Common.REGEX_FL_NAME)]],
      lastname:             [null, [Validators.required, Validators.pattern(Common.REGEX_FL_NAME)]],
      clinical_creds:       [null],
      clinical_creds_ids:   [null]
    });

    this.name_form.valueChanges.subscribe(v => {
      this.sob.form_valid = this.name_form.valid;
    })
  };

  setupFormData(): void {
    let data_creds = this.data.clinical_creds ? this.data.clinical_creds.map( cc => { return { id: cc.id, name: cc.cred_obj.name }}) : null;
    let common_fields = {
      firstname:          this.data.firstname             ? this.data.firstname             : null,
      lastname:           this.data.lastname              ? this.data.lastname              : null,
      clinical_creds:     data_creds,
      clinical_creds_ids: this.data['clinical_creds_ids'] ? this.data['clinical_creds_ids'] : null
    };
    this.name_form.reset( common_fields );
    this.name_form.markAsPristine();
    this.name_form.markAsUntouched();
    this.sob.form_valid = this.name_form.valid;
  };

  updateUserCreds(selection) {
    let value = [];
    for( let item of selection['value'] ) {
      value.push( item['id'] );
    }
    // order the creds
    value = Common.orderClinicalCredsIds( value, this.sob.users_support.clinical_credential_types );
    this.name_form.patchValue({clinical_creds_ids: value});
    this.name_form.controls['clinical_creds_ids'].markAsTouched();
    this.name_form.controls['clinical_creds_ids'].markAsDirty();
    this.VERBOSE && console.log( `onboardingStart - updateUserCreds()`, this.data);
  }

  async onSubmit() {
    this.updateUserCreds(this.name_form.controls['clinical_creds'])
    if (this.name_form && this.name_form.touched) {
      await this.sob.updateUser(this.name_form.value)
      this.nav_sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if(this.nav_sub) this.nav_sub.unsubscribe();
    this.sob.form_valid = false;
  }
}
