<div *ngIf="suggested_connections?.length > 0">
	<div class="heading-size-16">Suggested Connections</div>
	<ng-container *ngFor="let u of suggested_connections; index as i;">
		<div *ngIf="i < 3" class="border-bottom border-gray-200 d-flex align-items-start justify-content-between my-3 pb-2">
			<div class="d-flex align-items-start pointer" [routerLink]="['/profiles/in/', u.id]">
				<div class="d-inline-flex mr-2 rounded">
					<img class="avatar rounded" [src]="u.avatar"/>
				</div>
				<div class="name pr-2">
					<div class="font-weight-bold">{{u?.fullname}}</div>
					<div class="font-size-12">{{ u?.title }}<span *ngIf="u?.org_obj?.name && u?.title" class="dot bg-gray-400"></span>{{ u.org_obj ? u.org_obj?.name : ''}}</div>
					<div class="font-size-12"></div>
				</div>
			</div>
			<div>
				<app-send-message-button [user]="u">
					<button (click)="userMessageButton(u)"send-button class="btn profile-btn btn-blue-dark font-size-12 py-1 px-2 connect-btn">
						Message
					</button>
					<button (click)="userConnectButton(u)" connect-button class="btn profile-btn btn-green-lime btn-respond font-size-12 py-1 px-2 connect-btn">
						Connect
					</button>
					<button disabled sent-button class="btn profile-btn btn-blue-dark btn-respond font-size-12 py-1 px-2 connect-btn" style="background-color: rgba(0, 102, 163, 0.55);cursor: not-allowed;border: hsla(0,0%,100%,.45);">
						Invite Sent
					</button>
				</app-send-message-button>
			</div>
		</div>
	</ng-container>
	<button class="btn btn-gray-200 px-2 py-1" [routerLink]="['/suggested']" fragment="suggestedConnections"><span class="text-primary font-size-12">See more</span></button>
</div>
