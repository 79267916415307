import { Component, OnInit, OnChanges, EventEmitter, Input, Output } from '@angular/core';

// AVIA Loader
// @example <app-avia-loader [options]="{type: 'generic', rows: 1}"></app-avia-loader>
// @example <app-avia-loader [options]="{type: 'row', rows: 3}"></app-avia-loader>
// @example <app-avia-loader [options]="{type: 'line', rows: 3}"></app-avia-loader>
// @example <app-avia-loader [options]="{type: 'card', rows: 3}"></app-avia-loader>
@Component({
  selector: 'app-avia-loader',
  templateUrl: './avia-loader.component.html',
  styleUrls: ['./avia-loader.component.scss', '../avia-data-rows/avia-row.scss', './placeholder-loading.scss']
})
export class AviaLoaderComponent implements OnInit {
  @Input() loading: boolean;
  @Input() options: LoaderComponent_Options = new LoaderComponent_Options();
  @Output()('update_loading') update_loading = new EventEmitter();
  totalRows: string[];

  constructor() { }

  ngOnInit() {
    const min = 3; // min cols
    const max = 10; // max cols in the bar lenght
    this.totalRows = [...Array(this.options.rows)].fill('').map(x => {
      const num = Math.floor(Math.random() * (max - min) ) + min
      return `ph-col-${num}`
    });
  }
}

export class LoaderComponent_Options {
  constructor(
    public type: string = 'generic', //type: img, generic, row, line, card
    public round_img: boolean = false,
    public square_img: boolean = false,
    public rows: number = 1,
    public size:       any = undefined, // angular8: added to fix error when building prod: "Property does not exist"
  ) { }
}
