<avia-filedrop [modal]="true" [unlocked]="true" (dragFileAccepted)="dropFile($event)">

	<div *ngIf='link.length === 0' class="row">

		<div [ngClass]="{ 'col-sm-6':options?.use_preview, 'col-sm-12':!options?.use_preview, 'col-12':true }">
			<div class='cropper-wrapper' [ngStyle]="{'max-width':max_width + 'px'}">
				<!-- image cropper -->
				<img-cropper #cropper
					*ngIf="!uploading"
					[hidden]="!data.image"
					[image]="data"
					[ngStyle]="{'max-width':max_width + 'px'}"
					[settings]="cropperSettings"
					(cropPositionChange)="cropPositionChange($event)"
				></img-cropper>
				<i *ngIf="!uploading && !data.image" class="material-icons text-gray-500 placeholder-img">image</i>
				<!-- cloud upload icon, only shows during uploading -->

				<i *ngIf='uploading' class="material-icons cloudupload">cloud_upload</i>
			</div>

			<!-- DEBUGGING DISPLAY -->
			<ng-container *ngIf="DEBUG">
				<p class="mb-6"><strong>_options</strong>: {{_options | json}}</p>
				<p><strong>cropperSettings</strong>: {{cropperSettings | json}}</p>
			</ng-container>

			<div class="row">
				<!-- progress meter -->
				<div *ngIf='uploading' class="col-12 col-sm-12 centered">Progress: {{progress | number : '1.2'}}</div>

				<!-- UPLOAD FILE button -->
				<div *ngIf='!uploading' class="col-12 col-sm-6 centered">
					<div style="height:0px; overflow:hidden">
						<input #ImageUpload class="file-picker" type="file" id="imageupload" (change)="uploadImage($event)" name="bok" accept="image/*">
					</div>
					<button *ngIf="!(data && data.image)" class="btn btn-success" (click)="ImageUpload.click()">Upload Image</button>
					<button *ngIf="(data && data.image)" class="btn btn-cancel text-accent tocitem" (click)="ImageUpload.click()">Upload a Different Image</button>
				</div>

				<!-- SUBMIT button -->
				<div *ngIf="!uploading && data && data.image" class="col-12 col-sm-6 centered">
					<!-- upload to s3, return the link & cropped blob back using the onUploadDone() callback -->
					<div *ngIf="options?.use_upload" class="file-upload">
						<button class="btn btn-success" (click)="uploadCroppedImage()">Submit</button>
					</div>

					<!-- dont upload to s3, just return the cropped blob back using the onCropDone() callback -->
					<div *ngIf="options?.use_done" class="file-upload">
						<button class="btn btn-success" (click)="onDone()">Submit</button>
					</div>
				</div>
			</div>
		</div>

		<!-- preview display -->
		<div class="col-sm-6 col-12" *ngIf="options?.use_preview">
			<div [ngClass]="{ 'grey':!(data && data.image) }" class="result rounded centered">
				<img class="imagewindow"
					[ngClass]="{ 'd-none':!(data && data.image) }"
					[ngStyle]="{ height: cropperSettings.canvasHeight, width: cropperSettings.canvasWidth }"
					[src]="data?.image"
				><br>PREVIEW
			</div>
		</div>
	</div>

	<div *ngIf='link.length > 0' class="row">
		<!-- Once Cropped, allow user to SAVE, or go BACK -->
		<div *ngIf="link!=''" class="w-100">
			<img class='imagewindow'
				[ngStyle]="{ height: cropperSettings.canvasHeight, width: cropperSettings.canvasWidth }"
				[src]="link"
			>
			<div class="text-center">Your cropped image</div>
		</div>
		<div *ngIf="link!==''" class="textblock col-centered col-12">
			<div class="row">
				<div class="col-sm-6 col-12 centered">
					<button class="btn btn-cancel" (click)="back()">BACK</button>
				</div>
				<div class="col-sm-6 col-12 centered">
					<button class="btn btn-success" (click)="submit()">SAVE</button>
				</div>
			</div>
		</div>
	</div>

	<!-- hidden canvas for the target crop -->
	<!-- <img #croppedImage style="display:none" *ngIf="data&&data.image" [src]="data.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight"> -->
</avia-filedrop>
