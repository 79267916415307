import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, Input } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpBackend } from '@angular/common/http';

@Component({
  selector: 'avia-filedrop',
  templateUrl: './avia-filedrag.component.html',
  styleUrls: ['./avia-filedrag.component.scss']
})
export class AviaFiledragComponent implements AfterViewChecked {
  readonly VERBOSE: boolean = false;

  @Output() dragFileAccepted: EventEmitter<Object> = new EventEmitter();
  @Output() dragFileRejected: EventEmitter<Object> = new EventEmitter();
  @Input() modal: boolean = false;
  @Input() unlocked: boolean = false;
  @ViewChild('content', { static: true }) content: ElementRef;
  offset = !this.modal ? 60 : 0;
  isHovering: boolean = false;
  size_info: Object = {};

  constructor() { }

  ngAfterViewChecked() {
    if (this.content && this.unlocked) {
      this.offset = !this.modal ? 60 : 0;
      this.size_info['h'] = this.content.nativeElement.offsetHeight;
      this.size_info['w'] = this.content.nativeElement.offsetWidth;
      this.size_info['l'] = this.content.nativeElement.offsetLeft + this.offset;
      this.size_info['t'] = this.content.nativeElement.offsetTop + this.offset;
    }
  }

  onDragFileOverStart($event) {
    if (!this.isHovering && this.unlocked) {
      this.isHovering = true;
    }
    $event.preventDefault();
    $event.stopPropagation();
    return false;
  };

  onDragFileOverEnd($event): any {
    if (this.isHovering && $event.srcElement.className === 'inner') {
      if ($event.layerX <= 0 || $event.layerX >= (this.size_info['w'] - this.offset) || $event.layerY <= 0 || $event.layerY >= (this.size_info['h'] - this.offset)) {
        this.isHovering = false;
      }
    }

    $event.preventDefault();
    $event.stopPropagation();
    return false;
  }

  onDragFileAccepted(acceptedFile: File): any {
    this.VERBOSE && console.log("onDragFileAccepted");
    if (this.dragFileAccepted) {
      this.dragFileAccepted.emit({ file: acceptedFile });
    }
  }

  onDragFileRejected(rejectedFile: File): any {
    this.VERBOSE && console.log("onDragFileRejected");
    if (this.dragFileRejected) {
      this.dragFileRejected.emit(rejectedFile);
    }
  }

  onDragFileDrop($event: any): any {
    this.VERBOSE && console.log("onDragFileDrop");
    $event.preventDefault();
    $event.stopPropagation();
    if (this.unlocked) {
      this.FileSelectHandler($event);
    }
  }

  // you can *ngIf on this, to see whether to include your filedrag element or not
  static IsCompatible(): boolean {
    // is XHR2 available?
    var xhr = new XMLHttpRequest();
    return xhr.upload != undefined;
  }

  // file selection handler (can be called from drag, or from a file-requestor select box)
  FileSelectHandler(e) {
    // cancel the hover
    this.isHovering = false;

    // fetch FileList object
    var files = e.target.files || e.dataTransfer.files;

    // process all File objects
    for (var i = 0, f; f = files[i]; i++) {
      this.onDragFileAccepted(f);
      console.log(
        "File information: " + f.name +
        "type: " + f.type +
        "size: " + f.size + "bytes"
      );
    }
  }

  preventDefaultAndStopPropagation($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
