import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash';

import {
  Org,
} from '../../../class';

type Validation = {
  condition: string,
  message:   string
};


@Component({
  selector: 'app-avia-form-orgs-dropdown',
  templateUrl: './avia-form-orgs-dropdown.component.html',
  styleUrls: ['./avia-form-orgs-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaFormOrgsDropdown),
      multi: true
    }
  ]
})
export class AviaFormOrgsDropdown implements ControlValueAccessor {
  readonly VERBOSE: boolean = true;

  disabled: boolean = false;
  focused:  boolean = false;

  _selected_id:  number;
  _selected_org: Org;

  _orgs: Org[] = [];
  @Input()
    get orgs() { return this._orgs; }
    set orgs(data: Org[]) {
      this._orgs = cloneDeep(data);  // cloneDeep is needed; otherwise it will continue to reference the memory address of your input
      this.focused = false;
    }

  @Input() //selected_id
    get selected_id() { return this._selected_id; }
    set selected_id(value: number) {
      this._selected_id = value;
      this._selected_org = undefined;

      if (this._orgs != undefined) {
        for (let org of this._orgs) {
          if (org.id === value) {
            this._selected_org = org;
            break;
          }
        }
      }
    }
  @Input() //selected_org
    get selected_org() { return this._selected_org; }
    set selected_org(data: Org) {
      this._selected_org = cloneDeep(data);
      this._selected_id = this._selected_org.id;
    }

  @Input() placeholder: string       = 'Select an organization';
  @Input() tab_index:   number       = 0;
  @Input() validation:  Validation[] = [];

  @Output() selected: EventEmitter<number> = new EventEmitter<number>();

  memberCountMap: { [k: string]: string } = {
    '=1':    '1 Person',
    'other': '# People'
  };


  constructor() {};


  // Control Value Accessor - These functions must be declared even if empty

  onTouched: any = () => {};

  propagateChange = (_: number) => {
    this.selected.emit(_);
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  };

  registerOnTouched(fn) {
    this.onTouched = fn;
  };

  setDisabledState(is_disabled: boolean): void {
    this.disabled = is_disabled;
  };

  writeValue(value: number) {
    this.focused = false;
    this.selected_id = value;
    this.propagateChange(this._selected_id);
  }

}
