import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  cloneDeep as lodash_cloneDeep
} from 'lodash';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs'

import { AVIAConnectService } from '../../avia-connect.service';
import { ContentLibraryService } from './../content-library.service';
import { Common } from './../../common';
import { CommonValidators } from './../../common-validators';

import {
  AnalyticEvent,
  Color_Library,
  KmSupport_IdName,
  Nav_Tab,
  Nav_Tab_Pills,
  OrgsSupport,
} from '../../../class';


@Component({
  selector: 'app-add-content-modal',
  templateUrl: './add-content-modal.component.html',
  styleUrls: ['./add-content-modal.component.scss']
})
export class AddContentModalComponent implements OnInit, OnDestroy {
  readonly VERBOSE = false;

  // FILE TABS
  file_or_link_tabs: Nav_Tab_Pills;

  // FILE UPLOADING
  error:          string;
  file:           any;
  file_uploaded:  boolean = false;
  progress:       number  = 0.0;
  upload_started: boolean = false;

  // FORM
  content_form: FormGroup;
  fc_description: AbstractControl;
  fc_file_name: AbstractControl;
  fc_groups: AbstractControl;
  fc_is_public: AbstractControl;
  fc_link: AbstractControl;
  fc_member_type_arr: AbstractControl;
  fc_name: AbstractControl;
  fc_org_type_arr: AbstractControl;
  fc_produced_by: AbstractControl;
  fc_programs: AbstractControl;
  fc_published_date: AbstractControl;
  fc_special_content: AbstractControl;
  fc_type: AbstractControl;

  // CONTENT TYPES
  content_types: KmSupport_IdName;

  // Membership, Org Types, Programs
  avia_member_types: any[] = [];
  avia_member_focus: any[] = [];
  avia_org_types: any[] = [];
  show_orgtypes: boolean = false;
  show_membership: boolean = false;
  show_programs: boolean = false;
  subsc_orgtypes: Subscription;
  subsc_specialcontent: Subscription;

  // MODAL
  @Input() static_page: boolean = false;
  @Output() new_content: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ViewChild('ContentUpload', { static: true }) content_modal: NgbModal;
  modal_ref: NgbModalRef;


  constructor(
    public aviaService: AVIAConnectService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public scl: ContentLibraryService,
    public router: Router
  ) {
    this.file_or_link_tabs = new Nav_Tab_Pills([
      new Nav_Tab('Link', true),
      new Nav_Tab('Upload')
    ], 'none');

    this.createForm();
  };

  async ngOnInit(): Promise<void> {
    this.VERBOSE && console.log('AddContentModalComponent::ngOnInit');
    await this.scl.initUserAccess();

    this.content_types = (await this.aviaService.getKmSupport()).content_types;

    if(this.scl.keychain_cl.avia_org_membership.c) {
      await Promise.all([
        this.getOrgTypes(),
        this.getMemberFocus(),
        this.getMemberTypes()
      ]);
    }

    this.resetForm();
  }

  resetSpecialContent(val: number): void {
    this.fc_member_type_arr.setValidators([]);
    this.memberTypeArray.clear();
    for(let t of this.avia_member_types) {
      this.memberTypeArray.push(this.fb.control(false));
    }
    this.fc_member_type_arr.markAsPristine();

    this.fc_org_type_arr.setValidators([]);
    this.orgTypeArray.clear();
    for(let t of this.avia_org_types) {
      this.orgTypeArray.push(this.fb.control(false));
    }
    this.fc_org_type_arr.markAsPristine();

    this.fc_programs.setValidators([]);
    this.fc_programs.patchValue([]);
    this.fc_programs.markAsPristine();

    switch(val) {
      case 0:  // Org Types
        this.fc_org_type_arr.setValidators([CommonValidators.minLengthTruthyArray(1)]);
      break;

      case 1:  // Core Membership
        this.fc_member_type_arr.setValidators([CommonValidators.minLengthTruthyArray(1)]);
      break;

      case 2:  // Programs and Strategic Initiatives
        this.fc_programs.setValidators([CommonValidators.minLengthArray(1)]);
      break;
    }

    this.fc_member_type_arr.updateValueAndValidity();
    this.fc_org_type_arr.updateValueAndValidity();
    this.fc_programs.updateValueAndValidity();
  }

  async getOrgTypes(): Promise<void> {
    let result:OrgsSupport = await this.aviaService.getOrgsSupport();

    if(result && result.org_types) {
      this.avia_org_types = [];
      for (let ot of result.org_types) {
        if (ot.id !== 3) this.avia_org_types.push(ot);
      }
    }
  }

  async getMemberTypes(): Promise<void> {
    let result = await this.aviaService.getMemberTypes();
    if(result.status == 200) {
      this.avia_member_types = result.body;
    }
  }

  async getMemberFocus(): Promise<void> {
    let result = await this.aviaService.getMemberFocus();
    if(result.status === 200) {
      this.avia_member_focus = result.body;
    }
  }

  ngOnDestroy(): void {
    this.subsc_orgtypes.unsubscribe();
    delete this.subsc_orgtypes;

    this.subsc_specialcontent.unsubscribe();
    delete this.subsc_specialcontent;

    this.resetForm();
  }

  updateProducedBy($event: any): void {
    this.fc_produced_by.patchValue( ($event.value) ? this.aviaService.session.org.id : null );
    this.fc_produced_by.markAsDirty();
    this.fc_produced_by.markAsTouched();

    if ( !$event.value ) this.fc_is_public.patchValue(true);

    if(this.fc_produced_by.value == 1) {
      this.fc_special_content.patchValue(1); // If we select AVIA produced, then it default to Core members
    } else {
      this.fc_special_content.patchValue(-1); // If we deselect AVIA produced, then remove validators on membership
    }
  }

  switchSpecialContent(sc_val: number): void {
    this.show_orgtypes = false;
    this.show_membership = false;
    this.show_programs = false;

    this.fc_org_type_arr.clearValidators();
    this.fc_member_type_arr.clearValidators();
    this.fc_programs.clearValidators();

    switch(sc_val) {
      case 0:  // All Connect
        this.show_orgtypes = true;
        this.fc_org_type_arr.setValidators([CommonValidators.minLengthTruthyArray(1)]);
        if (this.fc_org_type_arr.value.length === this.avia_org_types.length) {
          this.fc_is_public.patchValue(true);
        } else {
          this.fc_is_public.patchValue(false);
        }
      break;

      case 1:  // Core
        this.show_membership = true;
        this.fc_member_type_arr.setValidators([CommonValidators.minLengthTruthyArray(1)]);
        this.fc_is_public.patchValue(false);
      break;

      case 2:  // Programs and Strategic Initiatives
        this.show_programs = true;
        this.fc_programs.setValidators([CommonValidators.minLengthArray(1)]);
        this.fc_is_public.patchValue(false);
      break;

      default:
        this.fc_is_public.patchValue(true);
    }

    this.fc_org_type_arr.updateValueAndValidity();
    this.fc_programs.updateValueAndValidity();
    this.fc_member_type_arr.updateValueAndValidity();
  }

  createForm(): void {
    this.content_form = this.fb.group({
      author:            null,
      produced_by:       null,
      description:       [null, Validators.required],
      file_name:         null,
      groups:            [null, CommonValidators.minLengthArray(1)],
      in_library:        1,
      link:              [null, Validators.required],
      md5_hash:          null,
      name:              [null, Validators.required],
      published_date:    null,
      s3_filename:       null,
      type:              [null, Validators.required],
      user:              this.aviaService.session.user.id ? this.aviaService.session.user.id : null,
      uploaded_by_org:   this.aviaService.session.org.id ? this.aviaService.session.org.id : null,
      is_public:         true,
      special_content:   null,
      org_type_array:    this.fb.array([]),
      member_type_array: this.fb.array([]),
      programs:          new FormControl([], { updateOn: "change" })
    });

    // Form Control (Abstract Control shortcuts)
    this.fc_description = this.content_form.controls.description;
    this.fc_file_name = this.content_form.controls.file_name;
    this.fc_is_public = this.content_form.controls.is_public;
    this.fc_groups = this.content_form.controls.groups;
    this.fc_link = this.content_form.controls.link;
    this.fc_member_type_arr = this.content_form.controls.member_type_array;
    this.fc_name = this.content_form.controls.name;
    this.fc_org_type_arr = this.content_form.controls.org_type_array;
    this.fc_produced_by = this.content_form.controls.produced_by;
    this.fc_programs = this.content_form.controls.programs;
    this.fc_published_date = this.content_form.controls.published_date;
    this.fc_special_content = this.content_form.controls.special_content;
    this.fc_type = this.content_form.controls.type;

    // Changes to Org Types check boxes
    this.subsc_orgtypes = this.fc_org_type_arr.valueChanges.subscribe(
      (val) => {
        if (this.fc_special_content.value === 0) {
          if (val.length === 0) {
            this.fc_is_public.patchValue(true);
          } else {
            let pub:boolean = true;
            for (let i of val) {
              if (!i) {
                pub = false;
                break;
              }
            }
            this.fc_is_public.patchValue(pub);
          }
        }
        this.VERBOSE && console.log('org types val: ', val, ' - is_public: ', this.fc_is_public.value);
      },
      (err) => { console.error('Edit Content Modal - "Org Types" Subscription Error: ', err); }
    );

    // Changes to Special Content radio buttons
    this.subsc_specialcontent = this.fc_special_content.valueChanges.subscribe(
      sc_val => { this.switchSpecialContent(sc_val); },
      (err) => { console.error('Add Content Modal - Special Content Subscription Error: ', err) }
    );
  }

  get orgTypeArray(): FormArray {
    return this.fc_org_type_arr as FormArray;
  }

  get memberTypeArray(): FormArray {
    return this.fc_member_type_arr as FormArray;
  }

  disableAll(): void {
    this.fc_file_name.disable();
    this.fc_link.disable();
  };

  enableAll(): void {
    this.fc_file_name.enable();
    this.fc_link.enable();
  };

  async open(): Promise<void> {
    let options: NgbModalOptions = { 'backdrop': 'static', size: 'lg', windowClass: 'avia-modal-fullscreen' };
    this.modal_ref = this.modalService.open(this.content_modal, options);
  };

  tabChanged($event: any): void {
    let tab_name = $event.name.toLowerCase();

    this.fc_file_name.disable();
    this.fc_link.disable();

    switch (tab_name) {
      case 'link':
        this.fc_link.enable();
      break;

      case 'upload':
        this.fc_file_name.enable();
      break;
    }
  };

  fixUpDate(date: any): Date {
    this.VERBOSE && console.log('fixUpDate: type in:', typeof date);
    date = new Date(date.replace(/-/g, '\/'));
    this.VERBOSE && console.log('fixUpDate: type out:', typeof date);
    return date;
  };

  // FILE UPLOAD
  current_upload_abort: () => any = undefined;

  dropFile($event: any): void {
    this.uploadfile($event.file);
  };

  fileChosenFromBrowse($event: any): void {
    this.uploadfile($event.target.files[0]);
  };

  private async uploadfile(file: any): Promise<void> {
    this.file = file;
    this.content_form.patchValue({ 'name': this.file.name });
    this.content_form.patchValue({ 'file_name': this.file.name });

    if (this.file == undefined) {
      await this.aviaService.notifyWarning('Please choose a file.');
    }

    const s3filename = Common.StrongerUUID();

    this.upload_started = true;

    const s3_upload = await this.aviaService.uploadToS3(
      s3filename,
      this.file.name,
      this.file,
      this.file.type,//'application/octet-stream',
      undefined,
      (s, p, err, abort) => {
        this.progress = (p / s);
        this.error = err;
        this.current_upload_abort = abort;
      }
    );

    if (s3_upload && s3_upload.message == "success" && s3_upload.url && !!this.file) {
      this.file_uploaded = true;
      this.upload_started = false;
      this.file.link = s3_upload.url;
      this.file.s3_filename = s3_upload.file_name;
      this.fc_link.enable();
      this.fc_link.patchValue(s3_upload.url);
      this.fc_link.disable();
      this.content_form.patchValue({ 'md5_hash': s3_upload.hash });
      this.content_form.patchValue({ 's3_filename': s3_upload.file_name });
    } else {
      await this.aviaService.notifyFailure(`Uploading file has failed. Please try again.`);
      console.error(this.error);
    }
  };

  // SUBMIT
  async onSubmit(): Promise<void> {
    try {
      this.enableAll(); // "wake" the hidden fields back up

      let new_content = lodash_cloneDeep(this.content_form.value);
      for(let key in new_content) {
        if(new_content[key] === '') {
          new_content[key] = null;
        }
      }

      new_content.link = Common.safeHttp(this.content_form.value.link);

      new_content.is_public = (new_content.is_public) ? 1 : 0;

      let setSubPermsFunc: any = (id:any, params:any) => Promise.resolve();
      let subPermsParams = {};

      if (new_content.produced_by) {
        // Connect User types, Member Types and Member Focus (Settable only by AVIA)
        if (this.scl.keychain_cl.avia_org_membership.c && this.fc_produced_by.value === 1) {
          // Setup function and params proxies so we dont have 'awaits' sprinkled within the switch/case

          switch (this.fc_special_content.value) {
            case 0: // Org Types
              let org_type_ids = [];
              for (let i = 0; i < this.avia_org_types.length; i++) {
                if (this.orgTypeArray.controls[i].value === true) {
                  org_type_ids.push(this.avia_org_types[i].id);
                }
              }
              // If all the Org types are checked, we are considered public
              if (org_type_ids.length === this.avia_org_types.length) {
                org_type_ids = [];
              } else {
                subPermsParams = {org_type_ids};
                setSubPermsFunc = this.aviaService.setContentOrgType.bind(this.aviaService);
              }
            break;

            case 1: // Membership Types
              let type_ids = [];
              for(let i = 0; i < this.avia_member_types.length; i++) {
                if(this.memberTypeArray.controls[i].value === true) {
                  type_ids.push(this.avia_member_types[i].id)
                }
              }
              subPermsParams = {type_ids};
              setSubPermsFunc = this.aviaService.setContentMemberType.bind(this.aviaService);
            break;

            case 2: // Programs
              let focus_ids = this.fc_programs.value.map(f => f.id);
              subPermsParams = {focus_ids};
              setSubPermsFunc = this.aviaService.setContentMemberFocus.bind(this.aviaService);
            break;
          }
        }
      }

      if(new_content.published_date) new_content.published_date = this.fixUpDate(this.fc_published_date.value);

      // Don't send falsey to the backend for 'produced_by'
      if (!new_content.produced_by) new_content.produced_by = null;

      // Analytic Event
      let analytic_event_obj = new AnalyticEvent( 'content_create', {card_id: new_content.id} );
      this.aviaService.createAnalyticEvent(analytic_event_obj);

      // Groups
      if(new_content.groups) {
        new_content.group_ids = new_content.groups.map(g => g.content);
        delete new_content.groups;
      }

      // Remove unused properties
      delete new_content.org_type_array;
      delete new_content.member_type_array;
      delete new_content.programs;

      // Submit the new Content and get its ID from the DB
      this.VERBOSE && console.log('AddContentModalComponent::onSubmit called with: ', new_content);
      new_content = (await this.aviaService.addContent(new_content)).body.new_item;
      await setSubPermsFunc(new_content.id, subPermsParams);

      // Form reset, cleanup and navigate
      this.resetForm();
      this.close.emit()
      this.new_content.emit( new_content );
      if (!this.static_page) {
        this.modal_ref.close();
        this.router.navigate(['/intelligence/cl/search'], { queryParams: { id: new_content.id } });
      }
    } catch (err) {
      console.error('ERROR - AddContentModalComponent::onSubmit',err);
      this.aviaService.notifyFailure('Sorry, it looks like something went wrong and we could not upload your content. Please contact an administrator for assistance.');
    }
  };

  // FORM DESTROY, REVERT, AND CLEANUP STUFF
  async cancelAndClose(): Promise<void> {
    if(this.file && this.file.link) {
      await this.aviaService.undoUploadToS3(Common.justFilename(this.file.link));
    }
    this.revert();
    if (!this.static_page) this.modal_ref.close();

  };

  async revert(): Promise<void> {
    // If we are in the middle of uploading a file (the xhr didn't finish on uploadToS3), abort
    if(this.upload_started && !this.file_uploaded && this.current_upload_abort) {
      this.current_upload_abort();
    }

    // If we have uploaded to s3 already, undo it
    if(this.file && this.file.link && this.progress) {
      await this.aviaService.undoUploadToS3(Common.justFilename(this.file.link));
    }
    this.resetForm();
  };

  _close(): void {
    this.revert();
    this.close.emit(); //Closes universal nav if open
    this.cancel.emit();
    this.modal_ref.close();
  };

  resetForm(): void {
    // Clear out component variables
    delete this.file;
    delete this.progress;
    delete this.error;
    this.file_uploaded = false;
    this.upload_started = false;

    //Reset form (will be pristine again)
    this.content_form.reset({
      author: null,
      produced_by: 0,
      description: null,
      file_name: null,
      groups: null,
      in_library: 1,
      link: null,
      md5_hash: null,
      name: null,
      published_date: null,
      s3_filename: null,
      type: null,
      user: this.aviaService.session.user.id,
      uploaded_by_org: this.aviaService.session.org.id,
      is_public: true,
      special_content: null,
      member_type_array: this.fb.array([]),
      org_type_array: this.fb.array([]),
      programs: null
    });

    this.resetSpecialContent(this.fc_special_content.value);
  };

  async checkExistingContent(obj): Promise<void> {
    if(this.content_form.value.name) {
      let existing_content = (await this.aviaService.searchContentPromise(obj)).body;

      if(existing_content.length && existing_content.length > 0) {
        let res = await this.alertUserAboutExistingContent(existing_content[0]);
        if(res.value) {
          this.revert();
          this.router.navigate(['/intelligence/cl'], { queryParams: { id: existing_content[0].id } });
          if (!this.static_page) this.modal_ref.close();
        }
      }
    }
  };

  private async alertUserAboutExistingContent(existing_content): Promise<any> {
    let html = `
      <p class="mb-4">We might already have this: </p>
      <div class="card mb-4 m-4">
      <h5 class="card-header" style="background: #ababab; color: #ffffff;">${ existing_content.name}</h5>
      <div class="card-block">
    `;

    if (existing_content.author) {
      html += `<p class="card-text truncate truncate-ellipsis-2"> by <em>${ existing_content.author}</em></p>`;
    }

    if(existing_content.description) {
      html += `<p class="card-text text-left truncate truncate-ellipsis-2" style="font-size:14px"> ${ existing_content.description}</p>`;
    }

    html += `</div></div>`;

    return await this.aviaService.notify(
      'info',
      'This Looks Familiar',
      undefined,
      {
        cancelButtonColor: Color_Library.primary,
        cancelButtonText: 'Continue Adding',
        confirmButtonText: 'Go to Content',
        html,
        showCancelButton: true,
        showConfirmButton: true,
        timer: 0
      }
    )
  };

};
