import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AVIAConnectService } from '../../avia-connect.service'
import { AnalyticEvent, Banner } from '../../../class'


@Component({
  selector: 'app-avia-banner',
  templateUrl: './avia-banners.component.html',
  styleUrls: ['./avia-banners.component.scss']
})
export class AviaBannerComponent implements OnInit {

  @Input() banner: Banner;

  public btn_text: string;
  public logo_src: string;
  public main_text: string;
  public bg_color: string;

  readonly E_URL_TYPES: {
    INTERNAL: number,
    EXTERNAL: number
  } = {
    INTERNAL: 1,
    EXTERNAL: 2,
  };

  constructor(public aviaService: AVIAConnectService, private router: Router) { }

  ngOnInit() {
    if (this.banner) {
      this.btn_text = this.banner.btn_text;
      this.logo_src = this.banner.image_url;
      this.main_text = this.banner.text;
      this.bg_color = this.banner.color;
    }
  }

  onBtnClick() {
    switch(this.banner.url_type_id) {
      case this.E_URL_TYPES.INTERNAL: this.router.navigate([this.banner.btn_url],  { queryParams: JSON.parse(this.banner.btn_url_query_params) });
        break;
      case this.E_URL_TYPES.EXTERNAL: {
        if (!this.banner.btn_url.match(/^https?:\/\//i)) {
          this.banner.btn_url = 'http://' + this.banner.btn_url;
        }
        window.open(this.banner.btn_url, '_blank');
        break;
      }
    }

    let obj = new AnalyticEvent(
      'banner_click',
      {
        user_id: this.aviaService.session.user.id,
        banner_id: this.banner.id,
        btn_url: this.banner.btn_url,
        btn_url_params: this.banner.btn_url_query_params
      }
    );
    this.aviaService.createAnalyticEvent(obj);
  }

}
