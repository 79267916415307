<!-- <app-sub-nav [options]="" [routes]="sub_routes"></app-sub-nav> -->
<div class="card-container p-0 p-md-4">
	<div class="card h-100">
		<div class="row no-gutters">
			<div class="col-12">
        <app-avia-tab-bar
          class="tab-bar"
          [nav_bar]="nav_bar"
          [tab_preselect_param]="'activeTab'">
        </app-avia-tab-bar>
        <!-- Password Change -->
        <div *ngIf="nav_bar?.active_tab === 1">
          <div class="col-12 col-md-11 col-lg-9 py-4">
            <h4>Change Your Password</h4>
            <app-avia-password-change (passed)="changePassword($event)"></app-avia-password-change>
          </div>
        </div>
				<!-- Email Preferences -->
        <div *ngIf="nav_bar?.active_tab === 0">
          <div class="col-12 col-md-11 col-lg-9 py-4">
            <h4>Email Preferences</h4>
              <app-avia-email-settings [session]="session" (updateEmail)="updateUser($event)"></app-avia-email-settings>
                <button type="button" class="btn btn-light" (click)="emailButtonClicked()">Save</button>
            </div>
        </div>



			</div>
		</div>
	</div>
</div>
