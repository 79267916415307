import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AVIAConnectService } from '../../avia-connect.service';
import { AviaImageCropComponent } from '../avia-image-crop/avia-image-crop.component';

@Component({
  selector: 'avia-image-crop-modal',
  templateUrl: './avia-image-crop-modal.component.html',
  styleUrls: ['./avia-image-crop-modal.component.scss']
})
export class AviaImageCropModalComponent implements OnInit {
  @Output('submit') submit:any = new EventEmitter();
  @Output('cancel') cancel:any = new EventEmitter();
  @Input() //options
    get options(): any { return this._options };
    set options(value:any) {
      if (value === undefined) return;
      this._options = value;
      if (this._options.use_upload === undefined) this._options.use_upload = true;
    }
    _options:any = { use_upload: true };
  imagecrop:AviaImageCropComponent;
  link: string = '';

  isSubmitted:boolean = false;
  error:string = '';

  initdata = undefined;
  //data = { placeholder: "placeholder", items: [{id:1,name:'blah'}], default: 'active', active: {id:1} };

  constructor( private modalService: NgbModal ) {

  }



  ngOnInit() {  }

  setImageCrop( child:AviaImageCropComponent ) {
    this.imagecrop = child;
  }

  onCancel() {
    //this.close();
    this.cancel.emit();
    this.isSubmitted = false;

    if (this.imagecrop)
      this.imagecrop.back();
    else
      console.error( "#imagecrop not found in avia-image-crop-modal.component.html" );
  }

  onSourceImageChange( e ) {
    this.link = '';
  }
  // called with {link, blob, img}
  onSave( e ) {
    this.link = e.link;
    this.submit.emit( { link: this.link } );
    //this.close();
  }

  // Just so any containing component knows what's up if we cancel with Esc
  @HostListener("document:keyup",['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=="Escape"){
      this.cancel.emit();
    }
  }
}
