
<ng-container *ngIf="aats?.tiles?.length && aats?.tiles[0]?.type === 1">
	<div @fade class="card mb-4">
		<i *ngIf="aats?.tiles[0]?.dismissible" class="ml-auto p-1 font-size-12 material-icons text-gray-600 unselectable pointer" (click)="aats?.nextTile()">close</i>
		<div [ngClass]="aats?.aviaService?.mobile_mode ? 'text-center' : 'd-flex'" class=" align-items-center card-body">
			<img *ngIf="aats?.tiles[0]?.sidebar_logo" class="font-size-18" [src]="aats?.tiles[0]?.sidebar_logo" />
			<div class="pl-4" [ngClass]="{'w-60': !aats?.aviaService?.mobile_mode}">
				<div class="heading-size-32 text-capitalize">
					<div *ngIf="aats?.tiles[0]?.header_logo" >
						<img class="card-img" [src]="aats?.tiles[0]?.header_logo" />
					</div>
					<div>{{aats?.tiles[0]?.header}}</div>
				</div>
				<div class="font-size-16">{{aats?.tiles[0]?.description}}</div>
			</div>
			<div [ngClass]="{'w-40 pl-10': !aats?.aviaService?.mobile_mode}">
				<!-- IMPORTING PRESET company/preset logos -->
				<div
					*ngIf="aats?.tiles[0]?.btn_logo_text"
					class="d-flex align-items-center my-3 overflow-hidden"
					[ngClass]="aats?.aviaService?.mobile_mode ? 'font-size-16 justify-content-center' : 'font-size-12'">
					<img
						class="tiny-logo mr-1"
						[ngClass]="{'logo-bg': aats?.tiles[0]?.btn_logo}"
						[ngStyle]="{'background-color': aats?.tiles[0]?.color}"
						[src]="aats?.tiles[0]?.btn_logo ? aats?.tiles[0]?.btn_logo : (aats?.tiles[0]?.crunchbase_logo ?  aats?.tiles[0]?.crunchbase_logo : aats?.tiles[0]?.company_logo)"
						onerror="this.src='../../../../assets/placeholder-company.jpg'" 	 />
						{{aats?.tiles[0]?.btn_logo_text}}
				</div>
				<button
				  *ngIf="aats?.tiles[0]?.id !== aats?.ADD_USER_ID"
					(click)="aats?.tileButtonClick()"
					class="btn btn-primary"
					[ngClass]="aats?.aviaService?.mobile_mode ? 'p-3 font-size-18 mt-2' : 'p-1 px-3 font-size-12'"
					[ngStyle]="{'background-color': aats?.tiles[0]?.color, 'border-color': aats?.tiles[0]?.color}">
						{{aats?.tiles[0]?.btn_text ? aats?.tiles[0]?.btn_text : 'Go There Now'}}
				</button>
				<app-avia-user-invite-modal
					*ngIf="aats?.tiles[0]?.id === aats?.ADD_USER_ID"
					[org]="aats?.org"
					>
					<button
						(click)="aats?.tileButtonClick()"
						class="btn btn-primary"
						[ngClass]="aats?.aviaService?.mobile_mode ? 'p-3 font-size-18 mt-2' : 'p-1 px-3 font-size-12'"
						[ngStyle]="{'background-color': aats?.tiles[0]?.color, 'border-color': aats?.tiles[0]?.color}">
							{{aats?.tiles[0]?.btn_text ? aats?.tiles[0]?.btn_text : 'Go There Now'}}
						</button>
				</app-avia-user-invite-modal>
			</div>
		</div>
		<div
			class="crisscross"
			[attr.style]="sanitizer.bypassSecurityTrustStyle('--red:'+aats?.convertHex('r')+'; --green:'+aats?.convertHex('g')+'; --blue:'+aats?.convertHex('b'))">
			<!-- CRISS CROSS PLACE HOLDER -->
		</div>
	</div>
</ng-container>

<!-- GO TO YOUR AHA PULSE TILES -->
<ng-container *ngIf="aats?.tiles?.length && aats?.tiles[0]?.type === 2">
	<div class="card mb-4 overflow-hidden">
		<div class="d-flex bg-aha-blue">
			<div class="w-40">
				<img class="h-100 w-100" [src]="'/assets/aha-user-tiles/aha-pulse/digital_pulse.jpg'" />
			</div>
			<div class="h-100 w-60">
				<img class="aha-logo scaled-logo" [ngClass]="{'mt-4': !aats?.aviaService?.mobile_mode}" [src]="'/assets/aha-user-tiles/aha-pulse/aha_center_logo.png'" />
				<img class="d-flex aha-text scaled-image" [src]="'/assets/aha-user-tiles/aha-pulse/aha_digital_pulse.svg'"/>
				<button
					(click)="aats?.tileButtonClick()"
					[ngClass]="aats?.aviaService?.mobile_mode ? '' : 'mt-2'"
					class="d-flex btn btn-primary text-black bg-highlight-teal p-1 px-2"
					style="border:none;">
					Go There Now
				</button>
				<footer class="aha-footer bg-white">
					<!-- FOOTER PLACEHOLDER -->
				</footer>
			</div>
		</div>
	</div>
</ng-container>

<!-- JOIN AHA ACTION TILE || SSO NOT ENABLED FOR THIS TILE YET - ONCE ENABLED TYPE 3 CAN BE USED -->
<ng-container *ngIf="aats?.tiles?.length && aats?.tiles[0]?.type === 3">
	<div class="card mb-4 overflow-hidden">
		<div class="d-flex card-body p-0">
			<img class="pl-2 align-self-center w-100 h-100" [src]="'/assets/aha-user-tiles/aha/aha_banner.svg'" />
			<img class="w-100 h-100" [src]="'/assets/aha-user-tiles/aha/wave.svg'" />
		</div>
		<!-- ICON FOR MODAL -->
		<div icon (click)="aats?.tileButtonClick()" class="d-flex pointer text-white w-100 heading-size-18 justify-content-center bg-blue-dark p-2">
			Link your AHA.org account today.
		</div>
	</div>
</ng-container>