import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


// AVIA Form Checkbox
// @example start
// <app-avia-form-checkbox
//   ngDefaultControl
//   [disabled]="some_form.controls.some_control.disabled"
//   [field]=""
//   [formControl]="some_form.controls.some_control"
//   [id]="'myIdString'"
//   [label]="'My Label'"
// ></app-avia-form-checkbox>
// @example end

@Component({
  selector: 'app-avia-form-checkbox',
  templateUrl: './avia-form-checkbox.component.html',
  styleUrls: ['./avia-form-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaFormCheckboxComponent),
      multi: true
    }
  ]
})
export class AviaFormCheckboxComponent implements ControlValueAccessor {
  _value: boolean = false;
  @Input()
    get value(): boolean { return this._value; }
    set value(data: boolean) {
      if (!data) return;
      this._value = data;
    }

  @Input() disabled: boolean = false;
  @Input() label: string;
  @Input() field: string;

  @Output() update: EventEmitter<Object> = new EventEmitter<Object>();

  constructor() { }

  sendUpdate(){
    let e = {
      value: this._value,
      label: this.label,
      field: this.field
    };
    this.update.emit(e);
  }

  // Control Value Accessor - These functions must be declared even if empty
  writeValue = (value: any) => {
    this._value = value;
    this.propagateChange(this._value);
  }

  propagateChange = (_: any) => {
    this.sendUpdate();
  }

  registerOnChange = (fn) => {
    this.propagateChange = fn;
  }

  registerOnTouched = () => {  }
}
