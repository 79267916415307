import { TourService } from './../_services/tour.service';
import { filter, startWith, flatMap, distinct } from 'rxjs/operators';
import { Common } from './../common';
import { ActivatedRoute } from '@angular/router';
import { AVIAConnectService } from './../avia-connect.service';
import { Injectable } from '@angular/core';
import { get as _get, find as _find } from 'lodash';
import { Observable, Subscription, interval } from 'rxjs'
import { Nav_Tab_Pills, Nav_Tab, Color_Library } from './../../class';

@Injectable({
  providedIn: 'root'
})

/*
  -- AVIA SERVICE HOOKS --

  this.aviaService.createPost(obj)
  this.aviaService.editPost(id, obj)
  this.aviaService.deletePost(id)
  this.aviaService.getPosts(id)
  this.aviaService.createPostReaction(id, obj)
  this.aviaService.removePostReaction(id, comment_id)
  this.aviaSexrvice.followPost(id, obj)
  this.aviaService.unfollowPost(id, obj)
*/

/*
  Updating on timer
  Keeping sections open while updating
  Pagination - Infinite scroll
  How to show/hide reply
  Replying 2 levels deep
  Premissions coming from backend
  adding reactions
  adding create post maker
  backend code coverage
  swaping between QA and post
*/

export class FeedService {
  private readonly VERBOSE: boolean = false;
  loading: boolean = false;

  readonly COUNT: number = 10;
  readonly POST_TIMER: number = 30000;

  config: any;

  items: any = [];
  support: any;
  session_user_id: number;

  new_posts: boolean = false;

  subscription: any;
  subscription_focus: any;
  subscription_blur: any;

  user_focused = true;

  // TABS
  nav_bar: Nav_Tab_Pills = new Nav_Tab_Pills([
    new Nav_Tab('All Activity', true),
    new Nav_Tab('Articles'),
    new Nav_Tab('Posts'),
    new Nav_Tab('Questions')
  ], 'transparent', Color_Library.gray_800, Color_Library.gray_200, Color_Library.white, Color_Library.gray_600);

  constructor ( public aviaService: AVIAConnectService, public route: ActivatedRoute, public tourService: TourService ) {
    this.subscription_focus = window.addEventListener('focus', event => {
      this.user_focused = true;
    });
    this.subscription_blur = window.addEventListener('blur', event => {
      this.user_focused = false;
    });
  }

  checkForNewPosts(): Observable<any> {
    return interval(this.POST_TIMER)
    // .pipe( startWith(0) )
    .pipe( distinct() )
    .pipe( flatMap( async ():Promise<any> => {

      if(!this.user_focused) return // stops pulling when user is off screen;

      let slim_config = this.generateConfig({
        filters:{
          limit: 1,
          offset: 0,
          exclude_owner_user:true,
          ids_flat: true,
        },
        //we are loading the feed without nesting this means that comments / replies / posts are all on the same level (likes are by default not included unless the exclude list is overriden)
        mark_viewed:false
      });

      let max_created;

      //if there are items only include items with a create date that is greater than max thread create date we have loaded at the moment
      if(this.items && this.items.length > 0) {
        for(let item of this.items) {
          if(max_created == null || item.thread_created > max_created) {
            max_created = item.thread_created
          }
        }
        slim_config.filters.range = {
          "comment.created": {
            "gte":max_created
          }
        }
      }

      //sort based on comment create not using default based on thread_create
      slim_config.filters.sort = {
        "comment.created": {
          "order": "desc"
        }
      }

      // stop calls when we are inactive OR when the server is down... Also must return an iterable
      let data = await this.aviaService.getPosts(slim_config);
      if (data.body && data.body.length > 0) {

        // console.log(data.body[0].modified);
        // console.log(this.items[0].modified);

        let max_created;
        for(let item of this.items) {
          if(!max_created || item.thread_created > max_created) max_created = item.thread_created;
        }

        if(data.body[0].thread_created > max_created) {
          if(!(this.items && this.items.length > 0 && data.body[0].thread_id === this.items[0].thread_id)) {
            this.new_posts = true;
          }
        } else {
          this.new_posts = false;
        }
      }

      return data.body;
    }));
  }

  async init(config) {
    this.VERBOSE && console.log('init');
    this.config = config;

    let my_config = this.generateConfig({
      filters:{
        limit: this.COUNT,
        offset: 0
      },
    });

    this.loading = true;

    this.items = [];
    this.new_posts = false;
    let linked_ids = [];

    if(this.route.snapshot.queryParams.feed_ids) {
      try {
        linked_ids = JSON.parse(this.route.snapshot.queryParams.feed_ids);
      }
      catch(ex) {
        console.log(ex);
      }
    }
    my_config.filters.top_ids = linked_ids;

    let [ p1, p2 ] = await Promise.all([
      this.getPosts(my_config),
      this._httpHelper(this.aviaService.getPostSupport(), {
        default_object: undefined,
        path_to_return: 'body',
      })
    ]);

    this.items = p1;
    this.support = p2;

    this.VERBOSE && console.log('items', this.items);
    this.VERBOSE && console.log('support', this.support);

    await this.initUserAccess();
    this.session_user_id = _get(this, 'aviaService.session.user.id');
    this.loading = false;

    if(this.subscription) this.subscription.unsubscribe();
    this.subscription = this.checkForNewPosts().subscribe(data => {
      try {
        // console.log(data)
      } catch (err) {
        this.aviaService.newrelicError( err );
      }
    });
  };

  /* - - - - - - - create - - - - - - - */
  async createPost(obj) {

    let body: any = {
      "content": obj.content,
      "type":obj.type,
      "tags":[
        // Only add tags if you pass them, do not add tags for children
        ...((this.config && this.config.create && this.config.create.tags) && !obj.parent_id) ? this.config.create.tags : [],
        ...obj.tags
      ],
      "pin": this.config.pin,
      "visibility":obj.visibility,
      "media":obj.media
    }

    if(obj.parent_id) body.parent_id = obj.parent_id;

    return this._httpHelper(this.aviaService.createPost(body), {
      default_object: [],
      path_to_return: 'body',
    });
  }

  async createPostReaction(id, obj) {
    return this._httpHelper(this.aviaService.createPostReaction(id, obj), {
      default_object: undefined,
      path_to_return: 'body[0]',
    });
  }

  async followPost(id, obj) {
    return this._httpHelper(this.aviaService.followPost(id, obj), {
      default_object: undefined,
      path_to_return: 'body[0]',
    });
  }

  async unfollowPost(id, obj) {
    return this._httpHelper(this.aviaService.unfollowPost(id, obj), {
      default_object: undefined,
      path_to_return: 'body[0]',
    });
  }


  generateConfig(config) {
    if(config.filters) {
      config.filters = Object.assign({}, this.config.filters, config.filters);
    }
    config = Object.assign({}, this.config, config);
    return config;
  }

  /* - - - - - - - read - - - - - - - */
  async getPosts(config){
    return this._httpHelper(this.aviaService.getPosts(config), {
      default_object: [],
      path_to_return: 'body',
    });
  }

  async unmutePost(id) {
    // should we aleart user to error and try again later if it does not work?
    await this.aviaService.unmutePost(id);
  }

  async mutePost(id) {
    // should we aleart user to error and try again later if it does not work?
    await this.aviaService.mutePost(id);
  }

  async unpinPost(post, pin_tag) {
    await this.aviaService.unpinPost(post.id, pin_tag);
  }

  async pinPost(post, pin_tag) {
    await this.aviaService.pinPost(post.id, pin_tag);
  }

  async filter(tab) {

    let types;
    switch(tab.name) {
      case 'Articles':
        types = [7];
        break;
      case 'Posts':
        types = [2];
        break;
      case 'Questions':
        types = [4];
        break;
      default:
        types = [2,4,7]
    }

    let filtered_config = {
      filters: {
        types,
        limit: 10,
        offset: 0
      },
    };

    this.config = this.generateConfig(filtered_config);

    let posts = await this.getPosts(this.config);
    this.items = posts;
    return this.items.length;
  }


  /* - - - - - - - update - - - - - - - */
  async editPost(id, obj){

    let body: any = {
      "content": obj.content,
      "tags":[
        ...obj.tags
      ],
      "media":obj.media,
      "visibility":obj.visibility
    }
    return this._httpHelper(this.aviaService.editPost(id, body), {
      default_object: undefined,
      path_to_return: 'body[0]',
    });
  }

  /* - - - - - - - delete - - - - - - - */
  async deletePost(post) {

    const res = await this.aviaService.deletePost(post.id);

    if(res.status === 200) {
      if(post.thread_id === post.id) {
        this.items = this.items.filter(x => x.id != post.id);
      } else if(post.thread_id === post.parent_id) {
        for(let item of this.items) {
          if(post.thread_id === item.id) {
            item.data.replies = item.data.replies.filter(x => x.id != post.id);
            item.data.reply_count--;
          }
        }
      } else {
        for(let item of this.items) {
          if(post.thread_id === item.id) {
            for(let reply of item.data.replies) {
              if(post.parent_id === reply.id) {
                reply.data.replies = reply.data.replies.filter(x => x.id != post.id);
                reply.data.reply_count--;
              }
            }
          }
        }
      }
    }
  }

  async removePostReaction(id, comment_id) {
    return this._httpHelper(this.aviaService.removePostReaction(id, comment_id), {
      default_object: undefined,
      path_to_return: 'body[0]',
    });
  }

  async _httpHelper(req_function, {
    status_check = true,
    default_object = undefined,
    path_to_return = undefined,
  }) {

    const res = await req_function;
    // if not check we just return
    if (status_check === false ) return res;
    // if we get a bad res return the dafult obj
    if (res.status !== 200 ) return default_object;
    // if we speciby a path we return that path
    if (path_to_return !== undefined) return _get(res, path_to_return, default_object);
    // else we just return the responce
    return res;

  }

  getVisibilityText(visibility) {
    let visibilityText = "";
    if(!visibility || visibility.length === 0) {
    }
    else if( visibility.length > 0) {

      let companyText = '';
      for(let vis of visibility) {
        if(vis.org_org_type_id === 2) {
          companyText = ', this Company,';
          break;
        }
      }

      visibilityText = ` will only be visible to Providers${companyText} and Avia`;
    }

    return visibilityText;
  }


  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  * ACCESS
  * The following contain Objects of booleans - e.g., { c: true, r: true, w: true, d: true }
  * How to use in the HTML *ngIf="keychain.core.r"
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

 //public keychain: AccessKeychain = new AccessKeychain();
  private async initUserAccess(rd: Object = {}) {
    let session = await this.aviaService.getSessionSupport();
  };
}
