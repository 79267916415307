import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { AVIAConnectService } from '../../../avia-connect.service';
import { UserRowComponent_Options } from '../../../_components/avia-data-rows/avia-user-row/avia-user-row.component';


@Component({
  selector: 'app-discussion-create-modal',
  templateUrl: './discussion-create-modal.component.html',
  styleUrls: ['./discussion-create-modal.component.scss']
})

export class DiscussionCreateModalComponent implements OnInit {
  private readonly VERBOSE: boolean = false;
  is_open: boolean = false;
  // MODAL STUFF
  @ViewChild('create', { static: true }) createModal: NgbModal;
  @Input() mode: string = "create";
  @Input() discussion = {
    participants: []
  };
  @ViewChild("discussion_name", { static: true }) new_message: any;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>(); // tell the parent component to submit form data
  @Output() update: EventEmitter<any> = new EventEmitter<any>(); // tell the parent component to submit form data
  modal_ref: NgbModalRef;
  modal_options: NgbModalOptions;
  user_display_opts: UserRowComponent_Options = new UserRowComponent_Options();
    local_discussion = {
    participants:[],
    name:null
  };

  constructor(private modalService: NgbModal, public aviaService: AVIAConnectService, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.user_display_opts.view_type = 'list';
    this.user_display_opts.allow_remove = true;
    this.user_display_opts.display_primary_contact = true;
    this.user_display_opts.display_exec_sponsor = true;
    this.local_discussion = JSON.parse(JSON.stringify(this.discussion));
  }

  async ngOnChanges(changes) {
    if(changes.discussion) {
      this.local_discussion = JSON.parse(JSON.stringify(this.discussion));
    }
  }

  updateParticipants($event) {
    this.local_discussion.participants = $event.participants;
  }

  openModal() {
    let options: NgbModalOptions = {
      backdrop: 'static',
      windowClass: 'avia-modal-center avia-modal-md'
    };
    this.modal_ref = this.modalService.open(this.createModal, options);
  }

  closeModal() {
    this.modal_ref.close();
  }

  close() {
    if(this.mode === 'create') {
      this.local_discussion.participants = [];
      this.local_discussion.name = null;
    }
    else {
      this.local_discussion = JSON.parse(JSON.stringify(this.discussion));
    }
    this.closeModal();
  }

  submitParticipants() {
    this.submit.emit(this.local_discussion);
    this.update.emit(this.local_discussion);
    this.close();
  }
}
