import { EventEmitter, Component, ViewChild, ElementRef, OnInit, OnChanges, SimpleChanges, Input, Output } from '@angular/core';
import { AVIAConnectService } from '../../../avia-connect.service';
import * as moment from 'moment';
@Component({
  selector: 'post-media',
  templateUrl: './post-media.component.html',
  styleUrls: ['./post-media.component.scss']
})
export class PostMediaComponent implements OnInit {
  @Input() url: any = null;
  @Input() media: any;
  @Input() edit: boolean = false;
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  media_loading = false;

  constructor(public aviaService: AVIAConnectService) { }

  _media_history = [];


  async ngOnInit() {
    if(this.media && this.media.url) {
      this.url = this.media.url;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes.url && changes.url.currentValue !== changes.url.previousValue) {
      this.media_loading = true;
      let result = await this.aviaService.mediaPreview({"url":this.url});
      this.media_loading = false;
      if(result && result.status == 200) {
        if(this.media) this._media_history.push(this.media);
        this.media  = result.body;
      }
    }
  }

  formatDate(date) {
    return moment(date).format('LL');
  }

  clearMedia() {
    this.media = this._media_history.pop();
  }

  click($event) {
    $event.stopPropagation();
  }
}
