<div class="avia-chat" (mouseenter)="getThread()" (mouseleave)="resetThreadTest()">
  <div *ngFor="let item of cs.thread?.top_level_comments" class="pb-3">
    <app-avia-chat-item
      [item]="item"
      [user_id]="cs.session?.user.id"
      (delete)="deleteMessage($event);"
      (update)="updateMessage($event); false">
    </app-avia-chat-item>
  </div>
  <div class="d-md-flex">
    <textarea [value]="''"
							#chat_item
							autosize
							rows="1"
							placeholder="{{ options?.message | lowercase }}"
							class="input-add px-2 py-1 w-100 border-gray-400"
              (keydown.enter)="postMessage(chat_item.value, email_notify); chat_item.value = ''; false"
              (focus)="dirty = true">
		</textarea>
    <div *ngIf="send_button"
        [ngClass]="{'btn-primary': dirty, 'btn-gray-100': !dirty}"
        class="btn ml-2 float-right"
        (click)="postMessage(chat_item.value, email_notify); chat_item.value = ''; false">
			Add&nbsp;comment
		</div>
	</div>

</div>

