import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitter, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Common } from 'src/app/common';
import { CommonValidators } from 'src/app/common-validators';
import {
  Community,
  CommunitySupport_Status_Types,
  CommunitySupport_Visibility_Types,
  E_Modal_Operating_Mode,
  Session,
  Color_Library
} from 'src/class';
import { CommunitiesService } from '../../communities.service';


@Component({
  selector: 'app-add-edit-cm',
  templateUrl: './add-edit-cm.component.html',
  styleUrls: ['./add-edit-cm.component.scss']
})
export class AddEditCmComponent implements OnDestroy, OnInit {
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template
  readonly VERBOSE = false;

  // NOTE: These must be read before 'data'
  @Input() fullscreen:        boolean = false;
  @Input() hide_activate_btn: boolean = false;
  @Input() mode:              number;
  @Input() static_page:       boolean = false;
  @Input() title_text:        string;

  _community: Community = new Community();
  @Input() // data
    get community():     any { return this._community; }
    set community(value: any) {
      if (value === undefined) return;
      this._community = value;
      this.host_id = this._community.org;
      this.setupFormData();
    }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save:   EventEmitter<any> = new EventEmitter();

  host_id:          number                              = 1;
  session:          Session                             = new Session;
  status_types:     CommunitySupport_Status_Types[]     = [];
  visibility_types: CommunitySupport_Visibility_Types[] = [];

  // FORM STUFF
  cm_form:            FormGroup;
  cm_form_hosts_sub:  Subscription = new Subscription();
  regex_names:        RegExp       = Common.REGEX_NAME;
  regex_website:      RegExp       = Common.REGEX_WEBSITE;

  ac_logo:       AbstractControl;
  ac_owner_objs: AbstractControl;
  ac_whitelogo:  AbstractControl;

  // MODAL STUFF
  @ViewChild('CmAddEdit', { static: true }) content: NgbModal;
  @ViewChild ('colorPicker', { static: true }) colorPicker;
  modal_ref: NgbModalRef;

  // LOGO STUFF
  remove_logo:  string;
  editing_logo: boolean = false;

  remove_white_logo:  string;
  editing_white_logo: boolean = false;

  // File Upload
  error:          string;
  file:           any;
  file_uploaded:  boolean = false;
  progress:       number = 0.0;
  upload_started: boolean = false;
  current_upload_abort: () => any = undefined;


  constructor( public scm: CommunitiesService, private fb: FormBuilder, private modalService: NgbModal ) {
    this.createForm();
  }


  // === INIT ===

  async ngOnInit() {
    this.VERBOSE && console.log('add-edit-cm - mode =', E_Modal_Operating_Mode.getPropName(this.mode));

    let promise_arr = [
      this.scm.aviaService.getSessionSupport(),
      this.scm.aviaService.getCommunitiesSupport(),
    ];
    let promise_res = await Promise.all( promise_arr );
    this.session = promise_res[0];
    this.status_types = promise_res[1].cm_status_types;
    this.visibility_types = promise_res[1].cm_visibility_types;

    // If not a super admin, remove the option to flag a community as archive
    if (!this.scm.keychain_cm.core.d) this.visibility_types = this.visibility_types.filter(type => type.id != 3)
  }

  ngOnDestroy(): void {
    this.cm_form_hosts_sub.unsubscribe();
  }


  // === DATA HANDLING ===

  async onSubmit(): Promise<any> {
    this.VERBOSE && console.log('add-edit-cm-modal::onSubmit called with: ', this.cm_form.value);

    let new_cm: Community = new Community();

    let form_data = this.cm_form.value;
    new_cm.description = form_data.description;
    new_cm.id          = form_data.id;
    new_cm.name        = form_data.name;
    new_cm.owner_objs  = form_data.owner_objs;
    new_cm.org         = this.cm_form.controls.hosts.value[0].id;
    new_cm.logo        = form_data.logo;
    new_cm.white_logo  = form_data.white_logo;
    new_cm.color       = form_data.color;
    new_cm.status      = form_data.status;
    new_cm.visibility  = form_data.visibility;

    if(this.remove_logo && CommonValidators.isLinkFromS3(this.remove_logo)) {
      let logo_link = this.remove_logo;
      let s3_filename = Common.justFilename(logo_link);
      await this.scm.aviaService.undoUploadToS3(s3_filename);
      this.remove_logo = undefined;
    }

    if(this.remove_white_logo && CommonValidators.isLinkFromS3(this.remove_white_logo)) {
      let white_logo_link = this.remove_white_logo;
      let s3_filename = Common.justFilename(white_logo_link);
      await this.scm.aviaService.undoUploadToS3(s3_filename);
      this.remove_white_logo = undefined;
    }

    if (this.mode === E_Modal_Operating_Mode.ADD) delete new_cm.id;
    this.save.emit( new_cm );
    this.close();
  }


  // === FORM CONTROLS (MAIN) ===

  createForm(): void {
    this.cm_form = this.fb.group({
      community_id: 0,
      name:         ['', [Validators.required, Validators.pattern(this.regex_names)]],
      description:  '',
      logo:         '',
      white_logo:   '',
      color:        '',
      hosts:        [[], [Validators.required, Validators.maxLength(1)]],
      owner_objs:   [[], [Validators.required]],
      status:       1,
      visibility:   1,
    });
    this.ac_logo = this.cm_form.controls.logo;
    this.ac_whitelogo = this.cm_form.controls.white_logo;

    this.ac_owner_objs = this.cm_form.controls.owner_objs;

    this.cm_form_hosts_sub = this.cm_form.controls.hosts.valueChanges.subscribe((value) => {
      let new_host_id = (value && value[0] && value[0].id !== undefined) ? value[0].id : undefined;

      if ( new_host_id !== this.host_id ) this.resetOwners();
      if (!new_host_id) {
        this.ac_owner_objs.disable();
      } else {
        this.ac_owner_objs.enable();
      }

      this.host_id = new_host_id;
    });
  }

  setupFormData(): void {
    this.VERBOSE && console.log('add-edit-cm-modal::setupFormData operating mode = ', E_Modal_Operating_Mode.getPropName(this.mode) );
    this.VERBOSE && console.log('add-edit-cm-modal::setupFormData _data =', this._community);

    let common_fields = {
      community_id: this._community.id          ? this._community.id          : 0,
      name:         this._community.name        ? this._community.name        : '',
      description:  this._community.description ? this._community.description : '',
      logo:         this._community.logo        ? this._community.logo        : '',
      white_logo:   this._community.white_logo  ? this._community.white_logo  : '',
      color:        this._community.color       ? this._community.color       : Color_Library.blue_light.slice(1),
      hosts:        this._community.org         ? [this._community.org_obj]   : [],
      owner_objs:   this._community.owner_objs  ? this._community.owner_objs  : [],
      status:       this._community.status      ? this._community.status      : 1,
      visibility:   this._community.visibility  ? this._community.visibility  : 1,
    };
    this.cm_form.reset( common_fields );

    if (this.mode === E_Modal_Operating_Mode.EDIT) {
      this.cm_form.controls.hosts.disable();
      this.cm_form.controls.status.disable();
    } else {
      this.cm_form.controls.hosts.enable();
      this.ac_owner_objs.disable();
      this.cm_form.controls.status.enable();
    }
  }


  // === MODAL STUFF ===

  close(): void {
    this.cancel.emit();
    this.modal_ref.close();
  }

  // NOTE: You can either set the Inputs 'data' and 'data_fallback' first, then call this method
  // OR, simply supply them with the calling of this method
  async open( data = this._community ) {
    let options: NgbModalOptions = {
      'backdrop': 'static',
      size: 'lg',
    };
    if (this.fullscreen) options['windowClass'] = 'avia-modal-fullscreen';

    this.community = data;
    this.modal_ref = this.modalService.open( this.content, options );
  }

  // LOGO
  onChangeLogo( e ) {
    this.remove_logo = undefined;
    this.editing_logo = false;
    this.ac_logo.patchValue(e.link);
    this.ac_logo.markAsDirty();
    this.ac_logo.markAsTouched();
  }

  async removeLogoLink() {
    this.remove_logo = this.ac_logo.value;
    this.ac_logo.patchValue(null);
    this.ac_logo.markAsDirty();
    this.ac_logo.markAsTouched();
  }

  // WHITE_LOGO
  onChangeWhiteLogo( e ) {
    this.remove_white_logo = undefined;
    this.editing_white_logo = false;
    this.ac_whitelogo.patchValue(e.link);
    this.ac_whitelogo.markAsDirty();
    this.ac_whitelogo.markAsTouched();
  }

  async removeWhiteLogoLink() {
    this.remove_white_logo = this.ac_whitelogo.value;
    this.ac_whitelogo.patchValue('');
    this.ac_whitelogo.markAsDirty();
    this.ac_whitelogo.markAsTouched();
  }

  // COLOR
  async changeColor(color){
    color = color.slice(1);
    this.cm_form.controls.color.patchValue(color);
    this.cm_form.controls.color.markAsDirty();
    this.cm_form.controls.color.markAsTouched();
  }

  resetOwners() {
    this.cm_form.controls['owner_objs'].reset();
  }

  // File upload
  async fileChosenFromBrowse(file) {
    // Only allow logos less than 1MB.
    if (file.size < 1000000) {
      this.file = await this.uploadfile(file);
      this.ac_whitelogo.patchValue(this.file.link);
      this.ac_whitelogo.markAsDirty();
      this.ac_whitelogo.markAsTouched();
    } else {
      this.scm.aviaService.notifyFailure("File must be less than 1MB");
    }
  }

  async uploadfile(file) {
    if (file === undefined) { this.error = "choose a file"; return; }

    this.progress = 0.0;
    let s3filename = Common.StrongerUUID();

    this.upload_started = true;

    const s3_upload = await this.scm.aviaService.uploadToS3(
      s3filename,
      file.name,
      file,
      file.type,//'application/octet-stream',
      undefined,
      (s, p, err, abort) => {
        this.progress = (p / s);
        this.error = err;
        this.current_upload_abort = abort;
      });
    if (s3_upload && s3_upload.message == "success" && s3_upload.url && !!file) {
      this.file_uploaded = true;
      this.upload_started = false;
      file.link = s3_upload.url;
      file.s3_filename = s3_upload.file_name;
      return file;
    } else {
      this.error = "upload failed: " + s3_upload.info;
      return {err: this.error};
    }
  }

  async cancelFileUpload() {
    if(this.upload_started && !this.file_uploaded && this.current_upload_abort) {  // If we are in the middle of uploading a file (the xhr didn't finish on uploadToS3), abort
      this.current_upload_abort();
    }

    if(this.file && this.file.link) {
      if(this.progress) {
        await this.scm.aviaService.undoUploadToS3(Common.justFilename(this.file.link));
      }
    }
  }

}
