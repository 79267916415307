<!-- REAL Input -->
<div class="avia-search topnav" *ngIf="!options.fake && in_topnav_bar">
	<div class="search-bar position-relative {{error_class}}"
		[ngClass]="{'bg-white': _options.search_bar_white_bg}"
		>
		<ng-container *ngIf="!autocomplete">
			<input #searchBox
			autocomplete="off"
			name="search"
			placeholder="{{ term ? term : options?.search_text }}"
			type="text"
			maxlength="255"
			[value]="term"
			[attr.tabindex]="mobile_mode ? '1' : undefined"
			[formControl]="obs_term"
			(blur)="toggleSearch(false)"
			(keyup.enter)="submitSearch(searchBox.value)"
			(keyup.shift.enter)="submitSearch(searchBox.value)"
			(click)="toggleSearch(true)"
			/>
		</ng-container>
		<ng-container *ngIf="autocomplete">
			<input #searchBox
			autocomplete="off"
			name="search"
			placeholder="{{ term ? term : options?.search_text }}"
			type="text"
			maxlength="255"
			[value]="term"
			[attr.tabindex]="mobile_mode ? '1' : undefined"
			[formControl]="obs_term"
			(blur)="toggleSearch(false)"
			(keyup.enter)="submitSearch(searchBox.value)"
			(keyup.shift.enter)="submitSearch(searchBox.value)"
			(click)="toggleSearch(true)"
			[inputFormatter]="typeaheadInputFormatter"
			[ngbTypeahead]="typeaheadSearch"
			[focusFirst]="false"
			[resultTemplate]="type_ahead_result"
			/>
			<i *ngIf="!aviaService?.mobile_mode" class="material-icons pointer text-gray-600" (click)="submitSearch(searchBox.value); toggleSearch(true);">search</i>
			<i *ngIf="aviaService?.mobile_mode" class="material-icons pointer text-gray-600" (click)="clear();">close</i>
		</ng-container>
		<ng-container *ngTemplateOutlet="modalTpl"></ng-container>
		<!-- CLOSE BUTTON (for mobile modal mode) -->
		<div class="backlink pointer mr-4 position-absolute" *ngIf="mobile_mode" (click)="close.emit()">
			<i class="material-icons" style="font-size: 24px; vertical-align: top;">close</i>
		</div>
	</div>
</div>

<!-- REAL Input -->
<div class="avia-search regular" *ngIf="!options.fake && !in_topnav_bar">
	<div class="p-1 search-bar position-relative d-flex align-items-center {{error_class}}"
		[ngClass]="{'bg-white': _options.search_bar_white_bg}"
		>
		<ng-container *ngIf="!autocomplete">
			<input #searchBox
			autocomplete="off"
			name="search"
			placeholder="{{ term ? term : options?.search_text }}"
			type="text"
			maxlength="255"
			[value]="term"
			[attr.tabindex]="mobile_mode ? '1' : undefined"
			[formControl]="obs_term"
			(blur)="toggleSearch(false)"
			(keyup.enter)="submitSearch(searchBox.value)"
			(keyup.shift.enter)="submitSearch(searchBox.value)"
			(click)="toggleSearch(true)"
			/>
		</ng-container>
		<ng-container *ngIf="autocomplete">
			<input #searchBox
			autocomplete="off"
			name="search"
			placeholder="{{ term ? term : options?.search_text }}"
			type="text"
			maxlength="255"
			[value]="term"
			[attr.tabindex]="mobile_mode ? '1' : undefined"
			[formControl]="obs_term"
			(blur)="toggleSearch(false)"
			(keyup.enter)="submitSearch(searchBox.value)"
			(keyup.shift.enter)="submitSearch(searchBox.value)"
			(click)="toggleSearch(true)"
			[inputFormatter]="typeaheadInputFormatter"
			[ngbTypeahead]="typeaheadSearch"
			[focusFirst]="false"
			[resultTemplate]="type_ahead_result"
			/>
			<i *ngIf="!aviaService?.mobile_mode" class="material-icons pointer text-gray-600" (click)="submitSearch(searchBox.value); toggleSearch(true);">search</i>
			<i *ngIf="aviaService?.mobile_mode" class="material-icons pointer text-gray-600" (click)="clear();">close</i>
		</ng-container>
		<ng-container *ngTemplateOutlet="modalTpl"></ng-container>
		<!-- CLOSE BUTTON (for mobile modal mode) -->
		<div class="backlink pointer mr-4 position-absolute" *ngIf="mobile_mode" (click)="close.emit()">
			<i class="material-icons" style="font-size: 24px; vertical-align: top;">close</i>
		</div>
	</div>
</div>

<!-- FAKE Input (used for mobile modal access) -->
<div class="avia-search mobile" *ngIf="options.fake">
	<div class="search-bar p-1 search-bar position-relative d-flex align-items-center" [ngClass]="{'bg-white': _options.search_bar_white_bg}">
		<i class="material-icons icon-md pointer text-gray-600">search</i>
		<input #searchBox
			autocomplete="off"
			name="fake_search"
			placeholder="{{ options.search_text }}"
			type="text"
			class="form-control"
		/>
		<ng-container *ngTemplateOutlet="modalTpl"></ng-container>
	</div>
</div>
<!-- Only one ng-content can be in a component at a time -->
<ng-template #modalTpl><ng-content></ng-content></ng-template>

<ng-template #type_ahead_result let-r="result" let-t="term">
	<div class="d-flex h-100" style="
			line-height: 15px;
			font-size: 14px;
			align-items: center;
			/* width: calc(20vw); */
			min-width: 200px;
			min-height: 42px;
		"
		(click)="navigate(r);"
		>
			<div style="min-width:24px;height: 24px;">
					<img style="object-fit: contain;" *ngIf="!r.icon" height="24" width="24" src="{{r.image}}">
					<div *ngIf="r.icon" class="rounded search_circle_icon d-flex align-items-center justify-content-center" [ngClass]="r.icon.bg" style="height:24px;width:24px;">
						<i class="material-icons text-white">{{r.icon.icon}}</i>
					</div>
			</div>

		<div class="wrapper w-100">
			<span class="name text-gray-800" style="font-size:14px" title="&nbsp;{{r.name}}">&nbsp;
				<ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
			</span>
			<span *ngIf="r.descriptor" style="font-size:12px" class="descriptor text-gray-600" title="r.descriptor"> &nbsp;• {{r.descriptor}}</span>
		</div>
	</div>
</ng-template>
