import { AnalyticEvent } from 'src/class';
import { AVIAConnectService } from 'src/app/avia-connect.service';
import { KnowledgeCard } from '../../../../class';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-avia-suggested-topic-card',
  templateUrl: './avia-suggested-topic-card.component.html',
  styleUrls: ['./avia-suggested-topic-card.component.scss']
})
export class AviaSuggestedTopicCardComponent implements OnInit {
  @Input() topic: KnowledgeCard;
  @Input() trending_new: boolean = false;  // Trending topic with x new followers or Topic with x followers.
  @Input() location: String;

  @Output() onFollowClick: EventEmitter<KnowledgeCard> = new EventEmitter();
  button_clicked: boolean = false;

  constructor(public aviaService: AVIAConnectService) { }

  ngOnInit() {
  }

  async followClicked() {
    let following = this.topic.following;
    if (this.button_clicked) return;
    this.button_clicked = true;
    if(this.trending_new) {
      this.topic.following ? this.topic.users_count-- : this.topic.users_count++;
    } else {
      this.topic.following ? this.topic.num_likes-- : this.topic.num_likes++;
    }
    this.topic.following = !this.topic.following;
    let res = await this.aviaService.updateKMcard(this.topic.id, {interested: following ? 0 : 1});
    if(res.status === 200 && res.body && res.body.card) {
      this.onFollowClick.emit(res.body.card);
      // Analytics and refreshing session
      let obj;
      if (this.topic.following) {
        obj = new AnalyticEvent(
          'suggested_km_card_interest_followed',
          {card_id: this.topic.id, location: this.location}
        );
      } else {
        obj = new AnalyticEvent(
          'suggested_km_card_interest_unfollowed',
          {card_id: this.topic.id, location: this.location}
        );
      }

      this.aviaService.createAnalyticEvent(obj);
      this.aviaService.getSessionSupport(true);
    } else {
      // If the network call failed, put it back to original
      this.topic.following ? this.topic.users_count-- : this.topic.users_count++;
      this.topic.following = !this.topic.following;
    }
    this.button_clicked = false;
  }

}
