<div class="data-selector">
	<div class="d-flex justify-content-between">
		<div *ngIf="_options['statictitle']" class="title">{{ _options['statictitle'] }}</div>
		<div *ngIf="_options['required']"><small><i>required</i></small></div>
	</div>

	<div *ngIf="!_options['disabled'] && _options['toggle'] && !toggle_state_on" class="add-toggle-button pb-4" (click)="toggle()">
		<span class="float-right">{{ _options['toggle_text'] }}</span>
	</div>

	<div ngbDropdown #dropdown="ngbDropdown" (blur)="closeAndEmitMulti($event, dropdown);" tabindex="-1" *ngIf="(!_options['disabled'] && !_options['toggle']) || toggle_state_on">
		<div class="selector-wrapper" [ngClass]="{'textbox-style': _options['textbox'] }">
			<div id="selector" [ngClass]="{'statictitle-style': _options['statictitle'] }" ngbDropdownToggle>
				<div *ngIf="_options['textbox']" [ngClass]="{'title':!selection_active, 'name':selection_active}">{{_options['item_prefix']}}{{ title }}</div>
				<span *ngIf="!_options['textbox']" [ngClass]="{'title':!selection_active, 'name':selection_active}">{{_options['item_prefix']}}{{ title }}</span>
				<i class="material-icons" [ngClass]="{'expand': _options['toggle'] }">arrow_drop_down</i>
				<i class="material-icons close" *ngIf="_options['toggle']" (click)="toggle()">close</i>
			</div>
		</div>
		<div ngbDropdownMenu class="dropdown-menu" [ngClass]="{'dropdown-menu-left': _options['dropdown_alignment'] === 'left', 'dropdown-menu-right': _options['dropdown_alignment'] === 'right'}">
			<div class="dropdown-item" *ngFor="let d of data; let i = index" (mousedown)="select(d, $event, dropdown)" [ngClass]="{'selected': (active_data_ids.indexOf(d?.id) > -1) }">

				<!--If the component is displaying users-->
				<div *ngIf="_options['type'] === 'user'" class="avatar-wrapper"
					[class.inactive]="d?.status_obj !== undefined ? d?.status_obj.id === 1 : d?.status !== undefined ? d?.status === 1 : false"
					[class.pending]="d?.status_obj !== undefined ? d?.status_obj.id === 2 : d?.status !== undefined ? d?.status === 2 : false"
					>
					<div class="avatar fade-target">
						<img class="avatar-img" src="{{ d?.avatar }}" />
					</div>
					<div class="name fade-target">{{ d?.fullname }}</div>
				</div>

				<!--If the component is displaying orgs-->
				<div *ngIf="_options['type'] === 'org'" class="logo-wrapper">
					<div class="logo">
						<img class="logo-img" src="{{ d?.logo }}" />
					</div>
					<div class="name">{{ d?.name }}</div>
				</div>

				<!--If the component is displaying cards -->
				<div *ngIf="_options['type'] === 'card' || _options['type'] === 'card_children' || _options['type'] === 'card_parents'" class="card-wrapper">
					<span class="name">{{ d?.name }}</span>
				</div>

				<!--If the component is get data passed to it-->
				<div *ngIf="_options['type'] === 'passed'" class="passed-wrapper">
					<span class="name">{{ d?.name }}</span>
				</div>
			</div>
		</div>
	</div>

	<div class="disabled" *ngIf="_options['disabled']">
		<div class="selector-wrapper" [ngClass]="{'textbox-style': _options['textbox']}">
			<div id="selector">
				<div class="title">{{ title }}</div>
			</div>
		</div>
	</div>
</div>
