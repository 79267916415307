<avia-modal-fullscreen [additionalCssClasses]='["get-on-top"]' #badbrowsermodal >
	<div class="h-100 row" style="background-color: rgba(0,0,0,0);">
		<div class="d-flex align-items-center w-100" style="background-color: rgba(0,0,0,0);">

			<div *ngIf='state===2' class='bg-light col-12 col-md-8 offset-md-2 text-center px-4 py-4'>
				<h3 class='text-accent'>Your</h3>
				<h3 class='text-accent'><span class='font-weight-bold'>enjoyment</span> <span class='text-info'>*</span></h3>
				<h3 class='text-accent'>of this website</h3>
				<div class='text-accent'>(as well as many others)</div>
				<h3 class='text-accent'>will be <div class='font-weight-bold'>much improved</div> by a</h3>
				<h3 class='text-accent'>quick upgrade to this browser.</h3>
				<div>
					<button class='btn btn-primary pr-4 btn-lg my-2' (click)='state=2'>Yes upgrade, I want better!</button>
					<button class='btn btn-outline-secondary btn-sm mb-2'>It's ok, I'm happy not to be able to view this website.</button>
				</div>
				<div class='text-accent'><span class='text-info'>*</span> In fact, the current version of your browser may not even be able to display our app at all!</div>
				<div class='text-accent'>If you are not able to upgrade your browser, for whatever reason, please <a [href]="'mailto:'+EMAILADDY_SUPPORT">let us know</a>!</div>
			</div>

			<div *ngIf='state===1' class='bg-light col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center px-4 py-4'>
				<h3 class='text-accent font-weight-bold mb-4 mt-6'>AVIA Connect not optimized for this browser</h3>
				<div class='row mt-4 mb-4'>
					<div class='text-secondary mx-12 text-center mx-auto w-100'>Unfortunately AVIA Connect does not support the browser you are using.</div>
				</div>
        <div class="text-center">
          Please switch to a supported browser to continue with AVIA Connect.
          <br>
          <a href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMImN-I0bPH5QIVE77ACh1SGwA7EAAYASAAEgKIJfD_BwE&gclsrc=aw.ds">Chrome</a>,
          <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>,
          <a href="https://support.apple.com/downloads/safari">Safari</a>,
          <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a>
        </div>
				<!--
				<h3 class='text-accent mb-4 mt-6' *ngIf='aviaService.deviceInfo.os == "windows" || aviaService.deviceInfo.os == "mac"'>Or try our desktop app (beta!):</h3>
				<div class='row' *ngIf='aviaService.deviceInfo.os == "windows" || aviaService.deviceInfo.os == "mac"'>
					<div class='col' *ngIf='aviaService.deviceInfo.os == "windows"'><a href="assets/AVIAConnect-1.0.0-win.exe"><img width='100px' src="assets/circle-icons/avia.png"/><div class='text-accent'>AVIAConnect<br>(.exe installer)</div></a></div>
					<div class='col' *ngIf='aviaService.deviceInfo.os == "mac"'><a href="assets/AVIAConnect-1.0.0.dmg"><img width='100px' src="assets/circle-icons/avia.png"/><div class='text-accent'>AVIAConnect<br>(.dmg disk image)</div></a></div>
					<div class='col' *ngIf='aviaService.deviceInfo.os == "mac"'><a href="assets/AVIAConnect-1.0.0-mac.zip"><img width='100px' src="assets/circle-icons/avia.png"/><div class='text-accent'>AVIAConnect<br>(.zip file with .app inside)</div></a></div>
				</div>
        -->
        <div class='row'>
					<div class="btn btn-primary mt-5 mb-4 mx-auto" (click)="copyLink()">Copy Url</div>
				</div>

				<!--
				<div class='row'>
					<div class='col'></div>
					<button class='col btn btn-outline-secondary btn-sm mb-4 mt-6'  (click)='state=1'>&lt; back</button>
					<div class='col'></div>
				</div>
				-->
			</div>

		</div>
	</div>
</avia-modal-fullscreen>
