import { Component, ViewChild, ElementRef, OnInit, OnChanges, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MessagesService } from '../../messages.service';
import * as moment from 'moment';
import { AVIAConnectService } from '../../../avia-connect.service';


@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscussionComponent implements OnInit {
  @ViewChild('messages_container', { static: true, read: ElementRef }) public messages_container: ElementRef<any>;
  @ViewChild("new_message_mobile", { static: true }) new_message_mobile: any;
  @ViewChild("new_message", { static: true }) new_message: any;
  @ViewChild("edit_message", { static: false }) edit_message: any;
  @Output() sent: EventEmitter<any> =  new EventEmitter<any>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();
  @Output() blur: EventEmitter<any> = new EventEmitter<any>();
  @Input() discussion;
  @Input() messages;
  dropDownSelect = null;
  dropDownPosition = {
    top:0,
    left:0
  };
  editing;
  highlighted;
  input_disabled = false;
  constructor( public aviaService: AVIAConnectService, public sm: MessagesService, public cRef: ChangeDetectorRef ) { }
  timeAgo(d) {
    return moment(d).fromNow();
  }
  humanTime(d) {
    return moment(d).calendar();
  }
  async ngOnInit() {
    if(!this.aviaService.mobile_mode) {
      this.new_message.nativeElement.focus();
    }

    let message_text;
    if(window.location.href.indexOf("message_text") > -1 || this.aviaService.redirectUrlParams.message_text) {
      let url = new URL(window.location.href);
      message_text = url.searchParams.getAll("message_text");
      // debugger
      this.new_message.nativeElement.value = atob(message_text);
    }

    for(let p of this.discussion.participants) {
      if(p.id === 4110) {
        this.input_disabled = true;
        break;
      }
      else {
        this.input_disabled = false;
      }
    }
  }
  async ngOnChanges(changes) {
    if(changes.messages && changes.messages.currentValue && changes.messages.currentValue.length > 0) {
      if(!changes.messages.previousValue
        || changes.messages.previousValue.length == 0
        || (changes.messages.previousValue[0]?changes.messages.previousValue[0].group_id:null != changes.messages.currentValue[1]?changes.messages.currentValue[1].group_id:null)) {
          setTimeout(()=>{
            if(this.messages_container.nativeElement) {
              this.messages_container.nativeElement.scrollTop = this.messages_container.nativeElement.scrollHeight;
              let parent = this.messages_container.nativeElement.closest(".modal-content");
              if(parent) {
                parent.scrollTop = parent.scrollHeight;
              }
            }
          }, 0)
        }
    }
    if(!this.aviaService.mobile_mode && changes.discussion && changes.discussion.previousValue && changes.discussion.previousValue.comment_group_id != changes.discussion.currentValue.comment_group_id) {
      this.new_message.nativeElement.focus();
    }
  }
  async keypress(e) {
    e.preventDefault();
  }
  showEdit() {
    this.editing = this.dropDownSelect;
  }
  async edit(m) {
    if(this.edit_message.nativeElement.innerText.trim().length > 0) {
      let message_res = await this.sm.editMessage(this.discussion.comment_group_id, m.id, {content:this.edit_message.nativeElement.innerText});
    }
    this.editing = null;
  }
  async delete() {
    let message_res = await this.sm.deleteMessage(this.discussion.comment_group_id, this.dropDownSelect);
    this.editing = null;
  }
  async submit() {
    let nativeTextarea;
    if(this.aviaService.mobile_mode) {
      nativeTextarea = this.new_message_mobile.nativeElement;
    }
    else {
      nativeTextarea = this.new_message.nativeElement;
    }

    let message = nativeTextarea.value;


    if(message.trim().length === 0) return false;
    if(message.length <= 4000) {
      nativeTextarea.value = '';
      nativeTextarea.style.height = 'initial';
      let message_res = await this.sm.createMessage(this.discussion.comment_group_id, {content:message.trim()});
      setTimeout(()=>{
        if(this.messages_container.nativeElement) {
          this.messages_container.nativeElement.scrollTop = this.messages_container.nativeElement.scrollHeight;
          let parent = this.messages_container.nativeElement.closest(".modal-content");
          if(parent) {
            parent.scrollTop = parent.scrollHeight;
          }
        }
      }, 200)
      return true;
    }
    else {
      let res = await this.aviaService.notify(
        'warning',
        'Message too long...',
        'Messages must be under 4000 characters',
        {
          confirmButtonText: 'Okay'
        }
      )
    }
  }
  async updateDiscussion(participants) {
    await this.sm.updateDiscussion(this.discussion.comment_group_id, {participants:participants.map(m=>m.id)});
  }
  async highlight($event, m) {
    if(m.id === this.highlighted || this.aviaService.mobile_mode === false) {
      this.highlighted = null;
    }
    else {
      this.highlighted = m.id;
    }
  }

  async openDropDown($event, m) {
    if($event.srcElement.className.indexOf("custom-dropdown-more") > -1) {
      if(m) {
        let p = $event.srcElement.closest('.message-block');
        this.dropDownPosition.top = p.offsetTop;


        if($event.layerX + 90 > p.clientWidth) {
          this.dropDownPosition.left = p.clientWidth - 90;
        }
        else {
          this.dropDownPosition.left = $event.layerX - 45;
        }

        this.dropDownSelect = m.id;
      }
    }
    else {
      this.dropDownSelect = null;
    }
  }
  inputHasFocus() {
    setTimeout(()=>{
      this.focus.emit();
      if(this.aviaService.mobile_mode && this.aviaService.deviceInfo.browser === 'safari' && this.aviaService.deviceInfo.device === 'iphone')
      {
        setTimeout(()=>{
          if(this.messages_container.nativeElement) {
            this.messages_container.nativeElement.scrollTop = this.messages_container.nativeElement.scrollHeight;
            let parent = this.messages_container.nativeElement.closest(".modal-content");
            if(parent) {
              parent.scrollTop = parent.scrollHeight;
            }
          }
        }, 200)
      }
    },500);
  }
  parseLinks(message) {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);
    //this is quick stripping of html and then a replace with links, angular should handle the rest of the sanitation for us
    return message.replace(/\</gi, '&lt;').replace(/\>/gi, '&gt;').replace(regex, "<a href='$&' target='_blank'>$&</a>");
  }
  inputLostFocus() {
    this.blur.emit();
  }
}
