<div class="wrapper">
  <!-- SIDE SECTION -->
  <!-- SIDE SECTION -->
  <!-- SIDE SECTION -->
  <div class="side">


      <div class="sidetext">
            <!-- AVIA LOGO -->
        <img class="avialogo ln-mb" [src]="'/assets/avia-connect-logo-white.svg'">
        <!-- TEXT -->
        <div class="maintext main-margin">Healthcare’s marketplace for digital transformation.</div>
        <div class="subtext ln-mb">Create an account and help your next customer find you.</div>

        <div class="countstext subtext">
          <div class="countline ln-border-bottom ln-mb" style="width: 75px;"></div>
          <div>
            <span class="fontbold">1,000+</span> 
            <span class="ln-notes"> health system buyers</span>
          </div>
          <div>
            <span class="fontbold">4,000+</span> 
            <span class="ln-notes"> digital health companies</span>
          </div>
        </div>

      </div>

      <div class="sideimg" >
        <img class="lnimage"  width="1396" height="552" [src]="'/assets/linkedin-illustration.svg'">
      </div>
    <!-- LN IMAGE -->

  </div>

  <!-- MAIN SECTION -->
  <!-- MAIN SECTION -->
  <!-- MAIN SECTION -->
  <div class="main">
    <ng-container>
      <div class="signinBox bg-white">
        <div class="">
          <div class="d-block d-lg-flex">
  
            <div class="">
              <div id="{{workflow}}Workflow" class="content-wrapper mx-auto">

                <!-- Standard Login Path -->
                <ng-container *ngIf="!workflow">
                  <h4 *ngIf="phase !== 0" class="status-head">{{ status_head[phase] }}</h4>
                  <p *ngIf="phase !== 1" class="status-msg">{{ status_msg[phase] }}</p>
                  <div *ngIf="phase === 1" class="alert alert-danger" role="alert">
                    <p class="status-msg">{{ status_msg[phase] }}</p>
                  </div>
                  <form class="d-flex flex-column justify-content-around h-100" (ngSubmit)="standardWorkflow( phase, user, pass )">
                    <ng-container *ngIf="phase === 0">
                      <div class="maintext signin">Sign In</div>
                      <div class="form-group">
                        <input autofocus type="text" class="form-control ln-font-size btn-height" id="user" [disabled]="browser_incompatible" required [(ngModel)]="user" name="user" placeholder="Email">
                      </div>
                      <div class="form-group">
                        <input type="password" class="form-control ln-font-size btn-height" id="pass" required [(ngModel)]="pass" name="pass" placeholder="Password">
                      </div>
                      <button type="submit" class="btn btn-lg btn-height font-weight-bold lnbtn signinbtn text-uppercase ln-font-size input" [disabled]="browser_incompatible" [ngClass]="{'btn-blue-pulse': phase !== 1, 'btn-cancel': phase === 1}">{{ action_btn[phase] }}</button>
                      <div *ngIf="browser_incompatible" class="legal-stuff text-center mx-auto text-gray-500">This browser is incompatible with AVIA Connect.  To view AVIA Connect please use a different browser, such as Edge or Chrome.</div>
    
                      <div class="text-center mx-auto ln-text-font-size">
                        By clicking 
                        <b class="font-weight-bolder">Sign In</b>, I agree to the
                        <b
                          class="pointer"
                          routerLink="/termsofservice"
                          [queryParams]="{back: '/signin'}"
                        >Terms of Service</b> & 
                        <b class="pointer" routerLink="/privacy" [queryParams]="{back: '/signin'}">Privacy Policy</b>.
                      </div>
                      <div class="form-group text-center ln-mt mb-four">
                        <div class="text-blue-dark btn btn-link font-weight-bold more-vert-space ln-font-size" (click)="storeUser(user)" routerLink="start">forgot your password?</div>
                      </div>
                      <button type="button" (click)="ssoCallback('aha')" class="btn btn-default btn-lg btn-height font-weight-bold text-gray-800 ahabtn">
                        <img class="aha-logo" src="/assets/pulse/aha/aha.png?v=2">SIGN IN WITH AHA
                      </button>
                      <div class="text-center mx-auto ln-mt ln-text-font-size" (click)="goToAha()">
                          AHA
                          <b class="pointer">Terms of Service</b> &
                          <b class="pointer">Privacy Policy</b>.
                      </div>

                      <div class="border-bottom ln-mb" style="height: 20px;"></div>
                      
                      <button type="button" class="btn btn-lg btn-accent cabnt btn-height font-weight-bold" [routerLink]="['/signup']">
                        CREATE AN ACCOUNT
                      </button>
                    </ng-container>
                    <ng-container *ngIf="phase == 1">
                      <button type="submit" class="mx-auto bottomButton btn btn-lg btn-height btn-cancel">{{ action_btn[phase] }}</button>
                    </ng-container>
                  </form>
                </ng-container>
    
                <!-- Send Create Password Email -->
                <ng-container *ngIf="workflow === 'start'">
                  <h4 class="status-head">{{ status_head[phase] }}</h4>
                  <div class=""><p *ngIf="phase !== 2" class="status-msg">{{ status_msg[phase] }}</p></div>
                  <div *ngIf="phase === 2" class="alert alert-danger" role="alert">
                    <p class="status-msg">{{ status_msg[phase] }}</p>
                  </div>
                  <form class="d-flex flex-column justify-content-around h-100" (ngSubmit)="startWorkflow( phase, user, pass )">
                    <ng-container *ngIf="phase === 0">
                      <div class="form-group mb-0">
                        <label for="user" class="p-0">EMAIL</label>
                        <input type="text" class="form-control" id="user" required [(ngModel)]="user" name="email">
                        <div class="form-group">
                          <div class="btn btn-sm btn-link float-left text-blue-dark" routerLink="">back</div>
                        </div>
                      </div>
    
                    </ng-container>
                    <button type="submit" class="bottomButton btn mx-auto" [ngClass]="{'btn-primary': phase !== 2, 'btn-cancel': phase == 2}">{{ action_btn[phase] }}</button>
                  </form>
                  <div *ngIf="phase === 1">
                    <p class="status-msg">If you don’t get it in a few minutes, please check your spam or junk mail. If you’re still not seeing it, email us at <a href='mailto:support@avia.health?subject=AVIA%20Connect%20--%20No%20Email%20Received'>support@avia.health.</a></p>
                  </div>
                </ng-container>
    
                <!-- Create New Password -->
                <ng-container *ngIf="workflow === 'finish'">
                  <h4 class="status-head">{{ status_head[phase] }}</h4>
                  <p *ngIf="phase !== 1" class="status-msg" [ngClass]="{'mb-2': ( phase === 0 && workflow === 'finish' )}">{{ status_msg[phase] }}</p>
                  <div *ngIf="phase === 1" class="alert alert-danger" role="alert">
                    <p class="status-msg">{{ status_msg[phase] }}</p>
                  </div>
                  <form *ngIf="phase !== 0" class="d-flex justify-content-around mt-auto" (ngSubmit)="finishWorkflow( phase, user, pass )">
                    <button type="submit" class="bottomButton mb-6 btn" [ngClass]="{'btn-primary': phase !== 0, 'btn-cancel': phase === 1}">{{ action_btn[phase] }}</button>
                  </form>
                  <ng-container *ngIf="phase === 0">
                    <app-avia-password-change
                      class="aviaPasswordChangeComponent d-block"
                      [create_mode]="true"
                      [button_title]="'Submit'"
                      [embedded]="true"
                      (passed)="createNewPassword($event)"
                    ></app-avia-password-change>
                  </ng-container>
                </ng-container>
              </div>
              <span *ngIf="!loaded">
                <app-avia-loader [options]="{type: 'generic'}"></app-avia-loader>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

 
 
  
</div>