import { cloneDeep } from 'lodash';
import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { merge, Observable, Subject } from 'rxjs';
import { NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';


type Validation = {
  condition: string,
  message:   string
};


@Component({
  selector: 'app-avia-form-search',
  templateUrl: './avia-form-search.component.html',
  styleUrls: ['./avia-form-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaFormSearchComponent),
      multi: true
    },
    NgbTypeaheadConfig
  ]
})
export class AviaFormSearchComponent implements ControlValueAccessor {
  /*
    To use as a form-control:
      1. In your form builder, use form controls (you can use validators too)
      2. In your HTML, use formControlName="your_form_control_defined"
      e.g.
      *** TypeScript ***
      createForm() {
        this.about_form = this.fb.group({
          states: [[this.states ? this.states : []], [Validators.required]]
        })
      }
      *** HTML ***
      <form [formGroup]="about_form">
        <app-avia-form-search
          formControlName="states"
          ngDefaultControl
          [data_list]="your_list_of_all_the_states"
        ></app-avia-form-search>
      </form>

    To use as a non-form-control element or "stande-alone" component:
      1. Input of [value]
      2. Output of (switched)
      i.e.
      <app-avia-form-search
        [data_list]="your_list_of_all_the_states"
        [disabled]="your_desired_state"
        [selected_list]="your_list_of_the_chosen_states"
      </app-avia-form-search>
  */
  @Input() add_mode:          boolean = false;
  @Input() locked:            boolean = false;
  @Input() disabled:          boolean = false;
  @Input() display_property:  string  = 'name'; // NOTE: This must be read before 'data_list' or 'selected_list'
  @Input() placeholder:       string  = 'Search';
  @Input() selector_property: string  = 'name'; // NOTE: This must be read before 'data_list' or 'selected_list'
  @Input() sort_property:     string  = 'name'; // NOTE: This must be read before 'data_list' or 'selected_list'
  @Input() slice:             number  = 100;
  @Input() allow_only_one:    boolean = false;

  _data_list: any[] = [];
  @Input() //data_list
    get data_list() { return this._data_list; }
    set data_list(value: any[]) {
      if (value === undefined || value === null) return;
      this._data_list = value;
    }

  _selected_list: any[] = [];
  @Input() // selected_list
    get selected_list():     any[] { return this._selected_list; }
    set selected_list(value: any[]) {
      if (value === undefined || value === null) return;
      this._selected_list = cloneDeep(value);
      this.sortSelectedList();
    }

  @Input() validation: Validation[] = [];

  @Output() updateList: EventEmitter<any> = new EventEmitter();

  // Typeahead Search
  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  formatter = (x: {name: string}) => x.name;

  model: string;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        if (term === '') {
          return this._data_list.slice(0, this.slice);
        } else {
          let local_data_list = this._data_list
            .slice(0)
            .filter(v =>
              v[this.sort_property].toLowerCase().indexOf(term.toLowerCase()) > -1
            ).slice(0, this.slice);
          if (this.add_mode) {
            local_data_list = [{
              [this.sort_property]:term,
              [this.display_property]:term,
              [this.selector_property]:term,
              id:term,
              'add_mode':true
            }].concat(local_data_list);
          }
          return local_data_list;
        }
      })
    );
  }

  // Control Value Accessor - These functions must be declared even if empty
  writeValue = (value: any) => {
    if ( value && value.length > -1 ) {
      this._selected_list = value;
    } else {
      this._selected_list = [];
    }
  }

  propagateChange = (_: any) => {
    this.updateList.emit(this._selected_list);
  };

  registerOnChange = (fn) => {
    this.propagateChange = fn;
  }

  registerOnTouched = () => {  }

  constructor(config: NgbTypeaheadConfig) {
    config.focusFirst = false;
  }

  selectItem = ($event: any) => {
    if ( !this.disabled ) {
      let is_in_selected = false;
      if ( this._selected_list && this._selected_list.map(s => s.id).indexOf($event.id) > -1 ) {
        is_in_selected = true;
      }
      if (!is_in_selected || this._selected_list.length === 0){ // eliminate duplicates
        this._selected_list.push($event);
        this.propagateChange(this._selected_list);
      }
    }
    this.sortSelectedList();
  }

  removeItem = ($event: any) => {
    if ( !this.disabled ) {
      if ($event.id != undefined) {
        this._selected_list = this._selected_list.filter(listed => listed.id != $event.id);
        this.propagateChange(this._selected_list);
      }
    }
    this.sortSelectedList();
  }

  sortSelectedList() {
    if (typeof this.selector_property == 'string') {
      this._selected_list.sort((a,b) => (a[this.selector_property].toLowerCase() > b[this.selector_property].toLowerCase()) ? 1 : -1);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
