<div class="radio-group" role="group">
	<button *ngFor="let item of _data; index as i;"
		class="col-12 avia-radiobutton align-items-center btn d-inline-flex mb-1"
		type="button"
		[class.btn-toggle-active] = "active === i"
		[class.btn-toggle-inactive] = "active !== i"
		[class.disabled]="disabled"
		(click)="_update(i)"
	><div class="cbox">
		<i class="material-icons md-8" *ngIf="active === i">fiber_manual_record</i>
	</div>{{item.name}}</button>
</div>
