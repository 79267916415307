<div class="container-fluid" [ngClass]="{'py-4': !aviaService.mobile_mode}">
	<div [ngClass]="{'card px-4 pt-4': !aviaService.mobile_mode, 'p-2': aviaService.mobile_mode}">
		<div class="d-flex align-items-center justify-content-between">
			<div class="heading-size-16" [ngClass]="{'px-2': !aviaService.mobile_mode}">Suggested Topics</div>
			<i class="material-icons pointer text-gray-700" (click)="goBack()">close</i>
		</div>

		<div class="row pt-4 px-2">
			<ng-container *ngFor="let t of suggested_topics; index as i">
				<div *ngIf="i < 7" class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
					<app-avia-suggested-topic-card [location]="'suggested page'" [topic]="t" [trending_new]="true"></app-avia-suggested-topic-card>
				</div>
			</ng-container>
			<div class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
				<div class="border border-gray-300 text-center topic-card p-4 position-relative">
					<div class="heading-size-24 text-center pt-4">See more topics to add to your interests</div>
					<app-avia-interests-modal>
						<button class="pointer btn btn-primary my-4 px-2"><span class="text-white font-size-12">Find New Interests</span></button>
					</app-avia-interests-modal>
					<app-avia-crisscross [color]="blue_pale" class="w-100 position-absolute criss-cross-wrapper"></app-avia-crisscross>
				</div>
			</div>
		</div>

		<div *ngIf="session?.org?.type != 2 && session?.org?.type != 5" class="row pt-2 pb-4 px-2">
			<div id=suggestedConnections class="col-12 heading-size-16 pb-5" [ngClass]="{'px-4': !aviaService.mobile_mode, 'px-2': aviaService.mobile_mode}">Suggested Connections</div>
			<ng-container *ngFor="let u of suggested_connections; index as i">
				<div *ngIf="i < 7" class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
					<app-avia-suggested-connection-card [location]="'suggested page'" [user]="u"></app-avia-suggested-connection-card>
				</div>
			</ng-container>
			<div class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
				<div class="border border-gray-300 text-center topic-card p-4 position-relative">
					<div class="heading-size-24 text-center pt-4">See more people to connect with</div>
					<app-avia-connections-modal>
						<button class="pointer btn btn-primary my-4 px-2"><span class="text-white font-size-12">Find New Connections</span></button>
					</app-avia-connections-modal>
					<app-avia-crisscross [color]="blue_light" class="w-100 position-absolute criss-cross-wrapper"></app-avia-crisscross>
				</div>
			</div>
		</div>
	</div>
</div>
