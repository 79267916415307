import { Component, forwardRef, Input, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, merge, Subject } from 'rxjs';

import {
  AccessKey,
  Org,
} from '../../../../../class';


type event_UpdateOrg = {
  action: string;
  data: Org;
  type: string;
  values: number[];
};

type Validation = {
  condition: string,
  message:   string
};


@Component({
  selector: 'app-avia-form-search-orgs',
  templateUrl: './avia-form-search-orgs.component.html',
  styleUrls: ['./avia-form-search-orgs.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaFormSearchOrgsComponent),
      multi: true
    }
  ]
})
export class AviaFormSearchOrgsComponent implements ControlValueAccessor {

  @Input() multi_select:  boolean      = true;
  @Input() prompt_string: string       = 'Add a new organization';
  @Input() validation:    Validation[] = [];
  @Input() org_types: number[];

  akey:          AccessKey = new AccessKey(true, true, true, true, true);
  disabled:      boolean   = false;
  loading:       boolean   = false;
  selected_list: Org[]     = [];
  // Typeahead Search
  //focus$ = new Subject<string>();
  //click$ = new Subject<string>();
  //model: string;


  constructor() {};

  updateOrgs($event: event_UpdateOrg) {
    if ( !this.disabled ) {
      switch ($event.action) {
        case 'add':
          // prevent duplicates
          let is_in_selected: boolean = false;
          if ( this.selected_list.length > 0 ) {
            if ( this.selected_list.map(s => s.id).indexOf($event.data.id) >= 0 ) is_in_selected = true;
          }

          if ( !is_in_selected ) this.selected_list.push($event.data);
        break;

        case 'delete':
          if($event.data.id) {
            this.selected_list = this.selected_list.filter(item => {
              if (item.id !== $event.data.id) return item;
            })
          }
        break;
      }

      this.propagateChange(this.selected_list);
    }
  };


  // Control Value Accessor - These functions must be declared even if empty

  writeValue(value: any) {
    this.selected_list = (value && value.length > -1) ? value : [];
  };

  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  };

  onTouched: any = () => {};
  registerOnTouched(fn) {
    this.onTouched = fn;
  };

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  };

}
