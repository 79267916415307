import { AVIAConnectService } from './../../avia-connect.service';
import { Component, OnInit } from '@angular/core';
import { Nav_Tab, Session, User, Nav_Tab_Pills, Nav_Tab_Bar,Color_Library } from './../../../class';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  changing_password: boolean = false;
  session:           Session;
  sub_routes:        Nav_Tab_Pills;
  nav_bar:           Nav_Tab_Bar;
  constructor( public aviaService: AVIAConnectService ) { }

  async ngOnInit() {
    this.nav_bar = new Nav_Tab_Bar(
      [],
      false,
      Color_Library.blue_light,
      Color_Library.white,
      Color_Library.green_light);

    let tab: Nav_Tab;

    this.nav_bar.addTab( new Nav_Tab('Email Preferences', true, true) );

    tab = this.nav_bar.addTab( new Nav_Tab('Change Password') );
    tab.show = true;



    this.session = await this.aviaService.getSessionSupport();
  }

  togglePasswordView() {
    this.changing_password = !this.changing_password;
  }
  // fake button to assure the people they changed there email prefs
  emailButtonClicked() {
    setTimeout(() => {
      this.aviaService.notify('success', 'Success!', 'Please allow 48 hours for all systems to update', {showConfirmButton: true, confirmButtonText: 'Okay', timer: false});
    }, 500);

  }

  async changePassword($event) {
    await this.aviaService.updatePassword($event.password);
    this.changing_password = false;
    this.aviaService.notify('success', 'Success!', 'Your password has been changed');
  }

  async updateUser($event) {
    let user_id = this.session.user.id;
    let result = await this.aviaService.updateUser(user_id, $event);
    if( result.err_msg && result.err_msg == 'error' ) {
      console.error( result );
      this.aviaService.notify(
        'error',
        'User not updated!',
        'Nothing was updated, due to an error. Please see the console for more details.'
      )
    }
    this.session = await this.aviaService.getSessionSupport();
  }
}
