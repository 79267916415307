import { FeedService } from './../../feed.service';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-user-tag',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss']
})
export class UserTagComponent implements OnInit {
  @Input() show_text = true;
  @Input() show_avatar = true;
  @Input() show_date = true;
  @Input() user: any;
  @Input() size: string = "sm"; // lg
  @Input() created: Date;
  @Input() prepend_date_text: string;

  posted_time_from_now: string;

  constructor() { }

  humanTime(d) {
    return moment(this.created).fromNow();
  }
  ngOnInit() {
    this.posted_time_from_now = moment(this.created).fromNow();
  }
  have_user(event) {
    return this.user.user_id ? true :false
  }

}
