import { EventEmitter, Component, OnChanges, Input, ViewEncapsulation, Output } from '@angular/core';
@Component({
  selector: 'post-reactions',
  templateUrl: './post-reactions.component.html',
  styleUrls: ['./post-reactions.component.scss', '../../feed.scss']
})
export class PostReactionsComponent implements OnChanges {
  @Input() like_text: string = 'Liked';
  @Input() like_count: number = 0;

  @Input() comment_text: string = 'Comments';
  @Input() comment_count: number = 0;
  @Output() commentsClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnChanges() {
  }

}
