import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  //Renderer,
  ɵlooseIdentical
} from '@angular/core';
import swal from 'sweetalert2';
import * as MediumEditor from 'medium-editor';

import { Common } from 'src/app/common';

/*
* Medium Editor wrapper directive.
*
* Examples:
    <medium-editor
      [(editorModel)]="textVar"
      [editorOptions]="{'toolbar': {'buttons': ['bold', 'italic', 'underline', 'h1', 'h2', 'h3']}}"
      [editorPlaceholder]="placeholderVar"
    ></medium-editor>
*/

@Directive({
  selector: 'medium-editor'
})
export class MediumEditorDirective implements OnInit, OnChanges, OnDestroy {

  private lastViewModel: string;
  private element: HTMLElement;
  private editor: any;
  private active: boolean;

  @Input('editorModel') model: any;
  @Input('editorOptions') options: any;
  @Input('editorPlaceholder') placeholder: string;

  @Output('editorModelChange') update = new EventEmitter();
  @Output('blur') blur = new EventEmitter();
  @Output('focus') focus = new EventEmitter();

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.element.innerHTML = '<div class="me-editable">' + this.model + '</div>';
    this.active = true;

    if (this.placeholder && this.placeholder.length) {
      this.options.placeholder = {
        text : this.placeholder
      };
    }


  const FileDragging = MediumEditor['Extension'].extend({
    name: 'fileDragging',
    allowedTypes: ['image'],
    init: function () {
      MediumEditor['Extension'].prototype.init.apply(this, arguments);
      this.subscribe('editableDrag', this.handleDrag.bind(this));
      this.subscribe('editableDrop', this.handleDrop.bind(this));
      this.subscribe('dragenter', this.handleDrop.bind(this));
    },
    handleDrag: function (event) {
      event.preventDefault();
      event.stopPropagation();
    },
    handleDrop: function (event) {
      // Prevent file from opening in the current window
      event.preventDefault();
      event.stopPropagation();
      swal({
        title: 'Uh Oh - Not Supported',
        html: 'We’re unable to support drag-and-drop images at this time. Please contact <a href="mailto:'+Common.EMAILADDY_SUPPORT+'" target="_blank">'+Common.EMAILADDY_SUPPORT+'</a> for assistance with images.',
        type: 'error'
      });
    }
  });


  if(!this.options.extensions) this.options.extensions = {};
  this.options.extensions['imageDragging'] = new FileDragging();
  this.options.extensions['fileDragging'] = {};
    // Global MediumEditor
  this.editor = new MediumEditor('.me-editable', this.options);
    this.editor.subscribe('editableInput', (event, editable) => {
      this.updateModel();
    });

    // support these custom events:
    // https://github.com/yabwe/medium-editor/blob/master/CUSTOM-EVENTS.md
    this.editor.subscribe('focus', (event, editable) => {
      this.focus.emit(event);
    });

    this.editor.subscribe('blur', (event, editable) => {
      this.blur.emit(event);
    });
  }

  refreshView() {
    if (this.editor) {
      this.editor.setContent(this.model);
    }
  }

  ngOnChanges(changes): void {
    if (this.isPropertyUpdated(changes, this.lastViewModel)) {
      this.lastViewModel = this.model;
      this.refreshView();
    }
  }

  // Emit updated model
  updateModel(): void {
    let value = this.editor.getContent();
    value = value.replace(/&nbsp;/g, '').replace(/<p><br><\/p>/g, '').trim();
    this.lastViewModel = value;
    this.update.emit(value);
  }

  // Remove MediumEditor on destruction of directive
  ngOnDestroy(): void {
    //this.editor.destroy(); // This is commented out because it was strangely keeping old values around so we just outright delete it in the next line
    delete this.editor;
  }

  isPropertyUpdated(changes, viewModel) {
    if (!changes.hasOwnProperty('model')) return false;

    const change = changes.model;

    if (change.isFirstChange()) return true;

    return !ɵlooseIdentical(viewModel, change.currentValue);
  }

}
