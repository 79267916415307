<div class="d-block pt-4">
	<h2 class="heading-size-36 text-center mt-3">Your Products</h2>
	<div class="row align-items-center justify-content-center">
		<div class="col-12">
			<p class="text-center font-weight-light text-gray-800 font-body">
				Add or update information about your products to help potential customers find and learn about your offerings.
			</p>
		</div>

		<div class="w-100 mb-2 d-flex justify-content-center">
			<app-add-edit-product-modal #ProductAddEditModal
				class="mx-auto"
				[data]="new_product_blank"
				[solco_id]="sob?.org.id"
				[mode]="sob?.E_Modal_Operating_Mode.ADD"
				[onboarding]="true"
				[title_text]="'Add a New'"
				[blue_btn]="true"
				(save)="submitAddEditProduct();"
			></app-add-edit-product-modal>
		</div>
		<div class="font-italic font-weight-light text-gray-600 mx-auto" *ngIf="!sob?.products.length">
			You must have at least one product with basic information filled out to proceed.
		</div>
	</div>
</div>
<div *ngFor="let product of sob?.products" class="product-container row d-flex justify-content-center my-4 mx-2 p-4 bg-light">

	<div class="row d-inline-flex w-100">
		<div class="d-inline-flex justify-content-between flex-row w-100">
			<h1 class="heading-size-20">{{product.name}}</h1>
			<div class="edit-icon">
				<app-add-edit-product-modal #ProductAddEditModal
					[data]="product"
					[solco_id]="sob?.org.id"
					[onboarding]="true"
					[mode]="sob?.E_Modal_Operating_Mode.EDIT"
					[title_text]="'Edit Product'"
					(save)="submitAddEditProduct();"
				></app-add-edit-product-modal>
			</div>
		</div>

		<div class="mt-4 font-weight-bold w-100">Short Description</div>
		<div class="w-100 d-flex mb-2">
			<div *ngIf="product.short_desc">{{product.short_desc}}</div>
			<app-add-edit-product-modal #ProductAddEditModal
				*ngIf="!product?.short_desc"
				[data]="product"
				[solco_id]="sob?.org.id"
				[onboarding]="true"
				[mode]="sob?.E_Modal_Operating_Mode.EDIT"
				[missing_field]="'short description'"
				[title_text]="'Edit Product'"
				(save)="submitAddEditProduct();"
			></app-add-edit-product-modal>
		</div>

		<div class="mt-4 font-weight-bold w-100">Description</div>
		<div class="w-100 d-flex mb-2">
			<div *ngIf="product.description">{{product.description}}</div>
			<app-add-edit-product-modal #ProductAddEditModal
				*ngIf="!product?.description"
				[data]="product"
				[solco_id]="sob?.org.id"
				[onboarding]="true"
				[mode]="sob?.E_Modal_Operating_Mode.EDIT"
				[missing_field]="'description'"
				[title_text]="'Edit Product'"
				(save)="submitAddEditProduct();"
			></app-add-edit-product-modal>
		</div>

		<div *ngIf="product.solutions.length" class="mt-4">
			<div *ngFor="let solution of product?.solutions" class="pill-list bg-orange-dark rounded float-left mr-1 mb-1 py-1">
				<span class="badge-pill text-center">
					<span class="font-size-10 font-weight-bold text-uppercase text-white text-nowrap">{{ solution.name }}</span>
				</span>
			</div>
		</div>
		<app-add-edit-product-modal #ProductAddEditModal
			*ngIf="!product.solutions.length"
			[data]="product"
			[solco_id]="sob?.org.id"
			[onboarding]="true"
			[mode]="sob?.E_Modal_Operating_Mode.EDIT"
			[missing_field]="'solution tags'"
			[title_text]="'Edit Product'"
			(save)="submitAddEditProduct();"
		></app-add-edit-product-modal>
	</div>

</div>

