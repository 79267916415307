import { Component, OnInit } from '@angular/core';
import { AVIAConnectService } from './../../avia-connect.service';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

  public running_on_signin: any;

  constructor( private aviaService:AVIAConnectService ) { }

  ngOnInit() {
  }
}
