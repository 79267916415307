import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';

import { AVIAConnectService, } from '../avia-connect.service';

@Component({
  selector: 'app-signup-wrapper',
  templateUrl: './signup-wrapper.component.html',
  styleUrls: ['./signup-wrapper.component.scss']
})
export class SignupWrapperComponent{

  constructor(
    public aviaService: AVIAConnectService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params['utm_content'] || params['utm_source']) {
        this.aviaService.createAnalyticNodeEvent({
          actions:[{
            action_type:"ROUTE_SIGNUP",
            eventkeytype:"ROUTE_SIGNUP",
            eventkeydivisor:1000,
            routeParams: this.aviaService.currentRouteParams
          }]
        })
      }
    })
  }

}
