import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AviaQuickModalComponent } from './../../../_components/avia-quick-modal/avia-quick-modal.component';
import { SearchService } from './../../../_services/search.service';

@Component({
  selector: 'app-avia-users-search',
  templateUrl: './avia-users-search.component.html',
  styleUrls: ['./avia-users-search.component.scss', '../avia-search-bar.scss'],
  providers: [SearchService]
})
export class AviaUsersSearchComponent implements OnInit {
  @Input() editing: boolean = false;
  @Input() error_class: string = '';
  @Input() new_user_add: boolean = false;
  @Input() new_user_skip_email: boolean = false;

  _options: Users_Search_Options = new Users_Search_Options();
  @Input() // options
    set options( inputData: Users_Search_Options ) {
      this._options = new Users_Search_Options();
      for (let i in inputData) {
        this._options[i] = inputData[i];
      }
    };
    get options(): Users_Search_Options { return this._options; };

  is_open:  boolean = false;

  @Output('done') done = new EventEmitter();

  @ViewChild('searchBox', { static: true }) searchBox;

  // MODAL STUFF
  @ViewChild('UsersSearch', { static: true }) content: NgbModal;
  @ViewChild(AviaQuickModalComponent, { static: false } ) add_user_modal: AviaQuickModalComponent ;
  modal_ref: NgbModalRef;

  constructor(public ssu: SearchService, private modalService: NgbModal) { }

  ngOnInit() {
    this.ssu.initSearch('uUser');
  }

  updateSearch($event) {
    if (this.options.search_add_ons) {
      for (let key in this.options.search_add_ons) {
        let obj = this.options.search_add_ons;
        $event[key] = obj[key];
      }
    }
    this.ssu.updateSearch($event);
  }

  resetSearch() {
    this.ssu.resetSearch();
    this.ssu.searching = false;
  }

  add(d) {
    let obj = {
      action: 'add',
      type: 'user',
      values: [d.id],
      data: d
    };
    this.done.emit(obj);
    this.closeModal();
    this.resetSearch();
  }

  addNewUser(d) {
    let obj = {
      action: 'add',
      type: 'user',
      values: [d.id],
      data: d
    };
    this.done.emit(obj);
    this._closeAddUserModal(d);
    this.resetSearch();
  }

  toggleView($event) {
    if (this.ssu.aviaService.mobile_mode && this.ssu.searching) this.closeModal();
    this.ssu.toggleView($event);
  }


  // === MODAL STUFF ===

  closeModal(): void {
    if (this.ssu.aviaService.mobile_mode) {
      this.resetSearch();
      this.modal_ref.close();
    }
  };

  openModal() {
    if (this.ssu.aviaService.mobile_mode) {
      let options: NgbModalOptions = {
        size: 'lg',
        windowClass: 'avia-modal-backdrop'
      };
      this.modal_ref = this.modalService.open(this.content, options);
      // TODO: Implement 'auto-focus' for the input when in Modal mode
      //setTimeout( () => {
      //  this.search_input.focusSearchBox();
      //}, 50);
    }
  };

  _openAddUserModal(){
    this.add_user_modal._open();
  }

  _closeAddUserModal(user) {
    this.resetSearch();
    this.add_user_modal._close();
  }

}


export class Users_Search_Options {
  constructor(
    public search_add_ons: any = null,
    public text: string = 'Search',
    public pulse_id:       any = undefined, // angular8: added to fix error when building prod: "Property does not exist"
  ) { }
}
