<p id='result' *ngIf='!gotit'>LOADING...</p>
<p id='result' *ngIf='gotit'>DONE</p>

<ul>
  <li [routerLinkActive]="['active']">
    <span data-toggle="tooltip" placement="right" ngbTooltip="testing testing testing!" container="body">
      <a id='clickme' routerLink="/12378945ASDFJHK-45789-ZCV-AVIA-134579-LTP1">other test page</a>
    </span>
  </li>
  <li *ngFor="let r of result">{{r.id}} {{r.type}} {{r.flags}} {{r.created}}</li>
</ul>


