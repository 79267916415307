import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Common } from '../common';
import * as moment from 'moment';

// TEMPLATE - DO NOT FORGET TO ADD TO AppModule!
// @Pipe({
//   name: 'name'
// })
// export class Name implements PipeTransform {

//   transform(value: any, args?: any): any {
//     return null;
//   }
// }

@Pipe({
  name: 'excludeFeatures'
})
export class ExcludeFeatures implements PipeTransform {

  transform(features: string[], args?: any): any {
    let features_filtered = features.filter((key) => key !== 'feature');
    return features_filtered;
  }
}

@Pipe({
  name: 'formatBigMoney'
  // ~ Takes a number like: 2283123456.11 and formats it to look like: '$2.3 Billion' ~
  //
  // Currently works for Trillions, Billions and Millions.
  //  - if 'value' falls outside of that range, we default to the built-in Currency Pipe.
  // Currencies supported: USD, GBP, EUR
})
export class FormatBigMoney implements PipeTransform {

  transform(value: number, currency_code: string = "USD", use_symbol:any = 'symbol', decimal_places: number = 2): string {
    let currency_symbol: string = '';
    let val_oom:         number = Math.round(value).toString().length; // Find the order of magnitude of 'value'
    let divisor:         number = 1;
    let magnified_val:   string = '';
    let label:           string = '';

    switch( currency_code ){
      case 'USD':
        currency_symbol = "$";
        break;
      case 'GBP':
        currency_symbol = "£";
        break
      case 'EUR':
        currency_symbol = "€";
        break
    }

    switch( true ){ // 00T,00B,00M,00t,000.00
      case (val_oom > 12):
        label = " Trillion";
        divisor = 1000000000000;
        break;
      case (val_oom > 9):
        label = " Billion";
        divisor = 1000000000;
        break;
      case (val_oom > 6):
        label = " Million";
        divisor = 1000000;
        break;
      default:
        let currencyPipe = new CurrencyPipe(currency_code);
        return currencyPipe.transform(value, currency_code, use_symbol, '1.0-0', 'en-us');
    }

    magnified_val = (value / divisor).toFixed( decimal_places );
    return (use_symbol ? currency_symbol: '') + magnified_val + label + (!use_symbol ? ' ' + currency_code : '');
  }
}


@Pipe({
  name: 'formatFileSize'
})
export class FormatFileSize implements PipeTransform {

  transform(value: number): string {
    let val_oom:         number = Math.round(value).toString().length; // Find the order of magnitude of 'value'
    let label;
    let divisor;
    switch( true ){ // 00T,00B,00M,00t,000.00
      case (val_oom > 12):
        label = " TB";
        divisor = 1000000000000;
        break;
      case (val_oom > 9):
        label = " GB";
        divisor = 1000000000;
        break;
        case (val_oom > 6):
        label = " MB";
        divisor = 1000000;
        break;
      case (val_oom > 3):
        label = " kB";
        divisor = 1000;
        break;
      default:
        label = " bytes";
        divisor = 1;
        break;
    }
    return `${(value / divisor).toFixed(2)} ${label}`;
  }
}

// Takes in an ISO date and returns a string like: '2018-02-20' -> 'Q1 2018'
@Pipe({
  name: 'formatFiscalPeriod'
})
export class FormatFiscalPeriod implements PipeTransform {

  transform(value: string): string {
    if (value === undefined || value === null || value ==='') return '';

    let year_month: string[] = value.split('-');
    let month = parseInt( year_month[1] );
    let quarter: string = '4';

    if ( month < 4) {
      quarter = '1';
    } else if ( month < 7 ) {
      quarter = '2';
    } else if ( month < 10 ) {
      quarter = '3';
    }

    return `Q${quarter} ${year_month[0]}`;
  }
}

@Pipe({
  name: 'formatSearchTerm'
})
export class FormatSearchTerm implements PipeTransform {

  transform(value: string, args?: any): string {
    let formattedString: string = ( value === '' ? `Search` : `Search (${ value })` );

    return formattedString;
  }
}

@Pipe({
  name: 'formatUsPhoneNumber'
  // ~ Takes a string or number like '3125551212' and formats it to look like: '(312) 555-1212' ~
})
export class FormatUsPhoneNumber implements PipeTransform {

  transform(value: any): string {
    value = value + '';
    if( /[^0-9]+/g.test(value) ) return value; // If value does not contain all digits, don't bother formatting it.
    if(/[0-9]{10}/g.test(value) && value.length === 10) {
      return '(' + value.substr(0,3) + ') ' + value.substr(3,3) + '-' + value.substr(6,4);
    } else {
      return value.substr(0,1) + ' (' + value.substr(1,3) + ') ' + value.substr(4,3) + '-' + value.substr(7,4);
    }
  }
}

@Pipe({
  name: 'opsExcludeEnabled'
})
export class OpsExcludeEnabled implements PipeTransform {

  transform(ops: Object[], args?: any): any {
    let ops_filtered = ops.filter((op) => op['name'] !== 'e');
    return ops_filtered;
  }
}

@Pipe({
  name: 'prepareWebAddress'
})
export class PrepareWebAddress implements PipeTransform {

  transform(web_addr: string, prefix: string = ''): string {
    if (web_addr == null || web_addr == undefined) return ''; //If the web address is not set, abort with an empty string
    prefix = /^(http)/.test(web_addr) ? '' : prefix; //If the web address already has 'http' at the beginning, ignore the prefix param
    return prefix + (web_addr = web_addr.replace(/^[\/]+/, '')).replace(/[\/]+$/, '');
  }
}


@Pipe({
  name: 'prettyDomain'
})
export class PrettyDomain implements PipeTransform {

  transform(web_addr: string): string {
    if (web_addr == null || web_addr == undefined) return ""; //If the web address is not set, abort with an empty string
    let domain = Common.parseDomain( web_addr );
    return domain;
  }
}

@Pipe({
  name: 'safeProp'
})
export class SafeProp implements PipeTransform {

  transform(obj: Object, prop: string): any {
    if( obj && obj[prop] ) return obj[prop];
    return null;
  }
}

@Pipe({
  name: 'truncate'
})
export class Truncate implements PipeTransform {

  transform(value: string, limit: number = 10, trail: string = '...', pipe_active = true): string {
    if(!value) return '';
    if(!pipe_active) return value;
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {

  transform(value: any, args?: any): any[] {
    // create instance vars to store keys and final output
    let keyArr: any[] = Object.keys(value),
      dataArr = [];

    // loop through the object,
    // pushing values to the return array
    keyArr.forEach((key: any) => {
      dataArr.push(value[key]);
    });

    console.log(dataArr);

    // return the resulting array
    return dataArr;
    }
}


@Pipe({
  name: 'smarttime'
})
export class Smarttime implements PipeTransform {

  transform(date: string): string {
    let currentDate = new Date();
    let passedDate = new Date(date);
    const ONE_DAY= 24 * 60 * 60 * 1000; /* ms */
    return ((currentDate.getTime() - passedDate.getTime()) > ONE_DAY) ?
      moment(passedDate).format('MMM Do, YYYY') :
      moment(passedDate).format('h:mm a');

  }
}
