import { Component, EventEmitter, Input, Output } from '@angular/core';

// AVIA Radiobutton
// @example <app-avia-radiobutton [active]="0" [data]="[{name:'Item 1'}, {name:'Item 2'}, {name:'Item 3'}]"></app-avia-radiobutton>
// @example <app-avia-radiobutton [active]="1" [data]="[{name:'Item 1'}, {name:'Item 2'}, {name:'Item 3'}]" [disabled]="true"></app-avia-radiobutton>
@Component({
  selector: 'app-avia-radiobutton',
  templateUrl: './avia-radiobutton.component.html',
  styleUrls: ['./avia-radiobutton.component.scss']
})
export class AviaRadiobuttonComponent {
  @Input() active: number = 0;
  @Input() //data
    set data(value: any[]) { this._data = value; }
    get data(): any[] { return this._data; }
    _data: any[];
  @Input() disabled: boolean = false;

  @Output() update: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  _update(active: number) {
    if (!this.disabled) {
      this.active = active;
      this.update.emit({ data: this._data[active], active });
    }
  }

}
