<div class="row justify-content-center">
	<div class="col-12 px-0 text-center">
		<div class="row justify-content-center py-4">
			<div class="col-10 col-md-8 col-lg-6">
				<h2 class="heading-size-36 text-center mt-4">Tell us your interests</h2>
				<div class="text-center font-weight-light text-gray-800 font-body font-size-16">We'll use it to keep you up to date on news and what the rest of the network is doing there as well.</div>
			</div>
		</div>
		<div class="container">
			<div class="row justify-content-center">
				<ng-container *ngFor="let t of sob?.topics; index as i">
					<div class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
						<app-avia-suggested-topic-card [location]="'onboarding'" [topic]="t"></app-avia-suggested-topic-card>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
