import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Common } from '../../common';
import { AVIAConnectService, } from '../../avia-connect.service';
import { AnalyticEvent, E_SomethingHappened } from '../../../class';
import { get as _get } from 'lodash';

@Component({
  selector: 'app-ln-signin',
  templateUrl: './ln-signin.component.html',
  styleUrls: ['./ln-signin.component.scss']
})
export class LnSigninComponent implements OnInit {

  action_btn:  string[] = [];
  loaded:      boolean  = false;
  pass:        string   = '';
  phase:       any      = 0; // angular8: was "number".  prod build gave "This condition will always return 'true' since the types '0' and '1' have no overlap."
  status_head: string[] = [];
  status_msg:  string[] = [];
  token:       string;
  user:        string   = '';
  hide:        boolean  = false;
  workflow:    string; // Supported workflows standard, start, finish
  browser_incompatible: boolean = false;

  VERBOSE: boolean = false;


  constructor(public aviaService: AVIAConnectService, private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef) {
    this.route.params.subscribe(params => {
      const workflow = params.workflow || null;
      if (!workflow || workflow === 'start' || workflow === 'finish') {
        this.workflow = workflow;
        Common.tagAnalytics({"signin.workflow":workflow});
        if (params['phase']) {
          this.phase = parseInt(params['phase']);
        }
      } else {
        this.router.navigate(['/signin']);
      }
    });

    this.route.queryParams.subscribe(params => {
      if(!params['oauth_response'] && !this.aviaService.getLocalValue("user", "") && !this.aviaService.getLocalValue("pw", "")) {
        aviaService.clearCookies();
      }
      if (params['sptoken']) {
        aviaService.signOut({keep_local_storage: true});
        this.token = params['sptoken'];
        this.aviaService.finishEmailWorkflow(this.token, undefined, true)
          .then(data => {
            this.VERBOSE && console.info(data);
            if (data.message === 'expired or invalid token') {
              this.phase = 1;
            }
          });
      } else {
        if(this.workflow && this.workflow === 'finish'){
          this.phase = 1;
        }
      }
      this.hide = false;
      if (params['oauth_response']) {
        this.hide = true;
        let oauth_response = JSON.parse(params['oauth_response']);
        this.signIn(null, null, oauth_response);
      } else if (params['token']) {
        // auto log in using the 'token' queryparam in the URL:
        this.VERBOSE && console.info( "Changing token to " + params['token'] );
        aviaService.changeToken( params['token'] );
      } else if (params['user'] && params['pass']) {
        // auto log in using the 'user' and 'pass' queryparams in the URL:
        this.VERBOSE && console.info( "Signing in with u:" + params['user'] + " p:" + params['pass'] );
        this.user = params['user'];
        this.pass = params['pass'];
        this.standardWorkflow( 0, this.user, this.pass );
      } else if (!params['sptoken']) {
        this.aviaService.publicPageCheck();
      }

      if (params['utm_content'] || params['utm_source']) {
        this.aviaService.createAnalyticNodeEvent({
          actions:[{
            action_type:"ROUTE_SIGNIN",
            eventkeytype:"ROUTE_SIGNIN",
            eventkeydivisor:1000,
            routeParams: this.aviaService.currentRouteParams
          }]
        })
      }
    });
  }


  ngOnInit() {
    this.buildStatusMessages(this.workflow);
    this.changePhase(this.phase);

    if(this.aviaService.deviceInfo.browser === 'ie') {
      this.browser_incompatible = true;
      this.cdr.detectChanges();
    }

    this.loaded = true;
    this.user = this.aviaService.getLocalValue("user", "");
    this.pass = this.aviaService.getLocalValue("pass", "");
    this.VERBOSE && console.info(this.workflow + ' - ' + this.phase);
  }

  ssoCallback(strategy) {
    location.href = `${this.aviaService.baseUrl}/sso/${strategy}/callback`;
  }

  // Workflow Action Control
  standardWorkflow(phase: number, user: string, pass: string) {
    if (user) this.aviaService.saveLocalValue('user', user);
    this.VERBOSE && console.log('standardWorkflow phase: %s', phase);
    switch (phase) {
      case 2:
        this.sendCreationEmail(user);
        this.VERBOSE && console.info('send new email');
      break;

      case 1:
        this.changePhase(0);
        this.VERBOSE && console.info('dismiss');
      break;

      default:
        this.signIn(user, pass, null);
    }
  }

  startWorkflow(phase: number, user: string, pass: string) {
    if (user) this.aviaService.saveLocalValue('user', user);
    switch (phase) {
      case 2:
      case 1:
        this.router.navigate(['/signin']);
        this.VERBOSE && console.info('going home');
      break;

      default:
        this.sendCreationEmail(user);
        this.VERBOSE && console.info('send new email');
    }
  }

  finishWorkflow(phase: number, user: string, pass: string) {
    if (user) this.aviaService.saveLocalValue('user', user);
    switch (phase) {
      case 2:
        this.router.navigate(['/signin']);
        this.VERBOSE && console.info('going home');
      break;

      case 1:
        this.router.navigate(['signin/start/0']);
        this.VERBOSE && console.info('going to start');
      break;

      default:
        this.VERBOSE && console.info('We should not see this');
    }
  }

  storeUser(user) {
    if (user) this.aviaService.saveLocalValue('user', user);
  }

  // Available Actions
  signIn(user: string, pass: string, data: any): Promise<any> {
    this.loaded = false;
    this.user = (user !== undefined) ? user : this.user;
    this.pass = (pass !== undefined) ? pass : this.pass;
    if(!this.pass && data == undefined){
      this.loaded = true;
      return;
    }
    return this.aviaService.signIn(this.user, this.pass, data).then(
      async(data) => {
        this.VERBOSE && console.info(data);//
        if (data.success) {

          this.aviaService.createAnalyticNodeEvent({
            actions:[{
              action_type:"SIGNIN",
              eventkeytype:"SIGNIN",
              eventkeydivisor:1000,
              routeParams: this.aviaService.currentRouteParams,
              //target: user_id, get it on back end
              attributes: {
                page_type: "signin",
              },
            }]
          })

          if(localStorage["redirectUrl"]) {
            this.aviaService.redirectUrl = localStorage["redirectUrl"];
            localStorage.removeItem("redirectUrl");
          }

          if(localStorage["redirectUrlParams"]) {
            this.aviaService.redirectUrlParams = JSON.parse(localStorage["redirectUrlParams"]);
            localStorage.removeItem("redirectUrlParams");
          }

          if (this.aviaService.redirectUrl) {
            //this.router.navigate([this.aviaService.redirectUrl], { queryParams: this.aviaService.redirectUrlParams, replaceUrl: true });
            let session = await this.aviaService.getSessionSupport();

            if ((session && session.user && session.user.onboarding_start == 0) || (session.org.type == 2 && session.user.is_owner && session.org.onboarding == null)) {
              await this.aviaService.checkOnboarding();
            }
            else {
              let link = this.aviaService.redirectUrl;
              if(this.aviaService.redirectUrlParams) {
                link += '?' + (new URLSearchParams(this.aviaService.redirectUrlParams)).toString();
              }

              location.href = link;
            }
          } else {
            let link = '/start'
            if(window.location.search) link = link+window.location.search;
            location.href = link;
          }

          this.aviaService.somethingHappened.emit({
            type: E_SomethingHappened.USER_SIGNED_IN,
            data: {}
          })

        } else {
          let err = data.backend_response ? data.backend_response.body : { status_name: 'default'};
          let brute_force_detected = data.backend_response.status == 429;
          if (err.status_name === 'pending') {
            this.status_msg[2] = 'It looks like your account has not been verified.';
            this.changePhase(2);
          } else if (err.status_name === 'inactive') {
            this.status_msg[1] = 'Hi there! You\'ll need to be invited, so please speak to your nearest AVIA rep!';
            this.changePhase(1);
          } else if (err.status_name === 'invalid') {
            this.status_msg[1] = 'Invalid username or password.';
            this.changePhase(1);
          } else if (err.error === 'invalid_grant') {
            this.status_msg[1] = 'Invalid username or password.';
            this.changePhase(1);
          } else if (err.accessdenied) {
            this.status_msg[1] = 'Oops, your admin didn\'t set up your permission correctly. Please contact your AVIA representative.';
            this.changePhase(1);
          } else if (brute_force_detected) {
            this.status_msg[1] = err.reason;
            this.changePhase(1);
          } else {
            this.status_msg[1] = 'Oops, something went wrong. Please contact your administrator.';
            this.changePhase(1);
          }
        }
        this.loaded = true;
      }, err => { console.error(err); }
    );
  }

  sendCreationEmail(email): void {
    this.loaded = false;
    this.aviaService.startEmailWorkflow(email).then(
      data => {
        this.VERBOSE && console.info(data);
        if (data.message === 'error') {
          this.router.navigate(['signin/start/2']);
        } else {
          this.changePhase(1);
          this.router.navigate(['signin/start/1']);
        }
        this.loaded = true;
      }, err => { console.error(err) }
    )
  }

  createNewPassword($event): void {
    this.loaded = false;
    let pw = $event.password;
    this.aviaService.finishEmailWorkflow(this.token, pw).then(
      data => {
        // ANALYTIC EVENT
        if (data.user) { // Only returns a user if the user had their status changed
          let obj = new AnalyticEvent(
            'user_status_change',
            {user_id: data.user.id, current_status: data.user.status}
          );
          this.aviaService.createAnalyticEvent(obj);
        }
        this.VERBOSE && console.info(data);
        if (data.message === 'error') {
          this.router.navigate(['signin/start/1'], { replaceUrl: true });
        } else {
          this.changePhase(2);
          this.aviaService.saveLocalValue("user", data.email);
          this.aviaService.saveLocalValue("pass", pw);
        }
        this.loaded = true;
        if(this.aviaService.deviceInfo.browser === 'ie') {
          this.browser_incompatible = true;
          this.aviaService.browserProblem = true;
          this.aviaService.browserProblemLock = true;
          this.aviaService.browserProblemSevere = true;
          this.aviaService.browser_nag_dismissed = false;
        }
      },
      err => { console.error(err); }
    );
  }


  // State Control
  buildStatusMessages(workflow): void {
    let email = this.aviaService.getLocalValue("user", "") || 'your email account';

    switch (workflow) {
      case 'start':
        this.status_head = ['Create New Password', 'Instructions Sent', 'Invalid Email'];
        this.action_btn = ['Send Me Instructions', 'Dismiss', 'Dismiss'];
        this.status_msg = [
          '',
          'Instructions for creating your password have been sent to ' + email,
          'Sorry, there is no account associated with that email!'
        ];
      break;

      case 'finish':
        this.status_head = ['Create New Password', 'Link Expired', 'Success'];
        this.action_btn = ['Submit', 'Continue', 'Continue'];
        this.status_msg = [
          'Passwords must include at least one uppercase letter, one lowercase letter, and one numeric character.',
          'It looks like your create password link has expired or is invalid. This can happen sometimes if you clicked an older email.',
          'You can now log in using your new password.'
        ];
        if (this.aviaService.deviceInfo.browser === 'ie') {
          this.status_msg[2] = "You can now log in using your new password. However, Internet Explorer is not supported. Please use a different browser to access AVIA Connect."
        }
      break;

      default:
        this.workflow = undefined;
        this.status_head = ['', 'Invalid Input', 'Account Not Verified'];
        this.action_btn = ['Sign in', 'Dismiss', 'Resend Verification Email'];
        this.status_msg = [
          '',
          'Invalid username or password',
          'It looks like your account has not been verified'
        ];
    }
  }

  goToAha() {
    location.href='https://www.aha.org/center/aha-digital-pulse/help/privacy-policy';
  }

  changePhase(new_phase = 0): void {
    // caps phase
    this.phase = new_phase >= 3 ? 0 : new_phase;
    this.VERBOSE && console.info('phase changed to: ' + new_phase);
  }

  @HostListener("document:keyup",['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if((event.key=="Escape" || event.key=="Enter") && this.phase==1){
      this.changePhase(0);
    }
  }

}
