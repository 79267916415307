<ng-template [ngIf]="ssu.aviaService.mobile_mode" [ngIfElse]="UsersSearch">
	<div class="search-bar-wrapper" (click)="openModal()">
		<!-- MODAL VIEW FOR ADD USER -->
		<app-avia-quick-modal
				*ngIf="new_user_add"
				[title]="'Add to Your Digital Pulse Team'"
				[is_open]="is_open"
				(changed)="is_open = $event?.is_open">
				<!-- INNER MODAL-->
				<app-avia-quick-add-user view
					[hs_id]="options?.search_add_ons?.org"
					[pulse_id]="options?.pulse_id"
					[skip_email]="new_user_skip_email"
					(onChildClose)="_closeAddUserModal($event)"
					(onNewUser)="addNewUser($event)">
				</app-avia-quick-add-user>
				<!-- ICON (HIDDEN FROM VIEW) -->
				<span icon #modalView class="p-1 pointer bg-gray-100 text-gray-700 rounded quick align-center d-none">
					<i class="material-icons align-middle align-self-center align-items-center">search</i>Edit
				</span>
		</app-avia-quick-modal>
		<app-avia-search #searchInput [options]="{ fake: true, search_text: options.text }" [searching]="false" [term]=""></app-avia-search>
	</div>
</ng-template>

<ng-template #UsersSearch>
	<div [ngClass]="{'avia-modal-full-screen avia-modal-full-screen-inverse': ssu.aviaService.mobile_mode}">
			<!-- MODAL VIEW FOR ADD USER -->
			<app-avia-quick-modal
				*ngIf="new_user_add"
				[title]="'Add to Your Digital Pulse Team'"
				[is_open]="is_open"
				(changed)="is_open = $event?.is_open">
				<!-- INNER MODAL-->
				<app-avia-quick-add-user view
					[hs_id]="options?.search_add_ons?.org"
					[pulse_id]="options?.pulse_id"
					[skip_email]="new_user_skip_email"
					(onChildClose)="_closeAddUserModal($event)"
					(onNewUser)="addNewUser($event)">
				</app-avia-quick-add-user>
				<!-- ICON (HIDDEN FROM VIEW) -->
				<span icon #modalView class="p-1 pointer bg-gray-100 text-gray-700 rounded quick align-center d-none">
					<i class="material-icons align-middle align-self-center align-items-center">search</i>Edit
				</span>
		</app-avia-quick-modal>

		<div *ngIf="ssu.aviaService.mobile_mode" class="heading-size-14 mt-2 align-items-center search-label">{{options.text}}</div>
		<!-- SEARCH INPUT -->
		<div class="search-bar-wrapper" [ngClass]="{'mt-4': ssu.aviaService.mobile_mode}" (click)="ssu.searching=true">
			<app-avia-search #searchInput
				[error_class]="error_class"
				[mobile_mode]="ssu.aviaService.mobile_mode"
				[searching]="ssu.searching"
				[term]="ssu.term"
				[options]="{ search_text: options.text }"
				(close)="closeModal()"
				(update_search)="updateSearch($event)"
				(toggle_search)="toggleView($event)"
			></app-avia-search>
		</div>

		<!-- SEARCH RESULTS -->
		<div #searchBox class="avia-search-box" [ngClass]="{'w-100': ssu.aviaService.mobile_mode}" *ngIf="ssu.searching" (clickOutside)="resetSearch()">
			<div class="search-results" [ngClass]="{'border border-gray-200': (ssu.search_results | async)?.length > 0}" *ngIf="ssu.search_results | async as search_result">
				<div class="list-item" *ngFor="let d of search_result"
					[class.inactive]="d?.status_obj !== undefined ? d?.status_obj.name === 'inactive' : d?.status !== undefined ? d?.status === 1 : false"
					[class.pending]="d?.status_obj !== undefined ? d?.status_obj.name === 'pending' : d?.status !== undefined ? d?.status === 2 : false"
					(click)="add(d)"
					>
					<div class="user d-flex flex-shrink-0 pointer p-2">
						<img class="tiny-avatar-img fade-target rounded-circle mr-1" src="{{ d.avatar }}" onerror="this.src='../../../../assets/default-user.jpg'" />
						<div class="title fade-target w-100">{{ d.firstname }} {{ d.lastname }}</div>
					</div>
				</div>
				<!-- ADD NEW USER FROM SEARCH-->
				<div *ngIf="new_user_add" class="list-item" (click)="_openAddUserModal()">
					<div class="user  d-flex pointer p-2">
						<i class="material-icons align-self-center text-gray-400 ml-1">add_box</i>
						<div class=" title align-self-center fade-target w-100">Add a new user</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
