<div class="container-fluid h-100 w-100 bg-white">

	<!-- HEADER -->
	<div class="headerblock row bg-gray-100 p-4">
		<div class="aviablock">
			<img class="avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
		</div>
	</div>

	<div *ngIf="!verify">
		<form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">
			<div class="row">
				<div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
						<!-- font-weight-bold  -->
					<div class="heading-size-24 pt-6 pb-4 text-center">Your organization</div>

					<div class="d-flex align-items-center justify-content-center">
						<div class="line bg-gray-300"></div>
					</div>

					<div class="text-center font-size-16 py-4">Please confirm your email. We use the email to match you to your organization.</div>

					<div>
						<div class="pb-4">
							<label for="email">Email</label>
							<input
								class="form-control"
								formControlName="email"
								id="email"
								name="email"
								placeholder="{{'name@example.com'}}"
								type="email"
							>
							<small *ngIf="formGroup.controls['email']?.hasError('pattern')" class="form-text text-danger">
								Enter a valid email address.
							</small>
							<div *ngIf="!formGroup.controls['email']?.hasError('pattern') && orgs.length === 0" class="form-text text-red-dark">
								<div>The domain <b>{{formGroup.controls['email'].value.split("@")[1]}}</b> is not found in our system. Click below to request help with setting up your profile.</div>
								<a [href]="'mailto:'+EMAILADDY_SUPPORT" class="btn btn-gray-200 w-100 font-size-sm">Contact Support</a>
							</div>
						</div>

						<div class="py-4">
							<label for="orgs">Organization</label>
							<div class="form-group d-flex position-relative">
								<i class="material-icons down position-absolute">arrow_drop_down</i>
								<select id="orgs" class="form-control" formControlName="org">
									<option
										*ngFor="let org of orgs; let i = index"
										[value]="org.id"
										[selected]="i == 0 ? true : null"
									>{{org.name}}</option>
								</select>
							</div>
						</div>

						<div class="py-4 text-center">
							Clicking below will share your profile with AVIA Connect.
							<div class="text-gray-600">
								<br>
								<span class="pointer" routerLink="/termsofservice" [queryParams]="{back: backUrl}">Terms of Service</span> | <span class="pointer" routerLink="/privacy" [queryParams]="{back: backUrl}">Privacy Policy</span>
							</div>
						</div>
					</div>

					<footer class="footer fixed-bottom bg-gray-100 p-4 d-flex align-items-center justify-content-center">
						<button class="btn btn-primary" type="submit" [disabled]="!orgs || orgs.length == 0">Continue</button>
					</footer>
					<!-- <div>The domain xx is not found in our system.</div>
					<div>Click below to request help with setting up your profile</div> -->
					<!-- Contact Support Button -->
				</div>
			</div>
		</form>
	</div>

	<div *ngIf="verify">
		<div class="row">
			<div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
				<!-- font-weight-bold  -->
				<div class="heading-size-24 pt-6 pb-4 text-center">Email verification</div>

				<div class="d-flex align-items-center justify-content-center">
					<div class="line bg-gray-300"></div>
				</div>

				<div class="text-center font-size-16 py-4">We've sent an email to verify your account with AHA. Once you have verified we will automatically log you in to Connect.</div>

				<div class="align-items-center text-center">
					<img class="picture" [src]="'/assets/pulse/aha/aha-avia@4x.png'">
				</div>

				<p class="text-center font-size-16 py-4">If you don’t get it in a few minutes, please check your spam or junk mail. If you’re still not seeing it, email us at <a href='mailto:support@avia.health?subject=AVIA%20Connect%20--%20No%20Email%20Received'>support@avia.health.</a></p>

				<footer class="footer fixed-bottom bg-gray-100 p-4 d-flex align-items-center justify-content-center"></footer>
			</div>
		</div>
	</div>
</div>
