<div class="row justify-content-center">
	<div class="col-12 px-0 text-center">
		<div class="row justify-content-center py-4">
			<div class="col-10 col-md-8 col-lg-6">
				<h2 class="heading-size-36 text-center mt-4">Follow people</h2>
				<div class="text-center w-75 m-auto font-weight-light text-gray-800 font-body font-size-16">Follow people you know, or others who are interested in {{topic_text}}.</div>
			</div>
		</div>
		<div *ngIf="!sob.loading" class="row mx-md-0 mx-1 justify-content-center">
			<div class="col-12 interests-container">
				<app-avia-masonry
					[options]="{num_cols: 1, num_cols_sm: 2, num_cols_md: 3}">
					<div *ngFor="let user of sob?.topics_users"
					class="m-2">
						<app-avia-connection-card
							[user]="user"
							[card_buttons]="{viewPro: true,
								remove: user.user_is_interested
							}"
						></app-avia-connection-card>
					</div>
				</app-avia-masonry>
			</div>
		</div>
		<div *ngIf="sob.loading" class="row no-gutters">
			<div class="col-12 col-md-6 col-lg-4">
				<app-avia-loader *ngIf="sob.loading" [options]="{type: 'cardwlogo'}"></app-avia-loader>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<app-avia-loader *ngIf="sob.loading" [options]="{type: 'cardwlogo'}"></app-avia-loader>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<app-avia-loader *ngIf="sob.loading" [options]="{type: 'cardwlogo'}"></app-avia-loader>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<app-avia-loader *ngIf="sob.loading" [options]="{type: 'cardwlogo'}"></app-avia-loader>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<app-avia-loader *ngIf="sob.loading" [options]="{type: 'cardwlogo'}"></app-avia-loader>
			</div>
			<div class="col-12 col-md-6 col-lg-4">
				<app-avia-loader *ngIf="sob.loading" [options]="{type: 'cardwlogo'}"></app-avia-loader>
			</div>
		</div>
	</div>
</div>
