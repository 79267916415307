
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccessKeychain_Content, AccessKeychain_Solco } from './../../class';
import { AVIAConnectService } from './../avia-connect.service';


@Injectable()
export class ContentLibraryService {
  private readonly VERBOSE: false;

  RESULTS_PER_PAGE: number = 15;
  /* * *
   * "The world needs dreamers and the world needs doers. But above all, the world needs dreamers who do."
   * * */
  sub_routes: Object[] = [
    {
      name: 'Library',
      path: '/intelligence/cl/search',
      active: true
    }
  ];

  params: object = {};

  keychain_cl: AccessKeychain_Content = new AccessKeychain_Content();
  keychain_sc: AccessKeychain_Solco   = new AccessKeychain_Solco();

  constructor(public aviaService: AVIAConnectService, private router: Router) { }

  async init() {
    await this.initUserAccess();
  }

  resetAll(clear_params: boolean = false, target_view: string = 'search') {
    this.params = {};
    let route = '/intelligence/cl/' + target_view;
    if (clear_params) {
      this.router.navigate([route], this.params);
    }
  }

  /* * *
   * USER ACCESS
   * https://docs.google.com/spreadsheets/d/1awa-znaYxfoJjNwQLG6Ogohmwd6sfRzQbp7G37AZIVM/edit#gid=0
   * The following contain Objects of booleans - e.g., { c: true, r: true, w: true, d: true }
   * How to use in the HTML *ngIf="scl.keychain_cl.card.r"
   * * */

  async initUserAccess(resource_desc: Object = {}) {
    let session = await this.aviaService.getSessionSupport();
    this.keychain_cl.feature             = this.aviaService.hasAccess(session, 'cl',   'feature',        'e',    resource_desc);
    this.keychain_cl.card                = this.aviaService.hasAccess(session, 'cl',   'card',           'crwd', resource_desc);
    this.keychain_cl.reports             = this.aviaService.hasAccess(session, 'cl',   'reports',        'r',    resource_desc);
    this.keychain_cl.avia_org_membership = this.aviaService.hasAccess(session, 'avia', 'org_membership', 'c',    resource_desc);

    this.keychain_sc.avia_relationship = this.aviaService.hasAccess(session, 'sc', 'avia_relationship', 'r', resource_desc);

    this.VERBOSE && console.log('content-library.service Content Library Access...');
    if(this.VERBOSE) {
      for (let item of Object.keys(this.keychain_cl)) {
        console.log('Access Key: %s - C:%s, R:%s, W:%s, D:%s, E:%s', item, this.keychain_cl[item].c, this.keychain_cl[item].r, this.keychain_cl[item].w, this.keychain_cl[item].d, this.keychain_cl[item].e);
      }
    }
  }
}
