
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notify-feed',
  templateUrl: './notify-feed.component.html',
  styleUrls: ['../avia-notify-item.component.scss']
})
export class NotifyFeedComponent implements OnInit {

  @Input() notification: any;
  @Input() show_dismiss: boolean = true;

  @Output('dismiss') dismiss = new EventEmitter();
  @Output('goTo')    goTo    = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick($event) {
    this.goTo.emit({'notification': $event});
  }

}
