<!-- DESKTOP TAB BAR -->
<div class="desktop-nav d-none d-md-block navbar-expand sticky-top py-1"
	[ngStyle]="{
		'background-color': (_nav_bar !== undefined && _nav_bar?.background_color !== undefined) ?
			_nav_bar?.background_color :
			'white'
	}">
	<div class="d-flex align-items-center w-100 justify-content-between">
		<!-- LEFT SIDE: NORMAL TABS -->
		<div class="wrapper-left d-inline-block">
			<div class="d-flex align-items-center">
				<ng-container *ngFor="let tab of normal_tabs">
					<ng-container *ngIf="tab?.show">
						<div class="nav-item d-inline-block pointer pl-4 pr-0 py-2">
							<div
								class="nav-link px-0 py-0"
								[ngStyle]="{'color': _nav_bar?.font_color}"
								[ngClass]="{'active': tab?.active}"
								(click)="selectTab(tab)">
								{{ tab?.name | uppercase }}
								<span *ngIf="_nav_bar?.show_badges">({{ tab?.badge }})</span>
							</div>
							<div [ngClass]="{'underline': tab?.active && show_underline}" [ngStyle]="{'border-color': _nav_bar?.highlight_color}"></div>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<!-- RIGHT SIDE: INTERNAL AND/OR HAMBURGER TABS -->
		<div class="wrapper-right d-inline-block float-right" *ngIf="show_right_side_custom_button">
			<div class="navbar py-0">
				<ng-content select="[desktop]"></ng-content>
			</div>
		</div>

		<div class="wrapper-right d-inline-block float-right" *ngIf="!show_right_side_custom_button && show_hamburger">
			<nav id="desktop-hamburger" class="navbar py-0">
				<div class="hamburguesa pointer" (click)="toggleHamburguesa()">
					<i class="material-icons" [ngStyle]="{'color': _nav_bar?.font_color}">menu</i>
				</div>
				<div class="dropdown-menu py-0" *ngIf="!desktop_is_collapsed"
					[ngbCollapse]="desktop_is_collapsed"
					[ngStyle]="{'background-color': _nav_bar?.background_color}"
					[ngClass]="{'thick-bar': thick_bar}"
					[@fadeInDown]="fadeInDown"
					[@fadeOutUp]="fadeOutUp"
					>
					<ul class="nav navbar-nav d-block"
						(clickOutside)="desktop_is_collapsed=true; $event.stopPropagation()"
						[exclude]="'.wrapper-right'">
						<ng-container *ngFor="let tab of hamburger_and_internal_tabs">
							<li *ngIf="tab?.show"
								class="nav-item pointer text-right p-2"
								[ngStyle]="{'background-color': tab?.active ? _nav_bar?.highlight_color : _nav_bar?.background_color}"
								(click)="selectTab(tab); toggleHamburguesa(); $event.stopPropagation()">
								<a [ngStyle]="{'color': _nav_bar?.font_color}"
									[ngClass]="{'active': tab?.active}"
									class="ham-nav-link nav-link">{{ tab?.name | titlecase }}
								</a>
							</li>
						</ng-container>
					</ul>
				</div>
			</nav>
		</div>
	</div>
</div>

<!-- MOBILE TABS -->
<div class="mobile-nav d-block d-md-none sticky-top"
	[ngStyle]="{
		'background-color': (_nav_bar !== undefined && _nav_bar?.background_color !== undefined) ?
			_nav_bar?.background_color :
			'white'
	}">
	<nav id="mobile-hamburger" class="d-block navbar py-0">
		<div class="row align-items-center">
			<div class="col-2 p-1 pl-2">
				<div class="hamburguesa d-flex align-items-center" (click)="toggleHamburguesa(); $event.stopPropagation()">
					<i *ngIf="!_nav_bar?.arrow" class="material-icons" [ngStyle]="{'color': _nav_bar?.font_color}" (click)="toggleHamburguesa(); $event.stopPropagation();">menu</i>
					<i *ngIf="_nav_bar?.arrow"
						class="material-icons"
						[ngStyle]="{'color': _nav_bar?.font_color}"
						(click)="toggleHamburguesa(); $event.stopPropagation()"
					>arrow_drop_down</i>
				</div>
			</div>
			<ng-content select="[mobile]"></ng-content>
		</div>

		<div
			*ngIf="!mobile_is_collapsed"
			class="dropdown-menu p-0"
			[@fadeInDown]="fadeInDown"
			[@fadeOutUp]="fadeOutUp"
			[ngbCollapse]="mobile_is_collapsed"
			[ngStyle]="{'background-color': _nav_bar?.background_color}"
			>
			<ul class="nav navbar-nav d-block"
				[exclude]="'.mobile-nav'"
				[ngStyle]="{'height': height_limit ? height_limit : 'auto'}"
				(clickOutside)="mobile_is_collapsed=true; $event.stopPropagation()">
				<!-- NORMAL TABS -->
				<ng-container *ngFor="let tab of normal_tabs">
					<li *ngIf="tab?.show"
						class="nav-item px-4 py-3"
						[ngStyle]="{'background-color': tab?.active ? _nav_bar?.highlight_color : _nav_bar?.background_color}"
						(click)="selectTab(tab); toggleHamburguesa(); $event.stopPropagation()"
						>
							<a [ngStyle]="{'color': _nav_bar?.font_color}" [ngClass]="{'active': tab?.active}" class="nav-link d-inline">{{ tab?.name }}</a>
							<span *ngIf="_nav_bar?.show_badges" class="float-right"> ({{ tab?.badge }})</span>
					</li>
				</ng-container>
				<div *ngIf="hamburger_and_internal_tabs && hamburger_and_internal_tabs.length > 0"class="px-4">
					<div class="line-divider" [ngStyle]="{'border-color': _nav_bar?.font_color}"></div>
				</div>
				<!-- INTERNAL AND/OR HAMBURGER TABS -->
				<ng-container *ngFor="let tab of hamburger_and_internal_tabs">
					<li *ngIf="tab?.show"
						class="nav-item px-4 py-3"
						[ngStyle]="{'background-color': tab?.active ? _nav_bar?.highlight_color : _nav_bar?.background_color}"
						(click)="selectTab(tab); toggleHamburguesa(); $event.stopPropagation()"
						>
							<a [ngStyle]="{'color': _nav_bar?.font_color}" [ngClass]="{'active': tab?.active}" class="nav-link d-inline">{{ tab?.name }}</a>
							<span *ngIf="_nav_bar?.show_badges" class="float-right"> ({{ tab?.badge }})</span>
					</li>
				</ng-container>
			</ul>
		</div>
	</nav>
</div>
