import { AccessKey, Color_Library } from './../../../../class';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AVIAConnectService } from '../../../avia-connect.service'
import { Common } from '../../../common';

@Component({
  selector: 'app-avia-user-row',
  templateUrl: './avia-user-row.component.html',
  styleUrls: ['../avia-row.scss', './avia-user-row.component.scss']
})
export class AviaUserRowComponent implements OnInit {
  @Input() // color
  set color(str: string) {
    str ? this._color = "#" + str : this._color = this.default_color;
  };
  get color(): string {
    return this._color;
  };
  _color: string;

  public default_color: string = Color_Library.green_bg;
  public reinvite_sent: boolean = false;
  public display_name: string = 'Employee';
  public display_subheader: string;
  public show_dropdown: boolean;
  connect_modal_open: any; // angular8: added to fix error when building prod: "Property does not exist"

  @Input() // options
    set options(inputData: UserRowComponent_Options) {
      this._options = new UserRowComponent_Options();
      for (let i in inputData) {
        this._options[i] = inputData[i];
      }
    };
    get options(): UserRowComponent_Options {
      return this._options;
    };
    _options: UserRowComponent_Options = new UserRowComponent_Options();
  @Input() // akey
    set akey(inputData: AccessKey) {
      this._akey = new AccessKey();
      for (let i in inputData) {
        this._akey[i] = inputData[i];
      }
    };
    get akey(): AccessKey {
      return this._akey;
    };
    _akey: AccessKey = new AccessKey();
  @Input() reinvite_akey: AccessKey = new AccessKey();
  @Input() inactivate_akey: AccessKey = new AccessKey();
  @Input() user: any = {};
  @Input() priorities: number[] = [];
  @Input() inventories: number[] = [];
  @Input() isLast: boolean = false;
  // add to the containing ngFor as follows: *ngFor="let something of somethings; let last = last"
  // then in this component's invocation add: [isLast]="last"

  @Output('done') done = new EventEmitter();
  @Output('changeDropdown') changeDropdown = new EventEmitter();
  @Output('updateOwners') updateOwners = new EventEmitter();
  @Output('updatePrimaryContact') updatePrimaryContact = new EventEmitter();
  @Output('updateExecSponsor') updateExecSponsor = new EventEmitter();
  @Output('updateUserStatus') updateUserStatus = new EventEmitter();


  constructor(public aviaService: AVIAConnectService) { }

  ngOnInit() {
    this.setDisplayName();
    this.setDisplaySubheader();
    this.setShowDropdown();
  }

  setDisplayName() {
    if (this.user) {
      if (this.user.fullname) {
        this.display_name = this.user.fullname;
      } else if (this.user.firstname && this.user.lastname) {
        this.display_name = this.user.firstname + ' ' + this.user.lastname;
      } else if (this.user.firstname) {
        this.display_name = this.user.firstname;
      } else if (this.user.lastname) {
        this.display_name = this.user.lastname;
      }
    }

    if (this.display_name.trim().length === 0) this.display_name = 'Employee';
  }

  setDisplaySubheader() {
    if (this.user) {
      this.display_subheader = this.user.title ? this.user.title : (this.user.emails && this.user.emails[0] && this.user.emails[0].email);
    }
  }

  setShowDropdown() {
    // can logged in user make action user inactive
    let can_inactivate = this.options.allow_inactivate && this.inactivate_akey.w;
    // can logged in user remove action user
    let can_remove = this.options.allow_remove && this._akey.d;
    // can logged in user change action user role
    let can_change_role = this.options.allow_owner_change && this._akey.w;
    // can logged in user make action user primary contact for org
    let can_make_primary = this.options.allow_primary_contact_change && this._akey.w;
    // can logged in user change status of action user
    let can_change_status = this.options.allow_status_change && this._akey.w;

    // if action user is active
    let user_is_inactive = this.user && this.user.status_obj && this.user.status_obj.name === 'inactive';

    this.show_dropdown = user_is_inactive ? false :
      (can_inactivate ||
      can_remove ||
      can_change_role ||
      can_make_primary ||
      can_change_status)
  }

  remove(d) {
    let obj = {
      action: 'delete',
      type: 'user',
      values: [d.id],
      data: d
    };
    this.done.emit(obj);
  }

  changeOwnerStatus(user){
    let obj = {
      action: user.is_owner ? 'delete' : 'add',
      type: 'owner',
      values: [user.id],
      data: user
    };
    this.updateOwners.emit(obj);
  }

  changePrimaryContactStatus(user){
    let obj = {
      action: user.is_primary_contact ? 'delete' : 'add',
      type: 'primaryContact',
      values: [user.id],
      data: user
    };
    this.updatePrimaryContact.emit(obj)
  }

  changeExecutiveSponsorStatus(user){
    let obj = {
      action: user.is_exec_sponsor ? 'delete' : 'add',
      type: 'execSponsor',
      values: [user.id],
      data: user
    };
    this.updateExecSponsor.emit(obj)
  }


  changeStatus(user, status){
    let obj
    switch(status) {
      case 'inactive':
      obj = {
        id: user.id,
        org_id: user.org_obj.id,
        email: user.emails[0].email,
        status: user.status_obj.id,
        action: "Inactivate",
      }
      break;
      case 'reinvite':
      case 'status':
      obj = {
        "id": user.id,
        "org_id": user.org_obj.id,
        "email": user.emails[0].email,
        "status": user.status_obj.id,
        "action": user.status_obj.action_user,
      }
        break;
      default:
    }

    this.updateUserStatus.emit(obj);
  }

  async reinviteUser(user) {
    let data = await this.aviaService.reinviteUser(user.id, user.org_obj.id);
    if (data.message && data.message === 'success') {
      this.aviaService.notify('info', 'Invitation Resent!', 'The user will receive an email soon');
      this.reinvite_sent = true;
    } else {
      this.aviaService.notify('error', 'Error!', data.message);
    }
  }

  changeDrop(d) {
    let obj = {
      user: this.user,
      role: d.field,
    }
    this.changeDropdown.emit(obj);
  }

}
export class UserRowComponent_Options {
  constructor(
    public view_type: string = 'row', //card, list, list-sm, row
    public allow_remove: boolean = false,
    public allow_owner_change: boolean = false,
    public allow_primary_contact_change: boolean = false,
    public allow_reinvite: boolean = false,
    public allow_inactivate: boolean = false,
    public allow_exec_sponsor_change: boolean = false,
    public allow_status_change: boolean = false,
    public display_role_and_status: boolean = false,
    public display_primary_contact: boolean = false,
    public display_exec_sponsor: boolean = false,
    public dropdown_options = [],
    public custom_button_text: string = '',
    public multi_org: boolean = false,
    public known_org: boolean = false,
    public org_badge: boolean = false,
    public connect_btn: boolean = false,
    public connect_modal_open: boolean = false,
    public allow_navigate: boolean = true,
  ) { }
}
