import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
import * as  dataURLtoBlob from 'blueimp-canvas-to-blob';

import { Common } from '../../common';
import { AVIAConnectService } from '../../avia-connect.service';


// AVIA ImageCrop
// @example start
// <avia-image-crop
//  *ngIf='true'
//  [options]='{use_upload:true}'
//  [initdata]="undefined"
//  (onUploadDone)="onUploadDone($event)"
//  (onSourceImageChange)="onSourceImageChange($event)"
//  (onUploadProgress)='onUploadProgress($event)'></avia-image-crop>
// @example end
@Component({
  selector: 'app-avia-onboarding-image-crop',
  templateUrl: './avia-onboarding-image-crop.component.html',
  styleUrls: ['./avia-onboarding-image-crop.component.scss']
})
export class AviaOnboardingImageCropComponent implements OnInit {
  readonly DEBUG:            boolean = false;
  readonly VERBOSE:          boolean = false;

  @Output('onSourceImageChange') onSourceImageChange:any = new EventEmitter(); // called with {canvas, img, fileblob}, when the source image changes
  @Output('onUploadDone') onUploadDone:any = new EventEmitter(); // called with the link URL to the uploaded cropped image, when the crop has been uploaded
  @Output('onSave') onSave:any = new EventEmitter(); // called with the link URL to the uploaded cropped image, when the crop has been uploaded

  @Input() img_link:any = undefined;
  @Input() submit_on_crop:boolean = false;
  @Input() max_height: number = 300;
  @Input() max_width: number = 300;

  cropper:ImageCropperComponent;
  @ViewChild('cropper', { static: false }) set content(c: ImageCropperComponent) {
    this.cropper = c;
  }
  @ViewChild('croppedImage', { static: false }) croppedImage:any;

  cropperSettings: any     = new CropperSettings();
  data:            any;
  error:           string;
  file:            any;
  link:            string  = '';
  progress:        number  = 0.0;
  size:            number  = 0.0;
  submitted:       boolean = false;
  uploading:       boolean = false;
  hide_cropper:    boolean = true;


  constructor( private aviaService: AVIAConnectService ) {  }

  ngOnInit() {
    // cropper settings

    this.cropperSettings.canvasWidth = this.max_width;
    this.cropperSettings.canvasHeight = this.max_height;
    this.cropperSettings._keepAspect = true;

    // resulting output image size / quality (500x500px with 80% quality == ~45k jpeg)
    this.cropperSettings.croppedWidth = 500;
    this.cropperSettings.croppedHeight = 500;


    this.cropperSettings.noFileInput = true;
    //this.cropperSettings.cropperClass = 'my-cropper';
    //this.cropperSettings.dynamicSizing = true; // NOTE: This doesn't seem to work with how we are implementing this component

    // resulting output image size / quality (500x500px with 80% quality == ~45k jpeg)
    this.cropperSettings.compressRatio = 0.8;
    this.cropperSettings.preserveSize = false; // false: use croppedWidth, croppedHeight
    this.cropperSettings.minWithRelativeToResolution = true;

    // touch settings
    this.cropperSettings.markerSizeMultiplier = 2.5;
    this.cropperSettings.touchRadius = 35;
    this.cropperSettings.centerTouchRadius = 35;
    this.cropperSettings.showCenterMarker = true;

    this.data = {};
  }
  ngAfterViewInit() {
    this.cropperSettings.canvasWidth = this.max_width;
    this.cropperSettings.canvasHeight = this.max_height;
    this.cropper.settings = this.cropperSettings;
  }
  ngOnDestroy() {
    if (!this.submitted) this.undoUpload();
  }

  cropPositionChange( e ) {
    let d = this.getData();
    d['position'] = e;
  }

  // returns {canvas, img, fileblob}
  getData() {
    let srcimg = this.cropper.image;
    let srcimg_src = this.cropper.image.image !== undefined ? this.cropper.image.image : this.cropper.image.src;
    let srcblob = (srcimg !== undefined && srcimg_src !== undefined && 0 < srcimg_src.length) ? dataURLtoBlob( srcimg_src ) : undefined;
    let img = this.cropper.cropper.getCroppedImage(false);
    let blob = 0 < img.src.length ? dataURLtoBlob( img.src ) : srcblob;
    let canvas = this.cropper.cropcanvas;
    return {canvas, img, blob, srcimg, srcimg_src, srcblob};
  }
  setData(d) {
    this.cropper.cropper.setImage( d.srcimg );
  }

  // file upload event generated by <input type='file'>
  // e == {target}
  uploadImage( e ) {
    let imgReader:FileReader = new FileReader();
    let image:HTMLImageElement = new Image();

    imgReader.onloadend = (loadEvent:any) => {

      image.src = loadEvent.target.result;
      this.cropper.setImage(image); // doesn't seem to set right away...
      this.hide_cropper = false;
      this.submitted = false;
      this.onSourceImageChange.emit( this.getData() );
    };

    if (e && e.target && e.target.files && e.target.files[0]) {
      const file:File = e.target.files[0];
      imgReader.readAsDataURL(file);
    }
  }

  dropFile( e ) {
    let new_event = { target: { files: [e.file] }};
    this.uploadImage( new_event );
  }

  submit() {
    this.onSave.emit( {link: this.link} )
    this.submitted = true;
    this.img_link = this.link
    this.data = {};
    this.link = "";
    this.hide_cropper = true;
  }

  back() {
    this.undoUpload();
  }

  // button press event generated by <input type='button'>
  // enable with options.use_upload
  async uploadCroppedImage() {
    if (this.cropper.image.src === "") {
      this.VERBOSE && console.log( "no image set yet" );
      return;
    }

    this.uploading = true;
    let data = this.getData();
    let s3filename = Common.StrongerUUID();
    let r:any = await this.aviaService.uploadToS3(
      s3filename,
      "myavatar.png",
      data.blob,
      data.blob.type,
      undefined,
      (s, p, err) => { this.size = s; this.progress = (p / s) * 100.0; this.error = err; }
    );
    //this.link = r.url;
    if (r.message == "success") {
      this.link = r.url;
      data['link'] = r.url;
      data['hash'] = r.hash;
      this.uploading = false;
      this.onUploadDone.emit( data );
      if (this.submit_on_crop) {
        this.submit()
      }

      this.VERBOSE && console.log( "added " + this.link + " to s3")
    } else {
      this.error = "upload failed: " + r.info;
      this.uploading = false;
    }
  }
  undoUpload() {
    if (this.link.length > 0) {
      this.aviaService.undoUploadToS3(Common.justFilename( this.link )).then(r => { });
      this.VERBOSE && console.log( "undoUpload: removed " + this.link + " from s3")
      this.link = '';
    } else {
      this.VERBOSE && console.log( "undoUpload: nothing to undo, no image ever set :-)" );
    }
  }

}
