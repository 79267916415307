import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'comment-reactions',
  templateUrl: './comment-reactions.component.html',
  styleUrls: ['./comment-reactions.component.scss', '../../feed.scss']
})
export class CommentReactionsComponent implements OnInit {
  @Input() like_text: string = 'Liked';
  @Input() like_count: number;

  @Input() comment_text: string = 'Comments';
  @Input() comment_count: number;
  constructor() { }

  ngOnInit() {
  }

}
