<div *ngIf="!mobile_collapse || !is_mobile" class="d-inline-block w-100 pb-2" [ngStyle]="{'background-color': _nav_bar?.background_color}">
	<ul class="nav nav-pills justify-content-center justify-content-md-start">
		<li *ngFor="let tab of _nav_bar?.tabs" class="nav-item">
			<div *ngIf="tab?.show" class="pointer badge-pill mr-2 mt-2 px-3 py-2 py-md-2 px-md-3"
				[ngClass]="{
					'active': tab?.active,
					'font-weight-bold': tab?.bold,
					'badge-constant': constant_size,
					'badge': !constant_size
				}"
				[ngStyle]="{
					'color': tab?.active ? tab?.font_color_active : tab?.font_color,
					'background-color': tab?.active ? tab?.bg_color_active : tab?.bg_color
				}"
				(click)="selectTab(tab)">{{ tab?.name | uppercase }}
			</div>
		</li>
	</ul>
</div>

<div class="pb-2" *ngIf="mobile_collapse && is_mobile">
	<ul class="nav">
		<li *ngFor="let tab of _nav_bar?.tabs" class="w-100 nav-item">
			<div *ngIf="tab?.active" class="w-100 pointer badge"
				[ngClass]="{
					'active': tab?.active,
					'font-weight-bold': tab?.bold
				}"
				[ngStyle]="{
					'color': tab?.active ? tab?.font_color_active : tab?.font_color,
					'background-color': tab?.active ? tab?.bg_color_active : tab?.bg_color
				}"
				(click)="openDropdown()"
				>
				<div class="d-flex p-1 no-gutters align-items-center justify-content-center">
					<div class="text-wrap mx-5 w-100">{{ tab?.name | uppercase }}</div>
					<div class="position-relative">
						<div class="position-absolute arrow"><i class="material-icons">arrow_drop_down</i></div>
					</div>
				</div>
			</div>
		</li>
	</ul>
	<ul *ngIf="show_dropdown" class="nav nav-pills">
		<li *ngFor="let tab of _nav_bar?.tabs" class="w-100">
			<div *ngIf="tab?.show && !tab?.active" class="w-100 nav-item mobile-nav">
				<div class="w-100 pointer badge p-2" (click)="selectTab(tab)">
					{{ tab?.name | uppercase }}
				</div>
			</div>
		</li>
	</ul>
</div>
