import { E_SomethingHappened } from 'src/class';
import { KnowledgeCard } from './../../../class';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AVIAConnectService } from '../../avia-connect.service'

@Component({
  selector: 'app-avia-interests-modal',
  templateUrl: './avia-interests-modal.component.html',
  styleUrls: ['./avia-interests-modal.component.scss']
})
export class AviaInterestsModalComponent implements OnInit {
  readonly VERBOSE = false;

  // NOTE: These must be read before 'data'
  @Input() fullscreen:        boolean   = true;
  @Input() static_page:       boolean   = false;

  topics: KnowledgeCard[] = [];

  // MODAL STUFF
  @ViewChild('AviaInterestsModal', { static: false }) content: NgbModal;
  modal_ref: NgbModalRef;

  constructor( public aviaService: AVIAConnectService, private modalService: NgbModal ) {  };

  async ngOnInit() {
    let res = await this.aviaService.getTopFollowedTopics({limit: 50});
    if(res.status === 200 && res.body) {
      this.topics = res.body;
    }
  };

  close() {
    this.aviaService.somethingHappened.emit({
      type: E_SomethingHappened.NEWSFEED_REFRESHED,
      data: { quick_links: true, action_tiles: true, suggested: true }
    });
    this.modal_ref.close();
  };

  // NOTE: You can either set the Inputs 'data' and 'data_fallback' first, then call this method
  // OR, simply supply them with the calling of this method
  async open( data = undefined ) {
    let options: NgbModalOptions = {
      backdrop: 'static',
      //scrollable: true, // Coming in @ng-bootstrap/ng-bootstrap 5.0.0
      size: 'lg',
    };
    options.windowClass = 'avia-interests-modal';
    if (this.fullscreen) {
      options.windowClass += ' avia-modal-fullscreen';
    } else {
      options.windowClass += ' avia-modal-xl';
    }
    this.modal_ref = this.modalService.open( this.content, options );
  };

}
