import { cloneDeep } from 'lodash';
import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, merge, Subject } from 'rxjs';

import {
  AccessKey,
  Topic,
} from '../../../../../class';


type event_UpdateTopic = {
  action: string;
  data:   Topic;
  type:   string;
  values: number[];
};

type Validation = {
  condition: string,
  message:   string
};


@Component({
  selector: 'app-avia-form-search-topics',
  templateUrl: './avia-form-search-topics.component.html',
  styleUrls: ['./avia-form-search-topics.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaFormSearchTopicsComponent),
      multi: true
    }
  ]
})
export class AviaFormSearchTopicsComponent implements ControlValueAccessor {

  @Input() multi_select:  boolean      = true;
  @Input() show_no_matches:  boolean      = false;
  @Input() ng_class: any = {};
  @Input() prompt_string: String       = 'Add a new organization';
  @Input() validation:    Validation[] = [];
  @Input() recommendations: object[];
  @Input() options: object = {
    text: 'Link topics to new content'
  }
  @Input()
    get selected_list() { return this._selected_list; }
    set selected_list(data: Topic[]) {
      if(data != undefined) {
        this._selected_list = cloneDeep(data);
      }
    }
  _selected_list: Topic[]   = [];

  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter();

  akey:          AccessKey = new AccessKey(true, true, true, true, true);
  disabled:      boolean   = false;
  loading:       boolean   = false;


  // Typeahead Search
  //focus$ = new Subject<string>();
  //click$ = new Subject<string>();
  //model: string;


  constructor() {};

  updateTopics($event: event_UpdateTopic) {
    if ( !this.disabled ) {
      switch ($event.action) {
        case 'add':
          // make sure topic is valid
          // We don't think this is necesary, but if we have problems with elastic/recommendations check here
          // if( !$event.data.content ) return;

          // prevent duplicates
          let is_in_selected: boolean = false;
          if ( this.selected_list.length > 0 ) {
            if ( this.selected_list.map(s => s.id).indexOf($event.data.id) >= 0 ) is_in_selected = true;
          }

          if ( !is_in_selected ) {
            delete $event.data.highlights;
            delete $event.data.keywords;
            this.selected_list.push($event.data);
          }
        break;

        case 'delete':
          if($event.data.id) {
            this.selected_list = this.selected_list.filter(item => {
              if (item.id !== $event.data.id) return item;
            })
          }
        break;
      }

      this.propagateChange(this.selected_list);
    }
  };


  // Control Value Accessor - These functions must be declared even if empty

  writeValue(value: any) {
    this.selected_list = (value && value.length > -1) ? value : [];
  };

  propagateChange = (_: any) => {
    this.onChange.emit(this.selected_list);
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  };

  onTouched: any = () => {};
  registerOnTouched(fn) {
    this.onTouched = fn;
  };

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  };

}
