import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-post-textarea-link-modal',
  templateUrl: './post-textarea-link-modal.component.html',
  styleUrls: ['./post-textarea-link-modal.component.scss']
})
export class PostTextAreaLinkModalComponent implements OnInit {
  modal_ref: NgbModalRef;
  modal_options: NgbModalOptions;
  @ViewChild('link', { static: true }) linkModal: NgbModal;
  @Output() create: EventEmitter<any> = new EventEmitter<any>();
  @Output() open: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  link_val = {
    text:null,
    link:null
  }

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  openModal() {
    let options: NgbModalOptions = {
      backdrop: 'static',
      windowClass: 'avia-modal-center avia-modal-md'
    };
    this.modal_ref = this.modalService.open(this.linkModal, options);
    this.open.emit();
  }

  closeModal() {
    this.link_val.text = null;
    this.link_val.link = null;
    this.modal_ref.close();
    this.close.emit();
  }

  createLink() {
    this.create.emit(this.link_val);
    this.closeModal();
  }
}
