import { filter } from 'rxjs/operators';
import { fadeIn, fadeOut } from 'ng-animate';
import { transition, useAnimation, trigger } from '@angular/animations';
import { GlobalNavbarService } from './global-navbar.service';
import { AviaSearchComponent } from './../../_components/avia-search/avia-search.component';
import { AnalyticEvent, E_SomethingHappened } from 'src/class';
import { Router, NavigationStart } from '@angular/router';
import { AVIAConnectService } from './../../avia-connect.service';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-global-navbar',
  templateUrl: './global-navbar.component.html',
  styleUrls: ['./global-navbar.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', useAnimation(fadeIn, { params: { timing: .45 } }))]), //:enter is an alias for void => *
    trigger('fadeOut', [transition(':leave', useAnimation(fadeOut, { params: { timing: .35 } }))]), //:leave is an alias for * => void
  ]
})
export class GlobalNavbarComponent implements OnInit {
  @ViewChild ('search_bar', { static: false }) search_bar: AviaSearchComponent;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if(event.keyCode == 27){
      this.gns.deselectAllTabs();
    }
  }

  term: string  = "";

  page_y_offset: number;

  hide_mobile_top_bar_on_scroll: boolean = false;

  notificationCountMap: { [k: string]: string } = {
    '=0': '',
    '>99': '99+',
    'other': '#'
  };

  constructor(public gns: GlobalNavbarService, public  aviaService: AVIAConnectService, private router: Router) {
    this.router.events.subscribe((event) => {
      if(this.search_bar && !router.url.includes('search')) this.search_bar.clear();
    });
  }

  ngOnInit() {
    this.gns.init();
    this.gns.tabs[0].active = true;
    window.addEventListener('scroll', this.scroll, true);
  }

  logoClicked() {
    this.aviaService.goToNewApp();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Pulse Navigation
  continuePulse() {
    this.aviaService.has_dismissed_pulse = true

    let redirect = this.aviaService.redirectUrl ? this.aviaService.redirectUrl : '/start';
    this.router.navigate([redirect]);
  }

  navigateBack() {
    window.history.back();
  }

  toggleShowMobileNotifications() {
    this.gns.show_mobile_notifications = !this.gns.show_mobile_notifications
  }
  updateSearch(e) {
    this.router.navigate(['/search'], { queryParams: {'term': e.term, 'search_type': 'all', active_users_only: e.active_users_only } });
    let obj = new AnalyticEvent(
      'search',
      {},
      e.term
    );
    this.term = e.term;
    this.aviaService.createAnalyticEvent(obj);
  }

  clearSearch() {
    if (this.search_bar) this.search_bar.clear();
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if((number > this.page_y_offset) && (number > 100)) {
      this.hide_mobile_top_bar_on_scroll = true;
    }
    else {
      this.hide_mobile_top_bar_on_scroll = false;
    }
    this.page_y_offset = number;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Router Outlet
  onActivate($event) {  }

  onDeactivate($event) {
    this.clearSearch();
  }
}
