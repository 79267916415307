<div *ngIf="gns?.aviaService?.mobile_mode">

		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[name]="'My Activity'"
				[routerLink]="['/profiles/in', gns.user_id]"
				[queryParams]="{ activeTab: 'Activity' }"
				[background_type]="1"
				[color]="gns.red_light"
				(click)="gns.closeMobileDrawer()"
			></app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.green_dark"
				[name]="'My Saved Items'"
				[background_type]="3"
				[routerLink]="['/profiles/in/', gns.user_id]"
				[queryParams]="{activeTab: 'Saved'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.blue_dark"
				[name]="'My Topics'"
				[background_type]="2"
				[routerLink]="['/profiles/in/', gns.user_id]"
				[queryParams]="{activeTab: 'Topics'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.violet_dark"
				[name]="'My Companies'"
				[background_type]="3"
				[routerLink]="['/profiles/in/', gns.user_id]"
				[queryParams]="{activeTab: 'Companies'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>

		<div *ngIf="gns.show_groups" class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.blue_mid"
				[name]="'My Groups'"
				[background_type]="1"
				[routerLink]="['/profiles/in/', gns.user_id]"
				[queryParams]="{activeTab: 'Groups'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>

		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.teal_dark"
				[name]="'My Connections'"
				[background_type]="2"
				[routerLink]="['/profiles/in/', gns.user_id]"
				[queryParams]="{activeTab: 'Connections'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>

		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.teal_dark"
				[name]="'Settings'"
				[background_type]="2"
				[routerLink]="['/Settings']"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>

		<div class="p-2">
			<app-nav-card
				class="pt-4"
				[color]="gns.teal_dark"
				[name]="'Sign Out'"
				[background_type]="2"
				(click)="gns.aviaService.signOut()">
			</app-nav-card>
		</div>


	<!-- <div class="py-2 px-4 mt-2 d-inline-flex justify-content-between w-100 bg-gray-100">
		<div class="my-auto heading-size-16">
			My Pinned Locations
		</div>
		<button class="btn btn-gray-200 text-primary" [routerLink]="['/locations']" (click)="gns.deselectAllTabs()">Manage</button>
	</div>
	<ng-container *ngFor="let location of gns.pinned_locations">
		<div class="mr-auto" (click)="gns.closeMobileDrawer()" [ngSwitch]="location?.link_type"> -->
			<!-- INVENTORY -->
			<!-- <div *ngSwitchCase="'inventory'" class="d-flex my-3 px-3">
				<div class="pointer d-inline-flex" [routerLink]="['/dashboard/hs/', location?.org_id, 'inventory', location?.id]">
					<div class="d-flex align-items-center justify-content-center logo mr-1 rounded" style="height:24px;width:24px;" [ngStyle]="{'background-color': '#'+location?.color}">
						<i class="material-icons text-white no-select md-12">done_all</i>
					</div>
					<span>{{location?.name}}</span>
				</div>
			</div> -->

			<!-- PRODUCT -->
			<!-- <div *ngSwitchCase="'product'" class="d-flex my-3 px-3">
				<div class="pointer d-inline-flex" [routerLink]="['/sc/', location?.org_id, 'product', location?.id]">
					<img class="logo mr-1"
					[src]="location?.logo"
					style="height:24px;width:24px;"
					onerror="this.src='/assets/placeholder-company.jpg'"/>
					{{location?.name}}
				</div>
			</div> -->

			<!-- SOLCO -->
			<!-- <div *ngSwitchCase="'org'" class="d-flex my-3 px-3">
				<div *ngIf="location.type === 2" class="d-flex pointer" [routerLink]="['/sc', location?.id]">
					<img class="logo mr-1"
					[src]="location?.logo"
					style="height:24px;width:24px;"
					onerror="this.src='/assets/placeholder-company.jpg'"/>
					{{location?.name}}
				</div>

				<div *ngIf="location.type === 1" class="d-flex pointer" [routerLink]="['/profiles/hs', location?.id]">
					<img class="logo mr-1"
					[src]="location?.logo"
					style="height:24px;width:24px;"
					onerror="this.src='/assets/placeholder-company.jpg'"/>
					{{location?.name}}
				</div>
			</div> -->

			<!-- WORKSPACE -->
			<!-- <div *ngSwitchCase="'group'" class="d-flex my-3 px-3">
				<div [routerLink]="['/ws/', location?.id]" class="pointer d-flex">
					<img class="logo mr-1"
						[src]="location?.logo"
						style="height:24px;width:24px;"
						onerror="this.src='/assets/placeholder-company.jpg'"
					/>{{location?.name}}
				</div>
			</div> -->

			<!-- USER -->
			<!-- <div *ngSwitchCase="'user'" class="d-flex my-3 px-3">
				<div [routerLink]="['/user/', location?.id]" class="pointer d-flex">
					<img class="logo mr-1"
						[src]="location?.logo"
						style="height:24px;width:24px;"
						onerror="this.src='/assets/placeholder-company.jpg'"
					/>{{location?.name}}
				</div>
			</div> -->

			<!-- COMMUNITY -->
			<!-- <div *ngSwitchCase="'community'" class="d-flex my-3 px-3">
				<div [routerLink]="['/user/', location?.id]" class="pointer d-flex">
					<img class="logo mr-1"
						[src]="location?.logo"
						style="height:24px;width:24px;"
						onerror="this.src='/assets/placeholder-company.jpg'"
					/>{{location?.name}}
				</div>
			</div> -->

			<!-- PRIORITY -->
			<!-- <div *ngSwitchCase="'priority'" class="d-flex my-3 px-3">
				<div class="pointer d-inline-flex" [routerLink]="['/dashboard/hs/', location?.org_id, 'priority', location?.id]" >
					<div class="d-flex align-items-center justify-content-center logo mr-1 rounded" style="height:24px;width:24px;" [ngStyle]="{'background-color': '#'+location?.color}">
						<i class="material-icons text-white no-select md-12">done</i>
					</div>
					<span>{{location?.name}}</span>
				</div>
			</div> -->

			<!-- TOPIC -->
			<!-- <div *ngSwitchCase="'topic'" class="d-flex my-3 px-3">
				<div class="pointer d-inline-flex" [queryParams]="{ id: location?.id, activeTab: 'Feed' }" [routerLink]="['/intelligence/km/graph']">
					<div class="d-flex mr-1 rounded align-items-center justify-content-center logo" style="height:24px;width:24px;" [ngSwitch]="location?.type" [ngClass]="{
						'bg-blue-pulse': (location?.type == 1),
						'bg-orange-dark': (location?.type == 2),
						'bg-teal-dark': (location?.type == 3),
						'bg-violet-dark': (location?.type == 4),
						'bg-green-dark': (location?.type == 5),
						'bg-red-dark': (location?.type == 6)}">
						<i *ngSwitchCase="1" class="material-icons text-white md-12">extension</i>
						<i *ngSwitchCase="2" class="material-icons text-white md-12">vpn_key</i>
						<i *ngSwitchCase="3" class="material-icons text-white md-12">important_devices</i>
						<i *ngSwitchCase="4" class="material-icons text-white md-12">trending_up</i>
						<i *ngSwitchCase="5" class="material-icons text-white md-12">build</i>
						<i *ngSwitchCase="6" class="material-icons text-white md-12">insert_chart</i>
					</div>
					{{location?.name}}
				</div>
			</div>
		</div>
	</ng-container>
	<div class="" *ngIf="gns.pinned_locations.length === 0">
		<div class="d-inline-flex p-4">
			<div class="pt-2 h-100">
				<img class="" src="/assets/pin-graphic.svg"/>
			</div>
			<div>
				<div class="w-100 p-2 my-auto text-align-left text-gray-600">Add your favorite locations to this menu for easy access.</div>
				<button class="ml-2 btn bg-gray-200 text-primary" [routerLink]="['/locations']" (click)="gns.closeMobileDrawer()">Add Pins</button>
			</div>
		</div>
	</div> -->
</div>
