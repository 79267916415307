import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { AVIAConnectService } from '../../../avia-connect.service';
import { Common as c } from '../../../common';


// FRONT END
// /sigin/sso/link/:sso_temp     -- takes token and redirect

@Component({
  selector: 'app-sso-link',
  templateUrl: './sso-link.component.html',
  styleUrls: ['./sso-link.component.scss']
})
export class SsoLinkComponent implements OnInit {
  readonly EMAILADDY_SUPPORT: string = c.EMAILADDY_SUPPORT; // Can't use statics in the HTML template.

  @Input() formGroup: FormGroup;
  orgs: any = [];
  backUrl: string = "/signin";
  verify: boolean = false;

  fc_email: AbstractControl;


  constructor(public aviaService: AVIAConnectService, private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.formGroup = this.formBuilder.group({
      email: ['', [ Validators.pattern(c.REGEX_EMAIL)]],
      org: ''
    });
    this.fc_email = this.formGroup.controls.email;

    this.route.params.subscribe(p => {
      let sso_temp = p.sso_temp
      if(sso_temp) {
        this.backUrl = '/signin/sso/link/' + sso_temp;
        let temp = sso_temp.split(".");
        let decoded_token;
        if(temp.length > 1 && typeof temp[1] === 'string') {
          decoded_token = JSON.parse( atob(temp[1]) );
        }
        this.fc_email.setValue(decoded_token.email);
        this.findOrg();
      }
    });

    this.fc_email.valueChanges
      .pipe( debounceTime(c.DEBOUNCE_TIME) )
      .subscribe( () => { this.findOrg(); } );

    if(aviaService.isLoggedIn()) router.navigate(['/signin']);
  }

  ngOnInit() {
  }

  async findOrg() {
    if ( this.fc_email.hasError('pattern') ) {
      this.orgs = [];
    } else {
      let results = await this.aviaService.signupDomainLookup( this.fc_email.value, [1,2,4,5] );

      this.orgs = results.body;
      if (this.orgs.length > 0) {
        this.formGroup.controls.org.setValue(this.orgs[0].id);
      } else {
        this.formGroup.controls.org.setValue(null);
      }
    }
  }

  async onSubmit(form) {
    let results = await this.aviaService.xhr(
      "POST",
      "/sso/link",
      {"Content-Type":"application/json"},
      {
        "email": this.fc_email.value,
        "org": this.formGroup.controls.org.value,
      }
    );

    this.verify = true;
  }

}
