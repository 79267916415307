<ng-template [ngIf]="sst.aviaService.mobile_mode" [ngIfElse]="TopicsSearch">
	<div class="search-bar-wrapper" (click)="openModal()">
		<app-avia-search #searchInput
			[options]="{ fake: true, search_text: options.text }"
			[searching]="false"
			[term]=""
		>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
		</app-avia-search>
	</div>
</ng-template>

<ng-template #TopicsSearch>
	<div [ngClass]="{'avia-modal-full-screen avia-modal-full-screen-inverse': sst.aviaService.mobile_mode}">
		<!-- SEARCH INPUT -->
		<div class="search-bar-wrapper" [ngClass]="{'mt-6': sst.aviaService.mobile_mode}">
			<app-avia-search #searchInput
				[mobile_mode]="sst.aviaService.mobile_mode"
				[options]="{ search_text: (!sst.term && sst.searching && options.show_recommendations) ? 'Recommended:' : options.text }"
				[searching]="sst.searching"
				[term]="sst.term"
				(close)="closeModal()"
				(update_search)="updateSearch($event)"
				(toggle_search)="toggleView($event)"
			>
			<ng-container *ngIf="!sst.aviaService.mobile_mode">
				<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
			</ng-container>
			</app-avia-search>
		</div>

		<!-- SEARCH RESULTS -->
		<div #searchBox class="avia-search-box" [ngClass]="{'w-100': sst.aviaService.mobile_mode}" *ngIf="sst.searching">
			<div *ngIf="sst.term && (sst.search_results | async) as res" class="border border-gray-200">
				<ng-container *ngIf="res && res.length">
					<div class="list-item" *ngFor="let d of res" (click)="addAndClose(d)">
						<div class="d-flex">
							<div class="side-bar" [ngClass]="{
									'bg-blue-light': d?.type === 1,
									'bg-orange-light': d?.type === 2,
									'bg-teal-light': d?.type === 3,
									'bg-violet-light': d?.type === 4,
									'bg-green-light': d?.type === 5,
									'bg-red-light': d?.type === 6
								}">
							</div>
							<div class="w-100 p-2 title text-capitalize" [ngClass]="{'archived': (d?.status == '1' || d?.status == '3')}">{{ d.name }}
								<div class="value-display float-right">
									<i class="material-icons text-gray-400 add ml-1">add</i>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<!-- NO MATCHES -->
				<ng-container *ngIf="show_no_matches && res && res.length === 0">
					<div class="list-item">
						<div class="d-flex">
							<div class="side-bar bg-danger"></div>
							<div class="w-100 p-2 title">No matches found.</div>
						</div>
					</div>
				</ng-container>
			</div>
			<!-- RECOMMENDATIONS -->
			<div *ngIf="!sst.term && options.show_recommendations" class="border border-gray-200">
				<div class="list-item" *ngFor="let r of recommendations" (click)="add(r)">
					<div class="d-flex">
						<div class="side-bar"
							[ngClass]="{
								'bg-blue-light': r?.type === 1,
								'bg-orange-light': r?.type === 2,
								'bg-teal-light': r?.type === 3,
								'bg-violet-light': r?.type === 4,
								'bg-green-light': r?.type === 5,
								'bg-red-light': r?.type === 6
							}"
						></div>
						<div class="w-100 p-2 title text-capitalize" [ngClass]="{'archived': (r.status == '1' || r.status == '3')}">{{ r.name }}
							<div class="value-display float-right">
								<i [ngClass]="{'text-green-dark': r.value_normalized > 0}" class="material-icons dots text-gray-200">lens</i>
								<i [ngClass]="{'text-green-dark': r.value_normalized > 0.33}" class="material-icons dots text-gray-200">lens</i>
								<i [ngClass]="{'text-green-dark': r.value_normalized > 0.67}" class="material-icons dots text-gray-200">lens</i>
								<i *ngIf="true" class="material-icons text-gray-400 add ml-1">add</i>
								<!-- TODO: IMPLEMENT THIS LATER -->
								<i *ngIf="false" class="material-icons text-gray-400 add ml-1" (click)="delete(r)">check</i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
