<div class="container-fluid h-100 w-100 bg-white">

	<!-- HEADER -->
	<div class="headerblock row bg-gray-100 p-4">
		<div class="aviablock">
			<img class="avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
		</div>
	</div>

</div>
