<div>
	<app-avia-users-search *ngIf="add_enabled == true"
		[options]="{text:'Add people to this conversation', search_add_ons: this.aviaService.session && this.aviaService.session.user && (this.aviaService.session.user.org_obj.type != 3 && this.aviaService.session.user.org_obj.type != 1)?{ org:'user', active_users_only:true}:{active_users_only:true}}"
		(done)="addUser($event.data)">
	</app-avia-users-search>

	<div class="participants-section">
		<app-avia-user-row *ngFor="let p of participants"
			class="d-block mt-2"
			[akey]="{d:true}"
			[user]="p"
			[options]="user_display_opts"
			(done)="updateUsers($event)"
		></app-avia-user-row>
	</div>
</div>
