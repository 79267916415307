import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { cloneDeep as _cloneDeep } from 'lodash';

import { AVIAConnectService } from '../../avia-connect.service';
import { CommonValidators } from '../../common-validators';
import {
  AccessKey,
  E_Modal_Operating_Mode,
  Org,
  User,
} from '../../../class';
import { Common } from '../../common';


@Component({
  selector: 'app-avia-user-invite-modal',
  templateUrl: './avia-user-invite-modal.component.html',
  styleUrls: ['./avia-user-invite-modal.component.scss']
})
export class AviaUserInviteModalComponent implements OnInit {
  readonly VERBOSE = false;

  @Input() akey:        AccessKey = new AccessKey();
  @Input() fullscreen:  boolean   = true;
  @Input() // org
    get org():Org { return this._org; }
    set org(value:Org) {
      if (value !== undefined && value !== null) {
        this._org = value;
        this.init();
      }
    };
    _org: Org;
  @Input() static_page: boolean   = false;
  @Input() title_text:  string    = 'Invite People To';

  @Output('userInvited') user_invited: EventEmitter<any> = new EventEmitter();
  @Output('cancel') cancel: EventEmitter<void>           = new EventEmitter();

  // MODALS
  modal_ref:                                  NgbModalRef;
  @ViewChild('UserInvite', { static: true }) user_invite_modal: any;

  // Add User
  readonly formrow_name_prefix: string = 'form_row_';
           formrow_name_index:  number = 0;

  data:             User                          = new User();
  form_row:         Object;
  form_rows:        FormGroup[]                   = [];
  i_am_owner:       boolean                       = false;
  invitees_orgs:    UserInviteModal_InviteesOrg[] = [];
  mode:             number                        = E_Modal_Operating_Mode.ADD;
  submits_returned: number                        = 0;
  submit_rows:      boolean                       = false;
  types:            Object[]                      = [{name: 'owner', value: 1}, {name: 'member', value: 0}];
  user_form:        FormGroup;

  // Bulk Add
  bulkadd_form:  FormGroup;
  show_bulk_add: boolean = false;
  bulk_add_data: string[];


  constructor( private aviaService: AVIAConnectService, private fb: FormBuilder, private modalService: NgbModal, private router:Router ) {
    this.form_row = {
      email:      ['',    [Validators.required, Validators.email] ],
      firstname:  ['',    [Validators.pattern(Common.REGEX_FL_NAME)] ],
      lastname:   ['',    [Validators.pattern(Common.REGEX_FL_NAME)] ],
      is_owner:   ['0',   [] ],
      org:        ['',    [Validators.required] ],
      row_id:     [0,     []], // hidden field
      successful: [false, []], // hidden field
    };

    this.createForm();
  };


  // === INITIALIZATION ===

  ngOnInit(): void { this.init(); };

  init(): void {
    this.VERBOSE && console.log('org:', this._org);
    this.VERBOSE && console.log('invitees_orgs:', this.invitees_orgs);

    if (this._org !== undefined && this._org !== null) {
      this.i_am_owner = this.akey.w;

      this.invitees_orgs.length = 0;
      this.invitees_orgs[0] = new UserInviteModal_InviteesOrg(0, this._org);

      this.form_rows.length = 0;
      this.createForm();
      this.setupFormData( this.form_rows[0], 0, 0);
    }
  };

  createForm(): void {
    this.user_form = this.fb.group({
      form_row_0: this.fb.group(this.form_row)
    });

    this.form_rows.length = 0; // Make sure the array is empty before adding the first row
    this.form_rows.push( this.user_form.controls.form_row_0 as FormGroup );

    this.bulkadd_form = this.fb.group({
      bulk_input: ['', Validators.required ]
    });
    this.bulkadd_form.reset();
  };

  setupFormData( row:FormGroup, index: number, row_id:number ): void {
    this.VERBOSE && console.log('add-activity-modal::setupFormData operating mode = ', E_Modal_Operating_Mode.getPropName(this.mode) );

    const invitees_org: UserInviteModal_InviteesOrg = this.invitees_orgs[index];
    const org: any = (invitees_org !== undefined && invitees_org.org !== undefined) ?
      invitees_org.org.id :
      '';

    let common_fields = {
      email:      this.data.emails[0].email ? this.data.emails[0].email                    : '',
      firstname:  this.data['firstname']    ? this.data['firstname']                       : '',
      lastname:   this.data['lastname']     ? this.data['lastname']                        : '',
      is_owner:   org !== ''                ? this.setDefaultOwnership( invitees_org.org ) : 0,
      org,
      row_id,            // hidden field
      successful: false, // hidden field
    };

    row.reset(common_fields);
  };


  // === ROW MANAGEMENT ===

  addNewRow() {
    this.submit_rows = false;
    this.formrow_name_index++;

    const total_invitees = this.invitees_orgs.length;
    let new_invitee = new UserInviteModal_InviteesOrg( this.formrow_name_index, this._org );
    if ( total_invitees > 0 ) new_invitee.org = this.invitees_orgs[total_invitees - 1].org;
    this.invitees_orgs.push( new_invitee );

    let new_row: FormGroup = this.fb.group(this.form_row);
    this.setupFormData(new_row, (this.invitees_orgs.length - 1), this.formrow_name_index);

    const new_name: string = this.formrow_name_prefix + this.formrow_name_index;
    this.form_rows.push(new_row);
    this.user_form.addControl(new_name, new_row);
    this.user_form.updateValueAndValidity();
  };

  removeRow( $event:{row_id:number} ): void {
    if ( this.form_rows.length === 1 ) return; // Don't remove the row if its the only one.

    this.form_rows = this.form_rows.filter( row => {
      if ( row.get('row_id').value != $event.row_id ) return row;
    });
    this.removeRowControl( $event.row_id );

    this.invitees_orgs = this.invitees_orgs.filter( item => {
      if ( item.row_id !== $event.row_id ) return item;
    });
  };

  removeLastRow(): void {
    this.removeRowControl( this.form_rows.length - 1 );
    this.form_rows.pop();
    this.invitees_orgs.pop();
  };

  removeRowControl( row_id:number ): void {
    this.user_form.removeControl(this.formrow_name_prefix+row_id);
    this.user_form.updateValueAndValidity();
  };


  // === DATA HANDLING ===

  onSubmit(): void {
    if ( this.submits_returned === 0 ) {
      this.submit_rows = true;
      this.aviaService.notify(
        'info',
        'Please wait...',
        'Please be patient while your new users are being invited to AVIA Connect.',
      );
    }
  };

  resetFormGroup( fields = undefined ): void {
    this.submits_returned = 0;
    this.invitees_orgs.length = 0;

    for (let i = 1; i < this.form_rows.length; i++) {
      this.user_form.removeControl( this.formrow_name_prefix+i );
      this.user_form.updateValueAndValidity();
    }
    this.form_rows.length = 0;
    this.formrow_name_index = -1;

    this.addNewRow();

    this.VERBOSE && console.log('user_form:', this.user_form);
    this.VERBOSE && console.log('form_rows:', this.form_rows);
  };

  setDefaultOwnership( invitees_org: Org ): number {
    // If operator is an 'owner'...
    //   If Invitee's Org is type 2 (Sol Co), new user defaults to 'Owner'
    //   If Invitee's Org is type 1,3,4... (AVIA, Healthcare System, Other), new user defaults to 'Member'
    // If operator is not an 'owner', new user defaults to 'Member'
    if ( this.i_am_owner ) {
      switch ( invitees_org.type ) {
        case 2:
          return 1;
        break;

        default:
          return 0;
        break;
      }
    }
  };

  async submitComplete( $event: any ) {
    this.submits_returned++;

    if ( this.submits_returned === this.form_rows.length ) {
      //All rows have finished submitting
      this.aviaService.closeNotification();

      let all_failed:  boolean = true;
      let all_passed:  boolean = true;
      let passed_rows: FormGroup[] = [];

      for ( let i = 0; i < this.form_rows.length; i++ ) {
        const result = this.form_rows[i].get('successful').value;
        if ( result !== true ) {
          all_passed = false;
        } else {
          all_failed = false;
          passed_rows.push(this.form_rows[i]);
        }
      }
      if ( all_passed ) {
        // Let the user know all have completed successfully and close
        let result = await this.aviaService.notify(
          'success',
          'Success',
          'All new users successfully invited!',
          {
            showConfirmButton: true,
            confirmButtonText: 'OK!',
            timer: 0
          }
        );
        if (result.value != undefined) {
          this.VERBOSE && console.log('AviaUserInviteModal::submitComplete: ALL PASSED, closing the modal');
          this.close();
        }
      } else if ( all_failed ) {
        this.aviaService.notify(
          'warning',
          'That didn\'t work...',
          'Please review errors for invitations that were not successful.',
          {
            showConfirmButton: true,
            confirmButtonText: 'I Understand',
            timer: 0
          }
        );
      } else {
        this.aviaService.notify(
          'info',
          'Almost there...',
          'Some new users were successfully invited, but some were not. We will return you to the invite screen to edit or remove the ones that were not successful',
          {
            showConfirmButton: true,
            confirmButtonText: 'I Understand',
            timer: 0
          }
        ).then(
          (result) => {
            if (result.value != undefined) {
              this.VERBOSE && console.log('AviaUserInviteModal::submitComplete: Only some passed.');
              // Remove the rows that have completed successfully
              for (let i = 0; i < passed_rows.length; i++) {
                const pass = passed_rows[i];
                this.removeRow({ row_id: pass.get('row_id').value });
              }
            }
          }
        );
      }
      this.submits_returned = 0;
      this.submit_rows = false;
    }
  };

  updateOrg( $event: any ): void {
    this.VERBOSE && console.log(`updateOrg`, $event);

    const invitees_org: Org       = $event.org;
    const row_form:     FormGroup = $event.row_form;
    const row_id:       number    = $event.row_id;

    this.invitees_orgs = this.invitees_orgs.map( item => {
      if ( item.row_id === row_id ) item.org = $event.org;
      return item;
    });

    const is_owner_control = row_form.get('is_owner');
    const org_control = row_form.get('org');

    is_owner_control.markAsDirty();
    is_owner_control.markAsTouched();
    is_owner_control.patchValue( this.setDefaultOwnership(invitees_org) );

    org_control.markAsDirty();
    org_control.markAsTouched();
    org_control.patchValue(invitees_org.id);
  };


  // === BULK ADD ===

  hideBulkAdd(): void {
    this.show_bulk_add = false;
  };

  showBulkAdd(): void {
    this.bulkadd_form.reset();
    this.show_bulk_add = true;
  };

  processBulkAdd(): void {
    /*
      Takes input like:
      floaty@foo.foo, Floaty, McFloatface
      floaty2@foo.foo, Floaty 2, McFloatface
      floaty3@foo.foo, Floaty 3, McFloatface
    */
    const bulk_add_raw: string = this.bulkadd_form.get('bulk_input').value
    const regex_bulkadd = new RegExp(/[\,\s\r\n]+/,'gm');

    this.bulk_add_data = bulk_add_raw.split(regex_bulkadd);

    this.hideBulkAdd();

    const prev_last_idx: number = this.form_rows.length - 1;
    let   new_row_idx:   number;

    this.bulk_add_data.forEach(row => {
      this.addNewRow();
      new_row_idx = this.form_rows.length - 1;
      this.form_rows[new_row_idx].get('email').patchValue( row.trim() );
      this.form_rows[new_row_idx].markAsDirty();
      this.form_rows[new_row_idx].markAsTouched();
      this.form_rows[new_row_idx].updateValueAndValidity();
    }, this);

    // See if we should remove a 'utility' row that was only used to switch the org
    if (this.form_rows[prev_last_idx].get('email').value === '') {
      this.removeRow({ row_id: this.form_rows[prev_last_idx].get('row_id').value });
    }

    this.user_form.markAsDirty();
    this.user_form.markAsTouched();
    this.user_form.updateValueAndValidity();
  };


  // === MODAL STUFF ===

  close(): void {
    this.submit_rows = false;
    this.resetFormGroup();
    this.cancel.emit();
    this.modal_ref.close();
  };

  // NOTE: You can either set the Inputs 'data' and 'data_fallback' first, then call this method
  // OR, simply supply them with the calling of this method
  open( data:User = this.data ): void {
    let options: NgbModalOptions = {
      'backdrop': 'static',
      size: 'lg',
    };
    if (this.fullscreen) options['windowClass'] = 'avia-modal-fullscreen';

    this.submit_rows = false;
    this.data = data;
    this.modal_ref = this.modalService.open( this.user_invite_modal, options );
  };

};


export class UserInviteModal_InviteesOrg {
  public row_id: number;

  private _org: Org;
  get org():Org { return this._org };
  set org( value:Org ) {
    this._org = _cloneDeep(value);
  };

  constructor(
    row_id: number = 0,
    org:    Org    = new Org()
  ) {
    this.row_id = row_id;
    this.org = org;
  }
};
