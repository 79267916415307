// @angular imports
import { Injectable } from '@angular/core';

// node_modules imports
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { switchMap, share, tap, catchError } from 'rxjs/operators';

// avia imports
import { AVIAConnectService } from './../avia-connect.service';

/* * *
  * Search Service
  * This service is intended to be [provided] at the component level
  * Each time it is [provided] it will create a new singleton to manage searching and results
  * You must call initSearch() with the type of objects you would like to search e.g., users, cards, content
  * “As long as you're being a copycat, you will never be the best copycat.”
  * Jaron
* * */

@Injectable()
export class SearchService {
  count:          number = 10;
  loading:        boolean = false; // used for loading animations
  search_results: Observable<any[]>; // angular8: changed any to any[], avoids errors when doing search_results?.length in HTML templates
  search_subject: BehaviorSubject<any> = new BehaviorSubject( new Observable() );
  search_type:    string;
  searching:      boolean = false;
  start:          number = 0;
  term:           string = '';

  private VERBOSE: boolean = false;


  constructor( public aviaService: AVIAConnectService ) { }

  toggleView( e ): void {
    this.VERBOSE && console.log(`toggleView: `, e);
    this.VERBOSE && console.log(this.searching);
    this.searching = e.searching;
  };

  updateSearch( e ): void {
    this.term = e.term;
    this.loading = true;
    this.search_subject.next( e );
  };

  forceUpdate(): void {
    this.loading = true;
    this.search_subject.next( {term: this.term} );
    this.VERBOSE && console.log('force update ' + this.search_type + ' search service');
  };

  resetSearch(): void {
    this.updateSearch({ term: '' });
  };

  initSearch( type, options = {} ): void {
    this.search_type = type;
    let search: Function = this.buildSearch( type, options );
    let bool_map: Function = this.buildBoolMap( type );
    let reset = () => of<any>([]);

    this.search_results = this.search_subject
    .pipe( switchMap(obj => bool_map(obj) ? search(obj) : reset()) )
    .pipe( tap(data => {
      // This is here so that we can push the setting of 'loading' onto the execution stack -- AS & JRS
      setTimeout(() => { this.loading = false; }, 0);
    }) )
    .pipe( share() )
    .pipe( catchError(error => {
      console.error('Search of type:', this.search_type, 'errored out with.', error);
      return of<any>([]);
    }) );
  };

  buildSearch( type, options ): Function {
    switch ( type ) {
      case 'content':           return (e): Observable<any> => this.aviaService.searchContent({ search: e.term, id: e.id, start: e.start, count: e.count, interested: e.interested, produced_by: e.produced_by, order: e.order, direction: e.direction });
      case 'hs':                return (e): Observable<any> => this.aviaService.searchOrgs({ term: e.term, type: 1});
      case 'org':               return (e): Observable<any> => this.aviaService.searchOrgs({ term: e.term, type: e.type });
      case 'solco':             return (e): Observable<any> => this.aviaService.searchOrgs({ term: e.term, type: 2, start: e.start, count: e.count, /*is_avia_vetted: e.is_avia_vetted,*/ is_high_impact: e.is_high_impact, is_market_val: e.is_market_val, is_pediatric: e.is_pediatric });
      case 'user':              return (e): Observable<any> => from( this.aviaService.getUsers({ filter:e.term }) );

      // UNIVERSAL: Use these for word searches
      case 'uProduct':         return (e): Observable<any> => this.aviaService.universalSearchProducts({search: e.term, start: e.start, count: e.count});
      case 'uActivity':        return (e): Observable<any> => this.aviaService.universalSearchActivities({search: e.term, start: e.start, count: e.count});
      case 'uCommunity':       return (e): Observable<any> => this.aviaService.universalSearchCommunity({search: e.term});
      case 'uContent':         return (e): Observable<any> => this.aviaService.universalSearchContent({search: e.term, in_library: e.in_library, produced_by: e.produced_by, is_public: e.is_public, start: e.start, count: e.count, no_avia_content: e.no_avia_content});
      case 'uHealthSystem':    return (e): Observable<any> => this.aviaService.universalSearchHealthSystems({search: e.term, type: 1});
      case 'uInv':             return (e): Observable<any> => this.aviaService.universalSearchInventories({search: e.term, org: e.org});
      case 'uOrg':             return (e): Observable<any> => this.aviaService.universalSearchOrgs({search: e.term, include_avia: options.include_avia});
      case 'uPriority':        return (e): Observable<any> => this.aviaService.universalSearchPriorities({search: e.term, org: e.org});
      case 'uSolco':           return (e): Observable<any> => this.aviaService.universalSearchSolcos({ search: e.term, type: 2, start: e.start, count: e.count });
      case 'uTopic':           return (e): Observable<any> => this.aviaService.searchKMTopics({ search: e.term, level: e.level, all: e.all, type: e.type, id: e.id, order: e.order, direction: e.direction });
      case 'uUser':            return (e): Observable<any> => this.aviaService.universalSearchUsers({search: e.term, org: e.org, connections: e.connections, active_users_only:e.active_users_only, exclude_org: e.exclude_org, start: e.start, count: e.count, status: e.status});
    }
  };

  buildBoolMap( type ): Function {
    switch ( type ) {
      case 'content':           return (obj): Function => (obj.term || obj.start || obj.count || obj.id || obj.interested || obj.produced_by || obj.order || obj.direction);
      case 'hs':                return (obj): Function => (obj.term);
      case 'org':               return (obj): Function => (obj.term || (obj.type === 0));
      case 'solco':             return (obj): Function => (obj.term);
      case 'user':              return (obj): Function => obj.term;

      case 'uActivity':        return (obj): Function => (obj.term);
      case 'uCommunity':       return (obj): Function => (obj.term);
      case 'uContent':         return (obj): Function => (obj.term || obj.in_library || obj.produced_by || obj.is_public);
      case 'uHealthSystem':    return (obj): Function => (obj.term);
      case 'uInv':             return (obj): Function => obj.org && obj.term;
      case 'uOrg':             return (obj): Function => (obj.term);
      case 'uPriority':        return (obj): Function => obj.org && obj.term;
      case 'uProduct':         return (obj): Function => (obj.term);
      case 'uSolco':           return (obj): Function => (obj.term);
      case 'uTopic':           return (obj): Function => (obj.term || obj.all || obj.type || obj.level || obj.id || (obj.type === 0));
      case 'uUser':            return (obj): Function => (obj.term || obj.org);
    }
  };

}
