<div class="avia-modal-full-screen avia-modal-full-screen-inverse">

  <div class="page container-fluid">
    <div class="d-flex align-items-center headerblock row">
			<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
			<span class="ml-auto backlink pointer" (click)="onCancel()">
					<i class="mr-4 material-icons" style="font-size: 24px; vertical-align: top;">close</i>
			</span>
    </div>

    <div class="row subpage">
      <avia-filedrop [modal]="true" [unlocked]="true" (dragFileAccepted)="dropFile($event)">
        <div class="fd-fix pt-4">
          <div class="col-12">
            <div *ngIf="data===undefined" class="col-12">
              <p>Loading form data...</p>
            </div>

            <!-- feedback collection screen -->
            <div class="row">
              <div *ngIf="data!==undefined && !isSubmitted" class="mx-auto col-12 col-sm-8 push-sm-2 text-left mb-6">
                <h2>Feedback</h2>
                <div class="subtitle text-center mb-7">We'd love to hear your thoughts!</div>

                <div *ngFor="let d of data.questions">
                  <div *ngIf="d['type']==='scale'">
                    <p>{{d.title}}<span *ngIf="d.isRequired === true"> (*)</span></p>
                    <app-avia-scale class="col-12 no-gutters mb-7 d-block" [data]="d.data" (onChange)="d.result=$event"></app-avia-scale>
                  </div>
                  <div *ngIf="d['type']==='selector'" class="mb-7">
                    <p>{{d.title}}<span *ngIf="d.isRequired === true"> (*)</span></p>
                    <app-avia-selector class="selector-click"
                      [active_data]="d.data.default === 'active' ? d.data.active : []"
                      [passed_data]="d.data.items"
                      [options]="{textbox: true, type: 'passed', passed_data_selector_title: d.data.placeholder, allow_deselection: false}"
                      (init)="d.result=$event['selected'][0]"
                      (done)="d.result=$event['selected'][0]"
                    ></app-avia-selector>
                  </div>
                  <div *ngIf="d['type']==='textbox'">
                    <form>
                      <p>{{d.title}}<span *ngIf="d.isRequired === true"> (*)</span></p>
                      <textarea class="textbox" autosize rows="6" name="d.name" [placeholder]="d.data.placeholder" [(ngModel)]="d.result"></textarea>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <!-- FILE ATTACHMENTS! -->
            <div *ngIf="data!==undefined && !isSubmitted" class="row mb-6">
              <div class="mx-auto col-12 col-sm-8 push-sm-2 text-center">
                <div style="height:0px; overflow:hidden">
                  <input class="file-picker" type="file" id="imageupload" (change)="uploadFile($event)" name="bok" accept="image/*">
                </div>
                <p>Add any supporting files (Drag&amp;Drop or <span onclick="document.getElementById('imageupload').click()" class='text-accent pointer'>Browse</span>)</p>
                <div *ngFor="let f of files" class="attached-file">
                  <span>Attached File: "{{f.file.name}}" [ <span class='text-accent pointer' (click)='removeFile( f.file.name )'>x</span> ]</span> {{f.progress < 1 ? '['+f.progress*100+']' : 'uploaded!'}}
                </div>
              </div>
            </div>

            <!-- SUBMIT or CANCEL -->
            <div class="row no-gutters mb-6 mt-4">
              <div class="col-12 col-sm-8 push-sm-2 mx-auto text-center">
                <div *ngIf="data!==undefined && !isSubmitted" class="col-12 no-gutters">
                  <button class="float-left col-4 col-md-3 col-xl-2 btn btn-cancel cancel" (click)="onCancel()">CANCEL</button>
                  <button class="float-right col-4 col-md-3 col-xl-2 push-xs-4 push-md-6 push-xl-8 btn btn-success success" (click)="onSubmit()">SEND</button>
                </div>

                <div *ngIf="data!==undefined && !isSubmitted && error!==''" class="col-12 mb-7 mt-6">
                  <div>{{error}}</div>
                </div>
              </div>
            </div>

            <!-- THANK YOU SCREEN -->
            <div *ngIf="isSubmitted" class="row mb-6">
              <div class="mx-auto col-12 col-sm-8 push-sm-2 text-center">
                <div class="col-12 mb-6">
                  <h2>Thanks!</h2>
                  <div class="subtitle">We really appreciate your feedback.</div>
                </div>
                <div class="col-12">
                  <button class="btn btn-success" style="text-align: center;margin-left:auto;margin-right:auto" (click)="onClose()">CLOSE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </avia-filedrop>
    </div>
  </div>
</div>
