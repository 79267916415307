<ng-template #create let-modal>
	<!-- HEADER -->
	<div class="bg-gray-200 px-4 py-2">
		<span class="header-text">{{mode === "edit"?"Add/remove participants":"New conversation"}}</span>
		<button type="button" class="close p-0 m-0" aria-label="Close">
			<i class="material-icons md-18" (click)="close()">close</i>
		</button>
	</div>
	<div>

		<div class="modal-body" style="max-height: 500px; min-height: 500px;overflow-y: auto;">
			<div>
				<div class="d-flex justify-content-between">
					<label for="discussion_name">Conversation Name</label>
					<small><i> optional </i></small>
				</div>
				<form>
					<input #discussion_name
						type="text"
						autocomplete="off"
						[name]="'discussion_name'"
						[(ngModel)]="local_discussion.name"
						class="d-block p-1 w-100"
						placeholder="Name this conversation (optional)"
					>
				</form>
			</div>
			<div class="mt-2">
				<label>Participants</label>
				<app-discussion-participants [participants]="local_discussion.participants" (update)="updateParticipants($event)" [mode]="mode" [allow_navigate]="false"></app-discussion-participants>
			</div>
	</div>
		<div class="modal-footer justify-content-between bg-gray-200">
			<button type="button" class="btn btn-outline-dark px-6 py-1 cancel-button" (click)="close()">Cancel</button>
			<button [disabled]="local_discussion.participants?.length === 0 && mode !== 'edit'" type="button" class="btn btn-primary px-6 py-1" (click)="submitParticipants()">
				{{mode === "edit"?"Save":"Create"}}
			</button>
		</div>
	</div>
</ng-template>

<!-- OPEN MODEL BUTTON -->
<div (click)="openModal()">
	<ng-content></ng-content>
</div>
