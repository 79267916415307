import { Color_Library } from './../../../../../class';
import { AVIAConnectService } from './../../../../avia-connect.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav-card',
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss']
})
export class NavCardComponent implements OnInit {
  @Input() name:              string;
  @Input() color:             string = Color_Library.primary;
  @Input() background_type:   number = 1;

  constructor(public aviaService: AVIAConnectService) { }

  ngOnInit() {
  }

}
