import { User, AnalyticEvent } from './../../../../class';
import { Component, OnInit, Input } from '@angular/core';
import { AVIAConnectService } from 'src/app/avia-connect.service';

@Component({
  selector: 'app-avia-suggested-connection-card',
  templateUrl: './avia-suggested-connection-card.component.html',
  styleUrls: ['./avia-suggested-connection-card.component.scss']
})
export class AviaSuggestedConnectionCardComponent implements OnInit {
  @Input() user: User;
  @Input() location: String;

  constructor(public aviaService: AVIAConnectService) { }

  ngOnInit() {
  }

  userMessageButton(u) {
    let obj = new AnalyticEvent(
      'suggested_connection_card_message_button',
      {user_id: u.id, location: this.location}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

  userConnectButton(u) {
    let obj = new AnalyticEvent(
      'suggested_connection_card_connect_button',
      {user_id: u.id, location: this.location}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

}
