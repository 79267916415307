<div class="w-100">
  <div class="d-flex justify-content-stretch the-line">
    <!-- LIKE BUTTON -->
    <button *ngIf="showLike"
      [ngClass]="{'q-and-a-blue-button': i_like == true, 'flex-fill': aviaService.mobile_mode}"
			type="button" class="btn q-and-a-default-button"
			(click)="_likeClicked()">
        <i class="material-icons md-18 mr-1 center-btn-text"
        [ngClass]="{'q-and-a-blue-button': i_like == true}">thumb_up</i>
        <span class="d-none d-sm-inline-block center-btn-text button-text text-gray-800"
          [ngClass]="{'q-and-a-blue-button': i_like == true}"
        >{{textLike}}</span>
		</button>
		<!-- COMMENT -->
		<button *ngIf="showComment"
			type="button" class="btn q-and-a-default-button"
			[ngClass]="{'flex-fill': aviaService.mobile_mode}"
			(click)="commentClicked.emit()">
			<i class="material-icons md-18 center-btn-text mr-1">message</i>
			<span class="d-none d-sm-inline-block center-btn-text button-text text-gray-800">{{textComment}}</span>
		</button>
		<!-- SHARE -->
		<app-content-share-modal *ngIf="post"
			class="mr-2"
			[ngClass]="{'flex-fill': aviaService.mobile_mode}"
			[post]="post">
			<button *ngIf="showShare"
				type="button" class="btn w-100 q-and-a-default-button"
				[ngClass]="{'flex-fill': aviaService.mobile_mode}"
				(click)="shareClicked.emit()">
				<i class="material-icons md-18 center-btn-text mr-1">share</i>
				<span class="d-none d-sm-inline-block center-btn-text button-text text-gray-800">Share</span>
			</button>
		</app-content-share-modal>
		<!-- Follow  -->
		<button *ngIf="showFollow"
			ngbTooltip="Save a item to be notified and add it to your saved activities"
			data-toggle="tooltip"
			placement="top"
			type="button" class="btn q-and-a-default-button"
			[ngClass]="{'q-and-a-blue-button': i_follow, 'not-following': !i_follow, 'flex-fill': aviaService.mobile_mode}"
			(click)="_followClicked()">
      <i [ngClass]="{'q-and-a-blue-button': i_follow, 'not-following': !i_follow}"
        class="material-icons md-18  center-btn-text mr-1">bookmark</i>
      <span class="d-none d-sm-inline-block center-btn-text button-text text-gray-800"
        [ngClass]="{'q-and-a-blue-button': i_follow, 'not-following': !i_follow}"
      >Save</span>
		</button>
		<button *ngIf="showBookmark"
			[ngClass]="{'q-and-a-blue-button': i_bookmark == true, 'flex-fill': aviaService.mobile_mode}"
			type="button" class="btn mr-0 q-and-a-default-button"
			(click)="_bookmarkClicked()">
				<i class="material-icons md-18 mr-1 center-btn-text"
				[ngClass]="{'q-and-a-blue-button': i_bookmark == true}">bookmark</i>
				<span class="d-none d-sm-inline-block center-btn-text button-text text-gray-800"
					[ngClass]="{'q-and-a-blue-button': i_bookmark == true}"
				>Save</span>
		</button>
</div>
</div>


