import { FeedService } from '../../feed.service';
import { AVIAConnectService } from '../../../avia-connect.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Component, OnInit, Input, Output, ViewChild, ElementRef, HostListener, EventEmitter, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import 'quill-mention';
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);
var bold = Quill.import('formats/bold');
bold.tagName = 'b';   // Quill uses <strong> by default
Quill.register(bold, true);


var italic = Quill.import('formats/italic');
italic.tagName = 'i';   // Quill uses <em> by default
Quill.register(italic, true);


import {PostMediaComponent} from '../post-media/post-media.component';

@Component({
  selector: 'app-post-textarea',
  templateUrl: './post-textarea.component.html',
  styleUrls: ['./post-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PostTextAreaComponent),
      multi: true
    }
  ]
})
export class PostTextAreaComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder: string = 'add some text';
  @Input() content = '';
  @Input() send_button: boolean = true;
  @Input() text_box_size = 'sm';
  @Input() focus_on_init = false;
  @Input() hide_verbose_tags = false;
  @Input() media_preview = false;
  @Input() mention_only_mode = false;

  @ViewChild('toolbar', { static: true }) toolbar: ElementRef;
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
  @ViewChild('media_component', {static:false}) mediaComponent: PostMediaComponent;

  @Input() modules;
  @Input() formats = ["emoji", "mention", "link"];
  @Input() tags = [];
  @Input() media = null;
  @Input() url: string = null;
  @Input() show_buttons: boolean = true;
  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this._quillLoaded) {
      if(event.target.closest('.emoji-mart') && this.eRef.nativeElement.contains(event.target)) {
        this.showEmojiMart();
      }
      else if(this._showEmojiMart == true){
        this.hideEmojiMart();
      }
      else if(!this.eRef.nativeElement.contains(event.target) && document.contains(event.target) && this._showAddLink == false){
        this.blur.emit(event);
      }
    }
  }

  subscriptions:  Subscription[]  = [];

  _showEmojiMart = false;
  _showAddLink = false;
  _quillLoaded = false;

  hideEmojiMart = () => {
    this._showEmojiMart = false;
    this._disable_return = false;
  }

  showEmojiMart = () => {
    setTimeout(()=>{
      this._showEmojiMart = true;
      this._disable_return = true;
    },0);
  }

  get value() {
    if(!this._value) {
      return {
      }
    }


    let e = document.createElement('div');
    e.innerHTML = this._value.html;
    let text = e.innerText ? e.innerText : e.textContent;
    return {
      html: this._value.html,
      tags: this.tags,
      text: text,
      media: this.mediaComponent && this.mediaComponent.media
    }
  }

  set value(val) {
  }


  _value:any = {};
  _disable_return = false;

  constructor(
    private eRef: ElementRef,
    public fs: FeedService,
    public aviaService: AVIAConnectService
  ) {
    const getSearchAll = async(searchTerm)=>{
      let config = Object.assign({limit: 10, offset: 0, elasticmode: true, active_users_only: true}, {term:searchTerm});
      let session = await this.aviaService.getSessionSupport();

      // if(session.user.org !== 1) {
      //   Object.assign(config, { connections: true, org:"user" });
      // }

      return this.aviaService.getSearchCustom(config,{
        query:"prefix",
        type:[{type: "user"},{type: "org"},{type: "topic"},{type: "product"}],
        fields:{
          "user":{
            "autocomplete":{score:20},
          },
          "org":{
            "autocomplete":{score:5}
          },
          "topic":{
            "autocomplete":{score:10},
          },
          "product": {
            "autocomplete":{score:1}
          }
        }
      });
    }

    const onOpen = ()=> {
      this._disable_return = true;
    }

    const onClose = ()=> {
      setTimeout(()=>{
        this._disable_return = false;
      },0);
    }

    this.modules = {
      mention: {
        showDenotationChar: false,
        allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "\u200B"],
        dataAttributes: ['id', 'name', 'value', 'denotationChar', 'link', 'target', 'data', 'type'],
        renderItem: (item, searchTerm) => {
          let image;
          let name = item.obj.name || item.obj.fullname;
          let descriptor;

          if(item.type === "topic") {
            let map = {
              1:{
                bg:'bg-blue-dark',
                icon: 'extension',
                descriptor: 'Capability'
              },
              2:{
                bg:'bg-orange-dark',
                icon: 'vpn_key',
                descriptor: 'Solution'
              },
              3:{
                bg:'bg-teal-dark',
                icon: 'important_devices',
                descriptor: 'Technology'
              },
              4:{
                bg:'bg-violet-dark',
                icon: 'trending_up',
                descriptor: 'Trend'
              },
              5:{
                bg:'bg-green-dark',
                icon: 'build',
                descriptor: 'Competency'
              },
              6:{
                bg:'bg-red-dark',
                icon: 'insert_chart',
                descriptor: 'Metric'
              }
            }

            image = `
              <span class="rounded search_circle_icon d-flex align-items-center justify-content-center
                ${map[item.obj.type].bg}"
                style="min-width:24px;height: 24px;">
                <i class="material-icons text-white" style="font-size:13px">${map[item.obj.type].icon}</i>
              </span>
            `;
            descriptor = map[item.obj.type].descriptor;
          }
          else if (item.type === "user") {
            descriptor = `${item.obj.title ? item.obj.title + ' at' : ''} ${item.obj.org_name}`;
          }
          else if (item.type === "product") {
            descriptor = "Product"
          }

          if(!image) image = `<img height="24" src="${item.obj.avatar || item.obj.logo}">`;


          return `
            <div class="d-flex h-100" style="
                line-height: 15px;
                font-size: 14px;
                align-items: center;
            ">
              ${image}
              <div class="wrapper overflow-hidden" style="
                display: block;
                max-width: 100%;
                align-items: center;
            ">
                <div class="ml-1 overflow-hidden" style="text-overflow: ellipsis;">
                  <span class="text-gray-800 font-weight-bold overflow-hidden" style="font-size:14px; text-overflow:ellipsis;" title="${name}">&nbsp; ${name}</span>
                  ${descriptor?`<span class="text-gray-600 overflow-hidden" style="font-size:12px; text-overflow:ellipsis;" title="${descriptor}">&nbsp;• ${descriptor}</span>`:''}
                </div>
              </div>
            </div>
          `;
        },
        onSelect: (item, insertItem) => {
          if(item.type == "topic") {
            item.type = 'card_id';
          }
          else {
            item.type = `${item.type}_id`;
          }
          let tag = {name:item.name};
          tag[item.type] = item.id;

          let exists = false;
          for(let existing_tag of this.tags) {
            if(existing_tag[item.type] == item.id) {
              exists = true;
              break;
            }
          }

          if(!exists) {
            this.tags.push(tag);
            this.tags = [].concat(this.tags);
          }
          const editor = this.editor.quillEditor as Quill;
          if(!this.mention_only_mode) {
            insertItem(item);
            // necessary because quill-mention triggers changes as 'api' instead of 'user'
            editor.insertText(editor.getLength() - 1, '', 'user')
          }
          else {
            this.clear();
          }

          this.propagateChange(this.value);
        },
        source: async function(searchTerm, renderList) {
          let result = (await getSearchAll(searchTerm)).body;
          renderList(result.hits.hits.map((m)=>{
            let item = m._source[m._source.type];
            return {
              'id':item.id,
              'value':item.fullname || item.name,
              'obj':item,
              'name':item.fullname || item.name,
              'type':m._source.type,
              'link':aviaService.frontUrl + item.route_path
            };
          }));
        },
        onOpen: function() {
          onOpen();
        },
        onClose: function() {
          onClose();
        }
      },
      toolbar:false
    }
  }

  _post_media_set = false;

  getTaglink(name, route) {
    let tagPrefixExist = name.charAt(0) === '@';
    return `<a href="${route}" target=_blank>${!tagPrefixExist ? '@': ''}${name}</a>`
  }

  renderTag() {
    let deltasCopy = [];
    //@ts-ignore
    let deltas = this.editor.quillEditor.editor.delta.ops;
    deltas.forEach(item => {
      let tag = this.tags.filter(tag => tag.name.replace('@','') === item.insert.replace('@',''))[0];
      if (tag) {
        tag.added = true;
        let mention = {
          insert: {
            mention: {
              denotationChar: '',
              id: tag.card_id || tag.id,
              index: '',
              link: tag.url,
              name: tag.name,
              value: this.getTaglink(tag.name, tag.url)
            }
          }
        };
        deltasCopy.push(mention);
      } else {
        deltasCopy.push(item);
      }
    });

    // NOTE: Quill automatically added one extra new line after the content has been loaded at first time
    // NOTE: trim unwanted whitespace and line break at the end
    let last_str = deltas[deltas.length - 1]?.insert;
    if (last_str) {
      last_str = last_str.trimEnd();
      deltas[deltas.length - 1].insert = last_str + ' '; // Added one space after last tag
    }
    if (!!this.content) {
      //@ts-ignore
      this.editor.quillEditor.setContents([{ insert: '\n' }]);
      //@ts-ignore
      this.editor.quillEditor.updateContents(deltasCopy);
      this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength(), 1, 'user');
    }
  }

  ngOnInit() {
    if(!this.tags) this.tags = [];

    this.subscriptions.push(this.editor
    .onContentChanged
    .pipe(
      debounceTime(100),
      distinctUntilChanged()
    )
    .subscribe((data) => {
      this._value = data
    }))

    if(this.mention_only_mode) {
      this.subscriptions.push(this.editor
        .onContentChanged
        .pipe(
          distinctUntilChanged()
        )
        .subscribe((data) => {
          let text = this.editor.quillEditor.getText();
          if(text.indexOf('@') < 0) {
            //@ts-ignore
            this.editor.quillEditor.setContents({ops:[{ insert: "@" + text }]});
            this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength(), 1);
          }
        }))
    }


    this.subscriptions.push(this.editor.onContentChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((data)=>{
      if(data.text) {
        let matches = data.text.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gmi);
        if(matches && matches.length > 0) {
          if(matches[matches.length - 1] != this.url && (!this.mediaComponent || !this.mediaComponent.media || this._post_media_set != this.mediaComponent.media)) {
            this._post_media_set = false;
            this.url = matches[matches.length - 1];
          }
        }
      }
    }));

    this.subscriptions.push(this.editor.onContentChanged.pipe(
      distinctUntilChanged()
    ).subscribe((data)=>{
      this.propagateChange(this.value);
    }));

    this.subscriptions.push(this.editor.onEditorCreated.subscribe(()=>{
      if(this.focus_on_init == true) {
        this.focus(null, true);
      }

      setTimeout(()=>{
        this._quillLoaded = true;
        this.renderTag();
      });
    }));

    if (this.content) {
      this.content = this.sanitizeContentHtml(this.content);
      this._value.html = this.content;
    }

    if(this.media) {
      this._post_media_set = this.media;
    }
  }

  sanitizeContentHtml(content) {
    if(content == null) return null;
    content = content.replace(/class="mention"/g,'');
    return content;
  }

  removeTag(tag) {
    this.tags = this.tags.filter(t=>t!=tag);
    this.tags = [].concat(this.tags);
  }

  @HostListener('focus', ['$event.target'])
  onFocus(focus) {
    this.focus(null,true);
    if(this.mention_only_mode) {
      this.clear();
    }
  }

  onQuillFocus($event) {
    if(this.mention_only_mode) {
      let text = this.editor.quillEditor.getText();
      if(text.indexOf('@') < 0) {
        //@ts-ignore
        this.editor.quillEditor.setContents({ops:[{ insert: "@" + text }]});
        this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength(), 1);
      }
    }
  }

  clear() {
      //@ts-ignore
    this.editor.quillEditor.setContents({ops:[{ insert: '' }]});
    this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength(), 1);
  }

  captureReturn($event) {
    if(this._disable_return) {
      $event.cancelBubble = true;
      $event.preventDefault();
      return false;
    }
  }

  selectEmoji($event) {
    let mergeFieldText = $event.emoji.native + " ";
    let selection = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.insertText(selection.index, mergeFieldText);
    this.hideEmojiMart();
    this.focus(selection.index + mergeFieldText.length);
  }

  async addLink($event) {
    let selection = this.editor.quillEditor.getSelection(true);
    if($event.link) {
      if(!$event.text) $event.text = $event.link;
      if($event.link && !$event.link.match(/https?:\/\//gi)) {
        $event.link = 'https://' + $event.link;
      }

      this.editor.quillEditor.insertText(selection.index, $event.text + " ");
      this.editor.quillEditor.formatText(selection.index, $event.text.length, {"link":$event.link});
      this.url = $event.link;

      this.focus(selection.index + $event.text.length + 1);
    }
  }

  addTag() {
    let selection = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.insertText(selection.index, '@');
    this.editor.quillEditor.focus();
  }

  addTagFromRec(tag) {
    this.tags.push(tag);
    this.tags = [].concat(this.tags);
  }

  returnSubmit() {
    if(this.fs.aviaService.mobile_mode == true) {
      return false
    }
    else {
      this.submitText();
    }
  }


  submitText() {
    if((this.value && this.value.text && this.value.text.length > 0) || (this.value.media)) this.submit.emit(this.value);
  }

  ngOnDestroy() {
    for(let subscription of this.subscriptions)  {
      subscription.unsubscribe();
    }
  }

  focus(index = null, end = false) {
    setTimeout(()=>{
      if(this.editor && this.editor.quillEditor) {
        this.editor.quillEditor.focus();
        if(end) {
          this.editor.quillEditor.setSelection(this.editor.quillEditor.getLength(), 0);
        }
        else if (index) {
          this.editor.quillEditor.setSelection(index, 0);
        }
      }
    },0);
  }


    // Control Value Accessor - These functions must be declared even if empty

    writeValue(value: any) {
    };

    propagateChange = (_: any) => {

    };
    registerOnChange(fn) {
      this.propagateChange = fn;
    };

    onTouched: any = () => {};
    registerOnTouched(fn) {
      this.onTouched = fn;
    };

    setDisabledState(isDisabled: boolean): void {
    };
}
