import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AVIAConnectService } from 'src/app/avia-connect.service';
import { Common } from 'src/app/common';

import { Color_HSL } from 'src/interface';

import { AccessKeychain_Communities,
  Community,
  CommunityPage,
  Org_HqAddr,
  Site_Map,
} from '../../class';


@Injectable({
  providedIn: 'root'
})
export class CommunitiesService {
  public readonly REGEX_LOOSENAME: RegExp = /^[^\s]+[^\n\r]{2,}/;

  public readonly PULSE_CC_TEXT: string = 'Command Center Digital Pulse Section';

  public archived_communities:         Community[];
  public color_as_hsl:                 Color_HSL = { h: 0, s: '100%', l: '50%' };
  public communities:                  Community[];
  public community:                    Community;
  public community_page:               CommunityPage;
  public community_hq_addr:            Org_HqAddr;
  public edit_mode:                    boolean = true;
  public page_links_body:              any;
  public page_links_footer:            any;
  public page_links_sidebar:           any;
  public page_links_sidebar_open_dict: any = {};
  public premium_communities:          Community[];
  public site_maps:                    Site_Map[] = [];
  public show_sidebar:                 boolean = true;
  public site_map:                     string = 'home';
  public standard_communities:         Community[];
  public users_interests_topics:       object = {};


  constructor(public aviaService: AVIAConnectService, private router: Router) { }

  goToCommunitiesList(): void {
    this.router.navigate(['communities'], {queryParams: []});
  };

  goToLandingPage(): void {
    this.router.navigate(['communities', 'page', this.community.landing_page], {queryParams: []});
  };

  async initArchive(): Promise<any> {
    this.archived_communities = (await this.aviaService.getCommunities({is_archive: 1})).body;
  };

  async initHome(updated_communities: Community[] = undefined){
    if (!updated_communities) {
      this.communities = (await this.aviaService.getCommunities()).body;
    } else {
      this.communities = updated_communities;
    }
    this.premium_communities = this.communities.filter(com => com.status == 2);
    this.standard_communities = this.communities.filter(com => com.status == 1);
    this.setSiteMap();
  };

  async initPage(page_id: number): Promise<any> {
    // Get the Community Page or warn of a failure and go back to the list
    this.site_map = 'page'
    let community_page_res = await this.aviaService.getComPage(page_id);
    if (community_page_res.status == 200) {
      this.community_page = community_page_res.body;
      if (!this.community_page.id) {
        this.aviaService.notifyFailure(`Something went wrong...\n\nCommunity Page ID is not valid.`);
        this.goToCommunitiesList();
      }
    } else {
      this.aviaService.notifyFailure(`Something went wrong...\n\n${community_page_res.body.err_msg}`);
      this.goToCommunitiesList();
      return;
    }

    // Get the Community and its owners, but assemble the data before we send it to our dependants
    let community_res = await this.aviaService.getCommunity(this.community_page.cm_id);
    if (community_res.status == 200) {
      this.community = community_res.body;
    } else {
      this.aviaService.notifyFailure(`Something went wrong...\n\nCould not get Community with ID: ${this.community_page.cm_id}.`);
      console.error('Could not get Community! - ERROR: ', community_res);
      this.goToCommunitiesList();
      return;
    }

    // Get the Community Org address
    if (this.community.org_obj) {
      this.community_hq_addr = this.community.org_obj.hq_addr;
    }

    // Get the Community color as HSL
    if (this.community && this.community.color) {
      this.color_as_hsl = Common.hexToCssHsl(this.community.color);
    }

    this.refreshBody(page_id);
    this.refreshSideBar(page_id);
    this.refreshFooter(page_id);

    this.initUserAccess_Communities(this.community.rd);
    this.setSiteMap();
  };

  setSiteMap() {
    if(this.site_map == 'home') {
      this.site_maps = [
        {name: 'Home', link: '/start', param: {} }, 
        {name: 'Communities', link: '/communities', param: {}},
      ];
    }
    else if(this.site_map == 'page' && this.community.id && this.community_page.id == this.community.landing_page){
      this.site_maps = [
        {name: 'Home', link: '/start', param: {} }, 
        {name: 'Communities', link: '/communities', param: {}},
        {name: this.community.name, link: '/communities/page/'+this.community.landing_page, param: {} },
      ];
    }
    else if(this.site_map == 'page' && this.community.id && this.community_page.id != this.community.landing_page){
      this.site_maps = [
        {name: 'Home', link: '/start', param: {} }, 
        {name: 'Communities', link: '/communities', param: {}},
        {name: this.community.name, link: '/communities/page/'+this.community.landing_page, param: {} },
        {name: this.community_page.name, link: '/communities/page/'+this.community_page.id, param: {}},
      ];
    }
  }

  async refreshBody(page_id: number): Promise<any> {
    this.page_links_body = (await this.aviaService.getComPageLinks(page_id, {location: 1})).body;

    let user_ids = this.page_links_body.filter(link => link.link_type === "user").map(user => user.link_content.id);
    if (user_ids && user_ids.length) {
      this.users_interests_topics = (await this.aviaService.getUsersInterestsTopics({user_ids, options: {slice: 3}})).body;
    }
  };

  async refreshFooter(page_id: number): Promise<any> {
    this.page_links_footer = (await this.aviaService.getComPageLinks(page_id, {location: 3})).body;
  };

  async refreshSideBar(page_id: number): Promise<any> {
    this.page_links_sidebar = (await this.aviaService.getComPageLinks(page_id, {location: 2})).body;
    this.show_sidebar = (Object.keys(this.page_links_sidebar).length > 0);
  };


  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  * USER ACCESS
  * The following contain Objects of booleans - e.g., { c: true, r: true, w: true, d: true }
  * How to use in the HTML *ngIf="keychain_cm.core.r"
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  public keychain_cm: AccessKeychain_Communities = new AccessKeychain_Communities();

  private async initUserAccess_Communities(rd: Object = {}) {
    let session = await this.aviaService.getSessionSupport();
    this.keychain_cm.core = this.aviaService.hasAccess(session, 'cm', 'core', 'crwd', rd);
    this.keychain_cm.pages = this.aviaService.hasAccess(session, 'cm', 'pages', 'crwd', rd);
    this.keychain_cm.premium = this.aviaService.hasAccess(session, 'org', 'premium', 'e', rd);
    this.keychain_cm.premium_badges = this.aviaService.hasAccess(session, 'org', 'premium_badges', 'e', rd);

    // for (let item of Object.keys(this.keychain_inv)) {
    //   this.VERBOSE && console.log('Access Key: %s - C:%s, R:%s, W:%s, D:%s, E:%s', item, this.keychain_inv[item].c, this.keychain_inv[item].r, this.keychain_inv[item].w, this.keychain_inv[item].d, this.keychain_inv[item].e);
    // }
  };

}
