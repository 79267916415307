<div class="form-group-row mb-4 mb-md-6" [formGroup]="form_group">

	<!-- FORM GROUP ROW -->
	<div class="d-md-flex">

		<!-- EMAIL -->
		<div class="form-group px-md-2 w-100">
			<label for="email">Email</label>
			<span class="float-right"><small><i>required</i></small></span>
			<input
				class="form-control"
				formControlName="email"
				id="email"
				name="email"
				placeholder="{{'name@example.com'}}"
				type="email"
				[ngClass]="{'is-invalid': email_invalid}"
			>
			<small *ngIf="email_invalid" class="form-text text-danger">
				You must submit a valid email
			</small>
		</div>

		<!-- FIRST NAME -->
		<div class="form-group px-md-2 w-100">
			<label for="firstname">First Name</label>
			<span class="float-right"><small><i>optional</i></small></span>
			<input
				class="form-control"
				formControlName="firstname"
				id="firstname"
				placeholder="First Name"
				type="text"
			>
			<span *ngIf="firstname_invalid" class="text-warning">
				<small><i>First name should be alphanumeric. </i></small>
			</span>
			<span *ngIf="form_group.controls.firstname.touched && form_group.controls.firstname.value.trim().length < 2" class="text-warning">
				<small><i>First name should have 2 or more characters.</i></small>
			</span>
		</div>

		<!-- LAST NAME -->
		<div class="form-group px-md-2 w-100">
			<label for="lastname">Last Name</label>
			<span class="float-right"><small><i>optional</i></small></span>
			<input
				class="form-control"
				formControlName="lastname"
				id="lastname"
				placeholder="Last Name"
				type="text"
			>
			<span *ngIf="lastname_invalid" class="text-warning">
				<small><i>Last name should be alphanumeric. </i></small>
			</span>
			<span *ngIf="form_group.controls.lastname.touched && form_group.controls.lastname.value.trim().length < 2" class="text-warning">
				<small><i>Last name should have 2 or more characters.</i></small>
			</span>
		</div>

		<!-- TYPE -->
		<div class="form-group px-md-2 w-100" *ngIf="show_type_chooser">
			<label for="is_owner">Type</label>
			<span class="float-right"><small><i>required</i></small></span>
			<select id="is_owner" class="form-control" formControlName="is_owner">
				<option disabled selected value>Choose a type</option>
				<option *ngFor="let type of types; let i=index;" [value]="type.value" >{{type['name']}}</option>
			</select>
		</div>

		<!-- ORG PLEASE NOT ORG VS AVIA ORG -->
		<div class="form-group px-md-2 w-100" *ngIf="show_org_chooser">
			<label for="org" class="text-uppercase">Organization</label>
			<span class="float-right"><small><i>required</i></small></span>
			<app-avia-orgs-search [options]="{ text:invitees_org ? invitees_org.name: 'Choose a new host...' }"
				(done)="updateOrg($event)"
			></app-avia-orgs-search>
		</div>

		<!-- DELETE THE ROW (DESKTOP) -->
		<button
			aria-label="Delete Row"
			class="close d-none d-md-inline form-group pt-md-6"
			type="button"
			(click)="removeMe()"
			>
			<span aria-hidden="true" class="text-danger"><i class="material-icons">remove_circle</i></span>
		</button>
	</div>

	<!-- STATUS MESSAGES -->
	<div *ngIf="success_content !== undefined" class="mb-4 px-md-2 small text-primary"><b>Success!</b> - {{success_content}}</div>
	<div *ngIf="failure_content !== undefined" class="mb-4 px-md-2 small text-danger"><b>Failure!</b> - {{failure_content}}</div>

	<!-- DELETE THE ROW (MOBILE) -->
	<button
		aria-label="Delete Row"
		class="btn btn-danger d-block d-md-none mx-auto"
		type="button"
		(click)="removeMe()"
	>Remove Row</button>

	<!-- Border for Mobile -->
	<div class="border-top d-block d-md-none mt-5 pb-2 w-100"></div>
</div>
