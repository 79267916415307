
<div *ngIf="showing" class='fullscreen zLow position-absolute text-white'>
	<!-- background (dismissable clickarea) -->
	<div class='fullscreen zLow position-absolute' (click)="close()" style='background-color:black; opacity: 0.75;'>
	</div>

	<!-- content -->
	<div class='zHigh position-relative' style=''>
		<div class='d-flex bg-black position-relative' style='top: 0;height: 60px;'>
			<div class="pointer" (click)="close()"><i class="material-icons px-2" [ngClass]="{'text-white':can_close, 'text-gray-600':!can_close}" style="line-height:60px;">arrow_back</i></div>
			<div class="pointer"><i class="material-icons px-2" style="line-height:60px;">{{content_viewer === 'isImage' ? 'image' : content_viewer == 'isAudio' ? 'volume_up' : content_viewer === 'isPDF' ? 'library_books' : content_viewer === 'isVideo' ? 'videocam' : content_viewer === 'isDownload' ? 'attachment' : content_viewer === 'isLink' ? 'link' : 'blur_on'}}</i></div>
			<div class="w-100 text-truncate" style="line-height:60px;">{{content_name}}</div>
			<div *ngIf="content_viewer === 'isPDF'" class="pointer" (click)="zoomIn()"><div class='text-white' style="line-height:60px;">{{(pdfzoom_now*100).toFixed(0) + "%"}}</div></div>
			<div *ngIf="content_viewer === 'isPDF'" class="pointer" (click)="zoomIn()"><div class='text-white'><i class="material-icons px-2" [ngClass]="{'text-white':pdfzoom < pdfzoom_max, 'text-gray-600':pdfzoom_max <= pdfzoom}" style="line-height:60px;">zoom_in</i></div></div>
			<div *ngIf="content_viewer === 'isPDF'" class="pointer" (click)="zoomOut()"><div class='text-white'><i class="material-icons px-2" [ngClass]="{'text-white':pdfzoom_min < pdfzoom, 'text-gray-600':pdfzoom <= pdfzoom_min}" style="line-height:60px;">zoom_out</i></div></div>
			<div *ngIf="content_viewer !== 'isDownload' && content_viewer !== 'isLink'" class="pointer"><a class='text-white' [href]='sanitizer.bypassSecurityTrustResourceUrl( content_url )' target="_blank" download><i (click)="logDownload()" class="material-icons px-2" style="line-height:60px;">cloud_download</i></a></div>
			<div *ngIf="content_viewer === 'isDownload' || content_viewer === 'isLink'" class="pointer"><div class='text-white' ngxClipboard [cbContent]="content_url"><i class="material-icons px-2" style="line-height:60px;">filter_none</i></div></div>
			<div *ngIf="can_print" (click)="logDownload()" class="pointer" (click)="print( content_url )"><a class='text-white' [href]='sanitizer.bypassSecurityTrustResourceUrl( content_url )' target="_blank" download><i class="material-icons px-2" style="line-height:60px;">print</i></a></div>
			<div class="pointer"><i class="material-icons px-2 text-gray-600" style="line-height:60px;">share</i></div>
		</div>

		<!--
		<div class='text'>
			<div>
				Name: <B>{{content_filename}}</B>
			</div>
			<div>
				URL: <a [href]='sanitizer.bypassSecurityTrustResourceUrl( content_url )' target="_blank">{{content_url}}</a>
			</div>
			<div>
				Type: {{content_type}}
			</div>
		</div>
		-->
		<div *ngIf='!ready' (click)="close()" style="position: relative; height: calc( 100vh - 60px );overflow:auto;">
				<div (click)="$event.stopPropagation()">
					<div *ngIf="!notfound" class="m-auto position-absolute trbl-0 text-center" style="height:1rem;">detecting content type...</div>
					<div *ngIf="notfound" class="m-auto position-absolute trbl-0 text-center" style="height:1rem;">content not found...</div>
				</div>
		</div>
		<div *ngIf='ready' class='zMed' (click)="close()" style="position: relative; height: calc( 100vh - 60px );overflow:auto;">
			<div (click)="$event.stopPropagation()">
				<ng-container *ngIf='content_viewer === "isImage"'>
					<!-- todo: would like to put a opacity:15% background behind the image (instead of opaque/black),
								so use doesn't feel like clicking in the transparent area
							  todo, get this working.  -->
					<img class="position-absolute m-auto trbl-0" style="max-width:80%;max-height:80%;background-color:rgba(0,0,0,.25);" [src]='sanitizer.bypassSecurityTrustResourceUrl( content_url )'/>
				</ng-container>
				<ng-container *ngIf='content_viewer === "isVideo"'>
					<video class="position-absolute m-auto trbl-0" style="width:80%;" controls>
						<source [src]='sanitizer.bypassSecurityTrustResourceUrl( content_url )' [type]='content_type'>
						Your browser does not support the video tag.
					</video>
				</ng-container>
				<ng-container *ngIf='content_viewer === "isAudio"'>
					<audio class="position-absolute m-auto trbl-0" style="width:80%;" controls>
						<source [src]='sanitizer.bypassSecurityTrustResourceUrl( content_url )' [type]='content_type'>
						Your browser does not support the audio tag.
					</audio>
				</ng-container>
				<ng-container *ngIf='content_viewer === "isPDF"'>
					<div class="m-auto position-absolute trbl-0 text-center" style="height:1rem;">{{progress_total > 1 ? ((progress / progress_total) * 100.0).toFixed(0) + '%' : (progress | formatFileSize)}}</div>
					<!-- <div *ngIf="content_viewer !== ''" class="m-auto position-absolute trbl-0 text-center" style="height:1rem;">{{progress | formatFileSize}}</div> -->
					<!-- <div *ngIf="content_viewer !== '' && progress_total > 1" class="m-auto position-absolute trbl-0 text-center" style="height:1rem;">{{progress_total | formatFileSize}} Total</div> -->
						<!-- <div [ngClass]="{'w-80': true}"> -->
						<pdf-viewer *ngIf="showing" (click)="$event.stopPropagation()" style='background-color:black;display: block;'
							[src]='content_url'
							[original-size]="false"
							[render-text]="true"
							[autoresize]="true"
							[show-all]="true"
							[zoom]="pdfzoom"
							[fit-to-page]="false"
							(after-load-complete)="onPdfLoad($event)"
							(error)="onPdfError($event)"
							(on-progress)="onPdfProgress($event)"
							(page-rendered)="onPdfRendered($event)"
						></pdf-viewer>
						<!--
						<avia-pdf-viewer
							*ngIf="showing"
							style='background-color:black;display: block;'
							[autoresize]="true"
							[fit-to-page]="false"
							[original-size]="false"
							[render-text]="true"
							[show-all]="true"
							[src]='content_url'
							[zoom]="pdfzoom"
							(after-load-complete)="onPdfLoad($event)"
							(click)="$event.stopPropagation()"
							(error)="onPdfError($event)"
							(on-progress)="onPdfProgress($event)"
							(page-rendered)="onPdfRendered($event)"
						></avia-pdf-viewer>
						-->
					<!-- </div> -->
				</ng-container>
				<ng-container *ngIf='content_viewer === "isLink"'>
					<div class="position-absolute m-auto trbl-0 text-center p-5" style="max-width:20rem;max-height:12rem;">
							<div class='p-4' style='background-color:rgba(0,0,0,.25);'>
								<div class="text-nowrap"><i class="material-icons p-2 align-middle">link</i><span class="align-middle">Open link in new tab</span></div>
								<div class="btn btn-light btn-sm text-black p-0 m-0 px-2 m-2" role="button" (click)="openLinkInNewTab( content_url )"><i class="material-icons pr-2 align-middle">link</i><span class="align-middle">OPEN</span></div>
							</div>
						</div>
				</ng-container>
				<ng-container *ngIf='content_viewer === "isDownload"'>
					<div class="position-absolute m-auto trbl-0 text-center p-5" style="max-width:20rem;max-height:12rem;">
						<div class='p-4' style='background-color:rgba(0,0,0,.25);'>
							<div class="text-nowrap"><i class="material-icons p-2 align-middle">link</i><span class="align-middle">Download file to your {{isMobileDevice() ? "device" : "computer"}}</span></div>
							<a (click)="logDownload()" class="btn btn-light btn-sm text-black p-0 m-0 px-2 m-2 text-truncate" style="max-width: 14rem" role="button" [href]='content_url' download><i class="material-icons pr-2 align-middle">cloud_download</i><span class="align-middle" style="text-transform: none;">{{content_filename}}</span></a>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
