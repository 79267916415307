import * as _ from 'lodash';
import { AVIAConnectService } from './../../avia-connect.service';
import { Common } from './../../common';
import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { inspect } from 'util';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

// AVIA Selector
//
// 1. Example Usage With TextBox & Custom Data
// @example start
//  <app-avia-selector class="selector-click"
//    [active_data]="[{id: 1}]"
//    [passed_data]="[{id: 1, name: 'General'},{id: 2, name: 'Technical Problem with AVIA Connect'},{id: 3, name: 'Need help using AVIA Connect'},{id: 4, name: 'Feature Request'}]"
//    [options]="{ textbox: true, type: 'passed' }" (done)="$event['selected'][0]"
//  ></app-avia-selector>
// @example end
//
// 2. Example Usage Without TextBox
// @example start
// <app-avia-selector
//    [active_data]="[{name: 'active1'}, {name: 'active2'}]"
//    [options]="{type: 'passed', textbox: false, id_name: 'id', passed_data_selector_title: 'Select Type'}"
//    [passed_data]="[{name: 'passed1'}, {name: 'passed2'}]">
// </app-avia-selector>
// @example end
@Component({
  selector: 'app-avia-selector',
  templateUrl: './avia-selector.component.html',
  styleUrls: ['./avia-selector.component.scss']
})
export class AviaSelectorComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  // Variables
  @Input() options:         Object = {};
  @Input() active_data:     any[]  = [];
  @Input() passed_data:     any[]  = [];
  @Input() name_access_key: string = 'name';

  @Output('init') init         = new EventEmitter(); // called once on init                      $event will be the selected item
  @Output('reselect') reselect = new EventEmitter(); // called on reselect                       $event will be the selected item
  @Output('done') done         = new EventEmitter(); // called on select only when data changed  $event will be the selected item

  // @Input()
  // set options(inputData: Avia_Popup_Chat_Options) {
  //   this._options = new Avia_Popup_Chat_Options();
  //   for (let i in inputData) {
  //     this._options[i] = inputData[i];
  //   }
  // };
  // get options(): Avia_Popup_Chat_Options {
  //   return this._options;
  // };
  // _options: Avia_Popup_Chat_Options = new Avia_Popup_Chat_Options();

  // export class Avia_Popup_Chat_Options {
  //   constructor(
  //     public color: string = 'gray',
  //     public default_message: boolean = true
  //   ) { }
  // }

  _options: Object = {
    allow_deselection: true,
    avians_only: false,
    card_id: null,
    card_level: null,
    card_type: null,
    clear_active_data_on_changes: false,
    count: 10,
    disabled: false,
    dropdown_alignment: 'left',
    id_name: 'id',
    item_prefix: '',
    multi_select: false,
    passed_data_selector_title: null,
    required: false,
    start: 0,
    statictitle: null,
    textbox: false,
    toggle: false,
    toggle_text: 'Add',
    type: 'user'
  };
  active_data_ids:   any[]    = [];
  default_title:     string;
  data:              Object[];
  indexed_data:      Object;
  selection_active:  boolean = false;
  title:             string;
  toggle_state_on:   boolean;


  // Logic
  constructor( private config: NgbDropdownConfig, private aviaService: AVIAConnectService ) {
    this.config.autoClose = false;
  }

  ngOnInit():void {
    if( !this.validateActiveData() ) this.active_data = [];
    this.setOptions();
    // this.getData();
  }

  ngOnChanges():void {
    if ( !this.validateActiveData() || this._options['clear_active_data_on_changes'] ) this.active_data = [];
    this.setOptions();
    this.getData();
  }

  ngAfterViewInit():void {
    let id = this._options['id_name'];

    // [init emitter]
    // if we're using custom data:
    if (
      this.passed_data !== null &&
      this.passed_data !== undefined &&
      this.passed_data[0] !== undefined &&
      this.active_data !== undefined &&
      this.active_data.length > 0 &&
      this.active_data[0][id] !== undefined
      ) {
      let obj = this.passed_data.find( r=> this.active_data[0][id] == r[id] )
      this.init.emit({ selected: [obj] });
    }
    // if we're NOT using custom data:
    //else // todo: implment me.. :)
    //  this.init.emit();
  }

  validateActiveData():boolean {
    if( this.active_data != undefined && this.active_data != null ) { // must not be undefined or null
      if( Array.isArray(this.active_data) == true ) {                 // must be an array
        for( let obj of this.active_data ) {
          if( obj != undefined && obj != null ) {                     // can not contain an undefined or null element
            if( Object.getOwnPropertyNames(obj).length == 0 ) {       // no element can be an empty object
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  validatePassedData():boolean {
    if( this.passed_data != undefined && this.passed_data != null ) { // must not be undefined or null
      if( Array.isArray( this.active_data ) == true ) {               // must be an array
        for( let obj of this.passed_data ) {
          if( obj != undefined && obj != null ) {                     // can not contain an undefined or null element
            if( Object.getOwnPropertyNames(obj).length == 0 ){        // no element can be an empty object
              return false;
            }
          } else {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  setOptions() {
    for (let key of Object.keys(this.options)) {
      this._options[key] = this.options[key];
    }
    if (this._options['toggle']) {
      this.toggle_state_on = false;
    }
  }

  getData():void {
    let result: Promise<any>;

    let getOrgs = ():void => {
      if (this.passed_data.length == 0) {
        result = this.aviaService.getOrgs();
      } else {
        result = new Promise( (res, rej) => res(this.passed_data) );
      }
      result.then((data) => {
        this.data = data;
        this.indexDataById();
        this.name_access_key = 'name';
        this.default_title = this._options['passed_data_selector_title'] ?
          this._options['passed_data_selector_title'] :
          this._options['multi_select'] ? 'Select Organizations' : 'Select an Organization';
        this.setUpStyle();
      });
    }

    let getUsers = ():void => {
      if (this.passed_data.length == 0) {
        let obj = { 'status': 4 };
        if (this._options['avians_only']) {
          obj['org'] = 1;
        }
        result = this.aviaService.getUsers(obj);
      } else {
        result = new Promise( (res, rej) => res(this.passed_data) );
      }
      result.then((data) => {
        this.data = data;
        this.indexDataById();
        this.name_access_key = 'fullname';
        this.default_title = this._options['passed_data_selector_title'] ?
          this._options['passed_data_selector_title'] :
          this._options['multi_select'] ? 'Select users' : 'Select a user';
        this.setUpStyle();
      });
    }

    let getCards = ():void => {
      let paramsObj = {};
      //if(this._options['card_level']) paramsObj['level'] = this._options['card_level'];
      if(this._options['card_type'])  paramsObj['type'] = this._options['card_type'];
      this.aviaService.getKMcards(paramsObj).then((data) => {
        this.data = data.body;
        this.indexDataById();
        this.name_access_key = 'name';
        this.default_title = this._options['passed_data_selector_title'] ?
          this._options['passed_data_selector_title'] :
          this._options['multi_select'] ? 'Select Cards' : 'Select a Card';
        this.setUpStyle();
      });
    }

    let getChildrenOrParents = ():void => {
      if ((/*!this._options['card_level'] || */!this._options['card_id'])) {
        this.default_title = this._options['passed_data_selector_title'] ?
          this._options['passed_data_selector_title'] :
          this._options['multi_select'] ? 'Select Cards' : 'Select a Card';
        this.setUpStyle();
      } else {
        this.aviaService.getKMcard(this._options['card_id']).then((data) => {
          this.data = this._options['type'] === 'card_parents' ? data.body.parents : data.body.children;
          this.indexDataById();
          this.name_access_key = 'name';
          this.default_title = this._options['passed_data_selector_title'] ?
            this._options['passed_data_selector_title'] :
            this._options['multi_select'] ? 'Select Cards' : 'Select a Card';
          this.setUpStyle();
        });
      }
    }

    switch (this._options['type']) {
      case 'org':
        getOrgs();
      break;

      case 'user':
        getUsers();
      break;

      case 'card':
        getCards();
      break;

      case 'card_children':
        getChildrenOrParents();
      break;

      case 'card_parents':
        getChildrenOrParents();
      break;

      case 'passed':
        if( this.validatePassedData() && this.validateActiveData() ){
          this.data = this.passed_data;
          //console.log( 'avia-selector.component data:\n%s\n\n', inspect(this.data) );
          this.indexDataById();
          this.default_title = this._options['passed_data_selector_title'] ? this._options['passed_data_selector_title'] : '-';
          this.setUpStyle();
        }
      break;
    }
  }

  indexDataById():void {
    this.indexed_data = Common.indexIt(this._options['id_name'], _.cloneDeep(this.data));
  }

  setUpStyle():void {
    let temp_title = [];
    this.active_data_ids = [];
    for (let p of this.active_data) {
      if( p == undefined || p == null ) continue;
      let id_name = this._options['id_name'];
      let id = (p[id_name] != undefined && p[id_name] != null) ? p[id_name] : '';
      if( id === '' ) continue;
      this.active_data_ids.push(id);
      if(
        this.indexed_data == undefined ||
        this.indexed_data[id.toString()] == undefined ||
        this.indexed_data[id.toString()][this.name_access_key] == undefined
      ) { continue };
      temp_title.push(this.indexed_data[id.toString()][this.name_access_key]);
    }
    this.title = temp_title.length > 0 ? temp_title.join(', ') : this.default_title;
  }

  select(data, $event, dropdown):void {
    this._options['multi_select'] ? this.selectMultiData(data, $event) : this.selectSingleData(data, $event, dropdown);
    this.setUpStyle();
  }
  selectMultiData(data, $event):void {
    if (this.active_data.some((p) => p[this._options['id_name']] == data.id)) {
      this.active_data = this.active_data.filter((p) => p[this._options['id_name']] != data.id);
    } else {
      this.active_data.push(data);
    }
  }
  selectSingleData(data, $event, dropdown):void {
    let chooseData = () => {
      this.active_data = [];
      this.active_data.push(data);
      this.selection_active = true;
    };

    if( this.active_data[0] == undefined ) {
      chooseData();
    } else {
      if ( this.active_data.some((p) => p[this._options['id_name']] == data.id) ) {
        if (this._options['allow_deselection']) {
          this.active_data = [];
          this.selection_active = false;
        } else {
          $event.stopPropagation();
          dropdown.close();
          this.selection_active = true;
          this.reselect.emit({ selected: this.active_data });
          return;
        }
      } else {
        chooseData();
      }
    }
    this.done.emit({ selected: this.active_data });
    $event.stopPropagation();
    dropdown.close();
  }

  closeAndEmitMulti($event, dropdown):void {
    if (this._options['multi_select']) {
      this.done.emit({ selected: this.active_data });
    }
    $event.stopPropagation();
    dropdown.close();
  }

  toggle():void {
    this.toggle_state_on = !this.toggle_state_on;
  }

  ngOnDestroy():void {
    this.config.autoClose = true;
    this.selection_active = false;
  }

}
