<div [ngClass]="{'pointer': user?.user_id}">
	<!-- SMALL -->
	<!-- SMALL -->
	<!-- SMALL -->
	<ng-container *ngIf="size === 'sm'">
		<div class="d-flex">
			<!-- IMAGE -->
			<a *ngIf="show_avatar" target="_blank" href="{{ user?.user_id ? '/profiles/in/'+ user?.user_id : '#'}}" (click)="have_user($event)">
				<img
					class="avatar-img-sm rounded"
					onerror="this.src='../../../assets/default-user.jpg'"
					[src]="user?.avatar"/>
			</a>
			<!-- TEXT -->
			<div *ngIf="show_text" [ngClass]="{'mx-2': show_avatar}">
				<a target="_blank" href="{{ user?.user_id ? '/profiles/in/'+ user?.user_id : '#'}}" (click)="have_user($event)">
					<span class="font-weight-bold">{{user?.fullname}}</span>
					<span *ngIf="user?.title" class="font-weight-bold">, {{user?.title}}</span>
				</a>
				<span *ngIf="user?.org?.name">
					<span class="border-left mx-2 border-gray-400"></span>
					<a target="_blank" href="{{
						user?.org.type == 2 ? '/sc/' + user?.org.id :
						user?.org.type == 1 ? '/profiles/hs/' + user?.org.id :
																	'/members/card/org' + user?.org.id
					}}">
						<span class="font-weight-bold">{{user?.org?.name}}</span>
					</a>
				</span>
				<span class="ml-2 font-size-12 text-gray-600">{{posted_time_from_now}}</span>
			</div>
		</div>
	</ng-container>
	<!-- LARGE -->
	<!-- LARGE -->
	<!-- LARGE -->
	<ng-container *ngIf="size === 'md'">
		<div class="d-flex">
			<!-- IMAGE -->
			<a *ngIf="show_avatar" target="_blank" href="{{ user?.user_id ? '/profiles/in/'+ user?.user_id : '#'}}" (click)="have_user($event)">
				<img
					class="avatar-img-md rounded"
					onerror="this.src='../../../assets/default-user.jpg'"
					[src]="user?.avatar"/>
			</a>
			<!-- TEXT -->
			<div *ngIf="show_text" [ngClass]="{'mx-2': show_avatar}">
				<a target="_blank" href="{{ user?.user_id ? '/profiles/in/'+ user?.user_id : '#'}}" (click)="have_user($event)">
					<span class="font-weight-bold">{{user?.fullname}}</span>
					<span *ngIf="user?.title" class="font-weight-bold"></span>
				</a>
				<span *ngIf="user?.org?.name">
					<span class="border-left mx-2 border-gray-400"></span>
					<a target="_blank" href="{{
						user?.org.type == 2 ? '/sc/' + user?.org.id :
						user?.org.type == 1 ? '/profiles/hs/' + user?.org.id :
																	'/members/card/org' + user?.org.id
					}}">
						<span class="font-weight-bold">{{user?.org?.name}}</span>
					</a>
				</span>
				<div *ngIf="show_date" class="font-size-12 text-gray-600">
					<span *ngIf="user?.title">{{user?.title}}&nbsp;•&nbsp;</span>
					<span *ngIf="prepend_date_text">{{ prepend_date_text }}&nbsp;</span>
					<span>{{ humanTime(created) }}</span>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="size === 'lg'">
		<div class="d-flex">
			<!-- IMAGE -->
			<a *ngIf="show_avatar" target="_blank" href="{{ user?.user_id ? '/profiles/in/'+ user?.user_id : '#'}}" (click)="have_user($event)">
				<img
					class="avatar-img rounded"
					onerror="this.src='../../../assets/default-user.jpg'"
					[src]="user?.avatar"/>
			</a>
			<!-- TEXT -->
			<div *ngIf="show_text" [ngClass]="{'mx-2': show_avatar}">
				<a target="_blank" href="{{ user?.user_id ? '/profiles/in/'+ user?.user_id : '#'}}" (click)="have_user($event)">
					<span class="font-weight-bold">{{user?.fullname}}</span>
				</a>
				<span *ngIf="user?.org?.name">
					<span class="border-left mx-2 border-gray-400"></span>
					<a target="_blank" href="{{
						user?.org.type == 2 ? '/sc/' + user?.org.id :
						user?.org.type == 1 ? '/profiles/hs/' + user?.org.id :
																	'/members/card/org' + user?.org.id
					}}">
						<span class="font-weight-bold">{{user?.org?.name}}</span>
					</a>
				</span>
				<div *ngIf="show_date" class="font-size-12 text-gray-600">
					<span *ngIf="user?.title">{{user?.title}}&nbsp;•&nbsp;</span>
					<span *ngIf="prepend_date_text">{{ prepend_date_text }}&nbsp;</span>
					<span>{{ humanTime(created) }}</span>
				</div>
			</div>
		</div>
	</ng-container>
</div>
