import { Subscription } from 'rxjs';
import { AVIAConnectService } from 'src/app/avia-connect.service';
import { Injectable } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';

@Injectable({
  providedIn: 'root'
})
export class TourService {
  public tours = {};
  public subscription: Subscription;

  constructor(public aviaService: AVIAConnectService, public joyrideService: JoyrideService) { }

  public async init() {
    let res = await this.aviaService.getTours();
    if(res.status = 200) this.tours = res.body;
  }

  public async checkForTour(tour_id) {

    let res = await this.aviaService.getTours(tour_id);

    if(res.status = 200) {
      this.tours[tour_id] = res.body[tour_id];
      this.startTour(this.tours[tour_id])
      // console.log(this.tours)
    }

  }

  public async startTour(tour) {
    if(!tour) return

    const steps = tour.map(t => t.step);

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.joyrideService.startTour({
      steps: steps,
      // stepDefaultPosition: 'top',
      showPrevButton: false,
      showCounter: true
    }).subscribe(
      async data => {
          console.log('step', data.name)
          console.log('data', data)
          this.aviaService.upsertTourItem(data.name, {completed:1});
      },
      async error => {
          console.log('error', error)
      },
      async() => {
        /* Temp fix until group endpoint is complete */
        for(let name of steps){
          this.aviaService.upsertTourItem(name, {completed:1});
        }
      }
    )
  };

  /* * * * * * * * * * * * * * * * * * * *
   * THESE LISTENERS ARE ALSO AVAILABLE! *
   * * * * * * * * * * * * * * * * * * * *

    public async onNext(tour_item) {
      console.log('onNext', tour_item)
        // Do something
    }

    public async onPrev(tour_item) {
      console.log('onPrev', tour_item)
        // Do something
    }

    public async onDone(tour_item) {
      console.log('onDone', tour_item)
        // Do something
    }
  * * * * * * * * * * * * * * * * * * * */

}
