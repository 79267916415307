<form [formGroup]="picture_form" #formElem="ngForm" (ngSubmit)="onSubmit()">
	<div class="row justify-content-center py-4">
		<div class="col-10 col-md-8 col-lg-6">
				<h2 class="heading-size-36 text-center mt-4">Add your picture</h2>
				<div class="px-lg-7 text-center font-weight-light text-gray-800 font-body font-size-16">Upload a photo of yourself to use as your profile image.</div>
		</div>
	</div>
	<div class="flex-row d-flex w-100">
		<div class="mx-auto">

			<app-avia-onboarding-image-crop
				[img_link]="picture_form.controls.avatar.value"
				[submit_on_crop]="true"
				(onSave)="onChangeAvatar($event)"
				(onSourceImageChange)="onSourceImageChange($event)"
			></app-avia-onboarding-image-crop>
		</div>
	</div>
</form>
