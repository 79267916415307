import { ChangeEvent } from 'angular2-virtual-scroll';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VirtualScrollComponent } from 'angular2-virtual-scroll';

import { AVIAConnectService } from './../../../avia-connect.service';
import { Color_Library, E_ConnectionManagerActions, E_ConnectionStatus, UserOrg_StatusObj } from '../../../../class';


@Component({
  selector: 'app-users-orgs-list',
  templateUrl: './users-orgs-list.component.html',
  styleUrls: ['./users-orgs-list.component.scss']
})
export class UsersOrgsListComponent implements OnInit {
  @Input() // data
    get data():     Object[]  { return this._data; }
    set data(value: Object[]) { this._data = value; this.setupData(); }
    _data:          Object[];
  @Input() // noresults_message (options) - Message to display instead of the default
    get noresults_message():      string   { return this._options['noresults_message']; }
    set noresults_message( value: string ) { this._options['noresults_message'] = value; }
  @Input() reset_buffer: boolean = true;
  @Input() route_path:   string;
  @Input() search_end_reached: boolean = true;
  @Input() show_conn_btns_for: number = undefined; // Show the User Connection buttons for this ID
  @Input() // show_cb_add (options) - Show the Crunchbase 'Add' button
    get show_cb_add():      boolean   { return this._options['show_cb_add'] }
    set show_cb_add( value: boolean ) { this._options['show_cb_add'] = value; }
  @Input() // show_noresults (options) - Show the 'No Results' display
    get show_noresults():      boolean   { return this._options['show_noresults']; }
    set show_noresults( value: boolean ) { this._options['show_noresults'] = value; }
  @Input() type: string;

  @Output('add_from_cb') add_from_cb  = new EventEmitter();
  @Output('refresh')     refresh_data = new EventEmitter();
  @Output('update_list') update_list  = new EventEmitter();

  @ViewChild(VirtualScrollComponent, { static: true }) private virtualScroll: VirtualScrollComponent;

  _options:           Object = {
    show_cb_add: false,
    show_noresults: true,
    noresults_message: 'No results.'
  };
  default_color:      string = Color_Library.primary;
  display_buffer:     OrgUserCard[] = [];
  is_solco:           boolean = false;
  loading:            boolean = true;
  scroll_items:       any;
  show_cancel_btn:    boolean = false;
  show_connected_btn: boolean = false;
  show_pending_btn:   boolean = false;
  updating:           boolean = true;

  constructor( private aviaService: AVIAConnectService ) {  }

  ngOnInit():void {
    this.setupData();
  }

  onListChange($event: ChangeEvent) {
    if( ($event.end !== this.display_buffer.length) || this.updating == true ) return;
    this.loading = !this.search_end_reached;
    this.updating = true;
    this.update_list.emit({ offset: $event.end });
  }

  setupData():void {
    if( this._data === null || this._data === undefined ) return;
    if( this.reset_buffer ) this.display_buffer = [];
    if( this.type === 'solco' || this.type === 'crunchbase') this.is_solco = true;
    for(let item of this._data) {
      let new_item = new OrgUserCard();
      let item_keys = Object.keys(item);
      for( let key of item_keys ) {
        switch(key) {
          case 'avatar':
            new_item.avatar = item[key];
          break;

          case 'logo':
            if( item[key] === null ) {
              if( item['crunchbase_obj'] && item['crunchbase_obj']['logo'] ) {
                new_item.avatar = item['crunchbase_obj']['logo'];
              } else {
                new_item.avatar = '/assets/placeholder-company.jpg';
              }
            } else {
              new_item.avatar = item[key];
            }
          break;

          case 'email':
          case 'emails':
            let first_email = item[key][0];
            if( /email/.test(Object.getOwnPropertyNames(first_email).join(' ')) ) first_email = first_email['email'];
            new_item.email = first_email;
          break;

          case 'fullname':
          case 'name':
            new_item.name = item[key];
          break;

          case 'hq_addr':
            new_item.description = this.type === 'solco' ? 'Solution Company' : 'Healthcare System';
            if( item[key] === null ) continue;
            for( let sub_key of Object.keys(item[key]) ){
              switch( sub_key ) {
                case 'city':
                case 'state':
                  new_item[sub_key] = item[key][sub_key];
                break;
              }
            }
            new_item.description += ( new_item.city ? ' in ' + new_item.city + (new_item.state ? ', ' + new_item.state : '') : (new_item.state ? ' in ' + new_item.state : '') );
          break;

          case 'id':
            new_item.id = item[key];
          break;

          case 'status_obj':
            new_item.status_obj = item[key];
          break;

          case 'title':
          //case 'dept': // NOTE: We handle Department here, so we never need to make it its own case.
            let org_name:string = item['org_name'] ? item['org_name'] : item['org_obj']['name'] ? item['org_obj']['name'] : null;
            let company:string = org_name ? org_name : 'an Unknown Organization';
            let title = (item['title'] && item['title'].length > 0) ? item['title'] : 'Employee'
            let dept = (item['dept'] && item['dept'].length > 0) ? ', ' + item['dept'] : '';
            new_item.description = title + dept + ' at ' + company;
          break;

          case 'type':
            new_item.type = item[key];
          break;

          case 'connections':
            if( this.show_conn_btns_for && item['connections'][this.show_conn_btns_for] !== undefined ) {
              let connection = item['connections'][this.show_conn_btns_for];
              if( connection.outbound == E_ConnectionStatus.CONNECTED && connection.inbound == E_ConnectionStatus.CONNECTED ){
                this.show_connected_btn = true;
              }
              if( connection.outbound == E_ConnectionStatus.CONNECTED && connection.inbound == E_ConnectionStatus.PENDING ){
                this.show_pending_btn = true;
              }
              if( connection.outbound == E_ConnectionStatus.PENDING && connection.inbound == E_ConnectionStatus.CONNECTED ){
                this.show_cancel_btn = true;
              }
            }
          break;

          // Crunchbase Import Only
          case 'domain':
            if( this.type !== 'crunchbase' ) continue;
            new_item.description = item[key];
          break;

          case 'crunchbase_id':
            if( this.type !== 'crunchbase' ) continue;
            new_item[key] = item[key];
          break;

          case 'is_avia_vetted':
          case 'is_market_val':
          case 'is_high_impact':
            new_item[key] = item[key];
          break;

          case 'color':
            new_item[key] = item[key];
          break;
        }
      }

      //This if-else is for mixed lists of orgs which contain solution companies.
      if(new_item.type !== 2) {
        // If not a solco, set link to {{ route_path }}/{{ type }}/{{ id }}
        new_item.link = '/' + this.route_path + '/' + this.type + '/' + new_item.id;
      } else {
        // Else (implies a solco), set path link to /sc/:id and changes description to 'Solution Company'
        new_item.link = '/sc/' + new_item.id;
        new_item.description = '';
      }
      this.display_buffer.push( new_item );
    }

    if( this.reset_buffer ) {
      this.virtualScroll.items = this.display_buffer;
      this.virtualScroll.scrollInto( this.display_buffer[0] );
    }
    this.loading = false;
    this.updating = false;
  }

  ignoreUser( user_id: number ):void {
    this.aviaService.manageUserConnection( E_ConnectionManagerActions.BLOCK, user_id ).then(
      (res) => { this.refresh_data.emit(); },
      (rej) => { console.error('ignoreUser error') }
    );
  }
  connectWithUser( user_id: number ):void {
    this.aviaService.manageUserConnection( E_ConnectionManagerActions.CONNECT, user_id ).then(
      (res) => { this.refresh_data.emit(); },
      (rej) => { console.error('connectWithUser error') }
    );
  }
  disconnectFromUser( user_id: number ):void {
    this.aviaService.manageUserConnection( E_ConnectionManagerActions.DISCONNECT, user_id ).then(
      (res) => { this.refresh_data.emit(); },
      (rej) => { console.error('disconnectFromUser error') }
    );
  }

  addFromCrunchbase( $event:any, crunchbase_id:string ):void {
    this.add_from_cb.emit( {'crunchbase_id': crunchbase_id} );
    $event.stopPropagation();
  }
}

export class OrgUserCard {
  constructor(
    public avatar:         string  = '',
    public city:           string  = '',
    public color:          string  = '',
    public description:    string  = '',
    public email:          string  = '',
    public id:             number  = null,
    public is_avia_vetted: boolean = undefined,
    public is_high_impact: boolean = undefined,
    public is_market_val:  boolean = undefined,
    public link:           string  = '',
    public name:           string  = '',
    public state:          string  = '',
    public status_obj:     UserOrg_StatusObj = new UserOrg_StatusObj(),
    public type:           number  = null
  ) { }
}
