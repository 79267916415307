import { TourService } from './_services/tour.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// AVIA Connect Modules:
import { SharedModule } from './shared/shared.module';

// Angular imports
import { NgModule, ErrorHandler, SystemJsNgModuleLoader } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientJsonpModule } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideRoutes } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

// http interceptor for mock tests
import { MockHttpInterceptor } from '../http.interceptor';

// material
import { DragDropModule } from '@angular/cdk/drag-drop';

// node_modules imports
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// avia imports (please alphabetize)
import { AVIAConnectService } from './avia-connect.service';

import { LazyLoaderService } from './lazy-loader.service';
import { AviaContentViewerComponent } from './_components/avia-content-viewer/avia-content-viewer.component';
import { AviaWebsocketService } from './_components/avia-websocket.service';
import { AVIAErrorHandlerComponent } from './shell/aviaerror-handler/aviaerror-handler.component';
import { AboutPageComponent } from './shell/about-page/about-page.component';
import { AppComponent } from './app.component';
import { CbSearchComponent } from './cb-search/cb-search.component';
import { ContentLibraryService } from './content-library/content-library.service';
import { CrashScreenComponent } from './shell/crash-screen/crash-screen.component';
import { EULAComponent } from './shell/eula/eula.component';
import { EulaboxComponent } from './shell/eulabox/eulabox.component';
import { FeedbackComponent } from './shell/feedback/feedback.component';
import { FeedbackFormComponent } from './shell/feedback-form/feedback-form.component';
import { FrontRoutingModule } from './app-routing.module';
import { IncompatibleBrowserNagComponent } from './shell/incompatible-browser-nag/incompatible-browser-nag.component';
import { LoadTestingPage2Component } from './shell/load-testing-page2/load-testing-page2.component';
import { LoadTestingPageComponent } from './shell/load-testing-page/load-testing-page.component';
import { MessagesService }   from './messages/messages.service';
import { MobileUserMenuComponent } from './shell/mobile-user-menu/mobile-user-menu.component';
import { NewsfeedComponent } from './newsfeed/newsfeed.component';
import { NewsfeedItemComponent } from './newsfeed/newsfeed-item/newsfeed-item.component';
import { NfContentComponent } from './newsfeed/newsfeed-item/nf-content/nf-content.component';
import { NfInfoComponent } from './newsfeed/newsfeed-item/nf-info/nf-info.component';
import { NfPeopleComponent } from './newsfeed/newsfeed-item/nf-people/nf-people.component';
import { OnboardingService } from './shell/onboarding/onboarding.service';
import { OnboardingComponent } from './shell/onboarding/onboarding.component';
import { ServerDownComponent } from './shell/server-down/server-down.component';
import { SignOutComponent } from './shell/sign-out/sign-out.component';
import { SigninBoxComponent } from './signin-box/signin-box.component';
import { SignupModule } from './signup/signup.module';
import { SsoVerifyComponent } from './signin-box/sso/sso-verify/sso-verify.component';
import { SsoLinkComponent } from './signin-box/sso/sso-link/sso-link.component';
import { SuggestedPageComponent } from './_components/avia-suggested/suggested-page/suggested-page.component';
import { TestFirewallComponent } from './shell/test-firewall/test-firewall.component';
import { UserMenuComponent } from './shell/user-menu/user-menu.component';
import { UserSettingsComponent } from './shell/user-settings/user-settings.component';
import { GestureConfig } from "../gesture-config";
import { GlobalNavbarComponent } from './shell/global-navbar/global-navbar.component';
import { MobileDrawerComponent } from './shell/global-navbar/components/mobile/mobile-drawer/mobile-drawer.component';
import { NavbarDiscoverDropdownComponent } from './shell/global-navbar/components/navbar-discover-dropdown/navbar-discover-dropdown.component';
import { MobileNavbarDiscoverComponent } from './shell/global-navbar/components/mobile/mobile-navbar-discover/mobile-navbar-discover.component';
import { NavCardComponent } from './shell/global-navbar/components/nav-card/nav-card.component';
import { NavbarMyConnectDropdownComponent } from './shell/global-navbar/components/navbar-my-connect-dropdown/navbar-my-connect-dropdown.component';
import { NavbarMyOrgDropdownComponent } from './shell/global-navbar/components/navbar-my-org-dropdown/navbar-my-org-dropdown.component';
import { MobileNavbarMyconnectComponent } from './shell/global-navbar/components/mobile/mobile-navbar-myconnect/mobile-navbar-myconnect.component';
import { MobileNavbarMyorgComponent } from './shell/global-navbar/components/mobile/mobile-navbar-myorg/mobile-navbar-myorg.component';
import { NotificationsPageComponent } from './notifications/notifications-page/notifications-page.component';
import { CommonModule } from '@angular/common';
import { PinnedLocationsPageComponent } from './shell/pinned-locations-page/pinned-locations-page.component';
import { TempUniversalAddComponent } from './shell/temp-universal-add/temp-universal-add.component';
import { DummyComponent } from './shell/dummy/dummy.component';
import { LnSigninComponent } from './signin-box/ln-signin/ln-signin.component';


@NgModule({
  declarations: [
    AboutPageComponent,
    AppComponent,
    AviaContentViewerComponent,
    CbSearchComponent,
    CrashScreenComponent,
    EULAComponent,
    EulaboxComponent,
    FeedbackComponent,
    FeedbackFormComponent,
    GlobalNavbarComponent,
    IncompatibleBrowserNagComponent,
    LoadTestingPage2Component,
    LoadTestingPageComponent,
    MobileUserMenuComponent,
    NewsfeedComponent,
    NewsfeedItemComponent,
    NfContentComponent,
    NfInfoComponent,
    NfPeopleComponent,
    OnboardingComponent,
    ServerDownComponent,
    SignOutComponent,
    SigninBoxComponent,
    SsoLinkComponent,
    SsoVerifyComponent,
    SuggestedPageComponent,
    TestFirewallComponent,
    UserMenuComponent,
    UserSettingsComponent,
    MobileDrawerComponent,
    NavbarDiscoverDropdownComponent,
    MobileNavbarDiscoverComponent,
    NavCardComponent,
    NavbarMyConnectDropdownComponent,
    NavbarMyOrgDropdownComponent,
    MobileNavbarMyconnectComponent,
    MobileNavbarMyorgComponent,
    NotificationsPageComponent,
    PinnedLocationsPageComponent,
    TempUniversalAddComponent,
    DummyComponent,
    LnSigninComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ClipboardModule,
    DragDropModule,
    HttpClientJsonpModule,
    DeviceDetectorModule,
    SharedModule,
    SignupModule,
    FrontRoutingModule,
    NgbModule,
  ],
  providers: [
    AVIAConnectService,
    TourService,
    AviaWebsocketService,
    ContentLibraryService,
    LazyLoaderService,
    MessagesService,
    OnboardingService,
    SystemJsNgModuleLoader,
    DeviceDetectorService,
    // see http.interceptor.ts and .json
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MockHttpInterceptor,
    //   multi: true
    // },
    provideRoutes([
      // Non-router based lazy-load modules (those that we lazy-load using the LazyLoaderService)
      // All lazy-load modules need to be listed so production build can generate separate X.xxx.chunk.js files
      // You could alternatively designate these in the lazyloaded NgModule's provideRoutes section
      //   themselves so that they "self describe" that they should be compiled
      //   to a separate chunk.js file.
      // It's unfortunate to need to specify the paths (and NgModule name as a
      //   string) here, however... Rather than just refer to the type.
      // TODO: someone help figure this out!
      { loadChildren: 'src/app/modules/pdfwriter.module#PdfWriterModule', path: '-------ignore-not-a-valid-path-00001-------',  },
      { loadChildren: 'src/app/modules/webgl.module#WebglModule',         path: '-------ignore-not-a-valid-path-00002-------',  },
      // { loadChildren: () => import('src/app/modules/pdfwriter.module').then(m => m.PdfWriterModule), path: '-------ignore-not-a-valid-path-00001-------',  },
      // { loadChildren: () => import('src/app/modules/webgl.module').then(m => m.WebglModule),         path: '-------ignore-not-a-valid-path-00002-------',  },
    ]),
    { provide: ErrorHandler, useClass: AVIAErrorHandlerComponent },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig } // to disable the global crash handler comment this out.  DONT FORGET to uncomment before checking in!
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
    // so that index.html can detect we started up (if it doesn't detect, then it'll reload)
    window['AVIACONNECTRUNNING'] = true;
  }
}
