import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
import * as  dataURLtoBlob from 'blueimp-canvas-to-blob';

import { Common } from '../../common';
import { AVIAConnectService } from '../../avia-connect.service';


// AVIA ImageCrop
// @example start
// <avia-image-crop
//  *ngIf='true'
//  [options]='{use_upload:true}'
//  [initdata]="undefined"
//  (onUploadDone)="onUploadDone($event)"
//  (onSourceImageChange)="onSourceImageChange($event)"
//  (onUploadProgress)='onUploadProgress($event)'></avia-image-crop>
// @example end
@Component({
  selector: 'avia-image-crop',
  templateUrl: './avia-image-crop.component.html',
  styleUrls: ['./avia-image-crop.component.scss']
})
export class AviaImageCropComponent {
  readonly DEBUG:            boolean = false;
  readonly _default_options: any     = {
    use_upload: true,  // show a submit button, uploads to s3 and calls onUploadDone when done
    use_done: false,   // show a submit button, calls onCropDone when done
    use_preview: false // show the preview image
  };
  readonly VERBOSE:          boolean = false;

  @Output('onSourceImageChange') onSourceImageChange:any = new EventEmitter(); // called with {canvas, img, fileblob}, when the source image changes
  @Output('onCropChange') onCropChange:any = new EventEmitter(); // called with {canvas, img, fileblob, position}, when the crop changes
  @Output('onCropDone') onCropDone:any = new EventEmitter(); // called with {canvas, img, fileblob}, when the crop is finished
  @Output('onUploadBegin') onUploadBegin:any = new EventEmitter(); // called before upload begins
  @Output('onUploadProgress') onUploadProgress:any = new EventEmitter(); // called with {size:s, progress:p, error:e} where progress is 0-size and size is in bytes, while the crop is uploading
  @Output('onUploadDone') onUploadDone:any = new EventEmitter(); // called with the link URL to the uploaded cropped image, when the crop has been uploaded
  @Output('onSave') onSave:any = new EventEmitter(); // called with the link URL to the uploaded cropped image, when the crop has been uploaded
  @Output('onInit') onInit:any = new EventEmitter(); // called at init with self as $event

  @Input() // options:any = {};
    get options():any { return this._options };
    set options(value: any) {
      if (value === undefined) return;
      this._options = value;
      this.initDynamicData();
    }
    _options: any = {};
  @Input() initdata:any = undefined; // if set, this component will initialize to the given image data (init this using the data object given to you by onUploadDone, which is same format as returned by getData)
  cropper:ImageCropperComponent;
  @ViewChild('cropper', { static: false }) set content(c: ImageCropperComponent) {
    this.cropper = c;

    // reset the cropper to the old initdata
    if (c !== undefined && this.initdata !== undefined) {
      //let image:any = new Image();
      c.setImage( this.initdata );
      this.onSourceImageChange.emit( this.getData() );
    }
  }
  @ViewChild('croppedImage', { static: false }) croppedImage:any;

  cropperSettings: any     = new CropperSettings();
  data:            any;
  error:           string;
  file:            any;
  link:            string  = '';
  max_height:      number  = 300;
  max_width:       number  = 300;
  progress:        number  = 0.0;
  size:            number  = 0.0;
  submitted:       boolean = false;
  uploading:       boolean = false;
  hide_cropper:    boolean = true;


  constructor( private aviaService: AVIAConnectService ) {  }

  initDynamicData(): void {
    // copy in default options
    for (let key of Object.keys(this._default_options)) {
      if (this._options[key] === undefined) this._options[key] = this._default_options[key];
    }

    // cropper settings
    if ( this._options.width ) {
      this.cropperSettings.width = this._options.width;
      this.max_width = this._options.width;
    }
    if ( this._options.height ) {
      this.cropperSettings.height = this._options.height;
      this.max_height = this._options.height;
    }
    this.cropperSettings.canvasWidth = (this._options.canvasWidth) ? this._options.canvasWidth : (this._options.width) ? this._options.width : 300;
    this.cropperSettings.canvasHeight = (this._options.canvasHeight) ? this._options.canvasHeight : (this._options.height) ? this._options.height : 300;
    this.cropperSettings._keepAspect = (this._options.keepAspect !== undefined) ? this._options.keepAspect : true;

    // resulting output image size / quality (500x500px with 80% quality == ~45k jpeg)
    this.cropperSettings.croppedWidth = (this._options.croppedWidth) ? this._options.croppedWidth : (this._options.width) ? this._options.width : 500;
    this.cropperSettings.croppedHeight = (this._options.croppedHeight) ? this._options.croppedHeight : (this._options.height) ? this._options.height : 500;
  }

  ngOnInit() {
    this.initDynamicData();

    // cropper settings
    this.cropperSettings.noFileInput = true;
    //this.cropperSettings.cropperClass = 'my-cropper';
    //this.cropperSettings.dynamicSizing = true; // NOTE: This doesn't seem to work with how we are implementing this component

    // resulting output image size / quality (500x500px with 80% quality == ~45k jpeg)
    this.cropperSettings.compressRatio = 0.8;
    this.cropperSettings.preserveSize = false; // false: use croppedWidth, croppedHeight
    this.cropperSettings.minWithRelativeToResolution = true;

    // touch settings
    this.cropperSettings.markerSizeMultiplier = 2.5;
    this.cropperSettings.touchRadius = 35;
    this.cropperSettings.centerTouchRadius = 35;
    this.cropperSettings.showCenterMarker = true;

    this.data = {};
    if (this.onInit) this.onInit.emit( this );
  }
  ngAfterViewInit() {
    this.cropper.settings = this.cropperSettings;
  }
  ngOnDestroy() {
    if (!this.submitted) this.undoUpload();
  }

  cropPositionChange( e ) {
    let d = this.getData();
    d['position'] = e;
    this.onCropChange.emit( d );
  }

  // returns {canvas, img, fileblob}
  getData() {
    let srcimg = this.cropper.image;
    let srcimg_src = this.cropper.image.image !== undefined ? this.cropper.image.image : this.cropper.image.src;
    let srcblob = (srcimg !== undefined && srcimg_src !== undefined && 0 < srcimg_src.length) ? dataURLtoBlob( srcimg_src ) : undefined;
    let img = this.cropper.cropper.getCroppedImage(false);
    let blob = 0 < img.src.length ? dataURLtoBlob( img.src ) : srcblob;
    let canvas = this.cropper.cropcanvas;
    return {canvas, img, blob, srcimg, srcimg_src, srcblob};
  }
  setData(d) {
    this.cropper.cropper.setImage( d.srcimg );
  }

  // file upload event generated by <input type='file'>
  // e == {target}
  uploadImage( e ) {
    let imgReader:FileReader = new FileReader();
    let image:HTMLImageElement = new Image();

    imgReader.onloadend = (loadEvent:any) => {

      image.src = loadEvent.target.result;
      this.initdata = image;
      this.cropper.setImage(image); // doesn't seem to set right away...
      this.onSourceImageChange.emit( this.getData() );
    };

    if (e.target.files[0]) {
      const file:File = e.target.files[0];
      imgReader.readAsDataURL(file);
    }
  }

  dropFile( e ) {
    let new_event = { target: { files: [e.file] }};
    this.uploadImage( new_event );
  }

  // enable with options.use_done
  onDone() {
    this.onCropDone.emit( this.getData() );
  }

  submit() {
    this.onSave.emit( {link: this.link} )
    this.submitted = true;
  }

  back() {
    this.undoUpload();
  }

  // button press event generated by <input type='button'>
  // enable with options.use_upload
  async uploadCroppedImage() {
    if (this.cropper.image.src === "") {
      this.VERBOSE && console.log( "no image set yet" );
      return;
    }

    this.onUploadBegin.emit( {} );
    this.uploading = true;
    let data = this.getData();
    let s3filename = Common.StrongerUUID();
    let r:any = await this.aviaService.uploadToS3(
      s3filename,
      "myavatar.png",
      data.blob,
      data.blob.type,
      undefined,
      (s, p, err) => { this.size = s; this.progress = (p / s) * 100.0; this.error = err; this.onUploadProgress.emit( {size:s, progress:p, error:err} ); }
    );
    //this.link = r.url;
    if (r.message == "success") {
      this.link = r.url;
      data['link'] = r.url;
      data['hash'] = r.hash;
      this.uploading = false;
      this.onUploadDone.emit( data );
      this.VERBOSE && console.log( "added " + this.link + " to s3")
    } else {
      this.error = "upload failed: " + r.info;
      this.onUploadProgress.emit( {error:this.error} );
      this.uploading = false;
    }
  }
  undoUpload() {
    if (this.link.length > 0) {
      this.aviaService.undoUploadToS3(Common.justFilename( this.link )).then(r => { });
      this.VERBOSE && console.log( "undoUpload: removed " + this.link + " from s3")
      this.link = '';
    } else {
      this.VERBOSE && console.log( "undoUpload: nothing to undo, no image ever set :-)" );
    }
  }

}
