import { fadeOut, fadeIn } from 'ng-animate';
import { trigger, transition, useAnimation } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { AviaActionTilesService } from './../../avia-action-tiles.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-at-common',
  templateUrl: './at-common.component.html',
  styleUrls: ['./at-common.component.scss', '../../avia-action-tiles.component.scss'],
  animations: [
    trigger('fade', [
      transition('* => void',
        useAnimation(fadeOut, { params: { timing: 0.2 }})),
      transition('void => *',
        useAnimation(fadeIn, { params: { timing: 0.5 }}))
      ]
    ),
  ]
})
export class AtCommonComponent implements OnInit {

  constructor(public aats: AviaActionTilesService, public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
