import { GlobalNavbarService } from '../../../global-navbar.service';
import { AVIAConnectService } from '../../../../../avia-connect.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-drawer',
  templateUrl: './mobile-drawer.component.html',
  styleUrls: ['./mobile-drawer.component.scss', '../../../global-navbar.component.scss']
})
export class MobileDrawerComponent implements OnInit {

  constructor(public aviaService: AVIAConnectService, public gns: GlobalNavbarService) {
    this.gns.drawer_change_subject.subscribe(value => {
      if(value && this.aviaService.mobile_mode) {
        document.body.classList.add('no-scroll');   // no-scroll in styles.scss
      } else {
        document.body.classList.remove('no-scroll');
      }
    })
  }

  ngOnInit() {

  }

  // I had an unsubscribe for change subject but that causes an Oh Dear because the subscription unsubs but the Behavior Subject persists in the service and we want the service to persist.
}
