import { Component, OnInit } from '@angular/core';
import { OnboardingService } from '../../onboarding.service';

@Component({
  selector: 'app-onboarding-complete',
  templateUrl: './onboarding-complete.component.html',
  styleUrls: ['./onboarding-complete.component.scss']
})
export class OnboardingCompleteComponent implements OnInit {

  constructor(public sob: OnboardingService) {}

  ngOnInit() {
    this.sob.form_valid = true;
  }
}
