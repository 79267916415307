<ng-template [ngIf]="static_page === false" [ngIfElse]="UserInvite"><span class="d-none"></span></ng-template>

<ng-template #UserInvite class="user-invite-modal d-block w-100">
	<div [ngClass]="{
			'avia-modal-full-screen avia-modal-full-screen-inverse': (fullscreen && static_page === false),
			'avia-modal': (!fullscreen && static_page === false)
		}"
		>
		<div class="page container-fluid bg-white">

			<div class="d-flex align-items-center headerblock row bg-gray-100" *ngIf="!static_page">
				<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
				<span class="ml-auto backlink pointer" (click)="close()">
					<i class="mr-4 material-icons" style="font-size: 24px; vertical-align: top;">close</i>
				</span>
			</div>

			<div class="titleblock row my-4 text-center" *ngIf="!static_page">	
				<div class="col-12">	
					<p class="text-uppercase" *ngIf="title_text !== undefined">{{title_text}}</p>	
					<h1 class="section-header-font">{{_org ? _org.name : 'AVIA Connect' }}</h1>	
				</div>	
			</div>	

			<div class="editblock row mx-0" [ngClass]="{'justify-content-sm-center':!static_page}">
				<!-- <div class="col-12 col-lg-10 pl-2 pr-2 px-sm-0 px-sm-0"> -->
				<div class="col-12 col-lg-10 pl-2 pr-4 px-sm-0 px-1 px-sm-0">
					<!-- === HEADER === -->
					<!-- DESKTOP VERSION -->
					<!-- <div class="d-none d-md-block hero-image">
						<div class="hero-text">
							<div>Enhance your Connect experience</div>
							<div>by expanding your network</div>
						</div>
						<div class="s-hero-text">Invite your peers to join Connect today</div>
					</div> -->
					<!-- MOBILE VERSION -->
					<!-- <div class="d-block d-md-none m-hero-image">
						<div class="m-hero-text">
							<div>Enhance your Connect experience</div>
							<div>by expanding your network</div>
						</div>
						<div class="m-s-hero-text">Invite your peers to join Connect today</div>
					</div> -->
					<!-- === MAIN FORM === -->
					<form *ngIf="!show_bulk_add" [formGroup]="user_form" (ngSubmit)="onSubmit()">
						<app-avia-user-invite-modal-row
							#InviteRow
							*ngFor="let form_row of form_rows; let i=index;"
							[form_group]="form_row"
							[email_invalid]="form_row.controls['email'].invalid && ( form_row.controls['email'].dirty )"
							[firstname_invalid]="form_row.controls['firstname'].invalid && !form_row.controls['firstname'].pristine"
							[lastname_invalid]="form_row.controls['lastname'].invalid && !form_row.controls['lastname'].pristine"
							[invitees_org]="invitees_orgs[i]?.org"
							[show_org_chooser]="_org?.type === 3"
							[show_type_chooser]="i_am_owner && invitees_orgs[i]?.org?.type != 3"
							[triggerSubmit]="submit_rows"
							[types]="types"
							(onRemove)="removeRow($event)"
							(onSubmitComplete)="submitComplete($event)"
							(onUpdateOrg)="updateOrg($event)"
						></app-avia-user-invite-modal-row>

						<!-- BULK & ADD ROW BUTTONS -->
						<div class="form-group d-flex justify-content-center justify-content-md-end px-2">
							<!--
							<button
								*ngIf="form_rows.length > 1"
								class="btn btn-small btn-gray-100 text-danger mr-4"
								type="button"
								(click)="removeLastRow()"
							>remove last</button>
							-->
							<button
								*ngIf="i_am_owner"
								class="btn btn-small btn-gray-100 mr-4"
								type="button"
								(click)="showBulkAdd()"
							>bulk add</button>
							<button
								class="btn btn-small btn-gray-100"
								type="button"
								(click)="addNewRow()"
							>add another</button>
						</div>

						<!-- MAIN BUTTONS -->
						<div class="form-group d-flex justify-content-center mt-7">
							<button class="btn btn-white border mr-2"
								type="button"
								(click)="close();"
							>Cancel</button>
							<button class="btn btn-primary ml-2"
								type="submit"
								[disabled]="(user_form.status !== 'VALID' || user_form.pristine) ? 'true' : undefined"
							>Send Invitation</button>
						</div>
					</form>

					<!-- === BULK ADD === -->
					<form *ngIf="show_bulk_add" class="px-2 w-100" [formGroup]="bulkadd_form" (ngSubmit)="processBulkAdd()">
						<div class="form-group w-100">
							<label for="bulkInput">Bulk Invite</label>
							<textarea
								#bulkInvite
								class="form-control"
								formControlName="bulk_input"
								id="bulkInput"
								rows="14"
							></textarea>
						</div>
						<p>Note: takes a list of email addresses separated by commas or carriage returns.</p>

						<div class="form-group d-flex justify-content-center">
							<div class="mr-1 mr-md-2">
								<button class="btn btn-gray-100 d-block mx-auto" (click)="hideBulkAdd()">Cancel Bulk Add</button>
							</div>
							<div class="ml-1 ml-md-2">
								<button
									class="btn btn-primary d-block mx-auto"
									type="submit"
									[disabled]="(bulkadd_form.status !== 'VALID') ? 'true' : undefined"
								>Add Bulk Invites</button>
							</div>
						</div>
					</form>

					<!-- === DEBUGGING DISPLAY === -->
					<span *ngIf="false">
						<!-- Display the form values and state during development -->
						<p>Form value: {{ user_form.value | json }}</p>
						<p>Form status: {{ user_form.status | json }}</p>
						<p>Pristine: <span class="text-uppercase">{{ user_form.pristine }}</span></p>
						<p>User's Org Data: id:{{_org?.id}}, type:{{_org?.type}}</p>
						<p>Invitee's Org Data:<br>
							<ng-container
								*ngFor="let org_row of invitees_orgs; let j=index;"
							>{{j}}: Row ID: {{org_row.row_id}} - Org ID: {{org_row.org.id}} - Org Name: {{org_row.org.name}}<br></ng-container>
						</p>
					</span>
				</div>
			</div>

		</div>
	</div>
</ng-template>


<!-- === ACTIVATION BUTTONS === -->
<div *ngIf="static_page === false" (click)="open()">
	<span #SuppliedElement>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedElement.children.length === 0"
		class="d-flex default launcher material-icons text-primary"
		data-toggle="tooltip"
		placement="left"
		ngbTooltip="Invite User"
	>add_circle</i>
</div>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
