import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs'

import { MessagesService } from '../../messages.service';
import { AVIAConnectService } from '../../../avia-connect.service';
import { E_SomethingHappened, AnalyticEvent } from '../../../../class';
import { T_SomethingHappened } from '../../../../interface';


import {
  E_ConnectionManagerActions,
} from '../../../../class';

@Component({
  selector: 'app-send-message-button',
  templateUrl: './send-message-button.component.html',
  styleUrls: ['./send-message-button.component.scss'],
})
export class AviaSendMessageButtonComponent implements OnInit {
  @Input() user;
  @Input() smol: boolean = false;

  inboundstate: number = 0;
  outboundstate: number = 0;

  connect: boolean = false;
  send: boolean = false;
  sent: boolean = false;

  session: any;
  notification_sub: Subscription;

  constructor( public aviaService: AVIAConnectService, public sm: MessagesService ) {
    this.notification_sub = aviaService.somethingHappened.subscribe((e:T_SomethingHappened) => {
      if ( e.type === E_SomethingHappened.USER_CONNECTION && this.user && this.user.id == e.data.id) {
        this.setup();
      }
    });
  }

  async setup() {
    if(this.user){
      this.session = await this.aviaService.getSessionSupport();
      for(let c of this.session.user.connections_outbound) {
        if(c.user_id === this.user.id) {
          this.outboundstate = c.state_obj.id;
        }
      }
      for(let c of this.session.user.connections_inbound) {
        if(c.user_id === this.user.id) {
          this.inboundstate = c.state_obj.id;
        }
      }
      if(this.user.id === this.session.user.id) {
        this.connect = false;
        this.send = false;
      }
      else if(this.inboundstate === 2 && this.outboundstate === 2) {
        this.send = true;
        this.connect = false;
      }
      else if(this.inboundstate !== 2 && this.outboundstate === 2) {
        this.sent = true;
        this.connect = false;
        this.send = false;
      }
      else if(this.inboundstate == 2) {
        this.sent = false;
        this.connect = true;
        this.send = false;
      }
      else if(this.session.user.org_obj.type === 2 || this.session.user.org_obj.type === 5) {
        if (this.user.org_type === 2 || this.user.org_type === 5) {
          this.connect = true;
          this.send = false;
        }
        else {
          this.connect = false;
          this.send = false;
        }
      }
      else {
        this.connect = true;
        this.send = false;
      }
    }
  }

  async ngOnInit() {
    this.setup();
  }

  async sendMessage() {
    let d = await this.sm.createDiscussion([this.user.id], null);
    this.sm.selectDiscussion(d);
  }

  async connectUser($event) {
    let obj;
    let result = await this.aviaService.manageUserConnection( E_ConnectionManagerActions.CONNECT, this.user.id, {message: $event} );
    if(result.response == "reinvited" || result.response == "invited") {
      this.connect = false;
      this.send = false;
      this.sent = true;
      // connection request
      if ($event.length > 0) {
        obj = new AnalyticEvent(
          'user_connection_request_message',
          {user_id: this.user.id}
        );
      } else {
        obj = new AnalyticEvent(
          'user_connection_request_no_message',
          {user_id: this.user.id}
        );
      }
      this.aviaService.createAnalyticEvent(obj);
      this.session = await this.aviaService.getSessionSupport(true);
    }
    else if(result.response == "accepted") {
      // connection accept
      obj = new AnalyticEvent(
        'user_connection_request_accepted',
        {user_id: this.user.id}
      );
      this.send = true;
      this.connect = false;
      this.aviaService.createAnalyticEvent(obj);
      this.session = await this.aviaService.getSessionSupport(true);
    }
  }

}
