<div class="discover-body">

			<div class="p-2">
				<app-nav-card
					[color]="gns.blue_dark"
					[name]="'Topics'"
					[background_type]="1"
					[routerLink]="['/intelligence/km/explore']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.violet_dark"
					[name]="'Companies'"
					[background_type]="2"
					[routerLink]="['/sc/explore']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.teal_dark"
					[name]="'Provider Orgs'"
					[background_type]="3"
					[routerLink]="['/profiles/explore']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.blue_mid"
					[name]="'Communities'"
					[background_type]="3"
					[routerLink]="['/communities']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.green_dark"
					[name]="'Groups'"
					[background_type]="2"
					[routerLink]="['/ws/grid']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.orange_gold"
					[name]="'Library'"
					[background_type]="2"
					[routerLink]="['/intelligence/cl']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

	<!-- <div class="p-4 bg-gray-100">
		<div class="heading-size-16 pb-4">Learn about healthcare topics</div>
		<button class="border-0 btn btn-gray-200" [routerLink]="['/intelligence/km/explore']" (click)="gns.closeMobileDrawer()">
			<span class="text-blue-dark">See&nbsp;all</span>
		</button>
	</div> -->

	<!-- <div class="bg-white container py-4">
		<div class="row">
			<div class="p-2">
				<app-nav-card
					[color]="gns.blue_dark"
					[name]="'Capabilities'"
					[background_type]="1"
					[routerLink]="['/intelligence/km/start']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.teal_dark"
					[name]="'Technology'"
					[background_type]="2"
					[routerLink]="['/intelligence/km/start']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>

			<div class="p-2">
				<app-nav-card
					[color]="gns.violet_dark"
					[name]="'Trends'"
					[background_type]="3"
					[routerLink]="['/intelligence/km/start']"
					(click)="gns.closeMobileDrawer()">
				</app-nav-card>
			</div>
		</div>
	</div> -->
<!--
	<div class="d-flex align-items-center p-4 bg-gray-100 justify-content-between">
		<div class="heading-size-16">AVIA Community</div>
		<div class="border-0 btn btn-gray-200" [routerLink]="['/communities/page/1']" (click)="gns.closeMobileDrawer()">
			<span class="text-blue-dark">See&nbsp;all</span>
		</div>
	</div>

	<div class="bg-white container pt-2 pb-4">
		<div class="border-0 pill bg-blue-extra-super-light rounded mr-2 mt-2 py-1 px-2 text-gray-800 d-inline-block" [routerLink]="['/communities/page/135']">COVID-19 Resource Hub</div>
		<div class="border-0 pill bg-blue-extra-super-light rounded mr-2 mt-2 py-1 px-2 text-gray-800 d-inline-block" [routerLink]="['/communities/page/2']">Medicaid Transformation Project</div>
		<div class="border-0 pill bg-blue-extra-super-light rounded mr-2 mt-2 py-1 px-2 text-gray-800 d-inline-block" [routerLink]="['/communities/page/3']">Digital Front Door</div>
		<div class="border-0 pill bg-blue-extra-super-light rounded mr-2 mt-2 py-1 px-2 text-gray-800 d-inline-block" [routerLink]="['/communities/page/5']">Consulting Services</div>
		<div class="border-0 pill bg-blue-extra-super-light rounded mr-2 mt-2 py-1 px-2 text-gray-800 d-inline-block" [routerLink]="['/communities/page/68']">Our Offering</div>
	</div> -->

</div>
