<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-body">
		<div class="d-flex align-items-center">
			<span class="material-icons md-24 circle bg-gray-300 text-white mr-3 p-3">link</span>
			<div class="heading-size-24 w-100">
				Get Link
			</div>
			<button
				class="btn btn-light heading-size-16 text-pulse-blue"
				(click)="copyLink()"
			>
				{{ link_copied ? 'Link&nbsp;Copied' : 'Copy&nbsp;Link' }}
			</button>
		</div>

		<div *ngIf="_post.type == 7" class="d-flex align-items-center mt-4">
			<span class="material-icons-outlined md-24 circle bg-gray-300 text-white mr-3 p-3">email</span>
			<div class="heading-size-24 w-100">
				Send Email
			</div>
			<a [href]="mail_to" target="_blank" (click)="logShares()">
				<button
					class="btn btn-light heading-size-16 text-pulse-blue"
				>
				Open&nbsp;Client
			</button>
		</a>
		</div>
	</div>
</ng-template>

<div (click)="open()">
	<ng-content></ng-content>
</div>

