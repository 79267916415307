<form class="form-group-row mb-4 mb-md-6" [formGroup]="user_form" (ngSubmit)="onSubmit()">
		<!-- EMAIL -->
		<div class="form-group px-md-2 w-100">
			<label for="email" class="text-uppercase">Email</label>
			<span class="float-right"><small><i>required</i></small></span>
			<input
				class="form-control"
				formControlName="email"
				id="email"
				name="email"
				placeholder="{{'name@example.com'}}"
				type="email"
				[ngClass]="{'is-invalid': user_form.controls['email'].invalid && ( user_form.controls['email'].dirty )}"
			>
			<small *ngIf="user_form.controls['email'].invalid && ( user_form.controls['email'].dirty )" class="form-text text-danger">
				You must submit a valid email
			</small>
		</div>

		<!-- FIRST NAME -->
		<div class="form-group px-md-2 w-100">
			<label for="firstname" class="text-uppercase">First Name</label>
			<span class="float-right"><small><i>optional</i></small></span>
			<input
				class="form-control"
				formControlName="firstname"
				id="firstname"
				placeholder="first name"
				type="text"
			>
		</div>

		<!-- LAST NAME -->
		<div class="form-group px-md-2 w-100">
			<label for="lastname" class="text-uppercase">Last Name</label>
			<span class="float-right"><small><i>optional</i></small></span>
			<input
				class="form-control"
				formControlName="lastname"
				id="lastname"
				placeholder="last name"
				type="text">
		</div>

		<!-- === BUTTONS === -->
		<div class="form-group d-flex justify-content-center mt-7">
			<button class="btn btn-white border mr-2"
				type="button"
				(click)="close()">
				Cancel
			</button>
			<button
				class="btn btn-primary ml-2"
				[disabled]="user_form.status !== 'VALID'"
				type="submit">
				Add To Team
			</button>
		</div>
	<!-- STATUS MESSAGES -->
	<div *ngIf="failure_content !== undefined" class="mb-4 px-md-2 small text-danger"><b>Failure!</b> - {{failure_content}}</div>
</form>
