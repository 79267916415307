<form [formGroup]="name_form" #formElem="ngForm" (ngSubmit)="onSubmit()">
	<div class="row justify-content-center py-4">
		<div class="col-10 col-md-8 col-lg-6">
			<h2 class="heading-size-36 text-center mt-4">Confirm your name</h2>
			<div class="text-center font-weight-light text-gray-800 font-body font-size-16">Confirm we have your name correct or update it if you go by something else.</div>
		</div>
	</div>
	<div class="flex-row justify-content-center">
		<div class="form-group mx-auto col-12 col-md-8 col-lg-6">
			<div class="font-weight-bold text-left pb-2">First name
				<span class="float-right">
					<small><i
						[ngClass]="{'text-danger': name_form.touched && name_form.controls['firstname'].touched && name_form.controls['firstname'].hasError('required'),
						'text-gray-600': (name_form.untouched && name_form.controls['firstname'].untouched) || !name_form.controls['firstname'].hasError('required')}"
						class="font-italic">required
					</i></small>
				</span>
			</div>
			<input #fname
				type="text"
				class="w-100 form-control"
				formControlName="firstname"
				[ngClass]="{'is-invalid': name_form.touched && name_form.controls['firstname'].touched && name_form.controls['firstname'].invalid}"/>
			<span *ngIf="name_form.touched && name_form.controls['firstname'].touched && name_form.controls['firstname'].hasError('pattern')">
				<small><i class="font-italic text-danger">Requires at least 2 characters</i></small>
			</span>
		</div>
		<div class="form-group mx-auto col-12 col-md-8 col-lg-6">
			<div class="font-weight-bold text-left">Last name
				<span class="float-right">
					<small><i
						[ngClass]="{'text-danger': name_form.touched && name_form.controls['lastname'].touched && name_form.controls['lastname'].hasError('required'),
						'text-gray-600': (name_form.untouched && name_form.controls['lastname'].untouched) || !name_form.controls['lastname'].hasError('required')}"
						class="font-italic">required
					</i></small>
				</span>
			</div>
			<input #lname
				type="text"
				class="w-100 form-control"
				[ngClass]="{'is-invalid': name_form.touched && name_form.controls['lastname'].touched && name_form.controls['lastname'].invalid}"
				formControlName="lastname"/>
			<span *ngIf="name_form.touched && name_form.controls['lastname'].touched && name_form.controls['lastname'].hasError('pattern')">
				<small><i class="font-italic text-danger">Requires at least 2 characters</i></small>
			</span>
		</div>
		<div class="form-group mx-auto col-12 col-md-8 col-lg-6">
			<div class="font-weight-bold text-left">Credentials</div>
			<app-avia-form-search
				formControlName="clinical_creds"
				ngDefaultControl
				[data_list]="sob?.users_support?.clinical_credential_types"
				[display_property]="'name'"
				[selector_property]="'name'"
				[slice]="sob?.users_support?.clinical_credential_types.length"
		></app-avia-form-search>
		</div>
	</div>
</form>

<!-- DEBUGGING DISPLAY (name_form) -->
<span *ngIf="false">
	<!-- Display the form values and state during development -->
	<p>Form value: {{ name_form.value | json }}</p>
	<p>Form status: {{ name_form.status | json }}</p>
	<p class="mb-6">Pristine: <span class="text-uppercase">{{ name_form.pristine }}</span></p>
</span>
