// Angular2 imports:
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

// AVIA imports:
import { AVIAConnectService } from './../../avia-connect.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-test-firewall',
  templateUrl: './test-firewall.component.html',
  styleUrls: ['./test-firewall.component.scss']
})
export class TestFirewallComponent implements OnInit {
  static DEFAULT_TIME: number = 10;
  timeleft: number = TestFirewallComponent.DEFAULT_TIME;
  timer:    any;
  searching:boolean = false;
  url:string;
  result:any = {result: 'no further diagnostic info'};
  isLoggedIn: boolean = this.aviaService.isLoggedIn();

  constructor(public aviaService: AVIAConnectService) {
    this.url = this.aviaService.baseUrl + "/about";
  }

  ngOnInit() {
    this.test();
    this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(async () => {
      --this.timeleft;
      if(this.timeleft <= 0) {
        clearInterval(this.timer);
        this.timeleft = TestFirewallComponent.DEFAULT_TIME;
        this.test();
        this.startTimer();
      }
    }, 1000);
  }

  async test() {
    this.timeleft = TestFirewallComponent.DEFAULT_TIME;
    this.searching = true;
    this.result = await this.aviaService.testForBackend();
    this.searching = false;
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    delete this.timer;
  }
}
