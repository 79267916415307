import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-avia-edit-modal',
  templateUrl: './avia-edit-modal.component.html',
  styleUrls: ['./avia-edit-modal.component.scss']
})
export class AviaEditModalComponent implements OnInit {
  @Input() headerIcon: string;
  @Input() modalTitle: string = '';     // give the modal a title
  @Input() assetColor: string = 'gray'; // color the opening button white or gray
  @Input() form: FormGroup;
  @Input() opaque_btn: boolean = false;

  @Output() savesignal: EventEmitter<any> = new EventEmitter<any>(); // tell the parent component to submit form data
  @Output() revertsignal: EventEmitter<any> = new EventEmitter<any>(); // tell the parent component to revert form data
  @Output() startEditing: EventEmitter<any> = new EventEmitter<any>(); // if modal needs a flag to tell parent component to load edit data


  @ViewChild('EditModal', { static: false }) content: NgbModal;
  @ViewChild('ConfirmModal', { static: false }) confirm: NgbModal;

  modal_ref: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  private opts(that:any):NgbModalOptions {
    return {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      windowClass: 'modal-md',
      backdrop: 'static',
      beforeDismiss: async function() {
        let dismiss:boolean = true;
        // open discard-confirmation modal
        if(!that.form.pristine) {
          await that.modalService.open(that.confirm, {centered: true, size: 'sm', backdropClass: 'discard-modal-backdrop', backdrop: "static"}).result.then((result) => {
            if(result=="exit")
              dismiss = true;
            if(result=="cancel")
              dismiss = false;
          });
        }
        // put that thing back where it came from or so help me!
        if(dismiss) that.revertsignal.emit();
        return dismiss;
      }
    }
  };

  saveData() {
    // call my name and SAVE ME
    this.savesignal.emit();
    this.modal_ref.close("saving");
  }

  async open() {
    // open editing modal
    this.modal_ref = this.modalService.open(this.content, this.opts(this));
    this.startEditing.emit(this.modal_ref);
  };

}
