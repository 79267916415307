import { EventEmitter, Component, OnInit, Input, Output } from '@angular/core';
import { AVIAConnectService } from '../../../avia-connect.service';
@Component({
  selector: 'post-react',
  templateUrl: './post-react.component.html',
  styleUrls: ['./post-react.component.scss', '../../feed.scss']
})
export class PostReactComponent implements OnInit {
  @Input() post: any;
  @Input() showLike: boolean = true;
  @Input() showComment: boolean = true;
  @Input() showShare: boolean = false;
  @Input() showFollow: boolean = true;
  @Input() showBookmark: boolean = false;

  @Input() textLike: string = 'Like';
  @Input() textComment: string = 'Comment';

  @Input() i_like: boolean = false;
  @Input() i_follow: boolean = false;
  @Input() i_bookmark: boolean = false;

  @Input()
  set modified_date(data: any) { this._modified_date = data; }
  get modified_date()          { return this._modified_date; }
  _modified_date: any;

  @Output() commentClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() followClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() likeClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() replyClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() shareClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() bookmarkClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(public aviaService: AVIAConnectService) { }
  _followClicked() {
    this.i_follow = !this.i_follow;
    this.followClicked.emit();
  }
  _likeClicked() {
    this.i_like = !this.i_like;
    this.likeClicked.emit();
  }

  _bookmarkClicked() {
    this.i_bookmark = !this.i_bookmark;
    this.bookmarkClicked.emit();
  }

  ngOnInit() {
  }

}
