import { FeedService } from './../../feed.service';
import { EventEmitter, Component, OnChanges, Input, ViewEncapsulation, Output, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { get as _get, find as _find } from 'lodash';
import { trigger, transition, state, style, animate, useAnimation } from '@angular/animations';
import { zoomOut, zoomIn, flipInX,  flipOutX} from 'ng-animate';
import { ReplyComponent } from './../reply/reply.component';


@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss', '../../feed.scss'],
  // encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('zoom', [
      transition('* => void',
        useAnimation(zoomOut, { params: { timing: 0.45 }})),
      transition('void => *',
        useAnimation(zoomIn, { params: { timing: 0.35 }}))
      ]
    ),
  ],
})
export class CommentComponent implements OnChanges {
  @Input() thread_visibility: any = [];
  @Input() is_top: any;
  @Input() page_config: any;
  @Input() comment: any = {};
  @Input() comment_type: number;
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('reply', { static: false}) reply: ReplyComponent;

  show_reply_box: boolean = false;
  reply_item: any;

  answering_question: boolean = false;
  constructor(
    public fs: FeedService,
    private renderer: Renderer2
  ) { }

  ngOnChanges() {
    this.updateReactions();
    this.updateSubitemReactions({});
  }
  updateReactions() {
    this.comment.likes_count = this.comment.data.reactions;
  }
  replyClicked(reply_item){
    this.show_reply_box = !this.show_reply_box;
    this.reply_item = reply_item;
    setTimeout(()=>{
      if(this.reply) {
        this.reply.focusTextArea();
      }
    }, 0)
  }

  async likeClicked(item = this.comment) { // when past item is a sub reply
    //don't do anything if already liked... just in case...
    if(item.data.user_reactions && item.data.user_reactions[0] !== undefined) return false;
    let res = await this.fs.createPostReaction(item.id, { type: 1, content: "" });
    const user_reactions = res.data.user_reactions;
    const reactions = res.data.reactions;

    // add logic to do subitem
    if(this.comment.id === item.id) {
      this.comment.data.user_reactions = user_reactions;
      this.comment.data.reactions = reactions;
    } else {
      this.updateSubitemReactions({ id: item.id, user_reactions, reactions})
    }
    this.updateReactions();
  }

  async unlikeClicked(item = this.comment) {
    if(item.data.user_reactions && item.data.user_reactions[0] === undefined) return false;
    let res = await this.fs.removePostReaction(item.data.user_reactions[0].reaction_id, item.id);

    // add logic to do subitem
    const user_reactions = res.data.user_reactions;
    const reactions = res.data.reactions;

    if(this.comment.id === item.id) {
      this.comment.data.user_reactions = user_reactions;
      this.comment.data.reactions = reactions;
    } else {
      this.updateSubitemReactions({ id: item.id, user_reactions, reactions})
    }
    this.updateReactions();
  }
  updateSubitemReactions({id = -1, user_reactions = [], reactions = 0}) {
    // debugger;
    this.comment.data.replies = this.comment.data.replies.map(reply => {

      if(reply.id == id) {
        reply.data.user_reactions = user_reactions;
        reply.data.reactions = reactions;
      }
      reply.likes_count = reply.data.reactions;
      return reply;
    })

  }

  async getReplies(el_id = this.comment.id) {
    let config = {
      filters:{
        parent_id: el_id,
        direction: "asc"
      }
    }
    let res = await this.fs.getPosts(config);
    this.comment.data.replies = res;
  }

  addReply(comment) {

    if(!this.comment.data.replies) {
      this.comment.data.replies = [];
    }

    if(comment) {
      this.comment.data.replies = this.comment.data.replies.concat(comment);
      this.comment.data.reply_count++;
    }

    this.show_reply_box = false;
  }
}
