import { KnowledgeCard, User, Session } from './../../class';
import { fadeOut, fadeIn } from 'ng-animate';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs'
import { Router } from '@angular/router';

import { AVIAConnectService } from './../avia-connect.service';
import { Banner, E_SomethingHappened } from '../../class';


@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss'],
})
export class NewsfeedComponent implements OnInit {
  session: Session;
  user_id: number;

  // Newsfeed Items
  loading: boolean = true;
  newsfeed_items: any[];
  readonly NEWSFEED_LOCATION_ID = 1
  quicklinks: any[];
  suggested_topics: KnowledgeCard[] = [];
  suggested_connections: User[] = [];
  newsfeed_subscription: Subscription;
  banner: Banner = new Banner();

  // Feed
  feed_config:    any;

  // routes
  sub_routes: Object[] = [
    {
      name: 'Home',
      path: '/start',
      active: true
    }
  ];

  constructor(private aviaService: AVIAConnectService, private router: Router) {
    // THIS WILL REDIRECT TO NEW FEED



    // this.newsfeed_subscription = this.aviaService.somethingHappened.subscribe(event => {
    //   if(event.type === E_SomethingHappened.NEWSFEED_REFRESHED) {
    //     if(event['data'] && event['data']['quick_links']) {
    //       this.getUserQuicklinks();
    //     }
    //     if(event['data'] && event['data']['suggested']) {
    //       this.getSuggestedTopics();
    //     }
    //   } else if(event.type === E_SomethingHappened.ONBOARDING_COMPLETED) this.linksUpdated();
    // })
  }

  async ngOnInit() {
    this.session = await this.aviaService.getSessionSupport();
    if(this.session.user.onboarding_start) {
      this.aviaService.goToNewApp();
    }
    // redirect to newfeed
    // this.session = await this.aviaService.getSessionSupport();
    // this.user_id = this.session.user.id;
    // this.feed_config = {
    //   filters:{
    //     newsfeed: true,
    //     tags:[
    //     ],
    //   },
    //   create: {
    //     tags:[{user_id:this.user_id}],
    //     types:[2,4],
    //   }
    // }
    // this.getBanner();
    // this.getSuggestedTopics();
    // this.getUserQuicklinks();
  }

  async getUserQuicklinks() {
    let res = await this.aviaService.getUserQuicklinks({limit: 10});
    if (res.status == 200) {
      this.quicklinks = res.body;
    }
  }

  // ERIC TODO (maybe rename to getSuggestedTopics)
  async getSuggestedTopics() {
    this.suggested_topics = await this.aviaService.getKMSuggested();
  }

  async getBanner() {
    let res = await this.aviaService.getBanner(this.NEWSFEED_LOCATION_ID)
    if (res.status == 200) {
      this.banner = res.body;
      this.loading = false;
    }
  }

  scrollTop() {
    try {
      window.scrollTo(0,0)
    } catch (err) {
      console.error(err)
    }
  }

  async linksUpdated() {
    [this.session] =
    await Promise.all([
      this.aviaService.getSessionSupport(true),
      this.getUserQuicklinks(),
      this.getSuggestedTopics(),
    ]);
  }

  async onSubmit() {
    // If user is a pulse participant redirect them there after onboarding- otherwise direct to dashboard
    if(this.aviaService.keychain_shell.pulse_enabled.e && this.aviaService.keychain_shell.pulse.e ){
      this.router.navigate(['dashboard', 'hs', this.session.org.id], { queryParams: { activeTab: "Digital Pulse" } });
    } else{
      await Promise.all([
        this.getUserQuicklinks(),
        this.getSuggestedTopics()
      ]);
    }
  }

  ngOnDestroy() {
    //this.newsfeed_subscription.unsubscribe();
  }
}
