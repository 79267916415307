// @angular imports
import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// node_module imports
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription, from, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { inspect } from 'util';

// avia imports
import { Common } from '../common';
import { AVIAConnectService } from '../avia-connect.service';
import { AviaModalFullscreen } from '../_components/avia-modal-fullscreen/avia-modal-fullscreen.component';

import {
  E_Modal_Operating_Mode,
  Org
} from '../../class';


@Component({
  selector: 'app-cb-search',
  templateUrl: './cb-search.component.html',
  styleUrls: ['./cb-search.component.scss']
})
export class CbSearchComponent implements OnInit, AfterContentInit, OnDestroy {
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template

  // Init, Options and Query Params
  qp_add:             string;
  qp_term:            string;
  query_params_sub:   Subscription;
  searchbar_options:  Object = {
    dropdown_enabled:     false,
    dropdown_include_all: true,
    search_bar_white_bg:  true,
    search_on_keypress:   false,
    search_text:          'Search by web address for best results, e.g., apple.com'
  }

  // Search Vars
  no_results:         boolean              = false;
  prev_term:          string               = '';
  search_complete:    boolean              = false;
  search_results:     Observable<{} | Org[]>;
  search_subject:     BehaviorSubject<any> = new BehaviorSubject( of<Org[]>([]) );
  searching:          boolean              = true;
  term:               string               = '';

  // Pagination Vars
  data_reset:         boolean = true;
  pgn_item_start:     number  = 0;  // NOTE: This is a zero-based array index, not a one-based DB index
  pgn_item_count:     number  = 30; // NOTE: This is set so that we can fetch the number of items that the viewport shows plus 2
  search_end_reached: boolean = false;
  count_total:        number  = 0;

  // Add Modal
  new_solco_blank: Org = new Org();

  // Edit Modal
  @ViewChild('ScAddModal', { static: false })    sc_add_modal:    any;
  @ViewChild('ScImportModal', { static: false }) sc_import_modal: any;


  constructor( public aviaService: AVIAConnectService, private route: ActivatedRoute, private router: Router ) {
    this.query_params_sub = this.route.queryParams.subscribe(
      (qp) => {
        this.qp_term = qp['term'] ? qp['term'] : undefined;
        this.qp_add  = qp['add']  ? qp['add']  : undefined;
      },
      (err) => { console.error('Error: %s', err); },
      () => { /* console.log('Complete'); */ }
    );
  };

  initSearch() {
    this.count_total = 0;
    this.searching = false;
    this.search_results = this.search_subject
    .asObservable()
    .pipe( switchMap( data => this.searchCompanies( data ) ) )
    .pipe( map( data => {
      this.searching = false;
      this.search_complete = true;
      this.no_results = data.length == 0 ? true : false;
      return data;
    }) )
    .pipe( catchError( error => {
      console.error('cb-search error: %s', error);
      this.no_results = true;
      return of<Org[]>([]);
    }) );
  };

  ngOnInit() {
    this.initSearch();
  };

  ngAfterContentInit() {
    switch( true ){
      case (this.qp_term !== undefined):
        //console.log('auto searching...');
        this.term = this.qp_term;
        this.qp_term = '';
        this.searching = true;
        this.search_subject.next( this.term );
      return;

      case (this.qp_add !== undefined):
        this.openAddFromCrunchbase({ 'crunchbase_id': this.qp_add });
      return;
    }
  }

  ngOnDestroy() {
    this.qp_add = undefined;
    this.qp_term = undefined;
    if( this.query_params_sub ) {
      this.query_params_sub.unsubscribe();
    }
  };


  ////////////////////////////////////////////////////////////////////////////////
  // READ

  //Search
  searchCompanies( term, pgn_item_start:number = this.pgn_item_start, pgn_item_count:number = this.pgn_item_count ): Observable<Org[]> {
    if( term === undefined || term.length === 0 ) return of<Org[]>([]);

    this.search_end_reached == false;
    let searchObj = { 'filter': term, 'start':  pgn_item_start, 'count':  pgn_item_count };

    return from( this.aviaService.getCrunchbase( searchObj ).then( response => {
      let data = response.body['rows'];
      this.count_total = response.body['count_total'] !== undefined ? response.body['count_total'] : 0;
      this.search_end_reached = response.body['end_reached'] !== undefined ? response.body['end_reached'] : true;
      return data.map( company => {
        /* NOTE: In case you want to parse an address (sort-of) from the Crunchbase db, the code below is a good start
        let split_hq_loc = company.headquarters_locations.split(',');
        let split_hq_loc_length = split_hq_loc.length;
        let normalized_loc = '';
        switch( true ) {
          case (split_hq_loc_length > 2):
            normalized_loc = split_hq_loc[ split_hq_loc_length - 3 ] + ', ' + split_hq_loc[ split_hq_loc_length - 2 ] + ', ' + split_hq_loc[ split_hq_loc_length - 1 ];
          break;
          case (split_hq_loc_length > 1):
            normalized_loc = split_hq_loc[ split_hq_loc_length - 2 ] + ', ' + split_hq_loc[ split_hq_loc_length - 1 ];
          break;
          case (split_hq_loc_length > 0):
            normalized_loc = split_hq_loc[0];
          break;
        }
        */
        let avia_id = company.avia_org_obj[0] !== undefined ? company.avia_org_obj[0].id : 0;
        this.searching = false;
        return { 'name':company.company_name, 'domain':company.domain, 'logo':company.company_logo, 'crunchbase_id':company.uuid, 'id':avia_id };
      });
    }));
  };

  updateSearch( $event ): void {
    if( $event && $event.offset !== undefined ) {
      if( this.search_complete === false || this.search_end_reached === true ) return;
      this.data_reset = false;
      this.pgn_item_start = $event.offset;
    }
    this.search_complete = false;
    this.search_subject.next( this.term );
  }

  termSubmitted( $event ): void {
    this.data_reset = true;       // We are resetting the virtual scroll list
    this.pgn_item_start = 0;      // We are resetting the pagination start
    this.prev_term = this.term;   // We are recording the prev term for
    this.searching = true;        // We are searching
    this.search_complete = false; // We are starting a new search
    this.term = $event.term;      // We have a new search term
    this.count_total = 0;
    //if( this.term !== '' && !( this.prev_term.startsWith( this.term ) ) ) {
    //  let mp = { 'AVA_search_term': this.term };
    //  //console.log('Crunchbase (Searched), %s', inspect(mp) );
    //  this.aviaService.mixpanelEnabled() && mixpanel.track("Crunchbase (Searched)", mp);
    //}
  }

  async openAddFromCrunchbase( $event ) {
    try {
      let response = await this.aviaService.getCrunchbase( {'uuid': $event.crunchbase_id, 'lite':1} );
      // console.log(response);
      let cb_to_add = response.body['rows'][0] ? response.body['rows'][0] : undefined;
      if (cb_to_add !== undefined) this.sc_import_modal.open( cb_to_add );
    } catch(err) {
      console.error('response came back empty! err=', err);
    }
  };

  async submitAddFromCrunchbase( org ) {
    delete org['crunchbase_uuid'];
    delete org['headquarters_locations'];
    delete org['url_crunchbase'];
    delete org['url_news'];
    org['type'] = 2; // Set type to 'solco'
    org['status'] = 4; // Set status to active
    let result = await this.aviaService.createSolCo( org );
    if( result && result['id'] !== undefined ) {
      this.aviaService.notify('success', 'Success!', 'Solution Company added');
      this.sc_add_modal.close();
      this.router.navigate([ '/sc/' + result['id'] ]);
    }
  };

  async submitImportFromCrunchbase( org ) {
    delete org['crunchbase_uuid'];
    delete org['headquarters_locations'];
    delete org['url_crunchbase'];
    delete org['url_news'];
    org['type'] = 2; // Set type to 'solco'
    org['status'] = 4; // Set status to active

    // Test to see if an Avia-style Address (or part of it) was supplied
    // if not, delete the 'hq_addr' block
    let empty_addr:boolean = true;
    for (const item in org['hq_addr']) {
      let val = org['hq_addr'][item];
      if (val !== undefined && val !== null && val !== '') {
        empty_addr = false;
        break;
      }
    }
    if (empty_addr) delete org['hq_addr'];

    let result = await this.aviaService.createSolCo( org );
    if( result && result['id'] !== undefined ) {
      this.aviaService.notify('success', 'Success!', 'Solution Company added');
      this.sc_import_modal.close();
      this.router.navigate([ '/sc/' + result['id'] ]);
    }
  };
}
