import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { AVIAConnectService } from '../../../avia-connect.service';
import { UserRowComponent_Options } from '../../../_components/avia-data-rows/avia-user-row/avia-user-row.component';


@Component({
  selector: 'app-discussion-participants',
  templateUrl: './discussion-participants.component.html',
  styleUrls: ['./discussion-participants.component.scss']
})

export class DiscussionParticipantsComponent implements OnInit {
  @Input() mode: string = "create";
  @Input() allow_navigate = true;
  @Input() participants = [];
  @Input() add_enabled = true;
  @Output() update: EventEmitter<any> = new EventEmitter<any>(); // tell the parent component to submit form data

  user_display_opts: UserRowComponent_Options = new UserRowComponent_Options();

  constructor(public aviaService: AVIAConnectService, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.user_display_opts.view_type = 'list';
    this.user_display_opts.allow_remove = true;
    this.user_display_opts.display_primary_contact = true;
    this.user_display_opts.display_exec_sponsor = true;
    this.user_display_opts.allow_navigate = this.allow_navigate;
    this.user_display_opts.org_badge = true;
  }

  async addUser(data) {
    if(this.mode != "create") {
      let res = await this.aviaService.notify(
        'warning',
        'Adding person',
        'Adding this person will give them access to the entire conversation history',
        {
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        }
      )
      if(res.dismiss === "cancel") return;
    }
    for(let p of this.participants) {
      if(p.id === data.id) return false;
    }
    if(this.aviaService.session && this.aviaService.session.user && data.id === this.aviaService.session.user.id) {
      return false;
    }
    this.participants.push(data);
    this.update.emit({participants:this.participants});
    return true;
  }

  async updateUsers(data) {
    let session = await this.aviaService.getSessionSupport();
    console.log(data.action);
    if(data.action === "delete") {
      if(this.mode != "create") {
        let title = "Removing participant";
        let message = "Once removed, this person will no longer have access to the messages in this conversation.";

        if(session.user.id === data.data.id) {
          title = "Removing yourself"
          message = "Once removed from this conversation you will no longer be able to see messages between the remaining participants.";
        }
        else if(this.participants.length < 3){
          title = "Removing participant"
          message = "Removing this participant will delete the conversation.";
        }

        let res = await this.aviaService.notify(
          'warning',
          title,
          message,
          {
            showCancelButton: true,
            confirmButtonText: 'Remove'
          }
        )
        if(res.dismiss === "cancel") return;
      }

      this.participants = this.participants.filter((p:any)=>{
        return p.id != data.data.id;
      })
    }
    this.update.emit({participants:this.participants});
  }
}
