<ng-content>
	<!-- Button goes here -->
</ng-content>

<div #drawer *ngIf="gns.show_mobile_drawer && !gns.tabs[0].active" class="drawer bg-white">
	<div class="drawer-header d-flex align-items-center justify-content-between bg-gray-100 px-4 py-2">
		<div class="heading-size-16">{{ gns?.active_tab?.name }}</div>
		<!-- <button class="close-btn border-0 btn-gray-100 d-flex align-items-center justify-content-center py-1" (click)="gns.closeMobileDrawer()">
			<i class="material-icons">close</i>
		</button> -->
	</div>
	<div class="drawer-body">
		<!-- My Connect -->
		<app-mobile-navbar-myconnect *ngIf="gns.tabs[3].active"></app-mobile-navbar-myconnect>

		<!-- My Organization -->
		<app-mobile-navbar-myorg *ngIf="gns.tabs[2].active"> </app-mobile-navbar-myorg>

		<!-- Discover -->
		<app-mobile-navbar-discover *ngIf="gns.tabs[1].active"></app-mobile-navbar-discover>

		<!-- Notifications -->
		<app-avia-notifications *ngIf="gns.tabs[4].active" (redirect)="gns?.closeMobileDrawer();gns?.getNotificationsCount()" (updateNotificationsCount)="gns.getNotificationsCount()" (close)="gns.closeMobileDrawer()"></app-avia-notifications>

	</div>

</div>
