import { AviaAlertModalComponent } from './../../_components/avia-alert-modal/avia-alert-modal.component';
import { E_AlertModalTypes } from './../../../class';
import { Params } from '@angular/router';
import { PostmakerComponent } from './../postmaker/postmaker.component';
import { ReplyComponent } from './../components/reply/reply.component';
import { FeedService } from './../feed.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, state, style, animate, useAnimation } from '@angular/animations';
import { zoomOut, zoomIn, flipInX,  flipOutX} from 'ng-animate';
import {  AnalyticEvent } from '../../../class';
import { get as _get, find as _find } from 'lodash';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss', '../feed.scss'],
  animations: [
    trigger('zoom', [
      transition('* => void',
        useAnimation(zoomOut, { params: { timing: 0.45 }})),
      transition('void => *',
        useAnimation(zoomIn, { params: { timing: 0.35 }}))
      ]
    ),
  ],
})
export class PostComponent implements OnInit, OnChanges {
  @Input() post: any = {};
  @ViewChild('postmaker', { static: false }) postmaker: PostmakerComponent;
  @ViewChild('reply', { static: false}) reply: ReplyComponent;
  @ViewChild('alertModal', { static: false }) alert_modal: AviaAlertModalComponent;

  view_more_not_clicked: boolean = true;
  show_reply_box: boolean = false;
  i_like: boolean = false;
  votes: any;
  page_config: any;
  post_type: number;
  read_more: boolean = false;

  //content hack...
  @Input() active_id:          number;
  @Input() allow_recommending: boolean = false;
  @Input() white_background:   boolean = false;
  @Input() gray_border:        boolean = false;
  @Input() show_appears_in:    boolean = true;
  @Input() content_types:      any;


  @Output() onContentDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() onRecommendedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onBookmark: EventEmitter<any> = new EventEmitter();
  @Output() onSetActiveID: EventEmitter<number> = new EventEmitter();

  readonly E_AlertModalTypes = E_AlertModalTypes;

  constructor(
    public fs: FeedService,
    public cd: ChangeDetectorRef
  ) { }

  async ngOnInit() {}

  ngOnChanges() {
    this.post_type = this.post.type || 1;

    const page_config_overrides = this.getPageConfigOverrides(this.post_type)
    this.page_config = this.getPageConfig(page_config_overrides);
    this.setReactions();
  }

  setReactions() {
   this.votes = this.post.data.reactions;
   this.i_like = this.post.data.user_reactions[0] !== undefined;
  }

  getPageConfigOverrides(type) {

    const reply_len = _get(this, 'post.data.replies.length', 0);
    const reply_count = _get(this, 'post.data.reply_count', 0);
    const more_reply = reply_count - reply_len

    let visibilityText = this.fs.getVisibilityText(this.post.data.visibility);

    switch(type) {
      case 1: // comment
        return {};
      case 2: // post
        return {
          show: {
            answer_button: false,
            follow: false,
            q: false,
            // see_answers: false,
            like_count: false,
            view_more_comments_button: reply_count > 2 &&  reply_len !== reply_count,
          },
          placeholders: {
            comment_button: 'Comment',
            comment_text: 'Comment',
            comment_placeholder: 'Comment' + visibilityText,
          }
        };
      case 3: // reply
        return {};
      case 4: // question
        return {
          show: {
            comment_button: false,
            share_button: false,
            help_text: true,
            // tags: false,
          },
          placeholders: {
            comment_button: 'Answer',
            comment_placeholder: 'Answer' + visibilityText,
            comment_react: 'Yes',
          }
        };
      case 5: // answer
        return {};
      case 6: // event
        return {};
      case 7: // content
        return {
          show: {
            answer_button: false,
            follow: false,
            q: false,
            // see_answers: false,
            like_count: false,
            view_more_comments_button: reply_count > 2 &&  reply_len !== reply_count,
          },
          placeholders: {
            comment_text: 'Comment' + visibilityText,
          }
        };
      default:
        return {};
    };
  }
  getPageConfig({ show: show_overrides = {}, placeholders: placeholders_overrides = {}}) {
    return {
      show: {
        answer_button: true,
        comment_button: true,
        follow: true,
        help_text: false,
        like: true,
        like_count: true,
        q: true,
        see_answers: true,
        share_button: true,
        tags: true,
        // not used yet
        card_kebab: false,
        show_more_text: false,
        view_more_comments_button: false,
        ...show_overrides
      },
      placeholders: {
        question_date: 'Posted',
        comment_text: 'Answer',
        comment_button: 'Comment',
        comment_placeholder: 'Add a comment...',
        ...placeholders_overrides
      },
    };
  }

  editing = false;
  async editPost() {
    this.editing = true;
    await this.cd.detectChanges();
    this.postmaker.open();
  }

  shareClicked() {
    console.log('shareClicked')
  }

  async likeClicked() {
    let res;
    if(this.i_like) {
      // get the reaction_id to delte it.
      const { reaction_id = -1} = _get(this, 'post.data.user_reactions[0]');
      res = await this.fs.removePostReaction(reaction_id, this.post.id);
    } else {
      res = await this.fs.createPostReaction(this.post.id, {content: "", type: 1 });
    }
    const user_reactions = _get(res, 'data.user_reactions', []);
    const reactions = _get(res, 'data.reactions', 0);
    this.post.data.user_reactions = user_reactions;
    this.post.data.reactions = reactions;
    this.setReactions();
  }
  async getReplies() {
    // this.page_config.show.view_more_comments_button = false;
    this.view_more_not_clicked = false;
    let config = {
      filters: {
        parent_id: this.post.id,
        direction: "asc",
        top:1
      }
    };
    let res = await this.fs.getPosts(config);
    this.post.data.replies = res;
  }

  async followClicked () {
    let res;
    if (this.post.data.user_followed) {
      res = await this.fs.unfollowPost(this.post.id, { comment_id: this.post.id });
    } else {
      res = await this.fs.followPost(this.post.id, { comment_id: this.post.id });
    }
    const user_followed = _get(res, 'data.user_followed', false);
    this.post.data.user_followed = user_followed;
  }

  async commentClicked () {
    this.show_reply_box = !this.show_reply_box;
    setTimeout(()=>{
      if(this.reply) {
        this.reply.focusTextArea();
      }
    },0);
  }

  updatePost(post) {
    if(post) {
      this.post = post;
      this.setReactions();
    }
  }

  openAlertModal() {
    this.alert_modal.open();
  }

  async toggleMute() {
    if(this.post.data.muted) {
      await this.fs.unmutePost(this.post.id);
    }
    else {
      await this.fs.mutePost(this.post.id);
    }

    this.post.data.muted = !this.post.data.muted;
  }

  async togglePin() {
    let move = (array, from, to) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
    };

    if(this.post.data.pin) {
      await this.fs.unpinPost(this.post, this.fs.config.pin);

      let i = 0
      for(i = 0; i < this.fs.items.length; i++) {
        let item = this.fs.items[i];
        if(!item.data.pin) {
          break;
        }
      }
      i = i-1;
      if(i < 0) i = 0;

      move(this.fs.items, this.fs.items.indexOf(this.post), i);
      this.post.data.pin = null;
    }
    else {
      this.post.data.pin = 1;
      this.sendPinToTop();
    }
  }

  async sendPinToTop() {
    let move = (array, from, to) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
    };
    await this.fs.pinPost(this.post, this.fs.config.pin);
    move(this.fs.items, this.fs.items.indexOf(this.post),0);
  }

  async deletePost(event) {
    await this.fs.deletePost(this.post);
    this.alert_modal.close();
  }

  addReply(replies) {
    this.post.data.replies = [...this.post.data.replies, ...replies];
    this.show_reply_box = false;
  }

  async updateContent(update_obj) {
    this.post.data.content_item = (await this.fs.aviaService.updateContent(this.post.data.content_item.id, update_obj)).body.updated_item;
    if(update_obj['interested'] != undefined) {
      this.onBookmark.emit();
    }
  }

  async bookmarkClicked() {
    await this.updateContent({'interested': (this.post.data.content_item.interested * -1 + 1 )});
  }

  isTop(comment_id, replies) {

    let getLikes = (comment)=> {
      return comment.data.reactions
    }


    let top_comment = replies.reduce((a,b)=>{
        return getLikes(a) > getLikes(b) ? a : b;
    })

    return top_comment.id == comment_id;
  }


  async recordClickAnalytics($event) {
    //polyfill for edge
    (function(E, d, w) {
      if(!E.composedPath) {
        E.composedPath = function() {
          if (this.path) {
            return this.path;
          }
        var target = this.target;

        this.path = [];
        while (target.parentNode !== null) {
          this.path.push(target);
          target = target.parentNode;
        }
        this.path.push(d, w);
        return this.path;
        }
      }
    })(Event.prototype, document, window);


    if($event.target) {
      for(let target of $event.composedPath()) {
        if($event.target.tagName == "A") {
          let event = new AnalyticEvent(
            'post_click',
            {
              "current": location.pathname,
              "urlparams": location.search,
              "link": target.href,
              "feed_config": this.fs.config
            },
            target.href
          );
          await this.fs.aviaService.createAnalyticEvent(event);
          break;
        }
      }
    }
  }
}
