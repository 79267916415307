import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// node_module imports
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, interval } from 'rxjs'
import { distinct, startWith, flatMap } from 'rxjs/operators'

const {
  groupBy:   _groupBy,
  partition: _partition
} = require('lodash');

// AVIA imports
import { AVIAConnectService } from './../../avia-connect.service';
import { Common } from './../../common';
import { E_SomethingHappened, Notification, User } from '../../../class';


@Component({
  selector: 'app-avia-notifications',
  templateUrl: './avia-notifications.component.html',
  styleUrls: ['./avia-notifications.component.scss']
})
export class AviaNotificationsComponent implements OnInit, OnDestroy {
  @Input() active: boolean = true;
  @Output() updateNotificationsCount: EventEmitter<any> = new EventEmitter();
  @Output() redirect: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  notifications:       Notification[] = [];
  notifications_count: number;
  session:             Object;
  subscription:        any;
  s:                   any;
  _notificationAlreadyRecieved: number[] = [];

  notificationCountMap: { [k: string]: string } = {
    '=0': '',
    '>99': '99+',
    'other': '#'
  };

  constructor(private config: NgbDropdownConfig, public aviaService: AVIAConnectService, private router: Router) {
    this.config.autoClose = true;
  }

  // check if the system is not sane
  isSystemSane(): boolean {
    return this.active &&
      !this.aviaService.shouldReloadNewVersion &&
      this.aviaService.loggedIn == true &&
      ((this.aviaService.access_token != '' &&
      this.aviaService.access_token != undefined) || this.aviaService.hasLoginCookies()) &&
      this.aviaService.serverDownQueue.length === 0;
  }

  ngOnInit() {

    this.aviaService.getSessionSupport().then(data => this.session = data).then(() => {
      this.subscription = this.getNotificationsOnInterval().subscribe(data => {
        // protect against bad data from retval from isSystemSane case in getNotificationsOnInterval()
        // (sometimes we change the format, and forget to update)
        // (was [] before, yeah, that's bad, crashy!)
        // since this code happens ALL THE TIME.  make it MEDICAL stable
        try {
          let core_notifications = this.sortNotifications(data.notifications);
          this.notifications = [];
          if(data && data.qa) {
            this.notifications = this.notifications.concat(data.qa);
            this.notifications = this.notifications.sort( (a,b) => {
              if(a.created < b.created) {
                return -1;
              }
              else {
                return 1;
              }
            });
          }
          if(core_notifications) {
            this.notifications = this.notifications.concat(core_notifications);
          }
          this.sortByDate();
          this.notifications_count = this.notifications.length;
        } catch (err) {
          this.aviaService.newrelicError( err );
        }
      });
    });
  }

  sortByDate() {
    this.notifications = this.notifications.sort((a, b) => {
      if (a.created < b.created) return 1;
      if (a.created > b.created) return -1;
      return 0;
    })
  }

  closeNotificationWindow() {
    this.close.emit();
  }

  getNotifications(): void {
    this.aviaService.getNotifications(this.session['user'].id, {dismissed: 0}).then(data => this.subscription.next(data.body));
  }

  getNotificationsOnInterval(): Observable<any> {

    return new Observable(subscriber => {
      let poll_timeout;
      let poll_last_run = Date.now();
      let poll_running = true;
      const POLLING_INTERVAL = 60000;

      let poll = async()=>{
        if (!this.isSystemSane()) return new Promise((rs,rj) => rs({ message: "success", count: 0, notifications: [] }));
        let data = await this.aviaService.getNotifications(this.session['user'].id, {dismissed: 0});
        if (data.body.notifications && data.body.notifications.length > 0) {
          let refreshSession = false;

          //only refresh the session once if there is a connection notification, then break out early.
          for(let n of data.body.notifications) {
            if(!this._notificationAlreadyRecieved.includes(n.id)) {
              if (n.type_obj && n.type_obj.id && n.type_obj.id == 3) {
                await this.aviaService.getSessionSupport(true);
                break;
              }
            }
          }

          for(let n of data.body.notifications) {
            if(!this._notificationAlreadyRecieved.includes(n.id)) {
              if (n.type_obj && n.type_obj.id && n.type_obj.id == 3) {
                this._notificationAlreadyRecieved.push(n.id);
                this.aviaService.somethingHappened.emit({type: E_SomethingHappened.USER_CONNECTION, data: n.action_user_obj});
              }
            }
          }
        }
        poll_last_run = Date.now();

        //notify subscribers...
        subscriber.next(data.body);

        //queue up the next iteration to run recursively
        poll_timeout = setTimeout(()=>{
          if(poll_running) {
            poll();
            return true;
          }
        }, POLLING_INTERVAL);

        return true;
      }

      window.addEventListener('focus', event => {
        poll_running = true;
        // if more than the polling interval has ellapsed since we last had focus then immediately poll
        // if less than the polling interval has ellapsed then only wait the remaining difference before polling
        let wait = (POLLING_INTERVAL - (Date.now() - poll_last_run));
        if(wait < 0) {
          wait = 0;
        }
        if(poll_timeout) clearTimeout(poll_timeout);
        poll_timeout = setTimeout(()=>{poll();}, wait);
      });
      window.addEventListener('blur', event => {
        poll_running = false;
        if(poll_timeout) clearTimeout(poll_timeout);
      });

      poll();
    });
  }
  // this function squashes notifications from chat into one message ie
  // "paul and john commented on x" instead of msg1: "Paul commented on X". msg2 "John commented on x"
  sortNotifications(notifications): Notification[] {
    // first we remove the notifications that cannot be squsahed
    const [otherNotifications, commentsNotifications] = _partition(notifications, n => n.type_obj.key === 'hs_action' || n.type_obj.key === 'user_obj' );
    // then we group the messages by the group id
    let sorted_notifications = _groupBy(commentsNotifications, 'comment_obj.group_id');
    // then if there are multiple notifications we squash into one.
    const squashedNotifications = Object.keys(sorted_notifications).map(group => {
      return sorted_notifications[group].length > 1
        ? this.createOneNotificationFromMany(sorted_notifications[group])
        : sorted_notifications[group][0]
    });
    return  [...squashedNotifications, ...otherNotifications]; //SUPER FLAT
  }

  createOneNotificationFromMany(notifications): Notification {
    // Create new blank notification and its data
    let new_compiled_notification = new Notification;
    let notification_ids = [];
    let compiled_users_set = new Set();   // Using set here to have unique items: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
    let users = new User;
    for(let n of notifications) {
      notification_ids.push(n.id);  // Make array of IDs to send update for each unique notification in compiled notification
      compiled_users_set.add(n.action_user_obj.fullname);  // Add each action user's fullname to set
    }
    let i = 0;
    users.fullname = '';

    // Making new notification's action user fullname to be concatenated string of each action user
    compiled_users_set.forEach(user => {  // for(let...) did not work here
      if(compiled_users_set.size === 2 && i === 1) {
        users.fullname += ` and ${user}`
      } else if(i === 0 && (compiled_users_set.size === 2 || compiled_users_set.size === 1)) {
        users.fullname += `${user} `;
      } else if(i === compiled_users_set.size - 1 && compiled_users_set.size !== 2) {
        users.fullname += `and ${user}`;
      } else {
        users.fullname += `${user}, `;
      }
      i++;
    })

    //Setting new notifcation to the processed data
    new_compiled_notification = notifications[0];
    new_compiled_notification.action_user_obj = users;
    new_compiled_notification.id = notification_ids;
    return new_compiled_notification;
  }

  async dismiss(event) {
    let notification = event.notification;
    if(event.notification.type_obj.name === "feed") {
      await this.aviaService.getPosts({
        filters:{
          ids: [notification.type_obj.post.id, ...(notification.type_obj.post.parent_id ? [notification.type_obj.post.parent_id] : [])]
        }
      })

      const index = this.notifications.indexOf(notification);
      if (index > -1) {
        this.notifications.splice(index, 1);
      }
      if(this.notifications_count > 0) {
        this.notifications_count = this.notifications_count - 1;
      }
      this.updateNotificationsCount.emit();
    }
    else {
      //console.log(notification);
      if(notification.id.length && notification.id.length > 1) {
        let update_notifications = [];
        for(let id of notification.id){
          let n = {id: id, dismissed: 1};
          update_notifications.push(n);
        }
        this.aviaService.updateNotifications({notifications: update_notifications, user_id: this.session['user'].id }).then(
          data => {this.getNotifications()
          this.updateNotificationsCount.emit();
           });
      } else {
        this.aviaService.updateNotification(notification.id, { dismissed: 1 }).then(
          data => {
            this.getNotifications()
            this.updateNotificationsCount.emit();
        });
      }
    }
  }

  goToCard($event): void {
    const notification = $event.notification;
    let queryParams;
    let url;

    switch(notification.type_obj.name) {
      case 'HS Action':
        queryParams = {};
        url = notification.options.path;
        break;

      case 'KM card comment':
        queryParams = { mode: 1, id: notification.km_obj.id }
        if (notification.comment_id) {
          queryParams['target'] = notification.comment_id;
        }
        url = '/intelligence/km/graph';
        break;

      case 'KM chat':
        queryParams = { mode: 1, id: notification.km_obj.id, chat: true }
        if (notification.comment_id) {
          queryParams['target'] = notification.comment_id;
        }
        url = '/intelligence/km/graph';
        break;

      case 'CL comment':
        queryParams = { mode: 1, id: notification.content_obj.id };
        if( notification.comment_id) {
          queryParams['target'] = notification.comment_id;
        }
        url = '/intelligence/cl/search';
        break;
      case 'Added to Group':
      case 'Added to Workspace':
      case 'Request to join channel':
      case 'Pulse Comment':
      case 'Info request':
      case 'Info request acknowledgment':
          queryParams = {};
          url = notification.options.url;
          break;
      case 'Channel chat':
      case 'Group chat':
        queryParams = { mode: 1, id: notification.channel_obj.id, chat: true };
        if (notification.comment_id) {
          queryParams['target'] = notification.comment_id;
        }
        url = '/ws/channel/' + notification.channel_obj.id;
        break;

      default: break;
    }

    if (url) { this.router.navigate([url], { queryParams });}
    this.dismiss({ notification });
    this.redirect.emit();

  }

  async goToMemberProfile($event) {
    let notification = $event.notification;
    await this.dismiss({notification:notification});
    if( notification.options && notification.options.accepting ) {
      this.router.navigate(['/profiles/in/' + notification.action_user_obj.id]);
    } else {
      // this.router.navigate(['/profiles/in/' + notification.target_user_obj.id], {queryParams: {activeTab: 'Network'}});
      this.router.navigate(['/profiles/in/' + notification.action_user_obj.id] );
    }
    this.redirect.emit();
  }

  goToLink($event) {
    const notification = $event.notification;
    const url = notification.url;
    window.location.href = url;
    this.dismiss($event);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
