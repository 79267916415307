import { E_AlertModalTypes } from './../../../class';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

// AVIA Alert
// @example debut
// <app-avia-alert-modal
//    [modal_title]="'Test Title'">
// </app-avia-alert-modal>
// @example fin
@Component({
  selector: 'app-avia-alert-modal',
  templateUrl: './avia-alert-modal.component.html',
  styleUrls: ['./avia-alert-modal.component.scss']
})
export class AviaAlertModalComponent implements OnInit {
  @Input() button_cancel_text: string = 'Cancel';
  @Input() button_confirm_text: string = 'Confirm';
  @Input() modal_title: string;
  @Input() show_cancel_button: boolean = false;
  @Input() show_confirm_button: boolean = true;
  @Input() type: E_AlertModalTypes = E_AlertModalTypes.GENERAL;
  /*
    0 = ERROR
    1 = WARNING
    2 = SUCCESS
    3 = GENERAL
  */

  @Output() confirmEmit: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('AlertModal', { static: true }) modal: NgbModal;

  readonly E_AlertModalTypes = E_AlertModalTypes;
  modal_ref: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  private opts(that:any): NgbModalOptions {
    return {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      windowClass: 'modal-md',
      backdrop: 'static',
    }
  }

  async open() {
    this.modal_ref = this.modalService.open(this.modal, this.opts(this));
  }

  async close() {
    this.modal_ref.close();
  }

  async confirm() {
    this.confirmEmit.emit(true);
  }

  async cancel() {
    this.modal_ref.close();
  }

}
