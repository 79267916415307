<div class="pb-4">
	<div class="row">
		<div class="col-12 col-md-8">
			<div class="row no-gutters pt-4 pb-2">
				<div class="font-weight-bold">Weekly Emails</div>
				<div class="d-flex align-items-center pl-1">
					<i class="material-icons pointer text-gray-500 tooltip-info"
						data-toggle="tooltip"
						ngbTooltip="Receive a weekly overview of top articles from AVIA Connect"
						placement="bottom">info
					</i>
				</div>
			</div>
			<!-- Articles -->
			<div class="alt-gray-rows py-1 pl-2">
				<app-avia-email-toggle-row
					[key]="'email_articles'"
					[label]="'Weekly articles update'"
					[value]="session?.user?.email_articles"
					(toggled)="update($event)">
				</app-avia-email-toggle-row>
			</div>

			<!-- Workspace Chat -->
			<div class="row no-gutters pt-4 pb-2">
				<div class="font-weight-bold">Group Chat</div>
				<div class="d-flex align-items-center pl-1">
					<i class="material-icons pointer text-gray-500 tooltip-info"
						data-toggle="tooltip"
						ngbTooltip="Receive periodic email updates with recent workspace chat"
						placement="bottom">info
					</i>
				</div>
			</div>
			<div class="alt-gray-rows py-1 pl-2" *ngFor="let ws of user_workspaces">
				<app-avia-email-toggle-row
					[key]="'email_ch_chat'"
					[link]="'/ws/channel/' + ws?.id"
					label="{{ ws?.activity_name }} | {{ ws?.name }}"
					[value]="ws?.email_ch_chat"
					(toggled)="updateWsChat($event, ws?.id)">
				</app-avia-email-toggle-row>
			</div>

			<!-- Feed and questions -->
			<div class="row no-gutters pt-4 pb-2">
				<div class="font-weight-bold">Feed & Questions</div>
				<div class="d-flex align-items-center pl-1">
					<i class="material-icons pointer text-gray-500 tooltip-info"
						data-toggle="tooltip"
						ngbTooltip="Receive periodic email updates about posts & questions you are following"
						placement="bottom">info
					</i>
				</div>
			</div>
			<div class="alt-gray-rows py-1 pl-2">
				<app-avia-email-toggle-row
					[key]="'email_qa_daily'"
					[label]="'Daily Questions Email'"
					[value]="session?.user?.email_qa_daily"
					(toggled)="update($event)">
				</app-avia-email-toggle-row>
			</div>
			<div class="alt-gray-rows py-1 pl-2">
				<app-avia-email-toggle-row
					[key]="'email_qa_responses'"
					[label]="'Reply/Anwsers Emails'"
					[value]="session?.user?.email_qa_responses"
					(toggled)="update($event)">
				</app-avia-email-toggle-row>
			</div>

			<!-- Messenger -->
			<div class="row no-gutters pt-4 pb-2">
				<div class="font-weight-bold">Messenger</div>
				<div class="d-flex align-items-center pl-1">
					<i class="material-icons pointer text-gray-500 tooltip-info"
						data-toggle="tooltip"
						ngbTooltip="Receive emails when you get a new message"
						placement="bottom">info
					</i>
				</div>
			</div>
			<div class="alt-gray-rows py-1 pl-2">
				<app-avia-email-toggle-row
					[key]="'email_messenger_blast'"
					[label]="'Email announcements from AVIA Connect'"
					[value]="session?.user?.email_messenger_blast"
					(toggled)="update($event)">
				</app-avia-email-toggle-row>
			</div>

		</div>
	</div>
</div>
