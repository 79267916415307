<div *ngIf="!aviaService?.mobile_mode" [routerLink]="'/notifications'" ngbDropdown placement="bottom-right" #notificationsDropdown="ngbDropdown" class="dropdown avia-notifications" (blur)="notificationsDropdown.close()">
	<div ngbDropdownToggle class="selector-size d-flex align-items-center" id="notifications-selector">
		<i class="pointer material-icons text-gray-600">notifications</i>
		<div *ngIf="notifications_count > 0"
			class="notification-badge badge-red-lighter d-flex align-items-center justify-content-center font-weight-bolder">
			{{ notifications_count | i18nPlural: notificationCountMap }}
		</div>
	</div>
	<!--
	<div ngbDropdownMenu class="notification-menu dropdown-menu dropdown-menu-right">
		<ng-container *ngFor="let n of notifications;index as i;last as isLast">
			<div class="dropdown-item" *ngIf="i<=5">
				<app-notify-feed *ngIf="n?.type_obj?.name === 'feed'" [notification]="n" (dismiss)="dismiss($event)" (goTo)="goToLink($event)"></app-notify-feed>
					n.type_obj?.name === 'KM chat' ||
					n.type_obj?.name === 'CL comment' ||
					n.type_obj?.name === 'Group chat' ||
					n.type_obj?.name === 'Channel chat' ||
					n.type_obj?.name === 'HS Action' ||
					n.type_obj?.name === 'Pulse Comment' ||
					n.type_obj?.name === 'Added to Group' ||
					n.type_obj?.name === 'Added to Workspace' ||
					n.type_obj?.name === 'Request to join channel' ||
					n.type_obj?.name === 'Info request' ||
					n.type_obj?.name === 'Info request acknowledgment')"
					[notification]="n"
					(dismiss)="dismiss($event)" (goTo)="goToCard($event)"
				></app-notify-comment>
				<app-notify-np-connect *ngIf="n.type_obj?.name === 'User connection'" [notification]="n" (dismiss)="dismiss($event)" (goTo)="goToMemberProfile($event)"></app-notify-np-connect>
			</div>
		</ng-container>

		<div *ngIf="notifications?.length === 0">
			<div class="notification-item">
				<div class="message">
					<div class="text-center text-gray-600 font-italic p-4">
						<span>No notifications at this time</span>
					</div>
				</div>
			</div>
		</div>
		<div class="dropdown-item">
			<button class="btn btn-sm mr-2 bg-gray-100 text-blue-dark w-100 text-center" [routerLink]="'/notifications'">See all notifications</button>
		</div>
	</div>
	-->
</div>
<!--
<div *ngIf="aviaService?.mobile_mode" [routerLink]="'/notifications'" class="h-100 w-100">
	<ng-container *ngFor="let n of notifications;index as i;last as isLast">
		<div *ngIf="i<=5" class="p-2">
			<app-notify-feed *ngIf="n?.type_obj?.name === 'feed'" [notification]="n" (dismiss)="dismiss($event)" (goTo)="goToLink($event)"></app-notify-feed>

			<app-notify-comment *ngIf="(n?.type_obj?.name === 'KM card comment' ||
				n.type_obj?.name === 'KM chat' ||
				n.type_obj?.name === 'CL comment' ||
				n.type_obj?.name === 'Workspace chat' ||
				n.type_obj?.name === 'Channel chat' ||
				n.type_obj?.name === 'HS Action' ||
				n.type_obj?.name === 'Pulse Comment' ||
				n.type_obj?.name === 'Added to Workspace' ||
				n.type_obj?.name === 'Request to join channel')"
				[notification]="n"
				(dismiss)="dismiss($event)" (goTo)="goToCard($event)"
			></app-notify-comment>
			<app-notify-np-connect *ngIf="n.type_obj?.name === 'User connection'" [notification]="n" (dismiss)="dismiss($event)" (goTo)="goToMemberProfile($event)"></app-notify-np-connect>
			<hr class="mx-2 mb-0" *ngIf="!isLast" />
		</div>
	</ng-container>
	<div *ngIf="notifications?.length === 0">
		<div class="notification-item">
			<div class="message">
				<div class="text-center text-gray-600 font-italic p-4">
					<span>No notifications at this time</span>
				</div>
			</div>
		</div>
	</div>
	<div (click)="closeNotificationWindow()">
		<button class="btn btn-sm mr-2 bg-gray-100 text-blue-dark w-100 text-center" [routerLink]="'/notifications'">See all notifications</button>
	</div>
</div>
-->
