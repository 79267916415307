<div *ngIf="state!==''" class="page container-fluid">
	<div *ngIf="show_close" class="w-100 sticky-top bg-gray-100 p-4">
		<span class="mr-auto backlink pointer" (click)="back()">
			<i class="mr-4 material-icons md-24" style="vertical-align: top;">arrow_back</i>
		</span>
	</div>
  <div class="subpage">
    <div class="row">
      <div class="titleblock col-12">
        <h1>AVIA Connect Legal Information</h1>
      </div>
    </div>
    <div class="row">
      <div class="tocblock col-md-3 col-sm-12 col-12 push-md-9">
        <li *ngIf="state==='tos'" class="tocitem accent pointer"                          >AVIA Connect Terms of Service</li>
        <li *ngIf="state!=='tos'" class="tocitem        pointer" (click)="setState('tos')">AVIA Connect Terms of Service</li>
        <li *ngIf="state==='pri'" class="tocitem accent pointer"                          >AVIA Connect Privacy Policy</li>
        <li *ngIf="state!=='pri'" class="tocitem        pointer" (click)="setState('pri')">AVIA Connect Privacy Policy</li>
        <li *ngIf="state==='3rd'" class="tocitem accent pointer"                          >Third-Party Licenses</li>
        <li *ngIf="state!=='3rd'" class="tocitem        pointer" (click)="setState('3rd')">Third-Party Licenses</li>
      </div>
      <div *ngIf="state==='tos'" class="textblock col-md-9 col-sm-12 col-12 pull-md-3">
				<h2>AVIA Connect Terms of Service</h2>
				<p>Last Updated: January 30, 2020</p>
        <p>By clicking the "Sign in" or “Accept” button, the user ("You") agree(s) to be bound by the terms and conditions contained in this agreement (the "Agreement") and the terms and conditions of Abundant Venture Innovation Accelerator d/b/a AVIA, LLC's ("AVIA") Privacy Policy, which is published at <a class="accent ptr" href="connect.avia.health/privacy">connect.avia.health/privacy</a> and which is incorporated herein by reference. The Privacy Policy explains how AVIA treats your information, and protects your privacy when you use AVIA Connect. If you do not agree to be bound by these terms and conditions or Privacy Policy policies, you may not use AVIA Connect.</p>
        <h3>Modifications</h3>
        <p>You agree that we may modify this Agreement or any policy or other terms referenced in this Agreement at any time, without advance notice to you and at our sole discretion. By using AVIA Connect, you agree to be bound by any such modifications. You will note that at the bottom of this Agreement, the date of last modification is indicated. When we modify this Agreement, you will receive notice through AVIA Connect and will be required to “Accept” the modified terms and conditions before continuing to use AVIA Connect. If you are dissatisfied with the AVIA Connect site, its content or this Agreement as it may be revised, you agree that your sole and exclusive remedy is to discontinue using AVIA Connect.</p>
        <h3>Termination</h3>
        <p>You agree that AVIA may terminate this Agreement, for any reason at our sole discretion, at any time, by providing you seven (7) days advance notice, and that AVIA reserves the right to suspend, or discontinue all or any aspects of AVIA Connect, for any reason or no reason at all, at our sole discretion, at any time. You further agree that AVIA may terminate this Agreement for cause at our sole discretion at any time, immediately upon notice to you, if you breach the terms and conditions of this Agreement. Upon termination, You may request a copy of your organization’s data and AVIA agrees to provide such copy in a readable electronic format within a reasonable time.</p>
        <h3>Collection and Use of Information.</h3>
        <p>You acknowledge and agree that AVIA may, directly or indirectly through the services of third parties, collect and store information regarding use of AVIA Connect through collection methods built into AVIA Connect.</p>
        <p>You further agree that AVIA may use such information for any purpose related to any use of AVIA Connect or the services provided by AVIA, including but not limited to:</p>
        <ul>
          <li>improving the performance of AVIA Connect or developing updates to AVIA Connect.</li>
					<li>improving the quality of services provided by AVIA and its Associates.</li>
					<li>personalizing the experience for each user.</li>
					<li>verifying your compliance with the terms of this Agreement and enforcing AVIA's rights, including all Intellectual Property Rights in and to AVIA Connect.</li>
        </ul>

        <p>For further information, please see AVIA’s Privacy Policy, which is published at <a class="accent ptr" href="https://connect.avia.health/privacy">connect.avia.health/privacy</a>.</p>

        <h3>User Restrictions</h3>
        <p>In the use of AVIA Connect, you agree that:</p>
        <ul>
          <li>You will not utilize AVIA Connect unless authorized to do so directly by AVIA or pursuant to your employment or contractual relationship with an AVIA client;</li>
					<li>You will not access or attempt to access any account or login of any other user or third party listed on AVIA Connect;</li>
					<li>You will not breach or attempt to breach any security measures of AVIA Connect;</li>
					<li>You will not make illegal use of AVIA Connect or use it for purposes which are illegal;</li>
					<li>You will follow U.S. and international laws regarding transmitting data and you will not attempt to gain access to our computer system or any other computer systems;</li>
					<li>You will not remove, obscure, or alter any notice, logo, trademark, legend or other intellectual property or proprietary right designation appearing on or contained within AVIA Connect;</li>
					<li>You will not use any device, process, or mechanism (e.g., a spider or "bot") to monitor, retrieve, search, or access AVIA Connect without our prior written consent;</li>
					<li>You will not copy, modify, reproduce, delete, distribute, download, store, transmit, sell, re-sell, publish, reverse engineer, or create derivative works of any aspect of AVIA Connect;</li>
					<li>You will not tamper with or otherwise interfere or attempt to interfere in any manner with the functionality or proper working of AVIA Connect;</li>
					<li>You will not upload, post, transmit, or store any material that:</li>
          <ul>
            <li>contains any protected health information (as such term is used in the Health Insurance Portability and Accountability Act of 1996 ("HIPAA") and the HITECH Act provisions of the American Recovery and Reinvestment Act of 2009 ("HITECH");</li>
            <li>contains any personally identifiable information (PII), or sensitive personal information (SPI) as defined in applicable Federal or State privacy and security laws and regulations;</li>
            <li>is competitively sensitive or contains proprietary trade secrets;</li>
            <li>is unlawful, offensive, defamatory, fraudulent, deceptive, misleading, harmful, threatening, harassing, obscene, or objectionable;</li>
            <li>breaches any of your contractual or confidentiality obligations;</li>
            <li>disrupts or interferes with the normal operations of AVIA Connect, such as posting or transmitting viruses, continuous posting of repetitive materials, or posting and/or uploading abnormally large files or other data.</li>
					</ul>
        </ul>
				<p>AVIA reserves the right to make the determination that any information or conduct on the AVIA Connect platform violates this Agreement, in our sole discretion.</p>

        <h3>Good Behavior in the Community</h3>
        <p>In interacting with other community members, you agree that:</p>
        <ul>
					<li>You will not violate other's privacy rights or personal rights by abusing AVIA Connect or any information on AVIA Connect, including, but not limited to, harassing or "stalking" another person, sending unsolicited e-mails, or collecting other's personal information;</li>
					<li>You will not interfere with anyone else who is a user of AVIA Connect in their use of AVIA Connect;</li>
					<li>You will not post or submit any inaccurate, false, deceptively incomplete, unlawful, offensive, defamatory, fraudulent, misleading, harmful, threatening, harassing, obscene, or objectionable information, nor any other information that does not otherwise comply with this Agreement;</li>
					<li>You will not upload, post, transmit, or store any material that:</li>
					<ul>
						<li>is an advertisement or other solicitation, including, but not limited to, any unauthorized advertising materials, unsolicited promotional materials, "junk mail," "spam mail," "chain letters," "pyramid schemes," franchises, distributorships, club memberships, sales arrangements, or similar materials.</li>
					</ul>
				</ul>
				<p>AVIA reserves the right to make the determination that any information or conduct on the AVIA Connect platform violates this Agreement, in our sole discretion.</p>

        <h3>Passwords</h3>
        <p>You agree and understand that you are responsible for maintaining the confidentiality of passwords associated with any account you use to access AVIA Connect and that you are not permitted to allow any other person or entity to use your password for any purpose. Accordingly, you agree that you are solely responsible to AVIA for all activities that occur under your account. If you become aware of any unauthorized use of your password you will notify AVIA promptly at <a class="accent ptr" [href]="'mailto:'+EMAILADDY_SUPPORT">support@{{EMAILADDY_SUPPORT}}</a></p>
        <h3>Monitoring</h3>
        <p>We reserve the right to log, review, and otherwise examine any information stored on or passing through our networks, servers or systems.</p>
        <h3>Downtime and Service Suspensions</h3>
        <p>Your access to and use of AVIA Connect and related services may be suspended for the duration of any anticipated, unanticipated, scheduled or unscheduled downtime, maintenance, system updates, malfunction, or other unavailability of the AVIA Connect or any portion or all of such related services for any reason, including as a result of power outages, system failures or other interruptions.</p>
        <p>We shall also be entitled, without any liability to you, to suspend access to AVIA Connect or any related services at any time, on a system-wide basis: (a) for scheduled downtime to permit us to conduct maintenance or make modifications to AVIA Connect; (b) in the event of a denial of service attack or other attack on AVIA Connect or other event that we determine, in our sole discretion, may create a risk to AVIA Connect, to you or to any of our other clients if AVIA Connect service were not suspended; or (c) in the event that we determine that AVIA Connect or any related service is prohibited by law or we otherwise determine that it is necessary or prudent to do so for legal or regulatory reasons.</p>
        <h3>Rights to Use AVIA Connect</h3>
        <p>AVIA grants you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use AVIA Connect as part of the services being provided to you by AVIA. This license is for the sole purpose of enabling you to use and enjoy the benefit of AVIA Connect, in the manner permitted by these terms and conditions. You may not, and you may not permit anyone else to, copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to copy or extract the source code or object code of AVIA Connect or any part thereof. Except as expressly provided herein, AVIA has given you specific written permission to do so, you may not assign (or grant a sub-license of) your rights to use AVIA Connect, grant a security interest in or over your rights to use AVIA Connect, or otherwise transfer any part of your rights to use the AVIA Connect. Notwithstanding the foregoing, you are granted limited administrative rights to provide access to AVIA Connect in your reasonable discretion to those parties who would reasonably need to access information located on AVIC Connect, or who could upload information and documents onto AVIA Connect for you to access, that would assist you in utilizing the services provided by AVIA Connect in the ordinary course.</p>
        <h3>Intellectual Property</h3>
				<p>The content on AVIA Connect including without limitation, the text, software, scripts, graphics, photos, sounds, videos, interactive features and the like ("Content") and the trademarks, service marks and logos contained therein ("Marks"), are owned by or licensed to AVIA, subject to copyright and other intellectual property rights under United States and foreign laws and international conventions. Content on AVIA Connect is provided to you AS IS for your information and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners of the Content. AVIA reserves all rights not expressly granted in and to AVIA Connect and the Content. You agree to not engage in the use, copying, or distribution of any of the Content other than to other AVIA Connect users or other members of your organization unless expressly permitted herein. If you download or print a copy of the Content for personal use, you must retain all copyright and other proprietary notices contained therein. You agree not to circumvent, disable or otherwise interfere with security-related features of AVIA Connect or features that prevent or restrict use or copying of any Content or that enforce limitations on use of AVIA Connect or the Content therein.</p>
				<p>Names and logos of third parties (“Third Party Trademarks”) may appear on AVIA Connect. The presence of any Third Party Trademarks does not signal the endorsement, sponsorship, or affiliation of AVIA with any of the owners of applicable Third Party Trademarks. We cannot provide sublicensing rights for any Third Party Trademarks. If you are the owner of any Third Party Trademarks and wish for AVIA to remove your trademark(s) from AVIA Connect, please contact AVIA at <a class="accent ptr" [href]="'mailto:'+EMAILADDY_SUPPORT">support@{{EMAILADDY_SUPPORT}}</a> and we will assist you.</p>
        <h3>Copyright and Trademark Policies</h3>
        <p>It is AVIA's policy to respond to notices of alleged copyright infringement that comply with applicable international intellectual property law (including, without limitation, in the United States, the Digital Millennium Copyright Act) and to terminate the accounts of repeat infringers.</p>
        <h3>Other Content; Limitation of Liability and Warranty Disclaimers</h3>
        <p>AVIA Connect may include hyperlinks to other websites or content or resources that are not owned or controlled by AVIA. AVIA has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party websites or resources which are provided by companies or persons other than AVIA. You acknowledge and agree that AVIA is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products, or other materials on or available from such websites or resources unless specifically denoted. You acknowledge and agree that AVIA is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products, or other materials on, or available from such websites or resources. We encourage you to be aware when you leave AVIA Connect and to read the terms and conditions and privacy policy of each other website that you visit.</p>
				<p>AVIA Connect may also include content and information that third parties have submitted to AVIA Connect regarding other companies, their areas of industry focus and services provided, names and titles of individuals affiliated with them, and other related business information, as well as questions and answers, reviews of services, and personal profile information, either in a private messenger app as well as openly posted on AVIA Connect (“Third Party Content”). AVIA has no control over, and assumes no responsibility for any such Third Party Content. You acknowledge and agree that AVIA is not responsible for the availability of Third Party Content and that AVIA does not endorse any advertising, products, or other materials on or available from third parties posting or submitting Third Party Content unless specifically denoted. You acknowledge and agree that AVIA is not liable for any loss or damage which may be incurred by you as a result of the availability or accuracy of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any Third Party Content.</p>
				<p>In the event that you provide Third Party Content to AVIA Connect, you are solely responsible for the accuracy and completeness of any such Third Party Content. AVIA will not review or approve Third Party Content prior to publication. However, AVIA reserves the right to delete or revise any inaccurate, false, deceptively incomplete, unlawful, offensive, defamatory, fraudulent, misleading, harmful, threatening, harassing, obscene, or objectionable Third Party Content, or any Third Party Content that does not otherwise comply with this Agreement, from the AVIA Connect platform, in our sole discretion, without compensation or obligation to you. AVIA will not retain or store any deleted or revised Third Party Content and is not responsible for any losses you may experience as a result of deletion.</p>
				<p>Endorsements of services or organizations, whether by badges, reviews, references or other Third Party Content posted on AVIA Connect by one user or organization, do not imply endorsement, sponsorship or affiliation with or by AVIA or other users or organizations.</p>
				<p>AVIA Connect may also include software, content, data, or other materials, including related documentation, that are owned by persons other than AVIA and that are provided to AVIA on licensee terms that are in addition to and/or different from those contained in this Agreement ("Third-Party Licenses"). A list of all materials, if any, included in AVIA Connect and provided under Third-Party Licenses can be found at connect.avia.health/3rdparty and the applicable Third-Party Licenses are accessible via links therefrom. You agree to be bound by and comply with all Third-Party Licenses. Any breach by you of any Third-Party License is also a breach of this Agreement.</p>
				<b>EXCEPT AS SPECIFICALLY SET FORTH IN THIS AGREEMENT, AVIA MAKES NO OTHER REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO AVIA CONNECT OR ANY CONTENT THEREON OR SERVICES RELATED THERETO. YOU AGREE THAT USE OF AVIA CONNECT IS AT YOUR SOLE RISK. AVIA CONNECT IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.</b>
				<p>Neither AVIA nor its members, agents, affiliates, or designees nor each of their respective officers, directors, employees, agents, contractors, subcontractors or the like (collectively, “Associates”) warrant that use of AVIA Connect will be uninterrupted or error-free.</p>
				<p>Under no circumstances shall AVIA or its Associates be liable for any direct, indirect, incidental, special, exemplary, punitive or consequential damages that result from, arise out of or otherwise relate to your use of or inability to use AVIA Connect, or any website linked to AVIA Connect, including but not limited to reliance by you on any information obtained from AVIA Connect that results in mistakes, omissions, interruptions, deletion or corruption of files, viruses, delays in operation or transmission, or any failure of performance, or any loss of profit, loss of goodwill or data, or cost of procurement of substitute goods or services or other intangible loss suffered.</p>
				<p>AVIA Connect utilizes industry accepted security systems in an attempt to eliminate unauthorized access to private information. By using AVIA Connect, you expressly agree to not hold AVIA or its Associates liable in the event of unauthorized access and subsequent use of your company and/or personal information. The foregoing Limitation of Liability shall apply in any action, whether in contract, tort or any other claim, even if an authorized representative of AVIA has been advised of or should have knowledge of the possibility of such damages.</p>

        <h3>Indemnity</h3>
        <p>You agree to defend, indemnify and hold harmless AVIA and any of its Associates, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to reasonable attorney's fees) arising from: (i) your use of or access to AVIA Connect; (ii) your violation of any term of this Agreement; or (iii) your violation of any third party right, including without limitation any privacy, copyright or other intellectual property right other than as a result of an action or omission of AVIA including any infringement of third party rights by AVIA connect. This defense and indemnification obligation will survive this Agreement and your use of AVIA Connect.</p>
        <h3>Notices</h3>
        <p>All Notices by us to you shall be by email to the email address listed as AVIA Connect Administrator. All notices by you to us shall be by email to <a class="accent ptr" [href]="'mailto:'+EMAILADDY_SUPPORT">support@{{EMAILADDY_SUPPORT}}</a> or in writing to:</p>
        <p>AVIA<br></p>
				<p>515 N. State St.,<br></p>
        <p>Suite 300<br></p>
        <p>Chicago, IL 60654</p>
        <h3>Entire Agreement</h3>
        <p>This Agreement, including AVIA's Privacy Policy incorporated herein, constitutes the entire agreement between you and us regarding the subject matter hereof and supersedes any and all prior or contemporaneous representation, understanding, agreement, or communication between you and us, whether written or oral, regarding such subject matter.</p>
        <h3>Severability</h3>
        <p>If any portion of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, the remaining portions of this Agreement will remain in full force and effect, and any invalid or unenforceable portions shall be construed in a manner that most closely reflects the effect and intent of the original language. If such construction is not possible, the provision will be severed from this Agreement, and the rest of the Agreement shall remain in full force and effect.</p>
        <h3>Waivers</h3>
        <p>The failure by us to enforce any provision of this Agreement shall in no way be construed to be a present or future waiver of such provision nor in any way affect our right to enforce such provision thereafter. All waivers by us must be in writing to be effective.</p>
        <h3>Successors and Assigns</h3>
        <p>This Agreement will be binding upon, and inure to the benefit of the parties and their respective successors and assigns.</p>
        <h3>Assignment</h3>
        <p>This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by AVIA without restriction.</p>
        <h3>Relationship</h3>
        <p>Nothing in this Agreement is intended to or does create any type of joint venture, creditor-debtor, escrow, partnership or any employer/employee or fiduciary or franchise relationship between you and us (or any of our Associates).</p>
        <h3>Applicable Law and Jurisdiction</h3>
        <p>AVIA Connect, including <a class="accent ptr" href="https://connect.avia.health">connect.avia.health</a> website (excluding links to websites operated by others) is controlled and operated by AVIA from offices in Chicago, Illinois. Although AVIA has made no effort to publish AVIA Connect, including <a class="accent ptr" href="https://connect.avia.health">connect.avia.health</a> website elsewhere, it is accessible in all fifty U.S. states and in other countries due to its presence on the Worldwide Web. As each of these states and countries have laws which may differ from those of Illinois and from each other, and as you and AVIA both benefit from establishing a predictable legal environment in which to publish, access and use AVIA Connect, including <a class="accent ptr" href="https://connect.avia.health">connect.avia.health</a> website, by publishing, accessing, and/or using the sites you agree that all matters arising from or relating to the use and operation of the sites will be governed by the laws of the State of Illinois, without regard to its conflict of laws principles. You agree that all claims you may have arising from or relating to the operation or use of AVIA Connect, including <a class="accent ptr" href="https://connect.avia.health">connect.avia.health</a> website will be heard and resolved in the courts of Cook County, Illinois. You consent to personal jurisdiction of such courts over you, stipulate to the fairness and convenience of proceeding in such courts, and covenant not to assert any objection to proceeding in such courts. The foregoing not withstanding you agree that AVIA shall be entitled to apply for any necessary injunctive remedies in any jurisdiction. If you choose to access the <a class="accent ptr" href="https://connect.avia.health">connect.avia.health</a> website from locations other than the State of Illinois, you will be responsible for compliance with all local laws of such other locations.</p>

        <p>Last Modified January 30, 2020</p>
      </div>
      <div *ngIf="state==='pri'" class="textblock col-md-9 col-sm-12 col-12 pull-md-3">
				<h2>AVIA Connect Privacy Policy</h2>
				<p>Effective Date/Last Updated: January 30, 2020</p>
        <p>In order to use AVIA Connect, you are required to review these policies (the “Privacy Policy”). By using the AVIA Connect platform (“AVIA Connect”) or communicating with us by e-mail, you agree to the terms and conditions of this Privacy Policy and to the terms of use of AVIA Connect as set forth in the Terms of Service (“TOS”). By using AVIA Connect or visiting any website owned or operated by Abundant Venture Innovation Accelerator, LLC d/b/a AVIA (“AVIA”), you hereby agree to be bound by the terms and conditions contained in this Privacy Policy, and by the terms and conditions of TOS, which is published at <a class="accent ptr" href="https://connect.avia.health/termsofservice">connect.avia.health/termsofservice</a> and incorporated herein by reference. This Privacy Policy explains how AVIA treats your personal information, and protects your privacy, when you use AVIA Connect. AVIA reserves the right to change this Privacy Policy and terms of TOS at any time without notice. Any changes to this Privacy Policy or TOS shall be posted in this page and shall be effective immediately upon posting. You must be at least 18 years old to use AVIA Connect or any other AVIA services, applications, and websites. If you do not agree to be bound by the policies and practices contained in this Privacy Policy or by TOS, you may not use AVIA Connect.</p>
        <h3>Types of Information We Collect</h3>
        <p>We collect information you provide when you use AVIA Connect, including when you sign up for an account, create or share content, and connect, message or communicate with others. We also collect information about how you use AVIA Connect, such as the types of content you view or engage with or the frequency and duration of your activities. We also collect information when you fill out forms in the application. This information includes but is not limited to the following:</p>
        <ul>
					<li>Organization name, logo and basic demographics</li>
					<li>User affiliation with and/or membership of one or more organizations</li>
					<li>User name, title, contact information and basic demographics</li>
					<li>User interest and/or experience with topics, companies and products</li>
					<li>User connections and communications with other users</li>
					<li>User activity on the platform, including page views</li>
					<li>User views, likes, shares and bookmarks on content</li>
					<li>User participation in groups, workspaces, channels and events</li>
					<li>User comments, questions, answers, reviews and feedback</li>
					<li>Organizational capability assessment data (through the Digital Pulse)</li>
					<li>User and organizational priority topic data</li>
					<li>User and organizational interest and/or experience data</li>
					<li>The programs and workspaces you and your organization participate in</li>
					<li>The companies and products you and your company are considering or using</li>
        </ul>
        <h3>Device Information</h3>
        <p>We may collect information from or about the computers, phones, or other devices where you install or access our Services, depending on the permissions you’ve granted. We may associate the information we collect from your different devices, which helps us provide consistent Services across your devices. Here are some examples of the device information we may collect:</p>
        <ul>
					<li>Attributes such as the operating system, hardware version, device settings, file and software names and types, battery and signal strength, and device identifiers.</li>
					<li>Device locations, including specific geographic locations, such as through GPS, Bluetooth, or WiFi signals.</li>
					<li>Connection information such as the name of your mobile operator or ISP, browser type, language and time zone, mobile phone number and IP address.</li>
        </ul>
        <h3>Use of Data Collected </h3>
				<p>Nonpublic data about you may be used to provide AVIA Connect and the AVIA website to you, for editorial and feedback purposes, to provide you with information or services that you request from us, to carry out any transactions (such as billing, if applicable) that you instruct us to, to personalize your experience in AVIA Connect, and for any other purpose with your consent. It will also be used in statistical analysis of users' behavior and to improve the product.</p>
				<p>We may use data collected from users and organizations on AVIA Connect to create, distribute, and publish aggregated reports. Such reports may include a variety of data types, including but not necessarily limited to page view, interests, Digital Pulse capability assessment ratings, adoption data, and user reviews data. Reports shall not include personal information, such as name, username, or email address.</p>
				<p>We may provide a messenger tool embedded within AVIA Connect. Messages sent or received through the messenger tool will be readable only by the individuals present in the message thread, though AVIA, its employees and its authorized contractors reserve the right to access such content in the scope of the performance of their work, solely for internal needs such as maintenance of the application or enforcement of the TOS.</p>
				<p>Information may also be made available to contractors as needed for maintenance of our systems / infrastructure.</p>
        <h3>Parties To Whom We Disclose Information</h3>
        <p>AVIA employees and contractors will have access to all data in the course of doing their work with AVIA members and through AVIA Connect or otherwise.</p>
        <p>Users of AVIA Connect will have access to a wide array of data related to and relevant for their own organization. This includes, but is not limited to, information about your organization provided by users from that organization and/or other organizations, such as firmographic data, assessment data, interest data, priorities data, activity and/or event participation data, adoption data, reviews data, users data and user uploaded files.</p>
				<p>Users of AVIA Connect will have access to a limited view of data related to other organizations and users from other organizations. Organizations may further limit (or extend) access to their data through their settings.</p>
				<p>Users of AVIA Connect will have access to aggregated reports and anonymized comparison data. AVIA publishes reports, analysis and articles on aggregated and/or anonymized data in AVIA Connect and through other channels.</p>
				<p>More generally, we may disclose personal information that we collect or you provide as described in this privacy policy to our subsidiaries and affiliates, to contractors, service providers, and other third parties we use to support our business, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of AVIA’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by AVIA about our website users is among the assets transferred; to fulfill the purpose for which you provide it; for any other purpose disclosed by us when you provide the information; or otherwise with your consent.</p>
				<p>We may also disclose your personal information to comply with any court order, law, or legal process, including to respond to any government or regulatory request; to enforce or apply our TOS and other agreements, including for billing and collection purposes; or if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of AVIA, our members, or others.</p>
        <h3>Use of Cookies</h3>
        <p>We may use "cookies" on our websites to personalize and enhance your website visits. A cookie is a text file that is placed on your hard drive by our web server. Cookies are uniquely assigned to a computer, and your computer will only allow our web servers to access our cookies. The most important thing to understand about cookies is that they pose no danger to you. They cannot be used to collect secret information about you or your computer or to provide information about you to other companies.</p>
        <p>Cookies provide a convenience by saving you time on subsequent visits and personalizing your web experience. For example, when you return to our website, cookies enable us to remember information from prior visits, and cookies may save data such as name, password, user-name, screen preferences, and pages viewed.</p>
        <p>You have the right to delete cookies placed on your hard drive, which will erase the data automatically saved during your visit. However, that will mean that our sites will not recognize you when you return. You may also modify your web browser to notify you before accepting cookies, or to decline all cookies. We strongly suggest that you accept cookies from us, since they help provide you a more seamless, personalized, and effective web browsing experience and keep our site compelling and informative.</p>
				<p>AVIA Connect or other AVIA content or applications may use third-party tools to track usage on and performance of the platform. These third parties may use cookies, alone or in conjunction with web beacons or other tracking technologies, to collect information about when you use our website and platforms. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. We do not control these third parties' tracking technologies or how they may be used. However, we do not allow such third parties access to AVIA Connect or to advertise on AVIA’s platforms and websites.</p>
				<h3>Links to Other Web Sites </h3>
        <p>AVIA Connect or related websites may contain links to other sites that are not owned or controlled by AVIA. We are not responsible for the content or privacy practices of other sites. This Privacy Policy applies only to AVIA Connect.</p>
        <p>AVIA Connect, including the <a class="accent ptr" [href]="'mailto:'+EMAILADDY_CONNECT">support@{{EMAILADDY_CONNECT}}</a> website, is confidential and proprietary to AVIA. All of the text, images, marks, logos and other content of this website is proprietary to AVIA or is used with the owner's permission or applicable law or regulation.</p>
				<h3>Data Security</h3>
				<p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All personal information you provide to us as well as account passwords are encrypted via industry-standard tools, both when stored in AVIA databases and repositories and when in transit from your computer to our data storage. We employ safeguards against cross-site scripting attacks and use role-based authorization to limit access to private messaging, health system data, and other content intended only for particular users.</p>
				<p>Where a password may be necessary for access to certain parts of AVIA Connect or our website, you are responsible for keeping this password confidential. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our website. AVIA Connect members or other AVIA users should not share HIPAA-protected patient information, including protected health information as that term is defined in HIPAA (“PHI”) on AVIA Connect or any AVIA services. We are not responsible for any breaches of HIPAA requirements or unauthorized disclosure of PHI on our website or applications, including AVIA Connect. </p>
				<p>Any transmission of your own personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on AVIA Connect or the website.</p>
				<h3>Changes to this Privacy Policy</h3>
				<p>We reserve the right to modify this Privacy Policy at any time and at our sole and absolute discretion.  If we do decide to change our Privacy Policy, we will post those changes on this page. If we make a significant or material change in the way we use your personal information, the change will be posted on this page thirty (30) days prior to taking effect and will notify members via email. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
				<p>This Privacy Policy was last modified on 1/30/2020 and supersedes and replaces any prior AVIA Privacy Policy.</p>
				<h3>Contact Us</h3>
				<p>For any other questions regarding this Privacy Policy, please consult the TOS or contact us at <a class="accent ptr" [href]="'mailto:'+EMAILADDY_SUPPORT">support@{{EMAILADDY_SUPPORT}}</a> or write us at:</p>
				<p>AVIA<br></p>
				<p>515 N. State St.,<br></p>
        <p>Suite 300<br></p>
				<p>Chicago, IL 60654</p>
				<h3>Your California Privacy Rights</h3>
				<p>Under California law, website operators are required to disclose how they respond to web browser “do not track” signals or other similar mechanisms that provide consumers with the ability to exercise choice regarding the collection of personally identifiable information of a consumer over time and across third party websites and apps, to the extent the operator engages in that collection.  At this time, we do not track our customers’ personally identifiable information over time and across third-party websites and apps; and therefore, we do not respond to Do Not Track (DNT) signals.  This law also requires website and app operators to disclose whether third parties may collect personally identifiable information about their users’ online activities over time and across different websites and apps when the users use the operator’s website or app.  We do not knowingly permit third parties to collect personally identifiable information about an individual consumer’s online activities over time and across different websites and apps when a consumer uses our website.  To learn more about how Do Not Track signals work, please visit <a class="accent ptr" href="http://allaboutdnt.com/">http://allaboutdnt.com/</a>.</p>
				<p>California residents may request to review changes to their personally identifiable information as collected by AVIA by contacting us at <a class="accent ptr" [href]="'mailto:'+EMAILADDY_INFO">support@{{EMAILADDY_INFO}}</a>. We reserve the right to verify your identity, including your status as a California resident, prior to providing any information, including personally identifiable information. As of the date of this Privacy Policy, AVIA is not subject to the provisions of the California Consumer Privacy Act (“CCPA”). If that changes, AVIA will update this Privacy Policy accordingly.</p>
      </div>
      <div *ngIf="state==='3rd'" class="textblock col-md-9 col-sm-12 col-12 pull-md-3">
        <h2>Third-Party Licenses</h2>
        <p>AVIA Connect may also include software, content, data, or other materials, including related documentation, that are owned by persons other than AVIA and that are provided to AVIA on licensee terms that are in addition to and/or different from those contained in this Agreement ("Third-Party Licenses").</p>
        <p>Below is a list of all materials, if any, included in AVIA Connect and provided under Third-Party Licenses. The applicable Third-Party Licenses are accessible via links in the list below. When you sign in to AVIA Connect, you agree to be bound by and comply with all Third-Party Licenses. Any breach by you of any Third-Party License is also a breach of this Agreement.</p>
        <p>Current and planned:</p>
        <ul>
          <li>Twitter. AVIA allows users to link their accounts to Twitter. <a class="accent ptr" href="https://twitter.com/tos?lang=en">https://twitter.com/tos?lang=en</a></li>
          <li>LinkedIn. AVIA allows users to link their accounts to LinkedIn. <a class="accent ptr" href="https://www.linkedin.com/legal/user-agreement">https://www.linkedin.com/legal/user-agreement</a></li>
					<li>CrunchBase. AVIA pulls some solution company profile information from CrunchBase and provides links to the CrunchBase website. <a class="accent ptr" href="https://about.crunchbase.com/docs/legal-terms/">https://about.crunchbase.com/docs/legal-terms/</a></li>
					<li>Definitive. AVIA pulls some hospital and health system profile information from Definitive. <a class="accent ptr" href="https://www.definitivehc.com/terms-of-use">https://www.definitivehc.com/terms-of-use</a></li>
        </ul>
        <h3>Last Revision Date</h3>
        <p>This Privacy Policy was last revised on December 17, 2019.</p>
          <BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR><BR>
      </div>
    </div>
  </div>
</div>
