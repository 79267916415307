import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AVIAConnectService } from '../../../avia-connect.service';
import { Common } from '../../../common';

import {
  AccessKey,
  Color_Library,
  E_ConnectionManagerActions,
  InvItem,
  Org
} from '../../../../class';


@Component({
  selector: 'app-avia-inv-network-view',
  templateUrl: './avia-inv-network-view.component.html',
  styleUrls: ['./avia-inv-network-view.component.scss', '../avia-network.scss']
})

// JAW
export class AviaInvNetworkViewComponent implements OnInit {
  readonly VERBOSE: boolean = false;
  @Input() inv_id: number;
  @Input() view_type: string = 'box'; //box or card

  public inv_item: InvItem = new InvItem();

  public loading:    boolean = false;
  public inv_support:            any;
  public color:      string = Color_Library.gray_400;
  public color_dark: string = Color_Library.gray_600;

  @Output('closeModal') closeModal = new EventEmitter();
  @Output() onConnect: EventEmitter<any> = new EventEmitter();

  private CONNECTIONMANAGERACTIONS: E_ConnectionManagerActions = new E_ConnectionManagerActions();

  constructor(public aviaService: AVIAConnectService) { };

  ngOnInit() {
    this.init(this.inv_id);
  }

  // *** INIT ***
  async init(inv_id) {
    this.VERBOSE && console.log('inv_id:', inv_id);

    this.loading = true;

    let [
      inv_res,
      inv_support_res,
    ] = await Promise.all([
      this.aviaService.getInventoryItemPeek(inv_id),
      this.aviaService.getInventorySupport(),
    ]);

    this.inv_item = inv_res.body;
    this.inv_support       = inv_support_res.body.data;
    this.initUserAccess(this.inv_item.rd)

    // we were getting a crash on hs_id_obj not always being defined
    // this way if there is hs_id_obj it will try to use that color to set colors on page
    // if it doesn't exist it will still set colors with the default on the else
    if (this.inv_item.hs_id_obj == undefined) {
      this.setColors(undefined)
    } else {
      this.setColors(this.inv_item.hs_id_obj.color)
    }

    this.VERBOSE && console.log('inv_item:', this.inv_item);
    this.VERBOSE && console.log('inv_support:', this.inv_support);

    this.loading = false;
  }

  // *** COLORS ***
  setColors(color) {
    if (color) {
      this.color = `#${color}`;
      this.color_dark = Common.changeColor(this.color, -0.2);
    } else {
      this.color = Color_Library.blue_light;
      this.color_dark = Color_Library.blue_dark;
    }
  };

  close(){
    this.closeModal.emit(true)
  }

  async connectUser(user_id){
    try {
      await this.aviaService.manageUserConnection( E_ConnectionManagerActions.CONNECT, user_id, {message: null} );
    } catch (err) {
      console.error('connectWithUser error');
      console.log( err );
    }
    let inv_res = await this.aviaService.getInventoryItemPeek(this.inv_id);
    this.inv_item = inv_res.body;
    this.onConnect.emit();
  }

  public akey: AccessKey = new AccessKey();

  private async initUserAccess(resource_desc: Object = {}, forceReload: boolean = false) {
    let session = await this.aviaService.getSessionSupport(forceReload);
    this.akey = this.aviaService.hasAccess(session, 'inv', 'core', 'crwd', resource_desc);
    // console.log(this.akey)
  };

}
