import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-avia-email-toggle-row',
  templateUrl: './avia-email-toggle-row.component.html',
  styleUrls: ['./avia-email-toggle-row.component.scss']
})
export class AviaEmailToggleRowComponent {
  @Input() key:   string;
  @Input() label: string;
  @Input() value: boolean;

  @Input() link?: string = undefined;

  @Output('toggled') toggled:EventEmitter<any> = new EventEmitter();

  constructor() { }

  toggle($event) {
    let value, updateObj = { };
    $event.value === true ? value = 1 : value = 0;
    updateObj[this.key] = value;

    this.toggled.emit(updateObj);
  }

}
