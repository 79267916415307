import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { AVIAConnectService } from './../../avia-connect.service';
import { AppComponent } from './../../app.component';

@Component({
  selector: 'app-eulabox',
  templateUrl: './eulabox.component.html',
  styleUrls: ['./eulabox.component.scss']
})
export class EulaboxComponent implements OnInit {
  @ViewChild("eula_box", { static: true }) modalRefTemplate:TemplateRef<any>;
  modalRef: NgbModalRef;
  onAccept: Function;
  state:    string = '';

  constructor( private modalService: NgbModal, private aviaService: AVIAConnectService) {
    this.aviaService.eulabox = this;
  }

  open( onAccept:Function ) {
    if(this.modalRef) this.modalRef.close();
    this.modalRef = this.modalService.open(this.modalRefTemplate, { windowClass: 'avia-modal-backdrop get-on-top-3', backdropClass: 'get-on-top-2', 'backdrop': 'static' });
    this.onAccept = onAccept;
  }
  accept() {
    this.aviaService.logEula().then( r => {
      if (this.onAccept !== undefined)
        this.onAccept();
      this.modalRef.close();
    },
    e => {
      console.log( "ERROR in EulaboxComponent, aviaService.logEula FAILED" );
      this.modalRef.close();
    });
  }

  signOut() {
    this.aviaService.signOut().then( r => {
      this.modalRef.close();
      location.reload();
    },
    e => {
      console.log( "ERROR in EulaboxComponent, aviaService.logEula FAILED" );
      this.modalRef.close();
      location.reload();
    });
  }

  setState( s:string ) {
    //console.log( "parent set [" + s + "]"  );
    this.state = s;

    if (this.modalRef !== undefined) {
      let winclass = s === '' ? 'avia-modal-backdrop' : 'avia-modal-fullscreen';
      //console.log( " - [" + winclass + "]" );
      this.modalRef.close();
      this.modalRef = this.modalService.open(this.modalRefTemplate, { windowClass: 'avia-modal-backdrop get-on-top-3', backdropClass: 'get-on-top-2', 'backdrop': 'static' });
    }
  }

  ngOnInit() {
  }
}
