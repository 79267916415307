import { DummyComponent } from './shell/dummy/dummy.component';
import { TempUniversalAddComponent } from './shell/temp-universal-add/temp-universal-add.component';
import { SignOutComponent } from './shell/sign-out/sign-out.component';
import { SuggestedPageComponent } from './_components/avia-suggested/suggested-page/suggested-page.component';
import { SsoLinkComponent } from './signin-box/sso/sso-link/sso-link.component';

import { SsoVerifyComponent } from './signin-box/sso/sso-verify/sso-verify.component';

// angular2
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// avia components
import { AboutPageComponent } from './shell/about-page/about-page.component';
import { AuthGuardService } from './auth-guard.service';
import { CanDeactivateGuardService } from './can-deactivate-guard.service';
import { CbSearchComponent } from './cb-search/cb-search.component';

import { EULAComponent } from './shell/eula/eula.component';
import { FeedbackComponent } from './shell/feedback/feedback.component';
import { LoadTestingPageComponent } from './shell/load-testing-page/load-testing-page.component';
import { LoadTestingPage2Component } from './shell/load-testing-page2/load-testing-page2.component';
import { NewsfeedComponent } from './newsfeed/newsfeed.component';
import { SigninBoxComponent } from './signin-box/signin-box.component'
import { LnSigninComponent  } from './signin-box/ln-signin/ln-signin.component'
import { TestFirewallComponent } from './shell/test-firewall/test-firewall.component'
import { UserSettingsComponent } from './shell/user-settings/user-settings.component';
import { NotificationsPageComponent } from './notifications/notifications-page/notifications-page.component';
import { PinnedLocationsPageComponent } from './shell/pinned-locations-page/pinned-locations-page.component';

const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  { path: 'about', component: AboutPageComponent, canActivate: [AuthGuardService] },
  { path: 'add', component: TempUniversalAddComponent, canActivate: [AuthGuardService] },
  { path: 'admin', loadChildren: () => import('./admin-page/admin-page.module').then(m => m.AdminPageModule), canLoad: [AuthGuardService] },
  { path: 'cbsearch', component: CbSearchComponent, canActivate: [AuthGuardService]},
  { path: 'dashboard', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule), canLoad: [AuthGuardService] },
  { path: 'developer', loadChildren: () => import('./developer/developer.module').then(m => m.DeveloperModule), canLoad: [AuthGuardService]},
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuardService] },
  { path: 'home', redirectTo: 'start', pathMatch: 'full' },
  { path: 'profiles/in/:id', canActivate: [AuthGuardService], component:DummyComponent },
  { path: 'profiles/:id', canActivate: [AuthGuardService], component:DummyComponent},
  { path: 'intelligence/km/:type', component:DummyComponent, canActivate: [AuthGuardService]},
  { path: 'intelligence/cl', component:DummyComponent, canActivate: [AuthGuardService]},
  { path: 'intelligence/:_1/:t_2', component:DummyComponent, canActivate: [AuthGuardService] },
  { path: 'insights', loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule), canLoad: [AuthGuardService] },
  { path: 'members', loadChildren: () => import('./members/members.module').then(m => m.MembersModule) },
  { path: 'notifications', component: NotificationsPageComponent, canActivate: [AuthGuardService] },
  { path: 'privacy', component: EULAComponent },
  { path: 'pulse', loadChildren: () => import('./pulse/pulse.module').then(m => m.PulseModule), canLoad: [AuthGuardService] },
  { path: 'register', redirectTo: 'admin', pathMatch: 'full' },
  { path: 'sc/:id', component:DummyComponent, canActivate: [AuthGuardService] },
  { path: 'sc/:id/product/:product_id', component:DummyComponent, canActivate: [AuthGuardService] },
  { path: 'search', component: DummyComponent },
  { path: 'settings', component: UserSettingsComponent, canActivate: [AuthGuardService] },
  { path: 'signout', component: SignOutComponent },
  { path: 'signin', component: SigninBoxComponent },
  { path: 'ln-signin', component: LnSigninComponent },
  { path: 'signin/sso/verify/:email_token', component: SsoVerifyComponent },
  { path: 'signin/sso/link/:sso_temp', component: SsoLinkComponent },
  { path: 'signin/:workflow', component: SigninBoxComponent },
  { path: 'signin/:workflow/:phase', component: SigninBoxComponent },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)},
  { path: 'start', component: NewsfeedComponent, canActivate: [AuthGuardService] },
  { path: 'suggested', component: SuggestedPageComponent, canLoad: [AuthGuardService] },
  { path: 'testfirewall', component: TestFirewallComponent },
  { path: 'termsofservice', component: EULAComponent },
  { path: '12378945ASDFJHK-45789-ZCV-AVIA-134579-LTP1', component: LoadTestingPageComponent },
  { path: '12345789ICVHNDQ-93481-ZCV-AVIA-165845-LTP2', component: LoadTestingPage2Component },
  { path: '3rdparty', component: EULAComponent },
  { path: '**', redirectTo: 'start', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      APP_ROUTES, {
        //enableTracing: true, // Really verbose. Great for debugging routing issues
        //preloadingStrategy: PreloadAllModules,

        scrollPositionRestoration: 'enabled', // restore scroll position on back button

        // set up automatic smooth scroll for # anchor hrefs
        // for manual smooth scrolling, use nativeElement.scrollIntoView({behavior: "smooth"})
        anchorScrolling: 'enabled',

        //scrollOffset: [0, 0], // [x, y]
      }
    )
  ],
  exports: [RouterModule],
  providers: [AuthGuardService, CanDeactivateGuardService]
})
export class FrontRoutingModule {  }
