import { E_AlertModalTypes } from './../../../../class';
import { AviaAlertModalComponent } from './../../../_components/avia-alert-modal/avia-alert-modal.component';
import { FeedService } from './../../feed.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {
  @Input() comment: any = {};
  @Input() show_a: boolean = false;
  @Input() header: string = '';
  @Input() avatar_size: string = 'sm';
  @Input() thread_visibility: any = [];

  @ViewChild('alertModal', { static: false }) alert_modal: AviaAlertModalComponent;
  readonly E_AlertModalTypes = E_AlertModalTypes;

  editing: boolean = false;

  constructor(
    public fs: FeedService
  ) { }

  ngOnInit() {
  }

  toggleEditing(){
    this.editing = !this.editing
  }

  openAlertModal() {
    this.alert_modal.open();
  }

  async deleteComment(event) {
    await this.fs.deletePost(this.comment);
    this.alert_modal.close();
  }

  updateComment(comment) {
    if(comment) {
      this.comment = comment;
    }
    this.toggleEditing();
  }

}
