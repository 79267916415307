<div class="border border-gray-300 h-100 text-center topic-card p-4 p-lg-5 d-flex flex-column w-100">
	<div class="mx-auto rounded p-4 p-lg-5 lh-0" [ngSwitch]="topic?.type" [ngClass]="{
		'bg-blue-pulse': (topic?.type == 1),
		'bg-orange-dark': (topic?.type == 2),
		'bg-teal-dark': (topic?.type == 3),
		'bg-violet-dark': (topic?.type == 4),
		'bg-green-dark': (topic?.type == 5),
		'bg-red-dark': (topic?.type == 6)
	}">
		<i *ngSwitchCase="1" class="material-icons text-white topic-icon">extension</i>
		<i *ngSwitchCase="2" class="material-icons text-white topic-icon">vpn_key</i>
		<i *ngSwitchCase="3" class="material-icons text-white topic-icon">important_devices</i>
		<i *ngSwitchCase="4" class="material-icons text-white topic-icon">trending_up</i>
		<i *ngSwitchCase="5" class="material-icons text-white topic-icon">build</i>
		<i *ngSwitchCase="6" class="material-icons text-white topic-icon">insert_chart</i>
	</div>
	<div class="heading-size-16 text-center pt-4">{{ topic?.name }}</div>
	<div *ngIf="trending_new && topic.users_count" class="font-size-12 pt-2 pt-md-4"><span class="font-weight-bold">Trending</span> topic with {{ topic?.users_count }} new followers</div>
	<div *ngIf="trending_new && topic.related_count" class="font-size-12 pt-2 pt-md-4"><span class="font-weight-bold">Related</span> to {{ topic?.related_count }} topics you’re following</div>
	<ng-container *ngIf="!trending_new">
		<div class="font-size-12 pt-2 pt-md-4" [ngSwitch]="topic?.type">
			<span *ngSwitchCase="1">Capability </span>
			<span *ngSwitchCase="2">Solution </span>
			<span *ngSwitchCase="3">Technology </span>
			<span *ngSwitchCase="4">Trend </span>
			<span *ngSwitchCase="5">Competency </span>
			<span *ngSwitchCase="6">Metric </span>
			with {{ topic?.num_likes}} followers
		</div>
	</ng-container>
	<div class="w-100 mt-auto pt-2 text-center follow-btn-div">
		<button
		class="btn d-inline-flex align-items-center py-1 px-2 follow-btn justify-content-center"
		[ngClass]="{'btn-gray-200': !topic?.following, 'btn-blue-pulse': topic?.following, 'flex-fill': aviaService.mobile_mode}"
		(click)="followClicked()">
		<i [ngClass]="{'text-blue-pulse': !topic?.following, 'text-white': topic?.following}"
			class="material-icons md-16  center-btn-text mr-1">star</i>
		<span class="d-inline-block font-size-12"
			[ngClass]="{'text-blue-pulse': !topic?.following, 'text-white': topic?.following}"
		>{{ topic?.following ? 'Following' : 'Follow '}}</span>
	</button>
	</div>
</div>
