<div class="aviaPasswordChange row no-gutters" [ngClass]="{'justify-content-center': create_mode}">
	<div class="col-12" [ngClass]="{'col-md-6 col-lg-5 col-xl-4': !create_mode}">
		<form class="passwordForm d-flex flex-column justify-content-end" [formGroup]="password_form" (ngSubmit)="passwordCheck()">
			<div class="form-group mb-2" formGroupName="passwords">
				<label for="initial" class="col-form-label">Password</label>
				<input
					class="form-control"
					formControlName="initial"
					id="initial"
					type="password"
					[ngClass]="{ 'is-invalid': !fc_initial?.pristine && fc_initial?.invalid }"
				/>
			</div>
			<div class="form-group mb-2" formGroupName="passwords">
				<label for="confirm" class="col-form-label">Confirm Password</label>
				<input type="password" id="confirm" class="form-control" formControlName="confirm" />
			</div>
			<div class="status-msg mb-4 text-danger" [ngClass]="{'valid-pw': ( fc_initial?.valid && fc_confirm?.valid && !fc_passwords?.invalid )}">
				<!-- <i *ngIf="fc_initial?.valid && fc_confirm?.valid" class="material-icons">check_circle</i> -->
				{{
					fc_initial?.errors?.isAviaPassword !== null ?
					(fc_initial?.pristine ? '' : fc_initial?.errors?.isAviaPassword) :
					(!fc_passwords?.invalid ? 'Valid Password' : 'Passwords must match')
				}}
			</div>
			<div [ngClass]="{'text-center': create_mode}">
				<button
					[ngClass]="{'btn-success': !fc_passwords?.invalid, 'pointer': !fc_passwords?.invalid, 'btn-invalid': fc_passwords?.invalid}"
					class="btn py-2 px-5"
					type="submit"
					[disabled]="fc_passwords?.invalid ? 'true' : undefined"
				>{{ button_title }}</button>
			</div>
		</form>
	</div>

<!-- <div class="status-msg mb-4 text-danger" [ngClass]="{'valid-pw': fc_initial?.valid}">
<i *ngIf="fc_initial?.valid" class="material-icons">check_circle</i>
<div class="status-msg mb-4 text-danger" [ngClass]="{'valid-pw': !fc_passwords?.invalid }">
<i *ngIf="!fc_passwords?.invalid " class="material-icons">check_circle</i>		 {{ fc_initial?.errors?.isAviaPassword !== null ? (fc_initial?.pristine ? '' : fc_initial?.errors?.isAviaPassword) : 'Valid Password' }}
{{fc_initial?.errors?.isAviaPassword}}
{{fc_initial?.pristine}}
{{fc_passwords?.pristine}} -->


	<!-- === DEBUGGING DISPLAY === -->
	<span *ngIf="false">
		<!-- Display the form values and state during development -->
		<p class="mb-0">Form value: {{ password_form.value | json }}</p>
		<p class="mb-0">Form Errors: {{fc_initial?.errors | json}}</p>
		<p class="mb-0">Form status: {{ password_form.status | json }}</p>
	</span>
</div>
