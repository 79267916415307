<ng-template #link let-modal>
	<!-- HEADER -->
	<div class="bg-gray-200 px-4 py-2">
		<span class="header-text">Add Link</span>
		<button type="button" class="close p-0 m-0" aria-label="Close">
			<i class="material-icons md-18" (click)="closeModal()">close</i>
		</button>
	</div>
	<div>

		<div class="modal-body">
			<form>
				<div>Text</div>
				<input
					type="text"
					autocomplete="off"
					[name]="'text'"
					[(ngModel)]="link_val.text"
					class="d-block p-1 w-100 form-control"
				>
				<div>Link</div>
				<input
					type="text"
					autocomplete="off"
					[name]="'link'"
					[(ngModel)]="link_val.link"
					class="d-block p-1 w-100 form-control"
				>
			</form>
	</div>
		<div class="modal-footer justify-content-between bg-gray-200">
			<button type="button" class="btn btn-outline-dark px-6 py-1 cancel-button" (click)="closeModal()">Cancel</button>
			<button type="button" class="btn btn-primary px-6 py-1" (click)="createLink()">
				Save
			</button>
		</div>
	</div>
</ng-template>

<!-- OPEN MODEL BUTTON -->
<div (click)="openModal()">
	<ng-content></ng-content>
</div>
