import { AccessKeychain_Dashboard, Org, Session } from './../../../class';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AVIAConnectService } from './../../avia-connect.service';

@Component({
  selector: 'app-avia-quicklinks',
  templateUrl: './avia-quicklinks.component.html',
  styleUrls: ['./avia-quicklinks.component.scss']
})
export class AviaQuicklinksComponent implements OnInit {

  @Input() links;

  @Output() linksChanged = new EventEmitter();

  public keychain_hs: AccessKeychain_Dashboard = new AccessKeychain_Dashboard();
  public avia: Org;
  public org: Org;
  private session: Session;

  constructor(public aviaService: AVIAConnectService) { }


  async ngOnInit() {
    this.session = await this.aviaService.getSessionSupport();
    this.org = this.session.org;

    // See if user is a part of the pulse team or org has membership
    if(this.org.pulse_enabled){
      this.keychain_hs.pulse = this.aviaService.hasAccess(this.session, 'org', 'pulse', 'e', this.org['rd']);
    }

    // If user is part of a health system or AVIA, will display the AVIA community link
    // In the users quick links at the top
    if (this.org && (this.org.type === 3 || this.org.type === 1)) {
      this.avia = await this.aviaService.getOrgById(1);
    }
  }


  saveInterestsChange() {
    this.linksChanged.emit();
  }

}
