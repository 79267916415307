<div class="d-flex pt-2 flex-wrap">
	<span class="font-weight-bold font-size-12 post-tags d-flex align-items-center mr-1 mb-1 p-1"
		joyrideStep="tour1step3"
		title="Suggested:"
		text="Once you tag a topic in your post, we’ll suggest additional topics here to improve your posts visibility."
	>Suggested:</span>
	<ng-container *ngFor="let tag of rec_tags">
		<div class="post-tags d-flex align-items-center mr-1 mb-1 p-1 bg-gray-100 font-size-12 rounded text-gray-700 font-weight-bold pointer"
			(click)="_addTag(tag)"
		>
			@{{tag.name}}
		</div>
	</ng-container>
</div>
