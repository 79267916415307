<div class="d-block pt-4">
	<h2 class="heading-size-36 text-center mt-4">Welcome to AVIA Connect!</h2>
	<div class="row justify-content-center">
		<div class="col-12 col-md-8">
			<div class="text-center font-weight-light font-size-16">The industry’s platform for digital health innovation.</div>
			<div class="text-center font-weight-light font-size-16">Trusted Insights, Tools, and Collaboration.</div>
		</div>
	</div>
</div>
<div class="row d-flex justify-content-center mt-4 px-4 pt-4">
	<img class="img-fluid" src="/assets/onboarding/start.svg">
</div>
