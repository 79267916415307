import { cloneDeep } from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
  AviaVerticalType,
  KmSupport,
  KmSupport_IdName,
  E_Modal_Operating_Mode,
  Session,
  Topic,
  User
} from '../../../class';
import { AVIAConnectService } from '../../avia-connect.service';


@Component({
  selector: 'app-add-topic-modal',
  templateUrl: './add-topic-modal.component.html',
  styleUrls: ['./add-topic-modal.component.scss']
})

export class AddTopicModalComponent implements OnInit {
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template
  readonly VERBOSE = false;

  @Input() // data
    get data():     any { return this._data; }
    set data(value: any) { this._data = value; this.initDynamicData(); };
    _data:          any;
  @Input() //fullscreen
    get fullscreen():      boolean { return this._fullscreen }
    set fullscreen( value: boolean ) { this._fullscreen = value; }
    _fullscreen:           boolean = true;
  @Input() //hide_activate_btn
    get hide_activate_btn():      boolean { return this._hide_activate_btn; }
    set hide_activate_btn( value: boolean ) { this._hide_activate_btn = value; }
    _hide_activate_btn:           boolean = false;
  @Input() mode:        number;
  @Input() title_text:  string;
  @Input() static_page: boolean = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save:   EventEmitter<any> = new EventEmitter();
  @Output() _close:  EventEmitter<any> = new EventEmitter();

  status:                      number;
  km_support:                  KmSupport          = new KmSupport();
  session:                     Session            = new Session;
  km_card_types:               KmSupport_IdName[] = [ new KmSupport_IdName() ];
  avia_vertical_types:         AviaVerticalType[] = [ new AviaVerticalType() ];
  metric_stakeholders_types:   KmSupport_IdName[] = [ new KmSupport_IdName() ];
  metric_categories_types:     KmSupport_IdName[] = [ new KmSupport_IdName() ];
  metric_measure_types:        KmSupport_IdName[] = [ new KmSupport_IdName() ];
  links:                       Topic[]            = [];
  links_existing:              Topic[]            = [];
  parents:                     Topic[]            = [];
  parents_existing:            Topic[]            = [];
  children:                    Topic[]            = [];
  children_existing:           Topic[]            = [];
  avia_primary_owner:          User;
  avia_primary_vertical_owner: number;
  avia_primary_owner_existing: User;
  metric_categories:           KmSupport_IdName[] = [new KmSupport_IdName()];
  metric_measure_type:         KmSupport_IdName   = new KmSupport_IdName();
  metric_stakeholders:         KmSupport_IdName[] = [new KmSupport_IdName()];
  metric_risk:                 boolean;
  metric_strategic_indicator:  boolean;
  metric_early_indicator:      boolean;

  // FORM STUFF
  topic_form: FormGroup;

  // MODAL STUFF
  @ViewChild('TopicAddEdit', { static: true }) content: NgbModal;
  modal_ref:                          NgbModalRef;

  published_status: Object[] = [
    {
      id: 1,
      name: 'draft',
      value: 0
    },
    {
      id: 2,
      name: 'published',
      value: 1
    }
  ];


  constructor( private aviaService: AVIAConnectService, private fb: FormBuilder, private modalService: NgbModal ) {
    this.createForm();
  };

  ngOnInit() {
    this.VERBOSE && console.log('mode =', E_Modal_Operating_Mode.getPropName(this.mode));
    (async () => {
      let promise_arr = [
        this.aviaService.getSessionSupport(),
        this.aviaService.getKmSupport(),
      ];
      let promise_res = await Promise.all( promise_arr );
      this.session = promise_res[0];
      this.km_support = promise_res[1];
      this.km_card_types = this.km_support['km_card_types'];
      this.avia_vertical_types = this.km_support['avia_vertical_types'];
      this.metric_stakeholders_types = this.km_support['km_metric_stakeholders_types'];
      this.metric_categories_types = this.km_support['km_metric_avia_category_types'];
      this.metric_measure_types = this.km_support['km_metric_measure_types'];
    })();
  };

  async initDynamicData() {
    if (this._data && this._data['id'] !== null) {

      // Parents
      if ( this._data['parents'] && this._data['parents'].length > 0 ) {
        this.parents_existing = this._data['parents'];
        this.parents = cloneDeep(this.parents_existing);
      }

      // Children
      if ( this._data['children'] && this._data['children'].length > 0 ) {
        this.children_existing = this._data['children'];
        this.children = cloneDeep(this.children_existing);
      }

      // Links
      if ( this._data['links'] && this._data['links'].length > 0 ) {
        this.links_existing = this._data['links'];
        this.links = cloneDeep(this.links_existing);
      }

      // Owners
      if ( this._data['avia_primary_owner'] ) {
        this.avia_primary_owner_existing = this._data['avia_primary_owner_obj'];
        this.avia_primary_owner = cloneDeep(this.avia_primary_owner_existing);
      }

      this.avia_primary_vertical_owner = cloneDeep(this._data['avia_primary_vertical_owner']);
      this.status = cloneDeep(this._data['status']);
      this.setupFormData();
    }
  };

  // === DATA HANDLING ===
  onSubmit(): void {
    this.VERBOSE && console.log('add-topic-modal::onSubmit called with: ', this.topic_form.value);
    this.save.emit( this.topic_form.value );
    if(this.modal_ref) {
      this.modal_ref.close()
    }
  };

  onClose(): void {
    this._close.emit();
  }
  revert(): void {
    this.avia_primary_vertical_owner = cloneDeep(this._data['avia_primary_vertical_owner']);
    this.avia_primary_owner = cloneDeep( this.avia_primary_owner_existing );
    this.status = cloneDeep(this._data['status']);
    this.revertTopics();
    this.setupFormData();
  };

  revertTopics(): void {
    // this.links.length = 0;
    // this.parents.length = 0;
    // this.children.length = 0;
    this.links = cloneDeep( this.links_existing );
    this.parents = cloneDeep( this.parents_existing );
    this.children = cloneDeep( this.children_existing );

    // Conditional Validation:
    // If type = metric, then a vertical selection is not required
    // Hooking into the types change event here
    const owner_ctrl = this.topic_form.get('avia_primary_vertical_owner');
    const metric_cat_ctrl = this.topic_form.get('metric_categories');
    const metric_type_ctrl = this.topic_form.get('metric_measure_type');
    const metric_stake_ctrl = this.topic_form.get('metric_stakeholders');
    const type_ctrl = this.topic_form.get('type');
    if (type_ctrl.value == 6) {
      owner_ctrl.clearValidators();
      metric_cat_ctrl.setValidators([Validators.required]);
      metric_type_ctrl.setValidators([Validators.required]);
      metric_stake_ctrl.setValidators([Validators.required]);
    } else if (type_ctrl.value.length) {
      owner_ctrl.setValidators([Validators.required]);
    }
    owner_ctrl.updateValueAndValidity();

  };

  updatePublishedStatus($event){
    let publish_status = $event.data.name
    switch( publish_status ) {
      case 'Draft':
        this.status = 1;
        break
      case 'Published':
        this.status = 2;
        break
      case 'Archived':
        this.status = 3;
        break
    }

    this.topic_form.get('status').markAsDirty();
    this.topic_form.get('status').markAsTouched();
    this.setPublishedStatus(this.status)
  }

  updatePrimaryVerticalOwner($event){
    let vertical_owner = $event.data
    this.avia_primary_vertical_owner = vertical_owner.id
    this.topic_form.get('avia_primary_vertical_owner').markAsDirty();
    this.topic_form.get('avia_primary_vertical_owner').markAsTouched();
    this.setAviaPrimaryVerticalOwner( this.avia_primary_vertical_owner);
  }

  updateTopics( $event: any, topic_set_type = 'links' ): void {

    this.VERBOSE && console.log('add-topic-modal::updateTopics called with:', $event);

    let
    topic_set,
    action = $event['action'],
    data = $event['data'];

    switch( topic_set_type ) {
      case 'parents':
        topic_set = this.parents;
        break
      case 'children':
        topic_set = this.children;
        break
      case 'links':
        topic_set = this.links;
        break
    }

    if ( data == undefined ) return;
    switch( action ) {
      case 'add':
        for (const t of topic_set) {
          if ( data['id'] == t['id'] ) return; // Don't add the same Topic more than once
        }
        let new_topic;
        new_topic = new Topic()
        new_topic = {
          ...new_topic,
          id: data['id'],
          name: data['name'],
          type: data['type']
        }

        topic_set.push( new_topic );
      break;

      case 'remove':
        topic_set = topic_set.filter( t => t.id !== data.id );
      break;
    }
    this.topic_form.get( topic_set_type ).markAsDirty();
    this.topic_form.get( topic_set_type ).markAsTouched();
    this.setTopics( topic_set, topic_set_type );
  };

  updateAviaPrimaryOwner( $event: any ): void {
    this.avia_primary_owner = $event.data;

    this.topic_form.get('avia_primary_owner').markAsDirty();
    this.topic_form.get('avia_primary_owner').markAsTouched();
    this.setAviaPrimaryOwner( this.avia_primary_owner);
  }

  // === FORM CONTROLS (MAIN) ===

  createForm(): void {
    this.topic_form = this.fb.group({
      avia_primary_owner:          ['', [ Validators.required ] ],
      avia_primary_vertical_owner: null,
      metric_categories:           null,
      metric_measure_type:         null,
      metric_stakeholders:         null,
      metric_risk:                 '',
      metric_strategic_indicator:  '',
      metric_early_indicator:      '',
      description:                 '',
      short_desc:                  '',
      name:                        ['', [ Validators.required ] ],
      links:                       '',
      parents:                     '',
      children:                    '',
      type:                        ['', [ Validators.required ] ],
      status:                      ''
    });
  };

  setupFormData(): void {
    this.VERBOSE && console.log('add-topic-modal::setupFormData operating mode = ', E_Modal_Operating_Mode.getPropName(this.mode) );
    let common_fields = {
      avia_primary_owner:          this.data['avia_primary_owner']          ? this.data['avia_primary_owner']                 : '',
      avia_primary_vertical_owner: this.data['avia_primary_vertical_owner'] ? this.data['avia_primary_vertical_owner']        : null,
      metric_categories:           this.data['metric_categories']           ? this.data['metric_categories'].map(d => d.id)   : null,
      metric_measure_type:         this.data['metric_measure_type']         ? this.data['metric_measure_type']                : null,
      metric_stakeholders:         this.data['metric_stakeholders']         ? this.data['metric_stakeholders'].map(s => s.id) : null,
      metric_risk:                 this.data['metric_risk']                 ? this.data['metric_risk']                        : 0,
      metric_strategic_indicator:  this.data['metric_strategic_indicator']  ? this.data['metric_strategic_indicator']         : 0,
      metric_early_indicator:      this.data['metric_early_indicator']      ? this.data['metric_early_indicator']             : 0,
      description:                 this.data['description']                 ? this.data['description']                        : '',
      short_desc:                  this.data['short_desc']                  ? this.data['short_desc']                         : '',
      name:                        this.data['name']                        ? this.data['name']                               : '',
      links:                       [],
      parents:                     [],
      children:                    [],
      type:                        this.data['type']                        ? this.data['type']                        : '1',
      status:                      this.data['status']                      ? this.data['status']                : 2
    };

    let edit_fields = {}; //TODO: This is here for the upcoming Edit mode

    this.topic_form.reset( common_fields );

    // Topics
    if ( this.links_existing.length > 0 )    this.setTopics( this.links_existing, 'links' );
    if ( this.parents_existing.length > 0 )  this.setTopics( this.parents_existing, 'parents' );
    if ( this.children_existing.length > 0 ) this.setTopics( this.children_existing, 'children' );
    if ( this.avia_primary_owner_existing )  this.setAviaPrimaryOwner( this.avia_primary_owner_existing );
  };

  setTopics( topic_set: Topic[] = [], topic_set_type ): void {
    let topic_ids = [];

    for (let t of topic_set) {
      topic_ids.push( t.id );
    }

    this.topic_form.get( topic_set_type ).patchValue(topic_ids);

    switch( topic_set_type ) {
      case 'parents':
        this.parents = topic_set;
        break
      case 'children':
        this.children = topic_set;
        break
      case 'links':
        this.links = topic_set;
        break
    }
  };

  updateCategories ($event: any) : void {
    this.metric_categories = $event.selected;
    this.topic_form.get('metric_categories').markAsDirty();
    this.topic_form.get('metric_categories').markAsTouched();
    this.setCategories(this.metric_categories);
  }

  updateMetricType ($event: any) : void {
    this.metric_measure_type = $event.selected[0];
    this.topic_form.get('metric_measure_type').markAsDirty();
    this.topic_form.get('metric_measure_type').markAsTouched();
    this.setMetricType(this.metric_measure_type);
  }

  updateStakeholders ($event: any) : void {
    this.metric_stakeholders = $event.selected;
    this.topic_form.get('metric_stakeholders').markAsDirty();
    this.topic_form.get('metric_stakeholders').markAsTouched();
    this.setStakeholders(this.metric_stakeholders);
  }

  setPublishedStatus( status ): void {
    this.VERBOSE
    this.topic_form.get('status').patchValue(status);
  }

  setAviaPrimaryVerticalOwner( avia_primary_vertical_owner ): void {
    this.VERBOSE
    this.topic_form.get('avia_primary_vertical_owner').patchValue(avia_primary_vertical_owner);
  }

  setMetricType ( type ): void {
    this.VERBOSE && console.log('add-topic-modal::setMetricType - type.id:', type.id);
    this.topic_form.get('metric_measure_type').patchValue(type.id);
  };

  setCategories( categories ): void {
    let cat_ids = [];
    for (const category of categories) {
      if ( category['id'] ) cat_ids.push( category['id'] );
    }
    this.VERBOSE && console.log('add-topic-modal::setCategories - cat_ids:', cat_ids);
    this.topic_form.get('metric_categories').patchValue(cat_ids);
  };

  setStakeholders ( stakeholders ): void {
    let stakeholder_ids = [];
    for (const stk of stakeholders) {
      if ( stk['id'] ) stakeholder_ids.push( stk['id'] );
    }
    this.VERBOSE && console.log('add-topic-modal::setStakeholders - stakeholder_ids:', stakeholder_ids);
    this.topic_form.get('metric_stakeholders').patchValue(stakeholder_ids);
  };

  setAviaPrimaryOwner( avia_primary_owner: User ): void {
    this.topic_form.get('avia_primary_owner').patchValue(avia_primary_owner.id);
  }


  // === MODAL STUFF ===

  close(): void {
    this.revert();
    this.cancel.emit();
    this._close.emit(); //Closes universal nav if open
    this.modal_ref.close();
  };

  async open( data = this.data ) {
    this.data = data;

    let options: NgbModalOptions = {
      'backdrop': 'static',
      size: 'lg',
    };
    if (this._fullscreen) options['windowClass'] = 'avia-modal-fullscreen';

    this.modal_ref = this.modalService.open( this.content, options );
    this.setupFormData();
  };

};
