import { FeedService } from './../../feed.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-post-recommendations',
  templateUrl: './post-recommendations.component.html',
  styleUrls: ['./post-recommendations.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostRecommendationsComponent implements OnInit {

  //@Input() current_tags = [];
  @Input()
  get current_tags():     any { return this._current_tags; }
  set current_tags(value: any) {
    this._current_tags = value;
    this.initDynamicData();
  };
  _current_tags:          any;

  @Input()
  get current_root_tags():     any { return this._current_root_tags; }
  set current_root_tags(value: any) {
    this._current_root_tags = value;
    this.initDynamicData();
  };
  _current_root_tags:          any;


  @Output() addTag: EventEmitter<any> = new EventEmitter();

  rec_tags: any[] = [];

  constructor(public fs: FeedService) { }

  async ngOnInit() {

  }

  async initDynamicData() {

    // console.log('initDynamicData', this.current_tags);
    let card_ids = [];

    if(this.current_tags && this.current_tags.length > 0) {
      for(let tag of this.current_tags) {
        if(tag.card_id) {
          card_ids.push(tag.card_id)
        }
      }
    }

    if(this.current_root_tags && this.current_root_tags.length > 0) {
      for(let tag of this.current_root_tags) {
        if(tag.card_id) {
          card_ids.push(tag.card_id)
        }
      }
    }

    if(card_ids.length > 0 ) {
      this.getTags(card_ids)
    }
  }

  async getTags(card_ids) {
    let res = await this.fs.aviaService.getPostsRecommendations({
      "tags": {
        "card_ids": card_ids,
        // "user_ids": [11,2]
      }
    })

    this.rec_tags = res.body;
  }

  _addTag(tag){
    this.addTag.emit(tag);
    this.rec_tags = this.rec_tags.filter(t=>t!=tag);
  }
}
