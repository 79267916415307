import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OnboardingService } from '../../onboarding.service';

@Component({
  selector: 'app-onboarding-welcome',
  templateUrl: './onboarding-welcome.component.html',
  styleUrls: ['./onboarding-welcome.component.scss']
})
export class OnboardingWelcomeComponent implements OnInit, AfterViewInit {

  constructor(public sob: OnboardingService) {
  }

  ngOnInit() {
    this.sob.form_valid = true;
  }

  ngAfterViewInit() {
    this.sob.aviaService.createAnalyticNodeEvent({
      actions:[{
        action_type:"ONBOARDING_SOLCO_WELCOME",
        eventkeytype:"ONBOARDING_SOLCO_WELCOME",
        eventkeydivisor:1000,
        routeParams: this.sob.aviaService.currentRouteParams,
        //target: user_id, get it on back end
        attributes: {
          page_type: "onboarding",
          // status
        },
      }]
    })
  }

}
