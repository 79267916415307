import { NgModule }      from '@angular/core';
import { SharedModule }  from '../shared/shared.module';

import { NgbModule }                        from '@ng-bootstrap/ng-bootstrap';

import { SignupComponentRoutingModule } from './signup-routing.module';

import { AviaFormOrgsDropdown } from './components/avia-form-orgs-dropdown.component';
import { CheckEmailComponent } from './components/check-email.component';
import { SignupComponent} from './components/signup.component';
import { SignupLandingComponent } from './components/signup-landing.component';
import { SignupOtherComponent } from './components/signup-other.component';
import { SignupWrapperComponent } from './signup-wrapper.component';

@NgModule({
  declarations: [
    AviaFormOrgsDropdown,
    CheckEmailComponent,
    SignupComponent,
    SignupLandingComponent,
    SignupOtherComponent,
    SignupWrapperComponent,
  ],
  imports: [
    NgbModule,
    SharedModule,
    SignupComponentRoutingModule,
  ],
  providers: [
    //SignupService
  ]
})

export class SignupModule { }
