<div class="notification-item">
  <div class="avatar my-0 mr-2"><img class="img-responsive avatar-img rounded" src="{{ notification.action_user_obj?.avatar }}" /></div>
  <button class="message btn text-left pointer p-0" [ngClass]="{'font-weight-normal': notification.dismissed == 1}" (click)="onClick(notification)">
    <div class="title text-truncate">
      <div>{{ notification.action_user_obj?.firstname }} {{ notification.action_user_obj?.lastname }} {{ notification.options ? notification.options.message : '' }}</div>
    </div>
    <div class="date">{{ notification.created | date: "MMM dd, y" }}</div>
  </button>
	<button *ngIf="show_dismiss" class="dismiss btn pointer" (click)="dismiss.emit({'notification':notification})">
		<i class="material-icons">cancel</i>
	</button>
</div>
