
<div (scroll)="onScroll($event)" class="scrollable cb-search container-fluid h-100 pt-4 px-4 pb-sm-4">
	<div class="row">
		<div class="col-12 col-sm-11">
			<div class="d-flex align-items-center cb-label mb-4 text-center text-sm-left">
				<img class="tiny-avatar-img mr-1" src="/assets/crunchbase-grey.svg" onerror="this.src='/assets/placeholder-company.jpg'" />
				<b>Search Crunchbase</b>
			</div>
			<app-avia-search #search_bar
				[options]="searchbar_options"
				[searching]="searching"
				[term]="term"
				(enter_pressed)="termSubmitted($event)"
				(update_search)="updateSearch($event)">
			</app-avia-search>
		</div>
	</div>

	<div *ngIf="term !== '' && (search_results | async)" class="results-container w-100">
		<span *ngIf="!searching" class="justify-content-between mt-sm-4 pb-3 pb-sm-2 no-gutters">
			<div class="col-12 col-sm-6 d-flex">
				<span (click)="openScAdd()" class="pointer mb-4 font-size-12 text-blue-light cb-label mr-2 mt-sm-1 text-right"><b>Still don't see what you're looking for?</b></span>
				<app-sc-add-edit-import-modal #ScImportModal
					hidden
					[data]="new_solco_blank"
					[mode]="E_Modal_Operating_Mode.ADD"
					[title_text]="'Add a Company'"
					(save)="submitImportFromCrunchbase($event);"
				></app-sc-add-edit-import-modal>
			</div>
			<div class="d-flex align-items-center pb-2 font-size-12 cb-label col-12 col-sm-6 mb-3 mb-sm-0 mt-sm-1 text-center text-sm-left">
				<b>Crunchbase search results ({{count_total}})</b>
			</div>
		</span>
		<div *ngIf="(!searching) && (no_results); else show_results" class="mt-4"><app-avia-no-results>No Results</app-avia-no-results></div>
		<div
			*ngFor="let company of scroll_items; last as isLast"
			class="list-item"
			>
			<div [ngClass]="{'border-bottom' : !isLast}" class="org fade-target d-flex pt-1 px-2">
				<img class="tiny-avatar-img mr-1" src="{{ company.logo }}" onerror="this.src='/assets/placeholder-company.jpg'" />
				<div class="title w-100">{{company.name}} <a *ngIf="company?.website" [href]="company?.website" target="_blank" class="pointer"> - {{company?.domain}}</a>
					<div class="value-display float-right" DebounceClick (debounceClick)="addCompany(company)" [debounceTime]="400">
						<i class="material-icons text-gray-400 add ml-1 pointer ">add_circle</i>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="searching"><app-avia-loader [options]="{type: 'generic'}"></app-avia-loader></div>
	</div>
</div>


