import { PasswordPolicy } from '../class';
import { UUID } from 'angular2-uuid';
import { EventEmitter } from '@angular/core';

let   removeMd = require('remove-markdown');
const tldjs    = require('tldjs');


export class Common {
  static DEBOUNCE_TIME: number = 300;

  static readonly DOMAIN:            string = 'aviahealthinnovation.com';
  static readonly EMAILADDY_SUPPORT: string = 'support@' + Common.DOMAIN;
  static readonly EMAILADDY_CONNECT: string = 'connect@' + Common.DOMAIN;

  static HEX_COLOR:          RegExp = new RegExp(/^#([A-Fa-f0-9]{6})$/);
  static REGEX_EMAIL:        RegExp = new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-_](?:[a-zA-Z0-9-_]{0,}[a-zA-Z0-9-_])(?:\.[a-zA-Z0-9-_.]{0,})?(?:\.[a-zA-Z]{2,})$/);
  static REGEX_EMAIL_SERVER: RegExp = new RegExp(/^(?:^(?!(www|ftp)))([a-z0-9][a-z0-9-]{1,61}(?:\.[a-z]{2,})+|localhost)$/, 'i'); // Match patterns like: 'avia.com', 'test.avia.co.us', 'hf.com', or 'localhost'. It supports '-' in the names (as long as it's not the first character). It fails on domains beginning with 'www.' and 'ftp.'
  static REGEX_FL_NAME:      RegExp = new RegExp(/^[a-z0-9\u0080-\uFFFF ']+[a-z0-9\u0080-\uFFFF, .'-]*[a-z0-9\u0080-\uFFFF ']$/, 'i'); // First and Last name validator
  static INVITE_NAME:        RegExp = new RegExp(/^[a-zA-Z]/);
  static REGEX_LINKEDIN:     RegExp = new RegExp(/^(https?:\/\/)?(www\.)?linkedin.com\/in\/[\w\-.]+\/?[\?=-\w\d]{0,}$/, 'i');
  static REGEX_NAME:         RegExp = new RegExp(/^[\w \.\,\!\&\(\)\[\]\-\+\^\#\\\:\;'\/\\=]{2,}$/, 'i');
  static REGEX_NO_LT_SPACES: RegExp = new RegExp(/^[^\s]+.+[^\s]+$/); // No leading or trailing whitespace (not multi-line compatible).
  static REGEX_TWITTER:      RegExp = new RegExp(/^(https?:\/\/)?(www\.)?twitter.com\/[\w\-.]+\/?$/, 'i');
  static REGEX_WEBSITE:      RegExp = new RegExp(/^(https?:\/\/)?(www\.)?[\w\-\.]+\.[A-Z]{2,}(([\w?=&+\-\.\/]+)?[\w?=&+\-\.]+)?\/?$/, 'i');

  // NOTE: The Bootstrap Small / Medium boundary is a browser width of 768px
  //       - Do NOT change this number unless you know how it will affect every component that uses it.
  static WINDOW_WIDTH_MOBILE_BREAKPOINT: number = 768;

  static sanitizeKeyName(key_name: string): string {
    key_name = key_name + '';
    let sane = key_name.toUpperCase();
    sane = sane.replace(/[ \/]/g, "_");
    sane = sane.replace(/[&]/g, "AND");
    sane = sane.replace(/[\',!@#$%^*\(\)-]/g, "");
    return sane;
  }

  // static indexIt(uniqueIdColumnName: any, rows: any) {
  //   uniqueIdColumnName = [].concat(uniqueIdColumnName); // make it an array if not already.
  //   let columnName = uniqueIdColumnName.shift();

  //   let retval = {};
  //   for (let r of [].concat(rows)) {
  //     if (r !== undefined) {
  //       let key = r[columnName];
  //       delete r[columnName];

  //       // if not present, create
  //       if (retval[key] == undefined) {
  //         retval[key] = r;
  //       } else {
  //         // if adding multiple elements, then push together as an array
  //         let val_arr = [].concat(retval[key]);
  //         val_arr.push(r);
  //         retval[key] = val_arr;
  //       }
  //     }
  //   }
  //   // sub (recursive) indexing if requested...
  //   if (uniqueIdColumnName.length > 0) {
  //     for (let key in retval) {
  //       retval[key] = this.indexIt(uniqueIdColumnName, retval[key]);
  //     }
  //   }
  //   return retval;
  // }

  // convert rows into an object, hashing on the unique id column
  static indexIt( uniqueIdColumnName, rows, removeKey=true, alwaysReturnArrs = false ) {

    uniqueIdColumnName = [].concat( uniqueIdColumnName ); // make it an array if not already.
    let columnName = uniqueIdColumnName.shift();

    let retval = {};
    for (let r of [].concat(rows)) {
      let key = r[columnName];
      if (removeKey) delete r[columnName];

      // if not present, create
      if (retval[key] == undefined) {
        retval[key] = alwaysReturnArrs ? [r] : r;
      } else {
        // if adding multiple elements, then push together as an array
        let val_arr = [].concat( retval[key] );
        val_arr.push(r);
        retval[key] = val_arr;
      }
    }
    // sub (recursive) indexing if requested...
    if (uniqueIdColumnName.length > 0) {
      for (let key in retval) {
        retval[key] = module.exports.indexIt( uniqueIdColumnName, retval[key] );
      }
    }
    return retval;

  }


  static objSafeRead(obj: Object, path: any[], empty) {
    for (let p of path) {
      if (obj && obj[p] !== undefined)
        obj = obj[p];
      else
        return empty;
    }
    return obj;
  }

  // Weak Unique ID (crypto-secure PRNG window.crypto.getRandomValues() if available, otherwise fallback to Math.random(), UUID compliant.  based on random number generator.  probably wont clash, but...)
  static UUID(): string { return UUID.UUID(); }

  // Stronger Unique ID (not UUID compliant.  much less likely to clash)
  static StrongerUUID(): string { return UUID.UUID() + "_" + (new Date()).getTime().toString(); }

  static removeMarkdown(md) {
    return md ? removeMd(md) : null;
  }


  static removeTrailingNewLines(s: string): string {
    return s.replace(/(\r\n|\n|\r)$/, "");
  }

  static removePrecedingAndTrailingWhiteSpace(s: string): string {
    return s.replace(/^(\s+)|(\s+$)/g, '');
  }

  static removeValueFromArray = function (arr, val, all = true) {
    if (all) {
      for (let i = arr.length; i--;) {
        if (arr[i] == val) arr.splice(i, 1);
      }
    } else {
      let i = arr.indexOf(val);
      if (i > -1) arr.splice(i, 1);
    }
    return arr;
  }

  static buildAviaListUpdateArray = function (current_array, update_object) {
    switch (update_object.action) {
      case 'delete':
        current_array = Common.removeValueFromArray(current_array, update_object.values[0]);
        break;
      case 'add':
        if (current_array.indexOf(update_object.values[0]) === -1) current_array.push(update_object.values[0]);
        break;
    }
    return current_array;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PASSWORD
  static verifyPassword = function (password, password_policy: PasswordPolicy = new PasswordPolicy() ) {
    /*
      See class.ts for PasswordPolicy class
      Description: checks password against password policy
      Returns: an object like { password_valid: true, statusMsg: 'Valid Password'} or
      { password_valid: false, statusMsg: 'Must be at least 8 characters.'}
    */
    let pw = password;
    let result = {
      'password_valid': true,
      'statusMsg': ''
    }
    let tests = {}

    if (password_policy.minDiacritic !== undefined && password_policy.minDiacritic !== 0) {
      tests['minDiacritic'] = {

      }
    }
    if (password_policy.preventReuse !== undefined && password_policy.preventReuse !== 0) {
      tests['preventReuse'] = {

      }
    }

    if (password_policy.minSymbol !== undefined && password_policy.minSymbol !== 0) {
      tests['minSymbol'] = {
        test: pw.replace(/[a-z]/g, '').replace(/[0-9]/g, '').replace(/[A-Z]/g, '').replace(/\s/g, "").length >= password_policy['minSymbol'],
        text: 'Must have at least ' + password_policy['minSymbol'] + ' symbol(s)'
      }
    }

    if (password_policy.minLowerCase !== undefined && password_policy.minLowerCase !== 0) {
      tests['minLowerCase'] = {
        test: new RegExp('^(?=.*[a-z]{' + password_policy.minLowerCase + '}).+$').test(pw.replace(/[A-Z]/g, '')),
        text: 'Must have at least ' + password_policy['minLowerCase'] + ' lowercase character(s)'
      }
    }
    if (password_policy.minUpperCase !== undefined && password_policy.minUpperCase !== 0) {
      tests['minUpperCase'] = {
        test: new RegExp('^(?=.*[A-Z]{' + password_policy['minUpperCase'] + '}).+$').test(pw),
        text: 'Must have at least ' + password_policy['minUpperCase'] + ' uppercase character(s)'
      }
    }
    if (password_policy.minNumeric !== undefined && password_policy.minNumeric !== 0) {
      tests['minNumeric'] = {
        test: new RegExp('^(?=.*[0-9]{' + password_policy['minNumeric'] + '}).+$').test(pw),
        text: 'Must have at least ' + password_policy['minNumeric'] + ' number(s)'
      }
    }

    if (password_policy.minLength !== undefined && password_policy.minLength !== 0) {
      tests['minLength'] = {
        test: pw.length >= password_policy['minLength'],
        text: 'Must be at least ' + password_policy['minLength'] + ' characters'
      }
    }
    if (password_policy.maxLength !== undefined && password_policy.maxLength !== 0) {
      tests['maxLength'] = {
        test: pw.length <= password_policy['maxLength'],
        text: 'Cannot be longer than ' + password_policy['maxLength'] + ' characters'
      }
    }

    for (let key in tests) {
      if (!tests[key].test) {
        result.statusMsg = tests[key].text;
        result.password_valid = false;
      }
    }

    if (result.password_valid) {
      result.statusMsg = 'Valid Password!';
      result.password_valid = true;
    }
    return result;
  }

  // check that the password boxes passes the password policy (both boxes match, and satisfy the policy)
  // returns an object like { password_valid: true, statusMsg: 'Valid Password'}
  static verifyPassword_2Boxes = function (passwordInitial, passwordConfirm, password_policy) {
    let pw = passwordInitial;
    let confirm = passwordConfirm;
    let result = {}
    result['password_valid'] = true;

    // run box2box tests
    let tests = {
      match: {
        test: pw === confirm,
        text: 'Passwords must match!'
      }
    };
    for (let key in tests) {
      if (!tests[key].test) {
        result['statusMsg'] = tests[key].text;
        result['password_valid'] = false;
      }
    }

    // run basic tests
    if (result['password_valid']) {
      result = Common.verifyPassword(pw, password_policy);
    }
    return result;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // USER / ORG related utilities

  // Takes in the output from the Avia Tags component
  //   returns an array of IDs, ordered by the 'priority' key found in the '_types_clinical_creds' table
  static orderClinicalCredsIds = function (original_values:any, clinical_credential_types:any ): number[] {
    let ordered_values = [];
    let val_count = 0;
    let val_length = original_values.length;
    for( let i in clinical_credential_types ) {
      // loop over the clinical credential types to order the user's input according to the priority we have set in the db
      let cred = clinical_credential_types[i];
      for( let j of original_values ) {
        if( cred['id'] == j ) {
          ordered_values.push( j );
          val_count++;
        }
      }
      if( val_count == val_length ) break;
    }
    return ordered_values;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // TYPE-OF UTILITIES

  // return true if obj is an array
  static isArray(obj) {
    return !!obj && obj.constructor === Array;
  }

  // return true if obj is an object
  static isObject(obj) {
    return typeof obj === 'object';
  }

  // return true if obj is an number
  static isNumber(obj) {
    return !!obj && typeof obj === 'number';
  }

  // return true if obj is an string
  static isString(obj) {
    return !!obj && typeof obj === 'string';
  }

  static justFilename(url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  static isValidHTTPResponse(status) {
    return 200 <= status && status < 300;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // UI UTILITIES

  // Used to calculate a height based upon an offset
  static calcMenuHeight(offset: number = 0, window_height: number = 0) {
    return window_height - offset; // Note: this allows mobile hamburguesa menu to scroll.
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // COLOR UTILITIES

  // NOTE: should probably use hexToRgb, rgbToHsv, hsvToRgb, and rgbToHex instead, for better control, better color space calculation.
  //  consider implementing changeColor() in terms of those.
  static changeColor(color: string, percent: number): string {
    /*  Description: function to lighten or darken a hex color
        Input:      - base hex color as string (with #)
                    - percent to darken/ligten (valid range for the second (percent) parameter is -1.0 to 1.0 i.e. -0.2 does 2% darker)
        Output:      returns hex color with #

        See:
        https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    */
    // let f = parseInt(color.slice(1),16),
    //     t = percent<0?0:255,
    //     p = percent<0?percent*-1:percent,
    //     R = f>>16,
    //     G = f>>8&0x00FF,
    //     B = f&0x0000FF;
    // return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
    let rgb = Common.hexToRgb(color);
    let hsv = Common.rgbToHsv(rgb.r, rgb.g, rgb.b);
    hsv[2] *= (1 + percent);
    let rbg_return = Common.hsvToRgb(hsv[0], hsv[1], hsv[2])
    return Common.rgbToHex(rbg_return[0], rbg_return[1], rbg_return[2]);
  }

  /**
   * Converts an RGB color value to HSL. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and l in the set [0, 1].
   *
   * @param   Number  r       The red color value
   * @param   Number  g       The green color value
   * @param   Number  b       The blue color value
   * @return  Array           The HSL representation
   */
  static rgbToHsl(r, g, b) {
    r /= 255, g /= 255, b /= 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }

      h /= 6;
    }

    return [ h, s, l ];
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   Number  h       The hue
   * @param   Number  s       The saturation
   * @param   Number  l       The lightness
   * @return  Array           The RGB representation
   */
  static hslToRgb(h, s, l) {
    var r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      let hue2rgb = function(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }

    return [ r * 255, g * 255, b * 255 ];
  }

  /**
   * Converts an RGB color value to HSV. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and v in the set [0, 1].
   *
   * @param   Number  r       The red color value
   * @param   Number  g       The green color value
   * @param   Number  b       The blue color value
   * @return  Array           The HSV representation
   */
  static rgbToHsv(r, g, b) {
    r /= 255, g /= 255, b /= 255;

    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, v = max;

    var d = max - min;
    s = max == 0 ? 0 : d / max;

    if (max == min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }

      h /= 6;
    }

    return [ h, s, v ];
  }

  /**
   * Converts an HSV color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
   * Assumes h, s, and v are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   Number  h       The hue
   * @param   Number  s       The saturation
   * @param   Number  v       The value
   * @return  Array           The RGB representation
   */
  static hsvToRgb(h, s, v) {
    var r, g, b;

    var i = Math.floor(h * 6);
    var f = h * 6 - i;
    var p = v * (1 - s);
    var q = v * (1 - f * s);
    var t = v * (1 - (1 - f) * s);

    switch (i % 6) {
      case 0: r = v, g = t, b = p; break;
      case 1: r = q, g = v, b = p; break;
      case 2: r = p, g = v, b = t; break;
      case 3: r = p, g = q, b = v; break;
      case 4: r = t, g = p, b = v; break;
      case 5: r = v, g = p, b = q; break;
    }

    return [ Math.floor( r * 255 ), Math.floor( g * 255 ), Math.floor( b * 255 ) ];
  }

  // convert rgba (255, 100, 50, 255) to hex #ff9933ff
  static rgbaToHex(r, g, b, a) {
    return "#" + ((a << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  // convert rgb (255, 100, 50) to hex #ff9933
  static rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  static hexToCssHsl(hex:string):any {
    let rgb: any = this.hexToRgb(hex);
    let hsl_arr: number[] = Common.rgbToHsl(rgb.r, rgb.g, rgb.b);
    let color_as_hsl:any = {h:0, s:'', l:''};
    color_as_hsl.h = Math.round(hsl_arr[0] * 360);
    color_as_hsl.s = Math.round(hsl_arr[1] * 100) + '%';
    color_as_hsl.l = Math.round(hsl_arr[2] * 100) + '%';
    return color_as_hsl;
  }

  // convert hex (#ffbbaa) or shorthand hex (#fba)
  static hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

  // convert hex (#ffbbaaff) or shorthand hex (#fbaf)
  static hexToRgba(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b, a) {
        return r + r + g + g + b + b + a + a;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16)
    } : null;
  }

  static hexToHue( hex ) {
    let rgb = this.hexToRgb( hex );
    let hsv = this.rgbToHsv( rgb.r, rgb.g, rgb.b );
    return hsv[0];
  }

  // convert hex (or simplified hex) to CSS style rgba
  // example(s):
  //   hexToCSSrgba( #ffaa99, 255 ) == "rgba( 255, 150, 90, 255 )"
  //   hexToCSSrgba( #fa9, 255 ) == "rgba( 255, 150, 90, 255 )"
  static hexToCSSrgba(hex, opacity) {
    let rgb = this.hexToRgb(hex);
    let rgba = `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
    return rgba;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // DOMAIN AND QUERY PARAMS

  // if this isn't working use js build in url
  // https://nodejs.org/docs/latest/api/url.html#url_constructor_new_url_input_base
  // const myURL = new URL('https://example.org:81/foo');
  // console.log(myURL.host); OR console.log(myURL.hostname);
  static parseDomain( data ) {
    let regex = /^https?:\/\//, domain;
    let hasProtocol = regex.test(data);
    let a = document.createElement('a');

    if(!hasProtocol) data = 'http://' + data;
    a.href = data;
    // dont do it yourself, fraught with problems! :)  TLD is HARD to match... too many weird ones.
    //return a.hostname.replace(new RegExp(/.*(?=\.\w+)$/i),"");

    // In case of bad domain, fail silently
    try {
      domain = tldjs.getDomain( a.hostname );
    } catch(err) {
      console.error(err);
      domain = '';
    }

    return domain;
  }

  static safeHttp(url) {
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    return url;
  }
  // if this is not working use
  // new URLSearchParams(params)
  // https://nodejs.org/docs/latest/api/url.html#url_constructor_new_urlsearchparams
    // or use Query String https://nodejs.org/docs/latest/api/querystring.html
  static queryStringToJSON() {
    let pairs = location.search.slice(1).split('&');

    var result = {};
    pairs.forEach(pair => {
      let arr = pair.split('=');
      //pair = pair.split('=');
      result[arr[0]] = decodeURIComponent(arr[1] || '');
    });
    return JSON.parse(JSON.stringify(result));
  }
  // think about using built in:
  // new URLSearchParams(params).toString()
  // https://nodejs.org/docs/latest/api/url.html#url_constructor_new_urlsearchparams
  static buildQueryParams(paramsObj: Object = {}): string{
    let params: string = '';
    for( let param in paramsObj ) {
      if (paramsObj[param] !== undefined) {
        if(Array.isArray(paramsObj[param])) {
          for(let item of paramsObj[param]) {
            params += '&' + param + '=' + encodeURIComponent(item);
          }
        } else {
          params += '&' + param + '=' + encodeURIComponent(paramsObj[param]);
        }
      }
    }
    if( params.length > 0 ) params = '?' + params.substring(1); // Turn the first '&' into a '?'
    return params;
  }

  // Generate CSV here
  static generateCSV(items, name) {

    // Only generate the CSV if one or more items exist in the array
    if (!items || !items.length) return;

    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n');

    var file = new Blob([csv], { type: 'text/csv'});
    var link = document.createElement("a");
    link.download = name;
    link.href = window.URL.createObjectURL(file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  // BE SURE TO KEEP IN SYNC WITH BACK END COMMON
  static common_domains = [
    /* Default domains included */
    "aol.com", "att.net", "comcast.net", "facebook.com", "gmail.com", "gmx.com", "googlemail.com",
    "hotmail.com", "hotmail.co.uk", "mac.com", "me.com", "mail.com", "msn.com",
    "live.com", "sbcglobal.net", "verizon.net", "yahoo.com", "yahoo.co.uk",

    /* Other global domains */
    "email.com", "fastmail.fm", "games.com" /* AOL */, "gmx.net", "hush.com", "hushmail.com", "icloud.com",
    "iname.com", "inbox.com", "lavabit.com", "love.com" /* AOL */, "outlook.com", "pobox.com", "protonmail.com",
    "rocketmail.com" /* Yahoo */, "safe-mail.net", "wow.com" /* AOL */, "ygm.com" /* AOL */,
    "ymail.com" /* Yahoo */, "zoho.com", "yandex.com",

    /* United States ISP domains */
    "bellsouth.net", "charter.net", "cox.net", "earthlink.net", "juno.com",

    /* British ISP domains */
    "btinternet.com", "virginmedia.com", "blueyonder.co.uk", "freeserve.co.uk", "live.co.uk",
    "ntlworld.com", "o2.co.uk", "orange.net", "sky.com", "talktalk.co.uk", "tiscali.co.uk",
    "virgin.net", "wanadoo.co.uk", "bt.com",

    /* Domains used in Asia */
    "sina.com", "sina.cn", "qq.com", "naver.com", "hanmail.net", "daum.net", "nate.com", "yahoo.co.jp", "yahoo.co.kr", "yahoo.co.id", "yahoo.co.in", "yahoo.com.sg", "yahoo.com.ph", "163.com", "126.com", "aliyun.com", "foxmail.com",

    /* French ISP domains */
    "hotmail.fr", "live.fr", "laposte.net", "yahoo.fr", "wanadoo.fr", "orange.fr", "gmx.fr", "sfr.fr", "neuf.fr", "free.fr",

    /* German ISP domains */
    "gmx.de", "hotmail.de", "live.de", "online.de", "t-online.de" /* T-Mobile */, "web.de", "yahoo.de",

    /* Italian ISP domains */
    "libero.it", "virgilio.it", "hotmail.it", "aol.it", "tiscali.it", "alice.it", "live.it", "yahoo.it", "email.it", "tin.it", "poste.it", "teletu.it",

    /* Russian ISP domains */
    "mail.ru", "rambler.ru", "yandex.ru", "ya.ru", "list.ru",

    /* Belgian ISP domains */
    "hotmail.be", "live.be", "skynet.be", "voo.be", "tvcablenet.be", "telenet.be",

    /* Argentinian ISP domains */
    "hotmail.com.ar", "live.com.ar", "yahoo.com.ar", "fibertel.com.ar", "speedy.com.ar", "arnet.com.ar",

    /* Domains used in Mexico */
    "yahoo.com.mx", "live.com.mx", "hotmail.es", "hotmail.com.mx", "prodigy.net.mx",

    /* Domains used in Brazil */
    "yahoo.com.br", "hotmail.com.br", "outlook.com.br", "uol.com.br", "bol.com.br", "terra.com.br", "ig.com.br", "itelefonica.com.br", "r7.com", "zipmail.com.br", "globo.com", "globomail.com", "oi.com.br"
  ];
  static isDomainCommon = (domain) => {
    return Common.common_domains.includes(domain);
  }

  // usage:
  //    await Common.setTimeout( 1 );
  //    ... do stuff...
  static setTimeout( milliseconds ) {
    return new Promise( (rs, rj) => setTimeout( () => rs(), milliseconds ) );
  }

  static clamp( val, lo, hi ) {
    return val < lo ? lo : hi < val ? hi : val;
  }

  // subscribe to an @Output emitter using code (instead of html template)
  // usage:
  //   Common.subscribe( pdfViewer, 'click', e => console.log( "clicky!" ) )
  static subscribe( component, name, func ) {
    if (component[name] === undefined)
      component[name] = new EventEmitter();
    component[name].subscribe( e => func( e ))
  }

  static tagAnalytics (tags, tagHotJar = true, tagNewRelic = true) {
    try {
      let hjTags = [];
      for(let key in tags) {
        hjTags.push(`${key}:${tags[key]}`);
        if(tagNewRelic === true && newrelic) {
          newrelic.setCustomAttribute(key, tags[key]);
        }
      }
      if(hjTags.length > 0 && tagHotJar === true) {
        if(typeof window['hj'] === 'function') {
          window['hj']('tagRecording', hjTags);
        }
      }
    }
    catch (ex) {
      console.log("error tagging analytics... seems like something DevOps should fix...");
      console.log(ex);
    }
  }
}
