<div *ngIf='links'>
	<div class="d-inline-flex align-items-center heading-size-16 pb-2">Quick Links
		<i class='material-icons info pointer' data-toggle="tooltip" placement="bottom"
		[ngbTooltip]="'Recently visited and added areas of interest or participation'">info</i>
	</div>

	<!-- AVIA community link -->
	<div *ngIf="avia"
		class="d-flex align-items-center mb-3">
		<div class="d-flex align-items-center pointer" [routerLink]="['/communities/page', 1]">
			<img class="logo mr-1"
			[src]="avia.logo"
			onerror="this.src='/assets/placeholder-company.jpg'"/>AVIA Community
		</div>

	</div>
	<!-- PULSE -->
	<div *ngIf="org?.type === 1 && keychain_hs?.pulse?.e"
		class="d-flex align-items-center my-3">
		<div class="d-flex align-items-center pointer" [routerLink]="['/dashboard/hs/', org?.id]" [queryParams]="{activeTab:'Digital Pulse'}">
			<img
			class="logo mr-1"
			[src]="'/assets/pulse/pulse-icon.svg'"/>Digital Pulse
		</div>
	</div>
	<div *ngFor="let link of links">

		<div [ngSwitch]="link.link_type">

			<!-- INVENTORY -->
			<div *ngSwitchCase="'inventory'" class="d-flex my-3">
				<div class="pointer d-inline-flex" [routerLink]="['/dashboard/hs/', link.hs_id, 'inventory', link.id]">
					<div class="d-flex align-items-center justify-content-center logo mr-1 rounded" [ngStyle]="{'background-color': '#'+link.color}">
						<i class="material-icons text-white no-select md-12">done_all</i>
					</div>
					<span>{{link.name}}</span>
				</div>
			</div>

			<!-- SOLCO -->
			<div *ngSwitchCase="'company'" class="d-flex my-3">
				<div class="d-flex pointer" [routerLink]="['/sc', link.id]">
					<img class="logo mr-1"
					[src]="link.logo"
					onerror="this.src='/assets/placeholder-company.jpg'"/>
					{{link.name}}
				</div>
			</div>

			<!-- WORKSPACE -->
			<div *ngSwitchCase="'workspace'" class="d-flex my-3">
				<div [routerLink]="['/ws/', link.id]" class="pointer d-flex">
					<img class="logo mr-1"
						[src]="link.logo"
						onerror="this.src='/assets/placeholder-company.jpg'"
					/>{{link.name}}
				</div>
			</div>

			<!-- PRIORITY -->
			<div *ngSwitchCase="'priority'" class="d-flex my-3">
				<div class="pointer d-inline-flex" [routerLink]="['/dashboard/hs/', link.hs_id, 'priority', link.id]" >
					<div class="d-flex align-items-center justify-content-center logo mr-1 rounded" [ngStyle]="{'background-color': '#'+link.color}">
						<i class="material-icons text-white no-select md-12">done</i>
					</div>
					<span>{{link.name}}</span>
				</div>
			</div>

			<!-- TOPIC -->
		<div *ngSwitchCase="'topic'" class="d-flex my-3">
			<div class="pointer d-inline-flex" [queryParams]="{ id: link.id, activeTab: 'Feed' }" [routerLink]="['/intelligence/km/graph']">
				<div class="d-flex mr-1 rounded align-items-center justify-content-center logo" [ngSwitch]="link.type" [ngClass]="{
					'bg-blue-pulse': (link.type == 1),
					'bg-orange-dark': (link.type == 2),
					'bg-teal-dark': (link.type == 3),
					'bg-violet-dark': (link.type == 4),
					'bg-green-dark': (link.type == 5),
					'bg-red-dark': (link.type == 6)}">
					<i *ngSwitchCase="1" class="material-icons text-white md-12">extension</i>
					<i *ngSwitchCase="2" class="material-icons text-white md-12">vpn_key</i>
					<i *ngSwitchCase="3" class="material-icons text-white md-12">important_devices</i>
					<i *ngSwitchCase="4" class="material-icons text-white md-12">trending_up</i>
					<i *ngSwitchCase="5" class="material-icons text-white md-12">build</i>
					<i *ngSwitchCase="6" class="material-icons text-white md-12">insert_chart</i>
				</div>
				{{link.name}}
			</div>
		</div>

		<!-- <div *ngSwitchDefault>output2</div> -->
		</div>
	</div>

	<!-- FIND NEW INTERESTS -->
	<div class="d-flex align-items-center my-3">
		<app-avia-interests-modal>
			<button class="pointer btn btn-gray-200 px-2 py-1"><span class="text-primary font-size-12">Find new interests</span></button>
		</app-avia-interests-modal>
	</div>

	<div *ngIf="org?.type == 1 && org?.membership?.member_type == null" class="p-1 d-flex flex-wrap align-items-center criss-cross rounded mb-2">
		<!-- PLACEHOLDER FOR CRISS CROSS -->
		<div class="text-white heading-size-15 m-auto">
			Get More With <img width="40" height="40" class="pb-1" alt="AVIA" src="/assets/AVIA-logo-white.svg" /> Membership
		</div>
		<div [routerLink]="['/communities/page/68']" class="pointer p-1 px-2 btn btn-primary font-size-12 mx-auto">
			Learn more
		</div>
	</div>
</div>
