<div *ngIf="mode === 'create'" class="card mb-4 p-4 w-100 shadow-none border border-gray-300">
	<div class="d-flex">
		<img
		*ngIf="show_avatar"
		class="avatar-img rounded mr-2"
		onerror="this.src='../../../assets/default-user.jpg'"
		[src]="fs?.aviaService?.session?.user?.avatar"
		/>
		<div class="w-100">
			<div (click)="open()">
				<input class="w-100 input-box pointer" disabled type="text" placeholder="{{ fs?.config?.create?.types?.includes(2) ? 'Ask question or create post' : 'Ask question' }}">
			</div>

			<div class="mt-2">
				<button
				*ngIf="fs?.config?.create?.types?.includes(2)"
				(click)="swapTypes(2); open();"
				type="button"
				class="btn btn-sm pm-blue-button mr-2">
					<span class="center-btn-text">Create post</span>
				</button>
				<button
				*ngIf="fs?.config?.create?.types?.includes(4)"
				(click)="swapTypes(4); open();"
				type="button"
				class="btn btn-sm pm-blue-button">
				<span class="center-btn-text">Ask question</span>
			</button>
		</div>
	</div>
</div>
</div>

<ng-template #postmodal let-modal>
	<!----- main ----->
	<ng-container>
		<div [ngClass]="{'d-none': section !== 1}">
			<div class="modal-header pb-0" style="flex-shrink: 0;">
				<div class="d-block">
					<div class="d-flex mb-4">
						<img
							*ngIf="show_avatar"
							class="avatar-img-lg rounded mr-4"
							onerror="this.src='../../../assets/default-user.jpg'"
							[src]="fs?.aviaService?.session?.user?.avatar"
						/>
						<div>
							<div class="font-weight-bold font-size-13 pb-1">
								<a target="_blank" href="{{ fs?.aviaService?.session?.user?.id ? '/profiles/in/'+ fs?.aviaService?.session?.user?.id : '#'}}" (click)="have_user($event)">
									<span>{{fs?.aviaService?.session?.user?.fullname}}</span>
									<span *ngIf="fs?.aviaService?.session?.user?.title">, {{fs?.aviaService?.session?.user?.title}}</span>
								</a>
								<!-- (click)="changeSection(3)" -->
								<span *ngIf="fs?.aviaService?.session?.org?.name">
									<span>
										<span class="border-left mx-2 border-gray-400"></span>
										{{fs?.aviaService?.session?.org?.name}}
									</span>
								</span>
							</div>
							<div *ngIf="mode === 'create'" class="d-flex align-items-center pt-4 pt-sm-0">
								<span class="text-blue-dark pointer font-size-12" (click)="changeSection(2)"
									joyrideStep="tour1step1"
									title="Visibility Settings:"
									text="Adjust who is able to see this post - whether it’s visible to Providers and AVIA, or visible to all Connect users."
								>
									Sharing To {{visibility.name}}
								</span>
							</div>
						</div>
					</div>
					<div class="d-flex">
						<button
							*ngIf="(mode === 'create' || post?.type === 2) && fs?.config?.create?.types?.includes(2)"
							(click)="swapTypes(2)"
							type="button"
							class="btn btn-l"
							[ngClass]="{'btn-primary text-white': type === 2, 'btn-gray-100 text-gray-600': type !== 2}">
							<span
								class="center-btn-text"
							>Create post</span>
						</button>
						<button
							class="btn btn-r"
							[ngClass]="{'btn-primary text-white': type === 4, 'btn-gray-100 text-gray-600': type !== 4}"
							*ngIf="(mode === 'create' || post?.type === 4) && fs?.config?.create?.types?.includes(4)"
							(click)="swapTypes(4)"
							type="button"
							joyrideStep="tour1step2"
							title="Question:"
							text="Ask a question to get answers from the network. We sent questions to anyone with experience, or interest in the topic, to help get you answers."
						>
							<span
								class="center-btn-text"
							>Ask Question</span>
						</button>
					</div>
				</div>

				<button type="button" class="close" (click)="modal.dismiss('dismissed')">
					<span class="material-icons md-24">close</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="d-flex">
					<div class="w-100">
						<div>
							<app-post-textarea
								#my_post
								ngbAutofocus
								placeholder="{{type === 4 ? 'Ask Question': 'Create post'}}"
								class="w-100"
								[text_box_size]="'lg'"
								[send_button]="false"
								[media_preview]="true"
								tabindex="0"
								[content]="post?.data?.content"
								[tags]="post?.data?.tags"
								[media]="post?.data?.media"
								(submit)="createOrEditPost(my_post.value); my_post.clear(); false;"
								>
							</app-post-textarea>
						</div>
						<div class="mt-4">
							<button
								(click)="modal.dismiss('dismissed')"
								type="button"
								class="btn btn-gray-100">
									<span class="center-btn-text text-gray-800">Cancel</span>
							</button>

							<button
								(click)="createOrEditPost(my_post.value); my_post.clear(); false;"
								type="button"
								class="btn btn-primary float-right">
									<span *ngIf="mode === 'create'" class="center-btn-text">Post {{type === 4 ? 'question': ''}}</span>
									<span *ngIf="mode === 'update'" class="center-btn-text">Save {{type === 4 ? '': 'post'}}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="type === 4 && fs?.aviaService?.session?.org?.type === 1" class="modal-footer d-block bg-gray-100 px-1 py-2">
				<p class="text-center font-size-13 text-gray-800">To reduce spam or solicitation your name will not be shown to companies on any questions or answers</p>
			</div>
		</div>
	</ng-container>
	<!----- privacy ----->
	<ng-container>
		<div [ngClass]="{'d-none': section !== 2}">
			<div class="py-4 pl-0 mx-4 modal-header border-bottom border-gray-300">
				<div class="d-inline-flex w-100">
					<button type="button" class="close pl-0 pr-5" (click)="changeSection(1)">
						<span class="material-icons md-24">keyboard_backspace</span>
					</button>
					<span class="heading-size-18 w-100">Select privacy</span>
					<button type="button" class="close pr-0" (click)="modal.dismiss('dismissed')">
						<span class="material-icons md-24">close</span>
					</button>
				</div>
			</div>

			<div class="modal-body">
				<div class="form-check p-3 pointer" *ngFor="let option of visibility_options" [ngClass]="{'check-blue': visibility.name == option.name}" (click)="storeStatusChange(option.name)">
					<input class="pointer form-check-input d-none" type="radio" [checked]="visibility.name == option.name"  name="option.name"/>
					<label class="pointer form-check-label" for="visibilityOptions">
						<strong>{{option.name}}</strong>
						<div>{{option.details}}</div>
					</label>
				</div>

			</div>
			<div class="modal-footer"></div>
		</div>
	</ng-container>
	<!----- indentity ----->
	<ng-container>
		<div [ngClass]="{'d-none': section !== 3}">
			<div class="modal-header">indentity</div>
			<div class="modal-body"></div>
			<div class="modal-footer" (click)="changeSection(1)">back</div>
		</div>
	</ng-container>
</ng-template>
