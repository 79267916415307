import { GlobalNavbarService } from './../../../global-navbar.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-navbar-myorg',
  templateUrl: './mobile-navbar-myorg.component.html'
})
export class MobileNavbarMyorgComponent implements OnInit {

  constructor(public gns: GlobalNavbarService) { }

  ngOnInit() {
  }

}
