//import { AuthGuardService } from './../auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CheckEmailComponent } from './components/check-email.component';
import { SignupComponent } from './components/signup.component';
import { SignupLandingComponent } from './components/signup-landing.component';
import { SignupOtherComponent } from './components/signup-other.component';
import { SignupWrapperComponent } from './signup-wrapper.component';


const SIGNUP_ROUTES: Routes = [
  {
    path: 'signup',
    component: SignupWrapperComponent,
    children: [
      { path: '', component: SignupLandingComponent },
      { path: 'hs', component: SignupComponent },
      { path: 'solco', component: SignupComponent },
      { path: 'other', component: SignupOtherComponent },
      { path: 'check-email', component: CheckEmailComponent },
      { path: '**', redirectTo: '/signup', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(SIGNUP_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class SignupComponentRoutingModule { }
