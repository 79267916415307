import { AVIAConnectService } from '../../../../../../avia-connect.service';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Content, AnalyticEvent } from '../../../../../../../class';
import * as moment from 'moment';

@Component({
  selector: 'app-content-share-modal',
  templateUrl: './content-share-modal.component.html',
  styleUrls: ['./content-share-modal.component.scss']
})
export class ContentShareModalComponent {
  @Input()
    set post(data) {
      this._post = data;
      if(this._post.id && this.aviaService.session) {
        this.buildLink();
        this.mail_to = this.buildMailTo();
      }
    }
    get content() { return this._post; }
  _post;

  avia_connect_link: string;
  link_copied: boolean = false;
  mail_to: string;

  @ViewChild('content', { static: true }) content_modal: NgbModal;
  modal_ref: NgbModalRef;

  constructor(private modalService: NgbModal, public aviaService: AVIAConnectService) { }

  open() {
    let options: NgbModalOptions = {
      windowClass: 'avia-modal-center avia-modal-md',
      beforeDismiss: this.setCopiedFalse
    };
    this.link_copied = false;
    this.modal_ref = this.modalService.open(this.content_modal, options);
  }

  buildMailTo(): string{
    const name = this.aviaService.session.user ? this.aviaService.session.user.fullname : 'An AVIA connect user';

    if(this._post.type == 7 && this._post.data.content_item) {
      return encodeURI('mailto:?subject=' + name +
      ' shared an article with you!&body=' + name +
      ' shared an article with you.\n\n' + this._post.data.content_item.name + ' by ' + this._post.data.content_item.author +
      '\nPublished on ' + moment(this._post.data.content_item.published_date).format('MMM Do, YYYY') +
      '\nLink: ' + this._post.data.content_item.link +
      '\n\n' + this._post.data.content_item.description +
      '\n\nThis article was found on AVIA Connect: ' + this.avia_connect_link
      )
    }
    else {
      return "";
    }
  }

  buildLink():void {
    if(this._post.type == 7) {
      this.avia_connect_link = `${this.aviaService.frontUrl}/intelligence/cl/search?id=${this._post.data.content_id}`;
    }
    else {
      let url = `${this.aviaService.frontUrl}/start`;

      if(this._post.data.rootTag) {
        url = this._post.data.rootTag.url;
      }
      else if(this._post.data.tags && this._post.data.tags.length > 0) {
        for(let tag of this._post.data.tags) {
          if(tag.user_id == null) {
            url = tag.url;
          }
        }
      }

      //make sure this doesn't cause a crash; fallback to /start;
      try {
        if(URL) {
          let urlObject = new URL(url);
          if(urlObject.searchParams) {
            urlObject.searchParams.append("feed_ids", JSON.stringify([this._post.id]));
          }
          this.avia_connect_link = urlObject.href;
        }
      }
      catch (ex) {
        this.avia_connect_link = `${this.aviaService.frontUrl}/start`;
      }

    }
  }

  async copyLink() {
    this.link_copied = true;
    await navigator.clipboard.writeText(this.avia_connect_link);
  }

  //ANALYTICS FOR SHARES PER CARD//
  logShares() {
    let obj = new AnalyticEvent(
      'card_share',
      {comment_id: this._post.id}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

  setCopiedFalse(): boolean {
    this.link_copied = false;
    return true;
  }

}
