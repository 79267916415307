// angular imports
import { ErrorHandler, Inject, Injectable, Injector, ElementRef, Renderer2, ApplicationRef } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { RouterOutlet } from '@angular/router';

// node_modules imports
import * as StackTrace from 'stacktrace-js';
import * as moment from 'moment';
import * as util from 'util'; // output JSON with circular refs:  util.inspect( obj )

// avia imports
import { AVIAConnectService } from './../../avia-connect.service';
import { Common } from '../../common';
import { environment } from '../../../environments/environment';

export class AuthorizationError {
  toString() {
      return 'You are not authorized to view this content!!!';
  }
}

// TO DISABLE:  see the line with AVIAErrorHandlerComponent in app.module.ts, and comment it out there.  Dont forget to recomment it before checking in.
@Injectable()
export class AVIAErrorHandlerComponent extends ErrorHandler {

  router:Router;
  //elRef:ElementRef = new ElementRef();
  renderer:Renderer2;
  isProd:boolean = environment.production;
  launchedCrashScreenOfDeath = false;

  constructor( private injector: Injector, @Inject(AVIAConnectService) private aviaService: AVIAConnectService ) {
    super();
  }

  // TO DISABLE:  see AVIAErrorHandlerComponent in app.module.ts
  handleError(error) {
    // manually inject some things (interesting, we can do this! leaving this here as an example)
    //const aviaService = this.injector.get( AVIAConnectService );
    if(this.aviaService.deviceInfo.browser === "firefox" && error.message === 'Permission denied to access property "nodeType"' && error.stack.indexOf("traverseUp") === 0){
      //silently fail for this specific firefox error
      console.log(error);
      return;
    }
    const chunkFailMessage = /Loading chunk [\d]+ failed/;

    if(chunkFailMessage.test(error.message)) {
      window.location.reload();
      return;
    }
    // Output error to dev console, and Launch the crash screen of death, once only within a single browser session...
    if (!this.launchedCrashScreenOfDeath) {
      this.launchedCrashScreenOfDeath = true;

      // Output the error to the dev console
      const location = this.injector.get(LocationStrategy);
      const message = error.message ? error.message : error.toString();
      const url = location instanceof PathLocationStrategy ? location.path() : '';

      super.handleError( error );

      // wait some millisecs before popping up the modal, for a satisfying delay that just feels right :)
      setTimeout(() => {
        // FILTER, logic for auto-reload, if too many errors (across browser sessions) within a time period for the same URL, then we disable auto reload.
        // count number of reloads on this URL to prevent infinite siezure inducing reloads...
        let key:string = 'AviaCrashCount';
        const crashSetupData = { count: 0, url: url, time: moment.now() };
        let crash:any = JSON.parse( this.aviaService.getLocalValue( key, JSON.stringify( crashSetupData ) ) );
        if (!Common.isObject( crash ))
          crash = crashSetupData;
        let reload = true;
        // reset the counter if time has elapsed, or if trying a new url
        if (moment( crash.time ).add(1,'minute') < moment( moment.now() ) || crash.url !== url) {
            crash.count = 0;
        }
        // if too many crashes, stop trying to reload
        if (2 <= crash.count) {
          reload = false;
        }
        // update the crash info...
        crash.time = moment.now();
        crash.count = crash.count !== undefined ? crash.count+1 : 0;
        crash.url = url;
        this.aviaService.saveLocalValue( key, JSON.stringify( crash ) );

        // call the app's Crash handler
        let ref:ApplicationRef = this.injector.get(ApplicationRef);
        ref.tick();
        this.aviaService.onCrash( url, message, error, reload );
        Common.tagAnalytics({"ohdear":"true"}, true, false);
        ref.tick();
        ref.tick();
        ref.tick();
        ref.tick();
        ref.tick();
        ref.tick();
      }, 500);

      StackTrace.fromError(error).then((stackframes)=>{
        let mappedTrace = stackframes.reduce(function(filtered, sf) {
          if(sf.fileName.indexOf("webpack:///src/app") > -1) {
            filtered.push(`    at ${sf.toString()}`);
          }
          return filtered;
        },[]);
        mappedTrace = [error.stack.split("\n")[0], ...mappedTrace];
        let mapped_stackTrace = mappedTrace.join("\n");

        if(mapped_stackTrace.length > 255) {
          mapped_stackTrace = mapped_stackTrace.substring(0, 254) + "…";

        }
        console.log("DONE!");
        this.aviaService.newrelicError( error, {"ohdear":"true", "mapped_stackTrace":mapped_stackTrace} );
      })
    }
  }
}
