<div class="d-flex">
	<ng-container *ngIf="!focused">
		<app-user-tag
			*ngIf="show_avatar"
			[show_text]="false"
			[show_date]="false"
			[show_avatar]="true"
			[size]="avatar_size"
			[user]="avia_session?.user"
			class="mr-2">
		</app-user-tag>
		<input class="input-box w-100" (focus)="focusTextArea()" type="text" [placeholder]="placeholder + fs.getVisibilityText(thread_visibility)"/>
	</ng-container>
	<ng-container *ngIf="focused">
		<app-user-tag
			*ngIf="show_avatar"
			[show_text]="false"
			[show_date]="false"
			[show_avatar]="true"
			[size]="avatar_size"
			[user]="avia_session?.user"
			class="mr-2">
		</app-user-tag>
		<app-post-textarea
			#new_reply
			class="w-100"
			[placeholder]="placeholder + fs.getVisibilityText(thread_visibility)"
			[content]="(auto_tag && !comment?.data?.content) ? buildReplyHTML(reply_owner?.user_id, reply_owner?.fullname) : comment?.data?.content"
			[tags]="(auto_tag && !comment?.data?.tags) ? [{user_id: reply_owner?.user_id, name: reply_owner?.fullname}] : comment?.data?.tags"
			[send_button]="true"
			[focus_on_init]="true"
			[hide_verbose_tags]="true"
			(submit)="reply(new_reply.value); new_reply.clear(); false"
			(blur)="unfocusTextArea()">
		</app-post-textarea>
	</ng-container>
</div>
