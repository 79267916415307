<div class="bg-gray-100 w-100">
  <div class="scroll-container">
    <div class="jumbotron d-flex flex-column w-100 mb-0 justify-content-center">
      <div class="container text-center">
        <h1 class="text-white">About AVIA</h1>
      </div>
    </div>
    <div class="col-12 col-md-10 mx-md-auto">
      <div class="mt-4">
				<div class="font-weight-bold">About AVIA</div>
				<p>AVIA leads a network of health systems committed to transforming healthcare through actionable innovation. By participating in the AVIA Innovator Network, Members work alongside AVIA and one another to incorporate a digital-first mindset into their strategies. They partner with us to help them identify, implement, and scale the digital solutions that bring these strategies to life. In the process, our Members improve the effectiveness, experience, and sustainability of care delivery.</p>
				<div class="font-weight-bold">About Our Work with Solution Companies</div>
				<p>AVIA is stage-agnostic and has partnered with solution companies specializing in many areas of digital health. Our primary goal is to identify the digital solutions that can best meet our Members’ most important challenges. As a result, their needs dictate the market segments we explore and the solution companies that we engage. Any prior financial relationships with solution companies are disclosed upfront in our process, and our Members ultimately select the solution that best fits their needs. If selected, solution companies have the option to become non-exclusive partners with AVIA. We are committed to a transparent process that aligns the interests of AVIA, health systems, and solution companies.</p>
			</div>
    </div>
  </div>
</div>





