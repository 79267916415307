<div class="border border-gray-300 text-center connection-card p-4 p-lg-5 position-relative w-100">
	<div class="d-inline-flex mr-2 rounded">
		<img class="rounded avatar"[src]="user?.avatar"/>
	</div>
	<div class="heading-size-16 text-center pt-2 pt-md-4">{{ user?.fullname }}</div>
	<div class="font-size-12">{{ user?.title }}<span *ngIf="user?.org_obj?.name && user?.title" class="dot bg-gray-400"></span>{{ user.org_obj ? user.org_obj?.name : ''}}</div>
	<div class="w-100 position-absolute text-center connect-btn-div">
		<div>
			<app-send-message-button [user]="user">
				<button (click)="userMessageButton(user)" send-button class="btn profile-btn btn-blue-dark font-size-12 py-1 px-2 connect-btn">
					Message
				</button>
				<button (click)="userConnectButton(user)" connect-button class="btn profile-btn btn-green-lime btn-respond font-size-12 py-1 px-2 connect-btn">
					Connect
				</button>
				<button disabled sent-button class="btn profile-btn btn-blue-dark btn-respond font-size-12 py-1 px-2 connect-btn" style="background-color: rgba(0, 102, 163, 0.55);cursor: not-allowed;border: hsla(0,0%,100%,.45);">
					Invite Sent
				</button>
			</app-send-message-button>
		</div>
	</div>
</div>
