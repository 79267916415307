<!-- The Search Box -->
<app-avia-orgs-search
	*ngIf="!disabled && (multi_select || (!multi_select && selected_list.length === 0))"
	[options]="{ text: prompt_string }"
	[org_types]="org_types"
	(done)="updateOrgs($event)"
></app-avia-orgs-search>
<ng-container *ngFor="let item of validation">
	<small *ngIf="item.condition" class="form-text text-danger">{{item.message}}</small>
</ng-container>
<!-- The List -->
<!--
<div *ngIf="!loading">
	<div *ngFor="let org of selected_list">
		<app-avia-hs-row
			[akey]="akey"
			[hs]="org"
			[options]="{ view_type: 'list', allow_remove: !disabled }"
			(done)="updateOrgs($event)"
		></app-avia-hs-row>
	</div>
	<div *ngIf="!selected_list || selected_list.length === 0"
		class="pt-4">
		<i class="text-gray-600">No organizations selected at this time.</i>
	</div>
</div>
-->
<!-- The List -->
<ng-container *ngIf="selected_list.length > 0">
	<div *ngFor="let item of selected_list" class="pill-list">
		<div *ngIf="item.name" class="pill-list-body">
			<div class="pill-list-icon"><img onerror="this.src='/assets/placeholder-org.jpg'" [src]="item.logo" /></div>
			<div class="pill-list-text">{{ item.name }}</div>
			<i *ngIf="!disabled" class="pill-list-close material-icons" (click)="updateOrgs({'action':'delete', 'data':item, 'type': undefined, 'values':[item.id]})">close</i>
		</div>
	</div>
</ng-container>
<!-- The Loading Indicator -->
<ng-container *ngIf="loading">
	<ng-container *ngTemplateOutlet="loadingTpl"></ng-container>
</ng-container>

<!-- angular8: added missing template outlet #loadingTpl (used above) to fix broken 'npm run build-prod' -->
<!-- LOADING INDICATOR TEMPLATE -->
<ng-template #loadingTpl>
	<div *ngIf="loading">
		<app-avia-loader [options]="{type: 'row', rows: 4}"></app-avia-loader>
	</div>
</ng-template>
