<div class="position-relative d-flex w-100">
	<!-- AVATAR -->
	<app-user-tag [show_text]="false"
		[size]="avatar_size"
		[user]="comment?.data?.user">
	</app-user-tag>
	<div class="d-flex ml-2 w-100">
		<div class="p-2 bg-gray-100 w-100 rounded" [ngClass]="{'answer-bg':comment?.type == 5}">
			<!-- USER Name -->
			<app-user-tag [show_avatar]="false"
				[show_date]="false"
				[size]="avatar_size"
				[user]="comment?.data?.user"
				[created]="comment?.created">
			</app-user-tag>
			<div class="post-header">
				<ng-content select="[post-header]"></ng-content>
			</div>
			<!-- Content -->
			<div *ngIf="!editing" class="font-size-14" [innerHTML]="comment?.data?.content"></div>
			<app-reply
				*ngIf="editing"
				[mode]="'update'"
				[parent_id]="comment?.parent_id"
				[type]="comment?.type"
				[comment]="comment"
				[focused]="true"
				(updateItem)="updateComment($event)"
				[show_avatar]="false"
			></app-reply>

		</div>
	</div>
	<div *ngIf="comment?.data?.user?.user_id === fs?.aviaService?.session?.user?.id" ngbDropdown placement="bottom-right" class="feed-dropdown position-absolute more pointer right-0">
		<div ngbDropdownToggle>
			<i class="material-icons md-24">more_vert</i>
		</div>
		<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right p-0">
			<button class="dropdown-item" (click)="toggleEditing()">
				<i class="material-icons md-24 pr-1">edit</i>Edit
			</button>
			<button class="dropdown-item" (click)="openAlertModal()">
				<i class="material-icons md-24 pr-1">remove_circle</i>Delete
			</button>
		</div>
	</div>
</div>

<app-avia-alert-modal #alertModal
	[button_confirm_text]="'Yes, I am sure.'"
	[modal_title]="'Delete Comment'"
	[show_cancel_button]="true"
	[type]="E_AlertModalTypes.ERROR"
	(confirmEmit)="deleteComment($event)"
	>
	<div class="row justify-content-center">
		<div class="col-12 text-center">
			Are you sure you want to delete this comment?
		</div>
	</div>
</app-avia-alert-modal>
