<!-- SELECTED ITEM -->
<ng-container #selectedOrg *ngIf="!focused; else orgsList">
	<div class="orgsDropdown form-control pointer position-relative">
		<i class="material-icons down position-absolute">arrow_drop_down</i>
		<div *ngIf="_selected_org; else placeholderText" class="org fade-target d-flex" (click)="focused = true">
			<img alt="Logo" class="orgLogo mr-1" onerror="this.src='/assets/placeholder-company.jpg'" [src]="_selected_org?.logo" />
			<div class="title w-100">{{ _selected_org?.name }}</div>
		</div>
		<ng-template #placeholderText>
			<p class="m-0" (click)="focused = true">{{placeholder}}</p>
		</ng-template>
	</div>
</ng-container>

<!-- DROPDOWN LIST -->
<ng-template #orgsList>
	<div class="orgsDropdown form-control h-100 p-0 pointer position-relative">
		<i class="material-icons up position-absolute">arrow_drop_up</i>
		<div class="orgsList overflow-auto p-2">
			<div class="list-item border-bottom" *ngFor="let o of _orgs" (click)="writeValue(o.id);">
				<div class="org fade-target pointer p-2">
					<!-- Org Info -->
					<div class="d-flex">
						<img alt="Logo" class="orgLogo mr-1" onerror="this.src='/assets/placeholder-company.jpg'" [src]="o.logo" />
						<div class="title w-100" [ngClass]="{'font-weight-bold': _selected_org?.id === o.id}">{{ o.name }}</div>
					</div>

					<!-- Counts -->
					<div class="counts d-flex font-sm mt-1" *ngIf="o.avia_member_count || o.is_aha_member || o.is_avia_member">
						<span class="font-italic mr-3 text-gray-600" *ngIf="o.avia_member_count > 0">{{ o.avia_member_count | i18nPlural: memberCountMap }}</span>
						<ng-container *ngIf="o.is_avia_member">
							<img alt="AVIA Logo" class="aviaLogo mr-1" src="/assets/circle-icons/avia.png" />
							<span class="mr-3">AVIA Member</span>
						</ng-container>
						<ng-container *ngIf="o.is_aha_member">
							<img alt="AHA Logo" class="ahaLogo mr-1" src="/assets/pulse/aha/aha.png" />
							<span>AHA Member</span>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
