import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AVIAConnectService } from '../../../avia-connect.service';
import { Org, AnalyticEvent } from '../../../../class';


@Component({
  selector: 'app-avia-user-invite-modal-row',
  templateUrl: './avia-user-invite-modal-row.component.html',
  styleUrls: ['./avia-user-invite-modal-row.component.scss']
})
export class AviaUserInviteModalRowComponent implements OnDestroy, OnInit {
  readonly VERBOSE: boolean = false;

  @Input() form_group: FormGroup; // Must come first

  @Input() email_invalid:     boolean = false; // user_form.controls['email'].invalid && ( user_form.controls['email'].dirty || user_form.controls['email'].touched )
  @Input() firstname_invalid: boolean = false; // user_form.controls['firstname']?.invalid && !user_form.pristine
  @Input() lastname_invalid:  boolean = false; // user_form.controls['lastname']?.invalid && !user_form.pristine


  @Input() invitees_org:      Org     = new Org();
  @Input() show_org_chooser:  boolean = false;
  @Input() show_type_chooser: boolean = false;
  @Input() types:             any[]   = [];

  @Input() // triggerSubmit
    get triggerSubmit(): boolean { return this._trigger_submit; }
    set triggerSubmit(value: boolean) {
      this.VERBOSE && console.log('triggerSubmit: ', value);
      if (value) this.onSubmit();
    };
    _trigger_submit: boolean = false;


  @Output('onRemove')         on_remove:          EventEmitter<any> = new EventEmitter();
  @Output('onSubmitComplete') on_submit_complete: EventEmitter<any> = new EventEmitter();
  @Output('onUpdateOrg')      on_update_org:      EventEmitter<any> = new EventEmitter();

  row_id:          number = 0;
  success_content: string;
  failure_content: string;

  constructor( private aviaService: AVIAConnectService ) {};

  ngOnInit(): void {
    this.VERBOSE && console.log('avia-user-invite-modal-row-component initialized');
    this.failure_content = undefined;
    this.success_content = undefined;
    this.row_id = this.form_group.get('row_id').value;
  };

  ngOnDestroy(): void {
    this.VERBOSE && console.log('avia-user-invite-modal-row-component destroyed');
    this.failure_content = undefined;
    this.success_content = undefined;
  };


  // === DATA HANDLING ===

  removeMe(): void {
    this.on_remove.emit({row_id: this.row_id});
  };

  updateOrg($event:any): void {
    this.VERBOSE && console.log('updateOrg:', $event);
    this.on_update_org.emit({org: $event.data, row_form: this.form_group, row_id: this.row_id});
  };

  async onSubmit(): Promise<any> {
    this.failure_content = undefined;
    this.success_content = undefined;
    this._trigger_submit = false;

    let invite_obj = {
      email:     this.form_group.get('email').value.trim(),
      firstname: this.form_group.get('firstname').value.trim(),
      lastname:  this.form_group.get('lastname').value.trim(),
      org:       this.form_group.get('org').value,
    };
    let add_new_owner = this.show_type_chooser ? (parseInt(this.form_group.get('is_owner').value) === 1 ? true : false) : false;
    let add_user_resp = await this.aviaService.addUserByOrg(invite_obj.org, invite_obj);

    // ANALYTIC EVENT for user status change
    let event = new AnalyticEvent(
      'user_status_change',
      {user_id: add_user_resp.body.id, previous_status: null, current_status: add_user_resp.body.status}
    );
    this.aviaService.createAnalyticEvent(event);
    this.VERBOSE && console.log(add_user_resp);

    if(add_user_resp.status == 200) {
      let add_new_owner_resp: any;
      if (add_new_owner) {
        // User Added, now add new owner
        add_new_owner_resp = await this.aviaService.addOrgOwner(add_user_resp.body.org, add_user_resp.body.id);
      } else {
        // User added as new member (fake the 'add owner' response for easier messaging)
        add_new_owner_resp = await new Promise((rs, rj) => rs({ body: {
          message: 'Success',
          reason: add_user_resp.body.org === 1 ? 'added as an AVIAn' : 'added as a member'
        } }))
      }
      this.success_content = `${add_user_resp.body.email} was ${add_new_owner_resp.body.reason}`;
    } else if (add_user_resp.status == 403) {
      // User NOT added - Invalid Domain
      this.VERBOSE && console.log(add_user_resp);
      this.failure_content = add_user_resp.body.reason ? add_user_resp.body.reason : 'Something went wrong!'
    } else {
      // User NOT added - Generic Error
      this.VERBOSE && console.log(add_user_resp);
      this.failure_content = add_user_resp.body.reason ? add_user_resp.body.reason : 'Something went wrong!'
    }

    this.form_group.get('successful').patchValue( this.success_content !== undefined ? true : false );
    this.form_group.get('successful').updateValueAndValidity({emitEvent:false});

    this.on_submit_complete.emit({ row_id: this.row_id });
  };

}
