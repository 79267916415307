<div class="mx-6">
<div class="media d-block d-sm-flex">
  <img class="d-flex mx-auto mb-4 mb-sm-0 mr-sm-4" src="{{ img_url }}" alt="Search">
  <div class="media-body pl-0 pl-sm-4 vertical-line-left">
		<h5 class="mt-0 text-center text-sm-left">No results found.</h5>
		<h5 *ngIf="suggestion">Did you mean: <a class="text-blue-dark pointer" (click)="searchSuggestion()" [innerHTML]="suggestion.highlighted"></a></h5>
    <div class="header-under-dash d-block d-sm-none pt-4 pb-4">
        <div class="dash"></div>
      </div>
    <label><i>Tips to get the most out of search:</i></label>
    <ul class="pl-4">
      <li>Please make sure your keywords are spelled correctly.</li>
      <li>Try different keywords for topics, names, or content.</li>
			<li>Try fewer and less specific keywords.</li>
			<li>For companies, try using the url, like “apple.com”</li>
    </ul>
  </div>
</div>
</div>
