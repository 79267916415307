import { User } from './../../../../class';
import { FeedService } from './../../feed.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { AVIAConnectService } from '../../../avia-connect.service';
import { PostTextAreaComponent } from '../post-text-area/post-textarea.component';

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplyComponent implements OnInit {
  @Input() parent_id: number;
  @Input() reply_owner = undefined;
  @Input() type: number;
  @Input() placeholder: string = "Reply";
  @Input() comment;
  @Input() mode: string = 'create'; // create or update
  @Input() thread_visibility: any = [];
  @Input() show_avatar = true;
  @Input() focused: boolean = false;
  @Input() auto_tag: boolean = false;
  @Input() avatar_size: string = "md";
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('new_reply', { static: false }) new_reply: any;
  avatar: string;
  avia_session: any;
  updating = false;

  constructor(
    public fs: FeedService,
    private aviaService: AVIAConnectService
  ) { }

  async ngOnInit() {
    this.avia_session = await this.aviaService.getSessionSupport();
    this.avatar = this.avia_session.user.avatar;
  }

  buildReplyHTML(user_id, name) {
    if(!user_id || !name) return '';

    let tag = `<div><a href="/profiles/in/${user_id}" target="_blank">${name}</a>&nbsp;</div>`;
    return tag;
  }

  buildReplyTags(user_id, name) {
    if(!user_id || !name) return [];

    return [{ user_id, name }];
  }

  async reply(content) {
    if (this.updating == true) { return } // stop dups
    this.updating = true;
    let res;
    try { // add try catch so if error happens we can reset focus and updating
      if(this.mode === 'create') {
        let reply_type = 3;
        if(this.type == 4) reply_type = 5;
        if((content.html && content.text && content.text.trim().length > 0) || content.media) {
          res = await this.fs.createPost({content:content.html, tags:content.tags, media: content.media, parent_id: this.parent_id, type: reply_type});
        }
      }
      if (this.mode === 'update') {
        console.log('update: ' + content)
        res = await this.fs.editPost(this.comment.id, {content:content.html,tags:content.tags, media: content.media, type: this.comment.type});
      }
      this.updateItem.emit(res);
      this.focused = false;
      this.updating = false;
    } catch (error) {
      this.focused = false;
      this.updating = false;
    }
  }

  focus() {
    this.focused = true;
  }

  focusTextArea() {
    setTimeout(()=>{
      this.focused = true;
    }, 0);
  }

  unfocusTextArea() {
    if(this.focused == true && (!this.new_reply.value.text || this.new_reply.value.text.length < 1)) {
      this.focused = false;
    }
  }
}
