// @angular imports
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';

// node_module imports
import * as moment from 'moment';

// avia imports
import { Common } from './../../common';
import { AVIAConnectService } from './../../avia-connect.service';
import { Session, CommentGroup } from './../../../class';
import { ChatService } from './../../_services/chat.service';

// AVIA Chat
// @example start
//   <app-avia-chat #km_chat
//     [email_notify]="false"
//     [group_id]="1489"
//     [location]="'km'"
//     [location_id]="380"
//     [options]="{ message: 'message test'}">
//   </app-avia-chat>
// @example end
@Component({
  selector: 'app-avia-chat',
  templateUrl: './avia-chat.component.html',
  styleUrls: ['./avia-chat.component.scss'],
  providers: [ChatService]
})
export class AviaChatComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  set options(inputData: Avia_Chat_Options) {
    this._options = new Avia_Chat_Options();
    for (let i in inputData) {
      this._options[i] = inputData[i];
    }
  };
  get options(): Avia_Chat_Options {
    return this._options;
  };
  _options: Avia_Chat_Options = new Avia_Chat_Options();

  @Input() email_notify: boolean = false;
  @Input() group_id: number;
  @Input() location: string;
  @Input() location_id: number;
  @Input() send_button: boolean = false;

  @Output() count_change: EventEmitter<any> = new EventEmitter();
  @Output() loaded: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("chat_item", { static: true }) chat_item: any;

  VERBOSE: boolean = false;

  public place_holder: string;
  public dirty: boolean = false;

  constructor(public cs: ChatService) { }

  canDeactivate() {
    if(this.chat_item.nativeElement.value.length !== 0){
      return this.cs.commentNotEmpty();
    } else {
      // If no comment, navigate away
      return true;
    }
  }

  ngOnInit() {
    this.cs.initChat(this.location, this.location_id, this.group_id);
    this.cs.loaded.subscribe(() => {
      setTimeout(()=> { this.loaded.emit() }, 100) // The DOM needs time to render
    });
  }

  ngOnChanges() {
    this.VERBOSE && console.log('--- ngOnChanges Called ---')
    this.cs.initChat(this.location, this.location_id, this.group_id);
  }

  update_test = true;
  last_updated = 0;
  getThread() {
    if (this.update_test && (moment.now() - this.last_updated > 5000)) {
      this.last_updated = moment.now();
      this.cs.updateThread();
      this.update_test = false;
      // console.log('update_test: ', this.update_test)
    }
  }

  resetThreadTest() {
    this.update_test = true;
    //console.log('update_test reset: ', this.update_test);
  }

  // CRUD
  async postMessage(value, email_notify) {
    await this.cs.postMessage(value, email_notify);
    this.countChanged();
  }

  // For calling in a click event instead of keydown
  async postMessageClick() {
    this.dirty = false;
    let value = this.chat_item.value;
    await this.cs.postMessage(value, false);
    this.chat_item.value = '';
    this.countChanged();
  }

  async updateMessage($event){
    await this.cs.updateMessage($event.message_id, $event.content);
  }

  async deleteMessage($event) {
    await this.cs.deleteMessage($event.message_id);
    this.countChanged();
  }

  private countChanged() {
    this.count_change.emit({ changed: true });
  }

  ngOnDestroy() {
    this.cs.destroyChat();
  }
}

export class Avia_Chat_Options {
  constructor(
    public notify_on_count_change: boolean = true,
    public message: string = 'Add a comment...'
  ) { }
}
