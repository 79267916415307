<ng-template #ScAddEditImport class="sc-add-edit-import-modal d-block w-100" let-c="close" let-d="dismiss">
	<div [ngClass]="{
			'avia-modal-full-screen avia-modal-full-screen-inverse': _fullscreen,
			'avia-modal': !_fullscreen
		}"
		>
		<div class="page container-fluid bg-white">

			<div class="d-flex align-items-center headerblock row bg-gray-100">
				<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
				<span class="ml-auto backlink pointer" (click)="close()">
					<i class="mr-4 material-icons md-24" style="vertical-align: top;">close</i>
				</span>
			</div>

			<div class="titleblock row my-4 text-center">
				<div class="col-12">
					<p class="text-uppercase" *ngIf="title_text !== undefined">{{title_text}}</p>
					<h1 class="section-header-font">Company Profile</h1>
					<small class="d-block px-4">Fields that are left blank will fall back on data provided by Crunchbase</small>
				</div>
			</div>

			<div class="editblock row justify-content-sm-center mx-0">
				<div class="col-12 col-sm-10 col-md-8 pl-2 pr-4 px-sm-0 px-1 px-sm-0">
					<form [formGroup]="solco_form" (ngSubmit)="onSubmit()">

						<!-- NAME -->
						<div class="form-group">
							<label for="name">
								Company Name:
								<img *ngIf="fallback_flags['url_twitter']" class="fallback-indicator d-md-none" src="/assets/crunchbase-grey.svg">
							</label>
							<input id="name" class="form-control"
								[ngClass]="{'is-invalid':solco_form.controls['name']?.invalid}"
								formControlName="name"
								placeholder="{{fallback_flags['name'] ? data_fallback['company_name'] : 'Company Name'}}"
								type="text"
							>
							<img *ngIf="fallback_flags['name']" class="fallback-indicator fallback-indicator-inline d-none d-md-block" src="/assets/crunchbase-grey.svg">
							<small *ngIf="solco_form.controls['name']?.hasError('pattern')" class="form-text text-danger">
								Company name must be at least 3 characters long and not start or end with a space.
							</small>
							<small *ngIf="solco_form.controls['name']?.hasError('required')" class="form-text text-danger">
								Company name is required.
							</small>
						</div>

						<!-- URL -->
						<div class="form-group">
							<label for="web_addr">
								URL:
								<img *ngIf="fallback_flags['url_twitter']" class="fallback-indicator d-md-none" src="/assets/crunchbase-grey.svg">
							</label>
							<input id="web_addr" class="form-control"
								[ngClass]="{'is-invalid':solco_form.controls['web_addr']?.invalid}"
								formControlName="web_addr"
								placeholder="{{fallback_flags['web_addr'] ? data_fallback['website'] : 'Company Website'}}"
								type="text"
							>
							<img *ngIf="fallback_flags['web_addr']" class="fallback-indicator fallback-indicator-inline d-none d-md-block" src="/assets/crunchbase-grey.svg">
							<small *ngIf="solco_form.controls['web_addr']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid URL (must start with 'http://' or 'https://' and not have a trailing '/').
							</small>
						</div>

						<!-- ADDRESS -->
						<div class="form-group" *ngIf="mode !== E_Modal_Operating_Mode.ADD">
							<label for="headquarters_locations">Address (referenced from Crunchbase):</label>
							<input id="headquarters_locations" class="border form-control-plaintext pl-3 text-gray-400 w-100"
								style="cursor: default;"
								formControlName="headquarters_locations"
								placeholder="No company address given"
								readonly
								type="text"
							>
						</div>

						<!-- AVIA ADDRESS -->
						<!-- street -->
						<div class="form-group" formGroupName="hq_addr">
							<label for="street">Street:</label>
							<input id="street" class="form-control"
								[ngClass]="{'is-invalid':solco_form.controls['hq_addr']['controls']['street']?.invalid}"
								formControlName="street"
								placeholder="Street address"
								type="text"
							>
							<small *ngIf="solco_form.controls['hq_addr']['controls']['street']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid street address.
							</small>
						</div>

						<!-- city, state, zip -->
						<div class="form-row" formGroupName="hq_addr">
							<!-- city -->
							<div class="form-group col-md-6">
								<label for="city">City:</label>
								<input id="city" class="form-control"
									[ngClass]="{'is-invalid':solco_form.controls['hq_addr']['controls']['city']?.invalid}"
									formControlName="city"
									placeholder="City"
									type="text"
								>
								<small *ngIf="solco_form.controls['hq_addr']['controls']['city']?.hasError('pattern')" class="form-text text-danger">
									Please enter a valid city name.
								</small>
							</div>

							<!-- state -->
							<div class="form-group col-md-3">
								<label for="state">State:</label>
								<!--
								<select id="state" class="form-control">
									<option selected>Choose...</option>
									<option>...</option>
								</select>
								-->
								<input id="state" class="form-control text-uppercase"
									[ngClass]="{'is-invalid':solco_form.controls['hq_addr']['controls']['state']?.invalid}"
									formControlName="state"
									placeholder="State Code"
									type="text"
								>
								<small *ngIf="solco_form.controls['hq_addr']['controls']['state']?.hasError('pattern')" class="form-text text-danger">
									Please enter a two-letter, state code.
								</small>
							</div>

							<!-- zip -->
							<div class="form-group col-md-3">
								<label for="postalCode">Postal Code:</label>
								<input id="postalCode" class="form-control"
									[ngClass]="{'is-invalid':solco_form.controls['hq_addr']['controls']['postalCode']?.invalid}"
									formControlName="postalCode"
									placeholder="US Postal code"
									type="text"
								>
								<small *ngIf="solco_form.controls['hq_addr']['controls']['postalCode']?.hasError('pattern')" class="form-text text-danger">
									Please enter a valid postal code (5 numbers and optionally a dash and 4 numbers).
								</small>
							</div>
						</div>

						<!-- CRUNCHBASE URL -->
						<!-- Visible for reference when Importing -->
						<div class="form-group" *ngIf="mode === E_Modal_Operating_Mode.IMPORT">
							<label for="url_crunchbase">Crunchbase:</label>
							<input id="url_crunchbase"
								class="border form-control form-control-plaintext pl-3 text-gray-400 w-100"
								formControlName="url_crunchbase"
								[ngStyle]="{'cursor':'default'}"
								placeholder="Crunchbase URL for this Company"
								readonly="true"
								type="text"
							>
							<!--
							<small *ngIf="solco_form.controls['url_crunchbase']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid Crunchbase Company URL - http(s)://www.crunchbase.com/organization/company-name
							</small>
							-->
						</div>
						<!-- PHONENUM -->
						<div class="form-group">
							<label for="phonenum">Phone Number:</label>
							<input id="phonenum"
								class="border form-control form-control-plaintext pl-3 w-100"
								formControlName="phonenum"
								placeholder="18001234567"
								type="tel"
								minlength="10" maxlength="11"
							>
							<small *ngIf="solco_form.controls['phonenum']?.hasError('pattern')" class="form-text text-danger">
								Please enter 10 or 11 digits only.
							</small>
						</div>

						<!-- EMAIL -->
						<div class="form-group">
							<label for="email">Email:</label>
							<input id="email"
								class="border form-control form-control-plaintext pl-3 w-100"
								formControlName="email"
								placeholder="company@email.com"
								type="email"
							>
							<small *ngIf="solco_form.controls['email']?.hasError('pattern')" class="form-text text-danger">
								Enter a valid email address.
							</small>
						</div>
						<!-- LINKEDIN -->
						<div class="form-group">
							<label for="url_linkedin">
								LinkedIn:
								<img *ngIf="fallback_flags['url_twitter']" class="fallback-indicator d-md-none" src="/assets/crunchbase-grey.svg">
							</label>
							<input id="url_linkedin" class="form-control"
								formControlName="url_linkedin"
								placeholder="{{fallback_flags['url_linkedin'] ? data_fallback['linkedin'] : 'Company LinkedIn URL (http(s)://www.linkedin.com/company/your-company-name-here)'}}"
								type="text"
							>
							<img *ngIf="fallback_flags['url_linkedin']" class="fallback-indicator fallback-indicator-inline d-none d-md-block" src="/assets/crunchbase-grey.svg">
							<small *ngIf="solco_form.controls['url_linkedin']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid LinkedIn Company URL - http(s)://www.linkedin.com/company/your-company-name-here
							</small>
						</div>

						<!-- TWITTER -->
						<div class="form-group">
							<label for="url_twitter">
								Twitter:
								<img *ngIf="fallback_flags['url_twitter']" class="fallback-indicator d-md-none" src="/assets/crunchbase-grey.svg">
							</label>
							<input id="url_twitter" class="form-control"
								formControlName="url_twitter"
								placeholder="{{fallback_flags['url_twitter'] ? data_fallback['twitter'] : 'Company Twitter URL'}}"
								type="text"
							>
							<img *ngIf="fallback_flags['url_twitter']" class="fallback-indicator fallback-indicator-inline d-none d-md-block" src="/assets/crunchbase-grey.svg">
							<small *ngIf="solco_form.controls['url_twitter']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid Twitter URL - http(s)://(www.)twitter.com/CompanyName
							</small>
						</div>

						<!-- NEWS - Unused for now -->
						<div class="form-group" *ngIf="false">
							<label for="url_news">News:</label>
							<input id="url_news" class="form-control"
								formControlName="url_news"
								placeholder="URL for Company news page"
								type="text"
							>
							<small *ngIf="solco_form.controls['url_news']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid URL (with no trailing '/').
							</small>
						</div>

						<!-- DESCRIPTION
						<div class="form-group">
							<label for="description">
								Description:
								<img *ngIf="fallback_flags['url_twitter']" class="fallback-indicator d-md-none" src="/assets/crunchbase-grey.svg">
							</label>
							<textarea id="description"
								class="form-control"
								formControlName="description"
								placeholder="{{fallback_flags['description'] ? data_fallback['company_description'] : 'Enter a description for the company here.'}}"
								rows="6"
							></textarea>
							<img *ngIf="fallback_flags['description']" class="fallback-indicator fallback-indicator-inline d-none d-md-block" src="/assets/crunchbase-grey.svg">
						</div> -->

						<!-- CRUNCHBASE TABLE -or- DATABASE ID -->
						<!-- Visible for reference when Importing -->
						<!-- Visible and Editable when Editing -->
						<!-- NOT Visible when Adding -->
						<div class="form-group" *ngIf="mode !== E_Modal_Operating_Mode.ADD && akey?.solco_internal.w">
							<label for="crunchbase_id">Crunchbase {{mode !== E_Modal_Operating_Mode.IMPORT ? 'Table' : 'Database'}} ID:</label>
							<input id="crunchbase_id" class="form-control"
								[ngClass]="{'border form-control-plaintextpl-3 text-gray-400 w-100': (mode !== E_Modal_Operating_Mode.EDIT)}"
								[ngStyle]="{'cursor': mode === E_Modal_Operating_Mode.EDIT ? 'text' : 'dafault'}"
								formControlName="crunchbase_id"
								placeholder="Crunchbase Table ID"
								[readonly]="(mode !== E_Modal_Operating_Mode.EDIT)"
								type="text"
							>
						</div>

						<!-- CRUNCHBASE UUID -->
						<!-- Visible for reference when Importing -->
						<div class="form-group" *ngIf="mode === E_Modal_Operating_Mode.IMPORT">
							<label for="crunchbase_uuid">Crunchbase UUID:</label>
							<input id="crunchbase_uuid" class="border form-control form-control-plaintext pl-3 text-gray-400 w-100"
								formControlName="crunchbase_uuid"
								[ngStyle]="{'cursor':'default'}"
								placeholder="Unique ID of this Company in Crunchbase"
								readonly="true"
								type="text"
							>
							<small *ngIf="solco_form.controls['crunchbase_uuid']?.hasError('pattern')" class="form-text text-danger">
								Please enter a valid Crunchbase UUID.
							</small>
						</div>

						<!-- BUTTONS -->
						<div class="form-group d-flex justify-content-center mt-7">
							<button
								type="button"
								class="btn btn-white border mr-2"
								[disabled]="solco_form.pristine === true ? 'true' : undefined"
								(click)="revert();"
							>Revert</button>
							<button
								type="submit"
								class="btn btn-primary ml-2"
								[disabled]="(solco_form.status !== 'VALID' || (mode !== E_Modal_Operating_Mode.IMPORT && solco_form.pristine)) ? 'true' : undefined"
							>{{(mode === E_Modal_Operating_Mode.ADD ? 'Add' : 'Save')}}</button>
						</div>
					</form>

					<span *ngIf="VERBOSE">
						<!-- Display the form values and state during development -->
						<p>Form value: {{ solco_form.value | json }}</p>
						<p>Form status: {{ solco_form.status | json }}</p>
					</span>
				</div>
			</div>

		</div>
	</div>
</ng-template>

<!-- ACTIVATION BUTTONS -->
<div *ngIf="mode === E_Modal_Operating_Mode.ADD && !_hide_activate_btn" class="sc-add-button" (click)="open()">
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedAddBtn.children.length === 0"
		class="material-icons sc-add-btn default"
		data-toggle="tooltip"
		placement="left"
		ngbTooltip="Add Company"
	>add_circle</i>
</div>

<span *ngIf="mode === E_Modal_Operating_Mode.EDIT && !_hide_activate_btn" class="sc-edit-button" (click)="open()">
	<span #SuppliedEditBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedEditBtn.children.length === 0"
		class="material-icons sc-edit-btn default"
		data-toggle="tooltip"
		placement="bottom"
		nbgTooltip="Edit"
	>edit</i>
</span>

<div *ngIf="mode === E_Modal_Operating_Mode.IMPORT && !_hide_activate_btn" class="sc-import-button" (click)="open()">
	<span #SuppliedImportBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedImportBtn.children.length === 0"
		class="material-icons sc-import-btn default"
		data-toggle="tooltip"
		placement="bottom"
		ngbTooltip="Import Company"
	>add_circle</i>
</div>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
