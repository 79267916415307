import { AnalyticEvent, E_SomethingHappened } from 'src/class';
import { AVIAConnectService } from 'src/app/avia-connect.service';
import { Topic } from '../../../../class';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-suggested-topics',
  templateUrl: './suggested-topics.component.html',
  styleUrls: ['./suggested-topics.component.scss']
})
export class SuggestedTopicsComponent implements OnInit {
  @Input() suggested_topics: Topic[] = [];
  button_clicked: boolean = false;


  constructor(public aviaService: AVIAConnectService) {

  }

  ngOnInit() {}

  async followClicked(topic: Topic) {
    let following = topic.following;
    if (this.button_clicked) return;
    this.button_clicked = true;
    topic.following ? topic.users_count-- : topic.users_count++;
    topic.following = !topic.following;
    let res = await this.aviaService.updateKMcard(topic.id, {interested: following ? 0 : 1});
    if(res.status === 200 && res.body && res.body.card) {
      this.aviaService.somethingHappened.emit({
        type: E_SomethingHappened.NEWSFEED_REFRESHED,
        data: { quick_links: true }
      });
      // Analytics and refreshing session
      let obj;
      if (topic.following) {
        obj = new AnalyticEvent(
          'suggested_km_row_interest_followed',
          {card_id: topic.id}
        );
      } else {
        obj = new AnalyticEvent(
          'suggested_km_row_interest_unfollowed',
          {card_id: topic.id}
        );
      }

      this.aviaService.createAnalyticEvent(obj);
      this.aviaService.getSessionSupport(true);
    } else {
      // If the network call failed, put it back to original
      topic.following ? topic.users_count-- : topic.users_count++;
      topic.following = !topic.following;
    }
    this.button_clicked = false;
  }

}
