import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { EventEmitter, OnDestroy } from '@angular/core';
import { Component, Input, ViewChild, Output } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-avia-quick-modal',
  templateUrl: './avia-quick-modal.component.html',
  styleUrls: ['./avia-quick-modal.component.scss'],
})

// JAW
export class AviaQuickModalComponent implements OnDestroy {
  readonly VERBOSE: boolean = false;

  @ViewChild('QuickModal', { static: true }) quick_modal: NgbModal;

  @Input() title: string = '';
  @Input() logo: string;
  @Input() is_open: boolean;
  @Input() open_modal: boolean = true
  @Input() tag: number; // not used within avia-quick-modal, it's for avia-solutions-cards to use
  @Input() no_padding:boolean = false; // Removes padding inside of modal body
  @Input() auto:boolean = false;
  @Input() modal_options: NgbModalOptions = {};

  modal_ref: NgbModalRef;
  color: any; // angular8: added to fix error when building prod: "Property does not exist"

  @Output() changed = new EventEmitter();
  @Output('close') close = new EventEmitter();
  private routeSub: any;  // subscription to route observer

  constructor(private modalService: NgbModal, public router: Router) { }

  public async _open() {
    this.changed.emit({is_open: true});

    this.modal_options = {
      size: 'lg',
      ariaLabelledBy: 'quick-view-modal',
      ...this.modal_options,
    };

    if(this.open_modal) {
      this.modal_ref = await this.modalService.open(this.quick_modal, this.modal_options);

      this.modal_ref.result.then(
        close => { this._close() },
        dismiss => { this._close() }
      );

      // * * * This will close the modal on route change * * *
      this.routeSub = this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this._close();
        }
      });
    }
  }

  public _close() {
    this.close.emit();
    this.modal_ref.close();
    if(this.routeSub) this.routeSub.unsubscribe();
  }

  public ngOnDestroy() {
    if(this.routeSub) this.routeSub.unsubscribe();
  }

}
