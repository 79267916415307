import { Router } from '@angular/router';
import { Color_Library, Org, User } from './../../../class';
import { AVIAConnectService } from './../../avia-connect.service';
import { Injectable, Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AviaActionTilesService {

  public loading:boolean = false;
  public default_color:string = Color_Library.blue_dark;
  public org: Org = new Org();
  public user: User = new User();
  public tiles: any;

  // ACTION TILES IDs
  public ADD_USER_ID: number  = 10;

  private VERBOSE: boolean = false;

  constructor(public aviaService: AVIAConnectService, private router: Router) { }

  async init() {
    this.loading = true;

    let session = await this.aviaService.getSessionSupport();
    this.org = session.org;
    this.user = session.user;

    await this.getActionTiles()
    this.loading = false;
  }

  async getActionTiles() {
    let res = await this.aviaService.getActionTiles();
    if(res.status === 200) {
      this.tiles = res.body;
      this.VERBOSE && console.log(this.tiles)
    }
  }

  async nextTile(dismiss_type = 1) {
    this.tiles[0].dismiss_type = dismiss_type;
    let tiles_res = await this.aviaService.upsertActionTiles(this.tiles)
    if(tiles_res.status === 200) {
      this.tiles = tiles_res.body.result;
      this.VERBOSE && console.log(this.tiles)
    }
  }

  async tileButtonClick(dismiss_type = 2) {
    // redirect user before adjusting tiles
    this.getButtonRoute(this.tiles[0]);

    // Update dismissal and get new tiles
    this.tiles[0].dismiss_type = dismiss_type;
    let tiles_res = await this.aviaService.upsertActionTiles(this.tiles);
    if(tiles_res.status = 200) {
      this.tiles = tiles_res.body.result;
      this.VERBOSE && console.log(this.tiles)
    }
  }

    convertHex(color){
      let hex = '#0176bd';
      if(this.tiles[0].color) {
        hex = this.tiles[0].color
      }

      hex = hex.replace('#','');
      if(color == 'r'){
        let r = parseInt(hex.substring(0,2), 16);
        return r;
      }
      if(color == 'g') {
        let g = parseInt(hex.substring(2,4), 16);
        return g;
      }
      if(color == 'b') {
        let b = parseInt(hex.substring(4,6), 16);
        return b;
      }
    }

  getButtonRoute(tile) {
    switch(tile.at_id) {
      case 1:
      case 2:
        this.router.navigate(['pulse', tile.pulse_id ,'hs', this.org.id, 'overview']);
        break;
      case 3:
      case 4:
        this.router.navigate(['sc', this.org.id]);
        break;
      case 5:
        this.router.navigate(['sc', this.org.id]);
        break;
      case 6:
        this.router.navigate(['ws', tile.act_id]);
        break;
      case 7:
        this.router.navigate(['intelligence/km/graph'], { queryParams: { id: tile.card_id }});
        break;
      case 8:
        this.router.navigate(['intelligence/km/graph'], { queryParams: { id: tile.card_id, activeTab: "Solutions" }});
        break;
      case 9:
        this.router.navigate(['sc', tile.solco_id ]);
        break;
      case 10:
        // Do nothing
        break;
      case 11:
        this.router.navigate(['profiles/in', this.user.id]);
        break;
      case 12:
        this.router.navigate(['communities/page', tile.page_id]); // For COVID, remove this if we survived
      default:
        // There's been an error?
        break;
    }
  }
}
