import { AVIAConnectService } from './../../avia-connect.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-out',
  template: ``,
  styles: []
})
export class SignOutComponent implements OnInit {


  constructor( public aviaService: AVIAConnectService ) { }

  ngOnInit(): void {
    this.aviaService.createAnalyticNodeEvent({
      actions:[{
        action_type:"SIGNOUT",
        eventkeytype:"SIGNOUT",
        eventkeydivisor:1000,
        routeParams: this.aviaService.currentRouteParams,
        //target: user_id, get it on back end
        attributes: {
          page_type: "signout",
        },
      }]
    })

    setTimeout(() => this.aviaService.signOut(), 0);
  }

}
