import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef, TemplateRef } from '@angular/core';

// AVIA imports
import { AVIAConnectService } from './../../avia-connect.service';
import { MessagesService } from './../../messages/messages.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-avia-messages-bubble',
  templateUrl: './avia-messages-bubble.component.html',
  styleUrls: ['./avia-messages-bubble.component.scss']
})
export class AviaMessagesBubbleComponent implements OnInit {
  is_open = false;
  @ViewChild('modal', { static: false }) modal: NgbModal;

  constructor(private modalService: NgbModal, public sm: MessagesService, ) { }
  ngOnInit() {

  }
}
