<ng-container *ngIf="!browser_incompatible">
	<div *ngIf="!hide" [ngClass]="{'h-100': !aviaService?.mobile_mode}" class="signinBox bg-white">
		<app-avia-header-row></app-avia-header-row>
		<div class="container-fluid">
			<div class="d-block d-lg-flex">
				<div class="ac-pt w-100">
						<div class="text-center ac-title ac-title-top">AVIA Connect:</div>
						<div class="text-center ac-title">Explore. Engage. Transform.</div>
						<div class="text-center ac-sub-title">AVIA Connect is a digital health knowledge platform that accelerates digital transformation research and tech selection.
						</div>
						<!-- <div class="text-center font-body">Trusted insights, tools, and collaboration.</div> -->
						<img class="d-none d-lg-block pg-img" [src]="'/assets/sign-up-img.svg'">

						<ul style="list-style: none;" class="d-none d-lg-block">
							<li class="li-header">Move faster with confidence on AVIA Connect:</li>
							<li>Access world-class, validated digital health research and resources</li>
							<li>Prioritize areas for digital transformation</li>
							<li>Connect with peers across the industry</li>
							<li>Digitize the tech selection process with contextualized research</li>
						</ul>
				</div>
				<div class="d-none d-lg-block h-100 line bg-gray-200">
				</div>
				<div class="w-100">
					<div *ngIf="loaded" id="{{workflow}}Workflow" class="content-wrapper mx-auto">
						<!-- Standard Login Path -->
						<ng-container *ngIf="!workflow">
							<h4 *ngIf="phase !== 0" class="status-head">{{ status_head[phase] }}</h4>
							<p *ngIf="phase !== 1" class="status-msg">{{ status_msg[phase] }}</p>
							<div *ngIf="phase === 1" class="alert alert-danger" role="alert">
								<p class="status-msg">{{ status_msg[phase] }}</p>
							</div>
							<form class="d-flex flex-column justify-content-around h-100" (ngSubmit)="standardWorkflow( phase, user, pass )">
								<ng-container *ngIf="phase === 0">
									<h4 class="text-center mt-3 d-none d-md-block font-weight-bolder">Sign In</h4>
									<div class="form-group w-100">
										<!-- <label for="user" class="p-0">Email</label> -->
										<input autofocus type="text" class="form-control" id="user" [disabled]="browser_incompatible" required [(ngModel)]="user" name="user" placeholder="Email">
									</div>
									<div class="form-group w-100">
										<!-- <label for="pass" class="p-0">Password</label> -->
										<input type="password" class="form-control" id="pass" [disabled]="browser_incompatible" required [(ngModel)]="pass" name="pass" placeholder="Password">
									</div>
									<button type="submit" class="bottomButton btn font-weight-bold" [disabled]="browser_incompatible" [ngClass]="{'btn-primary': phase !== 1, 'btn-cancel': phase === 1}">{{ action_btn[phase] }}</button>
									<div *ngIf="browser_incompatible" class="legal-stuff text-center mx-auto text-gray-500">This browser is incompatible with AVIA Connect.  To view AVIA Connect please use a different browser, such as Edge or Chrome.</div>

									<div class="legal-stuff text-center mx-auto text-gray-500">By clicking <b class="text-gray-600 font-weight-bolder">Sign In</b>, I agree to the
										<b
											class="pointer text-gray-600"
											(click)="aviaService?.goToNewLegal({tab: 'terms-of-service'})"
										>Terms of Service</b> & <b class="pointer text-gray-600" (click)="aviaService?.goToNewLegal({tab: 'privacy-policy'})">Privacy Policy</b>.
									</div>
									<div class="form-group w-100 text-center ">
										<div class="text-blue-dark btn btn-sm btn-link font-weight-bold more-vert-space" (click)="storeUser(user)" routerLink="start">forgot your password?</div>
									</div>
									<button type="button" (click)="ssoCallback('aha')" class="btn btn-gray-150 mx-auto font-weight-bold vert-space-bottom">
										<img class="aha-logo" src="/assets/pulse/aha/aha.png?v=2"> Sign In With Your AHA Account
									</button>
									<div class="legal-stuff text-center mx-auto mt-auto text-gray-500 " (click)="goToAha()">
											AHA
											<b class="pointer text-gray-600">Terms of Service</b> &
											<b class="pointer text-gray-600">Privacy Policy</b>.
									</div>
									<img class="vert-space-bottom vert-space" src="/assets/signup/or-divider.svg" alt="or-divider">
									<button type="button" class="btn btn-accent mx-auto font-weight-bold" [routerLink]="['/signup']">
										Create an account
									</button>
								</ng-container>
								<ng-container *ngIf="phase == 1">
									<button type="submit" class="mx-auto bottomButton btn btn-cancel">{{ action_btn[phase] }}</button>
								</ng-container>
							</form>
						</ng-container>

						<!-- Send Create Password Email -->
						<ng-container *ngIf="workflow === 'start'">
							<h4 class="status-head">{{ status_head[phase] }}</h4>
							<div class=""><p *ngIf="phase !== 2" class="status-msg">{{ status_msg[phase] }}</p></div>
							<div *ngIf="phase === 2" class="alert alert-danger" role="alert">
								<p class="status-msg">{{ status_msg[phase] }}</p>
							</div>
							<form class="d-flex flex-column justify-content-around h-100" (ngSubmit)="startWorkflow( phase, user, pass )">
								<ng-container *ngIf="phase === 0">
									<div class="form-group mb-0">
										<label for="user" class="p-0">EMAIL</label>
										<input type="text" class="form-control" id="user" required [(ngModel)]="user" name="email">
										<div class="form-group">
											<div class="btn btn-sm btn-link float-left text-blue-dark" routerLink="">back</div>
										</div>
									</div>

								</ng-container>
								<button type="submit" class="bottomButton btn mx-auto" [ngClass]="{'btn-primary': phase !== 2, 'btn-cancel': phase == 2}">{{ action_btn[phase] }}</button>
							</form>
							<div *ngIf="phase === 1">
								<p class="status-msg">If you don’t get it in a few minutes, please check your spam or junk mail. If you’re still not seeing it, email us at <a href='mailto:support@avia.health?subject=AVIA%20Connect%20--%20No%20Email%20Received'>support@avia.health.</a></p>
							</div>
						</ng-container>

						<!-- Create New Password -->
						<ng-container *ngIf="workflow === 'finish'">
							<h4 class="status-head">{{ status_head[phase] }}</h4>
							<p *ngIf="phase !== 1" class="status-msg" [ngClass]="{'mb-2': ( phase === 0 && workflow === 'finish' )}">{{ status_msg[phase] }}</p>
							<div *ngIf="phase === 1" class="alert alert-danger" role="alert">
								<p class="status-msg">{{ status_msg[phase] }}</p>
							</div>
							<form *ngIf="phase !== 0" class="d-flex justify-content-around mt-auto" (ngSubmit)="finishWorkflow( phase, user, pass )">
								<button type="submit" class="bottomButton mb-6 btn" [ngClass]="{'btn-primary': phase !== 0, 'btn-cancel': phase === 1}">{{ action_btn[phase] }}</button>
							</form>
							<ng-container *ngIf="phase === 0">
								<app-avia-password-change
									class="aviaPasswordChangeComponent d-block"
									[create_mode]="true"
									[button_title]="'Submit'"
									[embedded]="true"
									(passed)="createNewPassword($event)"
								></app-avia-password-change>
							</ng-container>
						</ng-container>
					</div>
					<span *ngIf="!loaded">
						<app-avia-loader [options]="{type: 'generic'}"></app-avia-loader>
					</span>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="browser_incompatible">

	<div *ngIf="!hide" [ngClass]="{'h-100': !aviaService?.mobile_mode}" class="signinBox bg-white">
		<app-avia-header-row></app-avia-header-row>
		<div class="container-fluid">
			<div class="d-block d-md-flex">
				<div class="w-100">
					<h2 class="text-center mt-3">Welcome to AVIA Connect!</h2>
					<div class="text-center font-body">AVIA Connect is a digital health knowledge platform that accelerates digital transformation research and tech selection.</div>
					<div class="text-center font-body">Trusted Insights, Tools, and Collaboration.</div>
					<img class="d-none d-md-block pt-md-6 pt-4 px-4 img-fluid mx-auto" [src]="'/assets/onboarding/start.svg'">
				</div>
				<div class="d-none d-md-block h-100 line bg-gray-200 mx-4 my-md-7 my-4">
				</div>
				<div class="w-100">

					<ng-container *ngIf="!workflow">
						<h3 class='text-center text-gray font-weight-bold mb-4 mt-6'>Unfortunately AVIA Connect does not support the browser you are using.</h3>
						<div class="text-center">
							Please switch to a supported browser to continue with AVIA Connect.
							<br>
							<a href="https://www.google.com/chrome/?brand=CHBD&gclid=EAIaIQobChMImN-I0bPH5QIVE77ACh1SGwA7EAAYASAAEgKIJfD_BwE&gclsrc=aw.ds">Chrome</a>,
							<a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>,
							<a href="https://support.apple.com/downloads/safari">Safari</a>,
							<a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a>
						</div>
					</ng-container>

					<ng-container *ngIf="workflow === 'finish'">
						<h4 class="status-head">{{ status_head[phase] }}</h4>
						<p *ngIf="phase !== 1" class="status-msg" [ngClass]="{'mb-2': ( phase === 0 && workflow === 'finish' )}">{{ status_msg[phase] }}</p>
						<div *ngIf="phase === 1" class="alert alert-danger" role="alert">
							<p class="status-msg">{{ status_msg[phase] }}</p>
						</div>
						<form *ngIf="phase !== 0" class="d-flex justify-content-around mt-auto" (ngSubmit)="finishWorkflow( phase, user, pass )">
							<button type="submit" class="bottomButton mb-6 btn" [ngClass]="{'btn-primary': phase !== 0, 'btn-cancel': phase === 1}">{{ action_btn[phase] }}</button>
						</form>
						<ng-container *ngIf="phase === 0">
							<app-avia-password-change
								class="aviaPasswordChangeComponent d-block"
								[create_mode]="true"
								[button_title]="'Submit'"
								[embedded]="true"
								(passed)="createNewPassword($event)"
							></app-avia-password-change>
						</ng-container>
					</ng-container>
					<span *ngIf="!loaded">
						<app-avia-loader [options]="{type: 'generic'}"></app-avia-loader>
					</span>
				</div>
			</div>
		</div>

	</div>
</ng-container>
