// angular includes
import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import { Location as LocationAngular } from '@angular/common';
//import { HttpBackend, HttpHeaders, HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// node_modules includes
import swal from 'sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject, from, timer } from 'rxjs'
import { map, take } from 'rxjs/operators';

import { cloneDeep as _cloneDeep, get as _get } from 'lodash';

import * as moment from 'moment';
import * as util from 'util'; // output JSON with circular refs:  util.inspect( obj )
let Semaphore = require('semaphore');
import * as querystring from 'querystring';

// avia system includes
import { Common } from './common';
import {
  AccessKey,
  AccessKeychain_Shell,
  CalEvent_Out,
  Color_Library,
  E_ConnectionManagerActions,
  E_SomethingHappened,
  Location,
  Org,
  User,
  AnalyticEvent
} from '../class';
import { environment } from '../environments/environment';
import { DataCacher } from './_utilities/DataCacher';
import { AviaWebsocketService } from './_components/avia-websocket.service'; // TODO: move to ./_services/
import { T_SomethingHappened, xhrHelper } from '../interface';

// avia app includes
import { AviaContentViewerComponent } from './_components/avia-content-viewer/avia-content-viewer.component';
import { EulaboxComponent } from './shell/eulabox/eulabox.component';
import { EULAComponent } from './shell/eula/eula.component';
import { OnboardingComponent } from './shell/onboarding/onboarding.component';

// Force the 'notificationEvent' Output to use the 'T_SomethingHappened' class of events.
export class NotificationEmitter<T_SomethingHappened> extends EventEmitter<any> {
  constructor( isAsync:boolean = false ) { super(isAsync); }
  emit($event: T_SomethingHappened) { super.emit($event); }
}

// Force the 'somethingHappened' Output to use the 'T_SomethingHappened' class of events.
export class SomethingHappened_EventEmitter<T_SomethingHappened> extends EventEmitter<any> {
  constructor( isAsync:boolean = false ) { super(isAsync); }
  emit($event: T_SomethingHappened) { super.emit($event); }
}


@Injectable()
export class AVIAConnectService {
  NUMBER_OF_TUTORIALS:    number           = 8; // Total number tutorials in AVIA Connect
  access_token:           string           = undefined;
  baseUrl:                string           = '';
  current_location:       Location         = new Location();
  frontUrl:               string           = '';
  backendTest:            boolean          = true;
  browser_nag_dismissed:  boolean          = false;
  browserProblem:         boolean          = false;
  browserProblemLock:     boolean          = false;
  browserProblemSevere:   boolean          = false;
  currentRouteParams:     any              = {};
  cookie_mode:            boolean          = navigator.cookieEnabled && sessionStorage["cookie_mode"] === 'false'? false : navigator.cookieEnabled;

  deviceInfo = null;
  deviceIsMobile:         boolean          = false; // are we running on a mobile DEVICE? iOS or Android, etc...
                                                    // BE CAREFUL: use 'mobile_mode' for mobile SIZE, which your desktop
                                                    // browser can be in.
                                                    // e.g. use this to choose language like "computer" vs "device" in dialogs
  eulabox:                EulaboxComponent = undefined;
  onboarding:             OnboardingComponent = undefined;
  universal_add_active:   boolean          = false;
  expires_in:             number           = 0;
  expires:                number           = 0;
  has_dismissed_pulse:    boolean          = false; // If pulse banner has been dismissed, don't show it
  has_visited_pulse:      boolean          = false; // Add pulse banner if we've already been to pulse during this session
  in_pulse:               boolean          = false;
  go_to_pulse_instead_of_home:               boolean          = false;
  loggedIn:               boolean          = false;
  mobile_mode:            boolean          = false; // are we running at mobile SIZE?
                                                    // mobile_mode is defined as bootstrap’s xs and sm.  see app.component.ts
                                                    // which listens to screen resizes. be responsive when browser size
                                                    // changes (desktop browsers can go in/out of mobile size)
                                                    // BE CAREFUL: use 'deviceIsMobile' for mobile DEVICE
                                                    // e.g. use this for UI
  pass:                   string           = undefined;
  protocol:               string           = "";
  redirectUrl:            string;          // store the URL so we can redirect after logging in
  redirectUrlParams:      any              = {};// store the URL Parmas so we can redirect after logging in
  refresh_token:          string           = undefined; // undefined when refresh TTL is 0
  router:                 Router;
  service_inited:         boolean          = false;
  show_interests:         boolean          = false;  // TODO SK: delete me once new nav is up
  shouldReloadNewVersion: boolean          = false; // set when version mismatch detected between front and back
  suggestedBrowser:       string           = "<a target='googchrome' class='link99' href='https://www.google.com/chrome/'>Google Chrome</a>";
  token_type:             string           = undefined;
  user:                   string           = undefined;
  useTestMocks:           boolean          = false; // disable certain things that piss off the test suite (do not enable in prod code)
  user_tutorial_count:    number;          // num of user tutorials that s/he have finished
  user_tutorial_total:    number;          // total num of user tutorials (dependent on dashboard visibility)
  window_height:          number;
  window_width:           number;
  messages:               Subject<any>;

  // NOTE: This Output is used as a way to notify other components when something has happened so they can update themselves.
  //       - USED BY: avia-notifications.component, universal-add.component
  //       - Emitted events should follow the format set down in 'interface::T_SomethingAdded'
  //       - Emitted events should only use types declared in 'class::E_SomethingHappened'. Please keep these types in sync with the universal-add component
  @Output() somethingHappened: SomethingHappened_EventEmitter<T_SomethingHappened> = new SomethingHappened_EventEmitter();


  constructor(
    public deviceService: DeviceDetectorService,
    public  injector:      Injector = undefined,
    private websocket:     AviaWebsocketService,
    public location:       LocationAngular
  ) {
    // cyclic dependency between Router and HttpClient.   We get around that... :)
    setTimeout(() => { if (injector) this.router = injector.get(Router); });

    this.protocol = environment.ssl === true ? "https" : "http";
    let env = window.location.hostname.split(".")[0];

    this.baseUrl = window.location.protocol + "//" + (
      window.location.hostname.match( /(aviahealthinnovation\.com|avia\.health|devops-avia\.com|aviaenv\.com)/ ) ?
      (`${window.location.hostname}/api/legacy`) :
      (
        window.location.hostname + (
          environment.dataServicePort === '' || environment.dataServicePort === undefined || environment.dataServicePort === null ?
          '' :
          ":" + environment.dataServicePort
        )
      )
    );

    //this.baseUrl = this.protocol + "://" + window.location.hostname + ":" + environment.dataServicePort;
    this.frontUrl = window.location.protocol + "//" + window.location.hostname + (
      window.location.port === '' || window.location.port === undefined || window.location.port === null ?
      '' :
      ":" + window.location.port
    );
    this.deviceInfo = this.deviceService.getDeviceInfo();

    // Set everything lowercase to match the rest of the codebase
    for(let key in this.deviceInfo){
      this.deviceInfo[key] = this.deviceInfo[key].toLowerCase();
    }
    this.deviceIsMobile = this.deviceInfo.device === "android" || this.deviceInfo.device === "iphone";

    this.newrelicVersion( "AVIAConnect", environment.version );
    if (window['AVIA_TESTING'] !== true) {
      console.info( "You like to look under the hood? Why not help build the engine? http://www.avia.health/careers/\n" );
      console.info( "Welcome to AVIA Connect v" + environment.version );
      console.info( "  Browser:         " + this.deviceInfo.browser );         // chrome, ms-edge, ie
      console.info( "  Browser Version: " + this.deviceInfo.browser_version ); // ie: 11.0
      console.info( "  Device:          " + this.deviceInfo.device );          // unknown, iphone, android
      console.info( "  OS:              " + this.deviceInfo.os );
      console.info( "  OS Version:      " + this.deviceInfo.os_version );
      console.info( "  User Agent:      " + this.deviceInfo.userAgent );
    }

    // everything is a BAD browser by default...
    this.browserProblem = true;
    this.browserProblemSevere = true;

    // ...and our whitelist will make it a GOOD browser:
    if (false
        || this.deviceInfo.browser.includes('chrome')
        || this.deviceInfo.browser.includes('firefox')
        || this.deviceInfo.browser.includes('ms-edge')
        || this.deviceInfo.browser.includes('safari')
        || this.deviceInfo.browser.includes('opera')
        || (this.deviceInfo.browser.includes('safari') && this.deviceInfo.device === 'iphone')
        || (this.deviceInfo.browser.includes('chrome') && this.deviceInfo.device === 'android')
      ) {
      this.browserProblem = false;       // pops up the notification bar at top of screen
      this.browserProblemSevere = false; // pops up the fullscreen modal (you need to pick one of these browsers), which suppresses notification bar until dismissed
    }

    if (this.deviceInfo.browser === 'ie' && this.deviceInfo.os == "windows") {
      let winverStr = this.deviceInfo.os_version.replace(/windows-/,'');
      let winver = parseInt( winverStr );
      if (7.0 <= winver) {
        this.suggestedBrowser = "<a target='msedge' class='link99' href='https://www.microsoft.com/en-us/windows/microsoft-edge'>Microsoft Edge</a> or <a target='googchrome' class='link99' href='https://www.google.com/chrome/'>Google Chrome</a>";
      }
    }
    this.testForBackend();
  }

  async goToNewApp(link = '/_/home', include_query_params = true) {
    if(window.location.search && include_query_params) link = link+window.location.search;
    location.replace(link);
  }

  public _buildAPIrestEndpoint(append){
    let rest_url = window.location.protocol + "//" + (
      window.location.hostname.match( /(aviahealthinnovation\.com|avia\.health|devops-avia\.com|aviaenv\.com)/ ) ?
        (`${window.location.hostname}/api/rest/`) :
        (
          window.location.hostname + (
            environment.dataServicePort === '' || environment.dataServicePort === undefined || environment.dataServicePort === null ?
            '' :
            ":" + environment.dataServicePort
          )
        )
      );

      return rest_url += append;
  }


  public async getNodeIdFromLegacy(legacy_id, legacy_table){
    let rest_url = this._buildAPIrestEndpoint(`util/convert/legacy_id_to_node_id`);
    const res = await this.xhrAuth('POST', rest_url, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, [{legacy_id, legacy_table}]);
    return _get(res, 'body[0]', null)
  }

  public async buildProfileLink(legacy_id, legacy_table){
    const obj = await this.getNodeIdFromLegacy(legacy_id, legacy_table);
    let link = `/_/profile/${obj.id}`;
    return link;
  }

  public async rerouteByLegacyRoute(route, state) {

    let url = state.url;
    let link;

    if(
      url.includes('/km')
    ) {
      link = (route.queryParams && route.queryParams.id)
        ? await new Promise((resolve, reject) => {
            // Why the timeout below?
            // Right after the topic is created, the record is first added to the old tables
            // and it takes some time to reflect it to the new node-edge
            // The invocation to the function buildProfileLink(...) requests the new backend api
            // to get the node id of the recently created topic. Since the record has not been replicated to new node-edge,
            // it returns an empty array and the redirection to new topic profile fails. Thus, the timeout.
            // The value of the timeout is based on multiple hit-and-trial.
            // TODO: improve this implementation.
            setTimeout(async () => {
              const res = await this.buildProfileLink(route.queryParams.id, 'km_cards');
              resolve(res);
            }, 4000)
          })
        : `/_/home`;
    } else if(
      url.includes('/intelligence/cl')
    ) {
      link = (route.params && route.params.id) ? await this.buildProfileLink(route.params.id, 'content') : `/_/home`;
    } else if(
      url.includes('/profiles/in')
    ) {
      link = (route.params && route.params.id) ? await this.buildProfileLink(route.params.id, 'users') : `/_/home`;
    } else if(
      url.includes('/profiles')
    ) {
      link = (route.params && route.params.id) ? await this.buildProfileLink(route.params.id, 'orgs') : `/_/home`;
    } else if(
      url.includes('/sc')
    ) {
      link = (route.params && route.params.id) ? (route.params.product_id) ? await this.buildProfileLink(route.params.product_id, 'products') : await this.buildProfileLink(route.params.id, 'orgs') : `/_/home`;
    } else if(
      url.includes('/communities/page')
    ) {
      if(route.params && route.params.page_id) {
        // Fetch the community using page_id, send cm_id to backend
        console.log(route.params)
        let community_page_res = await this.getComPage(route.params.page_id);

        console.log(community_page_res)
        if (community_page_res.status == 200) {
          let community_res = await this.getCommunity(community_page_res.body.cm_id);
          if (community_res.status == 200) {
            console.log(community_res)
            link = (community_res.body.org) ? await this.buildProfileLink(community_res.body.org, 'orgs') : `/_/home`;
          }
        }
      } else {
        console.log('hit')
        link = `/_/home`;
      }
    } else {
      return
    }

    if(link) {
      location.replace(link);
    }
  }

  async goToNewGroups(paramsObj) {
    let link = '/_/groups';
    if(paramsObj) {
      link += Common.buildQueryParams(paramsObj);
    } else if(window.location.search) {
      link = link+window.location.search;
    }

    location.replace(link);
  }

  async goToNewLegal(paramsObj) {
    let link = '/_/legal-information';
    if(paramsObj) {
      link += Common.buildQueryParams(paramsObj);
    } else if(window.location.search) {
      link = link+window.location.search;
    }

    location.replace(link);
  }

  async testForBackend() {
    let r = await this.xhr( 'GET', '/about', { 'Accept': 'application/json' }, undefined, undefined, { use_serverdown_queue: false, use_refresh_token: false } );
    this.backendTest = Common.isValidHTTPResponse( r.status );
    if (this.backendTest) {
      if (window['AVIA_TESTING'] !== true)
        console.info( "  Backend present: yes" );
    } else
      console.error( "  Backend present: cannot connect" );
    return { success: this.backendTest, result: r.body };
  }

  ////////////////////////////////////////////////////////////////////////////////
  //INIT - Must be call before using
  public async init(testing: boolean = false) {
    this.restoreLocal();
    if (this.browser_nag_dismissed) this.browserProblemSevere = false; // early opt-out, if they have previously dismissed the incompatible browser nag modal


    if (this.loggedIn && (this.access_token !== undefined && this.access_token !== '') || this.hasLoginCookies() && !testing ) {
      await this.loadPersistentData(true); // force reload of the persistant data - must be called first (before others access the cached data)
      await this.initShellAccess();
      await this.onAuthentication();
    }
    this.service_inited = true;
  }

  public async onAuthentication() {
    this.initSocketIO();
  }
  public async onUnauthentication() {
    this.websocket.destroy();
  }

  initSocketIO() {
    this.websocket.setAuth( this.access_token );
    this.websocket.setUrl( this.baseUrl );
    this.messages = <Subject<any>>this.websocket
      .connect()
      .pipe( map((response: any): any => {
        return response;
      }) );
  }

  // crash handler kernel (see app.component/crash-screen.component.html for onscreen component)
  // TO DISABLE:  see AVIAErrorHandlerComponent in app.module.ts, and comment it out there.  Dont forget to recomment it before checking in.
  // see also: aviaerror-handler.component.ts for the global exception handler
  crashed = false;
  crash = { goTo: '', message: '', error: '', reload: '' };
  crashCountDown;
  crashEnableReload = true;
  public onCrash( goTo, message, error, reload ) {
    let test_REFRESH = false; // ENABLE THIS to test the refresh in dev mode
    this.crash.goTo = goTo;
    this.crash.message = message;
    this.crash.error = 'Something happened at URL: ' + goTo + "<BR>";
    if (error.message) this.crash.error += "Error Message: \"" + error.message + "\"<BR>";
    if (error.ngDebugContext && error.ngDebugContext.component && error.ngDebugContext.component.constructor) this.crash.error += "In Component: " + error.ngDebugContext.component.constructor['name'] + "<BR>";
    if (error.ngDebugContext && error.ngDebugContext.componentRenderElement) this.crash.error += "In Element: " + error.ngDebugContext.componentRenderElement.localName + "<BR>";
    if (error.stack) this.crash.error += "<BR>Stack:<BR>" + error.stack.replace( /[\r\n]/g, "<BR>" );
    console.info( this.crash.error.replace( /<BR>/g, "\n" ) );
    this.crash.reload = reload;
    this.crashed = true;
    if (reload && (environment.production || test_REFRESH)) {
      this.crashCountDown = 10;
      timer(0,1000)
        .pipe( take(this.crashCountDown) )
        .pipe( map(()=> --this.crashCountDown) )
        .subscribe( x => {
          if (this.crashEnableReload && x === 0) window.location.href = goTo;
        });
    }

    if (!environment.production) {
      console.info( "-----------------------------\n" );
      console.info( "[Dev Mode] If this was production, we'd have reloaded " + goTo + " after a slow countdown timer.  Instead we're letting you see the errors" );
    }
  }

  eula_last_date_checked: any;// = moment("2017-07-05T23:59:59");
  doEulaCheck: boolean = false;
  EULA_VERBOSE: boolean = false;

  // HERE IS HOW THE EULA WORKS
  // 1.) by clicking SIGN IN, you IMPLICITLY ACCEPT the TOS  (no need to pop it up in the user's face)
  // 2.) if the user signs in with their cached token and  TOS version changes: then it should pop the EULA up in app for the user to accept.
  // 3.) if user leaves app up in their browser, then it should pop the EULA up in app for the user to accept.
  public async checkEula() {
    // CHECK: If first time running the app (i.e. on reload of page)
    if (this.eula_last_date_checked === undefined) {
      this.EULA_VERBOSE && console.log( "Eula: CHECK: If first time running the app (i.e. on reload of page)" );
      this.doEulaCheck = true;
      this.eula_last_date_checked = moment();
    }
    // CHECK: (when the app has been running) only check in the morning (if the day has changed)
    else if (0 < Math.abs(this.eula_last_date_checked.date() - moment().date())) {
      this.EULA_VERBOSE && console.log( "Eula: CHECK: (when the app has been running) only check in the morning (if the day has changed)" );
      this.doEulaCheck = true;
    }

    // keep track of the last time we checked the date...
    this.eula_last_date_checked = moment();
    this.EULA_VERBOSE && console.log( "Last Date Checked:" );
    this.EULA_VERBOSE && console.log( this.eula_last_date_checked );
    this.EULA_VERBOSE && console.log( "Eulabox:\ndoEulaCheck: " + this.doEulaCheck + "\neulabox defined: " + this.eulabox !== undefined );
    if (this.doEulaCheck && this.eulabox !== undefined) {
      let session = await this.getSessionSupport();
      this.EULA_VERBOSE && console.log( "Session:" );
      this.EULA_VERBOSE && console.log( session );
      this.EULA_VERBOSE && console.log( session.user );
      this.EULA_VERBOSE && console.log( session.user.EULA );
      if (
        session.user === undefined ||
        session.user.EULA === undefined ||
        session.user.EULA.tos_version === undefined || session.user.EULA.tos_version !== EULAComponent.version_tos ||
        session.user.EULA.privacy_version === undefined || session.user.EULA.privacy_version !== EULAComponent.version_pri ||
        session.user.EULA.third_party_version === undefined || session.user.EULA.third_party_version !== EULAComponent.version_3rd
      ) {
        console.log("There's a new version of the EULA/TOS/PrivacyPolicy:");
        console.log("TOS      is:" + EULAComponent.version_tos + " last one I accepted was:" + ((session.user !== undefined && session.user.EULA !== undefined) ? session.user.EULA.tos_version : "never"));
        console.log("PRIVACY  is:" + EULAComponent.version_pri + " last one I accepted was:" + ((session.user !== undefined && session.user.EULA !== undefined) ? session.user.EULA.privacy_version : "never"));
        console.log("3rdParty is:" + EULAComponent.version_3rd + " last one I accepted was:" + ((session.user !== undefined && session.user.EULA !== undefined) ? session.user.EULA.third_party_version : "never"));
        this.eulabox.open(r => { this.doEulaCheck = false; });
      } else {
        this.EULA_VERBOSE && console.log( "You've previously accepted the EULA, thank you" );
      }
    }
  }

  public async logEula() {
    if (this.loggedIn) {
      // getSessionSupport(true) is auto called for us by updateUser
      console.log( "Accepting the EULA, thank you." );
      this.doEulaCheck = false;
      this.eula_last_date_checked = moment();
      return await this.updateUser(this.session.user.id, {
        EULA: {
          tos_version: EULAComponent.version_tos,
          privacy_version: EULAComponent.version_pri,
          third_party_version: EULAComponent.version_3rd
        }
      });
    }
    return await new Promise<any>((rs, rj) => rj("cant log TOS/Privacy acceptance if not logged in"));
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public hasLoginCookies(): boolean {
    return document.cookie.indexOf("loggedIn=true") > -1;
  }

  async checkOnboarding(): Promise<void> {
    if (this.session && this.session.user && this.session.user.onboarding_start == 0) {
      setTimeout(() => {
        this.onboarding.open( () => {
          //console.log(`Onboarding Complete for: ${this.session.user.fullname}`);
        });
      }, 500)
    } else if (this.session.org.type == 2 && this.session.user.is_owner && this.session.org.onboarding == null) {
      setTimeout(() => {
        this.onboarding.openScOnboardingOnly( () => {
          //console.log(`Onboarding Complete for: ${this.session.user.fullname}`);
        });
      }, 500)
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  //LOCAL STORAGE
  public saveLocalValue(key: string, value: any) {
    if (this.testLocalStorage() && value !== "undefined" && value != undefined)
      localStorage.setItem(key, value);
    else if(this.testLocalStorage())
      localStorage.removeItem(key);
  }

  public getLocalValue(key: string, default_value: any): any {
    if (this.testLocalStorage() && localStorage[key])
      return localStorage.getItem(key);
    else
      return default_value;
  }
  // send 'all' to clear local storage
  public deleteLocalValue(key: string): any {
    if(this.testLocalStorage()) {
      if (key === 'all') { localStorage.clear() }
      else if (this.testLocalStorage() && localStorage[key]) {
        localStorage.removeItem(key);
      } else {
        //console.log('Could not remove local storage key: ' + key)
      }
    }
  }

  public clearCookies(): any {
    //immediately clear the loggedIn cookie with javascript
    document.cookie = `loggedIn=false;domain=${window.location.hostname};max-age=0;`;
    document.cookie = `loggedIn=false;domain=.${window.location.hostname};max-age=0;`;
    document.cookie = `loggedIn=false;max-age=0;`;
    document.cookie = `_ga_user_id=''; max-age=0; path=/`;
    document.cookie = `_ga_org_id=''; max-age=0; path=/`;
    document.cookie = `_ga_org_type_id=''; max-age=0; path=/`;
    //call the api to remove other cookies
    return this.xhr( 'POST', '/oauth/token/signout' );
  }

  private _testLocalStorage:boolean = (() => {
    try {
      var testKey = 'test', storage = window.sessionStorage;
      var testKey = 'test', storage = window.localStorage;

      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    } catch (err) {
      this.newrelicError( err );
      return false;
    }
  })()

  testLocalStorage(): boolean {
    return this._testLocalStorage;
  }

  // save state we'd like to persist between page refresh or browser restart
  public saveLocal() {
    if (typeof (Storage) !== "undefined") {
      this.saveLocalValue("user", this.user);
      // this.saveLocalValue("pass", this.pass);
      this.deleteLocalValue("pass"); // adding this to clear out everyone's storage

      if(this.cookie_mode === false) { //only store the tokens in local storage if not in cookie mode
        this.saveLocalValue("access_token", this.access_token);
        this.saveLocalValue("refresh_token", this.refresh_token);
        if(this.hasLoginCookies()) {
          this.clearCookies();
        }
      }
      else {
        this.deleteLocalValue("access_token");
        this.deleteLocalValue("refresh_token");
      }

      this.saveLocalValue("token_type", this.token_type);
      this.saveLocalValue("expires_in", this.expires_in);
      this.saveLocalValue("expires", this.expires);
      this.saveLocalValue("loggedIn", this.loggedIn);
      this.saveLocalValue("browser_nag_dismissed", this.browser_nag_dismissed);
      //await this.loadPersistentData( true );
    } else {
      console.log("Sorry! No Web Storage support...");
    }
  }

  // load state saved by saveSession()
  public restoreLocal() {
    if (typeof (Storage) !== "undefined") {
      this.user = this.getLocalValue("user", "");
      this.deleteLocalValue("pass"); // adding this to clear out everyone's storage

      this.access_token = this.getLocalValue("access_token", undefined);
      this.refresh_token = this.getLocalValue("refresh_token", undefined);
      this.token_type = this.getLocalValue("token_type", undefined);
      this.expires_in = this.getLocalValue("expires_in", undefined);
      this.expires = this.getLocalValue("expires", undefined);
      let logged_in = this.getLocalValue("loggedIn", false);
      if (logged_in == 'true') this.loggedIn = true;
      else if (logged_in == 'false') this.loggedIn = false;
      else this.loggedIn = logged_in;

      if( this.access_token === undefined && this.access_token !== '' ) {
        this.loggedIn = false;
        if( this.hasLoginCookies() ) {
          this.loggedIn = true;
        }
      }

      let browser_nag_dismissed = this.getLocalValue("browser_nag_dismissed", false);
      if (browser_nag_dismissed == 'true') this.browser_nag_dismissed = true;
      else if (browser_nag_dismissed == 'false') this.browser_nag_dismissed = false;
      else this.browser_nag_dismissed = browser_nag_dismissed;

      //await this.loadPersistentData( true );
    } else {
      console.log("Sorry! No Web Storage support...");
    }
  }
  // remove local store saved by saveSession()
  // on logout do all and onboard just user / pass ?
  public deleteLocal() {
    if (typeof (Storage) !== "undefined") {
      this.deleteLocalValue("user");
      this.deleteLocalValue("pass"); // keeping this to clear out everyone's storage
      this.deleteLocalValue("access_token");
      this.deleteLocalValue("refresh_token");
      this.deleteLocalValue("token_type");
      this.deleteLocalValue("expires_in");
      this.deleteLocalValue("expires");
      this.deleteLocalValue("loggedIn");
      this.deleteLocalValue("browser_nag_dismissed");
    } else {
      console.log("Sorry! No Web Storage support...");
    }
  }

  // signIn_WithToken
  public async changeToken( access_token ) {
    // for dramatic effect, switch the route to /signin, then back to /start

    await this.signOut();
    setTimeout( async () => {

      // log back in as next user:
      this.loggedIn = true;

      if(this.cookie_mode === true) {
        document.cookie = `loggedIn=true;domain=${window.location.hostname};`;
        document.cookie = `access_token=${access_token};domain=${window.location.hostname}`;
      }
      else {
        this.access_token = access_token;
      }
      this.saveLocal();
      await this.loadPersistentData(true);
      await this.initShellAccess();
      await this.onAuthentication();

      console.info( "Navigating to /start" );
      this.navigate( '/start' );

      // for dramatic effect, output a delayed inspirational message
      setTimeout( () => {
        console.info( "Reloaded as new user [done], enjoy the NEW YOU!" );
      }, 1000);
    }, 1);
  }


  ////////////////////////////////////////////////////////////////////////////////
  //SIGN IN, SIGN OFF, EMAIL VERIFY

  semaphore_signIn = new Semaphore(1);
  SIGNIN_VERBOSE:boolean = false;

  // login, caches the access/refresh token for future auth calls.
  public signIn(u: string, p: string, d: any): Promise<any> {
    return new Promise<any>((rs, rj) => {
      this.semaphore_signIn.take(() => {
        if (this.loggedIn && localStorage.loggedIn) {
          console.log("signIn(): already logged in, returning existing token")
          this.semaphore_signIn.leave()
          rs({ success: true, access_token: this.access_token })
          return;
        }
        this.___signIn(u, p, d).then(
          r => { this.semaphore_signIn.leave(); return rs(r) },
          e => { this.semaphore_signIn.leave(); return rj(e) },
        )
      })
    })
  }

  // login, caches the access/refresh token for future auth calls.
  async ___signIn(u: string, p: string, d: any): Promise<any> {
    let is_sso = d ? true : false;
    try {
      this.SIGNIN_VERBOSE && console.log( "___signIn()" );
      //let body = util.inspect({ 'grant_type':'password','username':u,'password':p });
      //let headers = new Headers({ 'Content-Type': 'application/json' });
      let data = d;
      if(data == undefined) {
        let body = 'grant_type=password&username=' + encodeURIComponent(u) + '&password=' + encodeURIComponent(p);
        let headers = { 'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic YXZpYWNvbm5lY3Q6bmlnaHRzaGFkZQ==' };
        data = await this.xhr( 'POST', '/oauth/token?cookie_mode=' + this.cookie_mode, headers, body );
        this.SIGNIN_VERBOSE && console.log( "___signIn():\nresult:\n", data );
        if (data.status !== 200) {
          this.SIGNIN_VERBOSE && console.log( "___signIn(): FAILED!!!!  invalid user/pass probably..." );
          this.loggedIn = false;
          return { success: false, backend_response: data };
        }
        data = data.body;
      }

      this.user = data.username;
      this.access_token = data.access_token;
      this.refresh_token = data.refresh_token;      // if the refresh TTL is 0, then refresh_token will be missing from data (undefined)
      this.expires_in = data.expires_in;
      this.expires = (this.expires_in * 1000) + Date.now() - (60 * 1000);
      this.token_type = data.token_type;
      this.newrelicSetAttribute( 'user', data.username );

      let retval = {};
      await this.loadPersistentData( true ); // reload all the cached data
      let session = await this.getSessionSupport();
      if (session['user'] === undefined) {
        console.log( '___signIn got a undefined session.user from getSessionSupport', 'data', data, 'session', session );
        this.newrelicSetAttribute( 'data', JSON.stringify( data ) );
        this.newrelicSetAttribute( 'session', JSON.stringify( session ) );
        this.newrelicSetAttribute( 'WTF', '___signIn got a undefined session.user from getSessionSupport' );
        this.newrelicError( session );
        return { success: false, backend_response: { body: { status_name: 'inactive' } } };
      }
      let obj: any = {};
      let user_status = session['user']['status_obj']['name'];
      if (user_status === 'active') {
        this.SIGNIN_VERBOSE && console.log('___signIn(): User is Active');
        this.loggedIn = true;
      } else if (user_status === 'verified') {
        this.SIGNIN_VERBOSE && console.log('User is Verified');
        this.loggedIn = true;
        obj.id = session['user'].id;
        obj.salesforce_id = session['user'].salesforce_id;
        obj.action = 'activate';
        obj.email = session['user'].emails[0].email;
        obj.givenName = session['user'].firstname;
        obj.surname = session['user'].lastname;
      } else if (user_status === 'pending') {
        this.SIGNIN_VERBOSE && console.log('___signIn(): User is Pending, logging out');
        this.loggedIn = false;
        retval['message'] = 'Email address is not verified.';
        retval['show_verify_link'] = true;
      } else {
        this.SIGNIN_VERBOSE && console.log('___signIn(): User status (' + user_status + ') is unhandled, logging out' );
        this.loggedIn = false;
      }

      if (this.loggedIn) {
        // save eula acceptance
        if(!is_sso) await this.logEula();
        // update the user if needed...
        if (obj.id !== undefined) {
          let updated_user = (await this.updateUser( obj.id, obj )).result.user;
          if (session['user']['status'] !== updated_user.status) {
            // ANALYTIC EVENT for user status change
            let event = new AnalyticEvent(
              'user_status_change',
              {user_id: updated_user.id, current_status: updated_user.status, previous_status: session['user']['status']}
            );
            this.createAnalyticEvent(event);
          }
        }
      }

      // ...then setup the shell and exit
      this.saveLocal();
      await this.initShellAccess();
      await this.onAuthentication();
      retval['success'] = this.loggedIn;
      retval['access_token'] = this.access_token;
      return retval;
    } catch (err) {
      this.loggedIn = false;
      console.error('there was an error in ___signIn');
      console.error(err);
      this.newrelicError( err );
      return { success: false, err: err, backend_response: { body: { status_name: 'inactive' } } };
    }
  }


  public async signOut( options:any = {} ) {
    console.log('signOut: ', options);

    let _options:any = {reload: false, nav: true};
    _options = Object.assign( _options, options );

    this.SIGNIN_VERBOSE && console.log( "signOut()" );
    // Reset service variables
    this.has_dismissed_pulse = false;
    this.has_visited_pulse = false;
    this.loggedIn = false;

    if(!options.keep_local_storage) {
      this.redirectUrl = '';
      this.redirectUrlParams = '';
      this.deleteLocalValue('all');
    }

    // this.mixpanelEnabled() && mixpanel.track("Sign Out");
    this.clearShellAccess();

    this.forgetPersistentData();
    this.onUnauthentication();

    await this.clearCookies();
    if (!this.useTestMocks) {
      this.SIGNIN_VERBOSE && console.log( "signOut()" );
      if (_options.nav) {
        //only navigate if not already on the signin page...
        if(this.router && this.router.url && this.router.url.indexOf("/signin") !== 0) {
          this.navigate( '/signin' );
        }
      }
      if (_options.reload)
        this.reload();
    }
  }

  // safe way to navigate (router may not be defined, i.e. in tests)
  public navigate( route ) {
    if (this.router) {
      console.log("navigating...");
      this.router.navigate([route]);
    }
  }

  // reload the app
  public reload() {
    location.reload();
  }

  public startEmailWorkflow(email, invite_type = "createpassword"): Promise<any> {
    let url = `/emailworkflow/start/${invite_type}/` + encodeURI(email);
    let body = {};
    return this.xhr('POST', url, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, body).then(data => data.body, err => { throw err.body });
  }

  public finishEmailWorkflow(token: string, password: string, test: boolean = false): Promise<any> {
    let url = '/emailworkflow/finish/' + encodeURI(token);
    url += test ? '?test=1' : '';
    let body = { password: password };
    return this.xhr('POST', url, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, body).then(data => data.body, err => { throw err.body });
  }

  public publicPageCheck() {
    if (this.loggedIn) {
      this.signOut();
    }
  }

  // NewRelic Browser Docs https://docs.newrelic.com/docs/browser?toc=true
  public newrelicError( data: any, attributes?: any ) {
    if (newrelic && newrelic.noticeError) {
      newrelic.noticeError( data, attributes );
    }
    this.newrelicEndInteraction();
  }
  // NewRelic Browser Docs https://docs.newrelic.com/docs/browser?toc=true
  public newrelicVersion( name: string, version: string ) {
    if (newrelic && newrelic.addRelease) {
      newrelic.addRelease( name, version );
    }
  }
  // we can enhance SPA logging in newrelic with interactions
  //   interaction().setName('kmcard').save(), setAttribute(), end()
  private nr_interaction: any;
  public newrelicStartInteraction( name = undefined ) {
    if (newrelic) {
      if (this.nr_interaction)
        this.nr_interaction.end();
      this.nr_interaction = newrelic.interaction();
      if (name) this.newrelicSetName( name );
      this.nr_interaction.save();
    }
  }
  public newrelicSetURL( url: string ) {
    if (newrelic && newrelic.setCurrentRouteName) {
      newrelic.setCurrentRouteName( url );
    }
  }
  public newrelicSetName( name: string ) {
    if (newrelic && this.nr_interaction && this.nr_interaction.setName) {
      this.nr_interaction.setName( name );
    }
  }
  public newrelicSetAttribute( key: string, val: string ) {
    if (newrelic && this.nr_interaction && this.nr_interaction.setAttribute) {
      this.nr_interaction.setAttribute( key, val );
    }
  }
  public newrelicEndInteraction() {
    if (newrelic && this.nr_interaction && this.nr_interaction.end) {
      this.nr_interaction.end();
      this.nr_interaction = undefined;
    }
  }

  semaphore_refreshToken = new Semaphore(1);

  // using the cached refresh_token from previous signIn(), get a new auth_token
  public refreshAuthToken(): Promise<any> {
    let current_token = this.access_token;
    let current_expires = this.expires;

    return new Promise<any>((rs, rj) => {
      this.semaphore_refreshToken.take(() => {
        // if the token or the expiration changed since we called the function, then someone else called refreshAuthToken(), so let's use that result
        // (only generate 1 token for many async refreshAuthToken() calls)
        if (current_token != this.access_token || current_expires != this.expires) { this.SIGNIN_VERBOSE && console.log( "refreshAuthToken(): already refreshed (semaphores rock!), returning existing token"); this.semaphore_refreshToken.leave(); return rs(this); }

        this.___refreshAuthToken().then(
          r => { this.semaphore_refreshToken.leave(); return rs(r); },
          e => { this.semaphore_refreshToken.leave(); return rj(e); }
        )
      })
    });
  }

  // using the cached refresh_token from previous signIn(), get a new auth_token
  async ___refreshAuthToken(): Promise<any> {
    if (this.refresh_token === undefined && !this.hasLoginCookies() ) {
      let result = {success: false, reason: "no refresh_token to use"};
      this.SIGNIN_VERBOSE && console.log( "__refreshAuthToken(): refresh_token undefined\nresult:\n" + util.inspect( result ) );
      console.error(result);
      await this.signOut();
      return { success: false};
    }

    if (!this.loggedIn) {
      // should never be signed out, this is an exceptional/unexpected case
      let msg = "__refreshAuthToken(): SIGNED OUT: can't refresh token if not even logged in...loggedIn:" + this.loggedIn.toString()
      console.error( msg );
      throw Error( msg );
    }

    if (this.loggedIn) {
      this.SIGNIN_VERBOSE && console.log( "__refreshAuthToken(): refreshing access_token using refresh_token:" + this.refresh_token );
      let body = 'grant_type=refresh_token&refresh_token=' + encodeURIComponent( this.refresh_token );
      let headers = { 'Accept': 'application/json',
                      'Content-Type': 'application/x-www-form-urlencoded',
                      'Authorization': 'Basic YXZpYWNvbm5lY3Q6bmlnaHRzaGFkZQ==' };
      try {
        let result = await this.xhr( 'POST', '/oauth/token?cookie_mode=' + this.cookie_mode, headers, body, undefined, { use_refresh_token: false } );
        if (result.status !== 200) {
          // we're handling failure by signing the user out
          let msg = "refresh failed with " + result.status;
          this.SIGNIN_VERBOSE && console.log( "__refreshAuthToken(): " + msg + "\nresult:\n" + util.inspect( result ) );
          await this.signOut();
          return { success: false};
        }
        else {
          result = result.body;
          this.SIGNIN_VERBOSE && console.log( "__refreshAuthToken(): SUCCESS:\nresult:\n" + util.inspect( result ) );
          this.refresh_token = result.refresh_token;
          this.access_token = result.access_token;
          this.expires_in = result.expires_in;
          this.expires = this.expires_in * 1000 + Date.now() - 60000;
          this.token_type = result.token_type;
          this.saveLocal();
          return { success: true };
        }
      } catch (err) {
        // crash, should never happen, this is an exceptional/unexpected case
        let msg = "__refreshAuthToken(): CRASH:\nerr:\n" + util.inspect( err ) +
          "\n\nURL: '/oauth/token'\nbody:\n" + body.replace( /&/, '\n' ) +
          "\nheaders:\n" + util.inspect( headers );
        this.SIGNIN_VERBOSE && console.log( msg );
        this.newrelicError( err );
        throw Error( msg );
      }
    }
  }

  // prepend a baseUrl only if the url doesn't start with "http
  public fixupUrl(baseUrl: string, url: string): string {
    if (url == undefined) {
      console.error("url passed in is undefined...");
      return baseUrl;
    }
    if (url.length < 4) {
      return baseUrl + url;
    }
    if (url.substring(0, 4) == "http")
      return url;
    else
      return baseUrl + url;
  }


  public serverDownQueue: any[] = []; // public so that angular2 templates can use .length to trigger showing the dlg
  SDQ_VERBOSE: boolean = false;

  public serverDownQueueAdd( item ) {
    this.serverDownQueue.push( item );
  }

  public async serverDownQueueProcess() {
    this.SDQ_VERBOSE && console.group('------------------------------------------------------------------------');
    this.SDQ_VERBOSE && console.log('Retrying the server down queue');
    this.SDQ_VERBOSE && console.log('Current Queue:');
    this.SDQ_VERBOSE && console.log(this.serverDownQueue);

    let promises = [];
    for (let item of this.serverDownQueue) {
      promises.push(
        this.xhr( item.type, item.url, item.headers, item.data, item.progressCB, { use_serverdown_queue: false }).then( response => {
          item.last_response = response;
            if (response.status !== 0) {
              item.rs( response );
            }
          })
        );
    }

    let p_all = await Promise.all( promises ).then(data => {
      this.serverDownQueue = this.serverDownQueue.filter( response => {
        this.SDQ_VERBOSE && console.log(response);
        return response.last_response && response.last_response.status === 0;
      });
    });

    this.SDQ_VERBOSE && console.groupEnd();
    this.SDQ_VERBOSE && console.log('------------------------------------------------------------------------');

    return p_all;
  }

  XHR_VERBOSE:boolean = false;

  // Make an HTTP request
  // headers is typically: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json' }
  // - To auto-stringify the data Javascript Object into a string:  pass {'Content-Type': 'application/json; charset=utf-8' } into the headers param
  // - To auto-parse the response body JSON into a JavaScript Object:  pass {'Accept': 'application/json'} into the headers param
  // success result is returned with { body: Object, headers: [] }
  // options are: use_serverdown_queue, use_refresh_token
  public xhr(helperObj: string | xhrHelper /* PUT, GET, POST, DELETE */, url: string, headers: Object = {}, data: any = undefined, progressCB: (bytes_xfered: number, err: string, abort?: () => any) => any = undefined, options = {}): Promise<any> {
    /// xhr helper vars
    let type;
    // if we are passed a string we use the defaults
    if (typeof helperObj == "string") {
      type = helperObj;
      helperObj = {
        method: type,
        status_check: false,
        default_object: undefined,
        path_to_return: undefined,
      }
    } else {  // get or set defaults for helper obj
      const { method, status_check = false, default_object = undefined, path_to_return = undefined } =  helperObj;
      type = method;
      helperObj = {
        method,
        status_check,
        default_object,
        path_to_return,
      }
    }

    data = (type === 'POST' || type === 'post') && data === undefined ? {} : data;
    let abort_called = false;
    function needToStringifyInputData( headers ) {
      return headers['Content-Type'] !== undefined && headers['Content-Type'].split(';').includes('application/json');
    }
    function needToParseRecvBody( headers ) {
      return headers !== undefined && headers['Accept'] !== undefined && headers['Accept'].split(';').indexOf('application/json') !== -1;
    }
    function returnHelper(helperObj, returnObj) {
      // if not check we just return
      if (helperObj.status_check === false ) return returnObj;
      // if we get a bad res return the dafult obj
      if (returnObj.status !== 200 ) return helperObj.default_object;
      // if we speciby a path we return that path
      if (helperObj.path_to_return !== undefined) return _get(returnObj, helperObj.path_to_return, helperObj.default_object);
      // else we just return the responce
      return returnObj;

    }

    this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): -->\nheaders:\n  " + util.inspect( headers ) + (data !== undefined ? "\ndata:\n  " + util.inspect( data ) : '') + (Object.keys( options ).length > 0 ? "\noptions:\n  " + util.inspect( options ) : '') );

    return new Promise((rs, rj) => {
      let _options = {use_serverdown_queue:true, use_refresh_token:true}; // default opts
      for (let op in options) _options[op] = options[op];

      let xhr: XMLHttpRequest = new XMLHttpRequest();

      //need to tell xhr to include cookies
      xhr.withCredentials = true;

      let Abort = () => {
        abort_called = true;
        xhr.abort();
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          let body = xhr.response;

          // decode known types:
          if (body !== undefined && body != "" && needToParseRecvBody(headers)) {
            try {
              body = JSON.parse( body );
            } catch (err) {
              this.newrelicError( err );
              util.inspect( err );
              util.inspect( body );
            }
          }

          // if the client is running an old front end, signal we want a reload
          if (urlIsAviaDataService) {
            let AVIA_DATA_SERVICE_VERSION = xhr.getResponseHeader('Avia-Data-Service-Ver');

            if (AVIA_DATA_SERVICE_VERSION !== null && AVIA_DATA_SERVICE_VERSION !== undefined) {
              // we set it like this, so that if the backend goes away, and then back to our version, we'll get that.
              this.shouldReloadNewVersion = (AVIA_DATA_SERVICE_VERSION !== environment.version);
            }
          }

          // parse headers string into a hash
          let res_headers = {};
          let h_str = xhr.getAllResponseHeaders();
          let h_array = h_str.match(/[^\u000D\u000A].*/gi);
          if (h_array) {
            for (let h of h_array) {
              let key_value = h.split(":");
              res_headers[key_value[0]] = key_value[1].substr(1).replace(/["']/g, "");
            }
          }

          if (Common.isValidHTTPResponse(xhr.status)) {
            let result = returnHelper(helperObj, { status: xhr.status, body: body, headers: res_headers });
            this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): <-- SUCCESS:\nresult:\n" + util.inspect( result ) );
            return rs( result );
          } else {
            // 401 Unauthorized, try refreshing the token
            if (xhr.status == 401 && _options.use_refresh_token) {
              this.XHR_VERBOSE && console.error( "xhr( "+type+", "+url+" ): <-- ERROR: response 401 unauthorized\n  Trying to refresh token, if not successful, we WILL auto-sign YOU out!" );
              return this.refreshAuthToken().then(
                r => {
                  // refresh succeeded, retry the same xhr call
                  this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): got new refresh_token: retry original xhr() with new access_token:" + this.access_token );
                  if (headers['Authorization'] !== undefined)
                    headers['Authorization'] = 'Bearer ' + encodeURIComponent( this.access_token );
                  this.xhr( type, url, headers, data, progressCB, { use_refresh_token: false } ).then(
                    r => {
                      if (Common.isValidHTTPResponse( r.status )) {
                        this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): refreshtoken: 2nd xhr() success:\n", r );
                        if (r.status === 401) { return this.signOut({reload: true, nav: false}).then( rr => rs(returnHelper(helperObj, r))) }
                        else return rs( returnHelper(helperObj, r) ); // GOOD!
                      } else {
                        this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): refreshtoken: 2nd xhr() error: " + r.status );
                        return this.signOut({reload: true, nav: false}).then( rr => rs({ status: r.status }) )
                      }
                    }
                  )
                },
                // auto reroute user to login screen (hard reload to purge the expecting xhr() callers) they have no business making xhr calls
                err => this.signOut({reload: true, nav: false}).then( r => rs( err ) )
              );
            } else if (xhr.status == 0/*|| xhr.status == 429*/ /* too many requests */ ) {
              if(abort_called) {  //xhr.abort will set status to 0, need this block to prevent it from going into server down yellow/orange bar
                let result = { body: body, headers: res_headers, status: xhr.status, message: 'aborted'};
                return rs( returnHelper(helperObj, result) );
              }
              // status == 0 usually means that the server is down...  try again!
              if (_options.use_serverdown_queue) {
                this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): <-- SERVER DOWN: response status(" + xhr.status + "): queuing until server comes back" );
                let item = {type: type, url: url, headers: headers, data: data, progressCB: progressCB, rs: rs, rj: rj};
                this.serverDownQueueAdd( item );
              } else {
                this.XHR_VERBOSE && console.log( "xhr( "+type+", "+url+" ): <-- SERVER DOWN: resolving for 'ServerDownQueue processing'" );
                return rs( returnHelper(helperObj, xhr)  ); // resolves in the serverDownQueueProcess() method that called me
              }
            } else {
              // error >= 400, let the app deal with it
              this.XHR_VERBOSE && console.error( "xhr( "+type+", "+url+" ): <-- ERROR: response status(" + xhr.status + "):\n\n" + util.inspect( body ) );
              if (progressCB) {
                progressCB( undefined, xhr.response, Abort );
              }
              let result = { body: body, headers: res_headers, status: xhr.status };
              // never reject() on >=400 error codes,
              // they're not exceptions, servers return them all the time, we need to deal with it either globally, or case by case...
              // so let's figure it out, but it's not an exception. shouldn't crash the app when unhandled.
              return rs( returnHelper(helperObj, result));
            }
          }
        }
      }; // xhr.onreadystatechange => () {}

      if (progressCB) {
        xhr.onprogress = (event) => {
          if((!event.lengthComputable && event.loaded === 0 && event.total === 0)) {   // This is to prevent progress from going back down to zero
            return;
          }
          progressCB( event.loaded, undefined, Abort );
        };
      }
      // some browsers (chrome for some reason) doesn't update with onprogress alone...
      xhr.upload.addEventListener( "progress", xhr.onprogress ); // chrome
      xhr.addEventListener( "progress", xhr.onprogress ); // firefox

      // auto fix the URL for our own local URLs (those without a http prefix)
      let fuu = this.fixupUrl( this.baseUrl, url );
      let urlIsAviaDataService = (fuu != url);

      // based on the header, transform the data as needed
      let dataToSend = data; // dont change any of the input data, this lets us resubmit the xhr() using the original inputs again...
      if (needToStringifyInputData( headers ))
        dataToSend = JSON.stringify( data );

      xhr.open( type, fuu, true );
      for (let key in headers) {
        xhr.setRequestHeader( key, headers[key] );
      }

      if(progressCB) {
        progressCB( 0, undefined, Abort);
      }
      xhr.send( dataToSend );
    });
  }

  public async validateAndRefreshTokens() {
    const hasLoginCookies = this.hasLoginCookies();
    //  Refresh using the refresh token if we switched auth modes or if token is expired
    //  we are in cookie mode but do not have login cookies
    //  -or-
    //  we aren't in cookie mode but do not have an access_token
    //  -or-
    //  the token is expired
    //  AND
    //  we have a refresh token that we can use
    //  -or-
    //  we have login cookies that we can use
    if(this.loggedIn === true && (( this.cookie_mode === true  && !hasLoginCookies ) ||
      ( this.cookie_mode === false && ( this.access_token == undefined || this.access_token == '' ) ) ||
      ( this.expires <= Date.now() )
        &&
      ( ( this.refresh_token !== undefined && this.refresh_token !== '' ) ||
      hasLoginCookies === true ) )) {
      await this.refreshAuthToken();
    }
  }

  // Make an authorized HTTP request to the avia backend.  (if you need authorized for non-AVIA data services, then use xhr() directly with your own Authorization header.)
  // WARNING: user must be logged in before making this call.
  //
  // NOTE:
  // To auto-parse the response body JSON into a JavaScript Object:  pass {'Accept': 'application/json'} into the headers param
  // headers is typically: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json' }
  public async xhrAuth(helperObj: string | xhrHelper, url: string, headers: Object = {}, data: any = undefined, progressCB: (bytes_xfered: number, err: string, abort?: () => any ) => any = undefined) {
    // nothing to do if you dont have a key in the first place. (prevents residual auth'd calls after signOut)
    await this.validateAndRefreshTokens();
    /// xhr helper vars
    let type;
    // if we are passed a string we use the defaults
    if (typeof type == "string") {
      type = helperObj;
    } else { // we use what was passed
      const temp = helperObj as xhrHelper; // needed to cast (stops the redlines )
      type = temp.method;
    }
    if ( ( this.access_token == undefined || this.access_token == '' ) && !this.hasLoginCookies() ) {
      console.info( `xhrAuth( '${type}', '${url}' ): no auth token (access_token = '${this.access_token}').  This can occur right after signout, if any pending xhr calls.` ); // developer, please fix!  figure out why this is being called without a token
      return { status: 401, body: {}, logged_in: this.loggedIn, error: "no access token, user probably not logged in" };
    }

    //if we have a local storage access token, then use that by setting the authorization header
    if(this.access_token !== undefined && this.cookie_mode === false) {
      headers['Authorization'] = 'Bearer ' + encodeURIComponent( this.access_token );
    }

    return this.xhr( helperObj, url, headers, data, progressCB );
  }


  //////////////////////////////////////////////////////////////////////////////
  // MOBILE Utilities

  public toggleFullScreen(force_on: boolean = false) {
    let doc = window.document;
    let docEl = doc.documentElement;

    if (!doc['fullscreenElement'] && !doc['mozFullScreenElement'] && !doc['webkitFullscreenElement'] && !doc['msFullscreenElement']) {
      let requestFullScreen = docEl['requestFullscreen'] || docEl['mozRequestFullScreen'] || docEl['webkitRequestFullScreen'] || docEl['msRequestFullscreen'];
      if (requestFullScreen && docEl) requestFullScreen.call(docEl);
    } else {
      let cancelFullScreen = doc['exitFullscreen'] || doc['mozCancelFullScreen'] || doc['webkitExitFullscreen'] || doc['msExitFullscreen'];
      if (!force_on && cancelFullScreen && doc) cancelFullScreen.call(doc);
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  //FILE UPLOADER

  // Upload a Single File to Amazon AWS:S3
  // Usage:
  // [.html]
  //    <form (ngSubmit)="onSubmit(f)" #f="ngForm" action=""> <input type="file" (ngModelChange)="fileEvent($event)" /> </form>
  //    <button (click)="uploadfile(f)">Upload file!</button>
  //
  // [Component]
  //    uploadfile( event: any ) {
  //      this.aviaService.uploadToS3( SOME_UNIQUE_GUID_STRING, this.file )
  //        .then( r => {
  //          if (r.message == "success") {
  //            this.link = r.url;
  //          } else {
  //            this.error = "upload failed: " + r.info;
  //          }
  //        });
  //    }
  //    fileEvent( event: any ){
  //        this.file = event.target.files[0];
  //    }
  //
  // [returns]
  //  onSuccess: { message: "success", url: "http://the link to s3", hash: 'ASDHF3270sdZVNsdafio34' }
  //  onError: { message: "error", info: "some error text" }
  public async uploadToS3(
    filename: string,
    download_filename: string,
    data: any,
    contentType: string = 'application/octet-stream',
    folder: string = undefined,
    progressCB: (size: number, progress: number, err: string, abort?: () => any) => any ): Promise<any> {
    if (data == undefined || data.size <= 0) { return { message: "error" }; }

    let s3_sign_request = `/s3/sign/${filename}?file_type=${contentType}`;
    if(folder) s3_sign_request += `&folder=${folder}`;
    let r = await this.xhrAuth( 'GET', s3_sign_request, { 'Accept': 'application/json' } );

    if (r.body.signed_request == undefined) {
      console.error("ERROR: backend couldnt give us a signed 'put' URL for:'" + filename + "' of type:'" + contentType + "'");
      return { message: "fail" };
    }

    let signed_request = r.body.signed_request;
    let url = r.body.url;
    let file_name = r.body.filename;

    // PUT params: http://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPUT.html
    let headers = { 'Content-Type': contentType, 'Content-Disposition': "attachment; filename=\"" + encodeURIComponent( download_filename ) + "\"" };
    r = await this.xhr( 'PUT', signed_request, headers, data, (p, e, abort ) => progressCB( data.size, p, e, abort ));

    if(r.status === 200) {
      let hash = r.headers['etag'] ? r.headers['etag'] : r.headers['ETag'];
      return { message: "success", url: url, hash: hash, file_name };
    } else {
      return r;
    }
  }

  // If you uploaded to S3, but discovered that the hash is the same as one already in the DB, then use this to undo/remove the file. Do not use this to delete files in general!
  public async undoUploadToS3( filename: string, folder: string = undefined ): Promise<any> {
    try {
      let url_request = `/s3/sign/${filename}?action=delete`;
      if(folder) url_request += `&folder=${folder}`;
      let r = await this.xhrAuth( 'GET', url_request, { 'Accept': 'application/json' } );
      if (r.body.signed_request == undefined) {
        console.error( "ERROR: backend couldnt give us a signed 'delete' URL for:'" + filename + "'" );
        return { message: "fail" };
      }
      let signed_request = r.body.signed_request;
      let r2 = await this.xhr( 'DELETE', signed_request );
      return { message: "success" };
    } catch (err) {
      this.newrelicError( err );
      return { message: "error" };
    }
  }

  // browser back functionality
  public back() {
    window.history.back();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ANALYTICS:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  public createAnalyticNodeEvent(obj): Promise<any> {
    let endpoint = this._buildAPIrestEndpoint(`analytics/events`);
    obj.attributes = Object.assign({}, obj.attributes);
    obj.attributes.browser = this.deviceInfo.browser || 'unknown';
    obj.attributes.browser_version = this.deviceInfo.browser_version || 'unknown';
    obj.attributes.device = this.deviceInfo.device || 'unknown';
    obj.attributes.os = this.deviceInfo.os || 'unknown';
    obj.attributes.os_version = this.deviceInfo.os_version || 'unknown';
    obj.attributes.user_agent = this.deviceInfo.userAgent || 'unknown';
    obj.attributes.path = location.pathname + location.search + location.hash;
    obj.attributes.location = {
      path: location.pathname,
      search: location.search,
      hash: location.hash
    }

    return this.xhr('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }


  public getAnalytics(paramsObj: Object = {}, {
    status_check = true,
    default_object = undefined,
    path_to_return = 'body' }): Promise<any> {

    let endpoint = '/analytics';
    return this.xhrAuth({ method: 'GET', status_check: true, default_object: undefined, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json' }).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public getAnalyticsReport(paramsObj: Object = {}): Promise<any> {
    let endpoint = '/analytics/report';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public createAnalyticEvent(obj): Promise<any> {
    let endpoint = '/analytics';
    obj.browser = this.deviceInfo.browser || 'unknown';
    obj.browser_version = this.deviceInfo.browser_version || 'unknown';
    obj.device = this.deviceInfo.device || 'unknown';
    obj.os = this.deviceInfo.os || 'unknown';
    obj.os_version = this.deviceInfo.os_version || 'unknown';
    obj.user_agent = this.deviceInfo.userAgent || 'unknown';
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }



  //////////////////////////////////////////////////////////////////////////////
  // UNIVERSAL SEARCH (not GET)

  // Search All

  public getSearchAll(paramsObj): Promise<any> {
    let endpoint = '/search/all';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public getSearchCustom(paramsObj, body): Promise<any> {
    let endpoint = '/search';
    endpoint += Common.buildQueryParams(paramsObj);
    /*
      body = {
        type:[{type: "user"}],
        fields:{
          "user":{
            "user.firstname":{score:16},
            "user.lastname":{score:16},
          },
          "org"
        }
      }
    */
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, body);
  }

  //Search Count
  // public getSearchCount(paramsObj): Promise<any> {
  //   let endpoint = '/search/count';
  //   endpoint += Common.buildQueryParams(paramsObj);
  //   return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  // }

  // KM
  public searchKMTopics(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.searchKMTopicsPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async searchKMTopicsPromise(paramsObj) {
    let endpoint = '/search/topics';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // Activities
  public universalSearchActivities(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchActivitiesPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async universalSearchActivitiesPromise(paramsObj) {
    let endpoint = '/search/activities';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // Content
  public universalSearchContent(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchContentPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async universalSearchContentPromise(paramsObj) {
    let endpoint = '/search/content';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  //File
  public async universalSearchFilePromise(paramsObj) {
    let endpoint = '/search/file';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // Users
  public universalSearchUsers(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchUsersPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async universalSearchUsersPromise(paramsObj) {
    let endpoint = '/search/users';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // Solcos
  public universalSearchSolcos(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchSolcosPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async universalSearchSolcosPromise(paramsObj, include_product = false) {
    let endpoint = include_product ? '/search/solcos_and_products' : '/search/solcos';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async universalSearchCommunityPromise(paramsObj) {
    let endpoint = '/search/community';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public universalSearchCommunity(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchCommunityPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  // ORGS
  public universalSearchOrgs(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchOrgsPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public universalSearchProducts(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchProductsPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async universalSearchOrgsPromise(paramsObj) {
    let endpoint = '/search/orgs';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async universalSearchProductsPromise(paramsObj) {
    let endpoint = '/search/products';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // HS
  public universalSearchHealthSystems(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type'];
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.universalSearchHealthSystemsPromise(paramsObj).then(data => data.body)); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async universalSearchHealthSystemsPromise(paramsObj) {
    let endpoint = '/search/healthsystems';
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // Activities
  // For universal search (rank ordered based on search terms) lists, use getUniversalSearchActivities()
  public async getUniversalSearchActivities() {
    let endpoint = `/search/${this.activities}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  // Priorities
  public async universalSearchPrioritiesPromise(paramsObj) {
    let endpoint = `/search/priorities`;
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public universalSearchPriorities(paramsObj: Object = {}): Observable<any> {
    return from(this.universalSearchPrioritiesPromise(paramsObj).then(data => data.body));
  }

  // Inventories
  public async universalSearchInventoriesPromise(paramsObj) {
    let endpoint = `/search/inventories`;
    endpoint += Common.buildQueryParams(paramsObj);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public universalSearchInventories(paramsObj: Object = {}): Observable<any> {
    return from(this.universalSearchInventoriesPromise(paramsObj).then(data => data.body));
  }


  ////////////////////////////////////////////////////////////////////////////////
  //RECOMMENDATIONS

  /* - - - Company - - - */
  public async getSolcoRecMetrics(id) {
    let endpoint = `/solco/${id}/rec/metrics`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getSolcoRecCapabilities(id) {
    let endpoint = `/solco/${id}/rec/capabilities`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getSolcoRecSolutions(id) {
    let endpoint = `/solco/${id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getSolcoRecCompanies(id) {
    let endpoint = `/solco/${id}/rec/companies`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  /* - - - Product - - - */
  public async getProductRecMetrics(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/rec/metrics`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  // Product rec metrics are built into getProductsBySolco()
  /* - - - Metric - - - */
  public async getMetricRecSolutions(id) {
    let endpoint = `/metric/${id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getMetricRecCapabilities(id) {
    let endpoint = `/metric/${id}/rec/capabilities`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getMetricRecCompanies(id) {
    let endpoint = `/metric/${id}/rec/companies`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  /* - - - Solution - - - */
  public async getSolutionRecMetrics(id) {
    let endpoint = `/solution/${id}/rec/metrics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getSolutionRecCapabilities(id) {
    let endpoint = `/solution/${id}/rec/capabilities`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getSolutionRecCompanies(id) {
    let endpoint = `/solution/${id}/rec/companies`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  /* - - - Capability - - - */
  public async getCapabilityRecMetrics(id) {
    let endpoint = `/capability/${id}/rec/metrics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getCapabilityRecSolutions(id) {
    let endpoint = `/capability/${id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }
  public async getCapabilityRecCompanies(id) {
    let endpoint = `/capability/${id}/rec/companies`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  /* - - - Channels - - - */
  public async getChannelRecCompanies(id:number) {
    let endpoint = `/channel/${id}/rec/companies`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getChannelRecTopics(id:number) {
    let endpoint = `/channel/${id}/rec/topics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getChannelRecMetrics(id:number) {
    let endpoint = `/channel/${id}/rec/metrics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getChannelRecSolutions(id:number) {
    let endpoint = `/channel/${id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  /* - - - Activity - - - */
  public async getActivityRecTopics(id) {
    let endpoint = `/activity/${id}/rec/topics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getActivityRecMetrics(id) {
    let endpoint = `/activity/${id}/rec/metrics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getActivityRecSolutions(id) {
    let endpoint = `/activity/${id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  /* - - - Priority - - - */
  public async getPriorityRecCapabilities(org_id, pri_id) {
    let endpoint = `/org/${org_id}/priority/${pri_id}/rec/capabilities`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityRecSolutions(org_id, pri_id) {
    let endpoint = `/org/${org_id}/priority/${pri_id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityRecMetrics(org_id, pri_id) {
    let endpoint = `/org/${org_id}/priority/${pri_id}/rec/metrics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  /* - - - Inventory - - - */
  public async getInventoryRecCapabilities(inv_id) {
    let endpoint = `/inventory/${inv_id}/rec/capabilities`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryRecSolutions(inv_id) {
    let endpoint = `/inventory/${inv_id}/rec/solutions`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryRecMetrics(inv_id) {
    let endpoint = `/inventory/${inv_id}/rec/metrics`
    return await this.xhrAuth(`GET`, endpoint, { 'Accept': 'application/json' });
  }


  ////////////////////////////////////////////////////////////////////////////////
  //ADMIN

  // User Data
  public async getUserById(id, org_id = undefined) {
    const org = org_id !== undefined ? `&org=${org_id}` : ''
    let endpoint = `/users/${id}?mode=full${org}`;
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body[0]); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getUserByEmail(email) {
    let endpoint = '/user/' + email;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'}).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getUsers(paramsObj: Object = {}) {
    let endpoint = '/users';
    endpoint += Common.buildQueryParams(paramsObj);
    let data = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getUsersInterestsTopics(obj) {
    let endpoint = '/users/interests/topics'
    endpoint += Common.buildQueryParams(obj);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj)
  };

  // TODO: delete this it will be replaced by addUserByOrg
  public async addNewUser(obj) {
    let endpoint = '/user';
    this.notifyWarning(`'AVIAConnectService::addNewUser' is deprecated.\n\nEverything is fine, but please let a developer know you saw this and what you were doing at the time.`);
    const res = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    if (res.status === 200){
      this.somethingHappened.emit( { type: E_SomethingHappened.USER_ADDED, data: {}} );
    }
    return res
  }
  public async addUserByOrg(id, obj){
    let endpoint = `/org/${id}/user`;
    const res = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    if (res.status === 200){
      this.somethingHappened.emit( { type: E_SomethingHappened.USER_ADDED, data: {}} );
    }
    return res
  }
  public async updateUser(id, obj) {
    let endpoint = '/user/' + id;
    let data = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj)

    if( this.session && this.session.user && this.session.user.id == id )
      await this.getSessionSupport( true );
    return data.body;
  }
  public signupDomainLookup(email: string, types: number[] = []): Promise<any> {
    return this.xhr(
      'POST',
      '/orgs/lookupbydomain',
      {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + encodeURIComponent( email + ':signupDomainLookup' ),
        'Content-Type': 'application/json'
      },
      { email, types },
      undefined,
      { use_serverdown_queue: false }
    );
  }
  public selfAddUser(email: string, org_id: number) {
    return this.xhr(
      'POST',
      '/user/selfadd',
      {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + encodeURIComponent( email + ':selfadd' ),
        'Content-Type': 'application/json'
      },
      { email, org_id },
      undefined,
      { use_serverdown_queue: false }
    );
  }
  public selfAddHelpEmail(user_info: any): Promise<any> {
    return this.xhr(
      'POST',
      '/user/selfadd/failure',
      {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + encodeURIComponent( user_info.email + ':selfaddfailure' ),
        'Content-Type': 'application/json'
      },
      user_info,
      undefined,
      { use_serverdown_queue: false }
    );
  }
  public selfAddOtherEmail(user_info: any): Promise<any> {
    return this.xhr(
      'POST',
      '/user/selfadd/other',
      {
        'Accept': 'application/json',
        'Authorization': 'Basic ' + encodeURIComponent( user_info.email + ':selfaddother' ),
        'Content-Type': 'application/json'
      },
      user_info,
      undefined,
      { use_serverdown_queue: false }
    )
  }

  public async reinviteUser(user_id, org_id) {
    let endpoint = `/org/${org_id}/user/${user_id}/reinvite`;
    let data = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {})
    return data.body;
  }

  public async reactivateUser(user_id, org_id) {
    let endpoint = `/org/${org_id}/user/${user_id}/reactivate`;
    let data = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {})
    return data.body;
  }

  public async inactivateUser(user_id, org_id) {
    let endpoint = `/org/${org_id}/user/${user_id}/inactivate`
    let data = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
    return data.body;
  }

  public async deleteUser(id, obj) {
    let endpoint = '/user/' + id;
    let data: any = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  // ***** DO NOT USE IN USER INTERFACE - FOR TESTING PURPOSES ONLY *****
  public async forceDeleteUser(id) {
    let endpoint = '/user/forcedelete/' + id;
    let data: any = await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async updatePassword(password) {
    let obj = { password: password };
    let endpoint = '/session/password';
    let data: any = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getContacts(sfAccount = null) {
    let accountFilter = sfAccount ? '/' + sfAccount : '';
    let endpoint = '/salesforce/contacts' + accountFilter;
    let data: any = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getContactsNotInDb(sfAccount = null) {
    let accountFilter = sfAccount ? '/' + sfAccount : '';
    let endpoint = '/salesforce/contacts/notimported' + accountFilter;
    let data: any = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async importContacts(obj) {
    let endpoint = '/salesforce/contacts/import';
    let data: any = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public syncContacts( obj:any, xhr_helper:xhrHelper = { method: 'POST', status_check: false, default_object: null, path_to_return: 'body' } ) {
    /* obj is of the form:
    {
      "sync":[ {"salesforce_id": "0014100000Br1HBAAZ", "id": 50}, ], // or "sync": "ALL",
      "forcePullFromSf":true                                         // or "forcePushToSf":true
    }
    */
    return this.xhrAuth('POST', '/sync/contacts', { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    //return this.xhrAuth(xhr_helper, '/sync/contacts', { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async addContact(obj) {
    let endpoint = '/salesforce/contact';
    let data: any = await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }


  ////////////////////////////////////////////////////////////////////////////////
  //MEMBERSHIPS

  public async getOrgMembershipLevels(org_id: number) {
    let endpoint = `/org/${org_id}/membershipLevels`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async addOrgMembers(org_id: number, level_id: number, obj: Object) {
    let endpoint = `/org/${org_id}/level/${level_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async getOrgMembers(org_id: number, paramsObj: Object = {}, member_org_id = undefined) {
    let endpoint = `/org/${org_id}/members`;
    if(member_org_id) {
      endpoint += `/${member_org_id}`
    }
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async deleteOrgMembers(org_id: number, level_id: number, member_id: number) {
    let endpoint = `/org/${org_id}/level/${level_id}/member/${member_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async getNoAviaCoreHs() {
    let endpoint = `/org/hs/noAviaCore`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }

  public async getMemberTypes() {
    let endpoint = `/memberTypes`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }

  public async upsertOrgMemberType(org_id: number, type_id: number) {
    let endpoint = `/org/${org_id}/memberTypes/${type_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async setOrgsMemberTypes(obj: { member_ids: number[], type_ids: number[] }) {
    let endpoint = `/orgsMemberTypes`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async deleteOrgMemberType(org_id:number, type_id: number) {
    let endpoint = `/org/${org_id}/memberTypes/${type_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async getMemberFocus() {
    let endpoint = `/memberFocus`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }

  public async setMemberFocusTopics(focus_id: number, obj: Object) {
    let endpoint = `/memberFocus/${focus_id}/topics`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async setOrgMemberFocus(org_id: number, obj: Object) {
    let endpoint = `/org/${org_id}/memberFocus`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async setOrgsMemberFocus(obj: { member_ids: number[], focus_ids: number[] }) {
    let endpoint = `/orgsMemberFocus`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async addMemberFocus(obj) {
    let endpoint = `/memberFocus`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async updateMemberFocus(id: number, obj) {
    let endpoint = `/memberFocus/${id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async deleteMemberFocus(id: number) {
    let endpoint = `/memberFocus/${id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  //////////////////////////////////////////////////////////////////////////////
  // Messages Data
  public async getUserDiscussions(comment_group_id) {
    let endpoint = `/messages`;
    if(comment_group_id) endpoint += `/${comment_group_id}`
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async createDiscussion(obj) {
    let endpoint = `/messages`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj).then((data) => data.body);
  }

  public async updateDiscussion(group_id: number, obj) {
    let endpoint = `/messages/${group_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj).then((data) => data.body);
  }

  public async addDiscussionParticipant(group_id: number, obj) {
    let endpoint = `/messages/${group_id}/participants`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj).then((data) => data.body);
  }

  public async deleteDiscussionParticipant(group_id: number, user_id: number) {
    let endpoint = `/messages/${group_id}/participants/${user_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}).then((data) => data.body);
  }

  public async createMessage(group_id: number, obj) {
    let endpoint = `/messages/${group_id}/posts`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj).then((data) => data.body);
  }

  public async editMessage(group_id: number, comment_id: number, obj) {
    let endpoint = `/messages/${group_id}/posts/${comment_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj).then((data) => data.body);
  }

  public async deleteMessage(group_id: number, comment_id: number) {
    let endpoint = `/messages/${group_id}/posts/${comment_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}).then((data) => data.body);
  }

  public async getMessages(group_id: number) {
    let endpoint = `/messages/${group_id}/posts`
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async blastMessage(obj) {
    let endpoint = `/messages/blast`
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  //////////////////////////////////////////////////////////////////////////////////
  // BANNERS
  public async getBanner(location_id: number) {
    let endpoint = `/banner/${location_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'})
  }


  //////////////////////////////////////////////////////////////////////////////////
  // FEED
  public async createPost(obj) {
    let endpoint = `/post`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async editPost(id, obj) {
    let endpoint = `/post/${id}/edit`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async deletePost(id) {
    let endpoint = `/post/${id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'})
  }
  public async getPosts(obj) {
    let endpoint = `/post/find`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async getPostsRecommendations(obj) {
    let endpoint = `/post/find/recommendations`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async getPostSupport() {
    let endpoint = `/post/support`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, {})
  }
  public async createPostReaction(id, obj) {
    let endpoint = `/post/${id}/reaction`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async removePostReaction(id, comment_id) {
    let endpoint = `/post/${comment_id}/reaction/${id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'})
  }
  public async followPost(id, obj) {
    let endpoint = `/post/${id}/follow`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async unfollowPost(id, obj) {
    let endpoint = `/post/${id}/follow`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }

  public async mutePost(id) {
    let endpoint = `/post/${id}/mute`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'})
  }
  public async unmutePost(id) {
    let endpoint = `/post/${id}/mute`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'})
  }

  public async pinPost(id, obj) {
    let endpoint = `/post/${id}/pin`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }
  public async unpinPost(id, obj) {
    let endpoint = `/post/${id}/unpin`;
    return await this.xhrAuth('post', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj)
  }

  public async mediaPreview(obj) {
    let endpoint = '/post/media';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => {
      return data;
    });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Org Data
  public async getAllOrgsWithSalesforceId() {
    let endpoint = '/orgs/withsalesforceids';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public async getOrgAviaContacts(orgId: number) {
    return await this.xhrAuth(
      'GET',
      '/orgs/aviacontacts/' + orgId,
      { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      {}
    ).then((data) => data.body);
  }
  public async getOrgById(id) {
    let endpoint = '/orgs/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body[0]); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public async getOrgActivities(id, paramsObj: Object = {}) {
    let endpoint = `/org/${id}/activities`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getOrgChannels(id) {
    let endpoint = `/org/${id}/channels`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public searchOrgs(paramsObj): Observable<any[]> {
    if (paramsObj.term) {
      paramsObj.filter = paramsObj.term;
      delete paramsObj.term;
    }
    return from(this.getOrgs(paramsObj));
  }
  public async getOrgs(paramsObj: Object = {}) {
    if( paramsObj['count'] && (paramsObj['count'] instanceof Function) ) {
      // HACK: Sometimes, during init, 'paramsObj.count' is passed in as a function by Avia Search Service, so bail.
      return [];
    }
    let endpoint = '/orgs';
    endpoint += Common.buildQueryParams(paramsObj);
    let data = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
    return data.body;
  }

  public async getHealthcareSystems(paramsObj: Object = {}) {
    let endpoint = '/orgs/healthcaresystems';
    endpoint += Common.buildQueryParams(paramsObj);
    let data = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
    return data;
  }
  public async getParentableOrgs(myOrgId: number) {
    let endpoint = '/orgs/parentable?myOrg=' + myOrgId;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getPartners(params_obj: Object = {}, org_id:number = undefined) {
    let endpoint = `/orgs/partners${(org_id !== undefined) ? '/'+org_id : ''}`;
    endpoint += Common.buildQueryParams(params_obj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async addNewOrg(obj) {
    let endpoint = '/org';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => {
      if(data.status != 200) {
        console.error(data.body);
        this.notify('error', 'ERROR!', 'Organization not added.');
      }
      return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
    });
  }
  public async updateOrg(id, obj, paramsObj: Object = {}) {
    let endpoint = '/org/' + id;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => {
      if( data.body && data.body.updated_org === undefined ) { console.log( data.body ) };
      return data.body; // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
    });
  }

  public async updateHealthcareSystem(id, obj: Object) {
    let endpoint = `/orgs/healthcaresystem/${id}`;
    return await this.xhr('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updatePartner(id, obj: Object) {
    let endpoint = `/orgs/partner/${id}`;
    return await this.xhr('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async addOrgAviaContacts(orgId: number, body: Object[]) {
    return await this.xhrAuth(
      'POST',
      '/orgs/aviacontacts/' + orgId,
      { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      body,
    ).then((data) => data.body);
  }
  public async updateOrgAviaContactsPrimary(orgId: number, userId: number, isPrimary: boolean) {
    // endpoint is: /orgs/aviacontacts/:id?user=n&primary=<0|1>
    let query = '?user=' + userId + '&primary=' + (isPrimary ? 1 : 0);
    return await this.xhrAuth(
      'PATCH',
      '/orgs/aviacontacts/' + orgId + query,
      { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      {}
    ).then((data) => data.body);
  }
  public async deleteOrgAviaContact(orgId: number, userId: number = null) {
    // endpoint is: /orgs/aviacontacts/:id?user=n
    let query = userId !== null ? '?user=' + userId : '';
    return await this.xhrAuth(
      'DELETE',
      '/orgs/aviacontacts/' + orgId + query,
      { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      {},
    ).then((data) => data.body);
  }
  public async getAviaContacts(org_id: number){
    let endpoint = `/org/${org_id}/aviacontacts`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getOrgContacts(org_id: number){
    let endpoint = `/org/${org_id}/contacts`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getOrgOwners(org_id: number){
    let endpoint = `/org/${org_id}/owners`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setAviaContacts(org_id: number, users){
    let endpoint = `/org/${org_id}/aviacontacts`;
    //endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, users);
  }
  public async setOrgContacts(org_id: number, users){
    let endpoint = `/org/${org_id}/contacts`;
    //endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, users);
  }
  public async addOrgOwner(org_id: number, user_id: number) {
    let endpoint = `/org/${org_id}/owner/${user_id}`;
    //endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async deleteOrgOwner(org_id: number, user_id: number) {
    let endpoint = `/org/${org_id}/owner/${user_id}`;
    //endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async setOrgOwners(org_id: number, users) {
    let endpoint = `/org/${org_id}/owners`;
    //endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, users);
  }

  public async upsertPulseAdmin(org_id: number, pulse_id: number, admin, { status_check = true,
    default_object = undefined,
    path_to_return = 'body.result' }) {
    let endpoint = `/pulse/${pulse_id}/hs/${org_id}/admin`;
    return await this.xhrAuth({ method: 'POST', status_check, default_object, path_to_return }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, admin);
  }
  public async getOrgApprovedDomains(org_id: number){
    let endpoint = `/org/${org_id}/domains`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setOrgApprovedDomains(org_id: number, domains: string[]){
    let endpoint = `/org/${org_id}/domains`;
    //endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, domains);
  }
  // competition
  public async getOrgExcludedOrgs(org_id: number){
    let endpoint = `/org/${org_id}/excludedorgs`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setOrgExcludedOrgs(org_id: number, excludedorgs: number[]){
    let endpoint = `/org/${org_id}/excludedorgs`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, excludedorgs);
  }
  public async getOrgExcludedUsers(org_id: number){
    let endpoint = `/org/${org_id}/excludedusers`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setOrgExcludedUsers(org_id: number, excludedusers: number[]){
    let endpoint = `/org/${org_id}/excludedusers`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, excludedusers);
  }

  public async getPrioritiesPeek( org_id ){
    let endpoint = `/org/${org_id}/priorities/peek`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoriesPeek( org_id ){
    let endpoint = `/org/${org_id}/inventories/peek`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public upsertOrgRevenue(org_id: number, rev_id: number|string, year: number, amount: number) {
    // POST /org/:id/revenue/:rev_id?
    return this.xhrAuth('POST', `/org/${org_id}/revenue/${rev_id}`, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {year, amount});
  };

  public deleteOrgRevenue(org_id, rev_id) {
    // DELETE /org/:id/revenue/:rev_id
    return this.xhrAuth('DELETE', `/org/${org_id}/revenue/${rev_id}`, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  };


  ////////////////////////////////////////////////////////////////////////////////
  //SALESFORCE
  public async getSfHealthcareSystems() {
    let endpoint = '/salesforce/healthcaresystems';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body);
  }
  public async getSfHealthcareSystemsNotInDb() {
    let endpoint = '/salesforce/healthcaresystems/notimported';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body);
  }
  public async importHealthcareSystems(obj) {
    let endpoint = '/salesforce/accounts/import';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public syncHealthcareSystems( obj:any, xhr_helper:xhrHelper = { method: 'POST', status_check: false, default_object: null, path_to_return: 'body' } ) {
    /* obj is of the form:
    {
      "sync":[ {"salesforce_id": "0014100000Br1HBAAZ", "id": 50}, ], // or "sync": "ALL",
      "forcePullFromSf":true                                         // or "forcePushToSf":true
    }
    */
    return this.xhrAuth('POST', '/sync/accounts', { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
    //return this.xhrAuth(xhr_helper, '/sync/accounts', { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // SOLUTION COMPANIES
  public async getSolutionCompanies( paramsObj: Object = {} ) {
    if( paramsObj['count'] && (paramsObj['count'] instanceof Function) ) {
      // HACK: Sometimes, during init, 'paramsObj.count' is passed in as a function by Avia Search Service, so bail.
      return [];
    }
    let endpoint = '/orgs/solutioncompanies';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(response => response.body);// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public async getSolCoById(id) {
    let endpoint = '/orgs/solutioncompanies/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }

  public async getSolcoCountsReport(paramsObj: Object = {}) {
    let endpoint = '/solco/reports/counts';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getCapitalReport(paramsObj: Object = {}) {
    let endpoint = '/solco/reports/capital';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getBasicStatsReport(paramsObj: Object = {}) {
    let endpoint = '/solco/reports/basicStats';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getUsageReport(paramsObj: Object = {}) {
    let endpoint = '/solco/reports/usage';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getTotalsReport(paramsObj: Object = {}) {
    let endpoint = '/solco/reports/totals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getGraph(paramsObj: Object = {}) {
    let endpoint = '/solco/reports/graph';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async updateSolCo(id, obj) {
    let endpoint = this.np + "/solco/" + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async createSolCo(obj) {
    let endpoint = '/solco';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => {
      if(data.status != 200) {
        console.error(data.body);// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
        this.notify('error', 'ERROR!', 'Company not added.');
      }
      return data.body;// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
    });
  }

  public async getSolCoSolutions(id){
    let endpoint = '/solco/' + id + '/solutions';
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getSolCoMetrics(id, paramsObj: Object ={}){
    let endpoint = '/solco/' + id + '/metrics';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async addSolcoMetric(id, obj){
    let endpoint = '/solco/' + id + '/metric';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updateSolcoMetric(id, metric_id, obj){
    let endpoint = '/solco/' + id + '/metric/' + metric_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteSolcoMetric(id, metric_id, obj){
    let endpoint = '/solco/' + id + '/metric/' + metric_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async setSolCoMetrics(id, obj){
    let endpoint = '/solco/' + id + '/metrics';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getSolCoCapabilities(id){
    let endpoint = '/solco/' + id + '/capabilities';
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }


  public async setSolCoCapabilities(id, obj){
    let endpoint = '/solco/' + id + '/capabilities';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async setSolCoSolutions(id, obj){
    let endpoint = '/solco/' + id + '/solutions';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getSolcoFiles(id) {
    let endpoint = '/solco/' + id + '/files';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getSolcoAdoptions(id) {
    let endpoint = '/solco/' + id + '/adoptions';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // PRODUCTS

  /* --- create --- */
  // WARNING! If you call this function anywhere but add-edit-product-modal, make sure you bring along the info-request check!
  public async createProduct(solco_id, obj) {
    let endpoint = `/solco/${solco_id}/product`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async createProductBadge(obj) {
    let endpoint = `/solco/product/badge`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  // public async addProductOrg(solco_id, prod_id, obj) {
  //   let endpoint = `/solco/${solco_id}/product/${prod_id}/org`;
  //   return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  // }
  public async addProductUser(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/user`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  /* --- read --- */
  public async getProductsBySolco(solco_id, paramsObj: Object = {}) {
    let endpoint = `/solco/${solco_id}/products`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProduct(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProductBadgeAutoComplete() {
    let endpoint = `/solco/product/badge/autocomplete`
    return await this.xhrAuth('GET', endpoint, {'Accept': 'application/json'});
  }
  public async getProductFiles(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/files`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProductAdoptions(id) {
    let endpoint = '/product/' + id + '/adoptions';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  // jaron
  public async getProductNetworkOrgs(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/network/orgs`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProductNetworkUsers(solco_id, prod_id, paramsObj?: Object) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/network/users`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProductMetrics(solco_id, prod_id, paramsObj: Object = {}) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/metrics`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProductSolutions(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/solutions`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getProductSolutionRecs(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/rec/solutions`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  /* --- update --- */
  public async updateProduct(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}`;
    return await this.xhrAuth('POST', endpoint,  { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  // public async setProductFiles(solco_id, prod_id, obj) {
  //   let endpoint = `/solco/${solco_id}/product/${prod_id}/files`;
  //   return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  // }
  // public async updateProductOrg(solco_id, prod_id, org_id, obj) {
  //   let endpoint = `/solco/${solco_id}/product/${prod_id}/org/${org_id}`;
  //   return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  // }
  public async updateProductUser(solco_id, prod_id, user_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/user/${user_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json','Content-Type': 'application/json' }, obj);
  }
  public async updateProductBadge(bid, obj) {
    let endpoint = `/solco/product/badge/${bid}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateBadgeAviaVetted(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/badge/aviavetted`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateBadgeHighImpact(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/badge/highimpact`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateBadgeMarketVal(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/badge/marketval`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async addProductMetric(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/metric`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }
  public async deleteProductMetric(solco_id, prod_id, metric_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/metric/${metric_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }
  // this code stays due to metrics multi-select feature
  public async setProductMetrics(solco_id, prod_id, obj){
    let endpoint = `/solco/${solco_id}/product/${prod_id}/metrics`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }
  public async updateProductMetric(solco_id, prod_id, metric_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/metric/${metric_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setProductSolutions(solco_id, prod_id, obj){
    let endpoint = `/solco/${solco_id}/product/${prod_id}/solutions`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }
  public async setProductSecurity(solco_id, prod_id, obj) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/security`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  /* --- delete --- */
  public async deleteProduct(solco_id, prod_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json'});
  }
  public async deleteProductBadge(bid) {
    let endpoint = `/solco/product/badge/${bid}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  // public async deleteProductOrg(solco_id, prod_id, org_id) {
  //   let endpoint = `/solco/${solco_id}/product/${prod_id}/org/${org_id}`;
  //   return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json'});
  // }
  public async deleteProductUser(solco_id, prod_id, user_id) {
    let endpoint = `/solco/${solco_id}/product/${prod_id}/user/${user_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json'});
  }

  ////////////////////////////////////////////////////////////////////////////////
  // CRUNCHBASE
  public async getCrunchbase(paramsObj: Object = {}) {
    let endpoint = '/crunchbase';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getCrunchbaseInvesments(paramsObj: Object = {}, cb_id?) {
    let endpoint = `/crunchbase/${cb_id}/investments`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getCrunchbaseInvestors(paramsObj: Object = {}, cb_id?) {
    let endpoint = `/crunchbase/${cb_id}/investors`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getCrunchbaseAcquisitions(paramsObj: Object = {}, cb_id?) {
    let endpoint = `/crunchbase/${cb_id}/acquisitions`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getCrunchbaseAcquirers(paramsObj: Object = {}, cb_id?) {
    let endpoint = `/crunchbase/${cb_id}/acquirers`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // ROLE DATA
  public async getAllRoles() {
    let endpoint = '/roles';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body);// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public async getRoleById(id) {
    let endpoint = '/role/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body);// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public async updateRole(endpoint, obj) {
    endpoint = '/role/' + endpoint;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }
  public async addNewRole(obj) {
    let endpoint = '/role';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj).then(data => data.body); // TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  ////////////////////////////////////////////////////////////////////////////////
  // NETWORK PROFILES
  np = '/np';
  // User
  public async getUserDemographics(id) {
    let endpoint = this.np + '/user/demographics/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async postUserDemographics(id, obj) {
    let endpoint = this.np + '/user/demographics/' + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async getUserContacts(id) {
    let endpoint = this.np + '/user/contacts/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async postUserContacts(id, obj) {
    let endpoint = this.np + '/user/contacts/' + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async getUserInterests(id) {
    let endpoint = this.np + '/interests/user/' + id + '/cards';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setUserInterestedCards(id, obj) {
    let endpoint = this.np + '/interests/user/' + id + '/cards';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async getUserInterestsOrgs(id) {
    let endpoint = this.np + '/interests/user/' + id + '/orgs';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async upsertUserInterestsOrg(user_id, org_id) {
    let endpoint = `${this.np}/interests/user/${user_id}/org/${org_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getSolcoNetworkUsers(id, paramsObj?: Object) {
    let endpoint = '/solco/' + id + '/network/users';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getSolcoNetworkOrgs(id, paramsObj?: Object) {
    let endpoint = '/solco/' + id + '/network/orgs';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async deleteUserInterestsOrg(user_id, org_id) {
    let endpoint = `${this.np}/interests/user/${user_id}/org/${org_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }
  public async getUserInterestsBookmarks(id) {
    let endpoint = this.np + '/interests/user/' + id + '/bookmarks';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async getUserInterestsLikes(id) {
    let endpoint = this.np + '/interests/user/' + id + '/likes';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async upsertUserInterestsProduct(solco_id, prod_id, user_id) {
    let endpoint = `/np/interests/user/${user_id}/solco/${solco_id}/product/${prod_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' } );
  }
  public async getUserInterestsProducts(user_id) {
    let endpoint = `/np/interests/user/${user_id}/products`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async deleteUsersInterestsProduct(solco_id, prod_id, user_id) {
    let endpoint = `/np/interests/user/${user_id}/solco/${solco_id}/product/${prod_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json'});
  }
  public async getCommonUserInterests(paramsObj: Object = {}) {
    let endpoint = '/user/interests/shared';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getTopFollowedTopics(paramsObj: Object = {}) {
    let endpoint = this.np + '/interests/topics';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getUserTopics(id) {
    let endpoint = '/user/' + id + '/topics';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getUserCompanies(id) {
    let endpoint = '/user/' + id + '/companies';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getUserQuicklinks(paramsObj: Object = {}) {
    let endpoint = '/user/quicklinks';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getUserPinnedLocations(paramsObj: Object = {}) {
    let endpoint = '/user/pinned_locations';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async addUserPinnedLocations(obj) {
    let endpoint = '/user/pinned_locations';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteUserPinnedLocations(obj) {
    let endpoint = '/user/pinned_locations';
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updateOrderUserPinnedLocations(obj) {
    let endpoint = '/user/pinned_locations_order';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }


  // Org
  public async getOrgDemographics(id) {
    let endpoint = this.np + '/org/demographics/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async postOrgDemographics(id, obj) {
    let endpoint = this.np + '/org/demographics/' + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async getOrgInterests(id) {
    let endpoint = this.np + '/interests/org/' + id + '/cards';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setOrgInterestedCards(id, obj) {
    let endpoint = this.np + '/interests/org/' + id + '/cards';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  ////////////////////////////////////////////////////////////////////////////////
  //USER CONNECTIONS
  // public async getUserConnections(id) {
  //   let endpoint = '/user/' + id + '/connections';
  //   return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  // }
  public async manageUserConnection( action: number , target_user: number, obj?: Object ) {
    let endpoint = '/user/' + target_user;
    let result;

    switch( action ){
      case E_ConnectionManagerActions.CONNECT:
        result = await this.xhrAuth('POST', endpoint+'/connect', { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
      break;
      case E_ConnectionManagerActions.DISCONNECT:
        result = await this.xhrAuth('POST', endpoint+'/disconnect', { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
      break;
      case E_ConnectionManagerActions.BLOCK:
        result = await this.xhrAuth('POST', endpoint+'/block', { 'Accept': 'application/json', 'Content-Type': 'application/json' });
      break;
    }

    if( result !== undefined ){
      if( result.statusCode == 400 ) {
        // display error
        this.notify('error', 'Sorry...', result.body.message);
      } else {
        if( result.body.response == 'blocked' ) {
          // display blocked error
          //this.notify('warning', 'Sorry...', result.body.message);
        } else {
          // display message
          //this.notify('success', 'Success!', result.body.message, {showConfirmButton: true, confirmButtonText: 'Okay', timer: false});
        }
      }
      return {response: result.body.response};
    }
    return {error: 'No Action Specified!'};
  }

  public async getUserAcceptedConnections(id) {
    let endpoint = '/user/' + id + '/connections/accepted';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async getUserPendingConnections(id) {
    let endpoint = '/user/' + id + '/connections/pending';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async getUserIgnoredConnections(id) {
    let endpoint = '/user/' + id + '/connections/ignored';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  ////////////////////////////////////////////////////////////////////////////////
  // PULSE
  readonly pulse = '/pulse';

  // - - - CREATE - - -
  public async addPulseTopicScore(hs_id: number, pulse_id: number, topic_id: any, data) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/question/${topic_id}/score`;
    return await this.xhrAuth( { method: 'POST', status_check: true, path_to_return: 'body.result' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, data);
  }

  public async addPulseParticipant(hs_id: number, pulse_id: number, u_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/participants/${u_id}`;
    return await this.xhrAuth({ method: 'POST', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async addPulseQuestionInvHistory(hs_id: number, topic_id: number, data) {
    let endpoint = `/pulse/hs/${hs_id}/question/${topic_id}/history`;
    return await this.xhrAuth( { method: 'POST', status_check: true, path_to_return: 'body.res' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, data);
  }

  public async addPulseQuestionInvHistoryBulk(hs_id: number, data) {
    let endpoint = `/pulse/hs/${hs_id}/history/bulk`;
    return await this.xhrAuth({ method: 'POST', status_check: true }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, data);
  }

  public async createPulseActivate(pulse_id: number, hs_id: number, step_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/step/${step_id}`;
    return await this.xhrAuth({ method: 'POST', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async createAdminEmail(pulse_id: number, hs_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/email/admin`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }
  // - - - READ - - -

  public async getOrgPulses(hs_id: number) {
    let endpoint = `/org/${hs_id}/pulses`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getOrgPulsesAccount(hs_id: number) {
    let endpoint = `/org/${hs_id}/pulses/account`;
    return await this.xhrAuth({ method: 'GET', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json' });
  }

  public async getOrgPulseModules(hs_id: number, pulse_id: number, paramsObj = {}) {
    let endpoint = `/org/${hs_id}/pulse/${pulse_id}/modules`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth({ method: 'GET', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json' });
  }
  public async getPulseLink(pulse_id: number) {
    let endpoint = `/pulse/link/${pulse_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getPulseNetworkAdoptionsHeatmap(hs_id: number, pulse_id: number) {
    const endpoint  = `/pulse/${pulse_id}/hs/${hs_id}/heatmap/network-adoptions`;
    return await this.xhrAuth({ method: 'GET', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json' });
  }
  public async getPulseNetworkMaturityHeatmap(hs_id: number, pulse_id: number) {
    const endpoint  = `/pulse/${pulse_id}/hs/${hs_id}/heatmap/network-maturity`;
    return await this.xhrAuth({ method: 'GET', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json' });
  }
  public async getPulseTopicHeatmap(hs_id: number, pulse_id: number, topic_id: number) {
    const endpoint  = `/pulse/${pulse_id}/hs/${hs_id}/question/${topic_id}/heatmap`;
    return await this.xhrAuth({ method: 'GET', status_check: true, path_to_return: 'body', default_object: null }, endpoint, { 'Accept': 'application/json' });
  }

  public async getPulseTableOfContents(pulse_id: number, id: number, paramsObj = {}) {
    let endpoint = `${this.pulse}/${pulse_id}/hs/${id}/contents`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPulseAdmin(hs_id: number, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/admin`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPulseSupport(hs_id) {
    let endpoint = `/pulse/hs/${hs_id}/support`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPulseActivated(hs_id: number, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/step`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'});
  }

  public async getPulseScoreForModules(hs_id: number, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/modules`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getPulseQuestion(hs_id: number, topic_id: any, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/question/${topic_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getPulseTopicTrends(hs_id: number, topic_id: any, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/question/${topic_id}/trends`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getPulseModuleTrends(hs_id: number, module_id: any, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/module/${module_id}/trends`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getGeneralPulseProgress(pulse_id: number, hs_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/progress/general`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseModuleOwners(pulse_id: number, hs_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/owners`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseModuleGroup(pulse_id: number, hs_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/group`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public getPulseModules() {
    let endpoint = '/pulse/modules';
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseTopicsCompanies(hs_id: number) {
    let endpoint = `/pulse/hs/${hs_id}/question/companies`;

    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseParticipants(hs_id: number, pulse_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/participants`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getPulseProgressForOwners(pulse_id: number, hs_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/progress/owners`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseProgressForModules(pulse_id: number, hs_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/progress/modules`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // This is a call in the digital pulse to retrieve all companies related to this topic from the inventory controller
  public async getPulseQuestionInventory(hs_id: number, topic_id: number) {
    let endpoint = `/hs/${hs_id}/inventory?topic=${topic_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseQuestionInvHistory(hs_id: number, topic_id: number) {
    let endpoint = `/pulse/hs/${hs_id}/question/${topic_id}/inventory/history`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getPulseQuestionScoreHistory(hs_id: number, topic_id: number) {
    let endpoint = `/pulse/hs/${hs_id}/question/${topic_id}/score/history`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // - - - UPDATE / UPSERT - - -
  public async upsertPulseModule(pulse_id: number, id: number, module_list, parms = {}) {
    let endpoint = `/pulse/${pulse_id}/hs/${id}/modules${Common.buildQueryParams(parms)}`;
    return await this.xhrAuth({ method: 'POST', status_check: true, path_to_return: 'body.result' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, module_list);
  }

  // public async upsertOnePulseTopicOwner(hs_id: number, topic_id: number, pulse_admin_id: number) {
  //   let endpoint = `/pulse/hs/${hs_id}/owner/${pulse_admin_id}`;
  //   return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {topic_id});
  // }

  public async upsertPulseTopicOwnerBulk(hs_id: number, pulse_id: number, pulse_admin_id: number, module_list) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/owner/${pulse_admin_id}/bulk`;
    return await this.xhrAuth({ method: 'POST', status_check: true, path_to_return: 'body' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, module_list);
  }

  public async updatePulseTopics(hs_id: number, pulse_id: number, topic_id: number, obj: object) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/question/${topic_id}`;
    return await this.xhrAuth({ method: 'POST', status_check: true, path_to_return: 'body.result' }, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updatePulseActive(pulse_id: number, hs_id: number, step_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/step/${step_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  // - - - DELETE - - -
  public async deletePulseModule(pulse_id: number, id: number, m_id: any) {
    let endpoint = `/pulse/${pulse_id}/hs/${id}/module/${m_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async deletePulseTopicOwner(hs_id: number, topic_id: number, u_id: number) {
    let endpoint = `/pulse/hs/${hs_id}/owner/${u_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, { topic_id });
  }

  public async deletePulseParticipant(hs_id: number, pulse_id: number, u_id: number) {
    let endpoint = `/pulse/${pulse_id}/hs/${hs_id}/participants/${u_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // KM MAP
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  km = '/km';
  public async getKMcard(id: number) {
    let endpoint = this.km + '/card/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getKMcards(paramsObj: Object = {}) {
    let endpoint = this.km + '/cards';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getKMcardsTrending(type: number) {
    let endpoint = this.km + '/cards/trending/' + type;
    let result = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });

    if(result.status === 200) {
      return result["body"];
    }
    else {
      return [];
    }
  }
  public async getKMCardsPopular() {
    let endpoint = this.km + '/cards/popular'
    let result = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });

    if(result.status === 200) {
      return result["body"];
    }
    else {
      return [];
    }
  }

  public async getKMSuggested() {
    let endpoint = this.km + '/cards/suggested'
    let result = await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
    if(result.status === 200) {
      return result["body"];
    }
    else {
      return [];
    }
  }

  public searchKMCards(paramsObj: Object = {}): Observable<any> {
    if (paramsObj['type'] === 0) {
      delete paramsObj['type']
      paramsObj['search'] = paramsObj['search'] ? paramsObj['search'] : '*';
    }
    return from(this.getKMcards(paramsObj).then(data => data.body));
  }

  public async getKMKeywords(id) {
    let endpoint = this.km + '/card/' + id + '/keywords';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async setKeywords(id, arr) {
    // Must send the entire array, not just the new keyword.
    let endpoint = this.km + '/card/' + id + '/keywords';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, arr);
  }

  public async getKmLinksGraph(id, previous_card_id: number = null) {
    if (previous_card_id) {
      id += '?previous=' + previous_card_id;
    }
    let endpoint = this.km + '/links/card/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getKmRelations(id) {
    let endpoint = this.km + '/links/card/' + id + '/relations';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async updateKmRelations() {
    let endpoint = this.km + '/links/card/relations';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json' });
  }
  public async addKMcard(obj) {
    let endpoint = this.km + '/card';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateKMcard(id, obj) {
    let endpoint = this.km + '/card' + ((obj.interested === 1 || obj.interested === 0) ? '/interested/' : '/') + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteKMcard(id) {
    let endpoint = this.km + '/card/' + id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }
  public async getKMcompanies(id, paramsObj: Object = {}) {
    let endpoint = this.km + '/card/' + id + '/companies';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getKMactivities(id, paramsObj: Object = {}) {
    let endpoint = this.km + '/card/' + id + '/activities';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getKMmetrics(id, paramsObj: Object = {}) {
    let endpoint = this.km + '/card/' + id + '/metrics';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async addKMetric(id, obj){
    let endpoint = this.km + '/card/' + id + '/metric';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updateKMMetric(id, metric_id, obj){
    let endpoint = this.km + '/card/' + id + '/metric/' + metric_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteKMMetric(id, metric_id, obj){
    let endpoint = this.km + '/card/' + id + '/metric/' + metric_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }

  public async getKMcapabilities(id) {
    let endpoint = this.km + '/card/' + id + '/capabilities';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getKMsolutions(id) {
    let endpoint = this.km + '/card/' + id + '/solutions';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async setKMcompanies(id, obj) {
    let endpoint = this.km + '/card/' + id + '/companies';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setKMactivities(id, obj) {
    let endpoint = this.km + '/card/' + id + '/activities';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setKMmetrics(id, obj) {
    let endpoint = this.km + '/card/' + id + '/metrics';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setKMcapabilities(id, obj) {
    let endpoint = this.km + '/card/' + id + '/capabilities';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setKMsolutions(id, obj) {
    let endpoint = this.km + '/card/' + id + '/solutions';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getTopicsUsers(obj) {
    let endpoint = this.km + '/users'
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj)
  }
  public async getCardNetworkUsers(id, paramsObj: Object = {}) {
    let endpoint = this.km + '/card/' + id + '/network/users';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getCardNetworkOrgs(id, paramsObj: Object = {}) {
    let endpoint = this.km + '/card/' + id + '/network/orgs';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async upsertCardInterestUser(card_id, user_id) {
    let endpoint = `${this.km}/card/${card_id}/user/${user_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async removeCardInterestUser(card_id, user_id) {
    let endpoint = `${this.km}/card/${card_id}/user/${user_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getTopicSelector() {
    let endpoint = this.km + '/topic_selector';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }

  public async getKMstakeholders(id) {
    let endpoint = this.km + '/card/' + id + '/stakeholders';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async addKMstakeholders(id, obj) {
    let endpoint = this.km + '/card/' + id + '/stakeholders';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteKMstakeholders(id,s_id) {
    let endpoint = this.km + '/card/' + id + '/stakeholders/' + s_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async getKMprerequisites(id) {
    let endpoint = this.km + '/card/' + id + '/prerequisites';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async addKMprerequisites(id, p_id) {
    let endpoint = this.km + '/card/' + id + '/prerequisites/' + p_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json'}, p_id);
  }
  public async deleteKMprerequisites(id, p_id) {
    let endpoint = this.km + '/card/' + id + '/prerequisites/' + p_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async getKMVisualizationJSON(settings) {
    let endpoint = "/km/reports/topicvisualizer";
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, settings);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // COMMENTS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async getCommentsByGroupId(card_id, group_id, start = undefined, count = undefined, level = 0, resolved = undefined) {
    // LEVEL
    // 0 return no comments
    // 1 return top level comments
    // 2 return comments & replies

  // let endpoint: string = this.comment_groups + id + '?direction=1';
    let endpoint = `${this.km}/${card_id}/comments/group/${group_id}/?direction=1`;
    level === 2 ? endpoint += '&deep=true' :
      level === 1 ? endpoint += '&top_level=true' :
      endpoint += ''

    if (start) endpoint += '&start=' + start;
    if (count) endpoint += '&count=' + count;
    if (resolved) endpoint += '&resolved=' + resolved;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async addComment(obj, card_id, paramsObj) {
    let endpoint = `${this.km}/${card_id}/comment`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async getComment(comment_id, card_id) {
    let endpoint = `${this.km}/${card_id}/comment/${comment_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async updateComment(id, card_id, obj) {
    let endpoint = `${this.km}/${card_id}/comment/${id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteComment(id, card_id) {
    let endpoint = `${this.km}/${card_id}/comment/${id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // KM REPORTS AND ANALYTICS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async getKmReportCounts(paramsObj: Object = {}) {
    let endpoint = this.km + '/reports/counts';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getKmReportTotals(paramsObj: Object = {}) {
    let endpoint = this.km + '/reports/totals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getKmReportSummary(paramsObj: Object = {}) {
    let endpoint = this.km + '/reports/summary';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getKmReportBasicStats(paramsObj: Object = {}) {
    let endpoint = this.km + '/reports/stats';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getKmReportUsage(paramsObj: Object = {}) {
    let endpoint = this.km + '/reports/usage';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getKmReportGraphOne(paramsObj: Object = {}) {
    let endpoint = this.km + '/reports/graphOne';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONTENT
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  content = '/content';
  private async buildSearchContentPromise(paramsObj: Object = {}) {
    let endpoint = this.content;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async searchContentPromise(paramsObj: Object = {}) {
    return await this.buildSearchContentPromise(paramsObj);
  }

  public searchContent(paramsObj: Object = {}): Observable<any> {
    return from(this.buildSearchContentPromise(paramsObj).then(data => data.body));// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  public async getContentById(id, paramsObj: Object = {}) {
    let query_params = Common.buildQueryParams(paramsObj);
    let endpoint = this.content + `/` + id + `${query_params}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentCount(paramsObj) {
    let query_params = Common.buildQueryParams(paramsObj);
    let endpoint = `${this.content}/count${query_params}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }

  public async getContentCountByGroup(group_id) {
    let endpoint = `${this.content}/count/group/` + group_id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }

  public async getContentFromSource(type, id:number = undefined, options = {}) {
    let query_params = Common.buildQueryParams(options);
    let endpoint = `${this.content}/source/${type}/${id ? id : ''}${query_params}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async addContent(obj) {
    let endpoint = this.content;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async addContentLogo(obj) {
    let endpoint = `${this.content}/logo`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updateContent(id, obj) {
    let endpoint = this.content + ((obj.liked === 1 || obj.liked === 0) ? '/liked/' : ((obj.interested === 1 || obj.interested === 0) ? '/interested/' : '/')) + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async createChContentRecommendation(id:number, ch_id:number, obj:any ={}) {
    let endpoint = this.content + '/' + id + '/ch/' + ch_id + '/recommended';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async removeChContentRecommendation(id:number, ch_id:number, obj:any ={}) {
    let endpoint = this.content + '/' + id + '/ch/' + ch_id + '/recommended';
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async createKmContentRecommendation(id, card_id, obj ={}) {
    let endpoint = this.content + '/' + id + '/km/' + card_id + '/recommended';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async removeKmContentRecommendation(id:number, ch_id:number, obj:any ={}) {
    let endpoint = this.content + '/' + id + '/km/' + ch_id + '/recommended';
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteContent(id) {
    let endpoint = this.content + '/' + id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async setContentToGroup(content_id, group_id, obj = {}) {
    let endpoint = this.content + '/' + content_id + '/group/' + group_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async removeContentFromGroup(content_id, group_id) {
    let endpoint = this.content + '/' + content_id + '/group/' + group_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public setContentOrgType(content_id: number, obj: any): Promise<any> {
    let endpoint: string = this.content + '/' + content_id + '/orgType'
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async addContentMemberType(content_id, type_id) {
    let endpoint = this.content + '/' + content_id + '/memberType/' + type_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async setContentMemberType(content_id, obj) {
    let endpoint = this.content + '/' + content_id + '/memberType';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteContentMemberType(content_id, type_id) {
    let endpoint = this.content + '/' + content_id + '/memberType/' + type_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async setContentMemberFocus(content_id, obj) {
    let endpoint = this.content + '/' + content_id + '/memberFocus';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async addContentMemberFocus(content_id, focus_id) {
    let endpoint = this.content + '/' + content_id + '/memberFocus/' + focus_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async deleteContentMemberFocus(content_id, focus_id) {
    let endpoint = this.content + '/' + content_id + '/memberFocus/' + focus_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentTotals(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/totals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentReportByTopic(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/topic';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentReportByUser(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/user';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentReportBySource(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/source';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentReportByUsage(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/usage';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentReportGraph(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/graph';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getContentReportCounts(paramsObj: Object = {}) {
    let endpoint = this.content + '/reports/counts';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // DASHBOARDS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async getEmailAnalytics(paramsObj: Object = {}) {
    let endpoint = '/avia/emailanalytics';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async updateEmailAnalyticsRow(mandrill_id: number, id: number) {
    let endpoint = `/avia/emailanalytics/${id}/update`;
    endpoint += Common.buildQueryParams({ mandrill_id });
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async emailAnalyticsCron(paramsObj: Object = {}) {
    let endpoint = '/avia/emailanalytics/cron';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getDashboardCounts(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/counts';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportCount(paramsObj: Object = {}) {
    let endpoint = '/avia/report/count';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getUserActivityReport(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/userActivity';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getUserActivityFilterData(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/userFilter';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getOrgActivityReport(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/orgActivity';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportOrgsTotals(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/orgsTotals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getGraphOrgsTotals(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/graph/orgsTotals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportUserTotals(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/userTotals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getGraphUserTotals(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/graph/userTotals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportActiveUsersTotals(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/activeUsers';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportMonthlyActivity(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/monthly';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportWeeklyActivity(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/weekly';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportDailyActivity(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/daily';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActiveUsersGraph(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/users/graph';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getUserActiveMinutesGraph(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/minutes/graph';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getMinutesPerUserGraph(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/ratios/graph';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportAdoptionTable(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/adoptionTable';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getReportMamMauTotals(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/mamMau';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getFeedbackWordl(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/wordl';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getFeedbackReport(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/feedback';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getNpsReport(paramsObj: Object = {}) {
    let endpoint = '/avia/reports/nps';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  // TRACKERS REPORTS

  public async getDashboardOpportunitiesReport(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/opportunities';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getDashboardContractsReport(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/contracts';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getDashboardImplementationsReport(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/implementations';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  // CSV REPORTS

  public async getDashboardOverallCSV(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/overall/csv';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getDashboardOpportunitiesCSV(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/opportunities/csv';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getDashboardContractsCSV(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/contracts/csv';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getDashboardImplementationsCSV(paramsObj: Object = {}) {
    let endpoint = '/dashboard/reports/implementations/csv';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  // Email Digest

  public async upsertEmailDigest(id, obj) {
    let endpoint = '/emaildigest/' + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getEmailDigest(id) {
    let endpoint = '/emaildigest/' + id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async deleteEmailDigest(id) {
    let endpoint = '/emaildigest/' + id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async sendEmailDigest(id, obj) {
    let endpoint = '/emaildigest/' + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertEmailDigestContent(id, content_id, obj) {
    let endpoint = '/emaildigest/' + id + '/content/' + content_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteEmailDigestContent(id, content_id) {
    let endpoint = '/emaildigest/' + id + '/content/' + content_id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getEmailDigestHistory() {
    let endpoint = '/emaildigest/history';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async getCurrentEmailDigest() {
    let endpoint = '/emaildigest/current';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ACTIVITIES
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private activities = 'activities';

  // For simple ordered lists of activities, use getActivities()
  public async getActivities(paramsObj = {}) {
    let endpoint = `/${this.activities}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActivitiesChannels(obj) {
    let endpoint = `/${this.activities}/channels`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }
  public async getActivitiesHosts() {
    let endpoint = `/${this.activities}/hosts`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  private activity = '/activity';
  public async addActivity(obj) {
    let endpoint = this.activity;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async addActivityOrg(act_id, org_id, is_host = 0, opts = {}) {
    opts['is_host'] = is_host; //NOTE: this is only setup this way so as not to break legacy implementation
    let endpoint = `${this.activity}/${act_id}/org/${org_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async addActivityUser(act_id, user_id, is_owner = 0, opts = {}) {
    opts['is_owner'] = is_owner; //NOTE: this is only setup this way so as not to break legacy implementation
    let endpoint = `${this.activity}/${act_id}/user/${user_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async addActivityLink(act_id, obj, opts = {}) {
    let endpoint = `${this.activity}/${act_id}/link`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getGroupUserAccess(group_id) {
    let endpoint = `/group/${group_id}/user_access`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getGroupUserStats(group_id) {
    let endpoint = `/group/${group_id}/user_stats`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActivity(id) {
    let endpoint = `${this.activity}/${id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getActivityByChannelId(id) {
    let endpoint = `${this.activity}`;
    endpoint += Common.buildQueryParams({'ch_id': id});
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getActivityTopics(id, types, paramsObj = {}) {
    let endpoint = `${this.activity}/${id}/topics`;
    endpoint += Common.buildQueryParams(paramsObj);
    endpoint += (paramsObj['filter'] ? '&' : "?") + types.map( t => `types=${t}` ).join("&");
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getActivityLinks(id, paramsObj = {}) {
    let endpoint = `${this.activity}/${id}/links`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});
  }
  public async createActivityTopic(id, obj, types=[]) {
    let endpoint = `${this.activity}/${id}/topic`;
    endpoint += "?" + types.map( t => `types=${t}` ).join("&");
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteActivityTopic(id, obj, types=[]) {
    let endpoint = `${this.activity}/${id}/topic/${obj.id}`;
    endpoint += "?" + types.map( t => `types=${t}` ).join("&");
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getActivityUsers(id, opts={}) {
    let endpoint = `${this.activity}/${id}/users`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getActivityOrgs(id, opts={}) {
    let endpoint = `${this.activity}/${id}/orgs`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getActivityOrgHosts(id) {
    let endpoint = `${this.activity}/${id}/org_hosts`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getActivityChannels(id, opts={}) {
    let endpoint = `${this.activity}/${id}/channels`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async updateActivity(id, obj) {
    let endpoint = `${this.activity}/${id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async removeActivityOrg(act_id, org_id, opts={}) {
    let endpoint = `${this.activity}/${act_id}/org/${org_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async removeActivityUser(act_id, user_id, opts={}) {
    let endpoint = `${this.activity}/${act_id}/user/${user_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async deleteActivityLink(id, act_id, opts={}) {
    let endpoint = `${this.activity}/${id}/link/${act_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async setActivityUsers(id, obj, opts={}) {
    let endpoint = `${this.activity}/${id}/users`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setActivityOrgs(id, obj, opts={}) {
    let endpoint = `${this.activity}/${id}/orgs`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setActivityOrgHosts(id, obj) {
    let endpoint = `${this.activity}/${id}/org_host`;
    return await this.xhrAuth('PATCH', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public setActivityRelActivities(id: number, related: number[] = []) {
    let endpoint = `${this.activity}/${id}/related_activities`; // /activity/:id/related_activities
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {related});
  }
  public async deleteActivity(id) {
    let endpoint = `${this.activity}/${id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }
  public async getUserActivities(id) {
    return await this.xhrAuth('GET', `/user/${id}/activities`, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ANLYTICS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  public async getActTotals(paramsObj: Object = {}) {
    let endpoint = this.activity + '/reports/totals';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActReportBasicStats(paramsObj: Object = {}) {
    let endpoint = this.activity + '/reports/basicStats';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActReportMonthlyUsage(paramsObj: Object = {}) {
    let endpoint = this.activity + '/reports/usageMonth';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActReportTotalUsage(paramsObj: Object = {}) {
    let endpoint = this.activity + '/reports/usageTotal';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getActReportCounts(paramsObj: Object = {}) {
    let endpoint = this.activity + '/reports/counts';
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CHANNELS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async addChannel(act_id:number, obj:any) {
    let endpoint = `${this.activity}/${act_id}/channel`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async addChannelOrg(ch_id:number, org_id:number, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/org/${org_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async addChannelUser(ch_id:number, user_id:number, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/user/${user_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async addChannelUsers(ch_id:number, obj:any, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/import`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async addGroupOwnerChannelUsers(group_id:number, owner_ids:any) {
    let endpoint = `/group/${group_id}/ownerChannelUsers`
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, { owner_ids });
  }
  public async addChannelUserRequest(ch_id:number, user_id:number, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/user/${user_id}/request`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }
  public async updateChannelUser(ch_id:number, user_id:number, obj:any, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/user/${user_id}/settings`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);
  }
  public async getChannel(id:number) {
    let endpoint = `/channel/${id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getChannels(paramsObj:any = {}) {
    let endpoint = `/channels`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getChannelUsers(id:number, opts:any = {}) {
    let endpoint = `/channel/${id}/users`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getChannelOrgs(id:number, opts:any = {}) {
    let endpoint = `/channel/${id}/orgs`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getChannelTopics(id:number, types:any[] =[], paramsObj:any = {}) {
    let endpoint = `/channel/${id}/topics`
    endpoint += Common.buildQueryParams(paramsObj);
    endpoint += (paramsObj['filter'] ? '&' : "?") + types.map( t => `types=${t}` ).join("&");
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async createChannelTopic(id:number, topic:any, types:any[] = []) {
    let endpoint = `/channel/${id}/topic`;
    endpoint += "?" + types.map( t => `types=${t}` ).join("&");
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, topic);
  }
  public setChannelTopics(id:number, topic_ids:number[] = []) {
    return this.xhrAuth('POST', `/channel/${id}/topics`, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {topic_ids});
  }
  public async deleteChannelTopic(id:number, topic:any, types:any[] = []) {
    let endpoint = `/channel/${id}/topic/${topic.id}`
    endpoint += "?" + types.map( t => `types=${t}` ).join("&");
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getChannelCompanies(id:number) {
    let endpoint = `/channel/${id}/solcos`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async createChannelCompany(id:number, obj:any) {
    let endpoint = `/channel/${id}/solco`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public setChannelCompanies(id:number, solco_ids:number[] = []) {
    return this.xhrAuth('POST', `/channel/${id}/solcos`, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {solco_ids});
  }
  public async deleteChannelCompany(id:number, obj) {
    let endpoint = `/channel/${id}/solco/${obj.id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async addFileToChannel(id:number, orgId:number) {
    let endpoint = `/channel/${id}/orgs/${orgId}/files`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json' });
  }
  public async updateChannel(id:number, obj:any) {
    let endpoint = `/channel/${id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async setChannelOrgs(id:number, obj:any) {
    let endpoint = `/channel/${id}/orgs`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateChannelFiles(id:number, obj:any) {
    let endpoint = `/channel/${id}/files`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteChannel(id:number) {
    let endpoint = `/channel/${id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }
  public async removeChannelOrg(ch_id:number, org_id:number, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/org/${org_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async removeChannelUser(ch_id:number, user_id:number, opts:any = {}) {
    let endpoint = `/channel/${ch_id}/user/${user_id}`;
    endpoint += Common.buildQueryParams(opts);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PRIORITIES
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private priorities = `priorities`;
  private priority = `priority`;

  public async createPriority( org_id, obj ) {
    let endpoint = `/org/${org_id}/${this.priority}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getPrioritySupport( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorities( org_id, is_archived = 0 ){
    let endpoint = `/org/${org_id}/${this.priorities}?is_archived=${is_archived}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPrioritiesNetwork( org_id ){
    let endpoint = `/org/${org_id}/${this.priorities}/network`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriority( org_id, pri_id ){
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityPeek( org_id, pri_id ) {
    // peek
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/peek`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }


  public async getPriorityUsers( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/users`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityMetrics( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/metrics`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityTopics( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/topics`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityActivities( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/activities`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getPriorityInventories( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/inventories`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async setPriorityGroups( org_id, pri_id, obj ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/groups`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async setPriorityTopics( org_id, pri_id, obj ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/topics`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updatePriority( org_id, pri_id, obj ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertPriorityUser( org_id, pri_id, user_id, obj ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/user/${user_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertPriorityMetric( org_id, pri_id, metric_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/metric/${metric_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }

  public async upsertPriorityActivity( org_id, pri_id, activity_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/activity/${activity_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }

  public async upsertPriorityInventory( org_id, pri_id, inv_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/inventory/${inv_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, {});
  }

  public async upsertPriorityGroup( org_id, group_id = undefined, obj ) {
    let endpoint = `/org/${org_id}/group`;
    if(group_id) endpoint += `/${group_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deletePriority( org_id, pri_id ) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth(`DELETE`, endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async deletePriorityUser(org_id, pri_id, user_id) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/user/${user_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deletePriorityMetric(org_id, pri_id, metric_id) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/metric/${metric_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deletePriorityActivity(org_id, pri_id, activity_id) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/activity/${activity_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deletePriorityInventory(org_id, pri_id, inv_id) {
    let endpoint = `/org/${org_id}/${this.priority}/${pri_id}/inventory/${inv_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deletePriorityGroup(org_id, group_id) {
    let endpoint = `/org/${org_id}/group/${group_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // INVENTORY
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private inventory = `inventory`;

  /* - - - - - - - create - - - - - - - */

  public async createInventoryItem( hs_id, obj ){
    let endpoint = `/hs/${hs_id}/${this.inventory}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async createInvItemProducts( inv_id, obj){
    let endpoint = `/${this.inventory}/${inv_id}/products`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }


  /* - - - - - - - read - - - - - - - */

  public async getInventory( params = {} ){
    let endpoint = `/${this.inventory}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryDashboardSupport( params = {} ) {
    let endpoint = `/${this.inventory}/dashboard/support`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getHsInventorySupport(hs_id, params = {}) {
    let endpoint = `/hs/${hs_id}/${this.inventory}/support`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getHsAgenda(hs_id, params = {}) {
    const qs = querystring.stringify(params);
    const  endpoint = `/hs/${hs_id}/agenda?${qs}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getHsInventory(hs_id, params = {}) {
    const qs = querystring.stringify(params);
    // use URLSearchParams to write parms see https://nodejs.org/docs/latest/api/url.html#url_constructor_new_urlsearchparams
    const  endpoint = `/hs/${hs_id}/${this.inventory}?${qs}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getHsInventoryNetwork(hs_id, params = {}) {
    let endpoint = `/hs/${hs_id}/${this.inventory}/network`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getHsTracker(hs_id, params = {}) {
    const qs = querystring.stringify(params);
    const  endpoint = `/hs/${hs_id}/tracker?${qs}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getOneHsTracker(hs_id, i_id, params = {}){
    let endpoint = `/hs/${hs_id}/tracker/${i_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' })
  }
  public async getInventoryItem( inv_id ) {
    // detailed
    let endpoint = `/${this.inventory}/${inv_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemPeek( inv_id ) {
    // peek
    let endpoint = `/${this.inventory}/${inv_id}/peek`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemUsers( inv_id, params = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/users`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemMetrics( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/metrics`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemActivities( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/activities`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemCapabilities( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/capabilities`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getInventoryItemProducts( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/products`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async getInventoryItemSolutions( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/solutions`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemPriorities( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/priorities`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventoryItemProgress( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}/progress`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventorySupport(){
    let endpoint = `/${this.inventory}/support`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInventorySubstatus(){
    let endpoint = `/inventory/substatus`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  /* - - - - - - - update - - - - - - - */
  public async createHsAgenda( hs_id, obj = {}, show_archive = false ){
    const qs = querystring.stringify({ show_archive });
    const  endpoint = `/hs/${hs_id}/agenda?${qs}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async createHsAgendaEmail( hs_id, obj = {}, parms = {} ){
    const qs = querystring.stringify(parms);
    const  endpoint = `/hs/${hs_id}/email/agenda?${qs}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async createHsTracker( hs_id, obj = {} ){
    const  endpoint = `/hs/${hs_id}/tracker`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateHsAgenda( hs_id, agenda_id, obj = {}, show_archive = false ){
    const qs = querystring.stringify({ show_archive });
    const  endpoint = `/hs/${hs_id}/agenda/${agenda_id}?${qs}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateHsTracker( hs_id, info_id, obj = {}, params = {}){
    let endpoint = `/hs/${hs_id}/tracker/${info_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async reorderHsAgenda( hs_id, objs = [], show_archive = false){
    const qs = querystring.stringify({ show_archive });
    const  endpoint = `/hs/${hs_id}/agenda/bulk?${qs}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, objs);
  }
  public async reorderHsTracker( hs_id, objs = []){
    let  endpoint = `/hs/${hs_id}/tracker/bulk`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, objs);
  }
  public async updateInvItem( inv_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertInvItemUser( inv_id, user_id, params = {}, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/user/${user_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertInvItemMetric( inv_id, metric_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/metric/${metric_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertInvItemActivity( inv_id, act_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/activity/${act_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertInvItemCapability( inv_id, cap_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/capability/${cap_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertInvItemProduct( inv_id, prod_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/product/${prod_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async upsertInvItemPriority( inv_id, pri_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/priority/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async upsertInvItemProgress( inv_id, progress_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/progress/${progress_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async upsertInvItemSolution( inv_id, pri_id, obj = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/solution/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }


  /* - - - - - - - delete - - - - - - - */

  public async deleteInvItem( inv_id ){
    let endpoint = `/${this.inventory}/${inv_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deleteInvItemUser( inv_id, user_id, params = {} ){
    let endpoint = `/${this.inventory}/${inv_id}/user/${user_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deleteInvItemMetric( inv_id, metric_id ){
    let endpoint = `/${this.inventory}/${inv_id}/metric/${metric_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }
  public async deleteInvItemCapability( inv_id, metric_id ){
    let endpoint = `/${this.inventory}/${inv_id}/capability/${metric_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }
  public async deleteInvItemActivity( inv_id, act_id ){
    let endpoint = `/${this.inventory}/${inv_id}/activity/${act_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deleteInvItemProduct( inv_id, prod_id ){
    let endpoint = `/${this.inventory}/${inv_id}/product/${prod_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deleteInvItemPriority( inv_id, pri_id ){
    let endpoint = `/${this.inventory}/${inv_id}/priority/${pri_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  public async deleteInvItemSolution( inv_id, act_id ){
    let endpoint = `/${this.inventory}/${inv_id}/solution/${act_id}`;
    //endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // COMMUNITIES:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private communities = `communities`;

  /* - - - - - - - create - - - - - - - */
  public async createCommunity( obj = {}, params = {} ) {
    let endpoint = `/${this.communities}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async createComPage( cm_id, obj = {}, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}/page`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async createComPageLink( page_id: number, obj: object ) {
    let endpoint = `/${this.communities}/pages/${page_id}/link`;
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async createComPageLinks( page_id: number, obj: object ) {
    let endpoint = `/${this.communities}/pages/${page_id}/links`;
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  /* - - - - - - - read - - - - - - - */
  public async getCommunities( params = {} ) {
    let endpoint = `/${this.communities}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getCommunity( cm_id, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public getCommunityByOrg( org_id:number ) {
    let endpoint = `/${this.communities}?org=${org_id}`;
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getComPage( cm_id, params = {} ) {
    let endpoint = `/${this.communities}/pages/${cm_id}`;
    endpoint += Common.buildQueryParams(params);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getComPageLinks( page_id, params = {} ) {
    let endpoint = `/${this.communities}/pages/${page_id}/links`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getComPages( cm_id, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}/pages`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  public async getComOwners( cm_id, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}/owners`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }
  /* - - - - - - - update - - - - - - - */
  public async updateComPage( page_id, obj = {}, params = {} ) {
    let endpoint = `/${this.communities}/pages/${page_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateCommunity( cm_id, obj = {}, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateComPageLink( page_id: number, link_id: number, obj: object ) {
    let endpoint = `/${this.communities}/pages/${page_id}/links/${link_id}`;
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateComPageLinksReorder( page_id: number, obj: object ) {
    let endpoint = `/${this.communities}/pages/${page_id}/links_reorder`;
    return this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  /* - - - - - - - delete - - - - - - - */
  public async deleteComPage( page_id: number ) {
    let endpoint = `/${this.communities}/pages/${page_id}`;
    return this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  };
  public async deleteComPageLink( page_id: number, location: number, link_id: number ) {
    let endpoint = `/${this.communities}/pages/${page_id}/links/${link_id}?location=${location}`;
    return this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  };
  public async deleteComPageLinks( page_id: number, location: number ) {
    let endpoint = `/${this.communities}/pages/${page_id}/links?location=${location}`;
    return this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  };
  public async forceDeleteComPage( page_id, params = {} ) {
    let endpoint = `/${this.communities}/page/${page_id}`;
    endpoint += Common.buildQueryParams(params);
    return this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  };
  public async forceDeleteCommunity( cm_id, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  };
  public async softDeleteComPage( page_id, params = { } ) {
    let obj = {is_archived: 1};
    let endpoint = `/${this.communities}/page/${page_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  };
  public async softDeleteCommunity( cm_id, params = { } ) {
    let obj = {is_archived: 1};
    let endpoint = `/${this.communities}/${cm_id}`;
    endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  };

  /* - - - - - - - reports - - - - - - - */
  public getCmMonthlyActivity( cm_id: number, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}/monthly_activity`;
    endpoint += Common.buildQueryParams(params);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public getCmPagesMonthlyActivity( cm_id: number, params = {} ) {
    let endpoint = `/${this.communities}/${cm_id}/pages_monthly_activity`;
    endpoint += Common.buildQueryParams(params);
    return this.xhrAuth('GET', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CHAT:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  message = '/message/';
  thread = '/thread/';

  public async getChatThread(location: string, location_id: number, thread_id: number, paramsObj: Object = {}) {
    let endpoint = `/${location}/${location_id}${this.thread}${thread_id}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  // Params obj used here to specify in-app notifications and email notifications
  public async addChatMessage(location: string, location_id: number, obj: Object, paramsObj: Object = {}) {
    let endpoint = `/${location}/${location_id}${this.message}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async getChatMessage(location: string, location_id: number, message_id: number) {
    let endpoint = `/${location}/${location_id}${this.message}${message_id}`;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async updateChatMessage(location: string, location_id: number, message_id, obj: Object) {
    let endpoint = `/${location}/${location_id}${this.message}${message_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteChatMessage(location: string, location_id: number, message_id: number) {
    let endpoint = `/${location}/${location_id}${this.message}${message_id}`;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FILES:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  files = '/files/';
  public async getFiles(location: string, location_id: number, paramsObj: Object = {}) {
    let endpoint = `/${location}/${location_id}${this.files}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async addFile(location: string, location_id: number, obj: Object, paramsObj: Object = {}) {
    let endpoint = `/${location}/${location_id}${this.files}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateFile(location: string, location_id: number, file_id: number, obj: Object) {
    let endpoint = `/${location}/${location_id}/file/${file_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteFile(location: string, location_id: number, file_id: number, paramsObj = {}) {
    let endpoint = `/${location}/${location_id}/file/${file_id}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FOLDERS:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async getFolders(location: string, location_id: number, paramsObj: Object = {}) {
    let endpoint = `/${location}/${location_id}/folder`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }
  public async createFolder(location: string, location_id: number, obj: Object, paramsObj: Object = {}) {
    let endpoint = `/${location}/${location_id}/folder`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async updateFolder(location: string, location_id: number, folder_id: number, obj: Object) {
    let endpoint = `/${location}/${location_id}/folder/${folder_id}`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }
  public async deleteFolder(location: string, location_id: number, folder_id: number, paramsObj = {}) {
    let endpoint = `/${location}/${location_id}/folder/${folder_id}`;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // NOTIFICATIONS:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  notification = '/notification';

  public async getNotifications(target_user_id, paramsObj: Object = {}) {
    let endpoint: string = this.notification + '/' + target_user_id;
    endpoint += Common.buildQueryParams(paramsObj);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getNotificationsCount() {
    let endpoint = this.notification + '/count';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async updateNotification(id, obj) {
    let endpoint = this.notification + '/' + id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async updateNotifications(obj) {
    let endpoint = `${this.notification}s`;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async deleteNotification(id) {
    let endpoint = this.notification + '/' + id;
    return await this.xhrAuth('DELETE', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // INFO REQUESTS:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async sendInfoRequest(o_id, obj) {
    let endpoint = `/inforeqs/new/`+o_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async closeInfoRequests(o_id, p_id, obj) {
    let endpoint = `/inforeqs/close/solco/`+o_id+`/product/`+p_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async notifyInfoRequestors_SolCo(o_id, obj) {
    let endpoint = `/inforeqs/notify/solco/`+o_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async notifyInfoRequestors_Prod(o_id, p_id, obj) {
    let endpoint = `/inforeqs/notify/solco/`+o_id+'/product/'+p_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);
  }

  public async getInfoRequests_SolCoProfile(id) {
    let endpoint = `/inforeqs/solco/`+id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInfoRequests_ProductProfile(o_id, p_id) {
    let endpoint = `/inforeqs/solco/`+o_id+'/product/'+p_id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async getInfoRequests_ProductFields(o_id, p_id) {
    let endpoint = `/inforeqs/fields/solco/`+o_id+'/product/'+p_id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // POINTS OF CONTACT

  public async getProductContacts(o_id, p_id) {
    let endpoint = '/contacts/solco/'+o_id+'/product/'+p_id;
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  public async setProductContact(o_id, p_id, u_id) {
    let endpoint = '/contacts/add/solco/'+o_id+'/product/'+p_id+'/user/'+u_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  public async unsetProductContact(o_id, p_id, u_id) {
    let endpoint = '/contacts/remove/solco/'+o_id+'/product/'+p_id+'/user/'+u_id;
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // TOURS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /* - - - - - - - read - - - - - - - */
  public async getTours( tour_id = undefined ){
    let endpoint = `/tours${tour_id ? '/' + tour_id : ''}`;
    // endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' });
  }

  /* - - - - - - - update - - - - - - - */
  public async upsertTourItem( step, body ){
    let endpoint = `/tours/step/${step}`;
    // endpoint += Common.buildQueryParams(params);
    return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, body);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // NEWSFEED
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  newsfeed = '/newsfeed';

  public async getNewsfeedCount(user_id:number): Promise<number> {
    try {
      let result = await this.xhrAuth('GET', `${this.newsfeed}/${user_id}/count`, {'Accept': 'application/json'});
      return result.body.count;
    } catch(err) {
      console.error('getNewsfeedCount has encountered an error:', err);
      return 0;
    }
  }

  public async getNewsfeed(paramsObj) {
    return await this.getContentFromSource('newsfeed', undefined, paramsObj);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // ACTION TILES
  action_tiles = '/action_tiles';

  public async getActionTiles() {
    try {
      let result = await this.xhrAuth('GET', this.action_tiles, {'Accept': 'application/json'});
      return result;

    } catch(err) {
      console.error('getNewsfeedCount has encountered an error:', err);
      return 0;
    }
  }

  public async upsertActionTiles(obj) {
    try {
      return await this.xhrAuth('POST', this.action_tiles, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, obj);

    } catch(err) {
      console.error('getNewsfeedCount has encountered an error:', err);
      return 0;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  //  EVENTS
  private events = '/events';

  /* - - - - - - - create - - - - - - - */
  public async createEvent(obj, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + Common.buildQueryParams(paramsObj);
      // debugger
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('createEvent has encountered an error:', err);
      return 0;
    }
  }

  /* - - - - - - - read - - - - - - - */
  public async getEvents(resource: any, id: number, paramsObj: Object = {}) {
    // AVIALABLE RESOURCES: channels, communities, topics, orgs, users

    try {
      let endpoint = this.events + `/${resource}/${id}` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, {'Accept': 'application/json'});

    } catch(err) {
      console.error('getEvents has encountered an error:', err);
      return 0;
    }
  }

  public async getEvent(id: number, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, {'Accept': 'application/json'});

    } catch(err) {
      console.error('getEvent has encountered an error:', err);
      return 0;
    }
  }

  public async getEventOrgs(id, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/orgs` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});

    } catch(err) {
      console.error('getEventOrgs has encountered an error:', err);
      return 0;
    }
  }

  public async getEventUsers(id, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/users` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});

    } catch(err) {
      console.error('getEventUsers has encountered an error:', err);
      return 0;
    }
  }

  public async getEventTopics(id, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/topics` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});

    } catch(err) {
      console.error('getEventTopics has encountered an error:', err);
      return 0;
    }
  }

  public async getEventCommunities(id, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/communities` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});

    } catch(err) {
      console.error('getEventCommunities has encountered an error:', err);
      return 0;
    }
  }

  public async getEventChannels(id, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/channels` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json'});

    } catch(err) {
      console.error('getEventChannels has encountered an error:', err);
      return 0;
    }
  }

  /* - - - - - - - update - - - - - - - */
  public async updateEvent(id: number, obj: CalEvent_Out, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('updateEvent has encountered an error:', err);
      return 0;
    }
  }

  public async upsertEventOrgs(id, obj, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/orgs` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('upsertEventOrgs has encountered an error:', err);
      return 0;
    }
  }

  public async upsertEventUsers(id, obj, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/users` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('upsertEventUsers has encountered an error:', err);
      return 0;
    }
  }

  public async upsertEventTopics(id, obj, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/topics` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('upsertEventTopics has encountered an error:', err);
      return 0;
    }
  }

  public async upsertEventCommunities(id, obj, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/communities` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('upsertEventCommunities has encountered an error:', err);
      return 0;
    }
  }

  public async upsertEventChannels(id, obj, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}/channels` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('POST', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'}, obj);

    } catch(err) {
      console.error('upsertEventChannels has encountered an error:', err);
      return 0;
    }
  }

  /* - - - - - - - delete - - - - - - - */
  public async deleteEvent(id, paramsObj: Object = {}) {
    try {
      let endpoint = this.events + `/${id}` + Common.buildQueryParams(paramsObj);
      return await this.xhrAuth('DELETE', endpoint, {'Accept': 'application/json', 'Content-Type': 'application/json'});

    } catch(err) {
      console.error('upsertEventChannels has encountered an error:', err);
      return 0;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // SIDE POP IN NOTIFICATIONS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public closeNotification(): void {
    swal.close();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // NOTIFICATION ALERTS

  // LINK TO ANDY'S MOCKS (to be built):
  // https://aviahealth.invisionapp.com/d/main#/console/18186183/377508278/preview

  // notify() passes relevant information to SWAL, this will use all defaults
  // notify____() below explicity pass header text and certain optional parameters

  // NOTE: in order to use the click events of any notifications, you must return the swal call (see notify() and notifyFailure())
    // when clicked, confirm button returns `res.value = 'confirm'`
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public notify(type, title, content, options = {}): Promise<any> {
    // Turning on confirm button if confirm button text is passed
    options['showConfirmButton'] = options['confirmButtonText'] ? true : options['showConfirmButton']

    return swal({
      //onOpen:          () => { swal.showLoading() },
      position:           options['position']          !== undefined ? options['position']          : 'center',
      showCancelButton:   options['showCancelButton']  !== undefined ? options['showCancelButton']  : false,
      cancelButtonText:   options['cancelButtonText']  !== undefined ? options['cancelButtonText']  : 'Cancel',
      showCloseButton:    options['showCloseButton']   !== undefined ? options['showCloseButton']   : false,
      cancelButtonColor:  options['cancelButtonColor'] !== undefined ? options['cancelButtonColor'] : undefined,
      cancelButtonClass: options['cancelButtonClass'] !== undefined ? options['cancelButtonClass'] : undefined,
      showConfirmButton:  options['showConfirmButton'] !== undefined ? options['showConfirmButton'] : false,
      confirmButtonText:  options['confirmButtonText'] !== undefined ? options['confirmButtonText'] : 'Ok, I understand.',
      confirmButtonColor: options['confirmButtonColor'] !== undefined ? options['confirmButtonColor'] : Color_Library.green_dark,
      confirmButtonClass: options['confirmButtonClass'] !== undefined ? options['confirmButtonClass'] : undefined,
      allowOutsideClick:  options['allowOutsideClick'] !== undefined ? options['allowOutsideClick'] : true,
      html:               options['html'] !== undefined ? options['html'] : undefined,
      text:               content,
      timer:              options['timer'] !== undefined ? options['timer'] : null,
      title:              title,
      type:               type, // Can be: warning, error, success, info, question
    })
  }

  public notifyFailure( message: string ): Promise<any> {
    return this.notify('error', 'Error!', message, { showConfirmButton: true });
  }
  public notifySuccess( message: string ): void {
    this.notify('success', 'Success!', message);
  }
  public notifyWarning( message: string, options: object = { showConfirmButton: true }): Promise<any> {
    return this.notify('warning', 'Warning.', message, options);
  }
  public notifyDelete( message: string, options: object = {
    confirmButtonColor: Color_Library.red_dark,
    confirmButtonText: 'Delete',
    showConfirmButton: true,
    showCancelButton: true,
  }): any {
    return this.notify('warning', 'Delete?', message, options);  // need to return swal in order to receive btn clicks
  }


  ////////////////////////////////////////////////////////////////////////////////
  //LOGS

  public async getLogs() {
    let endpoint = '/logs';
    return await this.xhrAuth('GET', endpoint, { 'Accept': 'application/json' }).then(data => data.body);// TECHDEBT TODO: do not return body, return actual response so caller can check status before using the body... need to fix this everywhere (please dont propagate this antipattern)
  }

  //Weekly Emails
  public async pushWeeklyEmailsManually(emails) {
    let endpoint = '/dev/emailmarketstrategiesupdate';
    return await this.xhrAuth('POST', endpoint, { 'Accept': 'application/json', 'Content-Type': 'application/json' }, emails);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PERSISTENT DATA:
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public session: any;
  activitiesSupportCacher: DataCacher = new DataCacher('/activity/support', () => this.xhrAuth('GET', '/activity/support', { 'Accept': 'application/json' }).then(r => {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/activity/support') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    let result = r.body;
    if (r.body) {
      result['activity_status_types'] = Common.indexIt('id', _cloneDeep( r.body['_types_activities_status'] ));
    }
    return result;
  }));
  channelSupportCacher: DataCacher = new DataCacher('/channel/support', () => this.xhrAuth('GET', '/channel/support', { 'Accept': 'application/json' }).then(r => {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/channel/support') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    let result = r.body;
    if (r.body) {
      result['channel_invite_override_types'] = r.body['channel_invite_override_types'];
    }
    return result;
  }));
  communitiesSupportCacher: DataCacher = new DataCacher('/communities/support', () => this.xhrAuth('GET', '/communities/support', { 'Accept': 'application/json' }).then(r=> {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/communities/support') got ${r.status}, ${JSON.stringify(r)}`; this.newrelicError(msg); console.info(msg); }
    let result = r.body;
    if (r.body) {
      result['cm_status_types'] = r.body['cm_status_types'];
      result['cm_visibility_types'] = r.body['cm_visibility_types'];
      result['cmpage_header_types'] = r.body['cmpage_header_types'];
    }
    return result;
  }));
  kmSupportCacher: DataCacher = new DataCacher('/km/support', () => this.xhrAuth('GET', '/km/support', { 'Accept': 'application/json' }).then(r => {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/km/support') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    let result = r.body;
    if (r.body) {
      result['type_lookup'] = Common.indexIt('id', _cloneDeep( r.body['km_card_types'] ));
    }
    return result;
  }));
  orgsSupportCacher: DataCacher = new DataCacher('/orgs/support', () => this.xhrAuth('GET', '/orgs/support', { 'Accept': 'application/json' }).then(r => {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/orgs/support') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    let result = r.body;
    if (r.body) {
      result['status_types_lookup'] = Common.indexIt('name', _cloneDeep( r.body['status_types'] ));
    }
    return result;
  }));
  _tagSession;
  sessionSupportCacher: DataCacher = new DataCacher('/session', async () => {
   return this.xhrAuth('GET', '/session', { 'Accept': 'application/json' }).then(r => {
    if(!this._tagSession) {
      this._tagSession = true;
      let rest_url = window.location.protocol + "//" + (
      window.location.hostname.match( /(aviahealthinnovation\.com|avia\.health|devops-avia\.com|aviaenv\.com)/ ) ?
        (`${window.location.hostname}/api/rest`) :
        (
          window.location.hostname + (
            environment.dataServicePort === '' || environment.dataServicePort === undefined || environment.dataServicePort === null ?
            '' :
            ":" + environment.dataServicePort
          )
        )
      );

      this.xhr('GET', rest_url + "/feed/session/basic", { 'Accept': 'application/json' }).then((data)=>{
        try {
          let d = new Date();
          d.setTime(d.getTime() + (2 * 365 * 24 * 60 * 60 * 1000));
          let result = data.body;
          if(result && result.id) {
            let event = {
              "user_id":result.id.toString()
            };

            if(result.attributes.org && result.attributes.org.length > 0) {
              event["org_id"] = result.attributes.org[0].id.toString();
              document.cookie = `_ga_org_id=${result.attributes.org[0].id.toString()}; expires=${d.toUTCString()}; path=/`;
            }
            if(result.attributes.org_type && result.attributes.org_type.length > 0) {
              event["org_type_id"] = result.attributes.org_type[0].id.toString();
              document.cookie = `_ga_org_type_id=${result.attributes.org_type[0].id.toString()}; expires=${d.toUTCString()}; path=/`;
            }
            document.cookie = `_ga_user_id=${result.id}; expires=${d.toUTCString()}; path=/`;
            window["gtag"]('set', 'user_properties', event);
            window["gtag"]('set', 'user_id', result.id.toString());
          }
        }
        catch(ex) {
          console.log("Failure to add user_id to GTM:", ex);
        }
      });
    }


    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/session') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    this.session = r.body;
    if(this.session && this.session.user && this.session.user) {
      Common.tagAnalytics({"user.id": this.session.user.id, "org.name": this.session.org.name, "org.type": this.session.org.type});
    }
    return this.session;
  })});

  productSupportCacher: DataCacher = new DataCacher('/product/support', () => this.xhrAuth('GET', '/product/support', {'Accept': 'application/json'}).then(r => {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/product/support') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    let result = r.body;
    return result;
  }));
  usersSupportCacher: DataCacher = new DataCacher('/users/support', () => this.xhrAuth('GET', '/users/support', { 'Accept': 'application/json' }).then(r => {
    if (r.status !== 200) { let msg = `DataCacher this.xhrAuth('GET', '/users/support') got ${r.status}, ${JSON.stringify( r )}`; this.newrelicError( msg ); console.info( msg ); }
    let result = r.body;
    if (r.body) {
      result['avia_dept_types_lookup'] = Common.indexIt('id', _cloneDeep(r.body['avia_dept_types']));
      result['avia_title_types_lookup'] = Common.indexIt('id', _cloneDeep(r.body['avia_title_types']));
      result['clinical_credential_types'] = r.body['_types_clinical_creds'];
      result['clinical_credential_types_lookup'] = Common.indexIt('id', _cloneDeep(r.body['_types_clinical_creds']));
      delete result['_types_clinical_creds'];
      result['status_types_lookup'] = Common.indexIt('name', _cloneDeep(r.body['status_types']));
    }
    return result;
  }));
  ready: boolean = false;

  public async loadPersistentData(forceReload = false): Promise<any> {
    if ( (this.access_token === undefined || this.access_token === '') && !this.hasLoginCookies() ) {
      console.error( "calling loadPersistentData() without an access_token" ); // developer, please fix!
      if (forceReload) this.forgetPersistentData();
      return;
    }
    return await Promise.all([
      this.kmSupportCacher.getData(forceReload),
      this.orgsSupportCacher.getData(forceReload),
      this.sessionSupportCacher.getData(forceReload),
      this.usersSupportCacher.getData(forceReload),
      this.productSupportCacher.getData(forceReload)
    ]);
  }
  forgetPersistentData(): void {
    this.kmSupportCacher.forget();
    this.orgsSupportCacher.forget();
    this.sessionSupportCacher.forget();
    this.usersSupportCacher.forget();
    this.productSupportCacher.forget();
  }
  terminatePersistentData(): void {
    delete this.kmSupportCacher;
    delete this.orgsSupportCacher;
    delete this.sessionSupportCacher;
    delete this.usersSupportCacher;
    delete this.productSupportCacher;
  }
  public async getActivitiesSupport(forceReload: boolean = false) {
    return await this.activitiesSupportCacher.getData(forceReload);
  }
  public async getChannelSupport(forceReload: boolean = false) {
    return await this.channelSupportCacher.getData(forceReload);
  }
  public async getCommunitiesSupport(forceReload: boolean = false){
    return await this.communitiesSupportCacher.getData(forceReload);
  }
  public async getKmSupport(forceReload: boolean = false) {
    return await this.kmSupportCacher.getData(forceReload);
  }
  public async getOrgsSupport(forceReload: boolean = false) {
    return await this.orgsSupportCacher.getData(forceReload);
  }
  public async getSessionSupport(forceReload: boolean = false) {
    return await this.sessionSupportCacher.getData(forceReload);
  }
  public async getUsersSupport(forceReload: boolean = false) {
    return await this.usersSupportCacher.getData(forceReload);
  }
  public async getProductSupport(forceReload: boolean = false) {
    return await this.productSupportCacher.getData(forceReload);
  }

  aviaContentViewer: AviaContentViewerComponent;
  public openContentViewer( content_url: string, content_name: string, content_filename: string, content_id: number, open_links_in_new_tab_on_desktop: boolean = false, redirect = true ) {
    if (content_url !== '' && this.aviaContentViewer !== undefined) {
      this.aviaContentViewer.open( content_url, content_name, content_filename, content_id, open_links_in_new_tab_on_desktop, redirect );
    }
  }
  public closeContentViewer() {
    if (this.aviaContentViewer !== undefined) {
      this.aviaContentViewer.close();
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Check if the session "has access" to the resource "cat/res/(rd)" for the given "operation"(s)
  //
  // Resources are described with [cat] & [res] & optionally a [resource_desc] (which comes alongside certain types of resources from the backend)
  // We can then ask if the session "has access" to perform the [operation] to that resource!
  //
  // Example:
  //     let access_granted = aviaService.hasAccess( aviaService.session, 'admin', 'feature', 'e', this.userData.rd );
  //
  // Notes:
  // - when implementing data endpoints that operate on a 'resource', i.e. a kmcard, clcard, user, org, etc..., please
  //   provide the optional [resource_desc] so that hasAccess can also check for additional
  //   rights granted due to session's relationship to the resource (such as if you're the owner)...
  // - THE BACKEND MUST PROTECT THE DATA.  DO NOT SIMPLY IMPLEMENT PERMISSIONS ON FRONT END ONLY - INSECURE
  // - DO NOT SUPPLY THE SESSION's RD.  THAT IS __YOU__!!  INSTEAD GIVE THE RD OF WHAT's BEING VIEWED.
  //   (hasAccess knows about your session already, from first param [session], so dont worry,
  //    if session.rd was needed, hasAccess would read it... :))
  // - [operation] can be one or many = e.g. "c" or "cw" or "rw" or "crwd"
  //
  // Typical Resource descriptor  (WARNING: not from session)
  //    resource_desc: {
  //      id: id,
  //      type: user/kmcard/content/org
  //      owner: users_id,
  //      org: orgs_id,
  //      vertical: km_cards_id,
  //      admin: user_id,  /// should it be listed in managers?
  //      managers: [user_id, user_id, user_id, user_id],
  //      managing_org: orgs_id,
  //    }
  public hasAccess(session, cat: string, res: string, operation: string, resource_desc: any = {}): AccessKey {
    let ak = new AccessKey();
    let ops: Array<string> = operation.split("");
    //_____________________________________________________________
    //////////////// put your BREAKPOINT here :-) \\\\\\\\\\\\\\\\\\
    //if (cat == "np" && res == "user_demo") {
    //  let i = 0;
    //  console.log(`session = `, session);
    //  console.log(`rd: `, resource_desc);
    //  ops.forEach(op => {
    //    if (op === 'w') {
    //      console.log(`np.user_demo: ${op} - index = ${i}`);
    //    }
    //    i++
    //  });
    //}
    if (!this.loggedIn || ops.length <= 0) {
      return ak;
    }

    let test_key = (session) => {
      let checkPermission = function( op, session, rightsOrRole, sessionRightsName=undefined ) {
        let result;
        if (rightsOrRole == 'rights')
          result = Common.objSafeRead(session, ['rights', sessionRightsName, 'category_resource_op', cat, res, op], false);
        else
          result = Common.objSafeRead(session, ['role', 'category_resource_op', cat, res, op], false);
        return result;
      }

      let checkIsIn = function( session, sessionPathArray, sessionPathValueIfNotPresent, ifSessionPathEqualsThis ) {
        if (ifSessionPathEqualsThis === undefined) return false;  // early out
        let sessionVal = Common.objSafeRead( session, sessionPathArray, sessionPathValueIfNotPresent );

        if(Common.isArray( ifSessionPathEqualsThis )) {
          if(Common.isArray(sessionVal)) {  // sessionVal and ifSessionPathEqualsThis are both arrays
            return sessionVal.some(r=> ifSessionPathEqualsThis.includes(r) );
          } else {  // ifSessionPathEquals this is an array and sessionVal is not
            return ifSessionPathEqualsThis.includes( sessionVal );
          }
        } else {
          if(Common.isArray(sessionVal)) {  // ifSessionPathEqualsThis is not an array and sessionVal is an array
            return sessionVal.includes(ifSessionPathEqualsThis);
          } else {  // if neither sessionVal or ifSessionPathEqualsThis are arrays
            return ifSessionPathEqualsThis === sessionVal;
          }
        }
      }

      let checkSpecialPermission = function( op, session, sessionPathArray, sessionPathValueIfNotPresent, ifSessionPathEqualsThis, sessionRightsName ) {
        // use this for testing only!
        //if(sessionRightsName === 'Member' || sessionRightsName === 'm_avia_core') {
        //  console.log('put your breakpoint here!');
        //}
        let result = checkIsIn( session, sessionPathArray, sessionPathValueIfNotPresent, ifSessionPathEqualsThis );
        result = result && checkPermission( op, session, 'rights', sessionRightsName );
        return result;
      }

      let checkHasAccess = function(op) {
        // if(session && cat == 'np' && res == 'solco_products') {
        //   debugger;
        // }
        // BAIL OUT if Excluded from accessing (Competitors and Excluded Users) certain resources...
        if (
            (
              ((resource_desc['type'] === 'org') && (res === 'priorities' || res === 'dashboard' || res === 'inventory' || res === 'peek')) ||
              ((resource_desc['type'] === 'inv')) ||
              ((resource_desc['type'] === 'pri')) || // for the future when we have a pri RD JAW
              //This is for hiding product/solco related things if a solco has blocked an org or user
              (resource_desc['type'] === 'org' &&
                ( res === 'solco_products' || res === 'solco_files' || res === 'solco_healthsystems' || res === 'solco_network' ||
                  res === 'tag_metrics' || res === 'solco_adoptions' || res === 'network' ||
                  res === 'solco_badging' || res === 'solco_contacts' || res === 'solco_people' || res === 'solco_reviews'
                )
              )
            ) && (
              checkIsIn( session, ['org', 'id'], false, resource_desc['excludedorgs']) ||
              checkIsIn( session, ['user', 'id'], false, resource_desc['excludedusers'])
            )
        ) {
          return false;
        }
        // see if __I__ have access
        if (checkPermission( op, session, 'role' )) return true;

        // check special permissions... owner of resource, special department, etc... (keeping the more common ones first, rare ones last => for better performance)
        // =- ones that DO NOT depend on the RD below this line (maybe can early out without doing await):
        if (checkSpecialPermission( op, session, ['user', 'superadmin'], 0, 1, 'is_superadmin' )) return true;
        if (checkIsIn( session, ['org', 'type'], 0, 3) && checkSpecialPermission( op, session, ['org', 'id'], 0, 1, 'is_avia' )) return true;

        if (checkIsIn(session, ['org', 'type'], 0, 1) && checkSpecialPermission(op, session, ['org', 'membership', 'member_levels', 'm_avia_core'], 0, 1, 'm_avia_core') ) return true;
        if (checkIsIn(session, ['org', 'type'], 0, 1) && checkSpecialPermission(op, session, ['org', 'membership', 'member_levels', 'm_aha_full'], 0, 3257, 'm_aha_full') ) return true;

        if (checkSpecialPermission( op, session, ['org', 'type'], 0, 1, 'is_hs') ) return true;
        if (checkSpecialPermission( op, session, ['org', 'type'], 0, 2, 'is_company' )) return true;
        if (checkSpecialPermission( op, session, ['org', 'type'], 0, 5, 'is_partner' )) return true;

        if (checkSpecialPermission( op, session, ['user', 'dept_avia_obj', 'name'], '', "Market Strategies", 'in_market_strategies_dept' )) return true;
        else if (checkSpecialPermission( op, session, ['user', 'dept_avia_obj', 'name'], '', "Provider Solutions", 'in_provider_solutions_dept' )) return true;
        else if (checkSpecialPermission( op, session, ['user', 'dept_avia_obj', 'name'], '', "AVIA Marketing", 'in_marketing' )) return true;
        if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.owners, 'is_owner' ) && !checkIsIn( resource_desc, ['visibility'], 3, 1 )) return true;
        if (checkSpecialPermission( op, session, ['user', 'test'], 0, 1, 'is_test' )) return true;
        if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.admins, 'is_admin' )) return true;
        if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.primary_contacts, 'is_pri_contact' )) return true;

        if (resource_desc.type == "user") {
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.id, 'is_me' )) return true;
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.is_connected, 'is_connected' )) return true;
          if (checkSpecialPermission( op, session, ['org', 'type'], 0, resource_desc.org_types, 'view_org_types' )) return true;
        }
        if (resource_desc.type === 'org') {
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.participants, 'is_participant' ) && !checkIsIn( resource_desc, ['visibility'], 3, 1 )) return true;
          if (checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.id, 'is_my_org' ) && checkIsIn( resource_desc, ['visibility'], 3, 3 )) return true;
        }
        if (resource_desc.type === 'inv' ) {
          if (checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.org, 'is_my_org' ) && !checkIsIn( resource_desc, ['visibility'], 3, 1 )) return true;
        }
        if (resource_desc.type === 'content' ) {
          if (checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.org, 'is_my_org' )) return true;
          if(resource_desc.member_focus.length || resource_desc.member_types.length) {  // This content is in a member_focus or member_type (aka protected)
            if (resource_desc.member_types.length) {
              if (checkSpecialPermission( op, session, ['user', 'member_types'], 0, resource_desc.member_types, 'view_content_member_types' )) return true;
            }
            if (resource_desc.member_focus.length) {
              if (checkSpecialPermission( op, session, ['user', 'member_focus'], 0, resource_desc.member_focus, 'view_content_member_types' )) return true;
            }
          } else if (cat == 'cl' && res == 'card' && op == 'r') {   // This content is not protected and we should grant cl.card.r
            return true;
          }
        }
        if (resource_desc.type == "pulse") {
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.participants_users, 'is_participant' )) return true;
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.owners, 'is_owner' )) return true;
        }
        if (resource_desc.type == "cm") {
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.owners, 'is_owner' )) return true;
        }
        if (resource_desc.type == "activity" || resource_desc.type == "channel") {
          //one option here is that we could check if the resource_desc.key is undefined and auto pass so that the RD can drive this!
          if (checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.participants_orgs, 'is_participant' ) && (resource_desc.is_published ? resource_desc.is_published : true)) return true;
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.participants_users, 'is_participant' ) && (resource_desc.is_published ? resource_desc.is_published : true)) return true;
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.owners, 'is_owner' )) return true;
          if (checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.host_orgs, 'is_host_org' )) return true;
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.host_users, 'is_host_user' )) return true;
          // 'org' is not implemented on activities (what's it even mean?  maybe it means something for files group, maybe for channels.  but not for activities!)  todo: implement this
          if (checkSpecialPermission( op, session, ['user', 'id'], 0, resource_desc.participants_users, 'is_participant_and_is_my_org' ) &&
              checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.org,               'is_participant_and_is_my_org' )) return true;
          if (checkSpecialPermission( op, session, ['user', 'org'], 0, resource_desc.org,               'is_my_org' )) return true;

          // [is_non_participant] - kind of 'involved' to determine. :)
          if ( resource_desc.is_public && resource_desc.is_published &&
            checkPermission( op, session, 'rights', 'is_non_participant' ) &&
            !(
              // and not in the list of 'participant' orgs or users
              checkIsIn( session, ['user', 'org'], 0, resource_desc.participants_orgs ) ||
              checkIsIn( session, ['user', 'id'],  0, resource_desc.participants_users )
            ) && (
              // and a valid org type:  "AVIA", "HS Member", "SC"
              (checkIsIn( session, ['org', 'type'], 0, 5 )) ||
              (checkIsIn( session, ['org', 'type'], 0, 3 ) && checkIsIn( session, ['org', 'id'], 0, 1 )) ||
              (checkIsIn( session, ['org', 'type'], 0, 2 )) ||
              (checkIsIn( session, ['org', 'type'], 0, 1 ))
            )
          ) return true;
        }
        if ((resource_desc.type === 'inv' || resource_desc.type === 'pri') && (res === 'peek')) {
          if (
            checkIsIn( session, ['org', 'type'], 0, 1, ) &&
            checkPermission( op, session, 'rights', 'is_non_participant' ) &&
            checkIsIn( resource_desc, ['visibility'], 3, 3 ) &&
            resource_desc.is_public
          ) return true;
        }
        return false;
      }

      for (let op of ops) {
        ak[op] = checkHasAccess(op); // we are inside an async.. so... never do ak = blah...  that'll break the reference the caller got, instead do ak[op] = true|false
        if (!ak[op]) ak[op] = false;
      }
    }

    test_key(session);
    return ak;
  }

  /* * *
  * SHELL ACCESS
  * The following contain Objects of booleans - e.g., { c: false, r: true, w: true, d: false, e: true }
  * How to use in the HTML *ngIf="aviaService.admin.e"
  * * */
  // Access
  keychain_shell: AccessKeychain_Shell = new AccessKeychain_Shell();

  // await on initShellAccess to ensure the shell access keys are initialized
  // (only YOU can prevent race conditions)
  public async initShellAccess(refresh_session: boolean = false) {
    let session = await this.getSessionSupport(refresh_session);
    this.keychain_shell.admin_feature = this.hasAccess( session, 'admin', 'feature', 'e', {} );
    this.keychain_shell.dashboard_feature = this.hasAccess( session, 'org', 'dashboard_feature', 'e', session && session.org && session.org.rd );
    this.keychain_shell.activity_feature = this.hasAccess( session, 'act', 'feature', 'e', {} );
    this.keychain_shell.cm_feature = this.hasAccess( session, 'cm', 'core', 'r', {} );
    this.keychain_shell.pulse = this.hasAccess(session, 'org', 'pulse', 'e', session && session.org && session.org.rd );
    this.keychain_shell.pulse_enabled = this.hasAccess( session, 'pulse', 'enabled', 'e', session && session.org && session.org.rd );
    this.keychain_shell.org_premium = this.hasAccess( session, 'org', 'premium', 'e', session && session.org && session.org.rd );
    // we can put other feature lock/unlocks here
  }

  public clearShellAccess(resource_desc: Object = {}): void {
    this.keychain_shell.admin_feature = new AccessKey();
    this.keychain_shell.dashboard_feature = new AccessKey();
    this.keychain_shell.cm_feature = new AccessKey();
  }

}
