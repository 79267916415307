import { Injectable, HostListener } from '@angular/core';

import { Observable } from 'rxjs';
import * as Rx from 'rxjs';
import * as io from 'socket.io-client';

@Injectable()
export class AviaWebsocketService {

  // Our socket connection
  private socket;
  private url:string;
  private auth_token:string;
  private VERBOSE:boolean = false;
  private ENABLED_SOCKETIO:boolean = false;

  constructor() {}

  setUrl( url ) {
    //this.url = "https://54.149.33.147/";
    this.url = url;
  }
  setAuth( auth_token ) {
    this.auth_token = auth_token;
  }

  connect(): Rx.Subject<MessageEvent> {
    // if we ever have problems with service workers, here's a clue:
    // https://github.com/angular/angular-cli/issues/9098
    // this.ngZone.runOutsideAngular(() => {}
    try {
      if (this.VERBOSE) {
        localStorage.debug='*'; // all
        //localStorage.debug = 'socket.io-client:socket'; // incoming data only
      } else {
        if (localStorage.debug) delete localStorage.debug;
      }
    }
    catch(ex) {
      console.log("localStorage is not supported...");
    }

    if (this.ENABLED_SOCKETIO) {
      this.VERBOSE && console.log( "Connecting to socketio at: " + this.url);
      this.socket = io.connect( this.url, {secure: true, transports: ['websocket', 'flashsocket', 'polling']} );
      this.socket.on('connect', () => {
        this.socket.emit('authenticate', {token: this.auth_token});
        this.socket.on('authenticated'+this.auth_token, function() {
          this.VERBOSE && console.log( "Authenticated to socketio" );
        });
      });
    }

    // on reconnection, reset the transports option, as the Websocket
    // connection may have failed (caused by proxy, firewall, browser, ...)
    //this.socket.on('reconnect_attempt', () => {
    //  this.socket.io.opts.transports = ['websocket', 'flashsocket', 'polling'];
    //});

    // We define our observable which will observe any incoming messages
    // from our socket.io server.
    let observable = new Observable(observer => {
      if (this.ENABLED_SOCKETIO) {
        this.socket.on('message', (data) => {
          this.VERBOSE && console.log("Received message from Websocket Server", data);
          observer.next(data);
        });
        return () => {
          this.socket.disconnect();
        }
      } else {
        return () => {};
      }
    });

    // We define our Observer which will listen to messages
    // from our other components and send messages back to our
    // socket server whenever the `next()` method is called.
    let observer = {
        next: (data: Object) => {
          if (this.ENABLED_SOCKETIO) this.socket.emit('message', JSON.stringify(data));
        },
    };

    // we return our Rx.Subject which is a combination
    // of both an observer and observable.
    return Rx.Subject.create(observer, observable);
  }

  public async destroy() {
    console.log( "unloading resources" );
    if (this.ENABLED_SOCKETIO && this.socket) {
      await this.socket.disconnect();
      await this.socket.close();
      this.socket = undefined;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event) {
    this.destroy();
  }

  @HostListener('window:onunload', ['$event'])
  onunload($event) {
    this.destroy();
  }

  @HostListener('window:unload', ['$event'])
  unload($event) {
    this.destroy();
  }
}
