
<div class="chat-item media position-relative">
	<span
		[ngClass]="{pointer: item?.user_obj?.status === 4 || item?.user?.status === 4}"
		[routerLink]="(item?.user_obj?.status === 4 || item?.user?.status === 4) ? ['/profiles/in/', item?.user_obj?.id] : null">
    <img class="rounded-circle align-self-start mr-3" src="{{ item?.user_obj.avatar }}">
  </span>
  <div class="media-body">
    <div class="mt-0 pb-1 user">
			<span
				class="font-weight-bold"
				[ngClass]="{pointer: item?.user_obj?.status === 4 || item?.user?.status === 4}"
				[routerLink]="(item?.user_obj?.status === 4 || item?.user?.status === 4) ? ['/profiles/in/', item?.user_obj?.id] : null">
				{{ item?.user_obj.firstname }} {{ item?.user_obj.lastname }}
			</span>
      <span class="text-gray-600 pl-1"> {{ item?.created | smarttime }}</span>
      <div *ngIf="user_id === item?.user_obj.id" ngbDropdown placement="bottom-right" class="position-absolute more d-inline-block pointer">
          <div ngbDropdownToggle><i class="material-icons">more_vert</i></div>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <button class="dropdown-item" (click)="editing = !editing">
              <span *ngIf="!editing">Edit</span>
              <span *ngIf="editing">Save</span>
            </button>
            <button class="dropdown-item" (click)="deleteMessage(item?.id)" *ngIf="user_id === item?.user_obj.id">Delete</button>
          </div>
        </div>
    </div>
    <textarea *ngIf="editing" #message
      class="w-100 unlocked-border" rows="1" autosize
      [value]="item?.content"
      (keydown.enter)="updateMessage(item?.id, message.value)"
      (blur)="updateMessage(item?.id, message.value)">
    </textarea>
    <div *ngIf="!editing" class="not-editing p-1" [ngClass]="{'my-comment': user_id === item?.user_obj.id}" [innerHtml]="parseLinks(item?.content)"></div>
  </div>
</div>
