<div (click)="openDropDown($event, null)" #messages_container [ngClass]="{'mobile-height':aviaService.mobile_mode, 'desktop-height':!aviaService.mobile_mode}">
	<div class="w-100 pr-4 px-4 message-block" *ngFor="let m of messages; let i = index;" [ngClass]="{'shiftup':highlighted==null||highlighted>m.id, 'shiftup-small':highlighted==m.id}">
			<div class="overflow-hidden">
				<div class="d-flex" [ngClass]="{'justify-content-flexend':(m.user_id == aviaService.session?.user.id), 'justify-content-left':(m.user_id != aviaService.session?.user.id)}">
					<div *ngIf="i == 0 || sm.messages[i-1].user_id !== m.user_id || m.notify === 0" class="pt-2">
						<span *ngIf="m.user_id != aviaService.session?.user.id || m.notify === 0" class="font-weight-bold">
							<a class="img-square position-relative img-box mr-1 pointer" href="{{'/profiles/in/' + m.user_id}}" target="_blank">
								<img class="avatar-img" onerror="this.src='../../../../assets/default-user.jpg'" src="{{m?.avatar}}">
							</a>
							{{m.firstname + ' ' + m.lastname}}&nbsp;
						</span>
						<span class="text-gray-600 font-italic font-small">{{timeAgo(m.created)}}</span>
					</div>
				</div>
			</div>
			<div class="w-100 message-container d-flex" [ngClass]="{'sending':m.temp != null,'justify-content-flexend':(m.user_id == aviaService.session?.user.id), 'justify-content-left':(m.user_id != aviaService.session?.user.id)}">
				<div *ngIf="(m.user_id == aviaService.session?.user.id) && m.id !== editing && !aviaService.mobile_mode" class="p-2 mt-2 text-italic text-gray-600 hover-item font-small">{{humanTime(m.created)}}</div>
				<div *ngIf="(m.user_id == aviaService.session?.user.id) && m.id !== editing && m.notify !== 0 && m.temp == null" class="hover-item more-menu mt-2 pt-2">
					<div class="text-gray-600" (click)="openDropDown($event, m)"><i class="material-icons custom-dropdown-more">more_vert</i></div>
				</div>
				<div [innerHtml]="parseLinks(m.content)" *ngIf="m.id !== editing" (click)="highlight($event, m)" class="message-content p-2 mt-2 overflow-hidden" [ngClass]="{'font-italic':m.notify==0, 'bg-white':m.notify==0,'my-message':(m.user_id == aviaService.session?.user.id && m.notify!=0), 'message':(m.user_id != aviaService.session?.user.id), 'bg-gray-100':(m.user_id != aviaService.session?.user.id)}">
				</div>
				<div *ngIf="m.id === editing" class="p-2 mt-2 edit-message border-gray-400">
					<div #edit_message contenteditable="true" (blur)="edit(m)" (keydown.enter)="keypress($event)" (keyup.enter)="edit(m);">{{m.content}}</div>
				</div>
				<div *ngIf="(m.user_id != aviaService.session?.user.id) && aviaService.mobile_mode === false" class="p-2 mt-2 text-italic text-gray-600 hover-item font-small">{{humanTime(m.created)}}</div>
			</div>
			<div *ngIf="m.modified > m.created" class="overflow-hidden edited-container">
				<div class="d-flex" [ngClass]="{'justify-content-flexend':(m.user_id == aviaService.session?.user.id), 'justify-content-left':(m.user_id != aviaService.session?.user.id)}">
					<div *ngIf="(m.user_id == aviaService.session?.user.id) && m.id !== editing" class="pr-2 pt-2 text-italic text-gray-600 hover-item font-small">{{humanTime(m.modified)}}</div>
					<div class="text-gray-600 pt-2 font-italic">(edited)</div>
					<div *ngIf="(m.user_id != aviaService.session?.user.id)" class="pt-2 pl-2 text-italic text-gray-600 hover-item font-small">{{humanTime(m.modified)}}</div>
				</div>
			</div>
			<div *ngIf="aviaService.mobile_mode" class="position-absolute p-2 mt-2 text-italic text-gray-600 mobile-time mobile-time-left font-small" [ngClass]="{'shiftup-time':highlighted!=null&&highlighted===m.id, 'mobile-time-left':m.user_id != aviaService.session?.user.id}">{{humanTime(m.created)}}</div>
	</div>
	<div *ngIf="dropDownSelect!=null" class="custom-dropdown" [ngStyle]="{'top.px':dropDownPosition.top,'left.px':dropDownPosition.left}">
		<button class="dropdown-item" (click)="showEdit()">Edit</button>
		<button class="dropdown-item" (click)="delete()">Delete</button>
	</div>
</div>

<div class="mt-2 position-absolute bottom-0 left-0 px-4 w-100 pb-4" [ngStyle]="{'display':!aviaService.mobile_mode?'block':'none'}">
	<div class="input-container px-2 py-1 w-100 border-gray-400 position-relative bg-white">
		<textarea class="input-add w-100 p-1 pr-7" [disabled]="input_disabled"  #new_message autosize rows="1" placeholder="Type a message" (keydown.enter)="keypress($event)" (keyup.enter)="aviaService.mobile_mode?keypress($event):submit();"></textarea>
		<div class="position-absolute right-0 bottom-0 px-1 py-1"><button class="btn p-0 send-button" [ngClass]="{'text-blue-dark':new_message.value.trim().length > 0}"  (click)="submit()"><i class="material-icons">
			send
			</i></button></div>
	</div>
</div>

<div class="mt-2 position-fixed bottom-0 left-0 w-100 bg-white" [ngStyle]="{'display':aviaService.mobile_mode?'block':'none'}">
	<div class="input-container px-2 py-1 w-100 border-gray-400 position-relative">
		<textarea (focus)="inputHasFocus()" (blur)="inputLostFocus()" class="input-add w-100 pr-7" [disabled]="input_disabled"  #new_message_mobile autosize [ngClass]="{'px-4':aviaService.mobile_mode, 'py-4':aviaService.mobile_mode}" rows="1" placeholder="Type a message"></textarea>
		<div *ngIf="aviaService.mobile_mode" class="position-absolute right-0 bottom-0 px-4 py-4"><button class="btn p-0 send-button" [ngClass]="{'text-blue-dark':new_message_mobile.value.trim().length > 0}" (click)="submit()"><i class="material-icons">
			send
			</i></button></div>
	</div>
</div>
