import { element } from 'protractor';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MediumEditorHelper } from '../../_utilities/markdown';


// The world's favorite AVIA Textarea, Based on Medium Editor JS
// @example start
//  <app-avia-textarea value="initial value" editing="false"></app-avia-textarea>
// @example end
// @example <app-avia-textarea value="other value" editing="true" options="{stuff: true}"></app-avia-textarea>
// @example start
//  <app-avia-textarea
//    [editing]="true"
//    [value]="'thing 1'">
//  </app-avia-textarea>
// @example end
// @see also MediumEditorDirective (_components/medium-editor.directive.ts) for the JS medium editor implementation
// @see also MediumEditorHelper (markdown.ts) for editor options + the markdown conversion.
@Component({
  selector: 'app-avia-textarea',
  templateUrl: './avia-textarea.component.html',
  styleUrls: ['./avia-textarea.component.scss'],
})
export class AviaTextareaComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() // value
    set value(data: string) {
      this._value = data;
      this.mediumHelper.setMarkdown(this._value);
    }
    get value(): string { return this._value; }
  _value: string;

  @Input()  // this lets us intercept when 'editing' changes, so that we can call done().  before we ngIf off the component
    set editing( value:boolean ) {
      if (this._editing && !value) {
        this.doneEditing();
        this.expand();
      }
      else if(!this._editing && value && !this.expanded) {
        this.expand();
      }
      this._editing = value;
    };
    get editing(): boolean {
      return this._editing;
    };
  _editing: boolean;

  @Input() // options
    set options( inputData: Avia_Textarea_Options ) {
      this._options = new Avia_Textarea_Options();
      for (let i in inputData) {
        this._options[i] = inputData[i];
      }
    };
    get options(): Avia_Textarea_Options {
      return this._options;
    };
  _options: Avia_Textarea_Options = new Avia_Textarea_Options();

  @Output('done') done = new EventEmitter();

  expanded:    boolean = false;
  show_expand: boolean = false;
  VERBOSE: boolean = false;
  public mediumHelper = new MediumEditorHelper()

  constructor(public cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.VERBOSE && console.info('ngOnInit');
    this.VERBOSE && console.info(this.value);
    this.VERBOSE && console.info(this.options);

    this.convertText();

  }


  convertText() {
    this.mediumHelper.setMarkdown(this.value);
    if (this.options.noHTML) {
      let converted_text = MediumEditorHelper.convertMarkdownToText(this.mediumHelper.markdown)
      this.mediumHelper.setMarkdown(converted_text);
    }
    if (!this.expanded && this.options.truncate && this.mediumHelper.markdown && this.mediumHelper.markdown.length >= this.options.truncate_count) {
      let truncated_string;
      truncated_string = this.mediumHelper.markdown.substr(0, this.options.truncate_count);
      truncated_string = truncated_string.substr(0, Math.min(truncated_string.length, truncated_string.lastIndexOf(" "))) + "... ";
      this.mediumHelper.setMarkdown(truncated_string);
      this.show_expand = true;
    }
    if (this.expanded && this.options.truncate && this.mediumHelper.markdown) {
      this.mediumHelper.setMarkdown(this.mediumHelper.markdown);
    }
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  convertMarkdownToText(txt){
    return MediumEditorHelper.convertMarkdownToText(txt)
  }

  expand() {
    this.expanded = !this.expanded;
    this.convertText();
    this.VERBOSE && console.info(this.options);
  }

  doneEditing() {
    let md = this.mediumHelper.getMarkdown();
    this.VERBOSE && console.log('avia-textarea.component - doneEditing - Before value:\n%s\n\n', this.value);
    this.VERBOSE && console.log('avia-textarea.component - doneEditing - Markdown value:\n%s\n\n', md);

    //strip base64 encoded images from the markdown
    md = md.replace(/\!\[.*?\]\(data\:.*?\)/ig, "");
    if(this.options.block_images) {
      md = md.replace(/\!\[.*?\]\(.*?\)/ig, "");
    }
    if(this.value !== md ) {
      this.done.emit({ value: md });
      this.VERBOSE && console.log('avia-textarea.component - doneEditing - Emitted value:\n%s\n\n', md);
    }
  }

  onBlur() {
    this.options.trigger_done_on_blur && this.doneEditing();
    this.options.edit_finish_on_blur && (this.editing = false);
  }

  onViewerClick( e ) {
    if (this.options.edit_start_on_click)
      this.editing = true;

    return true; // true == propagate the event to children
  }

  ngOnDestroy() {
    delete this._value;
    this.VERBOSE && console.info('ngOnDestroy');
  }
}

export class Avia_Textarea_Options {
  constructor(
    public truncate:                boolean = false,
    public truncate_count:          number  = 150,
    public truncate_expand:         boolean = false,
    public expand_white_color:      boolean = false,
    public truncate_expanded_text:  string  = 'see less',
    public truncate_collapsed_text: string  = 'see more',
    public justification:           string  = 'left',
    public bold:                    boolean = false,
    public placeholder:             string  = '\ Text',
    public color:                   string  = '#505050',
    public trigger_done_on_blur:    boolean = false, // for blur-based editing:       enable editing=true and options.trigger_done_on_blur
    public edit_start_on_click:     boolean = false, // for click/blur based editing: enable both edit_start_on_click and edit_finish_on_blur
    public edit_finish_on_blur:     boolean = false,
    public block_images:            boolean = false,
    public noHTML:                  boolean = false,
  ) {  }
}
