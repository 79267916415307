<div class="page container-fluid bg-white h-100 p-0">

	<app-avia-header-row></app-avia-header-row>

	<div class="mt-6 justify-content-center row">
		<div class="col-12 col-md-5 text-center">
			<h2>Please check your email</h2>
			<p class="px-4">We’ve sent a message to the email address you have provided. Please click the link in the email to validate and continue your registration.</p>
			<img alt="Email Sent" class="mb-2 w-100" src="/assets/signup/mail-check.svg" />
			<p class="px-4 mt-3">If you don’t get it in a few minutes, please check your spam or junk mail. If you’re still not seeing it, email us at <a href='mailto:support@avia.health?subject=AVIA%20Connect%20--%20No%20Email%20Received'>support@avia.health.</a></p>
		</div>
	</div>

</div>
