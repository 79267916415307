<ng-template #LogoAdd class="d-block w-100">
	<avia-filedrop [modal]="true" [unlocked]="!file" (dragFileAccepted)="dropFile($event)">
		<div class="avia-modal-full-screen avia-modal-full-screen-inverse">
			<div class="page container-fluid bg-white">

				<div class="d-flex align-items-center headerblock row bg-gray-100">
					<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
					<span class="ml-auto backlink pointer" (click)="cancelAndClose()">
						<i class="mr-4 material-icons md-24" style="vertical-align: top;">close</i>
					</span>
				</div>

				<div class="titleblock row mt-4 text-center">
					<div class="col-12">
						<h1 class="section-header-font pt-4">{{ (!file_form.valid || !file || !file_uploaded) ? title_text : 'File Successfully Uploaded' }}</h1>
					</div>
				</div>

				<div class="editblock row justify-content-sm-center mx-0">
					<div class="col-12 col-sm-8 col-md-6 pl-2 pr-4 px-sm-0 px-1 px-sm-0">
						<form [formGroup]="file_form" (ngSubmit)="onSubmit()">

							<!-- FILE SELECT -->
							<div>
								<div [hidden]="file ? true : undefined" class="form-group text-center mt-2">
									<input #fileInput class="d-none" type="file" (change)="fileChosenFromBrowse($event); fileInput.value = null"/>
									<div class="mt-4">
										<strong class="pointer text-primary" (click)="fileInput.click()">Choose a file</strong> or drag it on to the page.
									</div>
									<small class="text-blue-dark text-center">File name must be domain plus image file type i.e. 'google.com.png'</small>
								</div>
								<p class="mt-4">
									<ngb-progressbar *ngIf="file && progress > 0" type="success" [value]="progress * 100" [animated]="true">{{progress | percent}}</ngb-progressbar>
								</p>
								<div *ngIf="progress === 0 && file" class="progress mb-2">
									<div class="progress-bar progress-bar-striped progress-bar-animated bg-green-dark w-100" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100"></div>
								</div>
							</div>

							<!-- SOURCE -->
							<div class="form-group">
								<label for="Source" class="name">Source</label>
								<input id="name" class="form-control" formControlName="source" placeholder="Source Name" type="text">
								<small class="form-text text-blue-dark">Name with top-level domain i.e. 'google.com'</small>
								<small class="form-text text-danger"
									*ngIf="
										!file_form.pristine
										&& file_form.controls['source']?.hasError('required')
										&& !fc_logo_link.hasError('required')
									"
								>'Source' is required.</small>
							</div>

							<!-- BUTTONS -->
							<div class="form-group d-flex justify-content-center mt-7">
								<button class="btn btn-white border mr-2" type="button" (click)="revert()">Revert</button>
								<button
									class="btn btn-primary ml-2"
									[disabled]="(!file_form.valid && !file_uploaded) ? 'true' : undefined"
									[ngClass]="{'no-pointer': (!file_form.valid || !file_uploaded) ? 'true' : undefined}"
									type="submit">ADD
								</button>
							</div>
						</form>

						<!-- DEBUGGING DISPLAY -->
						<ng-container *ngIf="false">
							<!-- Display the form values and state during development -->
							<p>Form value: {{ file_form.value | json }}</p>
							<p>
								<span [class.font-weight-bold]="tabs[0].active">Form Filename errors: {{ fc_fname?.errors | json}}</span> - <br>
								Form Name errors: {{ fc_source?.errors | json}}
							</p>
							<p>Form status: {{ file_form.status | json }}</p>
							<p>Pristine:
								<span class="text-uppercase">{{ file_form.pristine }}</span>
							</p>
							<p>
								File: {{ file }}<br>
								File: {{ file | json }}
							</p>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</avia-filedrop>
</ng-template>


<!-- ACTIVATION BUTTONS -->
<div *ngIf="!_hide_activate_btn" class="file-add-button" (click)="open()">
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedAddBtn.children.length === 0" class="material-icons font-size-24 file-add-btn default">add_circle</i>
</div>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl>
	<ng-content></ng-content>
</ng-template>
