<div class="avia-card h-100 rounded position-relative">
	<div *ngIf="card_buttons.discon == true" ngbDropdown placement="bottom-right" class="ml-auto mr-1 more pointer disconnect-menu" (click)="$event.stopPropagation();">
		<div ngbDropdownToggle><i class="material-icons">more_vert</i></div>
		<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right p-0">
			<button class="dropdown-item" (click)="cancel()"><i class="material-icons pr-1">remove_circle</i>Disconnect</button>
		</div>
	</div>
	<div class="card-header py-2 pl-2 pr-4">
		<div class="float-left position-relative">
			<div class="d-flex align-items-center">
				<img
					class="avatar pointer float-left mr-2"
					onerror="this.src='/assets/default-user.jpg'"
					[routerLink]="['/profiles/in', user?.id]"
					[src]="user?.avatar"
				/>
			</div>
		</div>
		<div class="h-100 d-flex min-ht-avatar flex-column">
			<div class="d-flex heading-size-18 font-weight-bold text-wrap pointer" [routerLink]="['/profiles/in', user?.id]">
				<div>{{user?.fullname}}</div>
				<div *ngIf="card_buttons.remove && akey.d" ngbDropdown placement="bottom-right" class="ml-auto mr-1 more pointer" (click)="$event.stopPropagation();">
					<div ngbDropdownToggle><i class="material-icons">more_vert</i></div>
					<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right p-0">
						<button class="dropdown-item" (click)="remove(user)"><i class="material-icons pr-1">remove_circle</i>Delete</button>
					</div>
				</div>
			</div>
			<div class="text-left text-truncate">{{user?.title}}</div>
			<div class="text-left text-truncate">{{user?.dept}}</div>
			<div class="mr-auto mb-0">
				<app-send-message-button *ngIf="card_buttons.viewPro == true && user && !card_buttons.accept && !card_buttons.ignore && !card_buttons.cancel" [user]="user">
					<button send-button class="mr-2 mt-2 btn profile-btn btn-blue-dark btn-respond">
						Message
					</button>
					<button connect-button class="mr-2 mt-2 btn profile-btn btn-green-lime btn-respond">
						Follow
					</button>
					<button disabled sent-button class="mr-2 mt-2 btn profile-btn btn-blue-dark btn-respond" style="background-color: rgba(0, 102, 163, 0.55);cursor: not-allowed;border: hsla(0,0%,100%,.45);">
						Followed
					</button>
				</app-send-message-button>
				<button
					class="mr-2 mt-2 btn profile-btn btn-green-dark btn-respond"
					*ngIf="card_buttons.accept == true"
					(click)="accept()"
				>Accept</button>
				<button
					class="mr-2 mt-2 btn profile-btn btn-gray-200 btn-respond"
					*ngIf="card_buttons.ignore == true"
					(click)="ignore()"
				>Ignore</button>
				<button
					class="mr-2 mt-2 btn profile-btn btn-gray-200 btn-respond"
					*ngIf="card_buttons.cancel == true"
					(click)="cancel()"
				>Remove&nbsp;request</button>
			</div>
		</div>
	</div>
	<div class="org-bar d-flex align-items-center justify-content-between">
		<div class="text-truncate">
			<img class="logo mx-2" [src]="user?.org_obj.logo || user?.cb_logo">
			{{user?.org_obj.name}}
		</div>
		<app-avia-network-modal
			*ngIf="card_buttons.priorities_qview || card_buttons.inventories_qview"
			[priorities]="priorities || []"
			[inventories]="inventories || []"
			[org_id]="user?.org"
			[title]="user?.fullname"
			[logo]="user?.avatar"
			>
			<button class="btn btn-sm qv-button d-flex align-items-center wrapless mr-2">
				<i class="material-icons md-18 pr-1">search</i>
				View Experience
			</button>
		</app-avia-network-modal>
	</div>
</div>
