<ng-template #EditModal let-modal>
  <div class="modal-header bg-gray-200 py-2 d-flex align-items-center get-on-top-3">
		<div class="d-flex">
			<div *ngIf="headerIcon" class="mr-1"><img style="height:21px;" [src]="headerIcon"/></div>
			<div class="modal-title heading-size-14 font-weight-bold" id="modal-basic-title">{{ modalTitle }}</div>
		</div>
    <button type="button" class="close text-gray-700" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
		<ng-content select="[modal-body]"></ng-content>
	</div>

	<!-- DEBUGGING -->
	<div *ngIf="false" class="p-4">
		<hr/>
		<h5 class="text-danger text-center font-weight-bold">Debugging</h5>
		<p>Valid: {{ form?.valid }}</p>
		<p>Pristine: {{ form?.pristine }}</p>
		<p>Touched: {{ form?.touched }}</p>
		<p>Dirty: {{ form?.dirty }}</p>

		<pre>{{ form?.value | json }}</pre>
	</div>

  <div class="modal-footer justify-content-between bg-gray-200">
    <button type="button" class="btn btn-outline-dark px-6 py-1 cancel-button" (click)="modal.dismiss()">Cancel</button>
    <button [disabled]="!form?.valid || form?.pristine" type="button" class="btn btn-primary px-6 py-1" (click)="saveData()">Save</button>
  </div>
</ng-template>

<ng-template #ConfirmModal let-modal style="max-width: 50px">
  <div class="modal-header"></div>
  <div class="modal-body biggish-text" style="text-align: center">
    Are you sure you want to discard your unsaved changes?
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-outline-dark cancel-button" (click)="modal.close('exit')">Discard</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Keep</button>
  </div>
</ng-template>
<button class="btn p-0" [ngClass]="{'d-none': SuppliedBtn && SuppliedBtn.children.length === 0, 'bg-white': !opaque_btn, 'bg-opaque': opaque_btn}" [id]="'SuppliedBtn'" #SuppliedBtn (click)="open()">
	<ng-content select="[supplied-btn]"></ng-content>
</button>

<ng-container *ngIf="SuppliedBtn && SuppliedBtn.children.length === 0">
	<ng-container [ngSwitch]="assetColor" class="float-right">
		<button class="btn edit-btn p-0">
			<img *ngSwitchCase="'white'"
				class="edit-pencil-white pointer rounded"
				[src]="'/assets/edit-white-on.svg'"
				(click)="open()">
			<img *ngSwitchCase="'gray'"
				class="edit-pencil-gray pointer"
				[src]="'/assets/edit-gray-on.svg'"
				(click)="open()">
		</button>
	</ng-container>
</ng-container>
