<!-- The Search Box -->
<ng-container *ngIf="!disabled && (multi_select || (!multi_select && selected_list.length === 0))">
	<app-avia-users-search
		*ngIf="org_id"
		[options]="{ text: prompt_string, search_add_ons: {org: org_id, status: 4} }"
		(done)="updateUsers($event)"
	></app-avia-users-search>
	<app-avia-users-search
		*ngIf="!org_id"
		[options]="{ text: prompt_string, search_add_ons: {status: 4}  }"
		(done)="updateUsers($event)"
	></app-avia-users-search>
</ng-container>
<ng-container *ngFor="let item of validation">
	<small *ngIf="item.condition" class="form-text text-danger">{{item.message}}</small>
</ng-container>

<!-- The List -->
<ng-container *ngIf="!loading && selected_list.length > 0">
	<div *ngFor="let item of selected_list" class="pill-list">
		<div *ngIf="item.fullname" class="pill-list-body">
			<div class="pill-list-icon"><img onerror="this.src='/assets/default-user.jpg'" [src]="item.avatar" /></div>
			<div class="pill-list-text">{{ item.fullname }}</div>
			<i *ngIf="!disabled" class="pill-list-close material-icons" (click)="updateUsers({'action':'delete', 'data':item, 'type': undefined, 'values':[item.id]})">close</i>
		</div>
	</div>
</ng-container>

<!-- The Loading Indicator -->
<ng-container *ngIf="loading">
	<ng-container *ngTemplateOutlet="loadingTpl"></ng-container>
</ng-container>

<!-- angular8: added missing template outlet #loadingTpl (used above) to fix broken 'npm run build-prod' -->
<!-- LOADING INDICATOR TEMPLATE -->
<ng-template #loadingTpl>
	<div *ngIf="loading">
		<app-avia-loader [options]="{type: 'row', rows: 4}"></app-avia-loader>
	</div>
</ng-template>
