import { Component, OnInit } from '@angular/core';
import { Org } from '../../../../../../class';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OnboardingService } from '../../../onboarding.service';
import { Subscription } from 'rxjs';
import { Common } from '../../../../../common';
import { CommonValidators } from '../../../../../common-validators';

@Component({
  selector: 'app-onboarding-solco-profile',
  templateUrl: './onboarding-solco-profile.component.html',
  styleUrls: ['./onboarding-solco-profile.component.scss']
})
export class OnboardingSolcoProfileComponent implements OnInit {
  readonly VERBOSE: boolean = false;

  data:             Org;
  // logo
  remove_logo:     boolean = false;
  editing_logo:    boolean = false;

  sc_form: FormGroup;

  nav_sub: Subscription;

  constructor(public sob: OnboardingService, private fb: FormBuilder) {
    this.nav_sub = this.sob.navClick.subscribe(e => {
      if(e.next) {
        this.onSubmit();
      }
    });
    this.createForm();
  }

  ngOnInit() {
    this.sob.form_valid = false;
    this.data = this.sob.org;
    this.setupFormData();
  }

  createForm(): void {
    this.sc_form = this.fb.group({
      logo:          null,
      name:         [null, [ Validators.required, Validators.pattern( Common.REGEX_NAME ) ] ],
      description:  [null, [ Validators.required ] ],
   //   email:        [null, [ Validators.pattern(Common.REGEX_EMAIL), Validators.required ] ],
      url_linkedin: [null, [ Validators.pattern( /^https?:\/\/(www\.)?linkedin.com\/company\/[\w\-.]+\/?$/i ) ] ],
      url_twitter:  [null, [ Validators.pattern( /^https?:\/\/(www\.)?twitter.com\/[\w\-.]+\/?$/i ) ] ],
      web_addr:     [null, [ Validators.pattern( Common.REGEX_WEBSITE ), Validators.required ] ],
      hq_addr: this.fb.group({
        city:       [null, [ Validators.pattern( Common.REGEX_NAME ) ] ],
        state:      [null, [ Validators.pattern( /\b[A-Z]{2}\b/i ) ] ],
        street:     [null, [ Validators.pattern( Common.REGEX_NAME ) ] ],
        postalCode: [null, [ Validators.pattern( /^[0-9]{5}(-[0-9]{4})?$/ ) ] ],
      })
    });

    this.sc_form.valueChanges.subscribe(v => {
      this.sob.form_valid = this.sc_form.valid;
    })
  };

  setupFormData(): void {
    let common_fields = {
      logo:          this.data.logo          ? this.data.logo                          : null,
      crunchbase_id: this.data.crunchbase_id ? this.data.crunchbase_id                 : null,
      description:   this.data.description   ? this.data.description                   : null,
   //   email:         this.data.email         ? this.data.email                         : null,
      name:          this.data.name          ? this.data.name                          : null,
      url_linkedin:  this.data.url_linkedin  ? this.data.url_linkedin                  : null,
      url_twitter:   this.data.url_twitter   ? this.data.url_twitter                   : null,
      web_addr:      this.data.web_addr      ? this.data.web_addr.replace( /\/$/, '' ) : null,
      hq_addr: {
        city:       this.data.hq_addr && this.data.hq_addr.city       ? this.data.hq_addr.city       : null,
        state:      this.data.hq_addr && this.data.hq_addr.state      ? this.data.hq_addr.state      : null,
        street:     this.data.hq_addr && this.data.hq_addr.street     ? this.data.hq_addr.street     : null,
        postalCode: this.data.hq_addr && this.data.hq_addr.postalCode ? this.data.hq_addr.postalCode : null
      },
    };
    this.sc_form.reset( common_fields );
    this.sob.form_valid = this.sc_form.valid;
    this.sc_form.markAsPristine();
    this.sc_form.markAsUntouched();
  };


  async onSubmit() {
    if (this.sc_form.valid && this.sc_form.touched) {
      let update_obj: any = {};

      for(let key in this.sc_form.value) {
        if(this.sc_form.value[key] == '') {
          this.sc_form.value[key] = null;
        }
        update_obj[key] = this.sc_form.value[key];
      }

      // If logo is removed
      if(this.remove_logo && CommonValidators.isLinkFromS3(update_obj.logo)) {
        let link = update_obj.logo;
        let s3_filename = Common.justFilename(link);
        await this.sob.aviaService.undoUploadToS3(s3_filename);
      }
      this.remove_logo = false;

      await this.sob.updateOrg(update_obj);
      await this.sob.getSolco();
      this.nav_sub.unsubscribe();
    }
  }

  // LOGO
  onChangeLogo( e:any ) {
    this.remove_logo = false;
    this.sob.editing_avatar = false;
    this.sc_form.controls.logo.patchValue(e.link);
    this.sc_form.controls.logo.markAsDirty();
    this.sc_form.controls.logo.markAsTouched();
  }

  async removeLogoLink(){
    this.remove_logo = true;
    this.sc_form.controls.logo.patchValue(null);
    this.sc_form.controls.logo.markAsDirty();
    this.sc_form.controls.logo.markAsTouched();
  }

  ngOnDestroy() {
    if(this.nav_sub) this.nav_sub.unsubscribe();
    this.sob.form_valid = false;
  }

}
