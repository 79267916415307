<span *ngIf="show_help_text"
  class="react-item">
  Helpful?
</span>
<!-- LIKE -->
<strong class="react-item pointer"
	(click)="likeButtonClicked()"
	[ngClass]="{'liked':liked}"
>{{like_text}}</strong>
<!-- SPACER -->
<span class="react-item">&nbsp;·&nbsp;</span>
<!-- REPLY -->
<strong class="react-item pointer"
  (click)="replyClicked.emit()"
>{{reply_text}}</strong>
<!-- SPACER -->
<span class="react-item">&nbsp;·&nbsp;</span>
<!-- DATE TEXT -->
<span class="react-item">{{modified_date}}</span>
