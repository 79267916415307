import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AccessKeychain_Content, Content, KmSupport_IdName, Nav_Tab, Nav_Tab_Pills, AnalyticEvent } from '../../../../../class';
import { AVIAConnectService } from '../../../../avia-connect.service';
import { Common } from '../../../../common';
import { CommonValidators } from '../../../../common-validators';
import { environment } from '../../../../../environments/environment';
import { AviaAlertModalComponent } from '../../../../../app/_components/avia-alert-modal/avia-alert-modal.component';


@Component({
  selector: 'app-avia-content-card',
  templateUrl: './avia-content-card.component.html',
  styleUrls: ['./avia-content-card.component.scss']
})
export class AviaContentCardComponent implements OnInit {
  private readonly VERBOSE: boolean = false;

  @Input() active_id:           number;
  @Input() allow_recommending:  boolean = false;
  @Input() content:             Content = new Content();
  @Input() content_types:       KmSupport_IdName;
  @Input() gray_border:         boolean = false;
  @Input() show_appears_in:     boolean = true;
  @Input() white_background:    boolean = false;

  @Output() onSetActiveID: EventEmitter<number> = new EventEmitter();
  @Output() onContentDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() onRecommendedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onBookmark: EventEmitter<any> = new EventEmitter();

  @ViewChild('cl_comments', { static: true })   cl_comments;
  @ViewChild('alertModal', { static: true })    alert_modal: AviaAlertModalComponent;

  // akey
  keychain_cl: AccessKeychain_Content = new AccessKeychain_Content();

  // UI
  loading_comments: boolean = false;
  can_disable_org_content: boolean;
  nf_id: number;

  // File Upload
  error:          string;
  file:           any;
  file_uploaded:  boolean = false;
  progress:       number = 0.0;
  upload_started: boolean = false;
  current_upload_abort: () => any = undefined;

  // Tabs
  nav_bar: Nav_Tab_Pills;

  // preferenced Community for community_content
  pref_cm: any = null


  constructor(public aviaService: AVIAConnectService, private route: ActivatedRoute, private router: Router) {
    this.nav_bar = new Nav_Tab_Pills([
      new Nav_Tab('Link', true),
      new Nav_Tab('Upload'),
    ]);

    this.route.params.subscribe(async params => {
      this.nf_id = params.id || params.ch_id;

      if (!this.nf_id) {
        this.route.queryParams.subscribe(async params => {
          this.nf_id = params.id
        })
      }
    });
  }

  ngOnInit() {
    this.initUserAccess(this.content.rd);
    this.setPreferredCommunity()
  }

  setPreferredCommunity() {
    // if content is produced by avia & appears in avia community return avia
    // if content is produced by avia but does not appear in avia community return first community
    // if content is not produced by avia return first community
    if(this.content && this.content.cm) {
      if(this.content.produced_by === 1 && this.content.cm.find(cm => cm.id === 1)) {
        this.pref_cm = this.content.cm.find(cm => cm.id === 1)
      } else {
        this.pref_cm = this.content.cm[0]
      }
    }
  }

  async onSetActive() {
    this.onSetActiveID.emit(this.content.id);
    await this.getContent(this.content.id);
    this.checkLink(this.content.link);
    this.setPreferredCommunity()
  }

  checkLink(link) {
    let regex_1 = new RegExp(`^(https:\/\/aviaconnectcontent.s3.amazonaws.com\/)(.+)$`);
    let regex_2 = new RegExp(`^(https:\/\/s3-us-west-2.amazonaws.com\/aviaconnectcontent\/)(.+)$`);
    let regex_3 = new RegExp(`^(https:\/\/aviaconnectcontent-test.s3.amazonaws.com\/)(.+)$`);
    let regex_4 = new RegExp(`^(https:\/\/s3-us-west-2.amazonaws.com\/aviaconnectcontent-test\/)(.+)$`);

    if(regex_1.test(link) || regex_2.test(link) || regex_3.test(link) || regex_4.test(link)) {
      this.can_disable_org_content = false;
    } else {
      this.can_disable_org_content = true;
    }
  }

  // READ
  async getContent(id) {
    let res = await this.aviaService.getContentById(id);
    if(res.status == 200) {
      this.content = res.body[0];
      this.initUserAccess(this.content.rd);
    }
  }

  async addNewsfeedContentRecommendation(content) {
    let content_p;
    if (this.router.url.includes("/channel/")) {
      content_p = await this.aviaService.createChContentRecommendation(this.content.id, this.nf_id)
    } else if (this.router.url.includes("/km/")) {
      content_p = await this.aviaService.createKmContentRecommendation(this.content.id, this.nf_id)
    }

    if (content_p.status === 200) {
      this.content.recommended = 1;
    }
    this.onRecommendedChange.emit(true);

    this.VERBOSE && console.log("AviaContentCard::updateContent ", this.content);
  }

  async removeNewsfeedContentRecommendation() {
    let content_p;
    if (this.router.url.includes("/channel/")) {
      content_p = await this.aviaService.removeChContentRecommendation(this.content.id, this.nf_id)
    } else if (this.router.url.includes("/km/")) {
      content_p = await this.aviaService.removeKmContentRecommendation(this.content.id, this.nf_id)
    }
    if (content_p.status === 200) {
      this.content.recommended = 0;
    }
    this.onRecommendedChange.emit(true);
    this.VERBOSE && console.log("AviaContentCard::updateContent ", this.content);
  }

  async openDeleteModal() {
    this.alert_modal.open();
  }

  async deleteContent($event) {
    if($event) {
      let d = await this.aviaService.deleteContent(this.content.id);
      if(d.body.message === 'error') {
        this.aviaService.notify('error', 'Something went wrong. The content was not deleted.', '');
      } else {
        d = d.body;
        if(d.s3_status && !environment.production) {
          this.aviaService.notify('Deleted!', `This content has successfully been deleted.<br/><br/>(Non-prod only message)<br/><br/> s3 Status: ${d.s3_status.message}`, '');
        } else {
          this.aviaService.notify('Deleted!', `This content has successfully been deleted.`, '');
        }
        this.onContentDelete.emit(true);
      }
    }
    this.alert_modal.close();
  }

  async updateContent(update_obj) {
    this.VERBOSE && console.log("AviaContentCard::updateContent ", update_obj);
    this.content = (await this.aviaService.updateContent(this.content.id, update_obj)).body.updated_item;
    if(update_obj['interested'] != undefined) {
      this.onBookmark.emit();
    }
    this.checkLink(this.content.link);
    this.VERBOSE && console.log("AviaContentCard::updateContent ", this.content);
  }

  goToContentSource(link: string, name: string, file_name: string, content_id: number) {
    // if it's our own file in s3, then open in the viewer, otherwise just launch a new tab.
    if (CommonValidators.isLinkFromS3( link )) {
      this.aviaService.openContentViewer( link, name, file_name, content_id, true );
      return;
    }

    // piece out all the calls, help the popup blocker not block it.
    //let shouldopen = this.aviaService.aviaContentViewer.shouldOpenInNewTab(link, name, file_name, true);
    //if (shouldopen) {
      window.open(link);
    //}
    //if (link !== '' && this.aviaService.aviaContentViewer !== undefined) {
    //  this.aviaService.aviaContentViewer.open( link, name, file_name, true );
    //}

    // have aviaservice do it
    //console.log( file_name );
    //this.aviaService.openContentViewer( link, name, file_name, content_id, true );
  }

  //ANALYTICS
  logToggleEvent(type) {
    if((!this.content.liked && type == 'cl_like') || (!this.content.interested && type == 'cl_bookmark')) {
      let obj = new AnalyticEvent(
        type,
        { card_id: this.content.id }
      );
      this.aviaService.createAnalyticEvent(obj);
    }
  }

  logEvent(type) {
    let obj = new AnalyticEvent(
      type,
      (type == 'avia_membership_learn') ? {content_id: this.content.id} : {card_id: this.content.id}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

  async initUserAccess(resource_desc: Object = {}) {
    let session = await this.aviaService.getSessionSupport()
    this.keychain_cl.feature = this.aviaService.hasAccess(session, 'cl', 'feature', 'e', resource_desc);
    this.keychain_cl.card    = this.aviaService.hasAccess(session, 'cl', 'card',    'crwd', resource_desc);
    this.keychain_cl.reports = this.aviaService.hasAccess(session, 'cl', 'reports', 'r',    resource_desc);
    this.keychain_cl.avia_org_membership = this.aviaService.hasAccess(session, 'avia', 'org_membership', 'crwd', resource_desc);
    this.VERBOSE && console.log('AviaContentCards::initUserAccess');
    if(this.VERBOSE) {
      for (let item of Object.keys(this.keychain_cl)) {
        console.log('Access Key: %s - C:%s, R:%s, W:%s, D:%s, E:%s', item, this.keychain_cl[item].c, this.keychain_cl[item].r, this.keychain_cl[item].w, this.keychain_cl[item].d, this.keychain_cl[item].e);
      }
    }
  }
}
