import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-avia-header-row',
  templateUrl: './avia-header-row.component.html',
  styleUrls: ['./avia-header-row.component.scss']
})
export class AviaHeaderRowComponent {

  @Input() show_logo: boolean = true;

  constructor() { }

}
