// @angular imports
import { Injectable, EventEmitter } from '@angular/core';

// node_module imports
import { Observable,  Subscription, interval } from 'rxjs';
import { startWith,  distinct, flatMap } from 'rxjs/operators';

// avia imports
import { AVIAConnectService } from '../avia-connect.service';
import { CommentGroup, Session, AnalyticEvent } from '../../class';
import { Common } from './../common';

@Injectable()
export class ChatService {
  private group_id:    number;
  private location:    string;
  private location_id: number;
  loading:             boolean;
  session:             Session = new Session();
  subscription:        Subscription;
  thread:              CommentGroup;
  loaded: EventEmitter<boolean> = new EventEmitter();

  VERBOSE: boolean = false;

  constructor( private aviaService: AVIAConnectService ) { }

  initChat( location, location_id, group_id ): void {
    this.loading = true;
    this.group_id = group_id;
    this.location = location;
    this.location_id = location_id;
    this.aviaService.getSessionSupport().then(data => this.session = data);
    this.updateThread();
  }

  updateThread(): void {
    this.VERBOSE && console.info('------------------ THREAD UPDATED - ID: ' + this.group_id + ' ------------------');
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.getThreadOnInterval().subscribe();
  }

  private getThreadOnInterval(): Observable<any> {
    const thread_options = { top_level: true, order: 'created', direction: 0 };

    return interval(this.buildInterval())
      .pipe( startWith(0) )
      .pipe( distinct() )
      .pipe( flatMap(() => this.aviaService.getChatThread(this.location, this.location_id, this.group_id, thread_options)
        .then(data => {
          // console.log('called: ', data);
          this.thread = data.body;
          if(this.loading) {
            this.loading = false;
            this.loaded.emit();
          }
        }))
      );
  }
  buildInterval(): number {
    let interval = Math.floor(Math.random() * 4000 + 7000); // console.log(interval)
    return interval;
  }

  async postMessage(message: string, email_notify: boolean) {
    this.VERBOSE && console.info('message send: ' + message);
    if (message === '') return

    let obj = {
      content: Common.removeTrailingNewLines(message),
      user: this.session.user.id,
      group_id: this.group_id
    };

    let paramsObj = {
      notify: 1,
      emailnotify: email_notify === true ? 1 : 0
    };
    let data = await this.aviaService.addChatMessage(this.location, this.location_id, obj, paramsObj);
    this.VERBOSE && console.info('Message Status: ', data);
    this.updateThread();

    //ANALYTICS FOR COMMENT TRACKING//
    let analytic_obj;
    if(this.location === 'content'){
      analytic_obj = new AnalyticEvent(
        this.location +'_'+'comment',
        {card_id: this.location_id}
      );
    } else if (/pulse.*\/question/.test(this.location)) {
      analytic_obj = new AnalyticEvent(
        'question_chat',
        {card_id: this.location_id}
      );
    } else {
      analytic_obj = new AnalyticEvent(
        this.location +'_'+'chat',
        {card_id: this.location_id}
      );
    }
    this.aviaService.createAnalyticEvent(analytic_obj);
    return false;
  }

  async updateMessage(message_id: number, content: string) {
    this.VERBOSE && console.info('------------------ MESSAGE EDITED - ID ' + message_id + ' ------------------ ', 'new message: ', content);
    let data = await this.aviaService.updateChatMessage(this.location, this.location_id, message_id, {'content': content})
    this.updateThread();
  }

  async deleteMessage(message_id: number){
    this.VERBOSE && console.info('------------------ MESSAGE DELETED - ID ' + message_id + ' ------------------');
    let data = await this.aviaService.deleteChatMessage(this.location, this.location_id, message_id)
    this.updateThread();
  }

  async commentNotEmpty():Promise<boolean> {
    let result = await this.aviaService.notify(
      'warning',
      'Are you sure you want to leave?',
      'You have a comment that has not been sent',
      {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        showCancelButton: true,
        showConfirmButton: true,
        timer: 0,
      }
    )
    // NOTE: This is asynchronus! It will NOT run until the user takes an action!
    if (result.value != undefined) {
      return true;
    } else {
      return false;
    }
  }

  destroyChat(): void {
    if (this.subscription) // frontend tests can sometimes be undefined
      this.subscription.unsubscribe();
  }
}
