<ng-template [ngIf]="static_page === false" [ngIfElse]="TopicAddEdit"><span class="d-none"></span></ng-template>

<ng-template #TopicAddEdit class="topic-add-edit-modal d-block w-100">
	<div [ngClass]="{
			'avia-modal-full-screen avia-modal-full-screen-inverse': (fullscreen && static_page === false),
			'avia-modal': (!fullscreen && static_page === false)
		}"
		>
		<div class="page container-fluid bg-white">

			<div class="d-flex align-items-center headerblock row bg-gray-100" *ngIf="!static_page">
				<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
				<span class="ml-auto backlink pointer" (click)="close()">
					<i class="mr-4 material-icons md-24" style="vertical-align: top;">close</i>
				</span>
			</div>

			<div class="titleblock row my-4 text-center" *ngIf="!static_page">
				<div class="col-12">
					<p class="text-uppercase" *ngIf="title_text !== undefined">{{title_text}}</p>
					<h1 class="section-header-font">Topic</h1>
				</div>
			</div>

			<div class="editblock row mx-0" [ngClass]="{'justify-content-sm-center':!static_page}">
				<div class="col-12 col-sm-10 col-md-8 pl-2 pr-4 px-sm-0 px-1 px-sm-0">
					<form [formGroup]="topic_form" (ngSubmit)="onSubmit()">

						<!-- NAME -->
						<div class="form-group" *ngIf="mode === E_Modal_Operating_Mode.ADD">
							<label for="name" class="text-uppercase">Name</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<input id="name" class="form-control"
								maxlength="255"
								[ngClass]="{'is-invalid':topic_form.controls['name']?.invalid && !topic_form.pristine}"
								formControlName="name"
								placeholder="{{'Topic Name (255 character limit)'}}"
								type="text"
							>
						</div>

						<!-- TYPE -->
						<div class="form-group" *ngIf="mode === E_Modal_Operating_Mode.ADD">
							<label for="type" class="text-uppercase">Type</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<select id="type" class="form-control text-capitalize" formControlName="type" (change)="revertTopics()">
								<option *ngFor="let type of km_card_types;" [value]="type['id']">{{type['name']}}</option>
							</select>
						</div>

						<!-- PARENTS -->
						<div class="form-group" *ngIf="!(topic_form.value.type == 6 || topic_form.value.type == 2)">
							<label for="parents" class="text-uppercase">Parents</label>
							<app-avia-topics-search [options]="{ search_add_ons: {type: topic_form.value.type } }" (done)="updateTopics($event, 'parents')"></app-avia-topics-search>
							<div class="col-12 px-0">
								<div class="topic badge bg-gray-400 d-inline-flex mr-2 mt-2 text-uppercase text-white align-items-center" *ngFor="let p of parents">
									<div class="topic-name pt-1 text-truncate">{{p.name}}</div>
									<i class="material-icons ml-2 pointer" (click)="updateTopics({'action':'remove', 'data':p}, 'parents')">cancel</i>
								</div>
							</div>
						</div>

						<!-- CHILDREN -->
						<div class="form-group" *ngIf="!(topic_form.value.type == 6 || topic_form.value.type == 2)">
							<label for="children" class="text-uppercase">Children</label>
							<app-avia-topics-search [options]="{ search_add_ons: {type: topic_form.value.type } }" (done)="updateTopics($event, 'children')"></app-avia-topics-search>
							<div class="col-12 px-0">
								<div class="topic badge bg-gray-400 d-inline-flex mr-2 mt-2 text-uppercase text-white align-items-center" *ngFor="let c of children">
									<div class="topic-name pt-1 text-truncate">{{c.name}}</div>
									<i class="material-icons ml-2 pointer" (click)="updateTopics({'action':'remove', 'data':c}, 'children')">cancel</i>
								</div>
							</div>
						</div>

						<!-- LINKS -->
						<div class="form-group" *ngIf="((topic_form.value.type == 6 || topic_form.value.type == 2) && mode === E_Modal_Operating_Mode.ADD)">
							<label for="links" class="text-uppercase">Links</label>
							<app-avia-topics-search [options]="{ search_add_ons: {type: 1 } }" (done)="updateTopics($event, 'links')"></app-avia-topics-search>
							<div class="col-12 px-0">
								<div class="topic badge bg-gray-400 d-inline-flex mr-2 mt-2 text-uppercase text-white" *ngFor="let l of links">
									<div class="topic-name pt-1 text-truncate">{{l.name}}</div>
									<i class="material-icons md-32 ml-2 pointer" (click)="updateTopics({'action':'remove', 'data':l}, 'links')">cancel</i>
								</div>
							</div>
						</div>

						<!-- PRIMARY VERTICAL -->
						<div class="form-group" [hidden]="topic_form.value.type == 6">
							<label for="avia_primary_vertical_owner" class="text-uppercase">Primary Practice Area</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<app-avia-radiobutton
								[active]="avia_primary_vertical_owner - 1"
								[data]="avia_vertical_types"
								(update)="updatePrimaryVerticalOwner($event)"
							></app-avia-radiobutton>
						</div>

						<!-- PUBLISHED -->
						<div class="form-group">
							<label for="status" class="text-uppercase">Published Status</label>
							<app-avia-radiobutton
								[active]="status - 1"
								[data]="[{name:'Draft'}, {name:'Published'}, {name:'Archived'}]"
								(update)="updatePublishedStatus($event)"
							></app-avia-radiobutton>
						</div>

						<!-- PRIMARY OWNER -->
						<div class="form-group">
							<label for="owner" class="text-uppercase">Primary Owner</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<app-avia-users-search [options]="{
									text: avia_primary_owner  ? avia_primary_owner.fullname : 'Choose a new owner...',
									search_add_ons:{org: 1}
								}" (done)="updateAviaPrimaryOwner($event)"
							></app-avia-users-search>
						</div>


						<!-- METRIC ONLY FIELDS: METRIC CATEGORIES, TYPE, STAKEHOLDERS -->

						<!-- METRIC CATEGORIES -->
						<div class="form-group" *ngIf="((topic_form.value.type == 6) && mode === E_Modal_Operating_Mode.ADD)">
							<label for="metric_avia_cat" class="text-uppercase">Categories</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<app-avia-selector
								[passed_data]="metric_categories_types"
								[active_data]="metric_categories"
								[options]="{type: 'passed', allow_deselection: false, multi_select: true, textbox: true, passed_data_selector_title: 'Select Category', disabled: false }"
								(done)="updateCategories($event)"
							></app-avia-selector>
						</div>

						<!-- METRIC TYPE -->
						<div class="form-group" *ngIf="((topic_form.value.type == 6) && mode === E_Modal_Operating_Mode.ADD)">
							<label for="metric_measure_type" class="text-uppercase">Metric Type</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<app-avia-selector
								[passed_data]="metric_measure_types"
								[active_data]="metric_measure_type"
								[options]="{type: 'passed', allow_deselection: false, multi_select: false, textbox: true, passed_data_selector_title: 'Select Type', disabled: false }"
								(done)="updateMetricType($event)"
							></app-avia-selector>
						</div>

						<!-- METRIC STAKEHOLDERS -->
						<div class="form-group" *ngIf="((topic_form.value.type == 6) && mode === E_Modal_Operating_Mode.ADD)">
							<label for="stakeholders" class="text-uppercase">Stakeholders</label>
							<span class="float-right"><small ><i>required</i></small></span>
							<app-avia-selector
								[active_data]="metric_stakeholders"
								[passed_data]="metric_stakeholders_types"
								[options]="{multi_select: true, type: 'passed', allow_deselection: false, textbox: true, passed_data_selector_title: 'Select Type', disabled: false }"
								(done)="updateStakeholders($event)"
							></app-avia-selector>
						</div>

						<!-- METRIC INDICATORS -->
						<div class="form-group" *ngIf="((topic_form.value.type == 6) && mode === E_Modal_Operating_Mode.ADD)">
							<form [formGroup]="topic_form" class="d-block">
								<div>
									<input type="checkbox" formControlName="metric_risk" [value]="metric_risk">
									<label for="metric_risk">Risk Metric</label>
								</div>
								<div>
									<input type="checkbox" formControlName="metric_strategic_indicator" [value]="metric_strategic_indicator">
									<label for="metric_strategic_indicator">Strategic Indicator</label>
								</div>
								<div>
									<input type="checkbox" formControlName="metric_early_indicator" [value]="metric_early_indicator">
									<label for="metric_early_indicator">Early Indicator</label>
								</div>
							</form>
						</div>

						<!-- DESCRIPTION -->
						<!-- <div class="form-group" *ngIf="mode === E_Modal_Operating_Mode.ADD">
							<label for="description" class="text-uppercase">Description</label>
							<textarea id="description" class="form-control" formControlName="description" placeholder="Enter a description for the topic here." rows="6"></textarea>
						</div> -->

						<!-- SHORT DESCRIPTION -->
						<div class="form-group" *ngIf="mode === E_Modal_Operating_Mode.ADD">
							<label for="description" class="text-uppercase">Short Description</label>
							<textarea id="short_desc"
								class="form-control"
								formControlName="short_desc"
								maxlength="150"
								placeholder="Enter a short description for the topic here."
								rows="2"
							></textarea>
						</div>

						<!-- BUTTONS -->
						<div class="form-group d-flex justify-content-center mt-7">
							<button class="btn btn-white border mr-2"
								type="button"
								[disabled]="topic_form.pristine ? 'true' : undefined"
								(click)="revert();"
							>Revert</button>
							<button class="btn btn-primary ml-2"
								type="submit"
								[disabled]="!topic_form.valid || topic_form.pristine"
							>{{(mode === E_Modal_Operating_Mode.ADD ? 'Add' : 'Save')}}</button>
						</div>
					</form>

					<!-- DEBUGGING DISPLAY -->
					<span *ngIf="false">
						<!-- Display the form values and state during development -->
						<p>Form value: {{ topic_form.value | json }}</p>
						<p>Form status: {{ topic_form.status | json }}</p>
						<p>Pristine:
							<span class="text-uppercase">{{ topic_form.pristine }}</span>
						</p>
					</span>
				</div>
			</div>

		</div>
	</div>
</ng-template>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>

<!-- === ACTIVATION BUTTONS === -->
<div *ngIf="mode === E_Modal_Operating_Mode.ADD && !hide_activate_btn && static_page === false"
	class="topic-add-btn "
	(click)="open()"
	>
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedAddBtn.children.length === 0"
		class="material-icons act-add-btn default"
		data-toggle="tooltip"
		placement="bottom"
		ngbTooltip="Add Topic"
	>add_circle</i>
</div>

<span *ngIf="mode === E_Modal_Operating_Mode.EDIT && !hide_activate_btn && static_page === false"
	class="topic-edit-button"
	(click)="open()"
	>
	<span #SuppliedEditBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedEditBtn.children.length === 0"
		class="material-icons act-edit-btn default"
		data-toggle="tooltip"
		placement="bottom"
		nbgTooltip="Edit"
	>edit</i>
</span>
