<div class="card" [ngClass]="{'no-shadow': view_type === 'box'}">
	<div class="card-header d-inline-flex p-0" [ngStyle]="{'background': color_dark}">
		<div class="title-box d-flex align-items-center justify-content-center safari-flex">
			<i class="material-icons text-white">map</i>
		</div>
		<div class="row w-100 no-gutters align-items-center py-2 px-2" [ngStyle]="{'background': color}">
			<div class="col-12 col-sm-6 text-white font-weight-bold">
				{{ priority?.name }}
			</div>

			<div class="col-12 col-sm-6 p-0">
				<div class="d-flex h-100 align-items-center justify-content-between justify-content-sm-end">
					<div *ngIf="akey.r">
						<button class="view-pri-btn px-2 mr-2" [routerLink]="['/dashboard/hs/', priority?.org_id, 'priority', priority?.id]" [ngStyle]="{'background': color}" (click)="close()">View&nbsp;Priority</button>
					</div>
					<div class="d-flex pr-2 pointer align-items-center" [ngStyle]="{'background': color}"
						data-toggle="tooltip" placement="left" [ngbTooltip]="org?.name"
						[routerLink]="['/profiles/hs/', org?.id]"
						(click)="close()">
						<img class="img rounded align-self-center mx-auto"
							[src]="org?.logo"
							onerror="this.src='/assets/placeholder-company.jpg'"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-body" [ngClass]="{'card-body-border': view_type === 'box'}">
		<app-avia-loader *ngIf="loading" [options]="{type: 'row', rows: 5}"></app-avia-loader>
		<div *ngIf="!loading">
			<div *ngIf="priority?.capabilities?.length > 0">
				<div class="border-gray-300 border-bottom font-weight-bold">Capabilities</div>
				<div class="pt-2 pointer text-truncate" *ngFor="let capability of priority?.capabilities"
					[routerLink]="['/intelligence/km/graph/']"
					[queryParams]="{ id: capability.id }"
					(click)="close()">
						<i class="material-icons rounded icon align-self-center text-white mx-auto bg-blue-light">extension</i>
						<span class="pl-1">{{ capability.name }}</span>
				</div>
			</div>

			<div *ngIf="priority?.solutions?.length > 0">
				<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Solutions</div>
				<div class="pt-2 pointer text-truncate" *ngFor="let solution of priority?.solutions"
					[routerLink]="['/intelligence/km/graph/']"
					[queryParams]="{ id: solution.id }"
					(click)="close()">
						<i class="material-icons rounded icon align-self-center text-white mx-auto bg-orange-light">vpn_key</i>
						<span class="pl-1">{{ solution.name }}</span>
				</div>
			</div>

			<div *ngIf="priority?.companies?.length > 0">
				<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Companies</div>
				<div class="pt-2 pointer text-truncate" *ngFor="let company of priority?.companies"
					[routerLink]="['/sc', company?.id]"
					(click)="close()">
						<img [src]="company?.logo" class="logo"/>
						<span class="pl-2">{{ company?.name }}</span>
				</div>
			</div>

			<div *ngIf="priority?.team && priority?.team.length">
				<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Team</div>
				<ng-container *ngFor="let user of priority?.team">
					<div class="pt-2 d-flex">
						<div class="user-img pointer mr-2" [routerLink]="['/profiles/in/', user?.id]" (click)="close()">
							<img class="img rounded align-self-center mx-auto"
							data-toggle="tooltip" placement="right" [ngbTooltip]="user?.fullname"
							[src]="user?.avatar"
							onerror="this.src='/assets/placeholder-company.jpg'"
							/>
						</div>
						<div class="flex-grow-1 pointer utit" [routerLink]="['/profiles/in/', user?.id]" (click)="close()">
							<div class="text-truncate font-weight-bold">{{ user.fullname }}</div>
							<div class="text-truncate">
								{{ user.title }}
								<i *ngIf="user.org_obj.name && user.title" class="material-icons dot"> lens </i>
								{{ user.org_obj.name }}
							</div>
						</div>
						<app-send-message-button [user]="user">
							<button send-button class="btn btn-blue-dark">
								Message
							</button>
							<button connect-button class="btn btn-green-lime">
								Connect
							</button>
							<button disabled sent-button class="btn btn-blue-dark" style="background-color: rgba(0, 102, 163, 0.55);cursor: not-allowed;border: hsla(0,0%,100%,.45);">
								Invite Sent
							</button>
						</app-send-message-button>
						<!-- leaving this here in case we want to go back... amd
						<div [ngSwitch]="user.conStatus">
							<div *ngSwitchCase="'connected'" class="d-inline-block px-3 pb-3 my-auto">
								<app-send-message-button (click)="close();" [id]="user?.id"><button class="btn connect-btn">Send Message</button></app-send-message-button>
							</div>
							<div *ngSwitchCase="'requested'" class="d-inline-block px-3 pb-3 my-auto">
								<em class="smol">Pending</em>
							</div>
							<div *ngSwitchCase="'pending'" class="d-inline-block px-3 pb-3 my-auto">
								<button class="btn connect-btn" (click)="connectUser(user.id)">Accept</button>
							</div>
							<div *ngSwitchCase="'ignored'" class="d-inline-block px-3 pb-3 my-auto">
								<button class="btn connect-btn" (click)="connectUser(user.id)">Accept</button>
							</div>
							<div *ngSwitchCase="'available'" class="d-inline-block px-3 pb-3 my-auto">
								<button class="btn connect-btn" (click)="connectUser(user.id)">Connect</button>
							</div>
							<div *ngSwitchCase="'unavailable'" class="d-inline-block px-3 pb-3 my-auto">
							</div>
						</div>
						-->
					</div>
					<div class="d-flex w-100">
						<div class="img-wide mr-2"></div>
						<div class="line-stretcher border-bottom"></div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>


<!-- JAW -->
