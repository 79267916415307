import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';

// AVIA Toggle
// @example <app-avia-toggle [disabled]="false" [label]="'enabled active'" [value]="true"></app-avia-toggle>
// @example <app-avia-toggle [disabled]="false" [label]="'enabled inactive'" [value]="false"></app-avia-toggle>
// @example <app-avia-toggle [disabled]="true" [label]="'disabled active'" [value]="true"></app-avia-toggle>
// @example <app-avia-toggle [disabled]="true" [label]="'disabled inactive'" [value]="false"></app-avia-toggle>
@Component({
  selector: 'app-avia-toggle',
  templateUrl: './avia-toggle.component.html',
  styleUrls: ['./avia-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AviaToggleComponent),
      multi: true
    }
  ]
})

export class AviaToggleComponent implements ControlValueAccessor {
/*
  To use in a reactive form:
    1. In your form builder, use form controls (you can use validators too)
    2. In your HTML, use formControlName="{your_form_control_defined}"
    e.g.
    *** TypeScript ***
    createForm() {
      this.about_form = this.fb.group({
        is_amc: [[this.shsp.hs.is_amc ? this.shsp.hs.is_amc : false], [Validators.required]]
      })
    }
    *** HTML ***
    <form [formGroup]="about_form">
      <app-avia-toggle
        formControlName="is_amc">
      </app-avia-toggle>
    </form>

  To use as a non-form element or template driven form:
    1. Input of [value]
    2. Output of (switched)
    i.e.
    <app-avia-toggle
      [disabled]="!can_disable_avia_content"
      [label_show]="false"
      [value]="content?.[produced_by]"
      (switched)="updateContent({'produced_by': $event.value})">
      <label class="m-0">AVIA Produced</label>
    </app-avia-toggle>
*/

  @Input()
    get value() {
      return this._value;
    }
    set value(data: number) {
      this._value = data;
      this.propagateChange(this._value);
    }
    _value: number;

  @Input() disabled:   boolean = false;
  @Input() label:      string;
  /*
    label_show = false will hide label BEFORE toggle
    label_show = false + <ng-content> HTML enables label afterward
  */
  @Input() label_show: boolean = true;

  @Output() switched: EventEmitter<any> = new EventEmitter();

  // Control Value Accessor - These functions must be declared even if empty (DO NOT DELETE)
  writeValue(val: any) {
    this.value = val;
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  constructor() { }

  // Component Functions
  triggerSwitched(status: boolean) {
    let $event = {
      value: this._value,
      label: this.label
    };
    this.switched.emit($event);
  }
}
