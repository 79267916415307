import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fadeIn } from 'ng-animate';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { transition, trigger, useAnimation } from '@angular/animations';

import {
  Nav_Tab,
  Nav_Tab_Pills
} from '../../../class';


@Component({
  selector: 'app-avia-network-modal',
  templateUrl: './avia-network-modal.component.html',
  styleUrls: ['./avia-network-modal.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', useAnimation(fadeIn, { params: { timing: .5 } }))]), //:enter is an alias for void => *
  ],
})

// JAW
export class AviaNetworkModalComponent implements OnInit, OnDestroy {
  readonly VERBOSE: boolean = false;

  @ViewChild('NetworkModal', { static: true }) network_modal: NgbModal;
  @Input() priorities: any = [];

  @Input() inventories: any = [];
  @Input() org_id: number;
  @Input() title: string = '';
  @Input() logo: string;

  @Output() onConnect: EventEmitter<any> = new EventEmitter();

  public nav_bar: Nav_Tab_Pills;
  public color:  any; // angular8: added to fix error when building prod: "Property does not exist"
  public fadeIn:  any; // angular8: added to fix error when building prod: "Property does not exist"

  inv_index: number = 0;
  pri_index: number = 0;

  modal_ref: NgbModalRef;
  modal_options: NgbModalOptions;


  constructor(private modalService: NgbModal) {
    this.nav_bar = new Nav_Tab_Pills([
      new Nav_Tab('Priorities', true),
      new Nav_Tab('Inventories'),
    ]);
  }

  ngOnInit() { }

  async openModal() {
    this.modal_options = {
      size: 'lg',
    };

    this.modal_ref = this.modalService.open(this.network_modal, this.modal_options);

    this.buildTabs();
  }

  buildTabs() {
    if(!this.priorities) this.priorities = [];
    if(!this.inventories) this.inventories = [];

    this.nav_bar.setTabVisibility('Priorities', (this.priorities.length > 0) );
    this.nav_bar.setTabVisibility('Inventories', (this.inventories.length > 0) );

    // switches the tabs if you only pass in one
    if(this.priorities.length === 0 && this.inventories.length > 0) {
      this.nav_bar.switchTabByName('Inventories');
    } else {
      this.nav_bar.switchTabByName('Priorities');
    }
  }

  next() {
    if ( this.nav_bar.active_tab === 0 && (this.pri_index < this.priorities.length - 1)) this.pri_index += 1;
    if ( this.nav_bar.active_tab === 1 && (this.inv_index < this.inventories.length - 1)) this.inv_index += 1;
    this.VERBOSE && console.log('next called');
  }

  back () {
    if ( this.nav_bar.active_tab === 0 && (this.pri_index !== 0)) this.pri_index -= 1;
    if ( this.nav_bar.active_tab === 1 && (this.inv_index !== 0)) this.inv_index -= 1;
    this.VERBOSE && console.log('back called');
  }

  ngOnDestroy(){
    this.pri_index = 0;
    this.inv_index = 0;
    this.VERBOSE && console.log('destroyed: ', this.title);
  }

}
