<div>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 105 33">
		<defs>
			<rect id="shape-a" width="175" height="99" x="0" y="0"/>
		</defs>
		<g fill="none" fill-rule="evenodd" transform="translate(-15,-12)">
			<mask id="shape-b" fill="#fff">
				<use xlink:href="#shape-a"/>
			</mask>
			<use fill="#D8D8D8" fill-opacity="0" xlink:href="#shape-a"/>
			<polygon [ngStyle]="{'fill': color}" points="3.145 10.833 182.192 29.792 176.315 78.141 -2.731 59.182" mask="url(#shape-b)" opacity=".703"/>
			<polygon [ngStyle]="{'fill': color}" points="-15.882 48.127 158.049 1.204 171.199 50.149 -2.731 97.072" mask="url(#shape-b)" opacity=".702"/>
		</g>
	</svg>
</div>