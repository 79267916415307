<div class="py-2 bg-white border-bottom bar-wrapper align-items-center justify-content-center w-100" [ngClass]="{'d-flex': !(aviaService.mobile_mode && hide_mobile_top_bar_on_scroll),'d-none':(aviaService.mobile_mode && hide_mobile_top_bar_on_scroll)}">
	<!-- Desktop -->
	<div *ngIf="!aviaService.mobile_mode" class="mx-auto global-navbar h-100 d-flex align-items-center justify-content-between px-2 w-100">
		<div class="d-none h-100 d-md-inline-flex align-items-center">
			<button class="d-flex align-items-center justify-content-center border-0 pr-4 bg-white" [routerLink]="['/start']">
				<img class="a-logo" [src]="'/assets/connect-logo-a.svg'" alt="AVIA Connect"/>
			</button>
			<!-- <app-avia-search
				#search_bar
				class="search-bar pr-4"
				[options]="{search_text: 'Search AVIA Connect', search_bar_white_bg: true, search_on_keypress: false, active_users_only: true}"
				[searching]="true"
				[term]="term"
				[in_topnav_bar]="true"
				(update_search)="updateSearch($event)"
				[autocomplete]="true"
				>
			</app-avia-search> -->
		</div>
		<!-- Right-side Icons -->
		<div class="right-icons d-none d-md-inline-flex align-items-center">
			<div class="d-flex h-100 align-items-center">
				<div [hidden]="true">
					<app-avia-messages-bubble></app-avia-messages-bubble>
				</div>
				<!-- Home -->
				<a class="link heading-size-13 pr-4 d-inline-flex align-items-center position-relative" id="nav-button-home" [routerLink]="['/start']" [ngClass]="{'active': gns.tabs[0].active}" >
					<span class="material-icons my-circle" *ngIf="false">lens
						<!-- <span class="material-icons my-circle-center">lens</span> -->
					</span>
					<i class="material-icons md-24 pb-1 mr-1" [ngClass]="{'active': gns.tabs[0].active}">home</i>
					<span class="tab-name">{{ gns.tabs[0].name }}</span>
				</a>
			</div>
		</div>

	</div>

	<!-- Mobile -->
	<div *ngIf="aviaService?.mobile_mode" class="global-navbar d-flex d-md-none justify-content-between align-items-center px-2 w-100">
		<!-- Top Bar -->
			<app-mobile-drawer #myConnectDrawer class="d-flex justify-content-center mobile-drawer">
				<button
					class="d-flex align-items-center justify-content-center"
					[ngClass]="gns.tabs[3].active ? 'img-active': 'text-gray-600'">
					<div class="avatar">
						<img *ngIf="aviaService?.session && aviaService?.session?.user" class="avatar-img" [ngClass]="{'s3-avatar' : !gns.avatarIsCloudfront()}" [src]="aviaService?.session?.user?.avatar" [alt]="aviaService?.session?.user?.fullname" />
					</div>
				</button>
			</app-mobile-drawer>

			<div class="d-flex align-items-center w-100">
				<app-avia-search
					#search_bar
					class="search-bar w-100 pr-4 pl-2"
					[options]="{search_text: 'Search', search_bar_white_bg: true, search_on_keypress: false, active_users_only: true}"
					[searching]="true"
					[term]="term"
					[in_topnav_bar]="true"
					(update_search)="updateSearch($event)"
					[autocomplete]="true"
					>
				</app-avia-search>
			</div>

			<div class="d-flex align-items-center">
				<div class="d-inline-flex align-items-center">
					<app-avia-messages-bubble></app-avia-messages-bubble>
				</div>

			</div>

	</div>
</div>

<!-- DESKTOP RETURN TO PULSE BANNER -->
<div #pulseBanner
	*ngIf="!aviaService?.mobile_mode && !aviaService?.has_dismissed_pulse && aviaService?.has_visited_pulse "
	class="justify-content-center d-flex w-100 sticky-top bg-blue-light p-2"
	[ngClass]="aviaService?.deviceInfo?.browser === 'ms-edge' ? 'pulse-bar-edge' : 'pulse-bar'">
	<div class="justify-content-center align-items-center d-flex pulse-header bg-blue-light w-100 ml-auto" >
		<!-- PULSE LOGO -->
		<img class="pt-1 pr-2 avialogo" [src]="'/assets/pulse/dp-logo-white.svg'">
		<!-- CONTINUE BUTTON -->
		<button
			(click)="continuePulse();"
			class="btn btn-primary bg-white text-blue-light font-size-12 p-1 px-2">
			Continue
		</button>
	</div>
	<!-- CLOSE ICON -->
	<i class="ml-auto align-items-center text-white pointer material-icons pl-6 pr-2 pr-lg-0" (click)="aviaService.has_dismissed_pulse = true">close</i>
</div>

<!-- MOBILE RETURN TO PULSE BANNER -->
<div #pulseBanner
	*ngIf="aviaService?.mobile_mode && !hide_mobile_top_bar_on_scroll && !aviaService?.has_dismissed_pulse && aviaService?.has_visited_pulse "
	class="justify-content-center sticky-top d-flex w-100 bg-blue-light p-2"
	[ngClass]="aviaService?.deviceInfo?.browser === 'ms-edge' ? 'pulse-bar-edge' : 'pulse-bar'">
	<div class="justify-content-center align-items-center d-flex pulse-header bg-blue-light w-100 ml-auto" >
		<!-- PULSE LOGO -->
		<img class="pt-1 pr-2 avialogo" [src]="'/assets/pulse/dp-logo-white.svg'">
		<!-- CONTINUE BUTTON -->
		<button
			(click)="continuePulse();"
			class="btn btn-primary bg-white text-blue-light font-size-12 p-1 px-2">
			Continue
		</button>
	</div>
	<!-- CLOSE ICON -->
	<i class="ml-auto align-items-center text-white pointer material-icons pl-6 pr-2 pr-lg-0" (click)="aviaService.has_dismissed_pulse = true">close</i>
</div>

<!-- Mobile Bottom Bar -->
<footer *ngIf="aviaService.mobile_mode" class="d-flex align-items-center justify-content-between d-md-none footer w-100 bg-white px-3 pb-2 pt-2">
	<div class="d-flex justify-content-center align-items-center mobile-drawer">
		<button
			class="d-flex align-items-center justify-content-center"
			[routerLink]="['/']">
			<i [ngClass]="gns.tabs[0].active ? 'active': 'text-gray-600'" class="material-icons md-22">home</i>
			<span class="icon-description">Home</span>
		</button>
	</div>

	<app-mobile-drawer class="d-flex justify-content-center mobile-drawer">
		<button
			class="d-flex align-items-center justify-content-center">
			<i class="material-icons md-22" [ngClass]="gns.tabs[1].active ? 'active': 'text-gray-600'">explore</i>
			<span class="icon-description">Discover</span>
		</button>
	</app-mobile-drawer>


	<app-mobile-drawer *ngIf="gns.show_my_org" class="d-flex justify-content-center mobile-drawer">
		<button
			class="d-flex align-items-center justify-content-center"
			[ngClass]="{'img-active': gns.tabs[2].active}">
			<div class="org">
				<img class="org-logo" [src]="aviaService?.session?.user?.org_obj?.logo" alt="Logo" onerror="this.src='/assets/placeholder-company.jpg'">
			</div>
			<span class="icon-description">My org</span>
		</button>
	</app-mobile-drawer>

	<div class="d-flex justify-content-center mobile-drawer">
		<button
		class="d-flex align-items-center justify-content-center px-0"
		[routerLink]="'/notifications'" >
		<i
			[ngClass]="gns.tabs[4].active ? 'active': 'text-gray-600'"
			class="pointer material-icons md-22">
				 notifications
			</i>
			<div
				*ngIf="gns?.notifications_count > 0"
				class="notification-badge badge-red-lighter d-flex align-items-center justify-content-center font-weight-bolder">
				{{ gns?.notifications_count | i18nPlural: notificationCountMap }}
			</div>
			<span class="icon-description">Notifications</span>
		</button>
	</div>
</footer>

