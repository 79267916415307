import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// AVIA Scale
// @example <app-avia-scale class="col-12 no-gutters mb-7 d-block"></app-avia-scale>
@Component({
  selector: 'app-avia-scale',
  templateUrl: './avia-scale.component.html',
  styleUrls: ['./avia-scale.component.scss']
})
export class AviaScaleComponent implements OnInit {

  @Input() data:Array<Object> = [
    { id: 1, txt: "Unlikely" },
    { id: 2, txt: "" },
    { id: 3, txt: "" },
    { id: 4, txt: "" },
    { id: 5, txt: "" },
    { id: 6, txt: "" },
    { id: 7, txt: "" },
    { id: 8, txt: "" },
    { id: 9, txt: "" },
    { id: 10, txt: "Likely" }
  ];
  @Output() onChange: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  click( id:number ) {
    for (let d of this.data) {
      if (d['id'] === id)
        d['selected'] = true;
      else
        d['selected'] = false;
    }
    this.onChange.emit( id );
  }
}
