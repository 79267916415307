import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AVIAConnectService } from './../avia-connect.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


let _pollingTimer;

@Injectable()
export class MessagesService {
  sub_routes: Object[] = [
    {
      name: 'Messages',
      path: 'messages',
      active: true
    }
  ]
  discussions = [];
  messages = [];
  unread_total = 0;
  selectedDiscussion;
  loadCommentGroupId;
  dMap = {};
  d_sequence = 0;
  m_sequence = 0;
  polltime = 0;
  modal_ref: NgbModalRef = null;

  constructor( public aviaService: AVIAConnectService, private router: Router, private location: Location ) {
    this.poll(true);
    window.addEventListener('focus', event => {
      this.poll(true);
    });
    window.addEventListener('blur', event => {
      this.stopPoll();
    });
  }

  async updateUnReadTotal() {
    this.unread_total = 0;
    for(let d of this.discussions) {
      this.unread_total += d.unread_count;
    }
  }

  async loadDiscussions() {
    this.d_sequence += 1;
    let d_sequence = this.d_sequence;
    let session = await this.aviaService.getSessionSupport();
    let discussions = [];
    let result = await this.aviaService.getUserDiscussions(null);

    if(result.status == 200 && result.body) {
      discussions = result.body;
    }
    else {
      return false;
    }


    if(this.d_sequence !== d_sequence) return;

    let dMap = {};
    for(let d of discussions) {
      dMap[d.comment_group_id] = d;
    }
    if(this.selectedDiscussion && this.selectedDiscussion.comment_group_id && dMap[this.selectedDiscussion.comment_group_id] == null) this.selectedDiscussion = null;

    for(let d of discussions) {
      d.other_participants = d.participants?d.participants.filter((p)=>{
        return p.id != session.user.id;
      }):[];
      if((this.selectedDiscussion && d.comment_group_id === this.selectedDiscussion.comment_group_id)) {
        this.loadCommentGroupId = null;
        if(JSON.stringify(this.selectedDiscussion) !== JSON.stringify(d)) {
          this.selectedDiscussion = d;
        }
      }
    }
    if(JSON.stringify(discussions) !== JSON.stringify(this.discussions)) {
      this.discussions = discussions;
      this.dMap = {};
      for(let d of discussions) {
        this.dMap[d.comment_group_id] = d;
      }
    }
    await this.updateUnReadTotal();
    return this.discussions;
  }
  async createDiscussion(participants, name, route = true, as_node_ids = false) {
    if(!Array.isArray(participants)) participants = [participants];
    clearTimeout(_pollingTimer);
    let discussion = await this.aviaService.createDiscussion({
      participants:participants,
      name: name,
      as_node_ids
    });
    if(discussion) {
      let session = await this.aviaService.getSessionSupport();
      discussion.other_participants = discussion.participants?discussion.participants.filter((p)=>{
        return p.id != session.user.id;
      }):[];
      this.discussions.push(discussion);
    }
    return discussion;
  }
  async updateDiscussion(comment_group_id, options) {
    if(options.name && this.dMap[comment_group_id]) {
      if((this.dMap[comment_group_id].name && this.dMap[comment_group_id].name.trim() == options.name.trim()) || (this.dMap[comment_group_id].name == null && options.name.trim() == '')) {
        delete options.name;
      }
    }
    let discussion = await this.aviaService.updateDiscussion(comment_group_id, options);
    if(discussion.participants == null || discussion.participants.length === 0) {
      this.discussions = this.discussions.filter((d)=>{
        return d.comment_group_id !== discussion.comment_group_id;
      })
    }
    await this.poll();
  }
  async selectDiscussion(d) {
      let comment_group_id = null;
      if(d == null) {
        this.selectedDiscussion = null;
      }
      else {
        comment_group_id = d.comment_group_id;

        await this.poll(false, true, false);
        let previousSelection = this.selectedDiscussion;
        if((previousSelection && previousSelection.comment_group_id == comment_group_id)) return;
        this.messages = null;
        this.loadCommentGroupId = comment_group_id;
        for(let d of this.discussions) {
          if(comment_group_id == d.comment_group_id) {
            this.selectedDiscussion = d;
            d.unread_count = 0;
          }
        }
        await this.updateUnReadTotal();
        await this.poll(false, false, true);
      }
  }
  async loadMessages(comment_group_id) {
    this.m_sequence += 1;
    let m_sequence = this.m_sequence;
    let result = await this.aviaService.getMessages(comment_group_id);
    let messages = [];

    if(result.status == 200 && result.body) {
      messages = result.body;
    }
    else {
      return false;
    }

    if(this.m_sequence !== m_sequence) return;
    if(this.selectedDiscussion && this.selectedDiscussion.comment_group_id === comment_group_id) {
      if(JSON.stringify(messages) !== JSON.stringify(this.messages)) return this.messages = messages
    }
    return this.messages;
  }
  async createMessage(comment_group_id, obj) {
    this.stopPoll();
    let session = await this.aviaService.getSessionSupport(false);

    let temp = {
      id: 'temp',
      parent_id: null,
      group_id: comment_group_id,
      content: obj.content,
      user: session['user'].id,
      resolved: 0,
      notify: null,
      modified: (new Date()).toISOString(),
      created: (new Date()).toISOString(),
      user_id: session['user'].id,
      avatar: null,
      temp:true
    }
    if(this.selectedDiscussion && this.selectedDiscussion.comment_group_id == comment_group_id) {
      this.messages.push(temp);
    }

    let msg = await this.aviaService.createMessage(comment_group_id, obj);

    this.poll();
    return msg;
  }
  async editMessage(comment_group_id, comment_id, obj) {
    if(this.selectedDiscussion && this.selectedDiscussion.comment_group_id == comment_group_id) {
      for(let i = 0; i < this.messages.length; i++) {
        let m = this.messages[i];
        if(m.id == comment_id) {
          this.messages[i].content = obj.content;
        }
      }
    }
    let msg = await this.aviaService.editMessage(comment_group_id, comment_id, obj);
    this.poll();
    return msg;
  }
  async deleteMessage(comment_group_id, comment_id) {
    if(this.selectedDiscussion && this.selectedDiscussion.comment_group_id == comment_group_id) {
      for(let i = 0; i < this.messages.length; i++) {
        let m = this.messages[i];
        if(m.id == comment_id) {
          this.messages.splice(i, 1);
        }
      }
    }
    let msg = await this.aviaService.deleteMessage(comment_group_id, comment_id);
    this.poll();
    return msg;
  }
  async poll(lazy = false, discussions = true, messages = true) {
    if(_pollingTimer){
      clearTimeout(_pollingTimer)
    }
    if(!lazy || this.polltime < Date.now() - 12000) {
      await this.refresh(lazy, discussions, messages);
      this.polltime = Date.now();
    }
    if(_pollingTimer){
      clearTimeout(_pollingTimer)
    }
    _pollingTimer = setTimeout(async()=>{
      return this.poll();
    }, 12000)
  }
  async stopPoll() {
    if(_pollingTimer){
      clearTimeout(_pollingTimer)
    }
  }
  async refresh(lazy, discussions, messages) {
    //don't do anything if logged out
    if(this.aviaService.loggedIn == false) return true;
    if(discussions) await this.loadDiscussions();
    if(this.selectedDiscussion) {
      if(messages) await this.loadMessages(this.selectedDiscussion.comment_group_id);
    }
  }

  getOtherParticipant(d) {
    let user_id = d.user_id;
    let session = this.aviaService.session;
    if(!session) {
      return {id:0,avatar:null}
    }
    if((d.user_id == null || d.user_id == session.user.id) && d.other_participants && d.other_participants.length > 0) user_id = d.other_participants[0].id;
    for(let p of d.other_participants) {
      if(p.id !== user_id) {
        return p;
      }
    }
  }
}
