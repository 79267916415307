<ng-template [ngIf]="sso.aviaService.mobile_mode" [ngIfElse]="OrgsSearch">
	<div class="search-bar-wrapper" (click)="openModal()">
		<app-avia-search #searchInput [options]="{ fake: true, search_text: options.text }" [searching]="false" [term]="sso.term"></app-avia-search>
	</div>
</ng-template>

<ng-template #OrgsSearch>
	<div [ngClass]="{'avia-modal-full-screen avia-modal-full-screen-inverse': sso.aviaService.mobile_mode}">
		<!-- SEARCH INPUT -->
		<div class="search-bar-wrapper" [ngClass]="{'mt-6': sso.aviaService.mobile_mode}" (click)="sso.aviaService.mobile_mode ? sso.searching=false : sso.searching=true">
			<app-avia-search #searchInput
				[error_class]="error_class"
				[mobile_mode]="sso.aviaService.mobile_mode"
				[options]="{ search_text: (!sso.term && sso.searching && options.show_recommendations) ? 'Recommended:' : options.text }"
				[searching]="sso.searching"
				[term]="sso.term"
				(close)="closeModal()"
				(toggle_search)="toggleView($event)"
				(update_search)="updateSearch($event)"
			></app-avia-search>
		</div>

		<!-- SEARCH RESULTS -->
		<div #searchBox class="avia-search-box" [ngClass]="{'w-100': sso.aviaService.mobile_mode}" *ngIf="sso.searching">
			<div *ngIf="sso.term" class="border border-gray-200">
				<div class="list-item" *ngFor="let d of (sso.search_results | async)"
					[class.inactive]="d?.status_obj !== undefined ? d?.status_obj.name === 'inactive' : d?.status !== undefined ? d?.status === 1 : false"
					[class.pending]="d?.status_obj !== undefined ? d?.status_obj.name === 'pending' : d?.status !== undefined ? d?.status === 2 : false"
					(click)="addAndClose(d)"
					>
					<div class="org fade-target d-flex pointer p-2">
						<img class="tiny-avatar-img mr-1" src="{{ d.logo }}" onerror="this.src='/assets/placeholder-company.jpg'" />
						<div class="title w-100">{{ d.name }}
							<div class="value-display float-right">
								<i class="material-icons text-gray-400 add ml-1">add</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- RECOMMENDATIONS -->
			<div *ngIf="!sso.term && options.show_recommendations" class="border border-gray-200">
				<div class="list-item" *ngFor="let r of recommendations"
					[class.inactive]="r?.status_obj !== undefined ? r?.status_obj.name === 'inactive' : r?.status !== undefined ? r?.status === 1 : false"
					[class.pending]="r?.status_obj !== undefined ? r?.status_obj.name === 'pending' : r?.status !== undefined ? r?.status === 2 : false"
					(click)="add(r)"
					>
					<div class="org fade-target d-flex p-2">
						<img class="tiny-avatar-img mr-1" src="{{ r.logo }}" onerror="this.src='/assets/placeholder-company.jpg'" />
						<div class="w-100 title">{{ r.name }}
							<div class="value-display float-right">
								<i [ngClass]="{'text-green-dark': r.value_normalized > 0}" class="material-icons dots text-gray-200">lens</i>
								<i [ngClass]="{'text-green-dark': r.value_normalized > 0.33}" class="material-icons dots text-gray-200">lens</i>
								<i [ngClass]="{'text-green-dark': r.value_normalized > 0.67}" class="material-icons dots text-gray-200">lens</i>
								<i *ngIf="true" class="material-icons text-gray-400 add ml-1">add</i>
								<!-- TODO: IMPLEMENT THIS LATER -->
								<i *ngIf="false" class="material-icons text-gray-400 add ml-1" (click)="delete(r)">check</i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- ADD FROM CRUNCHBASE RESULTS-->
			<span *ngIf="crunchbase_search">
				<div *ngIf="!crunchbase_loading && crunchbase_count > 0" class="list-item border-right border-left border-bottom border-gray-200 " (click)="openCrunchbaseModal()">
					<!-- ICON -->
					<div icon class="user d-flex pointer p-2">
						<img class="tiny-avatar-img mr-1" src="/assets/crunchbase-grey.svg" onerror="this.src='/assets/placeholder-company.jpg'" />
						<div class=" title align-self-center fade-target w-100">View {{crunchbase_count}} results from crunchbase</div>
					</div>
				</div>
				<!-- CRUNCHBASE LOADING ANIMATION -->
				<div>
					<app-avia-loader *ngIf="crunchbase_loading" [options]="{type: 'row', rows: 1}"></app-avia-loader>
				</div>
			</span>
		</div>
	</div>
</ng-template>

<!-- CRUNCHBASE MODAL VIEW -->
<app-avia-quick-modal
	*ngIf="crunchbase_search"
	[title]="'Add Crunchbase Company'"
	[is_open]="is_open"
	(changed)="is_open = $event.is_open"
	>
	<app-avia-quick-add-crunchbase view
		[term]="sso?.term"
		(addSolco)="addFromCrunchbase($event)">
	</app-avia-quick-add-crunchbase>
</app-avia-quick-modal>