import { Component, OnInit } from '@angular/core';

import { AVIAConnectService } from '../../avia-connect.service';
import { Common } from '../../common';


@Component({
  selector: 'app-incompatible-browser-nag',
  templateUrl: './incompatible-browser-nag.component.html',
  styleUrls: ['./incompatible-browser-nag.component.scss']
})
export class IncompatibleBrowserNagComponent implements OnInit {
  readonly EMAILADDY_SUPPORT = Common.EMAILADDY_SUPPORT; // Can't use statics in the HTML template.
  state:number = 1;
  url: string = ''

  constructor( public aviaService:AVIAConnectService ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.aviaService.browserProblemSevere) {
      // get rid of any notifications from the top of screen... at least until they click out of this box, then we'll restore it just to nag a little further. :)
      setTimeout( () => {
        this.aviaService.browserProblem = false;
      }, 50 );
    }
  }

  urlToCopy() {
    if(!Object.entries) {
      Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
          resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
      };
    }

    if (!Object.keys) {
      Object.keys = (function() {
        'use strict';
        var hasOwnProperty = Object.prototype.hasOwnProperty,
            hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
            dontEnums = [
              'toString',
              'toLocaleString',
              'valueOf',
              'hasOwnProperty',
              'isPrototypeOf',
              'propertyIsEnumerable',
              'constructor'
            ],
            dontEnumsLength = dontEnums.length;

        return function(obj) {
          if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
            throw new TypeError('Object.keys called on non-object');
          }

          var result = [], prop, i;

          for (prop in obj) {
            if (hasOwnProperty.call(obj, prop)) {
              result.push(prop);
            }
          }

          if (hasDontEnumBug) {
            for (i = 0; i < dontEnumsLength; i++) {
              if (hasOwnProperty.call(obj, dontEnums[i])) {
                result.push(dontEnums[i]);
              }
            }
          }
          return result;
        };
      }());
    }

    if(this.aviaService.redirectUrl) {
      this.url =  this.aviaService.frontUrl + this.aviaService.redirectUrl
      if(Object.keys(this.aviaService.redirectUrlParams).length > 0) {
        this.url += '?'
        Object.entries(this.aviaService.redirectUrlParams).map( p => {
          this.url += `${p[0]}=${p[1]}&`
        })
      }
    } else {
      this.url = document.location.href
    }
  }

  copyLink() {
    this.urlToCopy();

    const inputElement: any = document.querySelector("#current_url");
    inputElement.readOnly = false;
    inputElement.contentEditable = true;
    inputElement.hidden = false;
    inputElement.value = this.url;
    inputElement.select();
    inputElement.setSelectionRange(0, this.url.length);
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    inputElement.readOnly = true;
    inputElement.contentEditable = false;
  }
}
