<div class="page container-fluid bg-white h-100 p-0">

	<app-avia-header-row></app-avia-header-row>

	<div class="justify-content-center mt-6 row w-100">
		<div class="col-12 col-md-5 text-center pr-0">
			<h2>Keep me updated</h2>
			<p class="px-4">As we expand our service offerings we will start supporting different types of healthcare organizations. Enter your information below to be contacted when your organization type becomes supported.</p>
		</div>
	</div>


	<div class="justify-content-center mt-4 row w-100 mx-0">
		<div class="bg-white col-12 col-md-5 justify-content-center no-gutters row text-center">
			<!-- INITIAL EMAIL FORM -->
			<form [formGroup]="fg_other" class="w-100" (ngSubmit)="onSubmitOther()">
				<div class="form-group text-left">
					<label for="email">First Name</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="First Name"
						class="form-control"
						formControlName="firstname"
						id="firstname"
						placeholder="Enter your first name"
						tabindex="1"
						type="text"
					>
				</div>

				<div class="form-group text-left">
					<label for="email">Last Name</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="Last Name"
						class="form-control"
						formControlName="lastname"
						id="lastname"
						placeholder="Enter your last name"
						tabindex="2"
						type="text"
					>
				</div>

				<div class="form-group text-left">
					<label for="email">Professional Email</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="emailHelp"
						class="form-control"
						formControlName="email"
						id="email"
						placeholder="Enter email associated with your organization"
						tabindex="3"
						type="email"
					>
					<small *ngIf="!fg_other.pristine && fg_other.controls['email'].hasError('required')" class="form-text text-danger">Email address is required.</small>
					<small *ngIf="!fg_other.pristine && fg_other.controls['email'].hasError('email')" class="form-text text-danger">A valid email address is required.</small>
				</div>

				<div class="form-group text-left">
					<label for="email">Organization Name</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="Org Name"
						class="form-control"
						formControlName="orgname"
						id="orgname"
						placeholder="Enter your organization's name"
						tabindex="4"
						type="text"
					>
					<small *ngIf="!fg_other.pristine && fg_other.controls['orgname'].hasError('orgname')" class="form-text text-danger">An organization name is required.</small>
				</div>

				<div class="form-group text-left">
					<label for="email">Organization Website</label>
					<input
						aria-describedby="Org Website"
						class="form-control"
						formControlName="website"
						id="website"
						placeholder="Enter your organization's website"
						tabindex="5"
						type="text"
					>
				</div>

				<div class="buttonGroup d-flex justify-content-between mt-7">
					<button class="btn btn-outline-gray-700" routerLink=".." tabindex="6" type="button">&lt;&nbsp;Cancel</button>
					<button class="btn" tabindex="7" type="submit" [disabled]="!fg_other.valid" [ngClass]="{'btn-gray-400': !fg_other.valid, 'btn-primary':fg_other.valid}">
						<span class="text-white">Send&nbsp;&gt;</span>
					</button>
				</div>

					<!-- DEBUGGING DISPLAY (FG_OTHER) -->
					<span *ngIf="DEBUG_MODE && fg_other.enabled">
						<!-- Display the form values and state during development -->
						<p>Form value: {{ fg_other.value | json }}</p>
						<p>Form status: {{ fg_other.status | json }}</p>
						<p class="mb-6">Pristine: <span class="text-uppercase">{{ fg_other.pristine }}</span></p>
					</span>

			</form>
		</div>
	</div>


</div>
