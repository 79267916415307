<p id='result' *ngIf='!gotit'>LOADING...</p>
<p id='result' *ngIf='gotit'>DONE</p>

<ul>
  <li [routerLinkActive]="['active']">
    <span data-toggle="tooltip" placement="right" ngbTooltip="testing testing testing!" container="body">
      <a id='clickme' routerLink="/12345789ICVHNDQ-93481-ZCV-AVIA-165845-LTP2">other test page</a>
    </span>
  </li>
  <li *ngFor="let r of result">{{r.status}} {{r.test}} {{r.created}}</li>
</ul>
