import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { AVIAConnectService } from '../../avia-connect.service';
import { Common } from '../../common';

// AVIA PasswordChange
// @example <app-avia-password-change></app-avia-password-change>
@Component({
  selector: 'app-avia-password-change',
  templateUrl: './avia-password-change.component.html',
  styleUrls: ['./avia-password-change.component.scss']
})
export class AviaPasswordChangeComponent implements OnInit {
  @Input('button_title') button_title: string  = 'Save';
  @Input('embedded')     embedded:     boolean = false;
  @Input()               create_mode:  boolean = false;   // Creating or changing password?

  @Output('passed') passed = new EventEmitter();

  fc_initial:   AbstractControl;
  fc_confirm:   AbstractControl;
  fc_passwords: AbstractControl;

  password_form:   FormGroup;
  password_policy: any;
  password_valid:  boolean = false;
  status_msg:      string;

  constructor( private aviaService: AVIAConnectService, private formBuilder: FormBuilder ) { }

  // CUSTOM VALIDATORS

  static areEqual(c: AbstractControl): ValidationErrors | null {
    const keys: string[] = Object.keys(c.value);
    for (const i in keys) {
      if (i !== '0' && c.value[keys[+i - 1]] !== c.value[keys[i]]) {
        return { areEqual: false };
      }
    }
  }

  static isAviaPassword = (policy:any): ValidationErrors | null => {
    return (c: AbstractControl) => {
      //if (policy === undefined) return;
      let result = Common.verifyPassword( c.value, policy );
      let valid = result['password_valid'] === true ? true : false;
      if (valid) return;
      return { isAviaPassword: result['statusMsg'] };
    }
  }

  // COMPONENT STUFF

  ngOnInit() {
    this.aviaService.xhr('GET', '/about', { 'Accept': 'application/json' }).then(data => {
      data = data.body;
      this.password_policy = data.password_policy;
      this.initForm();
    });
    this.initForm();
  }

  initForm() {
    this.password_form = this.formBuilder.group({
      'passwords': this.formBuilder.group({
        'initial': ['', [Validators.required, AviaPasswordChangeComponent.isAviaPassword(this.password_policy)]],
        'confirm': ['', [Validators.required, AviaPasswordChangeComponent.isAviaPassword(this.password_policy)]]
      }, { validator: AviaPasswordChangeComponent.areEqual })
    });

    this.fc_passwords = this.password_form.get('passwords');
    this.fc_initial = this.password_form.get('passwords.initial');
    this.fc_confirm = this.password_form.get('passwords.confirm');
  }

  verify(){
    let result = Common.verifyPassword_2Boxes(
      this.password_form.value.passwords.initial,
      this.password_form.value.passwords.confirm,
      this.password_policy
    );
    this.status_msg = result['statusMsg'];
    this.password_valid = result['password_valid'];
    return result['password_valid'];
  }

  passwordCheck() {
    if(this.verify()){
      this.passed.emit({password: this.password_form.value.passwords.initial});
    }
  }
}
