<ng-template [ngIf]="static_page === false" [ngIfElse]="ContentUpload"><span class="d-none"></span></ng-template>

<ng-template #ContentUpload let-c="close" let-d="dismiss" class="d-block w-100">
	<avia-filedrop [modal]="true" [unlocked]="!file" (dragFileAccepted)="dropFile($event)">
		<div [ngClass]="{ 'avia-modal-full-screen avia-modal-full-screen-inverse': !static_page }" >
			<div class="page container-fluid" [ngClass]="{ 'bg-white':!static_page }">
				<!-- AVIA Top Bar -->
				<div class="d-flex align-items-center headerblock row bg-gray-100" *ngIf="!static_page">
					<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
					<span class="ml-auto backlink pointer" (click)="_close()">
						<i class="mr-4 material-icons close-button">close</i>
					</span>
				</div>

				<!-- Form Heading -->
				<div class="titleblock row my-4 pt-2 text-center" *ngIf="!static_page">
					<div class="col-12"><h2>Add New Content</h2></div>
				</div>

				<!-- Modal Body -->
				<div class="editblock justify-content-center row mx-0 pb-4">
					<div
						[ngClass]="{
							'col-12 col-sm-10 col-md-6 px-4 px-sm-0':!static_page,
							'p-0':static_page
						}"
						>
						<!-- Form -->
						<form [formGroup]="content_form" (ngSubmit)="onSubmit()">
							<!-- Org produced toggle -->
							<div class="form-group file-form-group">
								<div>
									<app-avia-toggle
										formControlName="produced_by"
										[label_show]="false"
										(switched)="updateProducedBy($event)"
										>
										<label class="m-0 org-produced-label">Produced by {{aviaService.session.org.name}}</label>
									</app-avia-toggle>
								</div>

								<!-- Permissions -->
								<div *ngIf="scl?.keychain_cl?.avia_org_membership?.c && fc_produced_by.value == 1" class="row pt-4 mt-4">
									<div class="col-12">
										<div class="font-weight-bold pb-2">Who can see this content?</div>
										<div class="d-block d-md-inline">
											<div class="d-block d-md-inline">
												<input type="radio" [value]=0 formControlName="special_content" />
												<label class="pl-1 pr-4">User Types</label>
											</div>
											<div class="d-block d-md-inline">
												<input type="radio" [value]=1 formControlName="special_content" />
												<label class="pl-1 pr-4">Membership Types</label>
											</div>
											<div class="d-block d-md-inline">
												<input type="radio" [value]=2 formControlName="special_content" />
												<label class="pl-1 pr-4">Strategic Initiatives & Programs</label>
											</div>
										</div>
									</div>
									<!-- Org Type Sub-Perms -->
									<div *ngIf="show_orgtypes" class="col-12 pt-2">
										<div class="row">
											<small class="pl-4 form-text text-gray-500">
												<em>Select which user types you would like to see this content. Only those selected will be able to access.</em>
											</small>
											<div class="font-weight-bold pb-0 pb-md-2 pt-2 pt-md-0 col-12 col-md-6">Selected User Types</div>
											<div *ngIf="fc_special_content.value == 0" class="col-12 col-md-6 pb-2 text-md-right">
												<small [ngClass]="{
													'text-danger': !content_form.pristine && fc_org_type_arr.touched && fc_org_type_arr.hasError('minLengthTruthyArray'),
													'text-gray-500': content_form.untouched && fc_org_type_arr.hasError('minLengthTruthyArray')}"
													class="font-italic"
												>at least one type is required</small>
											</div>
										</div>
										<ng-container *ngIf="(avia_org_types && avia_org_types.length) && (orgTypeArray && orgTypeArray.length)">
											<div *ngFor="let type of avia_org_types; let i=index" class="pb-2">
												<app-avia-form-checkbox
													ngDefaultControl
													[formControl]="orgTypeArray.controls[i]"
													[id]="type?.id"
													[label]="type?.name"
												></app-avia-form-checkbox>
											</div>
										</ng-container>
									</div>
									<!-- Membership Sub-Perms -->
									<div *ngIf="show_membership" class="col-12 pt-2">
										<div class="row">
											<small class="pl-4 form-text text-gray-500">
												<em>Only health system members of AVIA. Specify which types below. "Adult" is the most common.</em>
											</small>
											<div class="font-weight-bold pb-0 pb-md-2 pt-2 pt-md-0 col-12 col-md-6">Selected Membership Types</div>
											<div *ngIf="fc_special_content.value == 1" class="col-12 col-md-6 pb-2 text-md-right">
												<small [ngClass]="{
													'text-danger': !content_form.pristine && fc_member_type_arr.touched && fc_member_type_arr.hasError('minLengthTruthyArray'),
													'text-gray-500': content_form.untouched && fc_member_type_arr.hasError('minLengthTruthyArray')}"
													class="font-italic"
												>at least one type is required</small>
											</div>
										</div>
										<ng-container *ngIf="(avia_member_types && avia_member_types.length) && (memberTypeArray && memberTypeArray.length)">
											<div *ngFor="let type of avia_member_types; let i=index" class="pb-2">
												<app-avia-form-checkbox
													[id]="type?.id"
													ngDefaultControl
													[formControl]="memberTypeArray.controls[i]"
													[label]="type?.name"
													[value]="memberTypeArray?.controls[i]?.value"
												></app-avia-form-checkbox>
											</div>
										</ng-container>
									</div>
									<!-- Programs Sub-Perms -->
									<div *ngIf="show_programs" class="col-12 pt-2">
										<div class="row">
											<small class="pl-4 form-text text-gray-500">
												<em>Only health system members of AVIA with the specified strategic initiative(s).</em>
											</small>
											<div class="font-weight-bold pb-0 pb-md-2 pt-2 pt-md-0 col-12 col-md-6">Selected Strategic Initiatives & Programs</div>
											<div *ngIf="fc_special_content.value == 2" class="col-12 col-md-6 pb-2 text-md-right">
												<small [ngClass]="{
													'text-danger': !content_form.pristine && fc_programs.touched && fc_programs.hasError('minLengthArray'),
													'text-gray-500': content_form.untouched && fc_programs.hasError('minLengthArray')}"
													class="font-italic"
												>at least one program or strategic initiative is required</small>
											</div>
										</div>
										<app-avia-form-search
											formControlName="programs"
											[data_list]="avia_member_focus"
											[placeholder]="'Find a program or strategic initiative'"
										></app-avia-form-search>
									</div>
								</div>
							</div>

							<app-avia-tab-pills [nav_bar]="file_or_link_tabs" (tab_selected)="tabChanged($event)"></app-avia-tab-pills>
							<!-- Link Tab -->
							<div [hidden]="file_or_link_tabs.active_tab !== 0 ? true : undefined" class="pb-2">
								<label for="link" class="name">Link</label>
								<span *ngIf="!fc_link.valid" class="float-right">
									<small>
										<i [ngClass]="{
											'text-danger': !content_form.pristine && fc_link.touched && fc_link.hasError('required'),
											'text-gray-500': content_form.untouched && fc_link.hasError('required')
											}"
										>required</i>
									</small>
								</span>
								<input
									autocomplete="off"
									class="form-control"
									formControlName="link"
									id="link"
									placeholder="Link Location"
									type="text"
									[ngClass]="{ 'is-invalid': fc_link.invalid && fc_link.touched }"
									(blur)="checkExistingContent({'link': fc_link.value ? fc_link.value : undefined})"
								>
								<small class="form-text text-gray-500">
									<em>Links work best for large files, such as videos. For other files, uploading ensures your file doesn't get lost or moved.</em>
								</small>
							</div>

							<!-- Upload Tab -->
							<div [hidden]="file_or_link_tabs.active_tab !== 1 ? true : undefined">
								<div [hidden]="file ? true : undefined" class="text-center mt-2">
									<input #fileInput class="d-none" type="file" (change)="fileChosenFromBrowse($event); fileInput.value = null"/>
									<div class="mt-0 mt-md-4" *ngIf="fc_produced_by.value">
										<strong class="pointer text-primary" (click)="fileInput.click()">Choose a file</strong> or drag it on to the page.
									</div>
									<div class="pt-2 text-danger" *ngIf="!fc_produced_by.value">
										<span><em class="text-danger">All uploaded content must be produced by your organization. Select that option to enable upload. If not, please link to the content.</em></span>
									</div>
								</div>
								<p class="mt-4">
									<ngb-progressbar *ngIf="file && progress > 0" type="success" [value]="progress * 100" [animated]="true">{{progress | percent}}</ngb-progressbar>
								</p>
								<div *ngIf="progress === 0 && file" class="progress mb-2">
									<div class="progress-bar progress-bar-striped progress-bar-animated bg-green-dark w-100" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100"></div>
								</div>
							</div>

							<!-- Name -->
							<div class="form-group">
								<label for="name">Name</label>
								<span *ngIf="!fc_name.valid"
									class="float-right">
									<small>
										<i [ngClass]="{
											'text-danger': fc_name.touched && fc_name?.hasError('required'),
											'text-gray-500': fc_name.untouched && fc_name?.hasError('required')
											}"
										>required</i>
									</small>
								</span>
								<input
									autocomplete="off"
									class="form-control"
									formControlName="name"
									id="name"
									placeholder="Content Name"
									type="text"
									[ngClass]="{ 'is-invalid': (fc_name.invalid && fc_name.touched) }"
									(blur)="checkExistingContent( {'name': fc_name.value ? fc_name.value : undefined} )"
								>
							</div>
							<!-- Author -->
							<div class="form-group">
								<label for="author">Author</label>
								<input
									autocomplete="off"
									class="form-control"
									formControlName="author"
									id="author"
									placeholder="Author"
									type="text"
								>
							</div>
							<!-- Type -->
							<div class="form-group" (click)="fc_type.markAsTouched()"> <!-- angular8: changed to markAsTouched() because setting touched=true was read-only (remove this comment) -->
								<label for="type">Type</label>
								<span *ngIf="!fc_type.valid"
									class="float-right"
									[ngClass]="{
										'text-danger': !content_form.pristine && fc_type.touched && fc_type.hasError('required'),
										'text-gray-500': fc_type.untouched && fc_type.hasError('required')
									}">
									<small><i>required</i></small>
								</span>
								<app-avia-selector #type
									[ngClass]="{ 'is-custom-invalid': fc_type.invalid && fc_type.touched }"
									[passed_data]="content_types"
									[options]="{type: 'passed', textbox: true, id_name: 'id', passed_data_selector_title: 'Select Content Type'}"
									(done)="content_form.patchValue({'type': $event.selected[0]?.id});"
								></app-avia-selector>
							</div>
							<!-- Published Date -->
							<div class="form-group">
								<label for="published_date">Published Date</label>
								<div>
									<input #published_date
										class="published-date"
										formControlName="published_date"
										placeholder="Date"
										type="date"
									>
								</div>
							</div>
							<!-- Topics -->
							<div class="form-group" (click)="fc_groups.markAsTouched()">
								<label for="groups">Appears In</label>
								<span *ngIf="!fc_groups.valid"
									class="float-right"
									[ngClass]="{
										'text-danger': !content_form.pristine && fc_groups.touched && fc_groups?.hasError('minLengthArray'),
										'text-gray-500': content_form?.untouched && fc_groups?.hasError('minLengthArray')
									}">
									<small><i>requires at least one topic appearance</i></small>
								</span>
								<app-avia-form-search-topics
									formControlName="groups"
									ngDefaultControl
									[multi_select]="true"
									[ng_class]="{ 'is-custom-invalid': fc_groups.invalid && fc_groups.touched }"
									[prompt_string]="'Link topics to new content'"
									[validation]="[{}]"
								></app-avia-form-search-topics>
							</div>
							<!-- Summary -->
							<div class="form-group">
								<label for="description">Summary</label>
								<span *ngIf="!content_form.controls.description.valid"
									class="float-right"
									[ngClass]="{
										'text-danger': !content_form.pristine && fc_description.touched && fc_description.hasError('required'),
										'text-gray-500': content_form.untouched && fc_description.hasError('required')
									}">
									<small><i>required</i></small>
								</span>
								<div class="w-100">
									<textarea formControlName="description" rows="4" class="w-100 description p-2" placeholder="Summary"></textarea>
								</div>
							</div>

							<!-- Buttons -->
							<div class="form-group d-flex justify-content-center my-5">
								<button class="btn btn-white border mr-2" type="button" (click)="revert()">Revert</button>
								<button
									class="btn btn-primary ml-2"
									type="submit"
									[disabled]="content_form.status !== 'VALID' || (file_or_link_tabs.active_tab === 1 && !fc_produced_by.value)"
									[ngClass]="{'no-pointer': content_form.status !== 'VALID' || (file_or_link_tabs.active_tab === 1 && !fc_produced_by.value)}"
								>ADD</button>
							</div>
						</form>

						<!-- DEBUGGING DISPLAY -->
						<span *ngIf="false">
							<!-- Display the form values and state during development -->
							<p>Form value: {{ content_form.value | json }}</p>
							<p>Form status: {{ content_form.status | json }}</p>
							<p>Pristine:<span class="text-uppercase">{{ content_form.pristine }}</span></p>
							<p>Touched:<span class="text-uppercase">{{ content_form.touched }}</span></p>
						</span>
					</div>
				</div>
			</div>
		</div>
	</avia-filedrop>
</ng-template>

<!-- === ACTIVATION BUTTONS === -->
<div (click)="open()" *ngIf="!static_page">
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>

	<div class="d-flex" *ngIf="SuppliedAddBtn.children.length === 0">
		<i class="material-icons" data-toggle="tooltip" placement="bottom" ngbTooltip="Add Content">add_circle</i>
	</div>
</div>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
