import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { AVIAConnectService } from './../../avia-connect.service';
import { AuthGuardService } from './../../auth-guard.service';
import * as _ from 'lodash';

// This represents the Feedback Page /feedback
// @autodoc false
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  previousUrl: string = "";

  constructor( private router: Router, private route: ActivatedRoute, private authGuard: AuthGuardService, private aviaService: AVIAConnectService ) {  }

  ngOnInit() {
    this.previousUrl = _.clone( this.authGuard.previousUrl );
    console.info( "WELCOME TO FEEDBACK! on close, we will take you back to: " + this.previousUrl );
  }

  async getFeedbackFormTemplateLoader() {
    let default_template = FeedbackComponent.hardCodedTemplate()[0];

    // pull the template from backend, change state when done
    let r = await this.aviaService.xhrAuth( "GET",
      "/feedback/templates?name=" + encodeURIComponent( 'site feedback' ),
      { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    );

    return r.body.length > 0 ? r.body[0] : default_template;

    // use hard coded template:
    //return default_template;
  }

  // useful for development / rapid iteration...
  static hardCodedTemplate() : Object {
    // pasted from postman, same format...
    return [
      {
        "template": {
          "questions": [
            {
              "data": [
                { "id": 1,  "txt": "Unlikely" },
                { "id": 2,  "txt": "" },
                { "id": 3,  "txt": "" },
                { "id": 4,  "txt": "" },
                { "id": 5,  "txt": "" },
                { "id": 6,  "txt": "" },
                { "id": 7,  "txt": "" },
                { "id": 8,  "txt": "" },
                { "id": 9,  "txt": "" },
                { "id": 10, "txt": "Likely" }
              ],
              "name": "LikelyToRecommend",
              "type": "scale",
              "title": "How likely would you be to recommend AVIA Connect to a collegue?",
              "result": "",
              "isRequired": true
            },
            {
              "data": {
                "text": "Choose Category",
                "items": [
                  { "id": 1, "name": "Compliment" },
                  { "id": 2, "name": "Problem - Technical Problem with AVIA Connect" },
                  { "id": 3, "name": "Help - Need help using AVIA Connect" },
                  { "id": 4, "name": "Suggestion - Feature Request" },
                  { "id": 5, "name": "Other - General Feedback" }
                ],
                "active": [
                  { "id": 1 }
                ]
              },
              "name": "Category",
              "type": "selector",
              "title": "If you have specific feedback, please select a category below:",
              "result": "",
              "isRequired": true
            },
            {
              "data": {
                "placeholder": "Enter feedback"
              },
              "name": "Freeform",
              "type": "textbox",
              "title": "Please enter feedback below and press send:",
              "result": "",
              "isRequired": false
            }
          ]
        },
        "id": 1,
        "name": "site feedback",
        "modified": "2017-07-17T15:59:32.000Z",
        "created": "2017-07-17T00:30:39.000Z"
      }
    ];
  }

  done() {
    if (this.previousUrl === undefined || this.previousUrl.match( /\/feedback/ )) {
      this.router.navigateByUrl( '/' );
    } else
      window.history.back(); // this fixes the issue where previousURL has lots of queryParams, etc...
  }
}
