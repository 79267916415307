import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-signup-landing',
  templateUrl: './signup-landing.component.html',
  styleUrls: ['./signup-landing.component.scss']
})
export class SignupLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
