<!-- The Search Box -->
<app-avia-topics-search
	*ngIf="!disabled && (multi_select || (!multi_select && selected_list.length === 0))"
	class="w-100"
	[ngClass]="ng_class"
	[show_no_matches]="show_no_matches"
	[recommendations]="recommendations"
	[options]="options"
	(done)="updateTopics($event)"
></app-avia-topics-search>
<ng-container *ngFor="let item of validation">
	<small *ngIf="item.condition" class="form-text text-danger">{{item.message}}</small>
</ng-container>

<!-- The List -->
<ng-container *ngIf="selected_list.length > 0">
	<div *ngFor="let item of selected_list" class="pill-list">
		<div *ngIf="item.name" class="pill-list-body">
			<div class="pill-list-text">
				<i
					class="material-icons"
					[ngClass]="{
						'text-blue-dark': item?.type === 1,
						'text-orange-dark': item?.type === 2,
						'text-teal-dark': item?.type === 3,
						'text-violet-dark': item?.type === 4,
						'text-green-dark': item?.type === 5,
						'text-red-dark': item?.type === 6
					}"
				>lightbulb_outline</i>{{ item.name }}</div>
			<i *ngIf="!disabled" class="pill-list-close material-icons" (click)="updateTopics({'action':'delete', 'data':item, 'type': undefined, 'values':[item.id]})">close</i>
		</div>
	</div>
</ng-container>
