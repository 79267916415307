import { Component, OnInit } from '@angular/core';
import { User } from '../../../../../class';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OnboardingService } from '../../onboarding.service';
import { Subscription } from 'rxjs';
import { AviaImageCropComponent } from 'src/app/_components/avia-image-crop/avia-image-crop.component';

@Component({
  selector: 'app-onboarding-profile-picture-form',
  templateUrl: './onboarding-profile-picture-form.component.html',
  styleUrls: ['./onboarding-profile-picture-form.component.scss']
})
export class OnboardingProfilePictureFormComponent implements OnInit {
  readonly VERBOSE: boolean = false;

  data: User;
  picture_form:   FormGroup;
  editing_avatar: boolean  = false;
  imagecrop;

  nav_sub: Subscription;

  constructor(public sob: OnboardingService, private fb: FormBuilder) {
    this.nav_sub = this.sob.navClick.subscribe(e => {
      if(e.next) {
        this.onSubmit();
      }
    });
    this.createForm();
  }

  ngOnInit() {
    this.data = this.sob.user;
    this.setupFormData();
  }

  onSourceImageChange( e ) {
    this.sob.form_valid = false;
  }

  createForm(): void {
    this.picture_form = this.fb.group({
      avatar: [null]
    });
  };

  setupFormData(): void {
    let common_fields = {
      avatar: this.data.avatar ? this.data.avatar : null
    };
    this.picture_form.reset( common_fields );
    this.picture_form.markAsUntouched();
    this.picture_form.markAsPristine();
    this.sob.form_valid = this.picture_form.valid;
  };

  onChangeAvatar(e) {
    this.picture_form.patchValue({avatar: e.link});
    this.picture_form.controls.avatar.markAsDirty();
    this.picture_form.controls.avatar.markAsTouched();
    this.sob.form_valid = true;

    this.sob.editing_avatar = false;
    this.picture_form.controls.avatar.setErrors(null)
  }

  async onSubmit() {
    if (this.picture_form && this.picture_form.valid && this.picture_form.touched) {
      await this.sob.updateUser(this.picture_form.value);
      this.nav_sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if(this.nav_sub) this.nav_sub.unsubscribe();
    this.sob.form_valid = false;
  }
}
