
<div class="d-flex justify-content-between reaction-item align-items-center">
	<div>
		<div *ngIf="like_count > 0" class="post-reactions-container d-flex align-items-center">
			<span class="material-icons md-10 blue-thumb-icon center-btn-text"> thumb_up </span>
			<div class="pl-1 q-and-a-default-button font-size-12">{{like_count}}</div>
		</div>
	</div>

	<div *ngIf="comment_count > 0" class="q-and-a-default-button pointer button-font-size" (click)="commentsClicked.emit()">
		{{comment_count}} {{comment_text}}{{ comment_count > 1 ? 's' : '' }}
	</div>
</div>

