// core angular
import { Component, OnInit, OnDestroy } from '@angular/core';

// node modules

// avia app
import { AVIAConnectService } from './../../avia-connect.service';
import { environment } from '../../../environments/environment';
import { AuthGuardService } from './../../auth-guard.service';
import * as _ from 'lodash';

// TO DISABLE:  see AVIAErrorHandlerComponent in app.module.ts, and comment it out there.  Dont forget to uncomment it before checking in.
// THERE IS A KNOWN ISSUE when HTML template spews 100's of errors
//    angular2 becomes unresponsive to *ngIf changes, ngIfs will _add_ but not _delete_ from the DOM, so this crash screen will persist!
@Component({
  selector: 'app-crash-screen',
  templateUrl: './crash-screen.component.html',
  styleUrls: ['./crash-screen.component.scss']
})
export class CrashScreenComponent implements OnInit, OnDestroy {
  expanded: boolean = false;
  VERBOSE: boolean = false;
  isOpen: boolean = false;
  isProduction: boolean = environment.production;
  previousUrl: string = "";

  constructor( public aviaService: AVIAConnectService, private authGuard: AuthGuardService ) {
    this.isOpen = true;
  }

  ngOnInit() {
    this.previousUrl = _.clone( this.authGuard.previousUrl );
    console.info( "WELCOME TO CRASH REPORTER! on refresh, we will take you to: " + this.previousUrl );
    this.VERBOSE && console.log('ngOnInit');
    this.VERBOSE && console.log('crashed ' + this.aviaService.crashed ? this.aviaService.crashed : 'not defined' );
    this.VERBOSE && console.log('crash count down ' + this.aviaService.crashCountDown ? this.aviaService.crashCountDown : 'not defined' );
    window.scrollTo(0,0);
  }

  /*
  refresh(){
    //window.location.href = this.aviaService.crash.goTo; // doesn't seem to have the route params.
    //window.location.reload(); // doesn't work when .html template is spewing 1000's of errors
    window.location.href = window.location.href; // seems to work more often than reload(), but can still fail to function
    this.VERBOSE && console.log(window.location.href);
  }
  */

  /*
  expand() {
    this.expanded = !this.expanded;
    this.VERBOSE && console.log( "expanded " + this.expanded );
  }
  */

  /*
  onClose() {
    this.isOpen = false;
    //this.aviaService.crashed = false;
    this.VERBOSE && console.log( "closing crash screen of death aviaService.crashed == " + this.aviaService.crashed );
  }
  */

  ngOnDestroy() {
    this.VERBOSE && console.log('ngOnDestroy');
  }

  goBack() {
    let url = this.previousUrl || "/start";
    window.location.assign(url);
  }

}
