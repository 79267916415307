import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AccessKey } from '../../../../class';


@Component({
  selector: 'app-avia-connection-card',
  templateUrl: './avia-connection-card.component.html',
  styleUrls: ['./avia-connection-card.component.scss']
})
export class AviaConnectionCardComponent {
  @Input() user;
  @Input() topics;
  @Input() akey: AccessKey = new AccessKey();
  @Input() card_buttons = {
    viewPro: false,
    discon: false,
    accept: false,
    ignore: false,
    cancel: false,
    remove: false,
    send: false,
    priorities_qview: false,
    inventories_qview: false
  };

  @Input() priorities;
  @Input() inventories;

  @Output() conAccept: EventEmitter<any> = new EventEmitter();
  @Output() conIgnore: EventEmitter<any> = new EventEmitter();
  @Output() conCancel: EventEmitter<any> = new EventEmitter();
  @Output() onRemove:  EventEmitter<any> = new EventEmitter();


  constructor() { }

  accept(){
    this.conAccept.emit();
  }

  ignore(){
    this.conIgnore.emit();
  }

  cancel(){
    this.conCancel.emit();
  }

  remove(d) {
    let obj = {
      action: 'delete',
      type: 'card',
      values: [d.id],
      data: d
    };
    this.onRemove.emit(obj);
  }

}
