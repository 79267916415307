import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { fadeIn, fadeOut, rotateIn, rotateOut } from 'ng-animate';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';

import { AVIAConnectService } from '../../avia-connect.service';
import {
  AccessKey,
  Community,
  Content_Source,
  E_Modal_Operating_Mode,
  E_SomethingHappened,
  KnowledgeCard,
  Workspace,
} from '../../../class';


@Component({
  selector: 'app-universal-add',
  templateUrl: './universal-add.component.html',
  styleUrls: ['./universal-add.component.scss'],
  animations: [
    trigger('fadeIn', [transition(':enter', useAnimation(fadeIn, { params: { timing: .5 } }))]), //:enter is an alias for void => *
    trigger('fadeOut', [transition(':leave', useAnimation(fadeOut, { params: { timing: .25 } }))]), //:leave is an alias for * => void
    trigger('rotateIn', [transition(':enter', useAnimation(rotateIn, { params: { timing: .25 } }))]), //:enter is an alias for void => *
    trigger('rotateOut', [transition(':leave', useAnimation(rotateOut, { params: { timing: .25 } }))]), //:enter is an alias for void => *
  ],
})
export class UniversalAddComponent implements OnInit, OnDestroy {
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template

  // Animations
  fadeIn: any;
  fadeOut: any;
  rotateIn: any;
  rotateOut: any;

  // Add Topic
  new_topic_blank: KnowledgeCard = new KnowledgeCard();
  @ViewChild('TopicAddModal', { static: false }) topic_add_modal;

  // Add Activity
  new_activity_blank: Workspace = new Workspace();
  @ViewChild('ActAddModal', { static: false }) act_add_modal;

  // Add Community Modal
  @ViewChild('CommunityAddModal', { static: false }) cm_add_modal;

  // Add Content Modal
  @ViewChild('ActContentModal', { static: false }) add_content_modal;

  // Add Content Logo Modal
  new_content_logo: Content_Source = new Content_Source();
  @ViewChild('ContentLogoModal', { static: false }) content_logo_modal;

  // Add Topic Modal
  private saving_topic: boolean = false;
  public show_archives: boolean = false;
  public show_reports: boolean = false;

  private params_sub: Subscription;

  // Invite Member Modal
  @ViewChild('UserInviteModalComponent', { static: false }) user_invite_modal;

  // Add Orgs Modal
  @ViewChild('AddOrgModal', { static: false }) add_org_modal;

  // Report Issue Modal

  // Submit Feedback Modal

  // ACCESS KEYS
  public admin_key: AccessKey = new AccessKey();
  public activity_add_key: AccessKey = new AccessKey();
  public content_add_key: AccessKey = new AccessKey();
  public is_avia_key: AccessKey = new AccessKey();
  public org_demo_key: AccessKey = new AccessKey();
  public topic_add_key: AccessKey = new AccessKey();
  public community_add_key: AccessKey = new AccessKey();

  private VERBOSE: boolean = false;


  constructor(public aviaService: AVIAConnectService, public router: Router, public route: ActivatedRoute) {
    // Update variables on route change
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.show_archives = this.routeHasArchives();
        this.show_reports = this.routeHasReports();
      }
    });
  }

  from_new_app = false;
  ngOnInit() {
    this.saving_topic = false;
    this.initUserAccess();
    this.show_archives = this.routeHasArchives();

    this.params_sub = this.route.queryParams.subscribe(params => {
      if (!params || !params.open) return
      setTimeout(() => { this.aviaService.universal_add_active = true })
      if (params.from_new_app) this.from_new_app = true;

      setTimeout(() => {
        switch (params.open) {
          case 'topic_add_modal': {
            this.topic_add_modal.open();
            this.topic_add_modal.cancel.subscribe(() => {
              this.custom_close();
            })
            this.topic_add_modal.save.subscribe((data) => {
              this.from_new_app && this.submitAddTopic(data)
            });
            break;
          }
          // case 'act_add_modal': {
          //   this.act_add_modal.open();
          //   this.act_add_modal.cancel.subscribe(() => {
          //     this.custom_close();
          //   })
          //   this.act_add_modal.save.subscribe((data) => {
          //     this.from_new_app && this.submitAddActivity(data)
          //   })
          //   break;
          // }
          case 'cm_add_modal': {
            this.cm_add_modal.open();
            this.cm_add_modal.cancel.subscribe(() => {
              this.custom_close();
            })
            this.cm_add_modal.save.subscribe((data) => {
              this.from_new_app && this.submitAddCommunity(data)
            })
            break;
          }
          case 'add_content_modal': {
            this.add_content_modal.open();
            this.add_content_modal.cancel.subscribe(() => {
              this.custom_close();
            })
            break;
          }
          case 'content_logo_modal': {
            this.content_logo_modal.open();
            this.content_logo_modal.cancel.subscribe(() => {
              this.custom_close();
            })
            this.content_logo_modal.save.subscribe((data) => {
              this.from_new_app && this.submitAddContentLogo(data)
            })
            break;
          }
          case 'user_invite_modal': {
            this.user_invite_modal.open();
            this.user_invite_modal.cancel.subscribe(() => {
              this.custom_close();
            })
            break;
          }
          case 'add_org_modal': {
            this.add_org_modal.open();
            this.add_org_modal.cancel.subscribe(() => {
              this.custom_close();
            })
            break;
          }
          default: this.aviaService.goToNewApp();
        }
      })
    });
  }

  custom_close() {
    if (this.from_new_app) {
      this.aviaService.goToNewApp();
    }
    this.aviaService.universal_add_active = false;
  }


  // NOTE: Please keep this component's 'submit types' in sync with class::UniversalAdd_Types

  async submitAddTopic(topic) {
    try {
      if (this.saving_topic) return;
      this.saving_topic = true;

      let response = await this.aviaService.addKMcard(topic);
      response = response.body;

      this.aviaService.somethingHappened.emit({ 'type': E_SomethingHappened.TOPIC_ADDED, 'data': response });

      this.router.navigate(['/intelligence/km/graph'], { queryParams: { id: response.card.id } });
    } catch (err) {
      console.error('submitAddTopic ERROR:', err);
      this.aviaService.notify('warning', 'Warning!', 'Topic not added.', { showConfirmButton: true, timer: null });
    }
    this.saving_topic = false;
    this.aviaService.universal_add_active = false;
  };

  async submitAddCommunity(community: Community) {
    this.VERBOSE && console.log('universal-add::submitAddCommunity called with: ', community);

    try {
      let response = await this.aviaService.createCommunity(community);
      if (response.status != 200) throw Error('ERROR - Community not added!');

      this.aviaService.somethingHappened.emit({ 'type': E_SomethingHappened.COMMUNITY_ADDED, 'data': response.body });

      this.aviaService.universal_add_active = false;
      this.router.navigate(['/communities']);
    } catch (err) {
      console.error('universal-add::submitAddCommunity ERROR:', err);
      this.aviaService.notifyFailure('Community not added.');
    }
  };

  async submitAddActivity(new_act: Workspace) {
    console.log("adding")
    this.VERBOSE && console.log('activities::submitAddActivity called with: ', new_act);

    delete new_act['id'];
    try {
      let response = await this.aviaService.addActivity(new_act);
      response = response['body'];

      this.aviaService.somethingHappened.emit({ 'type': E_SomethingHappened.ACTIVITY_ADDED, 'data': response });

      this.aviaService.notifySuccess('Group Added');
      this.router.navigate(['/activities/' + response['result']['id']]);
    } catch (err) {
      console.error('submitAddActivity ERROR:', err);
      this.aviaService.notifyFailure('Group not added.');
    }
  };

  async submitAddContentLogo(content_source) {
    this.VERBOSE && console.log('contentLogo::submitAddContentLogo called with: ', content_source);
    try {
      let response = (await this.aviaService.addContentLogo(content_source)).body;
      if (response.err || response.err_msg) {
        throw Error(response.err_msg);
      } else {
        this.aviaService.somethingHappened.emit({ 'type': E_SomethingHappened.CONTENT_LOGO_ADDED, 'data': response });
        this.aviaService.notifySuccess('Content logo successfully added.');
        this.aviaService.universal_add_active = false
      }
    } catch (err) {
      console.error('submitAddContentLogo ERROR:', err);
      this.aviaService.notifyFailure(`Logo not added.\n${err}`);
      await this.aviaService.undoUploadToS3(content_source.source, 'content-logo');
    }
  };

  // SUB NAV ROUTING
  async navigateToArchive() {
    if (this.router.url.includes("communities")) {
      this.router.navigate(['/communities/archive']);
    }
    if (this.router.url.includes("intelligence/km")) {
      this.router.navigate(['/intelligence/km/archive'])
    }
  }

  routeHasArchives() {
    let current_url = this.router.url;
    if (current_url.includes("communities")) return true;
    if (current_url.includes("intelligence/km")) return true;
    return false;
  }

  async navigateToReports() {
    if (this.router.url.includes("intelligence/cl")) {
      this.router.navigate(['/intelligence/cl/reports'])
    }
    if (this.router.url.includes("intelligence/km")) {
      this.router.navigate(['/intelligence/km/reports'])
    }
    if (this.router.url.includes("/sc")) {
      this.router.navigate(['/sc/reports'])
    }
    if (this.router.url.includes("/ws")) {
      this.router.navigate(['/ws/reports'])
    }
  }

  async navigateToVisualizer() {
    if (this.router.url.includes("intelligence/km")) {
      this.router.navigate(['/intelligence/km/visualize'])
    }
  }
  routeHasReports() {
    let current_url = this.router.url;
    if (current_url.includes("intelligence/cl")) return true;
    if (current_url.includes("intelligence/km")) return true;
    if (current_url.includes("sc/")) return true;
    if (current_url.includes("ws/")) return true;
    return false;
  }

  ngOnDestroy() {
    this.params_sub.unsubscribe();
  }

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  * USER ACCESS
  * The following contain Objects of booleans - e.g., { c: true, r: true, w: true, d: true }
  * How to use in the HTML *ngIf="keychain_cm.core.r"
  * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  initUserAccess(resource_desc: Object = {}): void {
    this.aviaService.getSessionSupport().then(session => {
      this.topic_add_key = this.aviaService.hasAccess(session, 'km', 'card', 'c', resource_desc);
      this.content_add_key = this.aviaService.hasAccess(session, 'cl', 'card', 'c', resource_desc);
      this.activity_add_key = this.aviaService.hasAccess(session, 'act', 'core', 'c', resource_desc);
      this.activity_add_key = this.aviaService.hasAccess(session, 'act', 'core', 'c', resource_desc);
      this.org_demo_key = this.aviaService.hasAccess(session, 'np', 'org_demo', 'rw', session.org.rd);
      this.community_add_key = this.aviaService.hasAccess(session, 'admin', 'feature', 'e', resource_desc);
      this.admin_key = this.aviaService.hasAccess(session, 'admin', 'feature', 'e', resource_desc);
      this.is_avia_key = this.aviaService.hasAccess(session, 'avia', 'org_membership', 'r', resource_desc);
    });
  };

}
