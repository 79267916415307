<div class="page container-fluid">
	<div class="d-flex align-items-center headerblock row bg-gray-100">
			<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
			<span class="ml-auto backlink pointer" (click)="onCancel()">
				<i class="mr-4 material-icons" style="font-size: 24px; vertical-align: top;">close</i>
			</span>
	</div>

	<!--
	<div *ngIf='data !== undefined'>Select a preset image:</div>
	<app-avia-selector *ngIf='data !== undefined' class="selector-click"
		[active_data]="data.default === 'active' ? data.active : []"
		[passed_data]="data.items"
		[options]="{textbox: true, type: 'passed', passed_data_selector_title: data.placeholder, allow_deselection: false}"
		(init)="$event['selected'][0]"
		(done)="onSubmit( $event['selected'][0] )"
	></app-avia-selector>
	-->

	<div class="subpage row">
		<div class="col-12">
			<avia-image-crop
				[options]="_options"
				(onInit)="setImageCrop($event)"
				(onSave)="onSave($event)"
				(onSourceImageChange)="onSourceImageChange($event)"
			></avia-image-crop>
		</div>
	</div>
</div>
