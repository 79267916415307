import { OnboardingService } from '../../../onboarding.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-solco-keys',
  templateUrl: './onboarding-solco-keys.component.html',
  styleUrls: ['./onboarding-solco-keys.component.scss']
})
export class OnboardingSolcoKeysComponent implements OnInit {

  constructor(public sob: OnboardingService) { }

  ngOnInit() {
    this.sob.form_valid = true;
  }

}
