import { NgbModal, NgbModalRef, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { User, E_SomethingHappened, Session } from '../../../../class';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AVIAConnectService } from 'src/app/avia-connect.service';
import { pullAllBy } from 'lodash';

@Component({
  selector: 'app-avia-connections-modal',
  templateUrl: './avia-connections-modal.component.html',
  styleUrls: ['./avia-connections-modal.component.scss']
})
export class AviaConnectionsModalComponent implements OnInit {
  @Input() fullscreen: boolean = true;
  @Input() static_page: boolean = false;

  user: User;
  session: Session;
  suggested_connections: User[] = [];

  // MODAL STUFF
  @ViewChild('AviaConnectionsModal', { static: false }) content: NgbModal;
  modal_ref: NgbModalRef;

  constructor(public aviaService: AVIAConnectService, private modalService: NgbModal) { }

  async ngOnInit() {
    this.session = await this.aviaService.getSessionSupport(true);
    this.user = this.session.user;

    let topics;
    if(this.user.interests_topics && this.user.interests_topics.length > 0){
      topics =  this.user.interests_topics
    } else {
      let topics_res = await this.aviaService.getKMCardsPopular()
      topics = topics_res.topic_ids
    }
    let topics_users_res = await this.aviaService.getTopicsUsers({topic_ids: topics, limit: 100});
    if (topics_users_res.status == 200 && topics_users_res.body && topics_users_res.body.users) {
      this.suggested_connections = topics_users_res.body.users;
      this.suggested_connections = pullAllBy(this.suggested_connections, [{...this.user, 'id': this.user.id}], 'id')
    }
  }

  close() {
    this.aviaService.somethingHappened.emit({
      type: E_SomethingHappened.NEWSFEED_REFRESHED,
      data: { quick_links: true, action_tiles: true, suggested: true }
    });
    this.modal_ref.close();
  };

  // NOTE: You can either set the Inputs 'data' and 'data_fallback' first, then call this method
  // OR, simply supply them with the calling of this method
  async open( data = undefined ) {
    let options: NgbModalOptions = {
      backdrop: 'static',
      //scrollable: true, // Coming in @ng-bootstrap/ng-bootstrap 5.0.0
      size: 'lg',
    };
    options.windowClass = 'avia-connections-modal';
    if (this.fullscreen) {
      options.windowClass += ' avia-modal-fullscreen';
    } else {
      options.windowClass += ' avia-modal-xl';
    }
    this.modal_ref = this.modalService.open( this.content, options );
  };

}
