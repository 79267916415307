<div
	class="py-2 px-1 px-sm-2 border-top-1 w-100 text-center"
	[ngClass]="{
		'bg-blue-light': (color === 'info'),
		'bg-orange-dark': (color === 'warning'),
		'bg-red-dark': (color === 'error'),
		'pointer': use_pointer
	}"
	>
	<div *ngIf='type === 0' class="m-auto text-white ">
		<span *ngIf="timeleft > 0">Attempting to Reconnect in {{ timeleft }} {{ timeleft > 1 ? 'seconds' : 'second' }}...<strong class="pointer" (click)="tryNow()">Try Now?</strong></span>
		<span *ngIf="timeleft <= 0">Reconnecting...</span>
		<ng-container *ngTemplateOutlet="showDismiss"></ng-container>
	</div>
	<div *ngIf='type === 1' class="m-auto text-white">
		<span [innerHTML]="msg"></span>
		<ng-container *ngTemplateOutlet="showDismiss"></ng-container>
	</div>
</div>

<!-- DISMISS BUTTON -->
<ng-template #showDismiss>
	<ng-container *ngIf="show_dismiss">
		<span class="d-inline d-md-none"><br></span>
		<div class="btn btn-sm btn-dismiss float-md-right mt-4 mt-md-0">Dismiss</div>
	</ng-container>
</ng-template>
