import { AVIAConnectService } from 'src/app/avia-connect.service';
import { Color_Library } from './../../../class';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avia-crisscross',
  templateUrl: './avia-crisscross.component.html'
})
export class AviaCrisscrossComponent implements OnInit {
  /*
    Note: if you want to manipulate the width of the criss cross, you
    will have to do so via parent components. e.g.
    HTML
    <div class="parent position-relative">
      ...
      <div class="position-absolute criss-cross-container">
        <app-avia-crisscross class="criss-cross"></app-avia-crisscross>
      </div>
    </div>

    CSS
    .criss-cross-container {
      bottom: 24px;
      left: 16px;
      width: 100%;   // If you have padding on your parent, you will have to adjust width to match
      .criss-cross {
        width: 100%
      }
    }
  */

  @Input() color:string = Color_Library.primary;

  constructor(public aviaService: AVIAConnectService) { }

  ngOnInit() {
  }

}
