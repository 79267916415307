<div class="avia-modal-full-screen avia-modal-full-screen-inverse" [hidden]="!isOpen" (click)='aviaService.crashEnableReload = false'>
  <div class="page container-fluid">
    <div class="d-flex align-items-center headerblock row">
			<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
			<span class="ml-auto backlink pointer" (click)="isOpen = false">
				<i class="mr-4 material-icons md-24" style="vertical-align: top;">close</i>
			</span>
    </div>

    <div class="row subpage">
      <div class="col-md-8 mx-auto text-center">
        <div class="img-wrapper mb-6 mt-7">
          <img class="cloud" src="/assets/cloud-sad.png">
        </div>
        <h2 id="ohdear" class="mb-6">Oh Dear!</h2>
        <p class="font-body">
          <span>Something went wrong.  Please try again in a moment.<BR>If the problem persists, please <a class='ptr accent' href="/feedback">report the problem</a>.</span>
        </p>
        <p [hidden]='!aviaService.crashEnableReload' class="mb-6 font-body">
          <span *ngIf="aviaService.crashCountDown > 0">Attempting to Reconnect in {{ aviaService.crashCountDown }} {{ aviaService.crashCountDown > 1 ? 'seconds' : 'second' }}... click the button below to try now</span>
          {{aviaService.crashCountDown}}
        </p>
        <div class="mb-4">
					<button class="btn btn-success" (click)="goBack()">Reconnect</button>
        </div>
        <div class="mb-6 font-body">
          <span>(Doesn't respond?  You may have to manually refresh the browser)</span>
        </div>

        <div class="header-under-dash mb-6 mt-6">
          <div class="dash"></div>
        </div>
        <p class="font-body">
          <span>{{ aviaService.crash.message }}</span>
          <span class="pointer arrows">
            <i class="material-icons" [hidden]="!expanded" (click)="expanded = !expanded; aviaService.crashEnableReload = false;">arrow_right</i>
            <i class="material-icons" [hidden]="expanded"  (click)="expanded = !expanded; aviaService.crashEnableReload = false;">arrow_drop_down</i>
          </span>
        </p>
        <div class="error-box mb-6" [hidden]="!expanded" [innerHTML]="aviaService.crash.error"></div>
      </div>
    </div>
  </div>
</div>


<div class="alert alert-danger" [hidden]="isOpen" style="position:absolute; z-index:1000; bottom:0; right:0;" role="alert">
  <strong>CAUTION:</strong> app may be unstable now: please <a href="{{previousUrl}}" class="alert-link">refresh browser soon</a>
</div>
