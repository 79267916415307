import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MessagesService } from './messages.service';
import { AVIAConnectService } from './../avia-connect.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  @ViewChild("discussion_name", { static: true }) new_message: any;
  @ViewChild('create', { static: true }) createModal: NgbModal;
  @ViewChild('messages', { static: false }) messagesModal: NgbModal;
  modal_ref: NgbModalRef;
  modal_options: NgbModalOptions;
  header_top = '0';
  header_bottom = 'initial';
  margin_top = '0';
  _scrollLockerListener;
  _scrollInterval;
  open = false;
  constructor(private modalService: NgbModal, public aviaService: AVIAConnectService, private router: Router, public sm: MessagesService) {
  }

  from_new_app = false;
  as_node_ids = false;
  async ngOnInit() {
    clearInterval(this._scrollInterval);

  }

  ngOnDestroy() {
    this.sm.selectedDiscussion = null;
    this.sm.loadCommentGroupId = null;
    clearInterval(this._scrollInterval);
    this.checkToGoBackToNewApp();
  }

  checkToGoBackToNewApp() {
    if(this.from_new_app) {
      window.history.back();
    }
  }

  async createDiscussion($event) {
    let participants = [];
    for(let p of $event.participants) {
      participants.push(p.id);
    }
    let d = await this.sm.createDiscussion(participants, $event.name);
    this.sm.selectDiscussion(d);
  }

  selectDiscussion($event) {
    this.sm.selectDiscussion($event);
  }

  updateParticipants($event) {
    let participants = [];
    for(let p of $event.participants) {
      participants.push(p.id);
    }
    this.sm.updateDiscussion(this.sm.selectedDiscussion.comment_group_id, {participants:participants, name:$event.name});
  }

  muteConversation() {
    this.sm.updateDiscussion(this.sm.selectedDiscussion.comment_group_id, {is_muted:1});
    this.aviaService.notify(
      "success",
      "Muted",
      "This conversation is now muted. You will no longer be notified about new messages. Unmute to turn on notifications.",
      {
        showCancelButton: false,
        confirmButtonText: 'Okay'
      }
    );
}

  unmuteConversation() {
    this.sm.updateDiscussion(this.sm.selectedDiscussion.comment_group_id, {is_muted:0});
  }

  getParticipantNames(discussion) {
    if(discussion.name) {
      return discussion.name;
    }

    let participantNames = [];
    if(discussion.other_participants.length === 1) {
      return discussion.other_participants[0].firstname + " " + discussion.other_participants[0].lastname;
    }
    let session = this.aviaService.session;
    discussion.other_participants.forEach((p)=>{
      if(p.id !== session.user.id) {
        participantNames.push(p.firstname);
      }
    })
    return participantNames.join(", ");
  }

  async openModal(user_id = null) {
    if(this.sm.modal_ref ) this.sm.modal_ref.close();
    let options: NgbModalOptions = {
      'backdrop': 'static', size: 'lg', windowClass: 'avia-modal-fullscreen'
    };
    this.sm.modal_ref = this.modalService.open(this.messagesModal, options);
    this.open = true;

    if(user_id) {
      let d = await this.sm.createDiscussion(user_id, null, null, this.as_node_ids);
      this.sm.selectDiscussion(d);
    }
  }
  closeModal() {
    this.open = false;
    this.sm.selectedDiscussion = null;
    this.sm.loadCommentGroupId = null;
    clearInterval(this._scrollInterval);
    let url = window.location.href;
    url = url.replace('messages=open', 'messages=close');
    history.replaceState({}, null, url);
    this.sm.modal_ref.close();
    this.checkToGoBackToNewApp()
  }
  repositionHeaderFocus() {
    //safari's mobile soft keyboard needs some extra help...
    if(this.aviaService.mobile_mode && this.aviaService.deviceInfo.browser === 'safari' && this.aviaService.deviceInfo.device === 'iphone')
    {
      this.margin_top = "350px";
      this.header_bottom = (window.innerHeight - 56) + "px";
      this.header_top = 'initial';
      //this.header_padding_top = '300px';
      let lockposition = window.scrollY;
      this._scrollLockerListener = (e)=>{
        window.scrollTo(0,lockposition);
      }
      this._scrollInterval = setInterval(this._scrollLockerListener,10);
      //window.addEventListener('touchmove',, { passive: false });
    }
  }
  repositionHeaderBlur() {
    if(this.aviaService.mobile_mode && this.aviaService.deviceInfo.browser === 'safari' && this.aviaService.deviceInfo.device === 'iphone')
    {
      clearInterval(this._scrollInterval);
      //window.removeEventListener('touchmove', this.preventDefault);
      this.header_bottom = "initial";
      this.header_top = '0px';
      this.margin_top = "0px";
    }
  }
}
