<app-avia-edit-modal
	[modalTitle]="'Edit Content'"
	[assetColor]="'gray'"
	[form]="content_form"
	(savesignal)="formElem.ngSubmit.emit()"
	(revertsignal)="revertForm()"
	(startEditing)="startEditing()"
	>

	<avia-filedrop (dragFileAccepted)="fileChosenFromBrowse($event.file); file = $event.file.name" [unlocked]="true" modal-body>
		<div class="form-group">
			<form [formGroup]="content_form" #formElem="ngForm" (ngSubmit)="onSubmit()">
				<div *ngIf="editing" class="column">

					<!-- NAME -->
					<div class="row my-4">
						<div class="col-6 font-weight-bold pb-2">Name</div>
						<div class="col-6 pb-2 text-right pb-2">
							<span *ngIf="!fc_name.valid"
								[ngClass]="{
									'text-danger': !content_form.pristine && fc_name.hasError('required'),
									'text-gray-500': content_form.pristine && fc_name.hasError('required')
								}">
								<small><i>required</i></small>
							</span>
						</div>
						<div class="col-12">
							<input type="text" class="form-control" formControlName="name" autocomplete="off">
						</div>
					</div>

					<!-- AUTHOR -->
					<div class="row my-4">
						<div class="col-12 font-weight-bold pb-2">Author</div>
						<div class="col-12">
							<input type="text" class="form-control" formControlName="author">
						</div>
					</div>

					<!-- TYPE -->
					<div class="row my-4">
						<div class="col-6 font-weight-bold pb-2">Type</div>
						<div class="col-6 text-right pb-2">
							<span *ngIf="!fc_type.valid"
								[ngClass]="{
									'text-danger': !content_form.pristine && fc_type.hasError('required'),
									'text-gray-500': content_form.pristine && fc_type.hasError('required')
								}">
								<small><i>required</i></small>
							</span>
						</div>

						<div class="col-12 d-inline-flex">
							<app-avia-selector
								[active_data]="[content?.type_obj]"
								[options]="{type: 'passed', textbox: false, id_name: 'id', passed_data_selector_title: 'Select Type'}"
								[passed_data]="content_types"
								(done)="updateContentType({'type': $event})"
							></app-avia-selector>
						</div>
					</div>

					<!-- PUBLISHED -->
					<div class="form-group">
						<label for="published_date">Published Date</label>
						<input #published_date
							formControlName="published_date"
							class="d-block published-date"
							placeholder="Date"
							type="date"
						>
					</div>

					<!-- DESCRIPTION -->
					<div class="row my-4">
						<div class="col-6 font-weight-bold pb-2">Summary</div>
						<div class="col-6 text-right pb-2">
							<span *ngIf="!fc_description.valid"
								[ngClass]="{
									'text-danger': !content_form.pristine && fc_description.hasError('required'),
									'text-gray-500': content_form.pristine && fc_description.hasError('required')
								}">
								<small><i>required</i></small>
							</span>
						</div>
						<div class="col-12">
							<textarea #description
								class="w-100 form-control unlocked-border h-100 rounded p-1"
								autosize
								rows="6"
								placeholder="{{ content?.description ? content?.description : 'enter a summary'}}"
								formControlName="description"
							></textarea>
						</div>
					</div>

					<!-- TOPICS -->
					<div class="row my-4">
						<div class="col-5 font-weight-bold pb-2">Topics</div>
						<div class="col-7 pb-2 text-right">
							<span *ngIf="!fc_groups.valid"
								[ngClass]="{
									'text-danger': !content_form.pristine && fc_groups.hasError('required'),
									'text-gray-500': content_form.pristine && fc_groups.hasError('required')
								}">
								<small><i>at least one topic is required</i></small>
							</span>
						</div>
						<div class="col-12">
							<app-avia-form-search-topics
								formControlName="groups"
								ngDefaultControl
								[multi_select]="true"
								[prompt_string]="'Select a Topic'"
								[validation]="[{}]"
							></app-avia-form-search-topics>
						</div>
					</div>

					<!-- Org produced toggle -->
					<div class="row my-4">
						<div class="col-12 pt-4 pt-md-0">
							<div class="mb-4">
								<app-avia-toggle
									[disabled]="!can_disable_org_content"
									[label_show]="false"
									[value]="fc_produced_by.value"
									(switched)="updateProducedBy($event)"
									>
									<label class="m-0 org-produced-label">Produced by {{aviaService.session.org.name}}</label>
								</app-avia-toggle>
								<div *ngIf="!can_disable_org_content">
									<small class="text-gray-600">
										<em>This looks like an upload. Only material produced by my organization can be uploaded. If not org produced, please provide a new link.</em>
									</small>
								</div>
							</div>

							<app-avia-tab-pills [nav_bar]="nav_bar"></app-avia-tab-pills>
							<!-- Link Tab -->
							<div [hidden]="nav_bar.active_tab !== 0 ? true : undefined" class="w-100 pb-2">
								<input #link
									id="link"
									formControlName="link"
									class="w-100"
									placeholder="Link Location"
									type="text"
									autocomplete="off"
								>
								<small class="form-text text-gray-500">
									<em>Links work best for large files, such as videos. For other files, uploading ensures your file doesn't get lost or moved.</em>
								</small>
							</div>

							<!-- Upload Tab -->
							<div [hidden]="nav_bar.active_tab !== 1 ? true : undefined">
								<div class="form-group text-center mt-2">
									<input #fileInput class="d-none" type="file" (change)="fileChosenFromBrowse($event.target.files[0]); fileInput.value = null"/>
									<div *ngIf="fc_produced_by.value" class="mt-4">
										<strong class="pointer text-primary" (click)="fileInput.click()">Choose a file</strong> or drag it on to the page.
									</div>
									<div *ngIf="!fc_produced_by.value">
										<span><em class="text-danger">All uploaded content must be produced by your organization. Select that option to enable upload. If not, please link to the content.</em></span>
									</div>
								</div>
								<p class="mt-4">
									<ngb-progressbar *ngIf="file && progress > 0" type="success" [value]="progress * 100" [animated]="true">{{progress | percent}}</ngb-progressbar>
								</p>
								<div *ngIf="progress === 0 && file" class="progress mb-2">
									<div class="progress-bar progress-bar-striped progress-bar-animated bg-green-dark w-100" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100"></div>
								</div>
							</div>

							<!-- Permissions -->
							<div *ngIf="membership_akey?.w && memberTypeArray && fc_produced_by.value" class="row pt-4">
								<div class="col-12">
									<div class="font-weight-bold pb-2">Who can see this content?</div>
									<div class="d-block d-md-inline">
										<div class="d-block d-md-inline">
											<input type="radio" [value]=0 formControlName="special_content" />
											<label class="pl-1 pr-4">User Types</label>
										</div>
										<div class="d-block d-md-inline">
											<input type="radio" [value]=1 formControlName="special_content" />
											<label class="pl-1 pr-4">Membership Types</label>
										</div>
										<div class="d-block d-md-inline">
											<input type="radio" [value]=2 formControlName="special_content" />
											<label class="pl-1 pr-4">Strategic Initiatives & Programs</label>
										</div>
									</div>
								</div>
								<!-- Org Type Sub-Perms -->
								<div *ngIf="show_orgtypes" class="col-12">
									<div class="row">
										<small class="pl-4 form-text text-gray-500">
											<em>Select which user types you would like to see this content. Only those selected will be able to access.</em>
										</small>
										<div class="font-weight-bold pb-0 pb-md-2 pt-2 pt-md-0 col-12 col-md-6">Selected User Types</div>
										<div *ngIf="fc_special_content.value == 0" class="col-12 col-md-6 pb-2 text-md-right">
											<small
												class="font-italic"
												[ngClass]="{
													'text-danger': !content_form.pristine && fc_org_type_arr.hasError('minLengthTruthyArray'),
													'text-gray-500': content_form.pristine || !fc_org_type_arr.hasError('minLengthTruthyArray')
												}"
											>at least one type is required</small>
										</div>
									</div>
									<ng-container *ngIf="(avia_org_types && avia_org_types.length) && (orgTypeArray && orgTypeArray.length)">
										<div *ngFor="let type of avia_org_types; let i=index" class="pb-2">
											<app-avia-form-checkbox
												ngDefaultControl
												[disabled]="fc_org_type_arr.disabled"
												[formControl]="orgTypeArray.controls[i]"
												[id]="type?.id"
												[label]="type?.name"
											></app-avia-form-checkbox>
										</div>
									</ng-container>
								</div>
								<!-- Membership Sub-Perms -->
								<div *ngIf="show_membership" class="col-12">
									<div class="row">
										<small class="pl-4 form-text text-gray-500">
											<em>Only health system members of AVIA. Specify which types below. "Adult" is the most common.</em>
										</small>
										<div class="font-weight-bold pb-0 pb-md-2 pt-4 pt-md-0 col-12 col-md-6">Selected Membership Types</div>
										<div *ngIf="fc_special_content.value == 1" class="col-12 col-md-6 pb-2 text-md-right">
											<small
												class="font-italic"
												[ngClass]="{
													'text-danger': !content_form.pristine && fc_member_type_arr.hasError('minLengthTruthyArray'),
													'text-gray-500': content_form.pristine || !fc_member_type_arr.hasError('minLengthTruthyArray')
												}"
											>at least one type is required</small>
										</div>
									</div>
									<ng-container *ngIf="(avia_member_types && avia_member_types.length) && (memberTypeArray && memberTypeArray.length)">
										<div *ngFor="let type of avia_member_types; let i=index" class="pb-2">
											<app-avia-form-checkbox
												ngDefaultControl
												[disabled]="fc_member_type_arr.disabled"
												[formControl]="memberTypeArray.controls[i]"
												[id]="type.id"
												[label]="type.name"
											></app-avia-form-checkbox>
										</div>
									</ng-container>
								</div>
								<!-- Programs Sub-Perms -->
								<div *ngIf="show_programs" class="col-12">
									<div class="row">
										<small class="pl-4 form-text text-gray-500">
											<em>Only health system members of AVIA with the specified strategic initiative(s).</em>
										</small>
										<div class="font-weight-bold pb-2 pt-4 pt-md-0 col-12 col-md-6">Selected Strategic Initiatives & Programs</div>
										<div *ngIf="fc_special_content.value == 2" class="col-12 col-md-6 pb-2 text-md-right">
											<small
												class="font-italic"
												[ngClass]="{
													'text-danger': !content_form.pristine && fc_programs.hasError('minLengthArray'),
													'text-gray-500': content_form.pristine || !fc_programs.hasError('minLengthArray')
												}"
											>at least one program or strategic initiative is required</small>
										</div>
									</div>
									<app-avia-form-search
										formControlName="programs"
										[data_list]="avia_member_focus"
										[selected_list]="content?.member_focus"
										[placeholder]="'Find a program or strategic initiative'"
									></app-avia-form-search>
								</div>
							</div>
						</div>
					</div>

				</div>

				<!-- DEBUGGING DISPLAY -->
				<span *ngIf="false">
					<!-- Display the form values and state during development -->
					<p>Form value: {{ content_form.value | json }}</p>
					<p>Form status: {{ content_form.status | json }}</p>
					<p>Pristine:<span class="text-uppercase">{{ content_form.pristine }}</span></p>
					<p>Touched:<span class="text-uppercase">{{ content_form.touched }}</span></p>
				</span>
			</form>
		</div>
	</avia-filedrop>
</app-avia-edit-modal>
