<div class="avia-textarea d-flex align-items-start" tabindex="0" [ngClass]="{'unlocked-border': editing }" [ngStyle]="{'color': options.color}">
	<medium-editor
		tabindex="0"
		*ngIf="editing"
		(blur)="onBlur()"
		[ngStyle]="{'color': options.color}"
		[(editorModel)]="mediumHelper.htmlModel"
		[editorOptions]="mediumHelper.getEditorOptions({placeholder: options.placeholder})"
		></medium-editor>
	<div *ngIf="!editing" class="medium-viewer mb-1"  (click)="onViewerClick($event)" [ngStyle]="{'color': options.color}">
		<span [innerHTML]="mediumHelper.htmlModel"></span>
		<div class="pt-4" *ngIf="options.truncate_expand && show_expand">
			<p *ngIf="expanded" [ngClass]="{'text-blue-dark': !options.expand_white_color,  'font-weight-bold':options.expand_white_color, 'text-white': options.expand_white_color}" class="expand-button pointer move-up" (click)="expand()">{{ options.truncate_expanded_text }}</p>
			<p *ngIf="!expanded" [ngClass]="{'text-blue-dark': !options.expand_white_color, 'font-weight-bold':options.expand_white_color, 'text-white': options.expand_white_color}" class="expand-button pointer move-up" (click)="expand()">{{ options.truncate_collapsed_text }}</p>
		</div>
	</div>
</div>
