import { CmShellBodyComponent } from './../../../communities/components/cm-shell-body/cm-shell-body.component';
import { AVIAConnectService } from './../../../avia-connect.service';
import { EventEmitter, Input, Component, OnInit, Output } from '@angular/core';
import { Common } from './../../../common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { AnalyticEvent } from '../../../../class';

@Component({
  selector: 'app-avia-quick-add-user',
  templateUrl: './avia-quick-add-user.component.html',
  styleUrls: ['./avia-quick-add-user.component.scss']
})
export class AviaQuickAddUserComponent implements OnInit {
  @Input()  hs_id:                    number;
  @Input()  pulse_id:                 number;
  @Input()  skip_email:               boolean = false;
  @Output() onChildClose:             EventEmitter<any> = new EventEmitter(); // Alert parent component that this modal has closed
  @Output() onNewUser:             EventEmitter<any> = new EventEmitter();

  failure_content:  string;
  user_form:        FormGroup;
  email_invalid;


  constructor(private aviaService: AVIAConnectService, private fb: FormBuilder) {
    this.createForm();
  }

  async ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.user_form = this.fb.group({
      email:      ['',    [Validators.required, Validators.email] ],
      firstname:  ['',    [Validators.pattern(Common.INVITE_NAME)] ],
      lastname:   ['',    [Validators.pattern(Common.INVITE_NAME)] ],
      successful: [false, []], // hidden field
    });
  };

  async onSubmit(): Promise<any> {
    this.failure_content = undefined;
    let invite_obj = {
      email:     this.user_form.get('email').value.trim(),
      firstname: this.user_form.get('firstname').value.trim(),
      lastname:  this.user_form.get('lastname').value.trim(),
      org:       this.hs_id,
      skip_email: false, // Always skip original invite email for pulse users
    };

    // Add pulse and set user as pending
    let add_user_resp = await this.aviaService.addUserByOrg(invite_obj.org, invite_obj);


    if(add_user_resp.status == 200) {
      let added_user = await this.aviaService.getUserById(add_user_resp.body.id, this.hs_id);
      // let data = await this.aviaService.updateUser(added_user.id, {action:'invite', email: invite_obj.email}); // Reinvite user to set status to pending
      // let email_res = await this.aviaService.startEmailWorkflow(invite_obj.email, 'createpasswordpulse'); // Resend invite email with pulse specific information

      this.addNewUser(added_user);
    }  else if (add_user_resp.status == 403) {
      // User NOT added - Invalid Domain
      this.failure_content = add_user_resp.body.reason ? add_user_resp.body.reason : 'Something went wrong!'
    } else {
      // User NOT added - Generic Error
      this.failure_content = add_user_resp.body.reason ? add_user_resp.body.reason : 'Something went wrong!'
    }
  }

  addNewUser(user) {
    this.onNewUser.emit(user);
    this.user_form.reset();
  }

  close() {
    // Reset all data and close the modal
    this.onChildClose.emit();
    this.user_form.reset();
  }
}
