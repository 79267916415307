<ng-template [ngIf]="static_page === false" [ngIfElse]="AddOrg"><span class="d-none"></span></ng-template>

<ng-template #AddOrg class='d-block w-100'>
	<div [ngClass]="{
		'avia-modal-full-screen avia-modal-full-screen-inverse': (fullscreen && static_page === false),
		'avia-modal': (!fullscreen && static_page === false)
	}"
	>
		<div class="page container-fluid bg-white">

			<div class="d-flex align-items-center headerblock row bg-gray-100" *ngIf="!static_page">
					<img class="pl-4 avialogo img-fluid " src="/assets/header-avia-connect-logo.svg">
					<span class="ml-auto backlink pointer" (click)="close()">
						<i class="mr-4 material-icons md-24" style="vertical-align: top;">close</i>
					</span>
			</div>

			<div class="titleblock row my-4 text-center" *ngIf="!static_page">
				<div class="col-12">
					<p class="text-uppercase">Add a New</p>
					<h1 class="section-header-font">Organization</h1>
				</div>
			</div>

			<div class="editblock row mx-0" [ngClass]="{'justify-content-sm-center':!static_page}">
				<div class="col-12 col-sm-10 col-md-8 pl-2 pr-4 px-sm-0 px-1 px-sm-0">
					<form [formGroup]="add_org_form" (ngSubmit)="onSubmit()">
						<!-- NAME -->
						<div class="form-group">
							<label for="name" class="text-uppercase">Name</label>
							<span class="float-right"><small><i>required</i></small></span>
							<input id='name' type="text" class="form-control" formControlName='name'>
						</div>

						<!-- SFDC LINK -->
						<div class="form-group">
							<label for="salesforce_id" class="text-uppercase">Salesforce Id</label>
							<input id='salesforce_id' type="text" class="form-control" formControlName='salesforce_id'>
						</div>

						<!-- ORG TYPE -->
						<div class="form-group">
							<label for="type" class="text-uppercase">Type</label>
							<span class="float-right"><small><i>required</i></small></span>
							<select name="type" id="type" class="form-control text-capitalize custom-select" formControlName='type'>
								<option value=0 disabled>Select an Organization type</option>
								<option *ngFor="let type of org_types;" [value]="type['id']">{{type['name']}}</option>
							</select>
						</div>

						<!-- AHA ID -->
						<div class="form-group" *ngIf='add_org_form.controls.type.value == 1'>
							<label for="aha_id" class="text-uppercase">AHA Id</label>
							<input id='aha_id' type="number" class="form-control" formControlName='aha_id'>
						</div>

						<div class="form-group d-flex justify-content-center mt-7">
							<button class="btn btn-primary ml-2"
								type="submit"
								[disabled]="!add_org_form.valid || add_org_form.pristine"
							>Save</button>
						</div>
					</form>

					<!-- DEBUGGING DISPLAY -->
					<span *ngIf="false">
						<!-- Display the form values and state during development -->
						<p>Form value: {{ add_org_form.value | json }}</p>
						<p>Form status: {{ add_org_form.status | json }}</p>
						<p>Pristine:
							<span class="text-uppercase">{{ add_org_form.pristine }}</span>
						</p>
					</span>
				</div>
			</div>

		</div>
	</div>
</ng-template>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #addOrgTpl><ng-content></ng-content></ng-template>

<!-- === ACTIVATION BUTTONS === -->
<div *ngIf="!hide_activate_btn && static_page === false"
	class="topic-add-btn "
	(click)="open()"
	>
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="addOrgTpl"></ng-container>
	</span>
	<!-- Fallback if ng-content not provided -->
	<i *ngIf="SuppliedAddBtn.children.length === 0"
		class="material-icons act-add-btn default"
		data-toggle="tooltip"
		placement="bottom"
		ngbTooltip="Add Org"
	>add_circle</i>
</div>
