<div class="d-flex align-items-center">
	<div *ngIf="label_show" [ngClass]="{'pr-2':label}" class="d-inline font-weight-bold" for="toggle">{{ label }}</div>
	<label class="toggle-switch d-inline-block position-relative m-0 mr-1" id="toggle">
		<input
			class="d-none"
			type="checkbox"
			[disabled]="disabled"
			[(ngModel)]="value"
			(change)="triggerSwitched($event)">
		<span class="slider position-absolute pointer" [ngClass]="{'disabled': disabled}" ></span>
	</label>
	<ng-content></ng-content>
</div>
