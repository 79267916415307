import { Component, OnInit } from '@angular/core';
import { AVIAConnectService } from './../../avia-connect.service';

@Component({
  selector: 'app-load-testing-page',
  templateUrl: './load-testing-page.component.html',
  styleUrls: ['./load-testing-page.component.scss']
})
export class LoadTestingPageComponent implements OnInit {
  gotit = false;
  result:any;
  constructor( private aviaService: AVIAConnectService ) { }

  async ngOnInit() {
    this.result = await this.aviaService.xhr( 'GET', '/12378945ASDFJHK-45789-ZCV-AVIA-134579-LTP1', { 'Accept': 'application/json' } );
    this.result = this.result.body;
    this.gotit=true;
  }

}
