<ng-template #messages let-modal>
<div *ngIf="open" class="container-fluid p-0 w-100 bg-white">
	<div>
		<div *ngIf='!aviaService.mobile_mode' class="row no-gutters">
			<div class="headerblock row bg-gray-100 w-100">
				<div class="aviablock col-6 pr-0 grat">
				</div>
				<div class="backblock col-6 pl-0 pr-2">
					<span class="backlink pointer" (click)="closeModal()">
						<i class="material-icons text-gray-600" style="font-size: 18px; vertical-align: top; padding:3px;">close</i>
					</span>
				</div>
			</div>
		</div>
		<div class="row no-gutters">
			<div *ngIf='!aviaService.mobile_mode || sm.selectedDiscussion == null' class="col-md-3 col-12 pb-4 border-right">
				<div class="p-4" [ngClass]="{'fixed-header':aviaService.mobile_mode }">
					<div class="d-flex justify-content-between">
						<div class="d-flex">
							<span *ngIf='aviaService.mobile_mode' class="pointer mr-2" style="height:0px">
								<i class="material-icons icon-btn text-gray-600" (click)="closeModal()">arrow_back</i>
							</span>
							<h5 class="m-0">Conversations</h5>
						</div>
					</div>
					<app-discussion-create-modal *ngIf="aviaService.session.user.org_obj.type == 1 || aviaService.session.user.org_obj.type == 3" (submit)="createDiscussion($event)"><button class="mt-4 btn btn-primary w-100 btn-no-wrap">Start New Conversation</button></app-discussion-create-modal>
				</div>
				<div style="overflow-y:auto;" [ngClass]="{'desktop-mode':aviaService.mobile_mode == false, 'mobile-mode':aviaService.mobile_mode }">
					<div *ngIf="sm.discussions && sm.discussions?.length > 0">
						<app-discussion-list [discussions]="sm.discussions" (select)="selectDiscussion($event)" [as_node_ids]="as_node_ids" ></app-discussion-list>
					</div>
					<div *ngIf="sm.discussions?.length < 1 && aviaService.mobile_mode" class="d-flex align-items-center justify-content-center h-100 text-gray-400 px-4 mt-7">
						<div class="empty-container">
							<i class="material-icons text-gray-200 w-100 text-align-center" style="font-size: 100px;">comments</i>
							<h4 class="text-gray-400">No conversations</h4>
							<p>Create a new conversation to get started!</p>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf='!aviaService.mobile_mode || sm.selectedDiscussion != null' class="col-md-6 col-12 pb-4 border-right">
				<div class="position-relative p-4" [ngClass]="{'fixed-header':aviaService.mobile_mode }" [ngStyle]="{'top':header_top,'bottom':header_bottom}">
					<div class="d-flex justify-content-between">
						<div class="d-flex overflow-hidden">
							<div *ngIf='aviaService.mobile_mode' class="d-flex" >
								<span class="pointer mr-2" style="height:0px">
									<i class="material-icons icon-btn text-gray-600" (click)="selectDiscussion(null)">arrow_back</i>
								</span>
								<div *ngIf="sm.selectedDiscussion.other_participants?.length > 1" class="position-relative avatar-img mr-4">
									<div class="img-square position-absolute img-box top-0 right-0">
										<img class="avatar-img-multi" onerror="this.src='../../../../assets/default-user.jpg'" [src]="sm?.getOtherParticipant(sm?.selectedDiscussion)?.avatar">
									</div>
									<div class="img-square position-absolute img-box bottom-0 left-0">
										<img class="avatar-img-multi" onerror="this.src='../../../../assets/default-user.jpg'" [src]="sm.selectedDiscussion?.avatar && sm.selectedDiscussion.user_id != aviaService.session?.user?.id?sm.selectedDiscussion?.avatar:sm.selectedDiscussion.other_participants[0]?.avatar">
									</div>
								</div >
								<div *ngIf="sm.selectedDiscussion.other_participants?.length === 1" class="position-relative avatar-img mr-4">
									<div class="img-square position-absolute img-box mr-2">
										<img class="avatar-img" onerror="this.src='../../../../assets/default-user.jpg'" src="{{sm.selectedDiscussion.other_participants[0]?.avatar}}">
									</div>
								</div>
							</div>
							<h5 class="d-flex overflow-hidden m-0">
								<span *ngIf='sm.selectedDiscussion?.name' class="text-ellipse">{{sm.selectedDiscussion.name}}</span>
								<span *ngIf='sm.selectedDiscussion != null && (sm.selectedDiscussion.name == null || sm.selectedDiscussion.name == "")' class="text-ellipse">{{getParticipantNames(sm.selectedDiscussion)}}</span>
								<span *ngIf='sm.selectedDiscussion?.is_muted' class="text-gray-400">
									<i class="material-icons" style="font-size: 18px;">notifications_off</i>
								</span>
							</h5>
						</div>


						<div *ngIf="sm.selectedDiscussion" ngbDropdown display="dynamic" placement="bottom-right" class="more pointer">
							<div class="text-gray-600" style="height:0px;" ngbDropdownToggle><i class="material-icons icon-btn">more_vert</i></div>
							<div ngbDropdownMenu class="dropdown-menu">
								<div class="dropdown-item p-0">
									<app-discussion-create-modal [mode]="'edit'" (update)="updateParticipants($event)" [discussion]="sm.selectedDiscussion">
										<button class="btn px-4">manage</button>
									</app-discussion-create-modal>
								</div>
								<div class="dropdown-item p-0">
									<button *ngIf="sm.selectedDiscussion.is_muted == 0 || sm.selectedDiscussion.is_muted == null" class="btn px-4" (click)="muteConversation()">mute</button>
									<button *ngIf="sm.selectedDiscussion.is_muted == 1" class="btn px-4" (click)="unmuteConversation()">unmute</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div [ngClass]="{'desktop-mode':aviaService.mobile_mode == false, 'mobile-mode':aviaService.mobile_mode }">
					<div [ngStyle]="{'marginTop':margin_top}">
						<app-discussion (focus)="repositionHeaderFocus()" (blur)="repositionHeaderBlur()" *ngIf="sm.selectedDiscussion != null && sm.messages != null" [discussion]="sm.selectedDiscussion" [messages]="sm.messages"></app-discussion>
						<div *ngIf="sm.discussions?.length < 1" class="d-flex align-items-center justify-content-center h-100 text-gray-400 px-4">
							<div class="empty-container">
								<i class="material-icons text-gray-200 w-100 text-align-center" style="font-size: 100px;">comments</i>
								<h4 class="text-gray-400">No conversations</h4>
								<p>Create a new conversation to get started!</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div *ngIf='!aviaService.mobile_mode' class="col-md-3 col-12 px-4 pb-4">
					<div class="py-4" [ngClass]="{'fixed-header':aviaService.mobile_mode }">
						<div class="d-flex justify-content-between">
							<h5 class="m-0">Participants</h5>
						</div>
						<app-discussion-create-modal *ngIf='sm.selectedDiscussion' [mode]="'edit'" (update)="updateParticipants($event)" [discussion]="sm.selectedDiscussion">
							<button class="mt-4 btn btn-primary w-100 position-relative pl-6 btn-no-wrap"><span class="material-icons add-icon">
								add
								</span>Add people to conversation</button>
						</app-discussion-create-modal>
					</div>
					<div [ngClass]="{'desktop-mode':aviaService.mobile_mode == false, 'mobile-mode':aviaService.mobile_mode }">
						<app-discussion-participants [allow_navigate]='false' [add_enabled]='false' *ngIf="sm.selectedDiscussion != null" [mode]="'edit'" (update)="updateParticipants($event)" [participants]="sm.selectedDiscussion.participants"></app-discussion-participants >
					</div>
			</div>
		</div>
	</div>

</div>
</ng-template>

<div (click)="openModal()">
	<ng-content></ng-content>
</div>
