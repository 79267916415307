<div class="avia-row-container bg-white d-flex w-100"
	[class.inactive]="user?.status_obj !== undefined ? user?.status_obj.id === 1 : user?.status !== undefined ? user?.status === 1 : false"
	[class.pending]="user?.status_obj !== undefined ? user?.status_obj.id === 2 : user?.status !== undefined ? user?.status === 2 : false"
	[ngClass]="{'mb-3': options.view_type !== 'list', 'card': options.view_type === 'card', 'border-bottom': options.view_type === 'list' && !isLast}"
	>
	<div class="avia-row d-inline-flex justify-content-between w-100"
		[class.pointer]="(user?.status === 4 || _akey?.d === true)">
		<div class="w-100 w-md-50"
			[ngClass]="{
				'border-full': options.view_type === 'row',
				'py-3': options.view_type !== 'list-sm'
			}"
			>
			<div class="fade-target col-12 px-0">
				<div class="d-flex align-items-center"
					[ngStyle]="{'cursor': (user?.status_obj?.id === 4 || user?.status === 4 || _akey.d === true) ? 'pointer' : 'default'}"
					[routerLink]="(user?.status_obj?.id === 4 || user?.status === 4 || _akey.d === true) && options?.allow_navigate === true ? ['/profiles/in/', user?.id] : null"
					>
					<div *ngIf="color" class="side-strip mr-2"
						[ngStyle]="{'background-color': color}">
					</div>
					<div class="img-square position-relative img-box mr-2" [ngClass]="{'img-box-list-sm':options.view_type === 'list-sm'}">
						<img class="avatar-img"
							onerror="this.src='../../../../assets/default-user.jpg'"
							[ngClass]="{'avatar-img-list-sm':options.view_type === 'list-sm'}"
							[src]="user?.avatar"
						/>
						<img class="company-logo"
							onerror="this.src='../../../../assets/placeholder-company.jpg'"
							*ngIf="options.multi_org"
							[ngClass]="{'company-logo-list-sm':options.view_type === 'list-sm'}"
							[src]="user?.org_obj.logo"
						/>
					</div>
					<div class="d-block align-titles">
						<div class="title pb-1"
							[ngClass]="{'title-list-sm':options.view_type === 'list-sm'}">
							{{ display_name }}
							<span *ngIf="user?.org_obj?.name && !options.multi_org && !options.known_org" class='pr-1'> | {{ user?.org_obj?.name }}</span>
							<span *ngIf="options.display_primary_contact && user.is_primary_contact && options.view_type !== 'list'">(<em>primary</em>)</span>
							<span *ngIf="options.display_primary_contact && user.is_primary_contact && options.view_type === 'list'"
								class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400"><span class="align-text-bottom">primary</span></span>
								<span *ngIf="options.display_exec_sponsor && user.is_exec_sponsor && options.view_type !== 'list'">(<em>executive sponsor</em>)</span>
								<span
									*ngIf="options.display_exec_sponsor && user.is_exec_sponsor && options.view_type === 'list'"
									class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400"
									><span class="align-text-bottom">executive sponsor</span>
								</span>
								<!-- Loops through the titles/roles passed as dropdown_options -->
								<div class="d-inline" *ngFor="let d of options.dropdown_options">
									<span *ngIf="user[d.field]" class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400">
										<span class="align-middle">{{ d.name }}</span>
									</span>
								</div>
						</div>
						<div class="title-details" [ngClass]="{'title-details-list-sm':options.view_type === 'list-sm', 'title-details-mobile': aviaService?.mobile_mode}">
							<span>{{ display_subheader }}</span>
							<span *ngIf="user?.dept">, {{ user?.dept }}</span>
							<div
								*ngIf="options.org_badge"
								class="d-inline-flex align-items-center badge-pill text-white ml-2 rounded pointer"
								[ngStyle]="{'background-color': color}"
								[routerLink]="options?.allow_navigate === false ? null : user?.org_obj.type == 2 ? ['/sc/', user?.org_obj.id] : (user?.org_obj.type == 1 ? ['/profiles/hs/', user?.org_obj.id] : ['/members/card/org', user?.org_obj.id])">
								<div class="align-self-center text-capitalize font-weight-bold"> {{ user?.org_obj?.name }}</div>
							</div>
						</div>
					</div>
					<!-- connection button -->
					<div *ngIf="options.connect_btn">
						<app-send-message-button *ngIf="user" [user]="user" [smol]="true"
							(click)="$event.stopPropagation()">
						</app-send-message-button>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex float-right align-items-center align-member-status pl-2 text-center">
			<div class="d-inline-flex justify-content-end">
				<!-- reinvitation button -->
				<div class='pr-2' *ngIf="(options?.allow_reinvite && (user?.status_obj?.action_user === 'Reinvite' || user?.status_obj?.action_user === 'Invite') && reinvite_akey?.w)">
					<div class="btn btn-sm invite-btn"
						[ngClass]="reinvite_sent ? 'invite-sent-btn' : 'btn-primary'"
						(click)="reinvite_sent ? null : reinviteUser(user)"
					>{{ reinvite_sent ? 'Invitation Sent' : 'Reinvite' }}</div>
				</div>
				<!-- User Status -->
				<div *ngIf="options.display_role_and_status">
					<div class="title" *ngIf="user?.org_obj?.type != 3">{{ user?.is_owner ? 'Owner' : 'Member' }}</div>
					<div class="title" *ngIf="user?.org_obj?.type == 3">{{ 'AVIAN' }}</div>
					<div class="text-capitalize title-details">{{ user?.status_obj?.name }}</div>
				</div>

				<app-avia-network-modal
					*ngIf="priorities?.length > 0 || inventories?.length > 0"
					[priorities]="priorities"
					[inventories]="inventories"
					[org_id]="user?.org"
					[title]="user?.fullname"
					[logo]="user?.avatar"
					>
					<i class="material-icons text-primary">dashboard</i>
				</app-avia-network-modal>

				<!-- Dropdown Items -->
				<div class="more pointer"
					[ngClass]="{'more-list-sm': options.view_type === 'list-sm'}"
					*ngIf="show_dropdown"
					ngbDropdown
					placement="bottom-right"
					>

					<div ngbDropdownToggle *ngIf="!options.custom_button_text">
						<!-- default kebab  -->
						<i  class="material-icons">more_vert</i>
						<!-- button with custom text -->
					</div>
					<button ngbDropdownToggle *ngIf="options.custom_button_text" class="d-none d-md-block custom-button-text btn btn-sm btn-gray-300 position-absolute">{{options.custom_button_text}}</button>
					<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right p-2">

						<!-- Inactivate User -->
						<button *ngIf="(options?.allow_inactivate && user?.status_obj?.name !== 'inactive' && inactivate_akey?.w)" class="dropdown-item px-2"
							(click)="$event.preventDefault(); $event.stopPropagation(); changeStatus(user, 'inactive')">
							<div class="row no-gutters">
								<div class="col-2"><i class="material-icons pr-1">person</i></div>
								<div class="col-10">Inactivate</div>
							</div>
						</button>
						<!-- Owner/Member -->
						<button *ngIf="(options?.allow_owner_change && _akey?.w && user?.status_obj?.id !== 1)" class="dropdown-item px-2" (click)="changeOwnerStatus(user)">
							<div class="row no-gutters">
								<div class="col-2">
									<i class="material-icons pr-1">{{user.is_owner ? 'person' : 'supervised_user_circle' }}</i>
								</div>
								<div class="col-10">{{user?.is_owner ? 'Make Member' : 'Make Owner' }}</div>
							</div>
						</button>
						<!-- Day-to-Day Contact/ Primary Contact -->
						<button *ngIf="(options?.allow_primary_contact_change && _akey?.w)" class="dropdown-item px-2" (click)="$event.preventDefault(); $event.stopPropagation(); changePrimaryContactStatus(user)">
							<div class="row no-gutters">
								<div class="col-2">
									<i class="material-icons pr-1">{{user.is_primary_contact ? 'remove_circle_outline' : 'add_circle' }}</i>
								</div>
								<div class="col-10">{{user?.is_primary_contact ? 'Remove Day-to-Day Contact' : 'Make Day-to-Day Contact' }}</div>
							</div>
						</button>
						<!-- Executive Sponsor -->
						<button *ngIf="(options.allow_exec_sponsor_change && _akey?.w)" class="dropdown-item px-2" (click)="$event.preventDefault(); $event.stopPropagation(); changeExecutiveSponsorStatus(user)">
							<div class="row no-gutters">
								<div class="col-2">
									<i class="material-icons pr-1">{{user?.is_exec_sponsor ? 'remove_circle_outline' : 'add_circle' }}</i>
								</div>
								<div class="col-10">{{user?.is_exec_sponsor ? 'Remove Executive Sponsor' : 'Make Executive Sponsor' }}</div>
							</div>
						</button>
						<!-- Etc. You can pass in an array of custom dropdown objects! [{field: this_field, name: 'Cool Name'}]-->
						<div *ngIf="options?.dropdown_options">
							<div *ngFor="let d of options?.dropdown_options">
								<button (click)="changeDrop(d)" class="dropdown-item px-2">
									<div class="row no-gutters">
										<div>{{user[d.field] ? 'Unassign' : 'Assign' }} {{d.name}}</div>
									</div>
								</button>
							</div>
						</div>
						<!-- Delete -->
						<button *ngIf="(options?.allow_remove && _akey?.d)" class="dropdown-item px-2" (click)="$event.preventDefault(); $event.stopPropagation(); remove(user)">
							<div class="row no-gutters">
								<div class="col-2">
									<i class="material-icons pr-1 text-danger">remove_circle</i>
								</div>
								<div class="col-10">Remove</div>
							</div>
						</button>
						<!-- Change Status -->
						<button *ngIf="(options?.allow_status_change && user?.status_obj?.action_user && _akey?.w )" class="dropdown-item px-2"
							(click)="$event.preventDefault(); $event.stopPropagation(); changeStatus(user, 'status')">
							<div class="row no-gutters">
								<div class="col-2"><i class="material-icons pr-1">person</i></div>
								<div class="col-10">{{ user?.status_obj?.action_user }}</div>
							</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
