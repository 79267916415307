import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import printjs from 'print-js'; // will define global printJS (if you call it printJS it will hide the global one)
import { Subscription } from 'rxjs';


import { environment } from 'src/environments/environment';
import { Common } from 'src/app/common';
import { CommonValidators } from 'src/app/common-validators';
import { AVIAConnectService } from 'src/app/avia-connect.service';


import {
  AnalyticEvent,
} from 'src/class';

// AVIA Content viewer is kicked off by code
// @example <avia-content-viewer #AviaContentViewer></avia-content-viewer>
// @code start
//   // use the avia service to launch the content viewer
//   aviaService.openContentViewer(...)
// @code end
// @see aviaService.openContentViewer(...)


@Component({
  selector: 'avia-content-viewer',
  templateUrl: './avia-content-viewer.component.html',
  styleUrls: ['./avia-content-viewer.component.scss']
})
export class AviaContentViewerComponent implements OnInit {
  private previous_url = '';

  can_close: boolean = true;
  can_print: boolean = false;
  content_filename: string = "";
  content_id: number;
  content_name: string = "";
  content_type: any;
  content_url: string = "";
  content_viewer: string = "";
  current_query_params: any;
  guardzoom:boolean = false;
  notfound: boolean = false;
  open_file: number;
  pdfzoom: number;
  pdfzoom_default: number = .8;
  pdfzoom_max: number = 1;
  pdfzoom_min: number = .5;
  pdfzoom_now: number;
  progress: number = 0;
  progress_total: number = 0;
  query_params_sub: Subscription;
  ready: boolean = false; // show the content
  router_sub: Subscription;
  showing: boolean = false; // show the viewer

  @ViewChild("theUrl", { static: true }) theUrl: any;


  private disableScroll(): void {
    document.body.classList.add('no-scroll');
  }

  private enableScroll(): void {
    document.body.classList.remove('no-scroll');
  }

  constructor(
    private aviaService: AVIAConnectService,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer
    ) {
    this.current_query_params = {}

    this.query_params_sub = this.route.queryParams.subscribe(
      (params) => {
        for (let p in params) {
          this.current_query_params[p] = params[p] ? params[p] : undefined;
        }
      },
      (err) => { console.log('Error: %s', err); },
      () => { }
    );

    this.router_sub = this.router.events.subscribe(
      (events) => {
        if ( events instanceof NavigationStart && this.showing ) {
          this.showing = false;
          this.enableScroll();
        }
      }
    )
  }

  ngOnInit() {
    //this.aviaService.openContentViewer( 'https://aviaconnectcontent-test.s3.amazonaws.com/44e03d28-719d-1c89-83cd-f5039a46f664_1534369445070', 'the.void.mp3' );
    //this.aviaService.openContentViewer( "/assets/fonts/BLOKKNeue-Regular.ttf", "BLOKKNeue-Regular.ttf" );
    //this.aviaService.openContentViewer( 'https://aviaconnectcontent.s3.amazonaws.com/8b89fec2-351c-17ff-b150-829259fc73bc_1530160939554', 'myfile.mp4' );
    //this.aviaService.openContentViewer( 'https://aviaconnectcontent-test.s3.amazonaws.com/0aafec20-5f97-cc8b-6459-962df5fa7779_1534366681723', 'myfile.pdf' );
    //this.aviaService.openContentViewer( 'http://www.subatomicglue.com', 'subatomicglue' );
  }

  // callers can try this before open, then call window.open on the link
  shouldOpenInNewTab( content_url:string, content_name:string, content_filename:string, open_links_in_new_tab_on_desktop:boolean = false ): boolean {
    if (this.showing === false) {
      this.content_url = /^http/.test(content_url) ? content_url : [this.aviaService.frontUrl, content_url].join( '' );
      this.content_name = content_name;
      this.content_filename = content_filename;
      this.content_viewer = "";
      if (content_filename === '' || content_filename === undefined || content_filename === null) {
        content_filename = content_name;
      }

      // this must be before any promise so it happens in the same calling context - avoid popup blockers
      if (this.calculateContentViewer() === "isLink" && open_links_in_new_tab_on_desktop && !this.isMobileDevice()) {
        return true;
      }
    }
    return false;
  }

  // open the content viewer.
  open( content_url:string, content_name:string, content_filename:string, content_id:number, open_links_in_new_tab_on_desktop:boolean = false, redirect = true ): void {
    // launch the viewer only if not showing
    this.previous_url = this.router.url;
    this.current_query_params['openFile'] = content_id;
    if(redirect) this.router.navigate([], {queryParams: this.current_query_params});

    if (this.showing === false) {
      this.content_url = /^http/.test(content_url) ? content_url : [this.aviaService.frontUrl, content_url].join( '' );
      this.content_name = content_name;
      this.content_id = content_id;
      this.content_filename = content_filename;
      this.content_viewer = "";
      this.can_close = true;
      this.ready = false;
      this.showing = true;
      this.progress = 0;
      this.progress_total = 0;
      this.can_print = false;
      this.notfound = false;
      this.pdfzoom = this.pdfzoom_default;
      this.pdfzoom_now = this.pdfzoom_default;
      if (content_filename === '' || content_filename === undefined || content_filename === null) {
        content_filename = content_name;
      }

      // this must be before any promise so it happens in the same calling context - avoid popup blockers
      let did_popup = false;
      if (this.calculateContentViewer() === "isLink" && open_links_in_new_tab_on_desktop && !this.isMobileDevice()) {
        did_popup = true;
        //this.openLinkInNewTab( content_url );
        //window.open( Common.safeHttp( content_url ) );
        //window.open(content_url);
      }

      setTimeout( async () => {
        // pull the content header 'content-type', if CORS allows

        // the angular dev server (webpack-dev-server) doesn't support HEAD requests...  suck it webpack
        let head_type = "HEAD";
        if (!/^http/.test(content_url) && !environment.production) {
          head_type = "GET";
        }
        let data = await this.aviaService.xhr( head_type, this.content_url, {}, {}, ()=>{}, { use_serverdown_queue: false } );
        // if document doesn't exist, get out of here...
        if (data.status === 404) {
          if (this.isFrontendAsset() || !this.isUrlFrontend()) {
            this.notfound = true;
            return;
          }
        }
        // we'll get a 0 for CORS block, otherwise, HEAD will give us a content-type
        if (data.status !== 0) {
          this.content_type = data.headers['content-type'];
        } else {
          this.content_type = undefined;
        }

        // type correction based on extention if application/octet-stream (blob) or unknown mime type
        if (this.isOctetStreamWithExtention('pdf')) this.content_type = 'application/pdf';
        if (this.isOctetStreamWithExtention('mp4')) this.content_type = 'video/mp4';
        if (this.isOctetStreamWithExtention('mp3')) this.content_type = 'audio/mp3';
        if (this.isOctetStreamWithExtention('ogg')) this.content_type = 'video/ogg'; // could be either, so let the video player play the audio oggs
        if (this.isUnknownWithExtention('png')) this.content_type = 'image/png';
        if (this.isUnknownWithExtention('jpg')) this.content_type = 'image/jpg';
        if (this.isUnknownWithExtention('jpeg')) this.content_type = 'image/jpeg';
        if (this.isUnknownWithExtention('gif')) this.content_type = 'image/gif';
        if (this.isUnknownWithExtention('svg')) this.content_type = 'image/svg';
        if (this.isUnknownWithExtention('pdf')) this.content_type = 'application/pdf';
        if (this.isUnknownWithExtention('mp4')) this.content_type = 'video/mp4';
        if (this.isUnknownWithExtention('mp3')) this.content_type = 'audio/mp3';
        if (this.isUnknownWithExtention('ogg')) this.content_type = 'video/ogg'; // could be either, so let the video player play the audio oggs

        // what viewer to use?
        this.content_viewer = this.calculateContentViewer();

        // do special stuff for some viewers...
        if (this.content_viewer === "isPDF") {
          this.can_close = false;
          this.can_print = true;
        } else if (!did_popup && this.content_viewer === "isLink" && open_links_in_new_tab_on_desktop && !this.isMobileDevice()) {
          //this.openLinkInNewTab( content_url );
          console.log( "would have opened it AFTER");
        }

        window.scrollTo(0,0);
        this.disableScroll();

        this.ready = true;
        window.scrollTo(0,0);
        document.body.classList.add('no-scroll');
      }, 0);
    }
  }

  close(): void {
    // protect against closing the pdf viewer while it's loading (which can cause a crash)
    if (this.can_close) {
      this.location.replaceState(this.previous_url);
      this.showing = false;
      delete this.current_query_params['openFile'];
      this.enableScroll();

      this.router.navigate([], {queryParams: this.current_query_params});
      document.body.classList.remove('no-scroll');
    }
  }

  // figure out which content viewer to use
  calculateContentViewer(): string {
    // TODO: this could be greatly optimized, it only happens once, so... lo-pri
    if (this.isImage()) return 'isImage'; else
    if (this.isVideo()) return 'isVideo'; else
    if (this.isAudio()) return 'isAudio'; else
    if (this.isPDF()) return 'isPDF'; else
    if (this.isDownload()) return 'isDownload'; else
    return 'isLink';
  }

  print( url:string ): void {
    if (printjs) {
      printjs( {type:'pdf', printable: this.content_url, showModal: true, modalMessage: 'retreiving...'});
    }
  }

  logDownload(): void {
    let obj = new AnalyticEvent(
      'file_download',
      {content_id: this.content_id}
    );
    this.aviaService.createAnalyticEvent(obj);
  }

  // running on a mobile DEVICE?
  isMobileDevice() : boolean {
    return this.aviaService.deviceIsMobile;
  }

  openLinkInNewTab( content_url:string ): void {
    window.open( Common.safeHttp( content_url ) );
  }


  // copy to clipboard
  /*
  copy( text ) {
    this.clipboardService.copyFromContent( text ); // import { ClipboardService } from 'ngx-clipboard'
  }
  */


  // takes some time for the PDF to destroy/load the DOM - if change too quickly, browser can crash
  zoomUpdate( timeout:number ): void {
    if (this.guardzoom || !this.can_close) {
      // ignore update, it'll get picked up at the end of the current running setTimeout
    } else {
      // update immediately
      this.pdfzoom = this.pdfzoom_now;

      // guard for a second.  if another change came in while guarding, kick off another update
      this.guardzoom = true;
      this.can_close = false;
      setTimeout( () => {
        this.guardzoom = false;
        this.can_close = true;
        if (this.pdfzoom != this.pdfzoom_now)
          this.zoomUpdate( timeout );
      }, timeout );
    }
  }

  zoomIn(): void {
    this.pdfzoom_now = Math.min( this.pdfzoom_max, this.pdfzoom_now+0.1 );
    this.zoomUpdate( 1000 ); // do we need to scale time by #pages in the pdf? hmmm.
  }

  zoomOut(): void {
    this.pdfzoom_now = Math.max( this.pdfzoom_min, this.pdfzoom_now-0.1 );
    this.zoomUpdate( 1000 ); // do we need to scale time by #pages in the pdf? hmmm.
  }


  onPdfLoad( e:any ): void {
    // takes some time for the PDF to load - if close too early browser can crash

    // todo: need to implement this better scheme for guarding!
    // which counts pages loaded rather than arbitrary timeout:
    // https://github.com/VadimDez/ng2-pdf-viewer/issues/367
    setTimeout( () => {
      this.can_close = true;
      if (this.pdfzoom != this.pdfzoom_now)
        this.zoomUpdate( 1000 ); // do we need to scale time by #pages in the pdf? hmmm.
    }, 2000 ); // do we need to scale time by #pages in the pdf? hmmm.
  }

  onPdfProgress( p:any ): void {
    this.progress = p.loaded ? p.loaded : 1;
    this.progress_total = p.total ? p.total : this.progress_total ? this.progress_total : 1;
  }

  onPdfError( e:any ): void {
    this.can_close = true;
  }

  onPdfRendered( e:any ): void {}


  private isContentType( re:any ) : boolean {
    return this.content_type && re.test( this.content_type );
  }

  private isUnknownWithExtention( ext:string ): boolean {
    return (this.content_type === undefined || this.content_type === '') &&
      this.content_filename &&
      (new RegExp( `\.${ext}$`, 'i' )).test( this.content_filename );
  }

  private isOctetStreamWithExtention( ext:any ): boolean {
    // unknown content is technically an octet stream, sure, makes sense!
    if (this.isUnknownWithExtention(ext)) return true;
    return this.isContentType( /(application\/octet-stream)/i ) &&
      this.content_filename &&
      (new RegExp( `\.${ext}$`, 'i' )).test( this.content_filename );
  }

  private isImage(): boolean {
    return this.isContentType( /(png|jpg|jpeg|svg|gif|bmp)/i ) ||
      this.isOctetStreamWithExtention('png') ||
      this.isOctetStreamWithExtention('jpg') ||
      this.isOctetStreamWithExtention('jpeg') ||
      this.isOctetStreamWithExtention('svg') ||
      this.isOctetStreamWithExtention('gif') ||
      this.isOctetStreamWithExtention('bmp');
  }

  private isVideo(): boolean {
    return this.isContentType( /(video\/ogg|video\/mp4|video\/webm)/i ) ||
      this.isOctetStreamWithExtention('mp4') ||
      this.isOctetStreamWithExtention('ogg') ||
      this.isOctetStreamWithExtention('webm');
  }

  private isAudio(): boolean {
    return this.isContentType( /(audio\/mp3|audio\/mpeg|audio\/ogg|audio\/wave|audio\/wav|audio\/x-wav|audio\/x-pn-wav|audio\/flac|audio\/x-flac|audio\/webm)/i ) ||
      this.isOctetStreamWithExtention('mp3') ||
      this.isOctetStreamWithExtention('wav') ||
      this.isOctetStreamWithExtention('wave') ||
      this.isOctetStreamWithExtention('flac');
  }

  private isPDF(): boolean {
    return this.isContentType(/(pdf)/i) ||
      this.isOctetStreamWithExtention('pdf');
  }

  private isLink(): boolean {
    return !this.isImage() && !this.isVideo() && !this.isAudio() && !this.isPDF() && !this.isDownload();
  }

  private isUrlFrontend(): boolean {
    let frontUrlRegexStr = `^${this.aviaService.frontUrl.replace(/\//g,'\\/')}`;
    let inOurFrontEnd = new RegExp( frontUrlRegexStr, 'i' );
    return inOurFrontEnd.test( this.content_url );
  }

  private isFrontendAsset(): boolean {
    let frontAssetUrl = this.aviaService.frontUrl + "/assets"
    let frontUrlRegexStr = `^${frontAssetUrl.replace(/\//g,'\\/')}`;
    let inOurFrontEnd = new RegExp( frontUrlRegexStr, 'i' );
    return inOurFrontEnd.test( this.content_url );
  }

  private isDownload(): boolean {
    return !this.isImage() &&
      !this.isVideo() &&
      !this.isAudio() &&
      !this.isPDF() && (
        this.isFrontendAsset() ||
        CommonValidators.isLinkFromS3( this.content_url ) ||
        (this.content_filename && /\..?.?.?$/i.test( this.content_filename ))
      );
  }

}
