<div class="p-md-4 p-0">
	<div class="bg-white shadow-sm py-2">
		<div class="d-flex justify-content-between px-5 py-4">
			<div class="heading-size-16">Notifications</div>
			<div><i class="material-icons pointer" (click)="$event.preventDefault(); closePage()">close</i></div>
		</div>
		<div class="px-5">
			<div class="mb-2" *ngFor="let n of notifications">

				<app-notify-feed *ngIf="(n?.type_obj?.name === 'feed')" [show_dismiss]="false" [notification]="n" (goTo)="goToLink($event)"></app-notify-feed>

				<!-- DO NOT DELETE BOTH CASES OF WORKSPACE AND CHANNEL CHAT - needed for older notifications -->
				<app-notify-comment *ngIf="(n?.type_obj?.name === 'KM card comment' ||
					n.type_obj?.name === 'KM chat' ||
					n.type_obj?.name === 'CL comment' ||
					n.type_obj?.name === 'Group chat' ||
					n.type_obj?.name === 'Channel chat' ||
					n.type_obj?.name === 'HS Action' ||
					n.type_obj?.name === 'Pulse Comment' ||
					n.type_obj?.name === 'Added to Group' ||
					n.type_obj?.name === 'Added to Workspace' ||
					n.type_obj?.name === 'Request to join channel' ||
					n.type_obj?.name === 'Info request' ||
					n.type_obj?.name === 'Info request acknowledgment')"
					[show_dismiss]="false"
					[notification]="n"
					(goTo)="goToCard($event)"
				></app-notify-comment>
				<app-notify-np-connect class="mb-2" *ngIf="n.type_obj?.name === 'User connection'" [show_dismiss]="false" [notification]="n" (goTo)="goToMemberProfile($event)"></app-notify-np-connect>
			</div>

			<div *ngIf="notifications.length === 0">
				<div class="text-center text-gray-600 font-italic p-4">
					<span>No notifications at this time</span>
				</div>
			</div>
		</div>
	</div>
</div>
