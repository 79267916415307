<div>
	<div *ngFor="let d of discussions">
		<div class="discussion-row d-flex align-items-center mt-4 pointer position-relative px-4" (click)="selectDiscussion(d)" [ngClass]="{'selected':(d.comment_group_id === sm.selectedDiscussion?.comment_group_id)}">
			<div *ngIf="d.other_participants?.length > 1" class="position-relative avatar-img mr-4">
				<div class="img-square position-absolute img-box top-0 right-0">
					<img class="avatar-img-multi" onerror="this.src='../../../../assets/default-user.jpg'" [src]="getOtherParticipant(d)?.avatar">
				</div>
				<div class="img-square position-absolute img-box bottom-0 left-0">
					<img class="avatar-img-multi" onerror="this.src='../../../../assets/default-user.jpg'" [src]="d?.avatar && d?.user_id != aviaService.session?.user?.id ? d?.avatar: d?.other_participants[0]?.avatar">
				</div>
			</div >
			<div *ngIf="d.other_participants?.length === 1" class="position-relative avatar-img mr-4">
				<div class="img-square position-absolute img-box mr-2">
					<img class="avatar-img" onerror="this.src='../../../../assets/default-user.jpg'" src="{{d.other_participants[0]?.avatar}}">
				</div>
			</div>
			<div class="overflow-hidden mr-6">
				<div class="text-truncate font-weight-bold" [ngClass]="{'font-weight-superbold':(d.unread_count > 0)}">
					{{getParticipantNames(d)}}
				</div>
				<div class="text-truncate" [ngClass]="{'font-weight-superbold ':(d.unread_count > 0)}">
					{{getLastComment(d)}}
				</div>
			</div>
			<div *ngIf="d.unread_count && d.unread_count > 0" class="badge badge-pill badge-blue-dark" style="
					right: 10px;
					position: absolute;
			">{{ d.unread_count > 9?'9+':d.unread_count}}</div>
			<div *ngIf="d.is_muted" class="text-gray-400" style="
				right: 0px;
				position: absolute;
			"><i class="material-icons" style="font-size: 18px;">notifications_off</i></div>
		</div>
	</div>
</div>
