<div class="page container-fluid bg-white h-100 p-0">

	<app-avia-header-row></app-avia-header-row>

	<div class="justify-content-center mt-5 row w-100 ">
		<div class="col-12 col-md-6 text-center pr-0">
			<h2>Create an Account</h2>
			<p>Choose your organization type below.</p>
		</div>
	</div>

	<div class="bg-white justify-content-center mt-4 no-gutters row text-center">
		<div class="col-12 col-md-3 mb-6 mb-md-0">
			<img alt="Health System" class="mb-2" src="/assets/signup/health-system.svg" />
			<h5 class="mb-4">Hospital or Health System</h5>
			<button class="btn btn-primary mb-4" tabindex="1" type="button" [routerLink]="['hs']">Sign up</button>
			<p class="mb-3 px-6 text-left"><strong>Discover.</strong> Learn how digital can advance your priorities</p>
			<p class="mb-3 px-6 text-left"><strong>Plan.</strong> Assess your digital performance and define your path ahead</p>
			<p class="mb-3 px-6 text-left"><strong>Collaborate.</strong> Tap into the knowledge and experience of the network</p>
		</div>
		<div class="col-12 col-md-3 mb-6 mb-md-0">
			<img alt="Digital Health Company" class="mb-2" src="/assets/signup/company.svg" />
			<h5 class="mb-4">Digital Health Company</h5>
			<button class="btn btn-primary mb-4" tabindex="2" type="button" [routerLink]="['solco']">Sign up</button>
			<p class="mb-3 px-6 text-left"><strong>Gain Exposure.</strong> Be discovered by a rapidly growing network of providers</p>
			<p class="mb-3 px-6 text-left"><strong>Showcase.</strong> Demonstrate how your products support digital transformation</p>
			<p class="mb-3 px-6 text-left"><strong>Validate.</strong> Highlight your success and adoptions</p>
		</div>
		<div class="col-12 col-md-3 mb-6 mb-md-0">
			<img alt="Other" class="mb-2" src="/assets/signup/other.svg" />
			<h5 class="mb-4">Other</h5>
			<button class="btn btn-primary mb-4 other-btn" tabindex="3" type="button" [routerLink]="['other']">Learn More</button>
			<p class="mb-3 px-6 text-left"><strong>Engage.</strong> Participate in a rapidly growing network focused on digital health</p>
			<p class="mb-3 px-6 text-left"><strong>Learn.</strong> Get insight into evolving market trends</p>
			<p class="mb-3 px-6 text-left"><strong>Lead.</strong> Accelerate the transformation of the industry</p>
		</div>
	</div>

</div>
