
/* * *
* We keep all classes here!
* Please alphabetize
* “If you get tired, learn to rest, not to quit.”
* IMPORTANT! IF YOU CHANGE ANYTHING IN THIS FILE YOU NEED TO RUN: "ng build --prod" (before checking in)
* * */


/* * * A * * */
export class AccessKey {
  constructor(
    public c: boolean = false,
    public r: boolean = false,
    public w: boolean = false,
    public d: boolean = false,
    public e: boolean = false
  ) { }
}

export class AccessKeychain_Activity {
  constructor(
    public core:              AccessKey = new AccessKey(),
    public draft_access:      AccessKey = new AccessKey(),
    public feature:           AccessKey = new AccessKey(),
    public files:             AccessKey = new AccessKey(),
    public invite_users_orgs: AccessKey = new AccessKey(),
    public private_access:    AccessKey = new AccessKey(),
    public reports:           AccessKey = new AccessKey(),
    public search:            AccessKey = new AccessKey(),
    public settings:          AccessKey = new AccessKey(),
    public show_empty_tab:    AccessKey = new AccessKey(),
    public tag_act:           AccessKey = new AccessKey(),
    public tag_companies:     AccessKey = new AccessKey(),
    public tag_content:       AccessKey = new AccessKey(),
    public tag_topics:        AccessKey = new AccessKey(),
    public tag_users_orgs:    AccessKey = new AccessKey(),
    public ws_core:           AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_Admin {
  constructor (
    public feature:   AccessKey = new AccessKey(),
    public orgs:      AccessKey = new AccessKey(),
    public roles:     AccessKey = new AccessKey(),
    public show_test: AccessKey = new AccessKey(),  // This is from np (not admin)
    public users:     AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_Communities {
  constructor (
    public core:            AccessKey = new AccessKey(),
    public pages:           AccessKey = new AccessKey(),
    public premium:         AccessKey = new AccessKey(),
    public premium_badges:  AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_Content {
  constructor (
    public avia_member_focus:   AccessKey = new AccessKey(),
    public avia_org_membership: AccessKey = new AccessKey(),
    public card:                AccessKey = new AccessKey(),
    public feature:             AccessKey = new AccessKey(),
    public reports:             AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Dashboard {
  constructor (
    public avia_admin: AccessKey = new AccessKey(),
    public avia_member_focus: AccessKey = new AccessKey(),
    public avia_org_membership: AccessKey = new AccessKey(),
    public checkin: AccessKey = new AccessKey(),
    public competition: AccessKey = new AccessKey(),
    public dashboard_feature: AccessKey = new AccessKey(),
    public dashboard_files: AccessKey = new AccessKey(),
    public dashboard_vis: AccessKey = new AccessKey(),
    public info_request: AccessKey = new AccessKey(),
    public inventory: AccessKey = new AccessKey(),
    public pulse: AccessKey = new AccessKey(),
    public premium: AccessKey = new AccessKey(),
    public premium_badges: AccessKey = new AccessKey(),
    public priorities: AccessKey = new AccessKey(),
    public trackers: AccessKey = new AccessKey(),
    public org_demo: AccessKey = new AccessKey(),
    public org_emails: AccessKey = new AccessKey(),
    public org_internal: AccessKey = new AccessKey(),
    public org_premium: AccessKey = new AccessKey(),
    public org_user_invite: AccessKey = new AccessKey(),
    public user_inactivate: AccessKey = new AccessKey(),
    public trackers_eligible: AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Files {
  constructor (
    public act_files:       AccessKey = new AccessKey(),
    public avia_insights:   AccessKey = new AccessKey(),
    public dashboard_files: AccessKey = new AccessKey(),
    public inventory_files: AccessKey = new AccessKey(),
    public priority_files:  AccessKey = new AccessKey(),
    public product_files:   AccessKey = new AccessKey(),
    public solco_files:     AccessKey = new AccessKey(),
    public ws_files:        AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_Inventory {
  constructor (
    public checkin:  AccessKey = new AccessKey(),
    public core:     AccessKey = new AccessKey(),
    public files:    AccessKey = new AccessKey(),
    public info_request: AccessKey = new AccessKey(),
    public internal: AccessKey = new AccessKey(),
    public premium:  AccessKey = new AccessKey(),
    public support:  AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Org {
  constructor (
    public dashboard_feature:       AccessKey = new AccessKey(),
    public feature:                 AccessKey = new AccessKey(),
    public org_company_interests:   AccessKey = new AccessKey(),
    public org_contacts:            AccessKey = new AccessKey(),
    public org_demo:                AccessKey = new AccessKey(),
    public org_internal:            AccessKey = new AccessKey(),
    public org_internal_settings:   AccessKey = new AccessKey(),
    public reports:                 AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_Priority {
  constructor(
    public core:           AccessKey = new AccessKey(),
    public files:          AccessKey = new AccessKey(),
    public private_access: AccessKey = new AccessKey(),
    public priorities_internal: AccessKey = new AccessKey(),
    public search:         AccessKey = new AccessKey(),
    public settings:       AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Pulse {
  constructor (
    public enabled:    AccessKey = new AccessKey(),
    public commenting: AccessKey = new AccessKey(),
    public question:   AccessKey = new AccessKey(),
    public setup:      AccessKey = new AccessKey(),
    public premium:    AccessKey = new AccessKey(),
    public premium_badges:    AccessKey = new AccessKey(),
    public configuration: AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Shell {
  constructor (
    public activity_feature: AccessKey = new AccessKey(),
    public admin_feature: AccessKey = new AccessKey(),
    public dashboard_feature: AccessKey = new AccessKey(),
    public org_switcher:  AccessKey = new AccessKey(),
    public org_premium:  AccessKey = new AccessKey(),
    public pulse: AccessKey = new AccessKey(),
    public pulse_enabled: AccessKey = new AccessKey(),
    public cm_feature:  AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_Solco {
  constructor(
    public avia_relationship:       AccessKey = new AccessKey(),
    public dashboard_feature:       AccessKey = new AccessKey(),
    public feature:                 AccessKey = new AccessKey(),
    public info_request:            AccessKey = new AccessKey(),
    public network:                 AccessKey = new AccessKey(),
    public premium:                 AccessKey = new AccessKey(),
    public premium_badges:          AccessKey = new AccessKey(),
    public raising_capital:         AccessKey = new AccessKey(),
    public solco_adoptions:         AccessKey = new AccessKey(),
    public solco_badging:           AccessKey = new AccessKey(),
    public solco_contacts:          AccessKey = new AccessKey(),
    public solco_demo:              AccessKey = new AccessKey(),
    public solco_files:             AccessKey = new AccessKey(),
    public solco_healthsystems:     AccessKey = new AccessKey(),
    public solco_internal:          AccessKey = new AccessKey(),
    public solco_internal_settings: AccessKey = new AccessKey(),
    public solco_people:            AccessKey = new AccessKey(),
    public solco_products:          AccessKey = new AccessKey(),
    public solco_products_listing:  AccessKey = new AccessKey(),
    public solco_reviews:           AccessKey = new AccessKey(),
    public tag_metrics:             AccessKey = new AccessKey(),
    public tag_solutions:           AccessKey = new AccessKey(),
    public tag_topics:              AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Topic {
  constructor(
    public avia_insights: AccessKey = new AccessKey(),
    public card: AccessKey = new AccessKey(),
    public card_approve: AccessKey = new AccessKey(),
    public card_comment: AccessKey = new AccessKey(),
    public card_comment_body: AccessKey = new AccessKey(),
    public card_comment_item: AccessKey = new AccessKey(),
    public card_comment_resolve: AccessKey = new AccessKey(),
    public card_evernote: AccessKey = new AccessKey(),
    public card_keywords: AccessKey = new AccessKey(),
    public card_notes: AccessKey = new AccessKey(),
    public card_owners: AccessKey = new AccessKey(),
    public card_settings: AccessKey = new AccessKey(),
    public card_settings_feature: AccessKey = new AccessKey(),
    public card_settings_owner: AccessKey = new AccessKey(),
    public cl_card: AccessKey = new AccessKey(),
    public draft_cards: AccessKey = new AccessKey(),
    public feature: AccessKey = new AccessKey(),
    public info_request: AccessKey = new AccessKey(),
    public network: AccessKey = new AccessKey(),
    public premium: AccessKey = new AccessKey(),
    public premium_badges: AccessKey = new AccessKey(),
    public reports: AccessKey = new AccessKey(),
    public tag_companies: AccessKey = new AccessKey(),
    public tag_content: AccessKey = new AccessKey(),
    public tag_topics: AccessKey = new AccessKey(),
    public tag_users_orgs: AccessKey = new AccessKey()
  ) { }
}

export class AccessKeychain_User {
  constructor (
    public avia_admin:              AccessKey = new AccessKey(),
    public feature:                 AccessKey = new AccessKey(),
    public profile:                 AccessKey = new AccessKey(),
    public avia:                    AccessKey = new AccessKey(),
    public info_request:            AccessKey = new AccessKey(),
    public internal:                AccessKey = new AccessKey(),
    public manage_connections:      AccessKey = new AccessKey(),
    public premium:                 AccessKey = new AccessKey(),
    public premium_badges:          AccessKey = new AccessKey(),
    public private_connections:     AccessKey = new AccessKey(),
    public profile_private_content: AccessKey = new AccessKey(),
    public contact_info:            AccessKey = new AccessKey(),
    public profile_content:         AccessKey = new AccessKey(),
    // public user_company_interests: AccessKey = new AccessKey(),
    // public user_contacts:          AccessKey = new AccessKey(),
    // public user_contact_info:      AccessKey = new AccessKey(),
    // public user_demo:              AccessKey = new AccessKey(),
    // public user_internal:          AccessKey = new AccessKey(),
    // public user_internal_settings: AccessKey = new AccessKey(),
  ) { }
}

export class AccessKeychain_Workspace {
  constructor (
    public chat:           AccessKey = new AccessKey(),
    public core:           AccessKey = new AccessKey(),
    public files:          AccessKey = new AccessKey(),
    public invite_users:   AccessKey = new AccessKey(),
    public premium:        AccessKey = new AccessKey(),
    public premium_badges: AccessKey = new AccessKey(),
    public show_empty_tab: AccessKey = new AccessKey(),
    public tag_users_orgs: AccessKey = new AccessKey(),
    public tag_companies:  AccessKey = new AccessKey(),
    public tag_topics:     AccessKey = new AccessKey()
  ) { }
}

export class AnalyticEvent {
  constructor (
    public event_type: string = undefined,
    public json_value: any = {},
    public string: string = undefined
  ) { }
}

export class AviaVerticalType {
  constructor (
    public id:         number = null,
    public name:       string = null,
    public short_name: string = null
  ) { }
}



/* * * B * * */
export class Badge {
  constructor (
    public link_type:          string  = undefined,
    public badge_type:         string  = undefined,
    public free_text:          string  = undefined,
    public strat_initiative:   number  = undefined,
    public strat_initiative_name:   string  = undefined,
    public capability_id:      number  = undefined,
    public capability_name:    string  = undefined,
    public solution_id:        number  = undefined,
    public solution_name:      string  = undefined,
    public awarded_dates:      Date[]  = [],
    public id:                 number  = undefined,
    public description:        string  = undefined,
    public descriptor:         string  = undefined,
    public products:           any     = []
  ) { }
}

export class BadgeAviaVetted {
  constructor (
    public awarded:             boolean = false,
    public awarded_date:        Date    = undefined,
    public description:         string  = undefined,
    public id:                  number  = undefined,
  ) { }
}

export class BadgeHighImpact {
  constructor (
    public awarded:             boolean = false,
    public awarded_date:        Date    = undefined,
    public description:         string  = undefined,
    public id:                  number  = undefined,
  ) { }
}

export class BadgetMarketVal {
  constructor (
    public awarded:              boolean = false,
    public awarded_date:         Date    = undefined,
    public hs_adoption_met:      boolean = false,
    public hs_adoption_met_desc: string  = undefined,
    public hs_recommended:       boolean = false,
    public hs_recommended_desc:  string  = undefined,
    public hs_scale_met:         boolean = false,
    public hs_scale_desc:        string  = undefined,
    public id:                   number  = undefined,
  ) { }
}

export class Banner {
  constructor (
    public id:                    number = undefined,
    public text:                  string = undefined,
    public image_url:             string = undefined,
    public btn_text:              string = undefined,
    public btn_url:               string = undefined,
    public btn_url_query_params:  string = undefined,
    public color:                 string = undefined,
    public url_type_id:           number = undefined
  ) {}
}

/* * * C * * */

export class CalEventIn_Orgs {
  constructor(
    public all:      Org[] = [],
    public hosts:    Org[] = [],
    public subjects: Org[] = [],
  ) { }
}

export class CalEventIn_Users {
  constructor(
    public all:      User[] = [],
    public owners:   User[] = [],
    public subjects: User[] = [],
  ) { }
}

export abstract class _CalEvent {
  // NOTE: These are all the member vars that are shared between CalEvent_In and CalEvent_Out.
  //       This class should never be instantiated directly.
  constructor(
    public channels:           Channel[]   = [],
    public communities:        Community[] = [],
    public description:        string      = undefined,
    public file_group:         number      = undefined,
    public id:                 number      = undefined,
    public link:               string      = undefined,
    public location:           string      = undefined,
    public name:               string      = undefined,
    public type:               number      = undefined,
  ) { }
}

export class CalEvent_In extends _CalEvent {
  // NOTE: These are the additional member vars that are unique to this class.
  //       This is the data set that we get from the back-end.
  constructor(
    public all_day:            boolean          = undefined,
    public created_by:         number           = undefined,
    public link_last_modified: Date             = undefined,
    public modified_by:        number           = undefined,
    public orgs:               CalEventIn_Orgs  = new CalEventIn_Orgs(),
    public time_end:           Date             = undefined,
    public time_start:         Date             = undefined,
    public topics:             Topic[]          = [],
    public users:              CalEventIn_Users = new CalEventIn_Users(),
  ) {
    super();
  }
}

export class CalEventOut_Org {
  constructor(
    public org_id:     number = undefined,
    public event_id:   number = undefined,
    public is_host:    number = undefined,
    public is_subject: number = undefined,
  ) { }
}

export class CalEventOut_Topic {
  constructor(
    public topic_id: number = undefined,
    public event_id: number = undefined,
  ) { }
}

export class CalEventOut_User {
  constructor(
    public user_id:    number = undefined,
    public event_id:   number = undefined,
    public is_owner:   number = undefined,
    public is_subject: number = undefined,
  ) { }
}

export class CalEvent_Out extends _CalEvent {
  // NOTE: These are the additional member vars that are unique to this class.
  //       This is the data set that we send to the back-end.
  constructor(
    public all_day:    number              = undefined,
    public orgs:       CalEventOut_Org[]   = [],
    public time_end:   string              = undefined,
    public time_start: string              = undefined,
    public topics:     CalEventOut_Topic[] = [],
    public users:      CalEventOut_User[]  = [],
  ) {
    super();
  }
}

export class Channel {
  constructor(
    public activity_id:           number       = null,
    public activity_name:         string       = null,
    public chat_group:            number       = null,
    //public cm:                    Community[]  = [new Community()],
    public companies_desc:        string       = null,
    public created:               Date         = null,
    public created_by:            number       = null,
    public created_by_obj:        User         = new User(),
    public description:           string       = null,
    public hosts:                 Org[]        = [new Org()],
    public id:                    number       = null,
    //public invite_perm_override_level: number = null,
    public is_ignored:            number       = null,
    public is_overview:           number       = null,
    public is_public:             number       = null,
    public is_published:          number       = null,
    public is_user_participating: boolean      = null,
    public is_org_participating:  boolean      = null,
    public metrics_desc:          string       = null,
    public modified:              Date         = null,
    public name:                  string       = null,
    public rd:                    any          = null,
    public show_pending:          number       = null,
    public show_request:          number       = null,
    //public topics:                Topic[]      = [ new Topic() ],
    public tracker:               Tracker      = new Tracker(),
  ) { }
}

export class ChannelSupport_ChannelInviteOverrideTypes {
  constructor(
    public id:          string = null,
    public name:        string = null,
    public description: string = null,
  ) { }
}

export class Channel_Support {
  constructor(
    public channel_invite_override_types: ChannelSupport_ChannelInviteOverrideTypes[] = [ new ChannelSupport_ChannelInviteOverrideTypes() ]
  ) { }
}

export class Color_Library {
  /*  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

      PLEASE, if you change a color in Color_Library, change it in custom.scss.
      This class mirrors our colors defined there.

      !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  constructor() { }
  public static white:                     string = '#ffffff';
  public static gray_100:                  string = '#f8f8f8';
  public static gray_150:                  string = '#F0F0F1';
  public static gray_175:                  string = '#EFEFEF';
  public static gray_200:                  string = '#e5e5e5';
  public static gray_300:                  string = '#dddddd';
  public static gray_400:                  string = '#cccccc';
  public static gray_500:                  string = '#ababab';
  public static gray_600:                  string = '#999999';
  public static gray_700:                  string = '#707070';
  public static gray_800:                  string = '#505050';
  public static gray_900:                  string = '#303030';
  public static black:                     string = '#000000';
  public static blue_super_light:          string = '#F2F6FA';
  public static blue_extra_light:          string = '#C6D1E1';
  public static blue_extra_super_light:    string = '#E5F2F2';
  public static blue_light:                string = '#0077bf';
  public static blue_dark:                 string = '#0066a3';
  public static blue_pale:                 string = '#56AADB';
  public static blue_pulse:                string = '#0076BC';
  public static blue_sky:                  string = '#3CA7E6';
  public static covid_blue:                string = '#44A4DB';
  public static teal_extra_light:          string = '#BEE4E3';
  public static teal_light:                string = '#67CBC9';
  public static teal_highlight:            string = '#EAF7FF';
  public static teal_dark:                 string = '#43B4B2';
  public static violet_extra_light:        string = '#E5CEE0';
  public static violet_light:              string = '#AB5B9B';
  public static violet_dark:               string = '#945487';
  public static green_extra_light:         string = '#DFE8D4';
  public static green_light:               string = '#B5D190';
  public static green_lime:                string = '#70963D';
  public static green_highlight:           string = '#E2EAD8';
  public static green_bg:                  string = '#89B055';
  public static green_dark:                string = '#81AC48';
  public static green_vida_loca:           string = '#A9C584';
  public static red_extra_light:           string = '#E9CECD';
  public static red_light:                 string = '#AA3C39';
  public static red_dark:                  string = '#922E2B';
  public static orange_extra_light:        string = '#FCEACE';
  public static orange_gold:               string = '#FF9E00';
  public static orange_light:              string = '#FC9552';
  public static orange_dark:               string = '#F58238';
  public static primary_dark:              string = '#273953';
  public static primary:                   string = '#0066A3';
  public static primary_light:             string = Color_Library.blue_super_light;
  public static primary_mid:               string = Color_Library.blue_light;
  public static primary_mid_light:         string = '#afc6ec';
  public static pulse_benchmark:           string = Color_Library.green_lime;
  public static pulse_benchmark_secondary: string = Color_Library.green_vida_loca;
  public static pulse_score:               string = Color_Library.blue_pulse;
  public static pulse_score_secondary:     string = Color_Library.blue_pale;
  public static accent:                    string = Color_Library.green_dark;
  public static accent_light:              string = Color_Library.green_light;
  public static danger:                    string = '#D9534F';
  public static warning:                   string = '#F0AD4E';
  public static pulse_score_list = {
    0: '#D8F1FF',
    1: '#A0DCFF',
    2: '#40B4FF',
    3: '#0074BF',
    4: '#004A79',
    5: Color_Library.primary_dark,
  }
  public static pulse_benchmark_list = {
    0: '#D4E5BE',
    1: '#B7CD99',
    2: '#A2C179',
    3: '#83A753',
    4: '#648B30',
    5: '#426415',
  };
}

export class Comment {
  constructor(
    public content:  string = null,
    public created:  Date   = null,
    public group_id: number = null,
    public id:       number = null,
    public user:     number = null,
  ) { }
}

export class CommentGroup_Results {
  constructor(
    public id:      number = null,
    public name:    string = null,
    public created: Date   = null
  ) {  }
}

export class CommentGroup {
  constructor(
    public name: string                    = null,
    public message: string                 = null,
    public results: CommentGroup_Results[] = [ new CommentGroup_Results() ],
    public top_level_comments: CommentGroup_Results[] = [ new CommentGroup_Results() ],
  ) {  }
}

export class Community_Org {
  constructor(
    public id:            number     = undefined,
    public name:          string     = undefined,
    public logo:          string     = undefined,
    public hq_addr:       Org_HqAddr = undefined,
    public url_linkedin:  string     = undefined,
    public url_twitter:   string     = undefined,
    public phonenum:      string     = undefined
  ) {  }
}

export class Community_CreatedBy {
  constructor(
    public id:        number        = undefined,
    public firstname: string        = undefined,
    public lastname:  string        = undefined,
    public fullname:  string        = undefined,
    public avatar:    string        = undefined,
    public org:       number        = undefined,
    public org_obj:   Community_Org = new Community_Org(),
    public status:    number        = undefined,
  ) {  }
}

export class Community_ModifiedBy {
  constructor(
    public id:        number        = undefined,
    public firstname: string        = undefined,
    public lastname:  string        = undefined,
    public fullname:  string        = undefined,
    public avatar:    string        = undefined,
    public org:       number        = undefined,
    public org_obj:   Community_Org = new Community_Org(),
    public status:    number        = undefined,
  ) {  }
}

export class Community_Rd {
  constructor(
    public type: string = undefined,
  ) {  }
}

export class Community_Status {
  constructor(
    public id:   number = undefined,
    public name: string = undefined,
  ) {  }
}

export class Community_Visibility {
  constructor(
    public id:   number = undefined,
    public name: string = undefined,
  ) {  }
}

export class Community {
  constructor(
    public id:              number               = undefined,
    public org:             number               = undefined,
    public org_obj:         Community_Org        = new Community_Org(),
    public owners:          number[]             = [],
    public owner_objs:      User[]               = [],
    public name:            string               = undefined,
    public description:     string               = undefined,
    public status:          number               = undefined,
    public status_obj:      Community_Status     = new Community_Status(),
    public logo:            string               = undefined,
    public white_logo:      string               = undefined,
    public rank:            number               = undefined,
    public visibility:      number               = undefined,
    public visibility_obj:  Community_Visibility = new Community_Visibility(),
    public color:           string               = undefined,
    public landing_page:    number               = undefined,
    public created_by:      number               = undefined,
    public created_by_obj:  Community_CreatedBy  = new Community_CreatedBy(),
    public modified_by:     number               = undefined,
    public modified_by_obj: Community_ModifiedBy = new Community_ModifiedBy(),
    public rd:              Community_Rd         = new Community_Rd(),
    public page_id:         any                  = undefined,
    public page_visibility: number               = undefined
  ) {  }
}

export class CommunityPage_HeaderType {
  constructor(
    public id:   number = undefined,
    public name: string = undefined,
  ){  }
}

export class CommunityPage_Rd {
  constructor(
    public type: string = undefined,
  ) {  }
}

export class CommunityPage_Visibility {
  constructor(
    public id:   number = undefined,
    public name: string = undefined,
  ) {  }
}

export class CommunityPage {
  constructor(
    public id:              number                   = undefined,
    public cm_id:           number                   = undefined,
    public name:            string                   = undefined,
    public short_desc:      string                   = undefined,
    public description:     string                   = undefined,
    public header:          string                   = undefined,
    public header_type:     number                   = undefined,
    public header_type_obj: CommunityPage_HeaderType = new CommunityPage_HeaderType(),
    public visibility:      number                   = undefined,
    public visibility_obj:  CommunityPage_Visibility = new CommunityPage_Visibility(),
    public title_people:    string                   = undefined,
    public title_content:   string                   = undefined,
    public title_calender:  string                   = undefined,
    public title_companies: string                   = undefined,
    public title_products:  string                   = undefined,
    public title_act:       string                   = undefined,
    public title_topics:    string                   = undefined,
    public title_pages:     string                   = undefined,
    public title_pulse:     string                   = undefined,
    public created:         Date                     = undefined,
    public created_by:      number                   = undefined,
    public created_by_obj:  Community_CreatedBy      = new Community_CreatedBy(),
    public modified:        Date                     = undefined,
    public modified_by:     number                   = undefined,
    public modified_by_obj: Community_ModifiedBy     = new Community_ModifiedBy(),
    public rd:              CommunityPage_Rd         = new CommunityPage_Rd(),
  ) {  }
}

export class CommunitySupport_Status_Types {
  constructor(
    public id:   number = null,
    public name: string = null,
  ) {  }
}

export class CommunitySupport_Visibility_Types {
  constructor (
    public id:   number = null,
    public name: string = null,
  ) {  }
}

export class Community_Support {
  constructor(
    public cm_status_types:     CommunitySupport_Status_Types[]     = [ new CommunitySupport_Status_Types() ],
    public cm_visibility_types: CommunitySupport_Visibility_Types[] = [ new CommunitySupport_Visibility_Types() ],
  ) {  }
}

export class Connections_Connection {
  constructor(
    public outbound: number = null,
    public inbound:  number = null
  ) {  }
}

export class Connections {
  constructor(
    public connection: Connections_Connection = new Connections_Connection()
  ) {  }
}

export class Content_MF_MT {
  constructor(
    public id:   number = null,
    public name: string = null,
  ) { }
}

export class Content {
  constructor(
    public author:           string          = undefined,
    public produced_by:      any             = undefined,
    public cm:               Community[]     = undefined,
    public created:          Date            = undefined,
    public chat_group:       number          = undefined,
    public chat_count:       number          = undefined,
    public description:      string          = undefined,
    public file_name:        string          = undefined,
    public for_avia_only:    number          = undefined,
    public group_ids:        number[]        = undefined,
    public groups:           Content_Group[] = undefined,
    public id:               number          = undefined,
    public interested:       number          = undefined,
    public is_public:        number          = undefined,
    public last_accessed:    Date            = undefined,
    public link:             string          = undefined,
    public liked:            number          = undefined,
    public likes_count:      number          = undefined,
    public logo:             string          = undefined,
    public logo_link:        string          = undefined,
    public md5_hash:         string          = undefined,
    public member_focus:     Content_MF_MT[] = [],
    public member_types:     Content_MF_MT[] = [],
    public modified:         Date            = undefined,
    public name:             string          = undefined,
    public org:              number          = undefined,
    public org_types:        number[]        = [],
    public permitted:        boolean         = undefined,
    public produced_by_obj:  any             = undefined,
    public product_category: number          = undefined,
    public published_date:   Date            = undefined,
    public recommended:      number          = undefined,
    public rd:               any             = undefined,
    public s3_filename:      string          = undefined,
    public source:           Content_Source  = new Content_Source(),
    public source_obj:       any             = undefined,
    public special_content:  number          = undefined,
    public type:             number          = undefined,
    public type_obj:         Content_TypeObj = new Content_TypeObj(),
    public user:             number          = undefined,
    public user_obj:         User            = new User(),
  ) { }
}

export class Content_Group {
  constructor(
    public name:           string   = undefined,
    public id:             number   = undefined,
    public type:           number   = undefined
  ) { }
}

export class Content_Source {
  constructor(
    public created_by: number = undefined,
    public id:         number = undefined,
    public logo_link:  string = undefined,
    public source:     string = undefined
  ) { }
}

export class Content_TypeObj {
  constructor(
    public id:          number = null,
    public name:        string = null
  ) {  }
}

export class Crunchbase {
  constructor(
    public id:                        number    = null,
    public company_name:              string    = null,
    public acquirees:                 Object [] = null,
    public acquirers:                 Object [] = null,
    public company_description:       string    = null,
    public company_logo:              string    = null,
    public created:                   Date      = null,
    public crunchbase:                string    = null,
    public domain:                    string    = null,
    public funding_rounds:            any[]     = null,
    public headquarters_locations:    string    = null,
    public investments:               Object [] = null,
    public investors:                 string [] = null,
    public linkedin:                  string    = null,
    public modified:                  Date      = null,
    public num_employees:             string    = null,
    public operating_status:          string    = null,
    public strategic_partners:        Object[]  = null,
    public total_equity_funding:      number    = null,
    public twitter:                   string    = null,
    public uuid:                      string    = null,
    public website:                   string    = null,
    public year_founded:              Date      = null
  ) { }
}



/* * * D * * */
export class AviaDeptTypes {
  constructor(
    public id:      number = null,
    public name:    string = null,
    public parent:  number = null,
    public created: Date   = null
  ) {  }
}



/* * * E * * */
abstract class _ENUM {
  static index: number = -1;
  static addIndex(): number { this.index += 1; return this.index; }
}

export class E_OnboardingModalTypes extends _ENUM {
  static readonly GENERAL: number = E_OnboardingModalTypes.addIndex();
  static readonly SOLCO: number = E_OnboardingModalTypes.addIndex();
  static readonly AHA: number = E_OnboardingModalTypes.addIndex();
  static readonly PARTNER: number = E_OnboardingModalTypes.addIndex();

}

export class E_AlertModalTypes extends _ENUM {
  static readonly ERROR: number = E_AlertModalTypes.addIndex();
  static readonly WARNING: number = E_AlertModalTypes.addIndex();
  static readonly SUCCESS: number = E_AlertModalTypes.addIndex();
  static readonly GENERAL: number = E_AlertModalTypes.addIndex();
}

export class E_ConnectionManagerActions extends _ENUM {
  static readonly CONNECT:    number = E_ConnectionManagerActions.addIndex();
  static readonly DISCONNECT: number = E_ConnectionManagerActions.addIndex();
  static readonly BLOCK:      number = E_ConnectionManagerActions.addIndex();
}

export class E_ConnectionStatus extends _ENUM {
  static readonly NONE:            number = E_ConnectionStatus.addIndex();
  static readonly PENDING:         number = E_ConnectionStatus.addIndex(); // NOTE: This MUST match what is in '_types_user_connection_states' in the DB (pending)
  static readonly CONNECTED:       number = E_ConnectionStatus.addIndex(); // NOTE: This MUST match what is in '_types_user_connection_states' in the DB (accepted)
  static readonly BLOCKED:         number = E_ConnectionStatus.addIndex(); // NOTE: This MUST match what is in '_types_user_connection_states' in the DB (blocked)
  static readonly WE_BLOCKED_THEM: number = E_ConnectionStatus.addIndex();
  static readonly THEY_ASKED_US:   number = E_ConnectionStatus.addIndex();
  static readonly ME:              number = E_ConnectionStatus.addIndex();
}

export class E_EventResources {
  static readonly CHANNELS:    string = 'channels';
  static readonly CM:          string = 'communities';
  static readonly COMMUNITIES: string = E_EventResources.CM; // NOTE: This is a clone of 'CM' on purpose.
  static readonly ORGS:        string = 'orgs';
  static readonly TOPICS:      string = 'topics';
  static readonly USERS:       string = 'users';
}

export class E_Modal_Operating_Mode extends _ENUM {
  static readonly getPropName = (value:number): string => {
    for ( let k of Object.keys(E_Modal_Operating_Mode) ) {
      if (k !== 'index' && E_Modal_Operating_Mode[k] === value) return k;
    }
    return 'INVALID';
  }

  static readonly ADD:    number = E_Modal_Operating_Mode.addIndex();
  static readonly IMPORT: number = E_Modal_Operating_Mode.addIndex();
  static readonly EDIT:   number = E_Modal_Operating_Mode.addIndex();
}

export class E_SomethingHappened extends _ENUM {
  // NOTE: This is used by the 'Something Happened' broadcast system. Please keep this in sync with the following:
  // - universal-add.component
  // - avia-notifications.component

  // Universal Add Events
  static readonly ACTIVITY_ADDED:       number = E_SomethingHappened.addIndex();
  static readonly COMMUNITY_ADDED:      number = E_SomethingHappened.addIndex();
  static readonly CONTENT_ADDED:        number = E_SomethingHappened.addIndex();
  static readonly CONTENT_LOGO_ADDED:   number = E_SomethingHappened.addIndex();
  static readonly FILES_CHANGED:        number = E_SomethingHappened.addIndex();
  static readonly HOME_CLICKED:         number = E_SomethingHappened.addIndex();
  static readonly NEWSFEED_REFRESHED:   number = E_SomethingHappened.addIndex();
  static readonly ONBOARDING_COMPLETED: number = E_SomethingHappened.addIndex();
  static readonly PINNED_LOCATION_ADDED:number = E_SomethingHappened.addIndex();
  static readonly TOPIC_ADDED:          number = E_SomethingHappened.addIndex();
  static readonly USER_ADDED:           number = E_SomethingHappened.addIndex();
  static readonly USER_SIGNED_IN:       number = E_SomethingHappened.addIndex();
  static readonly WORKSPACE_ADDED:      number = E_SomethingHappened.ACTIVITY_ADDED; // NOTE: This is a clone of 'ACTIVITY_ADDED' on purpose.

  // User Connection Event
  static readonly USER_CONNECTION: number = E_SomethingHappened.addIndex();
}

export class Elastic_Highlights {
  constructor(
    public keywords: string = null,
  ) { }
}


/* * * F * * */
export class Files_LocationIds {
  constructor(
    public act_id:     number = undefined,
    public ch_id:      number = undefined,
    public id:         number = undefined,
    public inv_id:     number = undefined,
    public org_id:     number = undefined,
    public pri_id:     number = undefined,
    public product_id: number = undefined,
  ) { }
}

export class Folder {
  constructor (
    public id:               number    = null,
    public name:             string    = null,
    public created:          Date      = null,
    public created_by:       number    = null,
    public files:            Content[] = [],
    public is_public:        boolean   = null,
    public modified:         Date      = null,
    public modified_by:      number    = null,
    public org:              number    = null,
    public product_category: number    = null
  ) {}
}



/* * * G * * */



/* * * H * * */
export class HS_Account_Details {
  constructor (
    public npr:                            number = null,
    public next_opt_out:                   Date   = null,
    public avia_co_region:                 number = null,
    public contract_start_date:            Date   = null,
    public contract_end_date:              Date   = null,
    public checkin_vis:                    number = null,
    public status:                         number = null,
    public dashboard_vis:                  number = null,
    public hs_membership:                  number = null,
    public member_since:                   Date   = null,
    public org_contacts:                   User[] = [],
    public avia_team:                      User[] = [],
    public renewal_index:                  number = null,
  ) {  }
}



/* * * I * * */
export class Interests_Type {
  constructor(
    public name: string = null,
  ) {  }
}

export class InterestsUser {
  constructor(
    public id:       number         = null,
    public name:     string         = null,
    public shared:   boolean        = false, // NOTE: this key is Virtual. It is used in Member Profiles to show common interests.
    public type:     number         = null,
    public type_obj: Interests_Type = new Interests_Type(),
  ) {  }
}

export class InvItem_ScIdObj_CrunchbaseObj {
  constructor(
    public logos:  string = null,
    public domain: string  = null,
  ) { }
}

export class InvItem_ScIdObj {
  constructor(
    public id:                            number                         = null,
    public logo:                          string                         = null,
    public name:                          string                         = null,
    public color:                         any                            = null,
    public crunchbase_id:                 number                         = null,
    public crunchbase_obj:                InvItem_ScIdObj_CrunchbaseObj  = new InvItem_ScIdObj_CrunchbaseObj(),
    public avia_relationship_manager_obj: Org_AviaRelationshipManagerObj = new Org_AviaRelationshipManagerObj(),
  ) { }
}

export class InvItem {
  constructor(
    public account_follow_up:                string          = undefined,
    public adoption_eligible:                number          = undefined,
    public avia_id:                          number          = undefined,
    public avia_involvement:                 number          = undefined,
    public avia_imp_owner:                   number          = undefined,
    public avia_opp_owner:                   number          = undefined,
    public avia_imp_owner_obj:               User            = new User(),
    public avia_opp_owner_obj:               User            = new User(),
    public ce_team_pri:                      number          = undefined,
    public created:                          Date            = undefined,
    public created_by:                       number          = undefined,
    public created_by_obj:                   User            = new User(),
    public def_of_scale:                     string          = undefined,
    public def_of_scale_done:                number          = undefined,
    public description:                      string          = undefined,
    public exc_team_pri:                     number          = undefined,
    public file_group:                       number          = undefined,
    public fin_acv:                          number          = undefined,
    public fin_acv_scale:                    number          = undefined,
    public fin_adjusted_total_con_val:       number          = undefined,
    public fin_adjusted_total_con_val_scale: number          = undefined,
    public fin_annual_revenue:               number          = undefined,
    public fin_business_history:             number          = undefined,
    public fin_con_fiscal_period:            string          = undefined,
    public fin_current_con_date:             Date            = undefined,
    public fin_estimate:                     number          = undefined,
    public fin_per_chance_scaling:           number          = undefined,
    public fin_per_chance_scaling_def:       number          = undefined,
    public fin_per_prob_contracting:         number          = undefined,
    public fin_per_prob_contracting_def:     number          = undefined,
    public fin_quarterly_key_opportunity:    number          = undefined,
    public fin_renewal_date:                 Date            = undefined,
    public fin_term_length:                  number          = undefined,
    public fin_total_con_val:                number          = undefined,
    public hs_id:                            number          = undefined,
    public hs_id_obj:                        Org             = undefined,
    public id:                               number          = undefined,
    public is_open:                          number          = undefined,
    public is_public:                        number          = undefined,
    public is_tracked:                       number          = undefined,
    public location_desc:                    string          = undefined,
    public metrics_desc:                     string          = undefined,
    public modified:                         Date            = undefined,
    public modified_by:                      number          = undefined,
    public modified_by_obj:                  User            = new User(),
    public name:                             string          = undefined,
    public near_goals:                       string          = undefined,
    public near_goals_done:                  number          = undefined,
    public needs_attention:                  number          = undefined,
    public next_steps:                       string          = undefined,
    public owner:                            number          = undefined,
    public owner_obj:                        User            = new User(),
    public quart_exc_goal:                   string          = undefined,
    public quart_opp_goal:                   string          = undefined,
    public rd:                               any             = undefined,
    public sc_id:                            number          = undefined,
    public sc_id_obj:                        InvItem_ScIdObj = new InvItem_ScIdObj(),
    public scale_eligible:                   number          = undefined,
    public is_company_goal:                  number          = undefined,
    public solution_follow_up:               string          = undefined,
    public status:                           number          = undefined,
    public st_date_01:                       Date            = undefined,
    public st_date_02:                       Date            = undefined,
    public st_date_03:                       Date            = undefined,
    public st_date_04:                       Date            = undefined,
    public st_date_05:                       Date            = undefined,
    public st_date_06:                       Date            = undefined,
    public st_date_07:                       Date            = undefined,
    public st_date_08:                       Date            = undefined,
    public st_date_cl:                       Date            = undefined,
    public st_daysin_01:                     number          = undefined,
    public st_daysin_02:                     number          = undefined,
    public st_daysin_03:                     number          = undefined,
    public st_daysin_04:                     number          = undefined,
    public st_daysin_05:                     number          = undefined,
    public st_daysin_06:                     number          = undefined,
    public st_daysin_07:                     number          = undefined,
    public st_daysin_08:                     number          = undefined,
    public st_daysin_09:                     number          = undefined,
    public st_days_open:                     number          = undefined,
    public status_obj:                       any             = undefined,
    public target_contract:                  Date            = undefined,
    public target_pilot_decision:            Date            = undefined,
    public target_scaled_solution:           Date            = undefined,
    public touch_level:                      number          = undefined,
    public touch_level_obj:                  any             = undefined,
    public products:                         any             = undefined, // angular8: added to fix error when building prod: "Property does not exist"
    public capabilities:                     any             = undefined, // angular8: added to fix error when building prod: "Property does not exist"
    public solutions:                        any             = undefined, // angular8: added to fix error when building prod: "Property does not exist"
    public team:                             any             = undefined, // angular8: added to fix error when building prod: "Property does not exist"
  ) { }
}

export class InvSpecialUsers {
    constructor(
      public analytics_owner:   User[] = [],
      public project_manager:   User[] = [],
      public executive_sponsor: User[] = [],
      public budget_owner:      User[] = [],
    ) {  }
}



/* * * J * * */



/* * * K * * */
export class KmSupport_IdName {
  constructor(
    public id:   number = null,
    public name: string = null
  ) {  }
}

export class KmSupport_NavbarConfig {
  constructor (
    public categories:                       KmSupport_NavbarConfig_Categories[] = [ new KmSupport_NavbarConfig_Categories() ],
    public default_active_category_level_id: number                              = null,
    public default_active_category_name:     string                              = null
  ) {  }
}

export class KmSupport_NavbarConfig_Categories {
  constructor (
    public level_id:          number = null,
    public name:              string = null,
    public opened_categories: number[] = null,
    public level_next_id:     number = null,
    public level_prev_id:     number = null
  ) {  }
}

export class KmSupport {
  constructor(
    public avia_vertical_types:              AviaVerticalType[]       = [ new AviaVerticalType() ],
    public content_types:                    KmSupport_IdName[]       = [ new KmSupport_IdName() ],
    public km_card_types:                    KmSupport_IdName[]       = [ new KmSupport_IdName() ],
    public km_metric_measure_types:          KmSupport_IdName[]       = [ new KmSupport_IdName() ],
    public km_metric_stakeholders_types:     KmSupport_IdName[]       = [ new KmSupport_IdName() ],
    public km_metric_avia_category_types:    KmSupport_IdName[]       = [ new KmSupport_IdName() ],
  ) {  }
}

export class KnowledgeCard {
  constructor(
    public activities:                       number  = null,
    public active:                           boolean = null,
    public approved:                         Date    = null,
    public avia_primary_owner:               number  = null,
    public avia_primary_owner_obj:           User    = new User(),
    public avia_primary_vertical_owner:      number  = null,
    public avia_primary_vertical_owner_obj:  User    = new User(),
    public cap_people_score:                 number  = null,
    public cap_people_comment:               string  = null,
    public cap_process_score:                number  = null,
    public cap_process_comment:              string  = null,
    public cap_tech_score:                   number  = null,
    public cap_tech_comment:                 string  = null,
    public cap_policies_score:               number  = null,
    public cap_policies_comment:             string  = null,
    public cap_consumer_experiance:          number  = null,
    public cap_finance:                      number  = null,
    public cap_growth:                       number  = null,
    public cap_efficiency:                   number  = null,
    public cap_experience:                   number  = null,
    public cap_quality:                      number  = null,
    public cap_wgll_text:                    string  = null,
    public cap_role_of_digital:              string  = null,
    public cap_core_considerations:          string  = null,
    public cap_prerequisites:                string  = null,
    public cap_value_at_stake:               string  = null,
    public cap_stakeholders_text:            string  = null,
    public cap_additional_context:           string  = null,
    public chat_group:                       number  = null,
    public comment_group:                    number  = null,
    public content:                          number  = null,
    public created:                          Date    = null,
    public created_by:                       number  = null,
    public description:                      string  = null,
    public flags:                            number  = null,
    public following:                        boolean = null,
    public id:                               number  = null,
    public is_published:                     number  = null,
    public key_metric:                       number  = null,
    public last_approved_by:                 number  = null,
    public last_modified_by:                 number  = null,
    public last_modified_by_obj:             User    = new User(),
    public level:                            number  = null, // artificially will be one of [0,1,2]
    public metrics:                          any     = null,
    public metric_baseline_measurement:      string  = null,
    public metric_benchmarks:                string  = null,
    public metric_collection_method:         string  = null,
    public metric_denominator:               string  = null,
    public metric_exclusion_criteria:        string  = null,
    public metric_strategic_indicator:       boolean = null,
    public metric_goals:                     string  = null,
    public metric_inclusion_criteria:        string  = null,
    public metric_early_indicator:           boolean = null,
    public metric_measure_type:              number  = null,
    public metric_measure_type_obj:          any     = null,
    public metric_measurement_period:        string  = null,
    public metric_numerator:                 string  = null,
    public metric_other_considerations:      string  = null,
    public metric_owner_role:                string  = null,
    public metric_potential_risks:           string  = null,
    public metric_risk:                      boolean = null,
    public metric_stakeholders:              any[]   = null,
    public metric_categories:                any[]   = null,
    public metric_strategies:                string  = null,
    public modified:                         Date    = null,
    public name:                             string  = null,
    public num_likes:                        number  = null,
    public notes:                            string  = null,
    public operational_considerations:       string  = null,
    public related_count:                    number  = null,
    public shared:                           boolean = null,
    public short_desc:                       string  = null,
    public solutions:                        any     = null,
    public solution_type:                    string  = null,
    public status:                           number  = null,
    public topics:                           any     = null,
    public type:                             number  = null,
    public type_name:                        string  = null,
    public type_obj:                         Object  = null,
    public users_count:                      number  = null,
    public users_know_about:                 any[]   = null
  ) { }
}



/* * * L * * */
export class LocationTypes_Obj {
  constructor (
    public name:   string,
    public active: boolean = false
  ) { }
}

export class LocationTypes {
  constructor (
    public Activities:     LocationTypes_Obj = new LocationTypes_Obj('Activities'),
    public Admin:          LocationTypes_Obj = new LocationTypes_Obj('Admin'),
    public Channels:       LocationTypes_Obj = new LocationTypes_Obj('Channels'),
    public Companies:      LocationTypes_Obj = new LocationTypes_Obj('Companies'),
    public ContentLibrary: LocationTypes_Obj = new LocationTypes_Obj('ContentLibrary'),
    public Dashboard_AVIA: LocationTypes_Obj = new LocationTypes_Obj('Dashboard_AVIA'),
    public Dashboard_Hs:   LocationTypes_Obj = new LocationTypes_Obj('Dashboard_Hs'),
    public Dashboard_Sc:   LocationTypes_Obj = new LocationTypes_Obj('Dashboard_Sc'),
    public Developer:      LocationTypes_Obj = new LocationTypes_Obj('Developer'),
    public Feedback:       LocationTypes_Obj = new LocationTypes_Obj('Feedback'),
    public Insights:       LocationTypes_Obj = new LocationTypes_Obj('Insights'),
    public Inventory:      LocationTypes_Obj = new LocationTypes_Obj('Inventory'),
    public KnowledgeMap:   LocationTypes_Obj = new LocationTypes_Obj('KnowledgeMap'),
    public Members:        LocationTypes_Obj = new LocationTypes_Obj('Members'),
    public Newsfeed:       LocationTypes_Obj = new LocationTypes_Obj('Newsfeed'),
    public Priorities:     LocationTypes_Obj = new LocationTypes_Obj('Priorities'),
    public Products:       LocationTypes_Obj = new LocationTypes_Obj('Products'),
    public Search:         LocationTypes_Obj = new LocationTypes_Obj('Search'),
    public Settings:       LocationTypes_Obj = new LocationTypes_Obj('Settings'),
    public Users:          LocationTypes_Obj = new LocationTypes_Obj('Users'),
    public Workspaces:     LocationTypes_Obj = new LocationTypes_Obj('Workspaces'),
  ) { }
}

export class Location {
  public locations: LocationTypes;

  constructor ( locations: LocationTypes = null ) {
    this.locations = (locations !== null) ? locations : new LocationTypes();
  }

  private clearLocation() {
    for(let l in this.locations) {
      this.locations[l].active = false;
    }
  }

  public setLocation(url: string) {
    this.clearLocation();
    // These are in a particular order. Do not rearrange unless you test each route.
    switch (true) {
      case url.includes('/activities/ws'):   this.locations.Workspaces.active     = true; break;
      case url.includes('/activities'):      this.locations.Activities.active     = true; break;
      case url.includes('/channel'):         this.locations.Channels.active       = true; break;
      case url.includes('/ws'):              this.locations.Activities.active     = true; break;
      case url.includes('/inventory'):       this.locations.Inventory.active      = true; break;
      case url.includes('/priority'):        this.locations.Priorities.active     = true; break;
      case url.includes('/intelligence/km'): this.locations.KnowledgeMap.active   = true; break;
      case url.includes('/intelligence/cl'): this.locations.ContentLibrary.active = true; break;
      case url.includes('/dashboard/avia'):  this.locations.Dashboard_AVIA.active = true; break;
      case url.includes('/dashboard/hs'):    this.locations.Dashboard_Hs.active   = true; break;
      case url.includes('/dashboard/sc'):    this.locations.Dashboard_Sc.active   = true; break;
      case url.includes('/product'):         this.locations.Products.active       = true; break;
      case url.includes('/sc'):              this.locations.Companies.active      = true; break;
      case url.includes('/start'):           this.locations.Newsfeed.active       = true; break;
      case url.includes('/members'):         this.locations.Members.active        = true; break;
      case url.includes('/insights'):        this.locations.Insights.active       = true; break;
      case url.includes('/in'):              this.locations.Users.active          = true; break;
      case url.includes('/admin'):           this.locations.Admin.active          = true; break;
      case url.includes('/developer'):       this.locations.Developer.active      = true; break;
      case url.includes('/feedback'):        this.locations.Feedback.active       = true; break;
      case url.includes('/search'):          this.locations.Search.active         = true; break;
      default:                               this.locations.Newsfeed.active       = true; // Default to Newsfeed/Home page
    }
  }

  public getLocation():string {
    for(let l in this.locations) {
      if(this.locations[l].active === true) return l;
    }
    return undefined;
  }
}



/* * * M * * */
export class MemberMemberFocus_Type {
  constructor(
    public name: string = undefined,
  ) { }
}

export class Member_MemberFocus {
  constructor(
    public id:   number                 = undefined,
    public name: string                 = undefined,
    public Type: MemberMemberFocus_Type = new MemberMemberFocus_Type(),
  ) { }
}

export class Member_MemberLevels {
  constructor(
    public id:   number = undefined,
    public name: string = undefined,
  ) { }
}

export class Member_MemberTypes {
  constructor(
    public id:   number = undefined,
    public name: string = undefined,
  ) { }
}

export class Member {
  constructor(
    public id:               number                = null,
    public member_focus:     Member_MemberFocus[]  = [],
    public member_levels:    Member_MemberLevels[] = [],
    public member_id:        number                = null,
    public org_obj:          Org                   = new Org(),
    public member_types:     Member_MemberTypes[]  = [],
    public member_types_ids: number[]              = [],
  ) { }
}



/* * * N * * */
export class NavTab_Style {
  constructor(
    public bg_color:          string  = null,
    public bg_color_active:   string  = null,
    public bold:              boolean = null,
    public font_color:        string  = null,
    public font_color_active: string  = null,
  ){ }
};

export abstract class _Nav_Tab_Group {
  public  active_tab:       number = -1;
  public  background_color: string = Color_Library.white;
  public  ready:            boolean = false;

  public font_color?: string = Color_Library.gray_600;

  public tabs:     Nav_Tab[] = []; // angular8: was protected, changed to public to fix npm run build-prod
  protected tabs_obj: Object = {};

  constructor(
    tabs:             Nav_Tab[] = [],
    background_color: string,
    font_color:       string,
  ) {
    if (background_color !== undefined) this.background_color = background_color;
    if (font_color !== undefined) this.font_color = font_color;
  }

  private getFirstVisibleTabIndex(): number {
    for (const tab of this.tabs) {
      if (tab.show === true) {
        return tab.index;
      }
    }
    return -1;
  }

  private sanitizeName(dirty_name: string): string {
    let cleaned_name: string = '';
    cleaned_name = (dirty_name !== undefined ? dirty_name : '').replace(/[&%#!\(\)\[\]\{\}\*\-]/gi, '');
    cleaned_name = cleaned_name.replace(/[\s\.]/gi, '_');
    if ( /^[\d]{1,}$/.test(cleaned_name) ) cleaned_name = `_${cleaned_name}`;
    return cleaned_name.toLowerCase();
  }

  public addTab(tab: Nav_Tab): Nav_Tab {
    if( !(tab.style.font_color) ) tab.font_color = this.font_color;
    tab.index = Object.keys(this.tabs_obj).length;
    let tab_name = this.sanitizeName(tab.name);
    this.tabs_obj[tab_name] = tab;
    this.tabs.push(this.tabs_obj[tab_name]);
    this.ready = true;
    return this.tabs_obj[tab_name];
  }

  public clearTabs(): void {
    this.tabs.length = 0;
    this.tabs_obj = {};
    this.active_tab = -1;
    this.ready = false;
  }

  public deactivateAllTabs(): void {
    for (const tab of this.tabs) {
      tab.active = false;
    }
  }

  public getActiveTabName(): string {
    if (this.tabs[this.active_tab]) {
      return this.tabs[this.active_tab].name;
    }
    return undefined;
  }

  public getTabByName(name: string): Nav_Tab {
    name = this.sanitizeName(name);                   // Make the name Object Key compatible
    if (this.tabs_obj[name] === undefined) {          // If we don't have a tab of this name use the first one that is allowed to be shown, instead!
      let tab_index = this.getFirstVisibleTabIndex();
      if (tab_index === -1) return undefined;         // If no tabs are allowed to be shown, bail!
      return this.tabs[tab_index];
    }
    return this.tabs_obj[name];
  }

  public selectActiveTab(): Nav_Tab {
    if (this.tabs.length === 0) return;
    if (this.active_tab !== undefined && this.active_tab >= 0) {
      return this.switchTab(this.active_tab);
    } else {
      for (const tab of this.tabs) {
        if (tab.show && tab.active) {
          this.active_tab = tab.index;
          return tab;
        }
      }
    }
    this.active_tab = this.getFirstVisibleTabIndex();
    if (this.active_tab < 0) return;
    this.tabs[this.active_tab].active = true;
    return this.tabs[this.active_tab];
  }

  public selectFirstTab(): Nav_Tab {
    if (this.tabs.length === 0) return;
    if (this.active_tab === -1) this.deactivateAllTabs();
    for (const tab of this.tabs) {
      if (tab.show) {
        if (this.active_tab > -1) this.tabs[this.active_tab].active = false;
        this.active_tab = tab.index;
        tab.active = true;
        return this.tabs[this.active_tab]; // NOTE: we want to return the tab from the array so we return an instance, not a copy
      }
    }
  }

  public setColors(background_color:string, font_color:string): void {
    this.background_color = background_color;
    this.font_color = font_color;
  };

  public setTabVisibility(tab_id: number | string, visibility: boolean): Nav_Tab {
    switch (typeof tab_id) {
      case 'number':
        this.tabs[tab_id].show = visibility;
      return this.tabs[tab_id];

      case 'string':
        let tab_name = this.sanitizeName(tab_id);
        if ( this.tabs_obj[tab_name] !== undefined ){
          this.tabs_obj[tab_name].show = visibility;
          return this.tabs_obj[tab_name];
        } else {
          console.error(`ERROR - _Nav_Tab_Group::setTabVisibility - Tab with name: '${tab_name}' was not found.`);
        }
      return undefined;
    }
  }

  public switchTab(i:number): Nav_Tab {
    if (this.tabs.length === 0) return;                                  // If there are no tabs defined, bail!
    if (this.active_tab === -1) this.deactivateAllTabs();                // Make sure the Tab objects states lineup with the stored active tab state of 'none'
    i = (i < 0 || i >= this.tabs.length) ?                               // If i is out of bounds use the first tab that is allowed to be shown, instead!
      this.getFirstVisibleTabIndex() :
      i;
    if (i === -1) return;                                                // If no tabs are allowed to be shown, bail!
    if (this.active_tab > -1) this.tabs[this.active_tab].active = false; // Deactivate the old tab
    this.active_tab = i;                                                 // Store the new active tab index
    this.tabs[this.active_tab].active = true;                            // Activate the new tab
    return this.tabs[this.active_tab];                                   // Return the new, active tab object
  }

  public switchTabByName(n:string): Nav_Tab {
    if (Object.keys(this.tabs_obj).length === 0) return;                   // If there are no tabs defined, bail!
    if (this.active_tab === -1) this.deactivateAllTabs();                  // Make sure the Tab objects states lineup with the stored active tab state of 'none'
    let name = this.sanitizeName(n);
    if (
      this.tabs_obj[name] === undefined ||                                 // If we don't have a tab of this name
      this.tabs_obj[name].show === false                                   // OR the named tab is not allowed to be shown, use the first one that is allowed to be shown, instead!
    ) {
      return this.switchTab(this.getFirstVisibleTabIndex());
    } else {
      if (this.active_tab > -1) this.tabs[this.active_tab].active = false; // Deactivate the old tab
      this.tabs_obj[name].active = true;                                   // Activate the new tab
      this.active_tab = this.tabs_obj[name].index;                         // Store the new active tab index
      return this.tabs_obj[name];                                          // Return the new, active tab object
    }
  }
}

export class Nav_Tab {
  public name:       string;
  public active?:    boolean;
  public show?:      boolean;
  public internal?:  boolean;
  public hamburger?: boolean;
  public badge?:     string;
  public index?:     number;
  public desc?:      string;

  private _style?: NavTab_Style = new NavTab_Style();
  public get style(): NavTab_Style { return this._style; };
  public set style(value:NavTab_Style) {
    if (value !== undefined) {
      this._style = value;
      this.processStyle();
    }
  };

  // Per Tab Coloring/Styling
  public bg_color:          string;
  public bg_color_active:   string;
  public bold:              boolean = false;
  public font_color:        string;
  public font_color_active: string;

  constructor(
    name:       string,
    active?:    boolean,
    show?:      boolean,
    internal?:  boolean,
    hamburger?: boolean,
    badge?:     string,
    index?:     number,
    style?:     NavTab_Style,
  ) {
    this.name = name;
    this.active = (active !== undefined) ? active : false;
    this.show = (show !== undefined) ? show : true;
    this.internal = (internal !== undefined) ? internal : false;
    this.hamburger = (hamburger !== undefined) ? hamburger : false;
    this.badge = (badge !== undefined) ? badge : undefined;
    this.index = (index !== undefined) ? index : undefined;
    if ( style ) this.style = style;
  }

  private processStyle(): void {
    this.bg_color = this._style.bg_color;
    this.bg_color_active = (this._style.bg_color_active) ? this._style.bg_color_active : this._style.bg_color;
    this.font_color = this._style.font_color;
    this.font_color_active = (this._style.font_color_active) ? this._style.font_color_active : this._style.font_color;
    if (this._style.bold) this.bold = this._style.bold;
  }

}

export class NavTabBar_ProcessHamburguesaResult {
  constructor(
    public show_hamburguesa:            boolean   = false,
    public hamburger_and_internal_tabs: Nav_Tab[] = []
  ) {}
}

export class Nav_Tab_Bar extends _Nav_Tab_Group {
  public arrow:           boolean = false;
  public highlight_color: string = Color_Library.gray_200;
  public show_badges:     boolean = false;

  constructor(
    tabs:              Nav_Tab[] = [],
    arrow?:            boolean,
    background_color?: string,
    font_color?:       string,
    highlight_color?:  string,
    show_badges?:      boolean
  ) {
    super(tabs, background_color, font_color);
    if (arrow !== undefined) this.arrow = arrow;
    if (highlight_color !== undefined) this.highlight_color = highlight_color;
    if (show_badges !== undefined) this.show_badges = show_badges;

    if (tabs) {
      this.clearTabs();
      tabs.forEach( (t) => {
        this.addTab( t );
      });
    }
  }

  public addTab(tab: Nav_Tab): Nav_Tab {
    if( !(tab.style.bg_color_active) ) tab.bg_color_active = this.highlight_color;
    return super.addTab(tab);
  }

  public setColors(
    background_color: string = Color_Library.white,
    font_color:       string = Color_Library.gray_600,
    highlight_color:  string = Color_Library.gray_200
  ): void {
    super.setColors(background_color, font_color);
    this.highlight_color = highlight_color;
  }

  public processHamburguesa(): NavTabBar_ProcessHamburguesaResult {
    let process_res = new NavTabBar_ProcessHamburguesaResult();

    for (let tab of this.tabs) {
      if (tab.internal || tab.hamburger) {
        if ( tab.show ) process_res.show_hamburguesa = true;
        process_res.hamburger_and_internal_tabs.push( tab );
      }
    }

    return process_res;
  }

  public processNormal(): Nav_Tab[] {
    let process_res: Nav_Tab[] = [];

    for(let tab of this.tabs) {
      if (!tab.internal && !tab.hamburger) {
        process_res.push(tab);
      }
    }

    return process_res;
  }
}

export class Nav_Tab_Pills extends _Nav_Tab_Group {
  private font_color_active: string = Color_Library.gray_800;
  private pill_color:        string = Color_Library.gray_100;
  private pill_color_active: string = Color_Library.gray_200;

  constructor(
    tabs:               Nav_Tab[] = [],
    background_color?:  string,
    font_color?:        string,
    pill_color?:        string,
    font_color_active?: string,
    pill_color_active?: string,
  ) {
    super(tabs, background_color, font_color);
    if (font_color_active !== undefined) this.font_color_active = font_color_active;
    if (pill_color !== undefined) this.pill_color = pill_color;
    if (pill_color_active !== undefined) this.pill_color_active = pill_color_active;

    if (tabs) {
      this.clearTabs();
      tabs.forEach( (t) => {
        this.addTab( t );
      });
    }
  }

  public addTab(tab: Nav_Tab): Nav_Tab {
    if( !(tab.style.bg_color) ) tab.bg_color = this.pill_color;
    if( !(tab.style.bg_color_active) ) tab.bg_color_active = this.pill_color_active;
    if( !(tab.style.font_color_active) ) tab.font_color_active = this.font_color_active;
    return super.addTab(tab);
  }

  public setColors(
    background_color:  string = Color_Library.gray_700,
    font_color:        string = Color_Library.gray_600,
    font_color_active: string = Color_Library.gray_800,
    pill_color:        string = Color_Library.gray_100,
    pill_color_active: string = Color_Library.gray_200,
  ): void {
    super.setColors(background_color, font_color);
    this.font_color_active = font_color_active;
    this.pill_color = pill_color;
    this.pill_color_active = pill_color_active;
  }
}

export class Nav_Tab_Vertical extends _Nav_Tab_Group {
  public background_color: string = 'transparent';
  public font_color:       string = Color_Library.gray_700;
  public highlight_color:  string = Color_Library.gray_100;

  constructor (
    tabs:              Nav_Tab[] = [],
    background_color: string = 'transparent',
    font_color:       string = Color_Library.gray_700,
    highlight_color:  string = Color_Library.gray_100,
  ) {
    super(tabs, background_color, font_color);
    if (highlight_color !== undefined) this.highlight_color = highlight_color;

    if (tabs) {
      this.clearTabs();
      tabs.forEach( (t) => {
        this.addTab( t );
      });
    }
  }

  public addTab(tab: Nav_Tab): Nav_Tab {
    if( !(tab.style.bg_color_active) ) tab.bg_color_active = this.highlight_color;
    return super.addTab(tab);
  }

  public setColors(
    background_color: string = 'transparent',//Color_Library.white,
    font_color:       string = Color_Library.gray_700,
    highlight_color:  string = Color_Library.gray_100
  ): void {
    super.setColors(background_color, font_color);
    this.background_color = background_color;
    this.font_color = font_color;
    this.highlight_color = highlight_color;
  }
}

export class Newsfeed {
  constructor(
    public user_id:        number = null,
    public content_id:     number = null,
    public id:             number = null,
    public name:           string = null,
    public description:    string = null,
    public source:         string = null,
    public author:         string = null,
    public published_date: Date   = null,
    public link:           string = null,
    public file_name:      string = null,
    public user:           number = null,
    public type:           number = null,
    public produced_by:    any = null,
    public for_avia_only:  number = null,
    public last_accessed:  Date   = null,
    public modified:       Date   = null,
    public created:        Date   = null,
    public md5_hash:       string = null,
  ) {  }
}

export class Notification {
  constructor(
    public action_user_obj: any           = null,
    public comment_obj:     Comment       = null,
    public content_name:    string        = null,
    public content_obj:     Content       = null,
    public channel_obj:     any           = null,
    public created:         Date          = null,
    public dismissed:       boolean       = null,
    public id:              any           = null,
    public km_obj:          KnowledgeCard = null,
    public options:         any           = null,
    public target_user_obj: User          = null,
    public type_obj:        any           = null,
  ) { }
}



/* * * O * * */
export class Org_Agenda {
  constructor(
    public id:         number = null,
    public is_checked: number = null,
    public list_placement: number = null,
    public name:       string = null,
    public org_id:     number = null,
    public status_id:  number = null,
  ) {  }
}

export class Org_AdminObj {
  constructor(
    public id:        number = null,
    public firstname: string = null,
    public lastname:  string = null,
    public title:     string = null
  ) {  }
}

export class Org_AviaCoRegionObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public desc:        string = null, // NOTE: This is here because the description field is abbreviated in the Org object.
    public description: string = null,
    public created:     Date   = null
  ) {  }
}

export class Org_AviaRelationshipObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null
  ) {  }
}

export class Org_AviaRelationshipManagerObj {
  constructor(
    public avatar:    string = null,
    public id:        number = null,
    public firstname: string = null,
    public lastname:  string = null,
    public fullname:  string = null,
    public title:     string = null
  ) {  }
}

export class Org_DistributionObj {
  constructor(
    public id:      number = null,
    public name:    string = null,
    public created: Date   = null
  ) {  }
}

export class Org_FundingStatusObj {
  constructor(
    public id:   number = null,
    public name: string = null,
    public desc: string = null
  ) {  }
}

export class Org_HealthRatingObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date   = null,
    public modified:    Date   = null
  ) {}
}

export class Org_HqAddr {
  constructor(
    public city:            string = null,
    public state:           string = null,
    public street:          string = null,
    public country:         string = null,
    public latitude:        string = null,
    public longitude:       string = null,
    public stateCode:       string = null,
    public postalCode:      string = null,
    public countryCode:     string = null,
    public geocodeAccuracy: string = null,
    public zip:             string = null, // angular8: added to fix error when building prod: "Property does not exist"
  ) {  }
}

export class Org_HsMembershipObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
  ) {  }
}

export class Org_MemberFocus {
  constructor(
    public id:        number  = null,
    public name:      string  = null,
    public status:    string  = null,
    public type_id:   number  = null,
    public type_name: string  = null,
    public Topics:    Topic[] = [ new Topic() ],
  ) { }
}

export class Org_OrgMembershipObj {
  constructor(
    public id: number,
    public level_id: number,
    public org_id: number,
  ) { }
}

export class Org_MemberTypes {
  constructor(
    public id:   number = null,
    public name: string = null,
  ) { }
}

export class Org_OperatingStatus {
  constructor(
    public name: string = null,
    public id:   number = null
  ) { }
}

export class Org_OwnershipObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date   = null
  ) {  }
}

export class Org_ParentObj {
  constructor(
    public id:   number = null,
    public name: string = null
  ) {  }
}

export class Org_PtClassificationObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date   = null,
    public modified:    Date   = null
  ) {}
}

export class Org_RenewalStatusTypes {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date   = null,
    public modified:    Date   = null
  ) {}
}

export class Org_RevenueByYear {
  constructor(
    public id:     number,
    public year:   number,
    public amount: number,
  ) { }
}

export class Org_RdObj {
  constructor(
    public id:   number = null,
    public type: string = null,
    public test: number = null
  ) {  }
}

export class Org_SolcoFundingStatusObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date   = null
  ) {  }
}

export class Org_SolcoGeneralExposure {
  constructor(
    public id:      number = null,
    public name:    string = null,
    public desc:    string = null,
    public created: Date   = null
  ) {  }
}

export class Org_StrategicPartners {
  constructor(
    public name:        string = null,
    public description: string = null
  ) {  }
}


export class Org_TypeObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date   = null
  ) {  }
}

export class Org {
  constructor(
    public admin:                          number                         = null,
    public admin_obj:                      Org_AdminObj                   = new Org_AdminObj(),
    public admin_name:                     string                         = null,
    public avatar:                         string                         = null,
    public avia_co_region:                 number                         = null,
    public avia_co_region_name:            string                         = null,
    public avia_co_region_obj:             Org_AviaCoRegionObj            = new Org_AviaCoRegionObj(),
    public avia_general_exposure:          number                         = null,
    public avia_financial_relationship:    number                         = null,
    public avia_general_exposure_name:     string                         = null,
    public avia_general_exposure_obj:      Object                         = { /* TODO: make Class for me */ },
    public avia_primary_contact:           number                         = null,
    public avia_relationship:              number                         = null,
    public avia_relationship_name:         string                         = null,
    public avia_relationship_obj:          Org_AviaRelationshipObj        = new Org_AviaRelationshipObj(),
    public avia_relationship_manager:      number                         = null,
    public avia_relationship_manager_name: string                         = null,
    public avia_relationship_manager_obj:  Org_AviaRelationshipManagerObj = new Org_AviaRelationshipManagerObj(),
    public avia_team:                      User[]                         = [new User()],
    public business_metrics_operational:   Object                         = { /* TODO: make Class for me */ },
    public business_metrics_strategic:     Object                         = { /* TODO: make Class for me */ },
    public business_model:                 string                         = null,
    public checkin_vis:                    number                         = null,
    public color:                          string                         = Color_Library.blue_dark,
    public comment_group:                  number                         = null,
    public content_attachments:            number                         = null,
    public content_casestudies:            number                         = null,
    public contract_date:                  Date                           = null,
    public contract_duration_mo:           number                         = null,
    public contract_end_date:              Date                           = null,
    public contract_renewal_status:        Org_RenewalStatusTypes         = null,
    public contract_renewal_status_obj:    Org_RenewalStatusTypes         = null,
    public contract_start_date:            Date                           = null,
    public created:                        Date                           = null,
    public description:                    string                         = null,
    public dashboard_vis:                  number                         = null,
    public distribution:                   number                         = null,
    public distribution_name:              string                         = null,
    public distribution_obj:               Org_DistributionObj            = new Org_DistributionObj(),
    public email:                          string                         = null,
    public example_clients:                Object                         = { /* TODO: make Class for me */ },
    public expansion_opps:                 string                         = null,
    public fiscal_year_start_date:         Date                           = null,
    public flags:                          number                         = null,
    public funding_status:                 number                         = null,
    public funding_status_name:            string                         = null,
    public funding_status_obj:             Org_FundingStatusObj           = new Org_FundingStatusObj(),
    public goals:                          string                         = null,
    public health_rating:                  number                         = null,
    public health_rating_obj:              Org_HealthRatingObj            = null,
    public hq_addr:                        Org_HqAddr                     = new Org_HqAddr(),
    public id:                             number                         = null,
    public implementation_timeline:        number                         = null,
    public is_avia_member:                 boolean                        = null,
    public is_last_child:                  boolean                        = null,
    public is_published:                   boolean                        = null,
    public last_modified:                  Date                           = null,
    public logo:                           string                         = null,
    public member_since:                   Date                           = null,
    public member_focus:                   Org_MemberFocus[]              = [ new Org_MemberFocus() ],
    public member_types:                   Org_MemberTypes[]              = [ new Org_MemberTypes() ],
    public membership:                     any                            = null,
    public name:                           string                         = null,
    public num_badges:                     number                         = null,
    public nav_color:                      string                         = null,
    public next_opt_out:                   Date                           = null,
    public notes:                          string                         = null,
    public npr:                            number                         = null,
    public num_customers:                  number                         = null,
    public org_contacts:                   User[]                         = [new User()],
    public org_owners:                     User[]                         = [new User()],
    public ownership:                      number                         = null,
    public ownership_name:                 string                         = null,
    public ownership_obj:                  Org_OwnershipObj               = new Org_OwnershipObj(),
    public parent:                         number                         = null,
    public parent_name:                    string                         = null,
    public parent_obj:                     Org_ParentObj                  = new Org_ParentObj(),
    public phonenum:                       string                         = null,
    public possible_actions:               string                         = null,
    public pri_desc:                       string                         = '',
    public pulse_enabled:                  number                         = null,
    public revenue_by_year:                Org_RevenueByYear[]            = [],
    public rd:                             Org_RdObj                      = new Org_RdObj(),
    public salesforce_id:                  string                         = null,
    public sc_review_overview:             number                         = null,
    public sc_list_products:               number                         = null,
    public sc_map_products:                number                         = null,
    public sc_product_details:             number                         = null,
    public sc_upload_files:                number                         = null,
    public sc_add_employees:               number                         = null,
    public sf_last_modified:               Date                           = null,
    public show_in_avia_cc:                number                         = null,
    public size_customers:                 number                         = null,
    public size_employees:                 number                         = null,
    public status:                         number                         = null,
    public status_obj:                     UserOrg_StatusObj              = new UserOrg_StatusObj(),
    public strategic_partners:             Org_StrategicPartners[]        = [new Org_StrategicPartners()],
    public total_capital_raised:           number                         = null,
    public type:                           number                         = null,
    public type_name:                      string                         = null,
    public type_obj:                       Org_TypeObj                    = new Org_TypeObj(),
    public url_crunchbase:                 string                         = null,
    public url_google_news:                string                         = null,
    public url_linkedin:                   string                         = null,
    public url_twitter:                    string                         = null,
    public value_received:                 string                         = null,
    public web_addr:                       string                         = null,
    public year_founded:                   Date                           = null,

    // HS // TODO: Refactor this to the 'orgs_hs' class
    public acute_hospitals:        number                 = null,
    public avg_claims:             number                 = null,
    public capitation:             boolean                = null,
    public direct_to_employer:     boolean                = null,
    public employer_contracts:     boolean                = null,
    public emr_ambulatory:         string                 = null,
    public emr_amb_last_upgrade:   Date                   = null,
    public emr_inpatient:          string                 = null,
    public emr_inpt_last_upgrade:  Date                   = null,
    public emr_planned_upgrade:    Date                   = null,
    public emr_same:               boolean                = null,
    public emr_system_commit:      number                 = null,
    public emr_total:              number                 = null,
    public emr_type_ambulatory:    number                 = null,
    public emr_type_inpatient:     number                 = null,
    public emr_version_ambulatory: string                 = null,
    public emr_version_inpatient:  string                 = null,
    public health_plan:            boolean                = null,
    public hs_membership:          number                 = null,                      //TODO: Are these fields deprecated?
    public hs_membership_obj:      Org_HsMembershipObj    = new Org_HsMembershipObj(), //TODO: Are these fields deprecated?
    public hs_states:              Object[]               = null,
    public innovation_co_develop:  boolean                = null,
    public innovation_created:     Date                   = null,
    public innovation_fte:         number                 = null,
    public innovation_invest:      boolean                = null,
    public innovation_llc:         boolean                = null,
    public innovation_team:        boolean                = null,
    public innovation_vc_fund:     boolean                = null,
    public is_aco:                 boolean                = null,
    public is_amc:                 boolean                = null,
    public is_bundled_pay:         boolean                = null,
    public is_childrens_hospital:  boolean                = null,
    public is_cin:                 boolean                = null,
    public is_crit_access:         boolean                = null,
    public is_provider_sponsored:  boolean                = null,
    public is_rural:               boolean                = null,
    public is_trauma:              boolean                = null,
    public net_op_margin:          number                 = null,
    public num_beds:               number                 = null,
    public num_employees:          number                 = null,
    public num_hospitals:          number                 = null,
    public num_lives:              number                 = null,
    public num_physicians:         number                 = null,
    public num_states:             number                 = null,
    public org_membership_obj:     Org_OrgMembershipObj[] = [],
    public payor_medicare:         number                 = null,
    public payor_medicaid:         number                 = null,
    public payor_commercial:       number                 = null,
    public payor_uninsured:        number                 = null,
    public rev_at_risk_contracts:  number                 = null,
    public self_insured:           boolean                = null,
    public shared_savings:         boolean                = null,
    public size_hs:                string                 = null,
    public tpa_in_house:           boolean                = null,

    // SOLCOS // TODO: Refactor this to the 'orgs_sc' class
    public acquired_by:                string                   = null,
    public acquisitions:               string[]                 = null,
    public avia_relationship_owner:    string                   = null,
    public company_logo:               string                   = null,
    public consultation:               number                   = 0,
    public crunchbase_id:              number                   = null,
    public crunchbase_logo:            string                   = null,
    public date_high_impact:           Date                     = null,
    public date_market_val:            Date                     = null,
    public disclose_raising_capital:   number                   = null,
    public disclosures:                string[]                 = null,
    public employees:                  number                   = null,
    public equity_warrants:            boolean                  = false,
    public equity:                     boolean                  = false,
    public in_discussion:              number                   = 0,
    public is_avia_vetted:             boolean                  = false,
    public is_covid:                   number                   = 0,
    public is_high_impact:             boolean                  = false,
    public is_market_val:              boolean                  = false,
    public investments:                Object[]                 = null,
    public investors:                  string[]                 = null,
    public most_recent_funding_amount: number                   = null,
    public most_recent_funding_round:  number                   = null,
    public most_recent_funding_date:   Date                     = null,
    public num_employees_est:          string                   = null,
    public operating_status:           Org_OperatingStatus      = null,
    public performance_based:          number                   = 0,
    public products:                   Product[]                = [],
    public badges:                     Badge[]                  = [],
    public raising_capital:            boolean                  = null,
    public raising_capital_date:       Date                     = null,
    public raising_capital_notes:      string                   = null,
    public solco_general_exposure:     Org_SolcoGeneralExposure = new Org_SolcoGeneralExposure(),
    public total_equity_funding:       number                   = null,
    public is_pediatric:               number                   = 0,
    public is_active:                  number                   = 0,
    public date_active:                Date                     = null,
    public onboarding:                 Date                     = null,
    public most_recent_funding_amt:    number                   = null,
  ) { }
}

export class Org_Hs extends Org {
  // TODO: Refactor this Org type's specific fields out of the Org class
  constructor () {
    super();
  }
}

export class Org_Pt extends Org {
  // TODO: Refactor this Org type's specific fields out of the Org class
  constructor (
    public classification:     number                  = null,
    public classification_obj: Org_PtClassificationObj = null,
  ) {
    super();
  }
}

export class Org_Sc extends Org {
  // TODO: Refactor this Org type's specific fields out of the Org class
  constructor () {
    super();
  }
}

export class OrgsSupport {
  constructor(
    public orgs_table_editable_fields: string[]                   = [],
    public solco_general_exposure:     Org_SolcoGeneralExposure[] = [ new Org_SolcoGeneralExposure() ],
    public ownership_types:            Org_OwnershipObj[]         = [ new Org_OwnershipObj() ],
    public org_types:                  Org_TypeObj[]              = [ new Org_TypeObj() ],
    public dashboard_vis_types:        Org_TypeObj[]              = [ new Org_TypeObj() ],
    public avia_region_types:          Org_AviaCoRegionObj[]      = [ new Org_AviaCoRegionObj() ],
    public distribution_types:         Org_DistributionObj[]      = [ new Org_DistributionObj() ],
    public solco_funding_status_types: Org_FundingStatusObj[]     = [ new Org_FundingStatusObj() ],
    public status_types:               Org_TypeObj[]              = [ new Org_TypeObj() ],
    public health_rating_types:        Org_HealthRatingObj[]      = [ new Org_HealthRatingObj() ],
    public pt_classification_types:    Org_PtClassificationObj[]  = [ new Org_PtClassificationObj() ],
    public renewal_status_types:       Org_RenewalStatusTypes[]   = [ new Org_RenewalStatusTypes() ]
  ) {  }
}



/* * * P * * */
export class PasswordPolicy {
  constructor(
      public minLength:     number = undefined,
      public maxLength:     number = undefined,
      public minLowerCase:  number = undefined,
      public minUpperCase:  number = undefined,
      public minNumeric:    number = undefined,
      public minSymbol:     number = undefined,
      public minDiacritic:  number = undefined,
      public preventReuse:  number = undefined
  ) { }
}

export class Priority {
  constructor(
    public created:                  Date            = undefined,
    public created_by:               number          = undefined,
    public created_by_obj:           User            = undefined,
    public companies_desc:           string          = undefined,
    public description:              string          = undefined,
    public file_group:               number          = undefined,
    public priority_groups:          PriorityGroup[] = [],
    public id:                       number          = undefined,
    public is_public:                number          = undefined,
    public modified:                 Date            = undefined,
    public modified_by:              number          = undefined,
    public modified_by_obj:          User            = undefined,
    public name:                     string          = '',
    public org_id:                   number          = undefined,
    public people:                   User[]          = [],
    public pri_metrics_desc:         string          = undefined,
    public pri_solution_desc:        string          = undefined,
    public topics:                   Topic[]         = undefined,
    public union_network_priorities: any    = undefined,
    public owner:                    any             = undefined,
    public owner_obj:                any             = undefined,
    public union_network_priorities_org_keys:  any   = undefined,  // angular8: added to fix error when building prod: "Property does not exist"
    public is_archived:              any             = undefined,  // angular8: added to fix error when building prod: "Property does not exist"
    public solution_recs:            any             = undefined,  // angular8: added to fix error when building prod: "Property does not exist"
  ) { }
}

export class PriorityGroup {
  constructor(
    public created:        Date   = undefined,
    public created_by:     number = undefined,
    public created_by_obj: User   = undefined,
    public description:    string = undefined,
    public id:             number = undefined,
    public modified:       Date   = undefined,
    public name:           string = undefined,
  ) { }
}

export class Product_Sc {
  constructor(
    public id:              number = undefined,
    public name:            string = undefined,
    public logo:            string = undefined,
    public crunchbase_logo: string = undefined
  ) { }
}

export class Product {
  constructor(
    public name:                              string          = undefined,
    public short_desc:                        string          = undefined,
    public accessing_reports:                 string          = undefined,
    public badge_avia_vet_id:                 number          = undefined, // To be removed
    public badge_avia_vetted:                 BadgeAviaVetted = new BadgeAviaVetted(), // To be removed
    public badge_high_impact:                 BadgeHighImpact = new BadgeHighImpact,
    public bagde_high_impact_id:              number          = undefined, // Useless - remove?
    public badge_market_val:                  BadgetMarketVal = new BadgetMarketVal(),
    public badge_market_val_id:               number          = undefined, // Useless - remove?
    public badges:                            Badge[]         = [],
    public business_case:                     string          = undefined,
    public capabilities:                      Topic[]         = [],
    public client_resources:                  string          = undefined,
    public covid_desc:                        string          = undefined,
    public solutions:                         Topic[]         = [],
    public content_group:                     number          = undefined,
    public created:                           Date            = undefined,
    public created_by:                        number          = undefined,
    public custom_reporting:                  string          = undefined,
    public description:                       string          = undefined,
    public file_group:                        number          = undefined,
    public id:                                number          = undefined,
    public is_covid:                          number          = undefined,
    public is_following:                      boolean         = undefined,
    public is_pediatric:                      boolean         = undefined,
    public is_product_complete:               boolean         = undefined,
    public maturity:                          string          = undefined,
    public metrics_overview:                  string          = undefined,
    public modified:                          Date            = undefined,
    public overall_approach:                  string          = undefined,
    public pediatric_desc:                    string          = undefined,
    public people_and_culture:                string          = undefined,
    public pricing_models:                    string          = undefined,
    public process_workflow:                  string          = undefined,
    public roadmap_desc:                      string          = undefined,
    public reports:                           string          = undefined,
    public sc:                                Product_Sc      = new Product_Sc(),
    public security:                          ProductSecurity = new ProductSecurity(),
    public solco_id:                          number          = undefined,
    public stakeholders:                      string          = undefined,
    public stakeholders_budget_holder:        string          = undefined,
    public stakeholders_champions:            string          = undefined,
    public stakeholders_operational_champion: string          = undefined,
    public stakeholders_other:                string          = undefined,
    public tti_type:                          any             = undefined,  // angular8: changed Object to any
    public ttv_success:                       any             = undefined,  // angular8: changed Object to any
    public ttv_impact:                        any             = undefined,  // angular8: changed Object to any
    public ttv_roi:                           any             = undefined,  // angular8: changed Object to any
    public use_cases:                         string          = undefined,
    public users_overview:                    string          = undefined,
    public tech_integration:                  string          = undefined,
    public time_to_implement:                 string          = undefined,
    public vs_external:                       string          = undefined,
    public vs_internal:                       string          = undefined,
    public time_to_value:                     string          = undefined,
    public youtube_id:                        string          = undefined,
    public solution_recs:                     any             = undefined,  // angular8: added to fix error when building prod: "Property does not exist"
    public youtube_embed_url:                 any             = undefined,  // angular8: added to fix error when building prod: "Property does not exist"
  ) { }
}

export class ProductSecurity {
  constructor(
    public certification:       Object[] = undefined,
    public compatibility:       Object[] = undefined,
    public data_protection:     Object[] = undefined,
    public data_infrastructure: ProductSecurity_SubObj = new ProductSecurity_SubObj(),
    public data_storage:        Object[] = undefined,
    public deployment:          Object[] = undefined,
    public emr_integration:     ProductSecurity_SubObj = new ProductSecurity_SubObj(),
    public emr_support:         Object[] = undefined,
    public identity_mgmt:       Object[] = undefined,
    public integrations:        Object[] = undefined,
    public offshore_data:       ProductSecurity_SubObj = new ProductSecurity_SubObj(),
    public offshore_dev:        Object[] = undefined,
    public patient_data:        Object[] = undefined,
    public protocols:           Object[] = undefined,
    public standards:           Object[] = undefined,
  ) { }
}

export class ProductSecurity_SubObj {
  constructor(
    public name: string = undefined,
    public id:   number = undefined
  ) { }
}

export class PulseInfo {
  constructor(
    public modules:                    Object[] = undefined,
    public active:                     Object[] = undefined,
    public pulse_admins:               User[] = [],
    public at_least_one_active_module: boolean = false
  ){ }
}

export class PulseModule {
  constructor(
    public description: string = null,
    public group_id:    number = null,
    public id:          number = null,
    public is_archived: number = null,
    public is_test:     number = null,
    public name:        string = null,
  ){ }
}



/* * * Q * * */
export class QuestionBase<T> {
  value:       T;
  key:         string;
  label:       string;
  required:    boolean;
  order:       number;
  controlType: string;
  options:     any; // angular8: added to fix error when building prod: "Property does not exist"
  type:        any; // angular8: added to fix error when building prod: "Property does not exist"

  constructor(options: {
    value?:       T,
    key?:         string,
    label?:       string,
    required?:    boolean,
    order?:       number,
    controlType?: string,
    options?:     any, // angular8: added to fix error when building prod: "Property does not exist"
    type?:     any, // angular8: added to fix error when building prod: "Property does not exist"
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order !== undefined ? options.order : 1;
    this.controlType = options.controlType || '';
    this.options = options.options; // angular8: added to fix error when building prod: "Property does not exist"
    this.type = options.type; // angular8: added to fix error when building prod: "Property does not exist"
  }
}

export class Question_Checkbox extends QuestionBase<string> {
  controlType = 'checkbox';
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}

export class Question_Dropdown extends QuestionBase<string> {
  controlType = 'dropdown';
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
  }
}

export class Question_Textbox extends QuestionBase<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}



/* * * R * * */
export class RegionTypes {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     Date = null
  ) {  }
}

export class Rights {
  constructor(
    public is_owner:                   any = null,
    public in_market_strategies_dept:  any = null,
    public in_provider_solutions_dept: any = null,
    public is_me:                      any = null,
    public is_connected:               any = null,
    public is_my_org:                  any = null,
    public is_test:                    any = null
  ) {  }
}

export class Role {
  constructor(
    public name:        string   = null,
    public id:          number   = null,
    public long_name:   string   = null,
    public avatar:      string   = null,
    public description: string   = null,
    public categories:  Object[] = []
  ) { }
}

export class RolePerms {
  constructor(
    public d: boolean = null,
    public w: boolean = null,
    public r: boolean = null,
    public c: boolean = null,
    public e: boolean = null
  ) {  }
}



/* * * S * * */
export class SC_Account_Details {
  constructor (
    public contract_start_date:           Date   = null,
    public contract_end_date:             Date   = null,
    public dashboard_vis:                 number = null,
    public member_since:                  Date   = null,
    public org_contacts:                  User[] = [],
    public avia_relationship_manager_obj: Org_AviaRelationshipManagerObj = new Org_AviaRelationshipManagerObj(),
    //for AVIA relationship:
    public consultation:                  number = null,
    public in_discussion:                 number = null,
    public performance_based:             number = null,
    public equity:                        boolean = null
  ) {  }
}

export class SessionRoleCatResOp_Admin {
  constructor(
    public feature: RolePerms = new RolePerms(),
    public orgs:    RolePerms = new RolePerms(),
    public users:   RolePerms = new RolePerms(),
    public roles:   RolePerms = new RolePerms(),
  ) {  }
}

export class SessionRoleCatResOp_Cl {
  constructor(
    public feature: RolePerms = new RolePerms(),
    public card:    RolePerms = new RolePerms(),
  ) {  }
}

export class SessionRoleCatResOp_Km {
  constructor(
    public feature:               RolePerms = new RolePerms(),
    public card:                  RolePerms = new RolePerms(),
    public card_comment:          RolePerms = new RolePerms(),
    public card_comment_item:     RolePerms = new RolePerms(),
    public card_comment_resolve:  RolePerms = new RolePerms(),
    public card_notes:            RolePerms = new RolePerms(),
    public card_evernote:         RolePerms = new RolePerms(),
    public card_owners:           RolePerms = new RolePerms(),
    public card_settings_feature: RolePerms = new RolePerms(),
    public card_settings_owner:   RolePerms = new RolePerms(),
    public card_settings:         RolePerms = new RolePerms(),
    public card_approve:          RolePerms = new RolePerms(),
    public card_comment_body:     RolePerms = new RolePerms(),
    public draft_cards:           RolePerms = new RolePerms(),
  ) {  }
}
export class SessionRoleCatResOp_Np {
  constructor(
    public feature:                 RolePerms = null,
    // public user_demo:               RolePerms = null,
    // public user_contact_info:       RolePerms = null,
    // public user_contacts:           RolePerms = null,
    // public user_internal:           RolePerms = null,
    public org_demo:                RolePerms = null,
    public org_contacts:            RolePerms = null,
    public org_internal:            RolePerms = null,
    public solco_demo:              RolePerms = null,
    public solco_contacts:          RolePerms = null,
    public solco_internal:          RolePerms = null,
    public user_internal_settings:  RolePerms = null,
    public org_internal_settings:   RolePerms = null,
    public solco_internal_settings: RolePerms = null,
    public show_test:               RolePerms = null
  ) {  }
}

export class SessionRoleCatResOp_Shell {
  constructor(
    public org_switcher: RolePerms = null,
    public feature:      RolePerms = null
  ) {  }
}

export class SessionRole_CatResOp {
  constructor(
    public shell: SessionRoleCatResOp_Shell = new SessionRoleCatResOp_Shell(),
    public np:    SessionRoleCatResOp_Np    = new SessionRoleCatResOp_Np(),
    public km:    SessionRoleCatResOp_Km    = new SessionRoleCatResOp_Km(),
    public cl:    SessionRoleCatResOp_Cl    = new SessionRoleCatResOp_Cl(),
    public admin: SessionRoleCatResOp_Admin = new SessionRoleCatResOp_Admin(),
  ) {  }
}

export class Session_Role {
  constructor(
    public id:                   number               = null,
    public name:                 string               = null,
    public description:          string               = null,
    public avatar:               string               = null,
    public category_resource_op: SessionRole_CatResOp = new SessionRole_CatResOp(),
  ) {  }
}

export class Session {
  constructor(
    public login_username: string       = null,
    public user:           User         = new User(),
    public rights:         Rights       = new Rights(),
    public role:           Session_Role = new Session_Role(),
    public org:            Org          = new Org()
  ) {  }
}

export class Site_Map {
  constructor(
    public name:        string = null,
    public link:        string = null,
    public param:       Object = {},
  ) {  }
}

export class StatusTypes {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public action_user: string = null,
    public action_org:  string = null,
    public created:     Date   = null,
  ) {  }
}



/* * * T * * */
export class TitleTypes {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null,
    public created:     string = null
  ) {  }
}

export class Topic {
  constructor(
    public id:            number = null,
    public cap_wgll_text: string = null,
    public content:       any = null,
    public description:   string = null,
    public following:     boolean = null,
    public name:          string = null,
    public short_desc:    string = null,
    public type:          number = null,
    public users_count:   number = null,
    // Properties added in by Elastic Search
    public highlights: Elastic_Highlights = new Elastic_Highlights(),
    public keywords:   string[] = [],
  ) {  }
}

export class Tracker {
  constructor(
    public id:            number = null,
    public org_id:        number = null,
    public status_id:     number = null,
    public item_type:     number = null,
    public workspace_id:  number = null,
    public inv_id:        number = null,
    public priority_id:   number = null,
    public link:          string = null,
    public description:   string = null,
    public is_tracked:    number = null

  ) { }
}



/* * * U * * */
export class User {
  // NOTE: This class is a unified version of the output of getUsers v1 AND v2
  constructor(
    public avatar:                string               = null,
    public avia_co_region:        number               = null,
    public avia_co_region_name:   string               = null,
    public avia_co_region_obj:    User_AviaCoRegion    = new User_AviaCoRegion(),
    public avia_contacts:         User_Contacts[]      = [ new User_Contacts() ],
    public clinical_creds:        User_ClinicalCreds[] = [ new User_ClinicalCreds() ],
    public connections:           Connections          = new Connections(),
    public created:               Date                 = null,
    public dept:                  string               = null,
    public dept_avia:             number               = null,
    public dept_avia_obj:         User_DeptAvia        = new User_DeptAvia(),
    public email_km_chat:         boolean              = null,
    public email_km_comment:      boolean              = null,
    public email_workspace_chat:  boolean              = null,
    public email_qa_daily:        boolean              = null,
    public email_qa_responses:    boolean              = null,
    public email_messenger_blast: boolean              = null,
    public emails:                User_Emails[]        = [ new User_Emails() ],
    public EULA:                  User_Eula            = new User_Eula(),
    public firstname:             string               = null,
    public fullname:              string               = null, // NOTE: This is a 'virtual' column that is not stored in the DB
    public id:                    number               = null,
    public interests_content:     number[]             = [],
    public interests_topics:      number[]             = [],
    public invited_by_obj:        any                  = null,
    public is_exec_sponsor:       number               = null,
    public is_owner:              number               = 0,
    public is_pulse_admin:        number               = 0,
    public is_primary_contact:    number               = null, // NOTE: This is a 'virtual' column that is not stored in the DB
    public last_modified:         Date                 = null,
    public lastname:              string               = null,
    public login_alert:           number               = null,
    public onboarding_start:      boolean              = false,
    public org:                   number               = null,
    public org_name:              string               = null,
    public org_obj:               User_Org             = new User_Org(),
    public personal_bio:          string               = null,
    public phonenums:             User_Phonenums[]     = [ new User_Phonenums() ],
    public primary_contact:       number               = null,
    public privacy_version:       string               = null,
    public rd:                    User_Rd              = new User_Rd(),
    public role:                  number               = null,
    public role_name:             string               = null,
    public role_obj:              User_Role            = new User_Role(),
    public salesforce_id:         string               = null,
    public sf_last_modified:      Date                 = null,
    public status:                number               = null,
    public status_obj:            UserOrg_StatusObj    = new UserOrg_StatusObj(),
    public third_party_version:   string               = null,
    public title:                 string               = null,
    public title_avia:            number               = null,
    public title_avia_name:       string               = null,
    public title_avia_obj:        User_TitleAvia       = new User_TitleAvia(),
    public tos_version:           string               = null,
    public email_articles:           any               = null,
  ) { }
}
export class User_AviaCoRegion {
  constructor(
    public id:       number = null,
    public fullname: string = null,
    public title:    string = null
  ) { };
}

export class User_ClinicalCreds_Cred {
  constructor(
    public id:        number = null,
    public name:      string = null,
    public long_name: string = null,
    public priority:  number = null,
    public created:   Date   = null
  ) { };
}

export class User_ClinicalCreds {
  constructor(
    public id:       number                  = null,
    public cred_obj: User_ClinicalCreds_Cred = new User_ClinicalCreds_Cred()
  ) { };
}

export class User_Contacts {
  constructor(
    public id:                 number  = null,
    public is_primary_contact: Boolean = null
  ) { };
}

export class User_DeptAvia {
  constructor(
    public id:   number = null,
    public name: string = null
  ) { };
}

export class User_Emails {
  constructor(
    public email: string = null
  ) { };
}

export class User_Eula {
  constructor(
    public tos_version:         string = null,
    public privacy_version:     string = null,
    public third_party_version: string = null,
    public modified:            string = null
  ) { };
}

export class User_Org {
  constructor(
    public id:    number = null,
    public name:  string = null,
    public type:  number = null,
    public color: string = null,
    public nav_color: string = null,
    public logo: string = null
  ) { };
}

export class User_Phonenums {
  constructor(
    public phonenum: string = null,
    public type:     string = null
  ) { };
}

export class User_Rd {
  constructor(
    public id:   number = null,
    public org:  number = null,
    public type: string = null,
    public test: number = null,
    public dept: number = null,
  ) { };
}

export class User_Role {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null
  ) { };
}

export class User_TitleAvia {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public description: string = null
  ) { };
}

export class UserOrg_StatusObj {
  constructor(
    public id:          number = null,
    public name:        string = null,
    public action_org:  string = null,
    public action_user: string = null,
    public desc:        string = null,
    public created:     Date   = null
  ) { };
}

export class UsersSupport {
  constructor(
    public user_table_editable_fields: string[]                  = null,
    public avia_region_types:          RegionTypes[]             = [ new RegionTypes ],
    public clinical_credential_types:  User_ClinicalCreds_Cred[] = [ new User_ClinicalCreds_Cred() ],
    public avia_dept_types:            AviaDeptTypes[]           = [ new AviaDeptTypes() ],
    public avia_title_types:           TitleTypes[]              = [ new TitleTypes() ],
    public status_types:               StatusTypes[]             = [ new StatusTypes() ]
  ) {  }
}



/* * * V * * */



/* * * W * * */
export class WorkspaceChannel_ChId {
  constructor(
    public id:   number = null,
    public name: string = null,
  ) {  }
}

export class Workspace_Channel {
  constructor(
    public ch_id:       number                = null,
    public ch_id_obj:   WorkspaceChannel_ChId = new WorkspaceChannel_ChId()
  ) {  }
}

export class Workspace_Host {
  constructor(
    public id:             number = null,
    public name:           string = null,
    public logo:           string = null,
    public type:           number = null,
    public color:          string = null,
    public crunchbase_obj: any    = null
  ) {  }
}

export class WorkspaceOwner_OrgObj {
  constructor(
    public id:   number = null,
    public name: string = null,
    public type: number = null,
  ) {  }
}

export class Workspace_Owner {
  constructor(
    public avatar:   string = null,
    public fullname: string = null,
    public id:       number = null,
    public org_obj:  WorkspaceOwner_OrgObj = new WorkspaceOwner_OrgObj(),
    public title:    string = null,
  ) {  }
}

export class WorkspaceSupport_StatusTypes {
  constructor(
    public id:   number = null,
    public name: string = null,
  ) {  }
}

export class WorkspaceSupport {
  constructor(
    public activity_status_types:   Object                         = {},
    public types_activities_status: WorkspaceSupport_StatusTypes[] = [ new WorkspaceSupport_StatusTypes() ]
  ) {  }
}

export class WorkspaceTopic {
  constructor(
    public id:   number = null,
    public name: string = null,
    public type: number = null,
  ) {  }
}

export class Workspace {
  constructor(
    public channels:              Workspace_Channel[] = [ new Workspace_Channel() ],
    public channel_ids:           number[]            = [],
    public cm:                    Community[]         = [new Community()],
    public companies_desc:        string              = null,
    public content_group:         number              = null,
    public created:               Date                = null,
    public created_by:            number              = null,
    public created_by_obj:        User                = new User(),
    public description:           string              = null,
    public file_group:            number              = null,
    public hosts:                 Workspace_Host[]    = [ new Workspace_Host() ],
    public id:                    number              = null,
    public is_org_participating:  boolean             = null,
    public is_public:             number              = null,
    public is_published:          number              = null,
    public is_user_participating: boolean             = null,
    public metrics_desc:          string              = null,
    public modified:              Date                = null,
    public name:                  string              = null,
    public owners:                Workspace_Owner[]   = [ new Workspace_Owner() ],
    public rd:                    any                 = null,
    public status:                number              = null,
    public status_obj:            StatusTypes         = new StatusTypes(),
    public network:               any                 = undefined, // angular8: added to fix error when building prod: "Property does not exist"
  ) { }
}


/* * * Y * * */



/* * * Z * * */
