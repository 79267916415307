import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnboardingService } from '../../onboarding.service';

@Component({
  selector: 'app-onboarding-user-interests',
  templateUrl: './onboarding-user-interests.component.html',
  styleUrls: ['./onboarding-user-interests.component.scss']
})
export class OnboardingUserInterestsComponent implements OnInit {
  // This might be janky but we need a way to tell who we are and where my previous slide location was.
  // This is the only slide without a form submission so keeping track of which slide is current and next was necessary.
  my_slide_num: number = 4;

  nav_sub: Subscription;

  constructor(public sob: OnboardingService) {
    this.nav_sub = this.sob.navClick.subscribe(e => {
      if(e.next && e.previous_slide == this.my_slide_num) {
        this.sob.refreshUser();
      }
    });
  }

  ngOnInit() {
    this.sob.form_valid = true
  }

  ngOnDestroy() {
    if(this.nav_sub) this.nav_sub.unsubscribe();
    this.sob.form_valid = false;
  }
}
