<avia-filedrop [modal]="true" [unlocked]="true" (dragFileAccepted)="dropFile($event)">
	<div *ngIf="link.length === 0" class="row">

		<div class="col-12">
			<div class="cropper-wrapper">
				<!-- image cropper -->
				<img-cropper #cropper
					*ngIf="!uploading"
					[hidden]="hide_cropper"
					[image]="data"
					[settings]="cropperSettings"
					(cropPositionChange)="cropPositionChange($event)"
				></img-cropper>
				<span *ngIf="uploading" class="loader"><div class="avia-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></span>
				<img
					*ngIf="hide_cropper"
					class="imagewindow"
					[ngStyle]="{ height: cropperSettings.canvasHeight, width: cropperSettings.canvasWidth }"
					[src]="img_link"
				>
				<i *ngIf="!uploading && !data.image && !img_link" class="material-icons text-gray-500 placeholder-img">image</i>
				<div style="height:0px; overflow:hidden">
					<input #ImageUpload class="file-picker" type="file" id="imageupload" (change)="uploadImage($event)" name="bok" accept="image/*">
				</div>

				<div *ngIf="!(data && data.image && !submitted)" class="row w-100 d-inline-flex justify-content-center mt-2">
					<button *ngIf="!uploading" class="btn btn-primary" (click)="ImageUpload.click()">Upload Image</button>
				</div>

				<div *ngIf="!uploading && data && data.image && !submitted" class="row w-100 d-inline-flex justify-content-between mt-2">

					<!-- UPLOAD FILE button -->
					<div *ngIf="!uploading">
						<button class="btn btn-gray-100" (click)="ImageUpload.click()">Re-upload Image</button>
					</div>

					<!-- SUBMIT button -->
					<div>
						<!-- upload to s3, return the link & cropped blob back using the onUploadDone() callback -->
						<div class="file-upload">
							<button class="btn btn-primary" (click)="uploadCroppedImage()">Confirm Crop</button>
						</div>

					</div>
				</div>
			</div>

			<!-- DEBUGGING DISPLAY -->
			<ng-container *ngIf="DEBUG">
				<p class="mb-6"><strong>img_link</strong>: {{img_link}}</p>
				<p class="mb-6"><strong>data image</strong>: {{data.image}}</p>
				<p><strong>cropperSettings</strong>: {{cropperSettings | json}}</p>
			</ng-container>


		</div>
	</div>

	<div *ngIf="link.length > 0" class="cropper-wrapper">
		<!-- Once Cropped, allow user to SAVE, or go BACK -->
		<div *ngIf="link!=''"
			class="mx-auto"
			[ngStyle]="{ height: cropperSettings.canvasHeight, width: cropperSettings.canvasWidth}"
		>
			<img class="imagewindow"
				[ngStyle]="{ height: cropperSettings.canvasHeight, width: cropperSettings.canvasWidth}"
				[src]="link"
			>
		</div>
		<div *ngIf="link!==''" class="textblock col-centered col-12">
			<div class="row w-100 d-inline-flex justify-content-between mt-2">
				<button class="btn btn-gray-100" (click)="back()">Back</button>
				<button class="btn btn-gray-100" (click)="submit()">Confirm</button>
			</div>
		</div>
	</div>

	<!-- hidden canvas for the target crop -->
	<!-- <img #croppedImage style="display:none" *ngIf="data&&data.image" [src]="data.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight"> -->
</avia-filedrop>
