import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { AVIAConnectService } from '../../../avia-connect.service';
import { Common } from '../../../common';

import {
  AccessKey,
  Color_Library,
  E_ConnectionManagerActions,
  Org,
  Priority
} from '../../../../class';


@Component({
  selector: 'app-avia-pri-network-view',
  templateUrl: './avia-pri-network-view.component.html',
  styleUrls: ['./avia-pri-network-view.component.scss', '../avia-network.scss']
})

// JAW
export class AviaPriNetworkViewComponent implements OnInit {
  readonly VERBOSE: boolean = false;
  @Input() org_id:    number;
  @Input() pri_id:    number;
  @Input() view_type: string = 'box'; //box or card

  @Output('closeModal') closeModal = new EventEmitter();
  @Output() onConnect: EventEmitter<any> = new EventEmitter();

  public akey: AccessKey = new AccessKey();
  public loading: boolean = false;
  public org: Org = new Org();
  public priority: any;

  public color:      string = Color_Library.gray_400;
  public color_dark: string = Color_Library.gray_600;


  constructor(public aviaService: AVIAConnectService) { };

  ngOnInit() {
    this.init(this.org_id, this.pri_id);
  }

  // *** INIT ***
  async init(org_id, pri_id) {
    this.VERBOSE && console.log('org_id:', org_id);
    this.VERBOSE && console.log('pri_id:', pri_id);

    this.loading = true;

    let [
      org_res,
      priority_res,
    ] = await Promise.all([
      this.aviaService.getHealthcareSystems({ id: org_id }),
      this.aviaService.getPriorityPeek(org_id, pri_id)
    ]);

    this.org = org_res.body.results[0];

    this.initUserAccess(this.org.rd)

    this.setColors(this.org);
    this.priority = priority_res.body;


    this.loading = false;

    this.VERBOSE && console.log('priority:', this.priority);
    this.VERBOSE && console.log('org:', this.org);

  }

  // *** COLORS ***
  setColors(org: Org) {
    if (org.color) {
      this.color = `#${org.color}`;
      this.color_dark = Common.changeColor(this.color, -0.2);
    } else {
      this.color = Color_Library.blue_light;
      this.color_dark = Color_Library.blue_dark;
    }
  };

  close(){
    this.closeModal.emit(true)
  }

  async connectUser(user_id){
    try {
      await this.aviaService.manageUserConnection( E_ConnectionManagerActions.CONNECT, user_id, {message: null} );
    } catch (err) {
      console.error('connectWithUser error');
      console.log( err );
    }
    let priority_res = await this.aviaService.getPriorityPeek(this.org_id, this.pri_id);
    this.priority = priority_res.body;
    this.onConnect.emit();
  }

  private async initUserAccess(resource_desc: Object = {}, forceReload: boolean = false) {
    let session = await this.aviaService.getSessionSupport(forceReload);
    //Using this because it only gives permission to avian, superadmin, and is_my_org and we dont have a specific pri core r
    this.akey = this.aviaService.hasAccess(session, 'inv', 'core', 'crwd', resource_desc);
    // console.log(this.akey)
  };

}
