<div class="page p-0 m-0 bg-white h-100 container-fluid">
<div *ngIf="!isLoggedIn" class="headerblock pt-3 pb-3 p-0 m-0 row">
  <div class="col-12 d-flex">
    <a class="accent ptr" href="http://www.aviahealthinnovation.com"><img class="avialogo align-middle img-fluid mx-auto d-block" src="/assets/header-avia-connect-logo.svg"></a>
  </div>
</div>
  <div class="pl-3 pr-3 pt-6 ml-0 mr-0 mb-0 bg-white">
    <div class="row">
      <div class="col-12">
        <h1 class="text-center mb-2 mb-sm-8">AVIA Connect Firewall<br/>Test Page</h1>
      </div>
      <div class="col-10 col-md-6 align-self-center text-center mx-auto my-auto">
        <div class="header-under-dash pt-4 pb-4">
          <div class="dash text-center"></div>
        </div>
        <div class="container">
          <p class="mt-1 mb-4 text-center">This page tests that your firewall settings are compatible with AVIA Connect by attempting to reach our data backend.</p>
          <div *ngIf='!searching && !aviaService.backendTest' class='mx-auto text-center mt-4 mb-6'><img width='20%' height='20%' src='/assets/cloud-sad.png'/>
            <div><h2 class='text-center mt-4 mb-4'>Test Failed!</h2><p class="text-center">Check your firewall, Couldn't Reach Data Service on {{url}}.  {{result.result?.err}}</p></div>
          </div>
          <div *ngIf='!searching && aviaService.backendTest' class='mx-auto text-center mt-4 mb-6'><img width='20%' height='20%' src='/assets/cloud-happy.png'/>
            <div><h2 class='text-center mt-4 mb-4'>Success!</h2><p class="mb-1 text-center">You're all set, all systems go!</p><br/><p class="text-center">We were able to reach all required systems, and you should be able to access the application over your current network.</p></div>
          </div>
          <div *ngIf='searching' class='mx-auto text-center mb-4'><img width='20%' height='20%' src='/assets/cloud-refresh.png'/><div class='mt-4 mb-4'>Searching</div></div>
          <div class='text-center mt-4 mb-4'>Refreshing in {{timeleft}}</div>
          <div class='pointer btn btn-success mb-6 mb-sm-0 mt-4' (click)='test()'>TEST AGAIN</div>
        </div>
      </div>
    </div>
  </div>
</div>
