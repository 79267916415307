<div class="card-content mb-4 p-0" [ngClass]="{'no-shadow': (white_background || gray_border), 'gray-border': gray_border }">
	<div *ngIf="!content?.permitted" class="d-flex h-100 position-absolute w-100 fade-wrapper">
	</div>
	<!-- content appears in community or premium content -->
	<div *ngIf="!content?.permitted || (show_appears_in && content?.cm && content?.cm.length)"
		class="d-block d-sm-flex bg-gray-100 card-banner">
		<!-- community -->
		<!-- ONLY SHOW COMMUNITY IF PAGE OF CONTENT AND COMMUNITY ARE PUBLISHED-->
		<div *ngIf="show_appears_in && content?.cm && content?.cm.length && content?.permitted && pref_cm.visibility == 2 && pref_cm.page_visibility == 2" class="d-flex align-items-center w-100 px-4 py-2">
			<div class="cm-logo">
				<img class="company-logo rounded cm-logo" onerror="this.src='/assets/placeholder-company.jpg'" [src]="pref_cm.logo"/>
			</div>
			<div class="pl-2 text-gray-600">
				<span [routerLink]="['/communities/page', pref_cm.page_id]" class="pointer font-weight-bold">See more in the {{pref_cm.name}} Community</span>
			</div>
		</div>
		<!-- premium content -->
		<div *ngIf="!content?.permitted" class="d-block d-sm-flex justify-content-between align-items-center bg-gray-300 premium py-2 pr-2 w-100 pl-4">
			<div class="d-flex align-items-start align-items-sm-center h-100 pr-0 pr-sm-2">
				<div>
					<div class="bg-green-lime rounded d-flex align-items-center justify-content-center avia-wrapper">
						<img class="avia-logo" [src]="'/assets/AVIA-a-white.svg'"/>
					</div>
				</div>

				<div class="pl-2 font-size-10 premium-msg">
					<span class="text-green-lime font-weight-bold">Premium Content:</span>
					<span *ngIf="(content?.member_focus && content?.member_focus?.length) || (content?.member_types && content?.member_types?.length)"> This content is available to members who have
						<ng-container *ngIf="content?.member_focus && content?.member_focus?.length; else memberFocus">
							<span> access to </span>
							<span *ngFor="let mf of content?.member_focus; let i=index;">
								{{ mf?.name }}{{ content?.member_focus?.length == 2 && i !== content?.member_focus?.length - 1 ? ' or ' : (content?.member_focus?.length == 1 ? '.' : (i == content?.member_focus?.length - 1 ? '.' : ', '))}}
							</span>
						</ng-container>
						<ng-template #memberFocus>
							<span *ngFor="let mt of content?.member_types; let i=index;">
								{{ mt?.name }}{{ content?.member_types?.length == 2 && i !== content?.member_types?.length - 1 ? ' or ' :
									(i != content?.member_types?.length - 1 ? ', ' : '')}}
							</span>
							<span> membership type access.</span>
						</ng-template>
					</span>
					<span *ngIf="(content?.org_types && content?.org_types)?.length > 0"> This content is available to members who are a
						<ng-container *ngIf="content?.org_types && content?.org_types?.length">
							<span *ngFor="let ot of content?.org_types; let i=index;">
								{{ ot?.name }}{{ content?.org_types?.length == 2 && i !== content?.org_types?.length - 1 ? ' or ' : (content?.org_types?.length == 1 ? '.' : (i == content?.org_types?.length - 1 ? '.' : ', '))}}
							</span>
						</ng-container>
					</span>
				</div>
			</div>
			<button
				[routerLink]="['/communities/page/68']"
				placement="bottom"
				ngbTooltip="This content is available to AVIA members. Learn more"
				class="premium-btn border-0 btn-gray-100 rounded text-gray-600 font-size-10 font-weight-bold mt-2 mt-sm-0"
				(click)="logEvent('avia_membership_learn')"
				>Learn&nbsp;More
			</button>
		</div>
	</div>

	<div class="card-header pt-4 pb-0 bg-white" [ngClass]="{'px-0': white_background, 'px-4': !white_background }">
		<div class="d-flex justify-content-between w-100">
			<!-- display name -->
			<div class="d-flex justify-content-between w-100" style="min-width: 0;">
				<button class="content-name text-blue-dark heading-size-16 text-capitalize text-truncate-3 font-large p-0 border-0 bg-white text-left"
					[ngClass]="{'pointer': content?.permitted}"
					[disabled]="!content?.permitted"
					(click)="goToContentSource(content?.link, content?.name, content?.file_name, content?.id); logEvent('content_click')"
					>{{ content?.name }}
				</button>
			</div>
				<!-- Buttons -->
				<div class="actions d-flex text-gray-800">
					<!-- the duplicate elements are necessary  -->
					<div
						*ngIf="keychain_cl?.card.w && content?.id == active_id"
						class="d-flex align-items-center justify-content-center mr-2 bg-gray-300 remove-circle pointer edit-button-align"
						(click)="openDeleteModal()">
						<i class="material-icons text-white">delete</i>
					</div>
					<app-avia-alert-modal #alertModal
						[button_confirm_text]="'Remove'"
						[modal_title]="'Are you sure you want to delete this content?'"
						[show_cancel_button]="true"
						[type]="0"
						(confirmEmit)="deleteContent($event)"
						>
					</app-avia-alert-modal>
					<app-content-edit-modal
						*ngIf="keychain_cl?.card?.w"
						ngbTooltip="Edit Content"
						placement="top"
						[membership_akey]="keychain_cl?.avia_org_membership"
						[card_akey]="keychain_cl?.card"
						[content_types]="content_types"
						[content_id]="content.id"
						(onContentSubmitted)="onSetActive()"
					></app-content-edit-modal>
				</div>
			</div>
	</div>

	<div *ngIf="active_id == content?.id" class="card-body row pb-4 pt-0 w-100" [ngClass]="{'px-0': white_background }">
		<!-- type obj name -->
		<div class="col-12">
			<div class="d-none d-sm-inline-flex text-gray-600 font-body mb-2">
				<div class="d-inline-flex">
					<span *ngIf="content?.type_obj">{{ content?.type_obj.name }}</span>
					<span *ngIf="content?.type_obj && content?.for_avia_only" class="spacer ml-2 mr-2 bg-gray-600"></span>
					<span *ngIf="content?.for_avia_only">Internal</span>
				</div>
			</div>
			<app-avia-textarea
				[value]="content?.description"
				[editing]="false"
			></app-avia-textarea>
		</div>
		<!-- media -->
		<div class="col-sm-7 col-12 pt-2">
			<div class="media">
				<!-- logo -->
				<img *ngIf="(content?.logo_link || (content?.source_obj && content?.source_obj?.logo_link))"
					class="align-self-center content-logo mr-2"
					[src]="(content?.source_obj?.logo_link ? content?.source_obj?.logo_link : content?.logo_link)">
				<div *ngIf="!content?.logo_link && !content?.source_obj" class="align-self-center content-logo bg-gray-400 mr-2 text-center">
					<div class="d-flex align-items-center h-100 justify-content-center">
						<i class="material-icons text-white">library_books</i>
					</div>
				</div>
				<div class="media-body align-self-center">
					<div *ngIf="content?.author"><em>{{ content?.author }}</em></div>
					<div *ngIf="content?.published_date" class="d-inline"><em>Published {{ content?.published_date | date }}</em></div>
					<div *ngIf="!content?.published_date && content?.created" class="d-inline"><em>Created {{ content?.created | date }}</em></div>
					<div *ngIf="(content?.published_date || content?.created) && (content?.source || content?.link || content?.source_obj)" class="d-inline line pl-1"></div>
					<div *ngIf="!content?.produced_by_obj?.name && (content?.source || content?.link || content?.source_obj)" class="d-inline">
						<em>{{ content?.produced_by_obj?.web_addr ?  (content?.produced_by_obj?.web_addr | prettyDomain) : (content?.source ? content?.source : ((content?.source_obj && content?.source_obj?.source) ? content?.source_obj?.source : (content?.link ? (content?.link | prettyDomain) : '')))}}</em>
					</div>
					<div *ngIf="content?.produced_by_obj?.name" class="d-inline">
						{{content?.produced_by_obj?.name}}
					</div>
				</div>
			</div>
		</div>
		<!-- Topics list -->
		<div *ngIf="active_id === content?.id" class="avia-list-body col-sm-5 px-0 pt-2 mt-2 mb-2 mb-sm-4 mx-6 mx-sm-0 pl-sm-4 pr-sm-6 pt-sm-4 mt-sm-0">

			<div class="pt-2" *ngIf="content?.groups?.length > 0">
				<ul class="list-unstyled">
					<li *ngFor="let g of content?.groups"
						class="pb-2 pl-sm-2 pl-lg-0 pointer"
						[queryParams]="{ id: g?.id }"
						[routerLink]="['/intelligence/km/graph']"
						>
						<div class="d-flex align-items-center justify-content-between">
							<div class="w-100 row align-items-center no-gutters">
								<div class="col-1 pr-0">
									<i *ngIf="g?.type === 1" class="material-icons topic-icons text-white bg-blue-dark">extension</i>
									<i *ngIf="g?.type === 2" class="material-icons topic-icons text-white bg-orange-dark">vpn_key</i>
									<i *ngIf="g?.type === 3" class="material-icons topic-icons text-white bg-teal-dark">important_devices</i>
									<i *ngIf="g?.type === 4" class="material-icons topic-icons text-white bg-violet-dark">trending_up</i>
									<i *ngIf="g?.type === 5" class="material-icons topic-icons text-white bg-green-dark">build</i>
									<i *ngIf="g?.type === 6" class="material-icons topic-icons text-white bg-red-dark">insert_chart</i>
								</div>
								<div class="col-10 pl-3 pl-lg-0">
									<div class="text-truncate text-capitalize">{{ g?.name }}</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div *ngIf="active_id != content?.id" class="card-body row no-gutters pt-0" [ngClass]="{'px-0': white_background }">
		<div class="col-11">
			<span [innerHTML]="content?.description | truncate: 200 : '... '"></span>
			<span *ngIf="content?.permitted" class="pl-2 text-blue-dark pointer" (click)="onSetActive(); logEvent('content_views')">Read more</span>
		</div>
		<div class="d-flex flex-wrap pt-2 w-100">
			<div class="media w-100">
				<img *ngIf="!content?.produced_by_obj?.logo && (content?.logo_link || (content?.source_obj && content?.source_obj?.logo_link))"
					class="align-self-center content-logo mr-2"
					[src]="(content?.source_obj?.logo_link ? content?.source_obj?.logo_link : content?.logo_link)"
				>
				<img *ngIf="content?.produced_by_obj?.logo"
					class="align-self-center content-logo mr-2"
					[src]="content?.produced_by_obj?.logo"
				>
				<div *ngIf="!content?.logo_link && !content?.source_obj && !content?.produced_by_obj?.logo" class="align-self-center content-logo bg-gray-400 mr-2 text-center">
					<div class="d-flex align-items-center h-100 justify-content-center">
						<i class="material-icons text-white">library_books</i>
					</div>
				</div>
				<div class="media-body align-self-center w-100">
					<div *ngIf="content?.author" class="content-author"><em>{{ content?.author }}</em></div>
					<div *ngIf="content?.published_date" class="d-inline"><em>Published {{ content?.published_date | date }}</em></div>
					<div *ngIf="!content?.published_date && content?.created" class="d-inline"><em>Created {{ content?.created | date }}</em></div>
					<div *ngIf="(content?.published_date || content?.created) && (content?.source || content?.link || content?.source_obj)" class="d-inline line"></div>
					<div *ngIf="!content?.produced_by_obj?.name && (content?.source || content?.link || content?.source_obj)" class="d-inline">
						<em>{{ content?.produced_by_obj?.web_addr ?  (content?.produced_by_obj?.web_addr | prettyDomain) : (content?.source ? content?.source : ((content?.source_obj && content?.source_obj?.source) ? content?.source_obj?.source : (content?.link ? (content?.link | prettyDomain) : ''))) }}</em>
					</div>
					<div *ngIf="content?.produced_by_obj?.name" class="d-inline">
						{{content?.produced_by_obj?.name}}
					</div>
				</div>
			</div>
			<div *ngIf="content?.recommended" class="pr-4 pt-2">
				<div class="recommended text-white p-1 pr-2 d-flex">
					<i class="material-icons text-white">check</i>
					<span>RECOMMENDED</span>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf='false' class="card-footer d-flex justify-content-between bg-gray-100 py-2 pl-4 pr-2 flex-wrap flex-sm-nowrap">
		<div class="dash d-flex pt-1 mx-0 my-auto">
			<button
				class="position-relative pointer p-0 mr-2 border-0 bg-gray-300"
				[ngClass]="{'btn-disabled': !content?.permitted}"
				[disabled]="!content?.permitted"
				(click)="updateContent({'liked': (content?.liked * -1 + 1 )}); logToggleEvent('cl_like')">
				<i *ngIf="!content?.permitted"
					class="material-icons text-center text-white"
					[ngClass]="{ 'bg-accent-light': content?.liked, 'bg-gray-300': !content?.liked, 'active-btn': content?.permitted }">
					thumb_up
				</i>
				<i *ngIf="content?.permitted"
					class="material-icons text-center text-white"
					[ngClass]="{ 'bg-accent-light': content?.liked, 'bg-gray-300': !content?.liked, 'active-btn': content?.permitted }"
					placement="bottom"
					ngbTooltip="Like">
					thumb_up
				</i>
				<span *ngIf="content?.likes_count > 0" [ngClass]="{ 'bg-accent': content?.liked, 'bg-gray-600': !content?.liked }" class="position-absolute badge badge-pill count text-white">{{ content?.likes_count }}</span>
			</button>
			<button
				class="position-relative pointer p-0 mr-2 border-0 bg-gray-300"
				[ngClass]="{'btn-disabled': !content?.permitted}"
				[disabled]="!content?.permitted"
				(click)="onSetActive()">
				<i *ngIf="!content?.permitted"
					class="material-icons text-center text-white"
					[ngClass]="{ 'bg-accent-light': content?.chat_count > 0, 'bg-gray-300': content?.chat_count <= 0 }">
					comment
				</i>
				<i *ngIf="content?.permitted"
					class="material-icons text-center text-white"
					[ngClass]="{ 'bg-accent-light': content?.chat_count > 0, 'bg-gray-300': content?.chat_count <= 0 }"
					placement="bottom"
					ngbTooltip="Comments">
					comment
				</i>
				<span *ngIf="content?.chat_count > 0 && content?.chat_count < 10"
					[ngClass]="{ 'bg-accent': content?.chat_count > 0 && content?.chat_count < 10, 'bg-gray-600': content?.chat_count <= 0 }"
					class="position-absolute badge badge-pill count text-white">{{ content?.chat_count }}
				</span>
				<span *ngIf="content?.chat_count >= 10"
					[ngClass]="{ 'bg-accent': content?.chat_count >= 10, 'bg-gray-600': content?.chat_count <= 0 }"
					class="position-absolute badge badge-pill count text-white">+
				</span>
			</button>
			<div class="pointer share-modal-wrapper bg-gray-300 mr-2">
				<!--<app-content-share-modal [content]="content"></app-content-share-modal>-->
			</div>
			<button
				class="pointer p-0 mr-2 border-0 bg-gray-300"
				[ngClass]="{'btn-disabled': !content?.permitted}"
				[disabled]="!content?.permitted"
				(click)="updateContent({'interested': (content?.interested * -1 + 1 )}); logToggleEvent('cl_bookmark')">
				<i *ngIf="!content?.permitted"
					class="material-icons text-center text-white"
					[ngClass]="content?.interested ? 'bg-accent-light' : 'bg-gray-300'">
					bookmark
				</i>
				<i *ngIf="content?.permitted"
					class="material-icons text-center text-white"
					placement="bottom"
					ngbTooltip="Bookmark"
					[ngClass]="content?.interested ? 'bg-accent-light' : 'bg-gray-300'">
					bookmark
				</i>
			</button>
		</div>
		<div class="d-flex mx-0 py-1">
			<div class="my-auto">
				<span *ngFor="let km of content?.groups; let i=index">
					<span *ngIf="i < 3 && content?.permitted"
						class="badge text-white text-truncate pointer d-none d-md-block float-right ml-2 mr-0 px-2 my-1"
						[ngClass]="{
							'badge-blue-dark': (km?.type === 1),
							'badge-orange-dark': (km?.type === 2),
							'badge-teal-dark': (km?.type === 3),
							'badge-violet-dark': (km?.type === 4),
							'badge-green-dark': (km?.type === 5),
							'badge-red-dark': (km?.type === 6)
						}"
						[queryParams]="{ id: km?.id }"
						[routerLink]="['/intelligence/km/graph']"
					>{{ km?.name | titlecase }}</span>
					<span *ngIf="i < 3 && !content?.permitted"
						class="badge text-white text-truncate d-none d-md-block float-right ml-2 mr-0 px-2 my-1"
						[ngClass]="{
							'badge-blue-dark': (km?.type === 1),
							'badge-orange-dark': (km?.type === 2),
							'badge-teal-dark': (km?.type === 3),
							'badge-violet-dark': (km?.type === 4),
							'badge-green-dark': (km?.type === 5),
							'badge-red-dark': (km?.type === 6)
						}">{{ km?.name | titlecase }}
					</span>
				</span>
			</div>
			<div *ngIf="allow_recommending" ngbDropdown placement="bottom-right" class="pointer my-auto d-md-block">
				<div ngbDropdownToggle class="pt-1">
					<i class="material-icons">more_vert</i>
				</div>
				<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right p-0">
					<div
						*ngIf="!content?.recommended"
						class="dropdown-item"
						(click)="addNewsfeedContentRecommendation(content)">
						Mark as Recommended
					</div>
					<div
						*ngIf="content?.recommended"
						class="dropdown-item"
						(click)="removeNewsfeedContentRecommendation()">
						Remove Recommendation
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card-body" *ngIf="active_id == content?.id && content?.permitted && false">
			<div class="pb-4">{{ content?.chat_count }}
				<span *ngIf="content?.chat_count !== 1">comments</span>
				<span *ngIf="content?.chat_count === 1">comment</span>
			</div>
			<app-avia-chat #cl_comments
				[email_notify]="false"
				[group_id]="content?.chat_group"
				[location]="'content'"
				[location_id]="content?.id"
				(count_change)="getContent(content?.id)"
			></app-avia-chat>
			<app-avia-loader *ngIf="content?.chat_count > 0 && cl_comments.cs?.loading" [options]="{type: 'row'}"></app-avia-loader>
	</div>
</div>
