// @angular imports
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

// node_modules
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable,  Subscription, from } from 'rxjs';
import { map, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

// avia imports
import { Common } from '../../common';
import { AVIAConnectService } from '../../avia-connect.service';

import {  AnalyticEvent } from '../../../class';


// AVIA Search
// @example begin
// <app-avia-search #searchInput
//   [searching]="true">
// </app-avia-search>
// @example end
@Component({
  selector: 'app-avia-search',
  templateUrl: './avia-search.component.html',
  styleUrls: ['./avia-search.component.scss']
})
export class AviaSearchComponent implements OnInit {
  @Input() error_class: string = '';
  @Input() mobile_mode: boolean = false;
  @Input() searching:   boolean;
  @Input() prevent_nav: boolean = false;
  @Input() in_topnav_bar: boolean = false;
  @Input() term = '';
  @Input() autocomplete = false;
  @Input() // options
    set options(inputData: Avia_Search_Options) {
      this._options = new Avia_Search_Options();
      for (let i in inputData) {
        this._options[i] = inputData[i];
      }
    };
    get options(): Avia_Search_Options {
      return this._options;
    };
    _options: Avia_Search_Options = new Avia_Search_Options();

  @Output('close')         close         = new EventEmitter();
  @Output('selected')      selected      = new EventEmitter();
  @Output('toggle_search') toggle_search = new EventEmitter();
  @Output('update_search') update_search = new EventEmitter();
  @Output('enter_pressed') enter_pressed = new EventEmitter();

  @ViewChild('searchBox', { static: false }) searchBox;

  query_params_sub: Subscription;
  obs_term: FormControl = new FormControl();
  querystamp: number = Date.now();
  show_typeahead: boolean = true;
  constructor( private config: NgbDropdownConfig, private route: ActivatedRoute, public aviaService: AVIAConnectService, private router: Router, ) {
    this.query_params_sub = this.route.queryParams.subscribe(params => {
      if(params.term) this.term = params.term;
    });
    config.autoClose = true;
    this.obs_term.valueChanges
    .pipe( map((value) => {
      if(typeof value === "object") value = value.name;
      return value.replace(/[\(\)\[\]\{\}\?]/g, '')
    }) )
    // .filter(value => value === '' || value.length > 1) //Legacy filter forcing searches to be >2 characters
    .pipe( debounceTime(200) ) // NOTE: Why is this deviated from the standard of 300ms setup in Common.DEBOUNCE_TIME?
   // .pipe( distinctUntilChanged() )
    .subscribe(
      (value) => {
        this.term = value.toString();
        if( this.options.search_on_keypress ) {
          this.updateSearch(this.term)
        }
        if (this.searching === false) {
          this.toggleSearch(true);
        }
      },
      (err) => {
        console.error('Error: %s', err);
      },
      () => { console.log('Complete'); }
    );
  }

  ngOnInit() {}


  updateSearch( term:string, offset:number = undefined, count:number = undefined ): void {
    let obj = {
      term: term,
      active_users_only: this.options.active_users_only ?  true : false
    };
    if (offset !== undefined) {
      obj['offset'] = offset;
      if (count !== undefined) obj['count'] = count;
    }
    this.update_search.emit(obj);
  }

  submitSearch( term ): void {
    this.show_typeahead = false;
    this.querystamp += 1;
    if(this._disable_enter) return;
    this.enter_pressed.emit({
      enter_pressed:true,
      term:term,
      active_users_only: this.options.active_users_only ?  true : false
    });
    this.updateSearch( term );
  }

  toggleSearch( bool ): void {
    // this allow items to be clicked before turning off the results
    let time = !bool ? 200 : 0;
    setTimeout(() => {
      this.searching = bool;
      this.toggle_search.emit({ searching: this.searching });
    }, time);
  }

  focusSearchBox(): void {
    this.searchBox.nativeElement.focus();
  }

  clear(): void {
    if (this.term) this.term = "";
  }

  ngOnDestroy() {
    if(this.query_params_sub) this.query_params_sub.unsubscribe();
  }

  async navigate(r) {
    let event = new AnalyticEvent(
      'autocomplete_search_click',
      {
        "current": location.pathname,
        "urlparams": location.search,
        "search_term":this.searchBox.nativeElement.value,
        "target":r
      },
      this.searchBox.nativeElement.value
    );
    await this.aviaService.createAnalyticEvent(event);

    if (this.prevent_nav) {
      this.selected.emit(r);
    }
    else {
      let query = [];
      for(let item in r.item.route_params) {
        query.push(`${item}=${r.item.route_params[item]}`);
      }
      if (!this.prevent_nav) {
        window.location.href = `${r.item.route_path.split("?")[0]}${query.length > 0?`?${query.join('&')}`:''}`;
      }
    }

    this.clear();
  }

  typeaheadSearch = (text$: Observable<string>) => {
      if(!this.autocomplete) return from(Promise.resolve([]));

      let getTypeAhead = (term)=>{
        this.querystamp += 1;
        let current_stamp = this.querystamp;
        this.show_typeahead = true;
        return from(this.aviaService.getSearchCustom(Object.assign({limit: 10, offset: 0, elasticmode: true, active_users_only: true}, {term:term}),{
          query:"prefix",
          type:[{type: "user"},{type: "org"},{type: "topic"},{type: "product"},{type: "channel"},{type: "workspace"},{type:"community"}],
          fields:{
            "user":{
              "autocomplete":{score:10}
            },
            "org":{
              "autocomplete":{score:3}
            },
            "topic":{
              "autocomplete":{score:1}
            },
            "topic_capabilities":{
              "autocomplete":{score:8}
            },
            "product": {
              "autocomplete":{score:1}
            },
            "channel": {
              "autocomplete":{score:1}
            },
            "workspace": {
              "name.autocomplete":{score:3},
              "description.autocomplete":{score:3}
            },
            "community":{
              "autocomplete":{score:3}
            }
          },
          //aggregations:["names"]
        }).then((data)=>{
          if(current_stamp != this.querystamp) return [];
          let results = [];
          data.body.hits.hits.forEach((i)=>{
            let item = i._source[i._source.type];
            let type = i._source.type;
            // if (this.prevent_nav) { item.route_path = undefined; debugger; }
            let result = {
              item: item,
              name: item.name || item.fullname,
              descriptor: null,
              image: item.avatar || item.logo,
              type: type,
              icon: null
            }
            if(type === "topic") {
              let map = {
                1:{
                  bg:'bg-blue-dark',
                  icon: 'extension',
                  descriptor: 'Capability'
                },
                2:{
                  bg:'bg-orange-dark',
                  icon: 'vpn_key',
                  descriptor: 'Solution'
                },
                3:{
                  bg:'bg-teal-dark',
                  icon: 'important_devices',
                  descriptor: 'Technology'
                },
                4:{
                  bg:'bg-violet-dark',
                  icon: 'trending_up',
                  descriptor: 'Trend'
                },
                5:{
                  bg:'bg-green-dark',
                  icon: 'build',
                  descriptor: 'Competency'
                },
                6:{
                  bg:'bg-red-dark',
                  icon: 'insert_chart',
                  descriptor: 'Metric'
                }
              }

              result.descriptor = map[item.type].descriptor;
              result.icon = map[item.type]
            }
            else if (type === "user") {
              result.descriptor = `${item.title ? item.title + ' at' : ''} ${item.org_name}`;
            }
            else if (type === "product") {
              result.descriptor = "Product"
            }
            else if (type === "community") {
              result.name = item.page_name;
              result.descriptor = "Community";
            }
            else if (type === "group" || type === "workspace") {
              if(!result.image) result.image = "/assets/placeholder-company.jpg"
              result.descriptor = "Group"
            }
            results.push(result);
          });



          return results;
        }));
      }

      return text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => term.length < 2 ? from(Promise.resolve([]))
          : getTypeAhead(term))
      )
  }
  _disable_enter = false;
  typeaheadInputFormatter = ($event)=>{
    this._disable_enter = true;
    return $event.name;
  }
}



export class Avia_Search_Options {
  constructor (
    public fake: boolean = false,
    public search_text: string = 'Search',
    public search_on_keypress = true,
    public search_bar_white_bg = false,
    public active_users_only = false
  ) { }
}
