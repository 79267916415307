import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { Common } from './common';

export class CommonValidators {
  static isValidDate(date: string, min_year: number): boolean {
    if(!this.isValidFourDigitYear(date, min_year)) {
      return false;
    }
    let moment_date = moment(date, 'YYYY-MM-DD').format();
    if(moment_date === undefined || moment_date == 'Invalid date') { // string of 'Invalid date' is what it becomes from moment
      return false;
    }
    return moment(date).isValid();
  }

  static isValidFormDate(min_year: number) {
    return(d: AbstractControl): {[key: string]: any} => {
      if(d &&
         d.value &&
         this.isValidFourDigitYear(d.value,min_year) &&
         moment(d.value, 'YYYY-MM-DD').format() != undefined &&
         moment(d.value, 'YYYY-MM-DD').format() != "Invalid date" &&
         moment(d.value).isValid()) {
        return null;
      }
      return { 'isValidFormDate': {valid: false}};
    }
  }

  static isValidFormDateOrNull(min_year: number) {
    return(d: AbstractControl): {[key: string]: any} => {
      if(d.value == null || d.value == ''){
        return null;
      } else if(d &&
         d.value &&
         this.isValidFourDigitYear(d.value,min_year) &&
         moment(d.value, 'YYYY-MM-DD').format() != undefined &&
         moment(d.value, 'YYYY-MM-DD').format() != "Invalid date" &&
         moment(d.value).isValid()) {
        return null;
      }
      return { 'isValidFormDate': {valid: false}};
    }
  }

  static isValidFourDigitYear(date: string, min_year: number): boolean {
    let regex = new RegExp(/^(\d{4})-/);   // Regex for 4 digit year with - delimiter
    if(!regex.test(date)) return false;
    let year = regex.exec(date)[1];
    if(parseInt(year) < min_year) {     // digit year >= min_year
      return false;
    }
    return regex.test(date);
  }

  static isLinkFromS3(link): boolean {
    if (link == undefined) return false;
    let bool = (link.toLowerCase().indexOf('avia') > -1 && link.toLowerCase().indexOf('s3') > -1);
    return bool;
  }

  // Check an Org's Salesforce ID to see if it is a valid Account ID
  static isSfAccountIdValid = function (id: string): boolean {
    if (id && id.length >= 15 && id.length <= 18 && /^001/.test(id)) return true;
    return false;
  }

  // Check a User's Salesforce ID to see if it is a valid Contact ID
  static isSfContactIdValid = function (id: string): boolean {
    if (id && id.length >= 15 && id.length <= 18 && /^003/.test(id)) return true;
    return false;
  }

  static minLengthTrimmedString(min: number) {
    return(c: AbstractControl): {[key: string]: any} => {
      if(c && c.value && c.value.trim().length >= min) {
        return null;
      }
      return { 'minLengthTrimmedString': {valid: false}};
    }
  }

  static minLengthArray(min: number) {
    return(c: AbstractControl): {[key: string]: any} => {
      if(c && c.value && c.value.length >= min) {
        return null;
      }
      return { 'minLengthArray': {valid: false}};
    }
  }

  static minLengthTruthyArray(min: number) {
    return(c: AbstractControl): {[key: string]:any} => {
      if(c && c.value && c.value.length) {
        let counter = 0;
        for(let val of c.value) {
          if(val == true) {
            counter++;
          }
          if(counter >= min) return null;
        }
      }
      return { 'minLengthTruthyArray': {valid: false}};
    }
  }

  static maxLengthArray(max: number) {
    return(c: AbstractControl): {[key: string]: any} => {
      if(c && c.value && c.value.length <= max) {
        return null;
      }
      return { 'maxLengthArray': {valid: false}};
    }
  }

  static isStringWhitespace() {
    return(c: AbstractControl): {[key: string]: any} => {
      if(c && c.value && typeof c.value == "string") {
        let cleansed_string = Common.removePrecedingAndTrailingWhiteSpace(c.value);
        if(cleansed_string == '') return { 'isStringWhiteSpace': { valid: false }};
      }
      return null;
    }
  }

  static isValidPhoneNum() {
    return(c: AbstractControl): {[key: string]: any} => {
      if(c && c.value && typeof c.value == "string") {
        let valid = true;
        let numex = new RegExp(/^\+?([0-9]|-|\(|\)| )+$/);
        if(!numex.test(c.value)) valid = false;
        let numOnly = c.value.replace(/\D/g,'');
        if(numOnly.length > 12) valid = false;
        if(valid){
          return null;
        } else {
          return { 'isValidPhoneNum': { valid: false }};
        }
      }
    }
  }
}

