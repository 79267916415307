<div class="card" [ngClass]="{'no-shadow': view_type === 'box'}">
	<div class="card-header d-inline-flex p-0" [ngStyle]="{'background-color': color_dark}">
		<div class="title-box d-flex align-items-center justify-content-center safari-flex">
			<i class="material-icons text-white">done_all</i>
		</div>
		<div class="row w-100 no-gutters align-items-center py-2 px-2" [ngStyle]="{'background': color}">
			<div class="col-12 col-sm-6 text-white">
				<div class="d-inline">{{ inv_item?.sc_id_obj?.name }}</div>
				<div class="d-inline" *ngIf="!loading">&nbsp;at&nbsp;</div>
				<div class="d-inline">{{ inv_item?.hs_id_obj?.name }}</div>
				<div class="d-inline" *ngIf="inv_item?.name">: {{inv_item?.name}}</div>
			</div>
			<div class="col-12 col-sm-6 text-left text-md-center text-lg-right py-2 py-sm-0">
				<div class="d-flex justify-content-between justify-content-sm-end align-items-center">
					<button class="view-inv-btn px-2 mr-2" *ngIf="akey.r" [routerLink]="['/dashboard/hs/', inv_item?.hs_id, 'inventory', inv_item?.id]"
						[ngStyle]="{'background': color}" (click)="close()">View&nbsp;Inventory
					</button>

					<div class="d-flex h-100 align-items-center justify-content-end">
						<div class="d-flex pr-2 pointer align-items-center" [ngStyle]="{'background': color}"
							data-toggle="tooltip" placement="left" [ngbTooltip]="inv_item?.hs_id_obj?.name"
							[routerLink]="['/profiles/hs/', inv_item?.hs_id]"
							(click)="close()">
							<img class="img rounded align-self-center mx-auto"
								[src]="inv_item?.hs_id_obj?.logo"
								onerror="this.src='/assets/placeholder-company.jpg'"
							/>
						</div>
						<div class="d-flex pr-2 pointer align-items-center" [ngStyle]="{'background': color}"
							data-toggle="tooltip" placement="left" [ngbTooltip]="inv_item?.sc_id_obj?.name"
							[routerLink]="['/sc/', inv_item?.sc_id]"
							(click)="close()">
							<img class="img rounded align-self-center mx-auto"
								[src]="inv_item?.sc_id_obj?.logo"
								onerror="this.src='/assets/placeholder-company.jpg'"
							/>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
	<div class="card-body" [ngClass]="{'card-body-border': view_type === 'box'}">
		<app-avia-loader *ngIf="loading" [options]="{type: 'row', rows: 5}"></app-avia-loader>
		<div *ngIf="!loading">

			<div *ngIf="inv_item?.products.length">
				<div class="border-gray-300 border-bottom font-weight-bold">Products</div>
				<div class="pt-2 pointer text-truncate" *ngFor="let product of inv_item?.products"
					[routerLink]="['/sc/', inv_item?.sc_id, 'product', product.id]"
					(click)="close()">
					<img class="img rounded align-self-center mx-auto"
						data-toggle="tooltip" placement="right" [ngbTooltip]="inv_item?.sc_id_obj?.name"
						[src]="inv_item?.sc_id_obj?.logo"
						onerror="this.src='/assets/placeholder-company.jpg'"
					/>
						<span class="pl-1">{{ product.name }}</span>
				</div>
			</div>

			<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Stage</div>
			<div class="pt-2">
					<span *ngIf="inv_item?.status_obj?.subname">&nbsp;{{inv_item?.status_obj?.subname}}</span>
			</div>

			<div *ngIf="inv_item?.capabilities.length > 0">
				<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Capabilities</div>
				<div class="pt-2 pointer text-truncate" *ngFor="let capability of inv_item?.capabilities"
					[routerLink]="['/intelligence/km/graph/']"
					[queryParams]="{ id: capability.id }"
					(click)="close()">
						<i class="material-icons rounded icon align-self-center text-white mx-auto bg-blue-light">extension</i>
						<span class="pl-1">{{ capability.name }}</span>
				</div>
			</div>

			<div *ngIf="inv_item?.solutions.length > 0">
				<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Solutions</div>
				<div class="pt-2 pointer text-truncate" *ngFor="let solution of inv_item?.solutions"
					[routerLink]="['/intelligence/km/graph/']"
					[queryParams]="{ id: solution.id }"
					(click)="close()">
						<i class="material-icons rounded icon align-self-center text-white mx-auto bg-orange-light">vpn_key</i>
						<span class="pl-1">{{ solution.name }}</span>
				</div>
			</div>

			<div *ngIf="inv_item?.team.length">
				<div class="border-gray-300 border-bottom mt-4 font-weight-bold">Team</div>
				<div class="pt-2 d-flex" *ngFor="let user of inv_item?.team">
					<div class="user-img mr-2" [routerLink]="['/profiles/in/', user?.id]" (click)="close()">
						<img class="img rounded align-self-center mx-auto"
						data-toggle="tooltip" placement="right" [ngbTooltip]="user?.fullname"
						[src]="user?.avatar"
						onerror="this.src='/assets/placeholder-company.jpg'"
						/>
					</div>
					<div class="d-flex line-stretcher border-bottom">
						<div class="flex-grow-1 pointer utit" [routerLink]="['/profiles/in/', user?.id]" (click)="close()">
							<div class="font-weight-bold text-truncate">
								{{ user.fullname }}
								<span *ngIf="user.analytics_owner"   class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400 mr-1"><span class="align-text-bottom">analytics owner</span></span>
								<span *ngIf="user.project_manager"   class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400 mr-1"><span class="align-text-bottom">project manager</span></span>
								<span *ngIf="user.executive_sponsor" class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400 mr-1"><span class="align-text-bottom">executive sponsor</span></span>
								<span *ngIf="user.budget_owner"      class="d-inline-block text-uppercase align-middle font-weight-bold badge badge-pill text-white badge-gray-400 mr-1"><span class="align-text-bottom">budget owner</span></span>
							</div>
							<div class="text-truncate">
								{{ user.title }}
								<i *ngIf="user.org_obj.name && user.title" class="material-icons dot"> lens </i>
								{{ user.org_obj.name }}
							</div>
						</div>
						<div class="con-con" [ngSwitch]="user.conStatus">
							<div *ngSwitchCase="'connected'" class="d-inline-block px-3 pb-3 my-auto">
								<em class="smol">Connected</em>
							</div>
							<div *ngSwitchCase="'requested'" class="d-inline-block px-3 pb-3 my-auto">
								<em class="smol">Pending</em>
							</div>
							<div *ngSwitchCase="'pending'" class="d-inline-block px-3 pb-3 my-auto">
								<button class="btn connect-btn" (click)="connectUser(user.id)">Accept</button>
							</div>
							<div *ngSwitchCase="'ignored'" class="d-inline-block px-3 pb-3 my-auto">
								<button class="btn connect-btn" (click)="connectUser(user.id)">Accept</button>
							</div>
							<div *ngSwitchCase="'available'" class="d-inline-block px-3 pb-3 my-auto">
								<button class="btn connect-btn" (click)="connectUser(user.id)">Connect</button>
							</div>
							<div *ngSwitchCase="'unavailable'" class="d-inline-block px-3 pb-3 my-auto">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- JAW -->
