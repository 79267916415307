<div>
	<div class="heading-size-16">Suggested Topics</div>
	<ng-container *ngFor="let t of suggested_topics; index as i;">
		<div *ngIf="i < 3" class="suggested-topic-card border-bottom border-gray-200 d-flex align-items-start justify-content-between my-3 pb-2">
			<div class="d-flex align-items-start pointer" [queryParams]="{ id: t.id }" [routerLink]="['/intelligence/km/graph']">
				<div class="d-inline-flex mr-2 rounded p-2" [ngSwitch]="t.type" [ngClass]="{
					'bg-blue-pulse': (t.type == 1),
					'bg-orange-dark': (t.type == 2),
					'bg-teal-dark': (t.type == 3),
					'bg-violet-dark': (t.type == 4),
					'bg-green-dark': (t.type == 5),
					'bg-red-dark': (t.type == 6)
				}">
					<i *ngSwitchCase="1" class="material-icons text-white md-16">extension</i>
					<i *ngSwitchCase="2" class="material-icons text-white md-16">vpn_key</i>
					<i *ngSwitchCase="3" class="material-icons text-white md-16">important_devices</i>
					<i *ngSwitchCase="4" class="material-icons text-white md-16">trending_up</i>
					<i *ngSwitchCase="5" class="material-icons text-white md-16">build</i>
					<i *ngSwitchCase="6" class="material-icons text-white md-16">insert_chart</i>
				</div>
				<div class="title pr-2">
					<div class="font-weight-bold">{{t.name}}</div>
					<div *ngIf="t.users_count" class="font-size-12"><span class="font-weight-bold">Trending</span> topic with {{ t?.users_count }} new followers</div>
					<div *ngIf="t.related_count" class="font-size-12"><span class="font-weight-bold">Related</span> to {{ t?.related_count }} topics you’re following</div>
				</div>
			</div>
			<div>
				<button
					class="btn d-inline-flex align-items-center py-1 px-2 follow-btn justify-content-center"
					[ngClass]="{'btn-gray-200': !t?.following, 'btn-blue-pulse': t?.following, 'flex-fill': aviaService.mobile_mode}"
					(click)="followClicked(t)">
					<i [ngClass]="{'text-blue-pulse': !t?.following, 'text-white': t?.following}"
						class="material-icons md-16  center-btn-text mr-1">star</i>
					<span class="d-none d-sm-inline-block font-size-12"
						[ngClass]="{'text-blue-pulse': !t?.following, 'text-white': t?.following}"
					>{{ t?.following ? 'Following' : 'Follow '}}</span>
				</button>
			</div>
		</div>
	</ng-container>
	<button class="btn btn-gray-200 px-2 py-1" [routerLink]="['/suggested']"><span class="text-primary font-size-12">See more</span></button>
</div>

