<ng-template [ngIf]="static_page === false" [ngIfElse]="AviaInterestsModal"><span class="d-none"></span></ng-template>

<ng-template #AviaInterestsModal>
	<!-- TITLE BLOCK -->
	<app-avia-header-row *ngIf="!static_page" class="sticky-top w-100">
		<div class="backblock col">
			<i class="material-icons close-icon md-24 pointer" (click)="close()" id="modal-interests-close-icon">close</i>
		</div>
	</app-avia-header-row>
	<div
		class="avia-interests-modal"
		[ngClass]="{
			'avia-modal-full-screen avia-modal-full-screen-inverse': (fullscreen && static_page === false),
			'avia-modal': (!fullscreen && static_page === false)
		}">
		<!-- INTERESTS -->
		<div class="container pt-4">
			<div class="row justify-content-center">
				<div class="col-12">
					<div class="heading-size-36 text-center mt-2">Tell us your interests</div>
					<div class="text-center font-weight-light text-gray-800 font-body font-size-16 pb-4">We'll use it to keep you up to date on news and what the rest of the network is doing there as well.</div>
				</div>
					<ng-container *ngFor="let t of topics; index as i">
					<div class="col-12 col-sm-6 col-lg-4 col-xl-3 pb-5">
						<app-avia-suggested-topic-card [location]="'suggested page'" [topic]="t"></app-avia-suggested-topic-card>
					</div>
				</ng-container>
			</div>
		</div>

	</div>
</ng-template>


<!-- === ACTIVATION BUTTONS === -->
<div (click)="open()">
	<span #SuppliedAddBtn>
		<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
	</span>
</div>

<!-- Only one ng-content can be in a component at a time -->
<ng-template #contentTpl><ng-content></ng-content></ng-template>
