<ng-template #AlertModal let-modal>
	<div class="modal-header py-4 d-flex align-items-center"
		[ngClass]="{
			'bg-red-dark': type === E_AlertModalTypes.ERROR,
			'bg-orange-dark': type === E_AlertModalTypes.WARNING,
			'bg-green-dark': type === E_AlertModalTypes.SUCCESS,
			'bg-blue-dark': type === E_AlertModalTypes.GENERAL
		}">
  </div>
  <div class="modal-body">
		<div class="modal-title heading-size-24 text-center py-4" id="modal-basic-title">{{ modal_title }}</div>
		<ng-content></ng-content>
	</div>

  <div class="modal-footer justify-content-center bg-white">
		<div class="pb-4">
			<button *ngIf="show_cancel_button"
				type="button"
				class="btn btn-outline-gray-500 px-6 py-1 mr-2"
				(click)="modal.dismiss()">{{ button_cancel_text }}
			</button>
			<button
				*ngIf="show_confirm_button"
				type="button"
				class="btn px-6 py-1"
				[ngClass]="{
						'btn-red-dark': type === E_AlertModalTypes.ERROR,
						'btn-orange-dark': type === E_AlertModalTypes.WARNING,
						'btn-green-dark': type === E_AlertModalTypes.SUCCESS,
						'btn-blue-dark': type === E_AlertModalTypes.GENERAL
					}"
				(click)="confirm()">
				{{ button_confirm_text }}</button>
		</div>
  </div>
</ng-template>