import { Common } from './../../../common';
import { Router, ActivatedRoute } from '@angular/router';
import { AVIAConnectService } from './../../../avia-connect.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sso-verify',
  templateUrl: './sso-verify.component.html',
  styleUrls: ['./sso-verify.component.scss']
})
export class SsoVerifyComponent implements OnInit {

  constructor(public aviaService: AVIAConnectService, private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(p => {
      if(p.email_token) {
        this.verifyEmail(p.email_token);
      }
    });

  }

  public async verifyEmail(token) {
    const endpoint = `/sso/verify/${token}`;
    setTimeout(()=>{location.href = this.aviaService.baseUrl + endpoint;},100);
  }

  ngOnInit(){ }

}
