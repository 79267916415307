<form [formGroup]="title_form" #formElem="ngForm" (ngSubmit)="onSubmit()">
	<div class="row justify-content-center py-4">
		<div class="col-10 col-lg-6">
			<h2 class="heading-size-36 text-center mt-4">Tell us more about you</h2>
			<div class="text-center font-weight-light text-gray-800 font-body font-size-16">Thanks {{ sob.user?.firstname }}! Just a few more questions to help us get your profile ready.</div>
		</div>
	</div>
	<div class="flex-row justify-content-center">
		<div class="mx-auto col-12 col-md-8 col-lg-6 mb-4">

			<div class="form-group mb-4">
				<div class="font-weight-bold text-left pb-2">Title
					<span class="float-right">
						<small><i
							[ngClass]="{'text-danger': title_form.touched && title_form.controls['title'].touched && title_form.controls['title'].hasError('required'),
													'text-gray-600': (title_form.untouched && title_form.controls['title'].untouched) || !title_form.controls['title'].hasError('required')}"
							class="font-italic">required
						</i></small>
					</span>
				</div>
				<input #title
					type="text"
					class="w-100 form-control"
					[ngClass]="{'is-invalid': title_form.touched && title_form.controls['title'].touched && title_form.controls['title'].invalid}"
					formControlName="title">
					<span *ngIf="title_form.touched && title_form.controls['title'].touched && title_form.controls['title'].hasError('pattern')">
						<small><i class="font-italic text-danger">Requires at least 2 characters</i></small>
					</span>
			</div>

			<div class="form-group mb-4">
				<div class="font-weight-bold text-left pb-2">Department
					<span class="float-right">
						<small><i
							[ngClass]="{'text-danger': title_form.touched && title_form.controls['dept'].touched && title_form.controls['dept'].hasError('required'),
													'text-gray-600': (title_form.untouched && title_form.controls['dept'].untouched) || !title_form.controls['dept'].hasError('required')}"
							class="font-italic">required
						</i></small>
					</span>
				</div>
				<input #dept
					type="text"
					class="w-100 form-control"
					[ngClass]="{'is-invalid': title_form.touched && title_form.controls['dept'].touched && title_form.controls['dept'].invalid}"
					formControlName="dept">
					<span *ngIf="title_form.touched && title_form.controls['dept'].touched && title_form.controls['dept'].hasError('pattern')">
						<small><i class="font-italic text-danger">Requires at least 2 characters</i></small>
					</span>
				</div>

				<div class="form-group mb-4">
					<div class="font-weight-bold text-left pb-2">Organization</div>
				<input #dept type="text" class="w-100 form-control bg-light cursor-default" value='{{ sob.user?.org_obj.name }}' readonly>
			</div>
		</div>
	</div>
</form>
