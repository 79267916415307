<div>
	<ng-container *ngIf="!disabled">
		<!-- The Search Box -->
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r[selector_property]" [term]="t"></ngb-highlight>
		</ng-template>
		<div
			*ngIf="_data_list && (_data_list?.length > 0 || add_mode == true); else emptyList"
			class="search-box position-relative bg-white p-1 d-flex align-items-center"
			[ngClass]="{'disabled-class' : (allow_only_one && _selected_list.length == 1) || locked}"
			>
			<i class="material-icons text-gray-600">search</i>
			<input
				#item_search
				#instance="ngbTypeahead"
				class="search-input w-100"
				type="text"
				[(ngModel)]="model"
				[disabled]="(allow_only_one && _selected_list.length == 1) || locked ? true : false"
				[focusFirst]="add_mode"
				[inputFormatter]="formatter"
				[ngbTypeahead]="search"
				[placeholder]="placeholder"
				[resultTemplate]="rt"
				(click)="click$.next($event.target.value)"
				(clickOutside)="item_search.value = ''"
				(focus)="focus$.next($event.target.value)"
				(selectItem)="selectItem($event.item);"
			/>
		</div>
		<ng-template #emptyList>
			<div class="disabled-class search-box p-1 position-relative">
				<span class="ml-1 text-gray-600">Sorry, nothing to search.</span>
			</div>
		</ng-template>
		<!-- Validation Warnings -->
		<ng-container *ngFor="let item of validation">
			<small *ngIf="item.condition" class="form-text text-danger">{{item.message}}</small>
		</ng-container>
	</ng-container>

	<!-- The List -->
	<ng-container *ngFor="let item of _selected_list">
		<div *ngIf="item" class="pill-list">
			<div *ngIf="item[display_property]" class="pill-list-body">
				<div class="pill-list-text">{{ item[display_property] }}</div>
				<i *ngIf="!disabled" class="pill-list-close material-icons" (click)="removeItem(item);">close</i>
			</div>
		</div>
	</ng-container>
</div>
