import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  Nav_Tab,
  Nav_Tab_Pills
} from '../../../../class';
import { AVIAConnectService } from '../../../../app/avia-connect.service';


// AVIA Tab Pills
// @example start
// <app-avia-tab-pills
//  [nav_bar]="test_tab_pills"
//  [reset_query_params]="true">
//  </app-avia-tab-pills>
// @example end
@Component({
  selector: 'app-avia-tab-pills',
  templateUrl: './avia-tab-pills.component.html',
  styleUrls: ['./avia-tab-pills.component.scss', '../avia-tab.scss']
})
export class AviaTabPillsComponent implements OnInit {
  readonly DEBUG_MODE: boolean = false;

  @Input() emit_on_init:       boolean = true;
  @Input() mobile_collapse:    boolean = false;
  @Input() textarea_hack:      number  = 10;
  @Input() constant_size:      boolean = false;
  @Input() reset_query_params: boolean = true;

  private query_params:         any = {};
  private query_params_sub:     Subscription;
  private preselected_tab:      string;
  private _tab_preselect_param: string;
  @Input() // tab_preselect_param
    // NOTE: Do not set if you do not want Tab tracking via Query Params
    // NOTE: This Input must appear second to last, here (always before 'nav_bar')

    get tab_preselect_param():string { return this._tab_preselect_param; };
    set tab_preselect_param(value: string) {
      this._tab_preselect_param = value;
      if (this.query_params_sub === undefined && this._tab_preselect_param !== undefined) {
        this.query_params_sub = this.route.queryParams.subscribe((query_params) => {
          this.DEBUG_MODE && console.log('query_params_sub - _nav_bar = ', this._nav_bar, ', query_params.' + this._tab_preselect_param, query_params[this._tab_preselect_param]);
          this.query_params = query_params !== undefined ? cloneDeep(query_params) : {};
          if ( this.preselected_tab !== query_params[this._tab_preselect_param] ) {
            this.preselected_tab = query_params[this._tab_preselect_param];
            if ( this._nav_bar !== undefined && this.emit_on_init) {
              this._nav_bar.switchTabByName(this.preselected_tab);
            }
          }
        });
      }

      this.DEBUG_MODE && console.log('QUERY_PARAMS_SUB (after) - ', this.query_params_sub);
    }

  _nav_bar: Nav_Tab_Pills;
  @Input() // nav_bar
    // NOTE: This Input must appear last, here, so that all other initialization can happen before displaying the tabs
    get nav_bar() { return this._nav_bar; }
    set nav_bar(data: Nav_Tab_Pills) {
      if (data === undefined) return;
      this._nav_bar = data;

      // Select the tab passed in by the query param, if set. If not, select the first available, viewable tab
      let tab = (this.preselected_tab !== undefined)
        ? this._nav_bar.switchTabByName(this.preselected_tab)
        : this._nav_bar.selectActiveTab();
      if(this.emit_on_init) this.tab_selected.emit(tab);
    }

  @Output('tab_selected') tab_selected = new EventEmitter();

  show_dropdown:            boolean = false;
  is_mobile:                boolean = false;


  constructor(private aviaService: AVIAConnectService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.is_mobile = this.aviaService.mobile_mode;
  }

  ngOnDestroy() {
    if (this.query_params_sub) {
      this.query_params_sub.unsubscribe();
    }
    this._tab_preselect_param = undefined;
    this.preselected_tab = undefined;
  };

  @HostListener('window:resize', ['$event']) onResize($event) {
    this.is_mobile = this.aviaService.mobile_mode;
  }

  selectTab(tab:Nav_Tab) {
    setTimeout(() => {
      if (tab !== undefined){
        let new_tab = this._nav_bar.switchTabByName(tab.name);
        if (new_tab !== undefined) {
          this.tab_selected.emit(new_tab);
          if (this._tab_preselect_param !== undefined) {
            if(this.reset_query_params) {
              this.query_params = {};
            }
            this.query_params[this._tab_preselect_param] = new_tab.name;
            this.router.navigate([], { queryParams: this.query_params, relativeTo: this.route });
            return; // TEMP - Read 'Deprecated stuff below. This will not be needed once that work is done.
          }
        }
      }
    }, this.textarea_hack);
    /* NOTE:
    - The input 'textarea_hack is defaulted to 10 because we need a tick per avia-textarea on a page tied to edit and tab select.
    - If you have more than 9, avia-textareas on a page, set 'textarea_hack' in your component to 9 plus however many more you have.
    - This only applies if you have tab components with avia-textarea and universal editing.
    - Questions? See Jaron, Susie or Sippy   - TEXT AREA!!!!
    */
    this.show_dropdown = false;
  }

  openDropdown() {
    this.show_dropdown = !this.show_dropdown;
  }

}
