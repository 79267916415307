import { UniversalAddComponent } from './../shell/universal-add/universal-add.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// angular imports
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

// node_modules imports
import { Autosize } from 'ng-autosize/src/autosize.directive';
import { ClickOutsideModule } from 'ng-click-outside';
import { D3Service } from 'd3-ng2-service'; // for complete list of d3 imports: node_modules/d3-ng2-service/index.d.ts
//import { FullCalendarModule } from '@fullcalendar/angular';
import { ImageCropperModule } from 'ngx-img-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { QuillModule } from 'ngx-quill'
import { JoyrideModule } from 'ngx-joyride';

// AVIA IMPORTS:

// pipes
import {
  ExcludeFeatures,
  FormatBigMoney,
  FormatFileSize,
  FormatFiscalPeriod,
  FormatSearchTerm,
  FormatUsPhoneNumber,
  OpsExcludeEnabled,
  PrepareWebAddress,
  PrettyDomain,
  SafeProp,
  Smarttime,
  Truncate,
  ValuesPipe
} from '../_pipes/formatting-pipes.pipe';

// A
import { AddActivityModalComponent } from './../activities/add-activity-modal/add-activity-modal.component';
import { AddContentLogoModalComponent } from '../content-library/add-content-logo-modal/add-content-logo-modal.component';
import { AddContentModalComponent } from '../content-library/add-content-modal/add-content-modal.component';
import { AddEditCmComponent } from '../communities/components/add-edit-cm/add-edit-cm.component';
import { AddEditEventComponent } from '../_components/cal/components/add-edit-event.component';
import { AddEditPriorityModalComponent } from '../priorities/components/add-edit-priority-modal/add-edit-priority-modal.component';
import { AddEditProductModalComponent }    from '../products/add-edit-product-modal/add-edit-product-modal.component';
import { AddEditWsModalComponent } from './../activities/workspace/components/add-edit-ws-modal/add-edit-ws.modal.component';
import { AddTopicModalComponent } from './../knowledge-map/add-topic-modal/add-topic-modal.component';
import { AtCommonComponent } from './../_components/avia-action-tiles/tiles/at-common/at-common.component';
import { AviaAgendaEmailModalComponent } from './../_components/avia-agenda-email-modal/avia-agenda-email-modal.component';
import { AviaAgendaRowComponent } from '../_components/avia-data-rows/avia-agenda-row/avia-agenda-row.component';
import { AttributeComponent } from '../_components/attribute/attribute.component';
import { AutoResizeDirective } from '../_directives/auto-resize.directive';

// AviaA
import { AviaActionTilesComponent } from '../_components/avia-action-tiles/avia-action-tiles.component';
import { AviaAddOrgModalComponent } from '../_components/avia-add-org-modal/avia-add-org-modal.component';
import { AviaActSideRowComponent } from '../_components/avia-data-side-rows/avia-act-side-row/avia-act-side-row.component';
import { AviaAlertModalComponent } from '../_components/avia-alert-modal/avia-alert-modal.component';

// AviaB
import { AviaBadgeUserComponent } from '../_components/avia-badge-user/avia-badge-user.component';
import { AviaBadgeContainerComponent } from '../_components/avia-badge-container/avia-badge-container.component';
import { AviaBadgesCardComponent } from '../_components/avia-badges-card/avia-badges-card.component';
import { AviaBadgesCardModalComponent } from '../_components/avia-badges-card-modal/avia-badges-card-modal.component';
import { AviaBannerComponent } from '../_components/avia-banners/avia-banners.component';
import { AviaBooleanFilterComponent } from '../_components/avia-filters/avia-boolean-filter/avia-boolean-filter.component';

// AviaC
import { AviaCapabilitiesRowComponent } from '../_components/avia-data-rows/avia-capabilities-row/avia-capabilities-row.component';
import { AviaCategoryFileRowGrouperComponent } from '../_components/avia-files/groupers/avia-category-file-row-grouper/avia-category-file-row-grouper.component';
import { AviaChannelRowComponent } from '../_components/avia-data-rows/avia-channel-row/avia-channel-row.component';
import { AviaChFileRowGrouperComponent } from '../_components/avia-files/groupers/avia-ch-file-row-grouper/avia-ch-file-row-grouper.component';
import { AviaChatComponent } from '../_components/avia-chat/avia-chat.component';
import { AviaChatItemComponent } from '../_components/avia-chat/avia-chat-item/avia-chat-item.component';
import { AviaCheckboxComponent } from '../_components/avia-checkbox/avia-checkbox.component';
import { AviaCheckboxFilterComponent } from './../_components/avia-filters/avia-checkbox-filter/avia-checkbox-filter.component';
import { AviaCheckbuttonComponent } from '../_components/avia-checkbutton/avia-checkbutton.component';
import { AviaCheckbuttonCircleComponent } from './../_components/avia-checkbutton-circle/avia-checkbutton-circle.component';
import { AviaCollapseBoxComponent } from './../_components/avia-collapse-box/avia-collapse-box.component';
import { AviaCommentComponent } from '../_components/avia-comments/avia-comment/avia-comment.component';
import { AviaCommentsComponent } from '../_components/avia-comments/avia-comments.component';
import { AviaCompanyCardsComponent } from '../_components/avia-data-cards/avia-company-cards/avia-company-cards.component';
import { AviaCompetenciesRowComponent } from '../_components/avia-data-rows/avia-competencies-row/avia-competencies-row.component';
import { AviaConnectionCardComponent } from '../_components/avia-data-cards/avia-connection-card/avia-connection-card.component';
import { AviaConnectionsModalComponent } from '../_components/avia-suggested/avia-connections-modal/avia-connections-modal.component';
import { AviaContentCardsComponent } from '../_components/avia-data-cards/avia-content-cards/avia-content-cards.component';
import { AviaContentCardComponent } from '../_components/avia-data-cards/avia-content-cards/avia-content-card/avia-content-card.component';
import { AviaContentSideRowComponent } from '../_components/avia-data-side-rows/avia-content-side-row/avia-content-side-row.component';
import { AviaContentSearchComponent } from '../_components/avia-data-search-bars/avia-content-search/avia-content-search.component';
import { AviaCrisscrossComponent } from '../_components/avia-crisscross/avia-crisscross.component';

// AviaD
import { AviaDataRowGrouperComponent } from '../_components/avia-data-row-grouper/avia-data-row-grouper.component';
import { AviaDotSelectorComponent } from '../_components/avia-dot-selector/avia-dot-selector.component';
import { AviaD3BarGraphComponent } from '../_components/avia-d3-bar-graph/avia-d3-bar-graph.component';
import { AviaD3DonutChartComponent } from '../_components/avia-d3-donut-chart/avia-d3-donut-chart.component';
import { AviaD3HorizontalBarComponent } from '../_components/avia-d3-horizontal-bar/avia-d3-horizontal-bar.component';
import { AviaD3ProgressBarComponent } from '../_components/avia-d3-progress-bar/avia-d3-progress-bar.component';
import { AviaDropDownSelectorComponent } from '../_components/avia-drop-down-selector/avia-drop-down-selector.component';

//AviaE
import { AviaEditToggleComponent } from '../_components/avia-edit-toggle/avia-edit-toggle.component';
import { AviaEditModalComponent } from '../_components/avia-edit-modal/avia-edit-modal.component';
import { AviaEmailSettingsComponent } from './../_components/avia-email-settings/avia-email-settings.component';
import { AviaEmailToggleRowComponent } from '../_components/avia-email-settings/components/avia-email-toggle-row/avia-email-toggle-row.component';

// AviaF
import { AviaFiledragComponent } from '../_components/avia-filedrag/avia-filedrag.component';
import { AviaFileFolderComponent } from '../_components/avia-files/components/avia-file-folder/avia-file-folder.component'
import { AviaFileRowGrouperComponent } from '../_components/avia-files/groupers/avia-file-row-grouper/avia-file-row-grouper.component';
import { AviaFileRowComponent } from '../_components/avia-files/components/avia-file-row/avia-file-row.component';
import { AviaFilesComponent } from '../_components/avia-files/avia-files.component';
import { AviaFileTileComponent } from '../_components/avia-files/components/avia-file-tile/avia-file-tile.component';
import { AviaFileUploadComponent } from '../_components/avia-files/components/avia-file-upload/avia-file-upload.component';
import { AviaFilterButtonComponent } from '../_components/avia-filter-button/avia-filter-button.component';
import { AviaFollowingComponent } from '../_components/avia-following/avia-following.component';
import { AviaFormCheckboxComponent } from '../_components/_forms/_components/avia-form-checkbox/avia-form-checkbox.component';
import { AviaFormDropdownComponent } from '../_components/_forms/_components/avia-form-dropdown/avia-form-dropdown.component';
import { AviaFormImageComponent } from '../_components/_forms/_components/avia-form-image/avia-form-image.component';
import { AviaFormSearchComponent } from '../_components/_forms/_components/avia-form-search/avia-form-search.component';
import { AviaFormSearchContentComponent } from '../_components/_forms/_components/avia-form-search-content/avia-form-search-content.component';
import { AviaFormSearchOrgsComponent } from '../_components/_forms/_components/avia-form-search-orgs/avia-form-search-orgs.component';
import { AviaFormSearchProductsComponent } from '../_components/_forms/_components/avia-form-search-products/avia-form-search-products.component';
import { AviaFormSearchTopicsComponent } from '../_components/_forms/_components/avia-form-search-topics/avia-form-search-topics.component';
import { AviaFormSearchUsersComponent } from '../_components/_forms/_components/avia-form-search-users/avia-form-search-users.component';
import { AviaFormSearchWorkspacesComponent } from '../_components/_forms/_components/avia-form-search-workspaces/avia-form-search-workspaces.component';
import { AviaFormTextareaComponent } from '../_components/_forms/_components/avia-form-textarea/avia-form-textarea.component';
import { AviaFormTextEditModalComponent } from './../_components/_forms/avia-form-text-edit-modal/avia-form-text-edit-modal.component';

// AviaG

// AviaH
import { AviaHeaderRowComponent } from '../_components/avia-header-row/avia-header-row.component';
import { AviaHsCardComponent } from '../_components/avia-data-cards/avia-hs-card/avia-hs-card.component';
import { AviaHsRowComponent } from '../_components/avia-data-rows/avia-hs-row/avia-hs-row.component';
import { HealthSystemComponent } from './../health-system/health-system.component';

// AviaI
import { AviaInvItemRowComponent } from '../_components/avia-data-rows/avia-inv-item-row/avia-inv-item-row.component';
import { AviaInvItemNetworkRowComponent } from '../_components/avia-data-rows/avia-inv-item-network-row/avia-inv-item-network-row.component';
import { AviaImageCropComponent } from '../_components/avia-image-crop/avia-image-crop.component';
import { AviaImageCropModalComponent } from '../_components/avia-image-crop-modal/avia-image-crop-modal.component';
import { AviaInputComponent } from '../_components/avia-input/avia-input.component';
import { AviaInterestsModalComponent } from '../_components/avia-interests-modal/avia-interests-modal.component';
import { AviaInvNetworkViewComponent } from '../_components/avia-network-views/avia-inv-network-view/avia-inv-network-view.component';
import { AviaInvSearchComponent } from '../_components/avia-data-search-bars/avia-inv-search/avia-inv-search.component';

// AviaK
import { AviaKeywordsComponent } from '../_components/avia-keywords/avia-keywords.component';

// AviaL
import { AviaListComponent } from '../_components/avia-list/avia-list.component';
import { AviaLoaderComponent } from '../_components/avia-loader/avia-loader.component';

// AviaM
import { AviaMasonryComponent } from '../_components/avia-masonry/avia-masonry.component';
import { AviaMemberWarningComponent } from '../_components/avia-member-warning/avia-member-warning.component';
import { AviaMessagesBubbleComponent } from '../_components/avia-messages-bubble/avia-messages-bubble.component';
import { AviaMetricsRowComponent } from '../_components/avia-data-rows/avia-metrics-row/avia-metrics-row.component';
import { AviaMetricFilterComponent } from '../_components/avia-filters/avia-metric-filter/avia-metric-filter.component';
import { AviaModalFullscreen } from '../_components/avia-modal-fullscreen/avia-modal-fullscreen.component';
import { AviaModuleListComponent } from '../_components/avia-module-list/avia-module-list.component';
import { AviaModuleRowComponent } from '../_components/avia-module-list/avia-module-row/avia-module-row.component';
import { AviaMultiSelectorComponent } from '../_components/avia-multi-selector/avia-multi-selector.component';

// AviaN
import { AviaNetworkModalComponent } from '../_components/avia-network-modal/avia-network-modal.component';
import { AviaNoResultsComponent } from '../_components/avia-no-results/avia-no-results.component';
import { AviaNotesComponent } from '../_components/avia-notes/avia-notes.component';
import { AviaNotificationsComponent } from '../_components/avia-notifications/avia-notifications.component';

// AviaO
import { AviaOnboardingImageCropComponent } from '../_components/avia-onboarding-image-crop/avia-onboarding-image-crop.component';
import { AviaOrgFilterComponent } from '../_components/avia-filters/avia-org-filter/avia-org-filter.component';
import { AviaOrgSideRowComponent } from '../_components/avia-data-side-rows/avia-org-side-row/avia-org-side-row.component';
import { AviaOrgsSearchComponent } from '../_components/avia-data-search-bars/avia-orgs-search/avia-orgs-search.component';
import { AviaOverviewItemComponent } from '../_components/avia-overview-item/avia-overview-item.component';

// AviaP
import { AviaPageCardsComponent } from '../_components/avia-data-cards/avia-page-cards/avia-page-cards.component';
import { AviaPagerComponent } from '../_components/avia-pager/avia-pager.component';
import { AviaPasswordChangeComponent } from '../_components/avia-password-change/avia-password-change.component';
import { AviaPdfViewerComponent } from '../_components/avia-pdf-viewer/avia-pdf-viewer.component';
import { AviaPillFilterComponent } from '../_components/avia-filters/avia-pill-filter/avia-pill-filter.component';
import { AviaPriNetworkViewComponent } from '../_components/avia-network-views/avia-pri-network-view/avia-pri-network-view.component';
import { AviaPriorityNetworkRowComponent } from '../_components/avia-data-rows/avia-priority-network-row/avia-priority-network-row.component';
import { AviaPriorityRowComponent } from '../_components/avia-data-rows/avia-priority-row/avia-priority-row.component';
import { AviaPrioritiesSearchComponent } from '../_components/avia-data-search-bars/avia-priorities-search/avia-priorities-search.component';
import { AviaProductCardsComponent } from '../_components/avia-data-cards/avia-product-cards/avia-product-cards.component';
import { AviaProductRowComponent } from '../_components/avia-data-rows/avia-product-row/avia-product-row.component';
import { AviaProductsSearchComponent } from '../_components/avia-data-search-bars/avia-products-search/avia-products-search.component';
import { AviaProductSideRowComponent } from '../_components/avia-data-side-rows/avia-product-side-row/avia-product-side-row.component';
import { AviaPulseOwnerListComponent } from '../_components/avia-pulse-owner-list/avia-pulse-owner-list.component';
import { AviaPulseOwnerRowComponent } from '../_components/avia-pulse-owner-list/avia-pulse-owner-row/avia-pulse-owner-row.component';
import { AviaPulseUserRowComponent } from '../_components/avia-data-rows/avia-pulse-user-row/avia-pulse-user-row.component';

// AviaQ
import { AviaQuickAddCrunchbaseComponent } from '../_components/avia-quick-add/avia-quick-add-crunchbase/avia-quick-add-crunchbase.component';
import { AviaQuickAddInventoryComponent } from '../_components/avia-quick-add/avia-quick-add-inventory/avia-quick-add-inventory.component';
import { AviaQuickAddNewInventoryComponent } from '../_components/avia-quick-add/avia-quick-add-new-inventory/avia-quick-add-new-inventory.component';
import { AviaQuickAddUserComponent } from '../_components/avia-quick-add/avia-quick-add-user/avia-quick-add-user.component';
import { AviaQuickCapabilityComponent } from '../_components/avia-quick-views/avia-quick-capability/avia-quick-capability.component';
import { AviaQuickCompanyComponent } from '../_components/avia-quick-views/avia-quick-company/avia-quick-company.component';
import { AviaQuickHsComponent } from '../_components/avia-quick-views/avia-quick-hs/avia-quick-hs.component';
import { AviaQuickEditInventoryComponent } from '../_components/avia-quick-edit/avia-quick-edit-inventory/avia-quick-edit-inventory.component';
import { AviaQuicklinksComponent } from '../_components/avia-quicklinks/avia-quicklinks.component';
import { AviaQuickMetricComponent } from '../_components/avia-quick-views/avia-quick-metric/avia-quick-metric.component';
import { AviaQuickModalComponent } from '../_components/avia-quick-modal/avia-quick-modal.component';
import { AviaQuickProductComponent } from '../_components/avia-quick-views/avia-quick-product/avia-quick-product.component';
import { AviaQuickUserComponent } from '../_components/avia-quick-views/avia-quick-user/avia-quick-user.component';
import { AviaQuickViewWrapperComponent } from '../_components/avia-quick-views/avia-quick-view-wrapper/avia-quick-view-wrapper.component';
import { AviaQuickWorkspaceComponent } from '../_components/avia-quick-views/avia-quick-workspace/avia-quick-workspace.component';

// AviaR
import { AviaRadiobuttonComponent } from '../_components/avia-radiobutton/avia-radiobutton.component';
import { AviaRadioFilterComponent } from '../_components/avia-filters/avia-radio-filter/avia-radio-filter.component';
import { AviaReportsTableComponent } from '../_components/avia-reports-table/avia-reports-table.component';
import { AviaReportsTableFancyComponent } from '../_components/avia-reports-table-fancy/avia-reports-table-fancy.component';

// AviaS
import { AviaScaleComponent } from '../_components/avia-scale/avia-scale.component';
import { AviaOrgCardsComponent } from '../_components/avia-data-cards/avia-org-cards/avia-org-cards.component';
import { AviaSearchComponent } from '../_components/avia-search/avia-search.component';
import { AviaSelectorComponent } from '../_components/avia-selector/avia-selector.component';
import { AviaSiteMapComponent } from '../_components/avia-site-map/avia-site-map.component';
import { AviaSocialMediaComponent } from '../_components/avia-social-media/avia-social-media.component';
import { AviaSolutionsCardsComponent } from '../_components/avia-data-cards/avia-solutions-cards/avia-solutions-cards.component';
import { AviaSolutionsRowComponent } from '../_components/avia-data-rows/avia-solutions-row/avia-solutions-row.component';
import { AviaSearchFilterComponent } from '../_components/avia-filters/avia-search-filter/avia-search-filter.component';
import { AviaStaticFilterComponent } from '../_components/avia-filters/avia-static-filter/avia-static-filter.component';
import { AviaSuccessAnimationComponent } from '../_components/avia-success-animation/avia-success-animation.component';
import { AviaSuggestedConnectionCardComponent } from './../_components/avia-suggested/avia-suggested-connection-card/avia-suggested-connection-card.component';
import { AviaSuggestedTopicCardComponent } from '../_components/avia-suggested/avia-suggested-topic-card/avia-suggested-topic-card.component';

// AviaT
import { AviaTabBarComponent } from '../_components/avia-tabs/avia-tab-bar/avia-tab-bar.component';
import { AviaTabPillsComponent } from '../_components/avia-tabs/avia-tab-pills/avia-tab-pills.component';
import { AviaTabVerticalComponent } from '../_components/avia-tabs/avia-tab-vertical/avia-tab-vertical.component';
import { AviaTechnologiesRowComponent } from '../_components/avia-data-rows/avia-technologies-row/avia-technologies-row.component';
import { AviaTextareaComponent } from '../_components/avia-textarea/avia-textarea.component';
import { AviaToggleComponent } from '../_components/_forms/_components/avia-toggle/avia-toggle.component';
import { AviaTogglebuttonComponent } from '../_components/avia-togglebutton/avia-togglebutton.component';
import { AviaTopicCardsComponent } from '../_components/avia-data-cards/avia-topic-cards/avia-topic-cards.component';
import { AviaTopicsSearchComponent } from '../_components/avia-data-search-bars/avia-topics-search/avia-topics-search.component';
import { AviaTopicsSideRowComponent } from '../_components/avia-data-side-rows/avia-topics-side-row/avia-topics-side-row.component';
import { AviaTrackerRowComponent } from '../_components/avia-data-rows/avia-tracker-row/avia-tracker-row.component';
import { AviaTrendsRowComponent } from '../_components/avia-data-rows/avia-trends-row/avia-trends-row.component';

// AviaU
import { AviaUserCardsComponent } from '../_components/avia-data-cards/avia-user-cards/avia-user-cards.component';
import { AviaUserRowComponent } from '../_components/avia-data-rows/avia-user-row/avia-user-row.component';
import { AviaUserInviteModalComponent } from '../_components/avia-user-invite-modal/avia-user-invite-modal.component';
import { AviaUserInviteModalRowComponent } from '../_components/avia-user-invite-modal/components/avia-user-invite-modal-row.component';
import { AviaUserSideRowComponent } from '../_components/avia-data-side-rows/avia-user-side-row/avia-user-side-row.component';
import { AviaUsersSearchComponent } from '../_components/avia-data-search-bars/avia-users-search/avia-users-search.component';
// AviaV

// AviaW
import { AviaWordcloudComponent } from '../_components/avia-wordcloud/avia-wordcloud.component';
import { AviaWorkspaceCardsComponent } from '../_components/avia-data-cards/avia-workspace-cards/avia-workspace-cards.component';
import { AviaWorkspaceRowComponent } from '../_components/avia-data-rows/avia-workspace-row/avia-workspace-row.component';
import { AviaWorkspacesSearchComponent } from '../_components/avia-data-search-bars/avia-workspaces-search/avia-workspaces-search.component';
import { AviaWsFileRowGrouperComponent } from '../_components/avia-files/groupers/avia-ws-file-row-grouper/avia-ws-file-row-grouper.component';

// B-Z...
import { CalComponent } from '../_components/cal/cal.component';
import { BadgeModalComponent } from '../_components/avia-badge-container/badge-modal/badge-modal.component';
import { CardListRowComponent } from '../_components/avia-list/card-list-row/card-list-row.component';
import { ClReportTabGraphComponent } from '../content-library/cl-reports/components/report-tab-graph/report-tab-graph.component';
import { ClReportTabTopicsComponent } from '../content-library/cl-reports/components/report-tab-topics/report-tab-topics.component';
import { ClReportTabTotalsComponent } from '../content-library/cl-reports/components/report-tab-totals/report-tab-totals.component';
import { ClReportTabUsageComponent } from '../content-library/cl-reports/components/report-tab-usage/report-tab-usage.component';
import { CommentComponent } from '../feed/components/comment/comment.component';
import { CommentItemComponent } from '../feed/components/comment-item/comment-item.component';
import { CommentReactComponent } from '../feed/components/comment-react/comment-react.component';
import { ReactionsComponent } from '../feed/components/reactions/reactions.component';
import { CommentReactionsComponent } from '../feed/components/comment-reactions/comment-reactions.component';
import { ConnectModalComponent } from './../users/connect-modal/connect-modal.component';
import { ContentEditModalComponent } from '../_components/avia-data-cards/avia-content-cards/avia-content-card/components/content-edit-modal/content-edit-modal.component';
import { ContentShareModalComponent } from './../_components/avia-data-cards/avia-content-cards/avia-content-card/components/content-share-modal/content-share-modal.component';
import { D3AxisDirective } from '../_components/avia-d3/d3-axis.directive'
import { D3BarChartComponent } from '../_components/avia-d3/d3-bar-chart.component'
import { D3LineGraphComponent } from '../_components/avia-d3/d3-line-graph.component'
import { DebounceClick } from '../_directives/debounce-click.directive';
import { DiscussionListComponent }   from '../messages/components/discussion-list/discussion-list.component';
import { DiscussionComponent }   from '../messages/components/discussion/discussion.component';
import { DiscussionCreateModalComponent } from '../messages/components/discussion-create-modal/discussion-create-modal.component';
import { DiscussionParticipantsComponent } from '../messages/components/discussion-participants/discussion-participants.component';
import { DynamicFormComponent } from '../_components/avia-multi-selector/_components/dynamic-form/dynamic-form.component';
import { DynamicFormQuestionComponent } from '../_components/avia-multi-selector/_components/dynamic-form/dynamic-form-question.component'
import { FeedComponent } from '../feed/feed.component';
import { FileService } from '../_components/avia-files/file.service';
import { FollowingTabComponent } from '../_components/avia-following/components/following-tab/following-tab.component';
import { ImportOrgsFromSfComponent } from '../admin-page/orgs/import-orgs-from-sf/import-orgs-from-sf.component';
import { ImportUsersFromSfComponent } from '../admin-page/users/import-users-from-sf/import-users-from-sf.component';
import { LoaderComponent } from '../shell/loader/loader.component';
import { MediumEditorDirective } from '../_components/medium-editor/medium-editor.directive';
import { MessagesComponent }          from '../messages/messages.component';
import { NotifyCommentComponent } from '../_components/avia-notifications/notify-comment/notify-comment.component';
import { NotifyNpConnectComponent } from '../_components/avia-notifications/notify-np-connect/notify-np-connect.component';
import { NotifyFeedComponent } from '../_components/avia-notifications/notify-feed/notify-feed.component';
import { OnboardingConnectionsComponent } from './../shell/onboarding/components/onboarding-connections/onboarding-connections.component';
import { OnboardingCompleteComponent } from './../shell/onboarding/components/onboarding-complete/onboarding-complete.component';
import { OnboardingWelcomeComponent} from './../shell/onboarding/components/onboarding-welcome/onboarding-welcome.component'
import { OnboardingNameFormComponent} from './../shell/onboarding/components/onboarding-name-form/onboarding-name-form.component';
import { OnboardingProfilePictureFormComponent} from './../shell/onboarding/components/onboarding-profile-picture-form/onboarding-profile-picture-form.component';
import { OnboardingSolcoCongratulationsComponent} from './../shell/onboarding/components/solco/onboarding-solco-congratulations/onboarding-solco-congratulations.component';
import { OnboardingSolcoDiscoverComponent} from './../shell/onboarding/components/solco/onboarding-solco-discover/onboarding-solco-discover.component';
import { OnboardingSolcoKeysComponent} from './../shell/onboarding/components/solco/onboarding-solco-keys/onboarding-solco-keys.component';
import { OnboardingSolcoProductsComponent} from './../shell/onboarding/components/solco/onboarding-solco-products/onboarding-solco-products.component';
import { OnboardingSolcoProfileComponent} from './../shell/onboarding/components/solco/onboarding-solco-profile/onboarding-solco-profile.component';
import { OnboardingTitleFormComponent} from './../shell/onboarding/components/onboarding-title-form/onboarding-title-form.component';
import { OnboardingUserInterestsComponent } from './../shell/onboarding/components/onboarding-user-interests/onboarding-user-interests.component';
import { OrgListRowComponent } from '../_components/avia-list/org-list-row/org-list-row.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { PostComponent } from '../feed/post/post.component';
import { PostmakerComponent } from '../feed/postmaker/postmaker.component';
import { PostMediaComponent } from '../feed/components/post-media/post-media.component';
import { PostReactComponent } from '../feed/components/post-react/post-react.component';
import { PostReactionsComponent } from '../feed/components/post-reactions/post-reactions.component';
import { PostRecommendationsComponent } from '../feed/components/post-recommendations/post-recommendations.component';
import { PostTextAreaComponent } from '../feed/components/post-text-area/post-textarea.component';
import { PostTextAreaLinkModalComponent } from '../feed/components/post-text-area-link-modal/post-textarea-link-modal.component';
import { ReplyComponent } from '../feed/components/reply/reply.component';
import { ReactComponent } from '../feed/components/react/react.component';
import { ScAddEditImportModal } from '../solution-co/components/sc-add-edit-import-modal/sc-add-edit-import-modal.component';
import { SearchBarComponent } from '../_components/search-bar/search-bar.component';
import { SearchService } from './../_services/search.service';
import { SuggestedConnectionsComponent } from '../_components/avia-suggested/suggested-connections/suggested-connections.component';
import { SuggestedTopicsComponent } from '../_components/avia-suggested/suggested-topics/suggested-topics.component';
import { AviaSendMessageButtonComponent } from '../messages/components/send-message-button/send-message-button.component';
import { SubNavComponent } from '../shell/sub-nav/sub-nav.component';
import { TweenPipe } from '../_pipes/tween.pipe';
import { UserListRowComponent } from '../_components/avia-list/user-list-row/user-list-row.component';
import { UsersOrgsListComponent } from '../members/components/users-orgs-list/users-orgs-list.component';
import { UserTagComponent } from '../feed/components/user-tag/user-tag.component';

@NgModule({
  declarations: [
    // A
    AddActivityModalComponent,
    AddContentLogoModalComponent,
    AddContentModalComponent,
    AddEditCmComponent,
    AddEditEventComponent,
    AddEditPriorityModalComponent,
    AddEditProductModalComponent,
    AddEditWsModalComponent,
    AddTopicModalComponent,
    AtCommonComponent,
    AttributeComponent,
    AviaAgendaEmailModalComponent,
    AutoResizeDirective,
    Autosize,

    // AviaA
    AviaActionTilesComponent,
    AviaAddOrgModalComponent,
    AviaActSideRowComponent,
    AviaAgendaRowComponent,
    AviaAlertModalComponent,

    // AviaB
    AviaBadgeUserComponent,
    AviaBadgeContainerComponent,
    AviaBadgesCardComponent,
    AviaBadgesCardModalComponent,
    AviaBannerComponent,
    AviaBooleanFilterComponent,

    // AviaC
    AviaCapabilitiesRowComponent,
    AviaCategoryFileRowGrouperComponent,
    AviaChannelRowComponent,
    AviaChFileRowGrouperComponent,
    AviaChatComponent,
    AviaChatItemComponent,
    AviaCheckboxComponent,
    AviaCheckboxFilterComponent,
    AviaCheckbuttonComponent,
    AviaCheckbuttonCircleComponent,
    AviaCollapseBoxComponent,
    AviaCommentComponent,
    AviaCommentsComponent,
    AviaCompanyCardsComponent,
    AviaCompetenciesRowComponent,
    AviaConnectionCardComponent,
    AviaConnectionsModalComponent,
    AviaContentCardComponent,
    AviaContentSideRowComponent,
    AviaContentCardsComponent,
    AviaContentSearchComponent,
    AviaCrisscrossComponent,

    // AviaD
    AviaDataRowGrouperComponent,
    AviaDotSelectorComponent,
    AviaDropDownSelectorComponent,
    AviaD3BarGraphComponent,
    AviaD3DonutChartComponent,
    AviaD3ProgressBarComponent,
    AviaD3HorizontalBarComponent,

    // AviaE
    AviaEditToggleComponent,
    AviaEditModalComponent,
    AviaEmailSettingsComponent,
    AviaEmailToggleRowComponent,

    // AviaF
    AviaFilesComponent,
    AviaFiledragComponent,
    AviaFileFolderComponent,
    AviaFileRowComponent,
    AviaFileRowGrouperComponent,
    AviaFileTileComponent,
    AviaFileUploadComponent,
    AviaFilterButtonComponent,
    AviaFollowingComponent,
    AviaFormCheckboxComponent,
    AviaFormDropdownComponent,
    AviaFormImageComponent,
    AviaFormSearchComponent,
    AviaFormSearchContentComponent,
    AviaFormSearchOrgsComponent,
    AviaFormSearchProductsComponent,
    AviaFormSearchTopicsComponent,
    AviaFormSearchUsersComponent,
    AviaFormSearchWorkspacesComponent,
    AviaFormTextareaComponent,
    AviaFormTextEditModalComponent,

    // AviaG

    // AviaH
    AviaHeaderRowComponent,
    AviaHsCardComponent,
    AviaHsRowComponent,
    HealthSystemComponent,

    // AviaI
    AviaInvItemRowComponent,
    AviaInvItemNetworkRowComponent,
    AviaInvSearchComponent,
    AviaImageCropComponent,
    AviaImageCropModalComponent,
    AviaInputComponent,
    AviaInterestsModalComponent,
    AviaInvNetworkViewComponent,

    // AviaJ

    // AviaK
    AviaKeywordsComponent,

    // AviaL
    AviaListComponent,
    AviaLoaderComponent,

    // AviaM
    AviaMasonryComponent,
    AviaMemberWarningComponent,
    AviaMessagesBubbleComponent,
    AviaMetricsRowComponent,
    AviaMetricFilterComponent,
    AviaModalFullscreen,
    AviaModuleListComponent,
    AviaModuleRowComponent,
    AviaMultiSelectorComponent,

    // AviaN
    AviaNetworkModalComponent,
    AviaNoResultsComponent,
    AviaNotesComponent,
    AviaNotificationsComponent,

    // AviaO
    AviaOnboardingImageCropComponent,
    AviaOrgCardsComponent,
    AviaOrgFilterComponent,
    AviaOrgSideRowComponent,
    AviaOrgsSearchComponent,
    AviaOverviewItemComponent,

    // AviaP
    AviaPageCardsComponent,
    AviaPagerComponent,
    AviaPasswordChangeComponent,
    AviaPillFilterComponent,
    AviaPriNetworkViewComponent,
    AviaPriorityNetworkRowComponent,
    AviaPriorityRowComponent,
    AviaPrioritiesSearchComponent,
    AviaProductCardsComponent,
    AviaPdfViewerComponent,
    AviaProductRowComponent,
    AviaProductSideRowComponent,
    AviaProductsSearchComponent,
    AviaPulseOwnerListComponent,
    AviaPulseOwnerRowComponent,
    AviaPulseUserRowComponent,

    // AviaQ
    AviaQuickAddCrunchbaseComponent,
    AviaQuickAddInventoryComponent,
    AviaQuickAddNewInventoryComponent,
    AviaQuickAddUserComponent,
    AviaQuickCapabilityComponent,
    AviaQuickCompanyComponent,
    AviaQuickHsComponent,
    AviaQuickEditInventoryComponent,
    AviaQuicklinksComponent,
    AviaQuickMetricComponent,
    AviaQuickModalComponent,
    AviaQuickProductComponent,
    AviaQuickUserComponent,
    AviaQuickViewWrapperComponent,
    AviaQuickWorkspaceComponent,

    // AviaR
    AviaRadiobuttonComponent,
    AviaRadioFilterComponent,
    AviaReportsTableComponent,
    AviaReportsTableFancyComponent,

    // AviaS
    AviaScaleComponent,
    AviaSearchComponent,
    AviaSelectorComponent,
    AviaSearchFilterComponent,
    AviaSendMessageButtonComponent,
    AviaSiteMapComponent,
    AviaSocialMediaComponent,
    AviaSolutionsCardsComponent,
    AviaSolutionsRowComponent,
    AviaStaticFilterComponent,
    AviaSuccessAnimationComponent,
    AviaSuggestedConnectionCardComponent,
    AviaSuggestedTopicCardComponent,

    // AviaT
    AviaTabBarComponent,
    AviaTabPillsComponent,
    AviaTabVerticalComponent,
    AviaTechnologiesRowComponent,
    AviaTextareaComponent,
    AviaToggleComponent,
    AviaTogglebuttonComponent,
    AviaTopicCardsComponent,
    AviaTopicsSearchComponent,
    AviaTopicsSideRowComponent,
    AviaTrackerRowComponent,
    AviaTrendsRowComponent,

    // AviaU
    AviaUserCardsComponent,
    AviaUserInviteModalComponent,
    AviaUserInviteModalRowComponent,
    AviaUserRowComponent,
    AviaUserSideRowComponent,
    AviaUsersSearchComponent,

    // AviaV

    // AviaW
    AviaWordcloudComponent,
    AviaWorkspaceCardsComponent,
    AviaWorkspaceRowComponent,
    AviaWorkspacesSearchComponent,
    AviaWsFileRowGrouperComponent,

    // B-Z...
    CalComponent,
    BadgeModalComponent,
    CardListRowComponent,
    ClReportTabGraphComponent,
    ClReportTabTopicsComponent,
    ClReportTabTotalsComponent,
    ClReportTabUsageComponent,
    CommentComponent,
    CommentItemComponent,
    CommentReactComponent,
    CommentReactionsComponent,
    ConnectModalComponent,
    ContentEditModalComponent,
    ContentShareModalComponent,
    D3BarChartComponent,
    D3LineGraphComponent,
    D3AxisDirective,
    DebounceClick,
    DiscussionListComponent,
    DiscussionCreateModalComponent,
    DiscussionParticipantsComponent,
    DiscussionComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    FeedComponent,
    ExcludeFeatures,
    FollowingTabComponent,
    FormatBigMoney,
    FormatFileSize,
    FormatFiscalPeriod,
    FormatSearchTerm,
    FormatUsPhoneNumber,
    ImportOrgsFromSfComponent,
    ImportUsersFromSfComponent,
    LoaderComponent,
    MediumEditorDirective,
    MessagesComponent,
    NotifyCommentComponent,
    NotifyNpConnectComponent,
    NotifyFeedComponent,
    OnboardingConnectionsComponent,
    OnboardingCompleteComponent,
    OnboardingNameFormComponent,
    OnboardingProfilePictureFormComponent,
    OnboardingSolcoCongratulationsComponent,
    OnboardingSolcoDiscoverComponent,
    OnboardingSolcoKeysComponent,
    OnboardingSolcoProductsComponent,
    OnboardingSolcoProfileComponent,
    OnboardingTitleFormComponent,
    OnboardingUserInterestsComponent,
    OnboardingWelcomeComponent,
    OpsExcludeEnabled,
    OrgListRowComponent,
    PostComponent,
    PostmakerComponent,
    PostMediaComponent,
    PostTextAreaComponent,
    PostTextAreaLinkModalComponent,
    PostReactComponent,
    PostReactionsComponent,
    PostRecommendationsComponent,
    ReactionsComponent,
    ReplyComponent,
    ReactComponent,
    PrepareWebAddress,
    PrettyDomain,
    SafeProp,
    ScAddEditImportModal,
    SearchBarComponent,
    Smarttime,
    SubNavComponent,
    SuggestedConnectionsComponent,
    SuggestedTopicsComponent,
    Truncate,
    TweenPipe,
    UniversalAddComponent,
    UserListRowComponent,
    UsersOrgsListComponent,
    UserTagComponent,
    ValuesPipe,
  ],
  imports: [
    JoyrideModule.forRoot(),
    ClickOutsideModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    //FullCalendarModule,
    ImageCropperModule,
    InfiniteScrollModule,
    DeviceDetectorModule,
    NgbModule,
    PickerModule,
    ReactiveFormsModule,
    RouterModule,
    VirtualScrollModule,
    QuillModule.forRoot(),
  ],
  exports: [
    // A
    AddActivityModalComponent,
    AddContentLogoModalComponent,
    AddContentModalComponent,
    AddEditCmComponent,
    AddEditEventComponent,
    AddEditPriorityModalComponent,
    AddEditProductModalComponent,
    AddEditWsModalComponent,
    AddTopicModalComponent,
    AtCommonComponent,
    AttributeComponent,
    AviaAgendaEmailModalComponent,
    AutoResizeDirective,
    Autosize,

    // AviaA
    AviaActionTilesComponent,
    AviaAddOrgModalComponent,
    AviaAgendaRowComponent,
    AviaAlertModalComponent,
    AviaActSideRowComponent,

    // AviaB
    AviaBadgeUserComponent,
    AviaBadgesCardComponent,
    AviaBadgesCardModalComponent,
    AviaBadgeContainerComponent,
    AviaBannerComponent,
    AviaBooleanFilterComponent,

    // AviaC
    AviaCapabilitiesRowComponent,
    AviaCategoryFileRowGrouperComponent,
    AviaChannelRowComponent,
    AviaChFileRowGrouperComponent,
    AviaChatComponent,
    AviaChatItemComponent,
    AviaCheckboxComponent,
    AviaCheckboxFilterComponent,
    AviaCheckbuttonComponent,
    AviaCheckbuttonCircleComponent,
    AviaCollapseBoxComponent,
    AviaCommentComponent,
    AviaCommentsComponent,
    AviaCompanyCardsComponent,
    AviaCompetenciesRowComponent,
    AviaConnectionCardComponent,
    AviaConnectionsModalComponent,
    AviaContentCardComponent,
    AviaContentSideRowComponent,
    AviaContentCardsComponent,
    AviaContentSearchComponent,
    AviaCrisscrossComponent,

    // AviaD
    AviaDataRowGrouperComponent,
    AviaDotSelectorComponent,
    AviaDropDownSelectorComponent,
    AviaD3BarGraphComponent,
    AviaD3DonutChartComponent,
    AviaD3HorizontalBarComponent,
    AviaD3ProgressBarComponent,


    // AviaE
    AviaEditToggleComponent,
    AviaEditModalComponent,
    AviaEmailSettingsComponent,
    AviaEmailToggleRowComponent,

    // AviaF
    AviaFilesComponent,
    AviaFiledragComponent,
    AviaFileFolderComponent,
    AviaFileRowComponent,
    AviaFileRowGrouperComponent,
    AviaFileTileComponent,
    AviaFileUploadComponent,
    AviaFilterButtonComponent,
    AviaFollowingComponent,
    AviaFormCheckboxComponent,
    AviaFormDropdownComponent,
    AviaFormImageComponent,
    AviaFormSearchComponent,
    AviaFormSearchContentComponent,
    AviaFormSearchOrgsComponent,
    AviaFormSearchTopicsComponent,
    AviaFormSearchUsersComponent,
    AviaFormSearchWorkspacesComponent,
    AviaFormTextareaComponent,
    AviaFormTextEditModalComponent,

    // AviaH
    AviaHeaderRowComponent,
    AviaHsCardComponent,
    AviaHsRowComponent,
    HealthSystemComponent,

    // AviaI
    AviaImageCropComponent,
    AviaImageCropModalComponent,
    AviaInputComponent,
    AviaInterestsModalComponent,
    AviaInvItemRowComponent,
    AviaInvItemNetworkRowComponent,
    AviaInvNetworkViewComponent,
    AviaInvSearchComponent,

    // AviaK
    AviaKeywordsComponent,

    // AviaL
    AviaListComponent,
    AviaLoaderComponent,

    // AviaM
    AviaMasonryComponent,
    AviaMemberWarningComponent,
    AviaMessagesBubbleComponent,
    AviaMetricsRowComponent,
    AviaMetricFilterComponent,
    AviaModalFullscreen,
    AviaModuleListComponent,
    AviaModuleRowComponent,
    AviaMultiSelectorComponent,

    // AviaN
    AviaNetworkModalComponent,
    AviaNoResultsComponent,
    AviaNotesComponent,
    AviaNotificationsComponent,

    // AviaO
    AviaOnboardingImageCropComponent,
    AviaOrgCardsComponent,
    AviaOrgFilterComponent,
    AviaOrgSideRowComponent,
    AviaOrgsSearchComponent,
    AviaOverviewItemComponent,

    // AviaP
    AviaPagerComponent,
    AviaPageCardsComponent,
    AviaPasswordChangeComponent,
    AviaPdfViewerComponent,
    AviaPillFilterComponent,
    AviaPriNetworkViewComponent,
    AviaPriorityNetworkRowComponent,
    AviaPriorityRowComponent,
    AviaPrioritiesSearchComponent,
    AviaProductCardsComponent,
    AviaProductRowComponent,
    AviaProductSideRowComponent,
    AviaPulseOwnerListComponent,
    AviaPulseOwnerRowComponent,
    AviaPulseUserRowComponent,

    // AviaQ
    AviaQuickAddCrunchbaseComponent,
    AviaQuickAddInventoryComponent,
    AviaQuickAddNewInventoryComponent,
    AviaQuickAddUserComponent,
    AviaQuickCapabilityComponent,
    AviaQuickCompanyComponent,
    AviaQuickHsComponent,
    AviaQuickEditInventoryComponent,
    AviaQuicklinksComponent,
    AviaQuickMetricComponent,
    AviaQuickModalComponent,
    AviaQuickProductComponent,
    AviaQuickUserComponent,
    AviaQuickViewWrapperComponent,
    AviaQuickWorkspaceComponent,

    // AviaR
    AviaRadiobuttonComponent,
    AviaReportsTableComponent,
    AviaReportsTableFancyComponent,

    // AviaS
    AviaScaleComponent,
    AviaSearchComponent,
    AviaSearchFilterComponent,
    AviaSelectorComponent,
    AviaSendMessageButtonComponent,
    AviaSiteMapComponent,
    AviaSocialMediaComponent,
    AviaSolutionsCardsComponent,
    AviaSolutionsRowComponent,
    AviaStaticFilterComponent,
    AviaRadioFilterComponent,
    AviaSuccessAnimationComponent,
    AviaSuggestedConnectionCardComponent,
    AviaSuggestedTopicCardComponent,

    // AviaT
    AviaTabBarComponent,
    AviaTabPillsComponent,
    AviaTabVerticalComponent,
    AviaTechnologiesRowComponent,
    AviaTextareaComponent,
    AviaToggleComponent,
    AviaTogglebuttonComponent,
    AviaTopicCardsComponent,
    AviaTopicsSearchComponent,
    AviaTopicsSideRowComponent,
    AviaTrackerRowComponent,
    AviaTrendsRowComponent,

    // AviaU
    AviaUserCardsComponent,
    AviaUserInviteModalComponent,
    AviaUserInviteModalRowComponent,
    AviaUserRowComponent,
    AviaUserSideRowComponent,
    AviaUsersSearchComponent,

    // AviaW
    AviaWordcloudComponent,
    AviaWorkspaceCardsComponent,
    AviaWorkspaceRowComponent,
    AviaWorkspacesSearchComponent,
    AviaWsFileRowGrouperComponent,

    // B-Z...
    CalComponent,
    BadgeModalComponent,
    CardListRowComponent,
    ClickOutsideModule,
    ClReportTabGraphComponent,
    ClReportTabTopicsComponent,
    ClReportTabTotalsComponent,
    ClReportTabUsageComponent,
    CommentComponent,
    CommonModule,
    ConnectModalComponent,
    ContentEditModalComponent,
    ContentShareModalComponent,
    D3AxisDirective,
    D3BarChartComponent,
    D3LineGraphComponent,
    DebounceClick,
    DiscussionListComponent,
    DiscussionCreateModalComponent,
    DiscussionParticipantsComponent,
    DiscussionComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    FeedComponent,
    ExcludeFeatures,
    FollowingTabComponent,
    FormatBigMoney,
    FormatFileSize,
    FormatFiscalPeriod,
    FormatSearchTerm,
    FormatUsPhoneNumber,
    FormsModule,
    //FullCalendarModule,
    HttpClientModule,
    ImageCropperModule,
    InfiniteScrollModule,
    ImportOrgsFromSfComponent,
    ImportUsersFromSfComponent,
    LoaderComponent,
    MediumEditorDirective,
    MessagesComponent,
    NotifyCommentComponent,
    NotifyNpConnectComponent,
    NotifyFeedComponent,
    OnboardingConnectionsComponent,
    OnboardingCompleteComponent,
    OnboardingNameFormComponent,
    OnboardingProfilePictureFormComponent,
    OnboardingSolcoCongratulationsComponent,
    OnboardingSolcoDiscoverComponent,
    OnboardingSolcoKeysComponent,
    OnboardingSolcoProductsComponent,
    OnboardingSolcoProfileComponent,
    OnboardingTitleFormComponent,
    OnboardingUserInterestsComponent,
    OnboardingWelcomeComponent,
    OpsExcludeEnabled,
    OrgListRowComponent,
    PickerModule,
    PostComponent,
    PostmakerComponent,
    PostTextAreaComponent,
    PostTextAreaLinkModalComponent,
    ReplyComponent,
    ReactComponent,
    PrepareWebAddress,
    PrettyDomain,
    ReactiveFormsModule,
    RouterModule,
    SafeProp,
    ScAddEditImportModal,
    SearchBarComponent,
    Smarttime,
    SubNavComponent,
    SuggestedConnectionsComponent,
    SuggestedTopicsComponent,
    Truncate,
    TweenPipe,
    UniversalAddComponent,
    UserListRowComponent,
    UsersOrgsListComponent,
    UserTagComponent,
    ValuesPipe,
    VirtualScrollModule
  ],

  providers: [
    D3Service,
    SearchService,
    FileService
  ],

})
export class SharedModule {
}
