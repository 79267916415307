import { Common } from './../../../common';
import { AccessKey, Notification } from './../../../../class';
import { Component, OnChanges, OnInit, Output, Input, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-notify-comment',
  templateUrl: './notify-comment.component.html',
  styleUrls: ['../avia-notify-item.component.scss']
})
export class NotifyCommentComponent implements OnInit {
  @Input() akey: AccessKey = new AccessKey();
  @Input() notification: Notification;
  @Input() options: NotifyComment_Options = new NotifyComment_Options();
  @Input() show_dismiss: boolean = true;

  @Output('dismiss') dismiss = new EventEmitter();
  @Output('goTo') goTo = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.condenseKeys();
  }

  condenseKeys(): void {
    if (this.notification === undefined || this.notification === null) return;

    switch (this.notification.type_obj.name) {
      case 'KM chat':
      case 'KM card comment':
      case 'KM card':
        this.notification.content_name = Common.objSafeRead(this, ['notification', 'km_obj', 'name'], null);
        break;

      case 'CL comment':
        this.notification.content_name = Common.objSafeRead(this, ['notification', 'content_obj', 'name'], null);
        break;

      case 'Channel chat':
      case 'Group chat':
        this.notification.content_name = Common.objSafeRead(this, ['notification', 'channel_obj', 'name'], null);
        break;

      default:
        break;
    }
  }
}

export class NotifyComment_Options {
  constructor(
    public large: boolean = false
  ) { }
}
