<div class="users-orgs-list-container h-100 w-100">

	<!-- No Results -->
	<div *ngIf="show_noresults && loading === false && display_buffer.length === 0">
		<app-avia-no-results>No Results</app-avia-no-results>
	</div>

	<!-- Data -->
	<div
		class="data-container h-100"
		[ngClass]="{'d-none':show_noresults && loading === false && display_buffer.length === 0}"
		>
		<virtual-scroll id="virtualScroll" class="d-block h-100 w-100"
			[bufferAmount]="2"
			[items]="display_buffer"
			[scrollAnimationTime]="500"
			(update)="scroll_items = $event"
			(change)="onListChange($event)"
			>
			<div class="list-group-item card mb-3 p-0" [ngClass]="type" *ngFor="let item of scroll_items" >
				<div class="userOrgCard d-flex justify-content-start h-100 w-100"
					[class.disabled]="type==='crunchbase'"
					[class.inactive]="item.status_obj&&item.status_obj.name=='inactive'"
					[class.pending]="item.status_obj&&item.status_obj.name=='pending'"
					[ngClass]="type"
					[routerLink]="[item?.link]"
					>
					<!-- Avatar Image -->
					<div class="side-stripe" [ngStyle]="{'background-color': item?.color ? '#' + item?.color : default_color}"></div>
					<div class="d-inline-block d-sm-block">
						<div class="avatar">
							<img class="avatar-img img-responsive" src="{{ item.avatar }}" onerror="this.src=this.solco?'../../assets/placeholder-company.jpg':''" />
						</div>
					</div>
					<!-- Contact Info -->
					<div class="info d-inline-flex d-sm-flex align-items-center mr-auto text-truncate">
						<span class="text-truncate">
							<div class="list-group-item-text text-truncate top-text copy-font" [ngClass]="{'text-primary':type==='crunchbase'}">{{ item.name }}</div>
							<div class="list-group-item-text bottom-text d-none d-sm-block font-italic text-truncate">{{ item.description }}</div>
						</span>
					</div>
					<!-- COMPANY BADGES -->
					<div class="badges d-inline-flex d-sm-flex align-items-center">
						<ul class="p-0 m-0 list-inline">
							<!-- <li *ngIf="item?.is_avia_vetted"
								class="list-inline-item pointer m-0 p-0 pl-md-2"
								data-toggle="tooltip"
								placement="bottom"
								ngbTooltip="AVIA Vetted"
								[routerLink]="['/sc/', item?.id]"
								[queryParams]="{activeTab: 'Badges'}">
								<img class="badge-svg" src="/assets/badges/badge-avia-vetted.svg" alt="badge-avia-vetted">
							</li> -->
							<li *ngIf="item?.is_high_impact"
								class="list-inline-item pointer m-0 p-0 pl-md-2"
								data-toggle="tooltip"
								placement="bottom"
								ngbTooltip="High Impact"
								[routerLink]="['/sc/', item?.id]"
								[queryParams]="{activeTab: 'Badges'}">
								<img class="badge-svg"src="/assets/badges/badge-high-impact.svg" alt="badge-high-impact">
							</li>
							<li *ngIf="item?.is_market_val"
								class="list-inline-item pointer m-0 p-0 pl-md-2"
								data-toggle="tooltip"
								placement="bottom"
								ngbTooltip="Market Validated"
								[routerLink]="['/sc/', item?.id]"
								[queryParams]="{activeTab: 'Badges'}">
								<img class="badge-svg" src="/assets/badges/badge-market-val.svg" alt="badge-market-val">
							</li>
						</ul>
					</div>
					<!-- Connections -->
					<div class="connection_buttons d-flex align-items-center ml-4" *ngIf="show_conn_btns_for!==undefined && type!=='crunchbase'">
						<div class="btn btn-sm btn-outline-gray-600" *ngIf="show_connected_btn" (click)="disconnectFromUser(item.id); $event.stopPropagation();">Disconnect</div>
						<div class="btn btn-sm btn-outline-gray-600" *ngIf="show_cancel_btn" (click)="disconnectFromUser(item.id); $event.stopPropagation();">Cancel</div>
						<div class="d-flex" *ngIf="show_pending_btn">
							<div class="btn btn-sm btn-outline-gray-600" (click)="ignoreUser(item.id); $event.stopPropagation();">Ignore</div>
							<div class="btn btn-sm btn-accent ml-2" (click)="connectWithUser(item.id); $event.stopPropagation();">Accept</div>
						</div>
					</div>
					<!-- Crunchbase -->
					<div class="crunchbase_extras m-2 ml-sm-4" *ngIf="type==='crunchbase'">
						<div class="d-none d-sm-flex align-items-center h-100" *ngIf="item?.id=='0'">
							<div class="status text-right">{{item.name}} is not currently profiled in AVIA Connect.</div>
							<div class="btn btn-sm btn-primary ml-2" (click)="addFromCrunchbase($event, item?.crunchbase_id)" *ngIf="_options['show_cb_add']">Add Company</div>
						</div>
						<div class="d-none d-sm-flex align-items-center h-100" *ngIf="item?.id!='0'">
							<div class="status text-right">{{item.name}} is profiled in AVIA Connect.</div>
							<div class="btn btn-sm btn-accent ml-2" routerLink="/sc/{{item?.id}}" (click)="$event.stopPropagation();">Go To Profile</div>
						</div>
						<!-- mobile -->
						<div class="d-flex d-sm-none align-items-center h-100">
							<div class="btn btn-sm btn-primary" *ngIf="item?.id=='0' && _options['show_cb_add']" (click)="addFromCrunchbase($event, item?.crunchbase_id)">Add</div>
							<div class="btn btn-sm btn-accent" *ngIf="item?.id!='0'" routerLink="/sc/{{item?.id}}" (click)="$event.stopPropagation();">View</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Loading Indicator -->
			<app-avia-loader id="aviaLoader" *ngIf="loading" [options]="{type: 'generic'}" style="display:block; height:65px"></app-avia-loader>
		</virtual-scroll>
	</div>

</div>
