import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'avia-modal-fullscreen',
  templateUrl: './avia-modal-fullscreen.component.html',
  styleUrls: ['./avia-modal-fullscreen.component.scss']
})
export class AviaModalFullscreen implements OnInit {
  @Input() additionalCssClasses: string[] = [];
  isOpen: boolean = false;
  modalRef: NgbModalRef;
  @ViewChild("modal", { static: true }) modalRefTemplate: TemplateRef<any>;


  constructor( private modalService: NgbModal ) {}

  ngOnInit() {
    setTimeout( () => {
      this.open();
    }, 0 );
  }

  ngOnDestroy() {
    this.close();
  }

  open() {
    if (!this.isOpen) {
      let modal_options: NgbModalOptions = {
        backdrop: 'static',
        size: 'lg',
        windowClass: ['avia-modal-fullscreen', ...this.additionalCssClasses].join(' ')
      };

      this.modalRef = this.modalService.open(this.modalRefTemplate, modal_options);
      this.isOpen = true;
    }
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;
      this.modalRef.close();
    }
  }
}
