import { Subscription } from 'rxjs';
import { AviaActionTilesService } from './avia-action-tiles.service';
import { Color_Library, E_SomethingHappened } from './../../../class';
import { AVIAConnectService } from './../../avia-connect.service';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-avia-action-tiles',
  templateUrl: './avia-action-tiles.component.html',
  styleUrls: ['./avia-action-tiles.component.scss'],
  providers: [AviaActionTilesService]
})
export class AviaActionTilesComponent implements OnInit {
  newsfeed_subscription: Subscription;
  constructor(public aats: AviaActionTilesService) {
    this.newsfeed_subscription = this.aats.aviaService.somethingHappened.subscribe(event => {
      if(event.type === E_SomethingHappened.NEWSFEED_REFRESHED) {
        if(event['data'] && event['data']['action_tiles']) {
          this.aats.getActionTiles();
        }
      }
    })
  }

  ngOnInit() {
    this.aats.init();
  }
  ngOnDestroy() {
    this.newsfeed_subscription.unsubscribe();
  }
}
