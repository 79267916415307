import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Org, E_Modal_Operating_Mode } from './../../../../class';
import { AVIAConnectService } from './../../../avia-connect.service';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter  } from '@angular/core';
import { of, Observable, BehaviorSubject, from } from 'rxjs';

@Component({
  selector: 'app-avia-quick-add-crunchbase',
  templateUrl: './avia-quick-add-crunchbase.component.html',
  styleUrls: ['./avia-quick-add-crunchbase.component.scss']
})
export class AviaQuickAddCrunchbaseComponent implements OnInit {
  readonly E_Modal_Operating_Mode = E_Modal_Operating_Mode; // NOTE: This line is only here so that this static class can be used in the HTML template

  // Search Options
  searchbar_options:  Object = {
    dropdown_enabled:     false,
    dropdown_include_all: true,
    search_bar_white_bg:  true,
    search_on_keypress:   false,
    search_text:          'Search by web address for best results, e.g., apple.com'
  }

  // Search Vars
  no_results:         boolean              = false;
  show_results:       any; // angular8: added to fix error when building prod: "Property does not exist"
  prev_term:          string               = '';
  scroll_items:       any[]                = [];
  search_complete:    boolean              = false;
  search_results:     Observable<{} | Org[]>;
  search_subject:     BehaviorSubject<any> = new BehaviorSubject( of<Org[]>([]) );
  searching:          boolean              = true;


  @Input() term:      string               = '';
  @Output('addSolco') addSolco = new EventEmitter();

  @ViewChild('ScImportModal', { static: false }) ScImportModal: NgbModal;

  // Pagination Vars
  data_reset:         boolean              = true;
  pgn_item_start:     number               = 0;  // NOTE: This is a zero-based array index, not a one-based DB index
  pgn_item_count:     number               = 50; // NOTE: This is set so that we can fetch the number of items that the viewport shows plus 2
  search_end_reached: boolean              = false;
  count_total:        number               = 0;

  // Add Modal
  new_solco_blank: Org = new Org();

  // Edit Modal
  @ViewChild('ScAddModal', { static: false })    sc_add_modal:    any;
  @ViewChild('ScImportModal', { static: false }) sc_import_modal: any;

  constructor( public aviaService: AVIAConnectService ) {};

  async addCompany(company) {
    if(company.id == 0){
      let result = await this.submitAddFromCrunchbase(company);
      this.addSolco.emit(result)
    } else {
      this.addSolco.emit(company);
    }


  }

  onScroll(event){
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.search_end_reached) {
      this.updateSearch({offset: 50})
    }
  }

  initSearch() {
    this.count_total = 0;
    this.searching = false;
    this.search_results = this.search_subject
    .asObservable()
    .pipe( switchMap( data => this.searchCompanies( data ) ) )
    .pipe( map( data => {
      this.searching = false;
      this.search_complete = true;
      this.no_results = data.length == 0 ? true : false;
      this.scroll_items = this.scroll_items.concat(data);
      return data;
    }))
    .pipe( catchError( error => {
      console.error('cb-search error: %s', error);
      this.no_results = true;
      return of<Org[]>([]);
    }) );
  };

  ngOnInit() {
    this.initSearch();
  };




  ////////////////////////////////////////////////////////////////////////////////
  // READ

  //Search
  searchCompanies( term, pgn_item_start:number = this.pgn_item_start, pgn_item_count:number = this.pgn_item_count ): Observable<Org[]> {
    if( term === undefined || term.length === 0 ) return of<Org[]>([]);

    this.search_end_reached == false;
    let searchObj = { 'filter': term, 'start':  pgn_item_start, 'count':  pgn_item_count };

    return from( this.aviaService.getCrunchbase( searchObj ).then( response => {
      let data = response.body['rows'];
      this.count_total = response.body['count_total'] !== undefined ? response.body['count_total'] : 0;
      this.search_end_reached = response.body['end_reached'] !== undefined ? response.body['end_reached'] : true;
      return data.map( company => {
        let avia_id = company.avia_org_obj[0] !== undefined ? company.avia_org_obj[0].id : 0;
        this.searching = false;
        return { 'name':company.company_name, 'domain':company.domain, 'website':company.website, 'logo':company.company_logo, 'crunchbase_id':company.uuid, 'id':avia_id };
      });
    }));
  };

  updateSearch( $event  = undefined ): void {
    if( $event && $event.offset !== undefined ) {
      if( this.search_complete === false || this.search_end_reached === true ) return;
      this.searching = true;
      this.data_reset = false;
      this.pgn_item_start += $event.offset;
    }
    this.search_complete = false;
    this.search_subject.next( this.term );
  }

  // Change term after input initializes
  ngOnChanges(){
    this.termSubmitted();
    this.updateSearch();
  }

  termSubmitted( $event = undefined ): void {
    this.scroll_items = []        // We are resetting the infinite scroll
    this.pgn_item_start = 0;      // We are resetting the pagination start
    this.prev_term = this.term;   // We are recording the prev term for
    this.searching = true;        // We are searching
    this.search_complete = false; // We are starting a new search
    if($event){
      this.term = $event.term;    // We have a new search term
    }
    this.count_total = 0;
  }

  async openAddFromCrunchbase( org ) {
    try {
      let response = await this.aviaService.getCrunchbase( {'uuid': org.crunchbase_id, 'lite':1} );
      let cb_to_add = response.body['rows'][0] ? response.body['rows'][0] : undefined;
      if (cb_to_add !== undefined) return cb_to_add;
    } catch(err) {
      console.error('response came back empty! err=', err);
    }
  };

  async submitAddFromCrunchbase( org ) {

    // Process the crunchbase_id
    let crunchbase_results = await this.openAddFromCrunchbase(org);
    org = { name: crunchbase_results['company_name'],
      crunchbase_id: crunchbase_results['id'],
      description: crunchbase_results['company_description'],
      url_linkedin: crunchbase_results['linkedin'],
      url_twitter: crunchbase_results['twitter'],
      web_addr: crunchbase_results['website'],
      type: 2,
      status: 4,
      dashboard_vis: 3,
      hs_membership: 1,
      admin: 1,
      logo: crunchbase_results['company_logo'],
      salesforce_id: null
    }

    let result = await this.aviaService.createSolCo( org );
    if( result && result['id'] !== undefined ) {
      return result;
    }
  };

  async submitImportFromCrunchbase( org ) {
    delete org['crunchbase_uuid'];
    delete org['headquarters_locations'];
    delete org['url_crunchbase'];
    delete org['url_news'];
    org['type'] = 2; // Set type to 'solco'
    org['status'] = 4; // Set status to active

    // Test to see if an Avia-style Address (or part of it) was supplied
    // if not, delete the 'hq_addr' block
    let empty_addr:boolean = true;
    for (const item in org['hq_addr']) {
      let val = org['hq_addr'][item];
      if (val !== undefined && val !== null && val !== '') {
        empty_addr = false;
        break;
      }
    }
    if (empty_addr) delete org['hq_addr'];

    let result = await this.aviaService.createSolCo( org );
    if( result && result['id'] !== undefined ) {
      this.sc_import_modal.close();
      this.addSolco.emit(result)
    }
  };

  openScAdd(){
    this.sc_import_modal.open();
  }
}
