import { AccessKey } from './../../../../class';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector:      'app-notify-np-connect',
  styleUrls:     ['../avia-notify-item.component.scss'],
  templateUrl:   './notify-np-connect.component.html'
})
export class NotifyNpConnectComponent implements OnInit {
  @Input() akey:         AccessKey = new AccessKey();
  @Input() notification: any;
  @Input() options:      NotifyNpConnect_Options = new NotifyNpConnect_Options();
  @Input() show_dismiss: boolean = true;
  
  @Output('dismiss') dismiss = new EventEmitter();
  @Output('goTo')    goTo    = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick($event) {
    this.goTo.emit({'notification': $event});
  }

}

export class NotifyNpConnect_Options {
  constructor(
    public large: boolean = false
  ) { }
}
