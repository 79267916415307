<div class="row justify-content-center pt-4">
	<div class="col-12 col-md-8 mt-4">
		<div class="heading-size-36 pb-4 text-center">Congratulations!</div>
		<div class="text-center col-12 col-md-6 m-auto font-weight-light font-size-16 font-body">Your company now has a basic profile, and has earned the <span class="font-weight-bold">Active Badge</span>. This will let users know that you're actively managing your profile.</div>
		<div class="py-6 text-center">
			<div class="col-12 mx-auto mb-4">
				<img class="active-badge-icon" [src]="'/assets/badges/badge-active.svg'"/>
			</div>
			<div class="d-inline-flex">
				<div class="col-6">
					<img class="lock-icon position-absolute" [src]="'/assets/badges/lock.svg'"/>
					<img class="badge-icon" [src]="'/assets/badges/badge-market-val-gray.svg'"/>
				</div>
				<div class="col-6">
					<img class="lock-icon position-absolute" [src]="'/assets/badges/lock.svg'"/>
					<img class="badge-icon" [src]="'/assets/badges/badge-avia-vetted-gray.svg'"/>
				</div>
			</div>

		</div>
	</div>
</div>

