<div class="page container-fluid bg-white h-100 p-0">

	<app-avia-header-row></app-avia-header-row>

	<div class="justify-content-center mt-6 row w-100">
		<div class="col-12 col-md-5 text-center pr-0">
			<h2>Your {{org_type}} and email</h2>
			<p class="px-4">{{sub_header}}</p>
		</div>
	</div>

	<div class="justify-content-center mt-4 row w-100 mx-0">
		<div class="bg-white col-12 col-md-5 justify-content-center no-gutters row text-center">
			<!-- INITIAL EMAIL FORM -->
			<form [formGroup]="fg_email" class="w-100" (ngSubmit)="onSubmitEmail()">
				<div class="form-group text-left">
					<label for="email">Email</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="emailHelp"
						class="form-control"
						formControlName="email"
						id="email"
						placeholder="Enter email associated with this {{org_type}}"
						tabindex="1"
						type="email"
					>
					<small *ngIf="!fg_email.pristine && fg_email.controls['email'].hasError('required')" class="form-text text-danger">Email address is required.</small>
					<small *ngIf="!fg_email.pristine && fg_email.controls['email'].hasError('email')" class="form-text text-danger">A valid email address is required.</small>
					<small class="form-text text-danger" [ngClass]="{'d-none': !fg_failure.enabled}">Sorry! The domain <strong>{{domain}}</strong> is not in our system. {{not_found_text}}</small>
				</div>
				<div class="buttonGroup d-flex justify-content-between mt-7" *ngIf="fg_email.enabled">
					<button class="btn btn-outline-gray-700" routerLink=".." tabindex="3" type="button">&lt;&nbsp;Back</button>
					<button class="btn" tabindex="2" type="submit" [disabled]="!fg_email.valid" [ngClass]="{'btn-gray-400': !fg_email.valid, 'btn-primary':fg_email.valid}">
						<span class="text-white">Next&nbsp;&gt;</span>
					</button>
				</div>

				<!-- DEBUGGING DISPLAY (FG_EMAIL) -->
				<span *ngIf="DEBUG_MODE && fg_email.enabled">
					<!-- Display the form values and state during development -->
					<p>Form value: {{ fg_email.value | json }}</p>
					<p>Form status: {{ fg_email.status | json }}</p>
					<p class="mb-6">Pristine: <span class="text-uppercase">{{ fg_email.pristine }}</span></p>
				</span>
			</form>

			<!-- FAILURE PATH FORM -->
			<form class="mb-4 w-100" [formGroup]="fg_failure" [ngClass]="{'d-none':!fg_failure.enabled}" (ngSubmit)="onSubmitFailure()">
				<!-- first name -->
				<div class="form-group text-left">
					<label for="firstname">First Name</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="firstnameHelp"
						class="form-control"
						formControlName="firstname"
						id="firstname"
						placeholder="Enter your first name"
						tabindex="1"
						type="text"
					>
					<small *ngIf="!fg_failure.pristine && fg_failure.controls['firstname'].hasError('required')" class="form-text text-danger">First name is required.</small>
				</div>

				<!-- last name -->
				<div class="form-group text-left">
					<label for="lastname">Last Name</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="lastnameHelp"
						class="form-control"
						formControlName="lastname"
						id="lastname"
						placeholder="Enter your last name"
						tabindex="2"
						type="text"
					>
					<small *ngIf="!fg_failure.pristine && fg_failure.controls['lastname'].hasError('required')" class="form-text text-danger">Last name is required.</small>
				</div>

				<!-- org name -->
				<div class="form-group text-left">
					<label for="orgname" class='text-capitalize'>{{org_type}} Name</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="orgnameHelp"
						class="form-control"
						formControlName="orgname"
						id="orgname"
						placeholder="Enter your {{org_type}}'s name"
						tabindex="3"
						type="text"
					>
					<small *ngIf="!fg_failure.pristine && fg_failure.controls['orgname'].hasError('required')" class="form-text text-danger">Organization name is required.</small>
				</div>

				<!-- website -->
				<div class="form-group text-left">
					<label for="website" class='text-capitalize'>{{org_type}} website</label>
					<span class="float-right"><small><i>required</i></small></span>
					<input
						aria-describedby="websiteHelp"
						class="form-control"
						formControlName="website"
						id="website"
						placeholder="Enter your {{org_type}}'s website"
						tabindex="4"
						type="text"
					>
					<small *ngIf="!fg_failure.pristine && fg_failure.controls['website'].hasError('required')" class="form-text text-danger">Organization website is required.</small>
				</div>

				<div class="buttonGroup d-flex justify-content-between mt-7" *ngIf="fg_failure.enabled">
					<button class="btn btn-outline-gray-700" tabindex="6" type="button" (click)="onBackToEmail($event)">Cancel</button>
					<button class="btn" tabindex="5" type="submit" [disabled]="!fg_failure.valid" [ngClass]="{'btn-gray-400': !fg_failure.valid, 'btn-primary':fg_failure.valid}"><span class="text-white">Submit Request</span></button>
				</div>

				<!-- DEBUGGING DISPLAY (FG_FAILURE) -->
				<span *ngIf="DEBUG_MODE && fg_failure.enabled">
					<!-- Display the form values and state during development -->
					<p>Form value: {{ fg_failure.value | json }}</p>
					<p>Form status: {{ fg_failure.status | json }}</p>
					<p class="mb-6">Pristine: <span class="text-uppercase">{{ fg_failure.pristine }}</span></p>
				</span>
			</form>

			<!-- HAPPY PATH FORM -->
			<form class="w-100" [formGroup]="fg_happy" [ngClass]="{'d-none':!fg_happy.enabled}" (ngSubmit)="onSubmitHappy()">
				<div class="form-group text-left">
					<label for="orgs">Organization</label>
					<app-avia-form-orgs-dropdown
						*ngIf="fg_happy.enabled"
						formControlName="org"
						[orgs]="orgs"
						[placeholder]="'Please select your '+org_type+'...'"
						[tabindex]="1"
					></app-avia-form-orgs-dropdown>
				</div>
				<div class="buttonGroup d-flex justify-content-between mt-7" *ngIf="fg_happy.enabled">
					<button class="btn btn-outline-gray-700" tabindex="3" type="button" (click)="onBackToEmail($event)">&lt;&nbsp;Back</button>
					<button class="btn" tabindex="2" type="submit" [disabled]="!fg_happy.valid" [ngClass]="{'btn-gray-400': !fg_happy.valid, 'btn-primary':fg_happy.valid}"><span [ngClass]="text-white">Next&nbsp;&gt;</span></button>
				</div>
				<!-- DEBUGGING DISPLAY (FG_HAPPY) -->
				<span *ngIf="DEBUG_MODE && fg_happy.enabled">
					<!-- Display the form values and state during development -->
					<p>Form value: {{ fg_happy.value | json }}</p>
					<p>Form status: {{ fg_happy.status | json }}</p>
					<p class="mb-6">Pristine: <span class="text-uppercase">{{ fg_happy.pristine }}</span></p>
				</span>
			</form>
		</div>
	</div>

</div>
