
<ng-template #eula_box let-c="close" let-d="dismiss" class="modal-md fade">

  <app-eula #eula *ngIf="state!==''" [state]="state" [running_on_signin]="false" [show_close]="true" (onchange)='setState($event)'></app-eula>

  <div *ngIf="state===''" class="modal-content">
		<div class="d-flex justify-content-center align-items-center modal-body">
			<div>
				<div class="align-middle">
					<a class="text-accent ptr" href="http://www.aviahealthinnovation.com"><img class="avialogo align-middle img-fluid mx-auto d-block" src="/assets/header-avia-connect-logo.svg"></a>
				</div>
				<div class="heading-size-32 text-center">
					We have updated our<BR>
					Terms of Service and Privacy Policy.
				</div>
				<div class="pt-4 text-center font-size-14">
					By clicking <b>Accept</b>, I agree to the<BR>
					<span class=" font-weight-bold text-blue-dark pointer" (click)="setState( 'tos' )">Terms of Service</span> and <span class="font-weight-bold text-blue-dark pointer" (click)="setState( 'pri' )">Privacy Policy</span>.
				</div>
			</div>
		</div>
    <div class="py-2 w-100 modal-footer justify-content-center bg-gray-200">
      <div class="pr-4 button">
        <button class="font-weight-bold btn btn-outline-dark px-6 py-1 cancel-button pointer" (click)="signOut()">Logout</button>
			</div>
			<div class="button">
        <button class="font-weight-bold btn btn-primary px-6 py-1" (click)="accept()">Accept</button>
      </div>
    </div>
  </div>
</ng-template>
