import { browser } from 'protractor';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FeedService } from './../feed.service';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-postmaker',
  templateUrl: './postmaker.component.html',
  styleUrls: ['./postmaker.component.scss', '../feed.scss']
})
export class PostmakerComponent implements OnInit {
  @Output() updateItem: EventEmitter<any> = new EventEmitter<any>();

  @Input() mode: string = 'create'; // create or update
  @Input() show_avatar = true;
  @Input() type: number; // 4 question, 2 post
  @Input() post;

  @ViewChild('postmodal', { static: false }) postmodal: NgbModal;
  modalRef: NgbModalRef;
  user_org_name: string = "my org";
  visibility:any = [];
  session:any;
  visibility_options:any = [
  ];

  /*
    1: main
    2: privacy
    3: indentity
  */
  section = 1;

  changeSection(section) {
    this.section = section;
  }

  constructor(
    public fs: FeedService,
    private modalService: NgbModal,
  ) { }

  async ngOnInit() {
    this.setVisibilityOptions()
    if(this.post) {
      this.swapTypes(this.post.type);
      for(let vis of this.visibility_options) {
        vis.selected = this.compareVisArray(this.post.data.visibility, vis.value);
      }
    } else {
      /* * * * * * * * * * * *
        Assign Type
        4 question
        2 post
      * * * * * * * * * * * */
      if(this.fs.config.create.types[0]) {
        this.swapTypes(this.fs.config.create.types[0]);
      }
    }

    this.user_org_name = this.fs.aviaService.session.org.name;
  }


  public compareVisArray(a1,a2)  {
    //don't ask...
    /*
      Comparing current vis settings
      to the different options
      and detects which is being used
      -- All or AVIANS and HS or etc.
    */
    let found = [];
    if(a1 && a2 && a1.length > 0 && a2.length > 0) {
      for(let v1 of a1) {
        for(let v2 of a2) {
          for(let k2 in v2) {
            if(v1[k2] == v2[k2]) {
              found.push(true);
              break;
            }
          }
        }
      }

      return found.length === a2.length;
    }
    return false;
  }

  public setVisibilityOptions() {
    let is_org = false;
    let is_workspace = false;

    for(let tag of this.fs.config.create.tags) {
      if(tag["org_id"] && tag["root"] == 1) {
        is_org = true;
      }
      else if (tag["workspace_id"] && tag["root"] == 1) {
        is_workspace = true;
      }
    }

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
      Public spaces: Topics/Main Feed
      --------------------------------
      Public
        Visible to all users
      Private
        Visible to only hospital and health system users and AVIA

      Workspaces/Groups:
      ------------------------
      People in this Group

      Company Q&A:
      ------------------
      Public
        Visible to this company and other hospital and health system users.
    * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

    this.visibility_options = [];
    this.visibility = null;

    if(is_workspace) {
      this.visibility_options.push(
        {
          value:[],
          name: "This Group",
          details: "Visible to all users in this Group",
          selected: false
        }
      );
      this.visibility = this.visibility_options[0]
    }
    else {
      this.visibility_options.push(
        {
          value:[],
          name: "Anyone",
          details: "Visible to all users",
          selected: false
        }
      );

      // show in public places Topic, Main Newsfeed, Org rooted posts
      this.visibility_options.push(
        {
          value:[ {"org_type_id":3}, {"org_type_id":1}, {"org_id":this.fs.aviaService.session.org.id} ],
          name: (this.fs.aviaService.session.org.type !== 1 && this.fs.aviaService.session.org.type !== 3)? "My Company, Providers, and AVIA" : "Providers and AVIA",
          details: (this.fs.aviaService.session.org.type !== 1 && this.fs.aviaService.session.org.type !== 3)? `Visible to only My Company, Providers, and AVIA`: `Visible to only Providers and AVIA`,
          selected: false
        }
      );

      // if(this.fs.aviaService.session.org.type !== 3 && this.fs.aviaService.session.org.type !== 1) {
      //   this.visibility = this.visibility_options[0];
      //   for(let tag of this.fs.config.create.tags) {
      //     if(tag["org_id"] == this.fs.aviaService.session.org.id && tag["root"] == 1) {
      //       this.visibility = this.visibility_options[1];
      //     }
      //   }
      // }
      if(!this.visibility) this.visibility = this.visibility_options[0]
    }
  }

  public storeStatusChange(name) {
    for(let vis of this.visibility_options) {
      if(vis.name == name) {
        this.visibility = vis;
      }
    }
  }

  public open() {
    let options: NgbModalOptions = {
      windowClass: 'show-modal-overflow',
      size: 'lg',
      backdrop: 'static'
    };

    if(this.fs.aviaService.mobile_mode == true) {
      options.windowClass = 'avia-modal-fullscreen';
    }

    this.modalRef = this.modalService.open(this.postmodal,options);
    // debugger
    if(this.mode === 'create') {
      this.fs.tourService.checkForTour(1);
    }
  }

  public swapTypes(type) {
    this.type = type;
    this.setVisibilityOptions();
  }

  async createOrEditPost(content) {
    if((content.html && content.text && content.text.trim().length > 0) || content.media) {

      if(this.mode === 'create') {
        let res = await this.fs.createPost({content:content.html,tags:content.tags, media: content.media, type:this.type, visibility:this.visibility.value});
        // debugger
        if(!this.fs.nav_bar.tabs[0].active) {
          this.fs.filter(this.fs.nav_bar.tabs[0]);
          await this.fs.nav_bar.switchTab(0);
        } else {
          this.fs.items = [ ...res, ...this.fs.items];
        }
      }
      if (this.mode === 'update') {
        console.log('update: ' + content)
        let res = await this.fs.editPost(this.post.id, {content:content.html,tags:content.tags, media: content.media, type:this.type, visibility:this.visibility.value});
        this.post = res;
        this.updateItem.emit(this.post);
      }
    }

    this.modalRef.close();
  }

  public have_user(event) {
    return this.fs.aviaService.session.user.id ? true :false
  }
}
