import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AVIAConnectService } from '../../avia-connect.service'
import { Common } from '../../common';


@Component({
  selector: 'app-signup-other',
  templateUrl: './signup-other.component.html'
})
export class SignupOtherComponent implements OnInit {
  readonly DEBUG_MODE: boolean = false;
  readonly VERBOSE:    boolean = false;

  fg_other: FormGroup

  constructor(private aviaService: AVIAConnectService, private fb: FormBuilder, private router: Router ) { }

  ngOnInit() {
    this.fg_other = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      orgname: ['', [Validators.required]],
      website: ''
    });
  }

  async onSubmitOther(): Promise<void> {
    this.fg_other.disable()
    let user_info = {
      firstname: this.fg_other.controls.firstname.value,
      lastname: this.fg_other.controls.lastname.value,
      email: this.fg_other.controls.email.value,
      orgname: this.fg_other.controls.orgname.value,
      website: this.fg_other.controls.website.value
    }

    let other_res = await this.aviaService.selfAddOtherEmail(user_info);


    if ( other_res['status'] == 200 && other_res['body'].message === 'success' ) {
      this.aviaService.notify('success', 'Help is on the way.', 'We\'ll reach out to you soon with next steps on completing your profile.', {showConfirmButton: true});
    } else {
      this.aviaService.notifyFailure(`Sorry, we could not process your request, please email "${Common.EMAILADDY_SUPPORT}" for assistance.`);
    }

    this.router.navigate(['']);
  }
}
