import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInDown, fadeOutUp } from 'ng-animate';
import { Subscription } from 'rxjs';
import { trigger, transition, useAnimation } from '@angular/animations';

import {
  Color_Library,
  NavTabBar_ProcessHamburguesaResult,
  Nav_Tab,
  Nav_Tab_Bar,
} from '../../../../class';


// AVIA TabBar
// @example start
// <app-avia-tab-bar
//   [height_limit]="'10px'"
//   [nav_bar]="test_nav_bar"
//   [reset_query_params]="true"
//   [tab_preselect_param]="'activeTab'"
// ></app-avia-tab-bar>
// @example end
@Component({
  selector: 'app-avia-tab-bar',
  templateUrl: './avia-tab-bar.component.html',
  styleUrls: ['./avia-tab-bar.component.scss', './../avia-tab.scss'],
  animations: [
    trigger('fadeInDown', [transition(':enter', useAnimation(fadeInDown, { params: {
      timing: .25,
      a: '-5px',
      b: '0px'
    }}))]),
    trigger('fadeOutUp', [transition(':leave', useAnimation(fadeOutUp, { params: {
      timing: .25,
      a: '0px'
    }}))])
  ]
})
export class AviaTabBarComponent {
  readonly DEBUG_MODE: boolean = false;

  @Input() height_limit:                  string;
  @Input() show_right_side_custom_button: boolean = false;
  @Input() textarea_hack:                 number = 10;
  @Input() thick_bar:                     boolean = false;
  @Input() reset_query_params:            boolean = true;

  private query_params:         any = {};
  private query_params_sub:     Subscription;
  private preselected_tab:      string;
  private _tab_preselect_param: string;
  @Input() // tab_preselect_param
    // NOTE: Do not set if you do not want Tab tracking via Query Params
    // NOTE: This Input must appear second to last (always before 'nav_bar')
    get tab_preselect_param():string { return this._tab_preselect_param; };
    set tab_preselect_param(value: string) {
      this._tab_preselect_param = value;
      if (this.query_params_sub === undefined && this._tab_preselect_param !== undefined) {
        this.query_params_sub = this.route.queryParams.subscribe((query_params) => {
          this.DEBUG_MODE && console.log('query_params_sub - _nav_bar = ', this._nav_bar, ', query_params.' + this._tab_preselect_param, query_params[this._tab_preselect_param]);
          this.query_params = query_params !== undefined ? cloneDeep(query_params) : {};
          if ( this.preselected_tab !== query_params[this._tab_preselect_param] ) {
            this.preselected_tab = query_params[this._tab_preselect_param];
            if ( this._nav_bar !== undefined ) {
              this._nav_bar.switchTabByName(this.preselected_tab);
            }
          }
        });
      }
      this.DEBUG_MODE && console.log('QUERY_PARAMS_SUB (after) - ', this.query_params_sub);
    }

  _nav_bar: Nav_Tab_Bar;
  @Input() // nav_bar
    //NOTE: This Input must appear last so that all other initialization can happen before displaying the tabs
    get nav_bar() { return this._nav_bar; }
    set nav_bar(data: Nav_Tab_Bar) {
      if (data === undefined) return;
      this._nav_bar = data;
      this.setUnderline();

      // Separate out the 'Normal' tabs from the 'Hamburger Menu' tabs
      this.normal_tabs = this._nav_bar.processNormal();
      let process_ham_res: NavTabBar_ProcessHamburguesaResult = this._nav_bar.processHamburguesa();
      this.show_hamburger = process_ham_res.show_hamburguesa;
      this.hamburger_and_internal_tabs = process_ham_res.hamburger_and_internal_tabs;

      // Select the tab passed in by the query param, if set. If not, select the first available, viewable tab
      let selected_tab = (this.preselected_tab !== undefined)
        ? this._nav_bar.switchTabByName(this.preselected_tab)
        : this._nav_bar.selectActiveTab();
      this.tab_selected.emit(selected_tab);
    }

  @Output('tab_selected') tab_selected = new EventEmitter();

  // Animation and Display
  desktop_is_collapsed:          boolean = true;
  fadeInDown:                    any;
  fadeOutUp:                     any;
  hamburger_and_internal_tabs:   Nav_Tab[] = [];
  mobile_is_collapsed:           boolean = true;
  normal_tabs:                   Nav_Tab[] = [];
  show_hamburger:                boolean;
  show_underline:                boolean;


  constructor(public router: Router, public route: ActivatedRoute) {}

  ngOnDestroy() {
    if (this.query_params_sub) {
      this.query_params_sub.unsubscribe();
    }
    this._tab_preselect_param = undefined;
    this.preselected_tab = undefined;
  };

  selectTab(tab:Nav_Tab) {
    setTimeout(() => {
      if (tab !== undefined){
        let new_tab = this._nav_bar.switchTabByName(tab.name);
        if (new_tab !== undefined) {
          this.tab_selected.emit(new_tab);
          if (this._tab_preselect_param !== undefined) {
            if(this.reset_query_params) {
              this.query_params = {};
            }
            this.query_params[this._tab_preselect_param] = new_tab.name;
            this.router.navigate([], { queryParams: this.query_params, relativeTo: this.route });
          }
        }
      }
    }, this.textarea_hack);
    /* NOTE:
    - The input 'textarea_hack is defaulted to 10 because we need a tick per avia-textarea on a page tied to edit and tab select.
    - If you have more than 9, avia-textareas on a page, set 'textarea_hack' in your component to 9 plus however many more you have.
    - This only applies if you have tab components with avia-textarea and universal editing.
    - Questions? See Jaron, Susie or Sippy   - TEXT AREA!!!!
    */
  }

  setUnderline() {
    this.show_underline = (this._nav_bar.background_color === Color_Library.white) ? true : false;
  }

  toggleHamburguesa() {
    this.desktop_is_collapsed = !this.desktop_is_collapsed;
    this.mobile_is_collapsed = !this.mobile_is_collapsed;
  }

}
