import { Component, OnInit } from '@angular/core';

import { OnboardingService } from '../../../onboarding.service';

@Component({
  selector: 'app-onboarding-solco-congratulations',
  templateUrl: './onboarding-solco-congratulations.component.html',
  styleUrls: ['./onboarding-solco-congratulations.component.scss']
})
export class OnboardingSolcoCongratulationsComponent implements OnInit {

  constructor(public sob: OnboardingService) {}

  ngOnInit() {
    this.sob.form_valid = true;

    this.sob.aviaService.createAnalyticNodeEvent({
      actions:[{
        action_type:"ONBOARDING_SOLCO_END",
        eventkeytype:"ONBOARDING_SOLCO_END",
        eventkeydivisor:1000,
        routeParams: this.sob.aviaService.currentRouteParams,
        //target: user_id, get it on back end
        attributes: {
          page_type: "onboarding",
          // status
        },
      }]
    })

  }


}
