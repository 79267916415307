<div class="pt-4">
	<h2 class="heading-size-36 text-center mt-4">Congratulations!</h2>
	<div class="row justify-content-center">
		<div class="col-10 col-md-8 col-lg-6">
			<p class="text-center font-body font-size-16 font-weight-light">
				You now have an AVIA Connect profile.
			</p>
		</div>
	</div>
</div>
<div class="row d-flex justify-content-center mt-4 px-4 pt-4">
	<img class="img-fluid" [src]="'/assets/onboarding/Congratulations.svg'">
</div>
