<div *ngIf="gns?.aviaService?.mobile_mode">
	<!-- Health System -->
	<ng-container *ngIf="gns.aviaService.session.org.type == 1">
		<div class="p-2">
			<app-nav-card
				[color]="gns.aviaService.session.org.color ? gns.aviaService.session.org.color : gns.blue_dark"
				[name]="'My Org Profile'"
				[background_type]="3"
				[routerLink]="['/profiles/hs/', gns.user_org_id]"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div *ngIf="gns.show_checkin" class="p-2">
			<app-nav-card
				[color]="gns.red_dark"
				[name]="'Check-in'"
				[background_type]="1"
				[routerLink]="['/dashboard/hs/', gns.aviaService.session.org.id]"
				[queryParams]="{activeTab: 'Check-in'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div *ngIf="gns.keychain.pulse.e && gns.keychain.pulse_enabled.e" class="p-2">
			<app-nav-card
				[color]="gns.pulse_blue"
				[name]="'Digital Pulse'"
				[background_type]="2"
				[routerLink]="['/dashboard/hs/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Digital Pulse'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div *ngIf="gns.keychain.dashboard_feature.e" class="p-2">
			<app-nav-card
				[color]="gns.teal_dark"
				[name]="'Our Team'"
				[background_type]="3"
				[routerLink]="['/dashboard/hs/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Our Team'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div *ngIf="gns.keychain.org_premium.e && gns.keychain.dashboard_feature.e" class="p-2">
			<app-nav-card
				[color]="gns.orange_gold"
				[name]="'Priorities'"
				[background_type]="3"
				[routerLink]="['/dashboard/hs/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Priorities'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div *ngIf="gns.keychain.org_premium.e && gns.keychain.dashboard_feature.e" class="p-2">
			<app-nav-card
				[color]="gns.violet_dark"
				[name]="'Companies'"
				[background_type]="1"
				[routerLink]="['/dashboard/hs/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Companies'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div *ngIf="gns.keychain.org_premium.e && gns.keychain.dashboard_feature.e" class="p-2">
			<app-nav-card
				[color]="gns.blue_mid"
				[name]="'Groups'"
				[background_type]="3"
				[routerLink]="['/dashboard/hs/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Groups'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<!-- <div class="py-2 px-4 mt-4 d-inline-flex justify-content-between w-100 bg-gray-100">
			<div class="my-auto heading-size-16">
				Invite Peers to Join Connect
			</div>
			<app-avia-user-invite-modal
				[akey]="gns?.keychain_org.org_demo"
				[org]="gns?.aviaService?.session?.org"
			>
			<button class="btn btn-gray-200 text-primary">Invite</button>
			</app-avia-user-invite-modal>
		</div> -->
	</ng-container>

	<!-- Solco -->
	<ng-container *ngIf="gns.aviaService.session.org.type == 2">
		<div class="p-2">
			<app-nav-card
				[color]="gns.aviaService.session.org.color ? gns.aviaService.session.org.color : gns.blue_dark"
				[name]="'My Org Profile'"
				[background_type]="3"
				[routerLink]="['/sc', gns.user_org_id]"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				[color]="gns.orange_gold"
				[name]="'Our Team'"
				[background_type]="1"
				[routerLink]="['/dashboard/sc/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Our Team'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				[color]="gns.violet_dark"
				[name]="'Files'"
				[background_type]="1"
				[routerLink]="['/dashboard/sc/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Files'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<!-- <div class="py-2 px-4 mt-4 d-inline-flex justify-content-between w-100 bg-gray-100">
			<div class="my-auto heading-size-16">
				Invite Peers to Join Connect
			</div>
			<app-avia-user-invite-modal
				[akey]="gns?.keychain_org.org_demo"
				[org]="gns?.aviaService?.session?.org"
			>
			<button class="btn btn-gray-200 text-primary">Invite</button>
			</app-avia-user-invite-modal>
		</div> -->
	</ng-container>

	<!-- Partner -->
	<ng-container *ngIf="gns.aviaService.session.org.type == 5">
		<div class="p-2">
			<app-nav-card *ngIf="gns.community && gns.community.landing_page"
				[color]="gns.aviaService.session.org.color ? gns.aviaService.session.org.color : gns.blue_dark"
				[name]="'My Community'"
				[background_type]="1"
				[routerLink]="['/communities/page', gns.community.landing_page]"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				[color]="gns.orange_gold"
				[name]="'Our Team'"
				[background_type]="1"
				[routerLink]="['/dashboard/partner/', gns.user_org_id]"
				[queryParams]="{activeTab: 'Our Team'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<!-- <div class="py-2 px-4 mt-4 d-inline-flex justify-content-between w-100 bg-gray-100">
			<div class="my-auto heading-size-16">
				Invite Peers to Join Connect
			</div>
			<app-avia-user-invite-modal
				[akey]="gns?.keychain_org.org_demo"
				[org]="gns?.aviaService?.session?.org"
			>
			<button class="btn btn-gray-200 text-primary">Invite</button>
			</app-avia-user-invite-modal>
		</div> -->
	</ng-container>

	<!-- AVIA -->
	<ng-container *ngIf="gns.aviaService.session.org.type == 3">
		<div class="p-2">
			<app-nav-card
				[color]="gns.green_dark"
				[name]="'AVIA Community'"
				[background_type]="1"
				[routerLink]="['/communities/page/1']"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				[color]="gns.blue_dark"
				[name]="'Accounts'"
				[background_type]="1"
				[routerLink]="['/dashboard/avia/1']"
				[queryParams]="{activeTab: 'Accounts'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				[color]="gns.violet_dark"
				[name]="'Membership'"
				[background_type]="1"
				[routerLink]="['/dashboard/avia/1']"
				[queryParams]="{activeTab: 'Membership'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<div class="p-2">
			<app-nav-card
				[color]="gns.orange_gold"
				[name]="'Our Team'"
				[background_type]="1"
				[routerLink]="['/dashboard/avia/1']"
				[queryParams]="{activeTab: 'Team'}"
				(click)="gns.closeMobileDrawer()">
			</app-nav-card>
		</div>
		<!-- <div class="py-2 px-4 mt-4 d-inline-flex justify-content-between w-100 bg-gray-100">
			<div class="my-auto heading-size-16">
				Invite Peers to Join Connect
			</div>
			<app-avia-user-invite-modal
				[akey]="gns?.keychain_org.org_demo"
				[org]="gns?.aviaService?.session?.org"
			>
			<button class="btn btn-gray-200 text-primary">Invite</button>
			</app-avia-user-invite-modal>
		</div> -->
	</ng-container>

</div>
