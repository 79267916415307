import { Component, OnInit, ViewEncapsulation, Input, Output } from '@angular/core';
import { User } from '../../../../class';
import { Session, CommentGroup } from './../../../../class';
import { EventEmitter } from "@angular/core";

@Component({
  selector: 'app-avia-chat-item',
  templateUrl: './avia-chat-item.component.html',
  styleUrls: ['./avia-chat-item.component.scss']
})

export class AviaChatItemComponent implements OnInit {
  @Input() item;
  @Input() user_id: number;

  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  editing: boolean = false;
  VERBOSE: boolean = false;

  constructor() { }

  ngOnInit() {}

  updateMessage(id: number, content: string) {
    if(content !== '')
    this.update.emit({ message_id: id, content: content });
  }

  deleteMessage(id: number) {
    this.delete.emit({ message_id: id });
  }

  parseLinks(message) {
    if(!message) return "";
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);
    //this is quick stripping of html and then a replace with links, angular should handle the rest of the sanitation for us
    return message.replace(/\</gi, '&lt;').replace(/\>/gi, '&gt;').replace(regex, "<a href='$&' target='_blank'>$&</a>");
  }
}
