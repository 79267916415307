<div class="toggle-row row no-gutters justify-content-between align-items-center">
	<div class="col-8">
		<div [routerLink]="link ? [link] : ['.']" [ngClass]="{'pointer': link}" class="mb-0">{{ label }}</div>
	</div>
	<div class="col-4 pr-0 text-right">
		<div class="d-flex justify-content-end align-items-center">
			<app-avia-toggle
				[label_show]="false"
				[value]="value"
				(switched)="toggle($event);">
			</app-avia-toggle>
		</div>
	</div>
</div>
