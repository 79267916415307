import { GlobalNavbarService } from './../../../global-navbar.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-navbar-myconnect',
  templateUrl: './mobile-navbar-myconnect.component.html'
})
export class MobileNavbarMyconnectComponent implements OnInit {

  constructor(public gns: GlobalNavbarService) { }

  ngOnInit() {
  }

}
