import { Observable, from } from 'rxjs';


export class DataCacher {
  static debuglog: boolean = false;

  cachedData: any;
  comment:    string = "";
  observable: Observable<any>;
  promise:    Promise<any>;

  fnThatCallsBack: (callbackFn: (err:string, result:any)=>any) => any; // your async function which calls your callbackFunc
  fnThatReturnsAObservable: () => Observable<any>; // your function which returns a observable
  fnThatReturnsAPromise: () => Promise<any>; // your function which returns a promise

  // see if the data is already cached.
  get(): any { return this.cachedData; }
  forget() {
    this.cachedData = undefined;
  }


  // teach me how to pull data, use one of the three functions here
  constructor( comment:string = "", promiseFunc: () => Promise<any> = undefined, asyncFunc: (callbackFn: (err:string, result:any)=>any) => any = undefined, observableFunc: () => Observable<any> = undefined ) {
    this.comment = comment;
    if (promiseFunc) this.setPromiseFn( promiseFunc );
    else if (asyncFunc) this.setAsyncFn( asyncFunc );
    else if (observableFunc) this.setObservableFn( observableFunc );
  }

  // setPromiseFn( () => aviaService.xhrAuth(...) )
  setPromiseFn( func: () => Promise<any> ) {
    this.fnThatReturnsAPromise = func;
    DataCacher.debuglog && console.log( '[' + this.comment + ']' + ' Set Promise Func' );
  }

  // setAsyncFn( (cb) => aviaService.xhrAuth(...).then( r => cb( undefined, r ), e => cb( e, undefined ) ) )
  setAsyncFn( func: (callbackFn: (err:string, result:any)=>any) => any ) {
    this.fnThatCallsBack = func;
    DataCacher.debuglog && console.log( '[' + this.comment + ']' + ' Set Async Func' );
  }

  // setObservableFn( () => this.http.post(...) )
  setObservableFn( func: () => Observable<any> ) {
    this.fnThatReturnsAObservable = func;
    DataCacher.debuglog && console.log( '[' + this.comment + ']' + ' Set Observable Func' );
  }

  // Usage:
  // // once in aviaService:
  // let support_table = new DataCacher();
  // support_table.setPromiseFn( () => aviaService.xhrAuth(...) )
  //
  // // per component
  // let s = support_table.getData().then( r => console.log( r ) );
  getData( forceReload = false ): Promise<any> {
    DataCacher.debuglog && console.log( '[' + this.comment + ']' + ' getData()' );
    if (!forceReload && this.cachedData) {
      DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Return cache data");
      return new Promise( (rs, rj) => rs( this.cachedData ) );
    } else if (!forceReload && this.promise) {
      DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Return Promise");
      return this.promise;
    } else {
      DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Launch the work");
      this.promise = new Promise( (rs, rj) => {
        DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Work starting...");
        if (this.fnThatReturnsAObservable) {
          let subscription;
          subscription = this.fnThatReturnsAObservable().subscribe(
            r => {
              DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Work Complete, returning result.");
              rs( r );
              this.cachedData = r;
            },
            err => console.dir( err ),
            () => subscription.dispose()
          );
        } else if (this.fnThatReturnsAPromise) {
          this.fnThatReturnsAPromise().then(
            r => {
              DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Work Complete, returning result.");
              rs( r );
              this.cachedData = r;
            },
            err => {
              console.info( "=====================" );
              console.info( `DataCacher Failure in ${this.comment}: ` );
              console.info( err );
              console.info( "=====================" );
              return { message: "error" };
            }
          );
        } else if (this.fnThatCallsBack) {
          this.fnThatCallsBack( (err, r) => {
            if (err) {
              err => console.log( err );
            }
            DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Work Complete, returning result.");
            rs( r );
            this.cachedData = r;
          });
        } else {
          console.error( '[' + this.comment + ']' + " ERROR: you haven't taught me how to load the data" )
          rs( {} );
        }
      });

      DataCacher.debuglog && console.log( '[' + this.comment + ']' + " - Return New Promise");
      return this.promise;
    }
  }

  // if you must have the observable version.... :)
  getDataObs( forceReload = false ): Observable<any> {
    return from( this.getData( forceReload ) );
  }
}
