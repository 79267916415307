<div class="notification-item">
<!-- ICON -->
<div [ngSwitch]="notification?.options?.action_type">
  <div *ngSwitchCase="'priority'" class="alt-icon"><i class="material-icons text-white bg-gray-500 rounded p-1">check</i></div>
  <div *ngSwitchCase="'inventory'" class="alt-icon"><i class="material-icons text-white bg-gray-500 rounded p-1">done_all</i></div>
  <div *ngSwitchCase="'info_request'" class="alt-icon"><i class="material-icons text-white bg-gray-500 rounded p-1">outlined_flag</i></div>
  <div *ngSwitchCase="'info_request_ack'" class="alt-icon"><i class="material-icons text-white bg-gray-500 rounded p-1">outlined_flag</i></div>
		<!-- <div class="avatar my-0 mr-2"><img class="img-responsive avatar-img rounded" src="{{ notification.action_user_obj?.avatar }}" /></div> -->
  <div *ngSwitchDefault class="icon"><i class="material-icons">comment</i></div>
</div>
<!-- DATA -->
  <button class="message btn text-left p-0" [ngClass]="{'font-weight-normal': notification.dismissed}" (click)="goTo.emit({'notification':notification});">
    <div [ngSwitch]="notification.type_obj.name" class="title">
      <div *ngSwitchCase="'HS Action'">
          {{ notification?.options?.notication }}
      </div>
      <div *ngSwitchCase="'Pulse Comment'">
        {{ notification?.action_user_obj?.fullname }} {{ notification?.options?.message }}
      </div>
      <div *ngSwitchCase="'Added to Workspace'">
        {{ notification?.options?.message }}
			</div>
			<div *ngSwitchCase="'Request to join channel'">
        {{ notification?.options?.message }}
			</div>
      <div *ngSwitchDefault>
        {{ notification?.action_user_obj?.fullname }} commented on {{ notification?.content_name }}
      </div>
      <div *ngSwitchCase="'Info request'">
        {{ notification?.options?.requestor }} {{ notification?.options?.message }}
      </div>
      <div *ngSwitchCase="'Info request acknowledgment'">
        {{ notification?.options?.acker }} {{ notification?.options?.message }}
      </div>
    </div>
    <div class="date">{{ notification.created | date: "MMM dd, y" }}</div>
	</button>
	<!-- DISMISS -->
	<button *ngIf="show_dismiss" class="dismiss btn pointer" (click)="dismiss.emit({'notification':notification}); $event.preventDefault(); $event.stopPropagation();">
		<i class="material-icons">cancel</i>
	</button>
</div>
