import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { Common } from '../../common';


@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss']
})
export class EULAComponent implements OnDestroy, OnInit {
  readonly EMAILADDY_CONNECT: string = Common.EMAILADDY_CONNECT; // Can't use statics in the HTML template.
  readonly EMAILADDY_SUPPORT: string = Common.EMAILADDY_SUPPORT; // Can't use statics in the HTML template.
  readonly EMAILADDY_INFO:    string = 'info@' + Common.DOMAIN;

  backButton: string = "/start";

  sub_queryparams: Subscription;

  @Input() public state:             string  = 'tos';
  @Input() public running_on_signin: boolean = true;
  @Input() public show_close:        boolean = false;

  @Output() public onchange: EventEmitter<any> = new EventEmitter();

  ///////////////////////////////////////////////////////////////////////
  // VERSION:  MAKE SURE TO UPDATE THIS WHENEVER YOU CHANGE THE DOCUMENT
  static version_tos:string = "0.1.1";
  static version_pri:string = "0.1.1";
  static version_3rd:string = "0.1.1";
  ///////////////////////////////////////////////////////////////////////


  constructor( private router: Router, private route: ActivatedRoute) {
    if (this.sub_queryparams === undefined) {
      this.sub_queryparams = this.route.queryParams.subscribe(params => {
        if (params['back']) {
          this.backButton = params['back'];
        }
      });
    }
  }

  ngOnInit() {
    // if coming from a URL, set up the initial state so page elements display correctly
    if (this.router.url.match( /termsofservice/ )) this.setState( 'tos' );
    if (this.router.url.match( /privacy/ )) this.setState( 'pri' );
    if (this.router.url.match( /3rdparty/ )) this.setState( '3rd' );
  }

  ngOnDestroy(): void {
    this.sub_queryparams.unsubscribe();
    this.sub_queryparams = undefined;
  }

  setState( s:string ) {
    //console.log( "child was [" + this.state + "]"  );

    this.onchange.emit(s);

    if (this.running_on_signin) {
      let navigationExtras:any = {};

      if (this.backButton !== "") navigationExtras.queryParams = { 'back': this.backButton };
      setTimeout(() => {
        this.router.navigate([], navigationExtras);
      }, 0);
    }

    // set state
    //console.log( "child set [" + s + "]"  );
    this.state = s;
  }

  back() {
    if (this.running_on_signin) {
      this.router.navigate( [this.backButton] );
    } else {
      this.setState( '' );
    }
  }

}
