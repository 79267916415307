import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AccessKey } from './../../../../class';
import { SearchService } from './../../../_services/search.service';

@Component({
  selector: 'app-avia-topics-search',
  templateUrl: './avia-topics-search.component.html',
  styleUrls: ['./avia-topics-search.component.scss', '../avia-search-bar.scss'],
  providers: [SearchService]
})
export class AviaTopicsSearchComponent implements OnInit {
  @Input() editing: boolean = false;
  @Input() recommendations: object[];
  @Input() show_no_matches: boolean = false;
  @Input() // options
  set options(inputData: Topics_Search_Options) {
    this._options = new Topics_Search_Options();
    for (let i in inputData) {
      this._options[i] = inputData[i];
    }
  };
  get options(): Topics_Search_Options {
    return this._options;
  };
  _options: Topics_Search_Options = new Topics_Search_Options();

  @Output('done') done = new EventEmitter();

  @ViewChild('searchInput', { static: false }) search_input;
  @ViewChild('searchBox', { static: false }) searchBox;

  // MODAL STUFF
  @ViewChild('TopicsSearch', { static: false }) content: NgbModal;
  modal_ref: NgbModalRef;

  constructor(public sst: SearchService, private modalService: NgbModal) { }

  ngOnInit() {
    this.sst.initSearch('uTopic');
  }

  updateSearch($event) {
    if (this.options.search_add_ons) {
      for (let key in this.options.search_add_ons) {
        let obj = this.options.search_add_ons;
        $event[key] = obj[key];
      }
    }
    this.sst.updateSearch($event);
  }

  resetSearch() {
    this.sst.resetSearch();
    this.sst.searching = false;
  }

  add(d) {
    let obj = {
      action: 'add',
      type: 'card',
      values: [d.id],
      data: d
    };
    this.done.emit(obj);
  }

  addAndClose(d) {
    this.add(d)
    this.resetSearch();
    this.closeModal();
  }

  toggleView($event) {
    // Only show recommendations of the allowed search type
    if (this.options && this.recommendations && this.options.search_add_ons && this.options.search_add_ons.type && this.options.show_recommendations) {
      // search add_ons type can be a number or array of numbers.  Converts and flattens to single dimmension array
      let filter_type = [].concat.apply([], [this.options.search_add_ons.type]);
      this.recommendations = this.recommendations.filter(rec => { return filter_type.includes(rec['type'])})
    }
    if (this.sst.aviaService.mobile_mode && this.sst.searching) this.closeModal();
    this.sst.toggleView($event);
  }


  // === MODAL STUFF ===

  closeModal(): void {
    if (this.sst.aviaService.mobile_mode) {
      this.resetSearch();
      this.modal_ref.close();
    }
  };

  openModal() {
    if (this.sst.aviaService.mobile_mode) {
      let options: NgbModalOptions = {
        size: 'lg',
        windowClass: 'avia-modal-backdrop',
      };
      this.modal_ref = this.modalService.open(this.content, options);
      // TODO: Implement 'auto-focus' for the input when in Modal mode
      //setTimeout( () => {
      //  this.search_input.focusSearchBox();
      //}, 50);
    }
  };

  delete(x:any) {console.log( "angular8: this function was missing when building prod: 'Property does not exist'. Called by the template.... so we added it", x ); }
}


export class Topics_Search_Options {
  constructor(
    public search_add_ons: any = null,
    public show_recommendations: boolean = false,
    public text: string = 'Search'
  ) { }
}
