import { TourService } from './../_services/tour.service';

import { FeedService } from './feed.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { T_SomethingHappened } from '../../interface'
import { E_SomethingHappened } from '../../class'

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss'],
  providers: [FeedService, TourService]
})
export class FeedComponent implements OnInit, OnDestroy {
  @Input() config: any;

  @Input() offset = 0;
  @Input() throttle = 0;
  @Input() scrollDistance = 2;
  @Input() scrollUpDistance = 1;

  // TODO: optional to mimic fb or show 2
  @Input() comments_closed_by_visibility: boolean = false;

  //content stuff...
  @Input() active_id:          number;
  @Input() allow_recommending: boolean = false;
  @Input() white_background:   boolean = false;
  @Input() gray_border:        boolean = false;
  @Input() show_appears_in:    boolean = true;
  @Input() show_filters:        boolean = false;

  @Output() onContentDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() onRecommendedChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onBookmark: EventEmitter<any> = new EventEmitter();

  sub_home_clicked: Subscription;

  setActiveCard(id) {
    this.active_id = id;
  }
  content_types: any;

  constructor(
    public fs: FeedService
  ) { }

  async ngOnInit() {
    if(!this.sub_home_clicked) {
      this.sub_home_clicked = this.fs.aviaService.somethingHappened.subscribe((e: T_SomethingHappened) => {
        if(e.type === E_SomethingHappened.HOME_CLICKED) {
          this.setActiveCard(null);
          window.scrollTo(0,0);
          this.fs.init(this.config)
        }
      })
    }

    this.content_types = (await this.fs.aviaService.getKmSupport()).content_types;
    await this.fs.init(this.config);
  }

  ngOnDestroy() {
    if(this.sub_home_clicked) {
      this.sub_home_clicked.unsubscribe();
      delete this.sub_home_clicked;
    }

    if(this.fs.subscription) this.fs.subscription.unsubscribe();
    if(this.fs.subscription_focus) this.fs.subscription_focus.unsubscribe();
    if(this.fs.subscription_blur) this.fs.subscription_blur.unsubscribe();
  }

  async addItems(limit, offset, _method) {
    let my_config = this.fs.generateConfig({
      filters:{
        limit: limit,
        offset: offset,
        exclude: this.fs.items.map(m => m.id )
      }
    });

    let posts = await this.fs.getPosts(my_config);
    this.fs.items.push(...posts)
    return this.fs.items.length;
  }

  async appendItems(limit, offset) {
    return this.addItems(limit, offset, 'push');
  }

  async prependItems(limit, offset) {
    return this.addItems(limit, offset, 'unshift');
  }

  async onScrollDown (ev) {
    if(this.fs.items && this.fs.items.length > 0 && this.fs.items[this.fs.items.length - 1].created) {
      await this.appendItems(this.fs.COUNT, this.fs.items[this.fs.items.length - 1].created);
    }
  }

  async onScrollUp(ev) {
    // this.prependItems(this.fs.COUNT, this.fs.items.length);
  }

  async getNewPosts() {
    window.scrollTo(0,0);
    this.fs.new_posts = false;
    await this.fs.init(this.config);
  }

}
