import { Injectable } from '@angular/core';
import {
  cloneDeep as lodash_cloneDeep,
  get     as _get,
  orderBy as lodash_orderBy,
  sortBy as lodash_sortBy,
  uniqBy as lodash_uniqBy,
} from 'lodash';

import { AVIAConnectService } from '../../avia-connect.service';
import { Common } from '../../common';

import {
  AccessKey,
  AccessKeychain_Files,
  AnalyticEvent,
  Content,
  E_SomethingHappened,
  Folder,
  Org,
  User,
} from '../../../class';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  // Location Stuff
  location_id:  number;
  location_str: string;
  current_location: string;
  org_id: number;
  open_file_id: number;

  // Data
  file_group = {};   // For Products and Solcos (to sort by category)
  files: Content[] = [];
  folders: Folder[] = [];
  all_files: Content[];
  resource: any;  // Can be activity, ws, solco, product, org, etc. We need rd for initUserAccess

  // UI Front End
  editing:           boolean = false;
  editing_folder_id: number;
  empty_folder_name: boolean = false;
  loading:           boolean = false;
  show_add_folder:   boolean = false;   // generic show_add_folder for avia-file-row-grouper only (ws, solco, and products have show_add_folder attached to their respective groups)
  show_is_public:    boolean = false;

  // Access
  keychain_files: AccessKeychain_Files = new AccessKeychain_Files();
  akey: AccessKey = new AccessKey();

  // Filters
  filter_data: File_Filter_Obj[] = [
    new File_Filter_Obj(true, 'created', 'Date Added', true, 1),
    new File_Filter_Obj(false, 'name', 'Title', true, 0),
    new File_Filter_Obj(false, 'user_obj', 'Owner', this.aviaService.mobile_mode ? false : true, 0)
  ];

  // Workspace Files and Folder Stuff (really special case stuff)
  keychain_ch_files = {};
  keychain_ws_files = {}; // angular8: added to fix error when building prod: "Property does not exist"

  ws_akey:        AccessKey = new AccessKey();
  is_workspace:   boolean = false;
  my_org:         any; // angular8: changed from number to any to get to compile (you were using org_id.id in html template)
  ch_file_groups: any[] = [];
  ch_orgs:        any[] | Org[] = []; // Had to double type this because of lodash!  :(
  ch_users:       User[] = [];

  // Channel Files and Folder Stuff (really special case stuff)
  act_files:     Content[] = [];
  act_folders:   Folder[]  = [];
  act_id:        number;
  all_act_files: Content[] = [];

  is_channel: boolean = false;


  // Product Files
  product_categories = {};
  is_product: boolean = false;

  private VERBOSE: boolean = false;

  constructor(public aviaService: AVIAConnectService) { }

  async init(location_ids: Object) {
    this.loading = true;
    this.current_location = this.aviaService.current_location.getLocation();
    this.location_id  = location_ids['id'];
    this.VERBOSE && console.log(`File Service - init():\nlocation_ids: `, location_ids, `\nthis.location_id: ${this.location_id}\nthis.current_location: `, this.current_location);
    switch(this.current_location) {
      case 'Activities':
        this.location_str = 'activity';
        this.resource = (await this.aviaService.getActivity(this.location_id)).body;
      break;
      case 'Channels':
        this.location_str = `channel/${location_ids['ch_id']}/org`;
        this.location_id = location_ids['ch_id'];
        this.resource = (await this.aviaService.getChannel(this.location_id)).body;
        this.act_id = this.resource.activity_id;
        this.is_channel = true;
      break;
      case 'Workspaces':
      case 'Groups':
        this.location_str = `channel/${location_ids['id']}/org`;
        this.resource = (await this.aviaService.getChannel(this.location_id)).body;
        this.is_workspace = true;
      break;
      case 'Inventory':
        this.location_str = `inventory`;
        this.location_id = location_ids['inv_id'];
        this.resource = (await this.aviaService.getInventoryItem(this.location_id)).body;
      break;
      case 'Products':
        this.location_str = `solco/${location_ids['org_id']}/product`;
        this.location_id = location_ids['product_id'];
        this.resource = (await this.aviaService.getSolCoById(location_ids['org_id'])).body.results[0];
        this.is_product = true;
      break;
      case 'Priorities':
        this.location_str = `org/${location_ids['org_id']}/priority`;
        this.location_id = location_ids['pri_id'];
        this.resource = (await this.aviaService.getHealthcareSystems({id: location_ids['org_id']})).body.results[0];
      break;
      case 'Companies':
        this.location_str = `solco`;
        this.resource = (await this.aviaService.getSolCoById(this.location_id)).body.results[0];
      break;
      case 'Dashboard_AVIA':
        this.resource = (await this.aviaService.getOrgs({id: this.location_id}))[0];
        this.location_str = `dashboard`;
      break;
      case 'Dashboard_Hs':
        this.resource = (await this.aviaService.getHealthcareSystems({id: this.location_id})).body.results[0];
        this.location_str = `dashboard`;
      break;
      case 'Dashboard_Sc':
        this.resource = (await this.aviaService.getSolCoById(this.location_id)).body.results[0];
        this.location_str = `dashboard`;
      break;
      default:
      break;
    }
    this.VERBOSE && console.log(`File Service - init():\nthis.resource:`, this.resource);
    await this.initUserAccess(this.resource && this.resource['rd'] ? this.resource['rd'] : {});

    switch (true) {
      case this.is_product:
        await this.getFileCategories();
        await this.getFiles();
        this.loading = false;
      break

      case this.is_workspace:
        await this.getWorkspaceFiles();
        this.loading = false;
      break

      case this.is_channel:
        await this.getChannelFiles();
        this.loading = false;
      break;

      default:
        await this.getFiles();
        this.loading = false;
      break;
    }

    if(this.open_file_id) {
      this.openFile(this.open_file_id);
    }
  }

  async getAllFiles() {
    if (this.is_workspace) {
      this.getWorkspaceFiles();
    } else if (this.is_channel) {
      this.getChannelFiles();
    } else {
      let res = await this.aviaService.getFiles(this.location_str, this.location_id);
      if(res.status == 200) {
        this.files = res.body.result.files;
        this.folders = res.body.result.folders;
        if(this.is_product) this.sortProductFiles();
      }
    }
    this.show_add_folder = false;
    this.aviaService.somethingHappened.emit( {'type':E_SomethingHappened.FILES_CHANGED, 'data':null} );
  }

  // General File and Folder CRUD operations
  async getFiles() {
    let res = await this.aviaService.getFiles(this.location_str, this.location_id);
    this.VERBOSE && console.log('File Service - getFiles()', res.body);
    if(res.status == 200) {
      this.files = res.body.result.files;
      this.all_files = res.body.result.all_files;
      this.folders = res.body.result.folders;

      if(this.is_product) {
        this.sortProductFiles();
      }
    }
  }

  async addFile( new_file: object, type:string = undefined, org_id: number = undefined ) {
    try {
      if (this.current_location === "Products" && type !== undefined) {
        new_file['product_category'] = type;
      }
      this.VERBOSE && console.log('file.service::addFile called with: ', new_file);
      await this.aviaService.addFile(this.location_str, org_id ? org_id : this.location_id, new_file);

      await this.getAllFiles();
    } catch(err) {
      console.error('addFile ERROR:', err);
      this.aviaService.notify('warning', 'Warning!', 'File not added.', { showConfirmButton: true, timer: null });
    }
  }

  async editFile(file_id: number, data: object, org_id: number = undefined) {
    await this.aviaService.updateFile(this.location_str, org_id ? org_id : this.location_id, file_id, data);

    await this.getAllFiles();
    // this.sortFiles(this.filter_data);
  }

  async deleteFile(file_id: number, org_id: number = undefined) {
    await this.aviaService.deleteFile(this.location_str, org_id ? org_id : this.location_id, file_id);

    await this.getAllFiles();
    //this.sortFiles(this.filter_data);
  }

  // Folders
  async addFolder( new_folder: object, org_id: number = undefined ) {
    try {
      //exit early and prevent creation of name is null or empty
      if(new_folder["name"] === "" || new_folder["name"]  === null) {
        return "";
      }

      //Remove preceding and trailing whitespace
      if(new_folder["name"]) {
        new_folder["name"] = Common.removePrecedingAndTrailingWhiteSpace(new_folder["name"]);
      }

      if(new_folder['product_category']) new_folder['product_category'] = parseInt(new_folder['product_category']);

      this.VERBOSE && console.log('file.service::addFolder called with: ', new_folder);

      await this.aviaService.createFolder(this.location_str, org_id ? org_id : this.location_id, new_folder);
      await this.getAllFiles();
      //this.sortFiles(this.filter_data);
    } catch(err) {
      console.error('addFile ERROR:', err);
      this.aviaService.notify('warning', 'Warning!', 'Folder not added.', { showConfirmButton: true, timer: null });
    }
  }

  async deleteFolder( folder_id: number, org_id: number = undefined ) {
    let delete_res = (await this.aviaService.deleteFolder(this.location_str, org_id ? org_id : this.location_id, folder_id)).body.result;
    this.VERBOSE && console.log('file.service::deleteFolder called with: ', folder_id, this.files, this.folders);
    await this.getAllFiles();
  }

  async editFolder(folder_id: number, data: object, org_id: number = undefined ) {
    //exit early and prevent creation of name is null or empty
    if(data["name"] === "" || data["name"]  === null) {
      return "";
    }

    //Remove preceding and trailing whitespace and check for empty
    if(data["name"]) {
      data["name"] = Common.removePrecedingAndTrailingWhiteSpace(data["name"]);
    }

    await this.aviaService.updateFolder(this.location_str, org_id ? org_id : this.location_id, folder_id, data);
    this.editing_folder_id = undefined;

    await this.getAllFiles();
    //this.sortFiles(this.filter_data);
  }

  // Workspace Files
  async getWorkspaceFiles() {
    // If we one day decide to remove an org's files the moment they are removed as a participant
    // Clear out the workspace files before re-assembling them here
    let [orgs, users] = await Promise.all([
      this.aviaService.getChannelOrgs(this.location_id),
      this.aviaService.getChannelUsers(this.location_id)
    ]);

    this.ch_orgs = orgs.body;
    this.ch_users = users.body.map(u => u.org_obj);
    this.ch_orgs = lodash_orderBy(lodash_uniqBy([].concat.apply(this.ch_orgs, this.ch_users), 'id'), ['name'], ['asc']);

    let promises = [];
    this.ch_orgs.forEach(org => {
      promises.push(this.aviaService.getFiles(`channel/${this.location_id}/org`, org.id, {order: 'name', direction: 0}));
    })

    let files = await Promise.all(promises), folders = [], all_files = [];

    return this.aviaService.getSessionSupport().then(session => {
      for (let i = 0; i < files.length; i++) {
        this.keychain_ch_files[files[i].body.rd.org] = this.aviaService.hasAccess(session, 'ws', 'files', 'crwd', files[i].body.rd);
        folders[i] = files[i].body.result.folders;
        all_files[i] = files[i].body.result.all_files;
        files[i] = files[i].body.result.files;  //de-promisify
        files[i].forEach(file => file.org = this.ch_orgs[i].id); // attach the appropriate org id to the file
        folders[i].forEach(folder => folder.org = this.ch_orgs[i].id);
      }
      this.all_files = [].concat.apply([], all_files);
      this.files = [].concat.apply([], files); //flatten!
      this.folders = [].concat.apply([], folders);
      this.sortWorkspaceFiles();
    })
  };

  // Channel Files
  async getChannelFiles() {
    // If we one day decide to remove an org's files the moment they are removed as a participant
    // Clear out the workspace files before re-assembling them here
    let [orgs, users, act_files_p] = await Promise.all([
      this.aviaService.getChannelOrgs(this.location_id),
      this.aviaService.getChannelUsers(this.location_id),
      this.aviaService.getFiles('activity', this.act_id)
    ]);

    this.ch_orgs = orgs.body;
    this.ch_users = users.body.map(u => u.org_obj);
    this.ch_orgs = lodash_orderBy(lodash_uniqBy([].concat.apply(this.ch_orgs, this.ch_users), 'id'), ['name'], ['asc']);

    this.act_files = act_files_p.body.result.files;
    this.all_act_files = act_files_p.body.result.all_files;
    this.act_folders = act_files_p.body.result.folders;

    let promises = [];
    this.ch_orgs.forEach(org => {
      promises.push(this.aviaService.getFiles(`channel/${this.location_id}/org`, org.id, {order: 'name', direction: 0}));
    })

    let files = await Promise.all(promises), folders = [], all_files = [];

    return this.aviaService.getSessionSupport().then(session => {
      for (let i = 0; i < files.length; i++) {
        this.keychain_ch_files[files[i].body.rd.org] = this.aviaService.hasAccess(session, 'ws', 'files', 'crwd', files[i].body.rd);
        folders[i] = files[i].body.result.folders;
        all_files[i] = files[i].body.result.all_files;
        files[i] = files[i].body.result.files;  //de-promisify
        files[i].forEach(file => file.org = this.ch_orgs[i].id); // attach the appropriate org id to the file
        folders[i].forEach(folder => folder.org = this.ch_orgs[i].id);
      }
      this.all_files = [].concat.apply([], all_files);
      this.files = [].concat.apply([], files); //flatten!
      this.folders = [].concat.apply([], folders);
      this.sortWorkspaceFiles();
    })
  };

  sortWorkspaceFiles() {
    if (this.files === undefined || this.folders === undefined) return;
    let files_group = {}; // Make dictionary
    for(let org of this.ch_orgs) {  // Each org gets its own entry into dictionary
      files_group[org.id] = {
        files: [],  // files and folders properties instantiated with empty arrays
        folders: [],
        location_id: org.id,
        header_title: org.name + ' Files',
        name: org.name,
        show_add_folder: false,
        has_all_empty_folders: true
      }
    };
    for(let f of this.files) {    // Sort files by org
      files_group[f.org].files.push(f);
    }
    for(let folder of this.folders) {   // Sort folders by org
      files_group[folder.org].folders.push(folder);
      if(folder.files && folder.files.length > 0) {
        files_group[folder.org].has_all_empty_folders = false;
      }
      // Sort files in each folder in a folder group. Could scale poorly?
      for(let nest_folder of files_group[folder.org].folders) {
        nest_folder.files = lodash_sortBy(nest_folder.files, 'name');
      }
    }

    this.ch_file_groups = lodash_sortBy(files_group, 'name');
    this.VERBOSE && console.log(this.ch_file_groups);
  }


  sortFiles($event) {
    this.VERBOSE && console.log('File Service - sortFiles()', $event);
    this.filter_data = $event.data;
    let sort_type = $event.data.key;
    if (sort_type === 'user_obj') {
      this.files = lodash_orderBy(this.file_group[this.location_id], file => file[sort_type]['lastname'].toLowerCase());
    } else if (sort_type === 'created'){
      this.files = lodash_orderBy(this.file_group[this.location_id], sort_type, 'desc');
    } else if (sort_type === 'name') {
      this.files = lodash_orderBy(this.file_group[this.location_id], file => file[sort_type].toLowerCase());
    } else {
      this.files = lodash_orderBy(this.file_group[this.location_id], sort_type);
    }
    this.sortProductFiles();
  }

  async getFileCategories() {
    let prod_support = await this.aviaService.getProductSupport();

    let prod_file_categories = lodash_cloneDeep(prod_support.product_file_categories);   // This is necessary because it will change the value in memory -SK

    this.product_categories = Common.indexIt('id', prod_file_categories);

    for(let cat in this.product_categories) {
      this.product_categories[cat]['sanitized_name'] = Common.sanitizeKeyName(this.product_categories[cat]['name']);
    }
  }

  sortProductFiles(){
    for(let cat in this.product_categories) {
      this.file_group[cat] = {
        files: [],
        folders: [],
        show_add_folder: false
      };
    }

    for(let file of this.files) {
      if(file.product_category && this.file_group[file.product_category]) {
        this.file_group[file.product_category].files.push(file);
      }
    }

    for(let folder of this.folders) {
      if(folder.product_category && this.file_group[folder.product_category]) {
        this.file_group[folder.product_category].folders.push(folder);
      }
    }
    this.VERBOSE && console.log('File Service - sortProductFiles() ', this.file_group);
  }

  determineWhichAccessKey() {
    switch(this.current_location) {
      case 'Activities':
        this.akey = this.keychain_files.act_files;
      break;
      case 'Channels':
      case 'Workspaces':
        this.ws_akey = this.keychain_files.act_files;
        this.akey = this.keychain_files.ws_files;
      break;
      case 'Inventory':
        this.akey = this.keychain_files.inventory_files;
      break;
      case 'Products':
        this.akey = this.keychain_files.product_files;
      break;
      case 'Priorities':
        this.akey = this.keychain_files.priority_files;
      break;
      case 'Companies':
        this.akey = this.keychain_files.solco_files;
      break;
      case 'Dashboard_AVIA':
      case 'Dashboard_Hs':
      case 'Dashboard_Sc':
        this.akey = this.keychain_files.dashboard_files;
      break;
      default:
      break;
    }

  }

  openFile(file_id) {
    let combined_files = this.all_files.concat(this.all_act_files);
    combined_files.forEach(file => {
      if (file.id == file_id) {
        let obj = new AnalyticEvent(
          'file_open',
          {file_id: file_id}
        );
        this.aviaService.createAnalyticEvent(obj);
        this.aviaService.openContentViewer( file.link, file.name, file.file_name, file.id, false );
      }
    })
  }

  async openKmFile(file_id, card_id) {
    let [_,  km_card_res] = await Promise.all([
      this.initUserAccess(),
      this.aviaService.getKMcard(card_id)
    ]);

    const group_id = _get(km_card_res, 'body.content', -1);
    const q_obj = {
      group: group_id,
      direction: 1,
      order: 'likes_count',
      km_newsfeed: 1,
    };
    const avia_insights_res = await this.aviaService.searchContentPromise({
      ...q_obj,
      produced_by: 1,
    });

    const avia_insights = avia_insights_res.body;

    let combined_files = [...avia_insights]; // incase there are more files....
    combined_files.forEach(file => {
      if (file.id == file_id) {
        let obj = new AnalyticEvent(
          'file_open',
          {file_id: file_id}
        );
        this.aviaService.createAnalyticEvent(obj);
        this.aviaService.openContentViewer( file.link, file.name, file.file_name, file.id, false );
      }
    })
  }

  toggleAddFolder() {
    this.show_add_folder = !this.show_add_folder;
  }

  async initUserAccess(resource_desc: Object = {}) {
    let session = await this.aviaService.getSessionSupport();
    this.keychain_files.act_files       = this.aviaService.hasAccess(session, 'act', 'files',           'crwd', resource_desc);
    this.keychain_files.avia_insights   = this.aviaService.hasAccess(session, 'km', 'avia_insights', 'e', resource_desc);
    this.keychain_files.dashboard_files = this.aviaService.hasAccess(session, 'org', 'dashboard_files', 'crwd', resource_desc);
    this.keychain_files.inventory_files = this.aviaService.hasAccess(session, 'inv', 'core',            'crwd', resource_desc);
    this.keychain_files.priority_files  = this.aviaService.hasAccess(session, 'org', 'priorities',      'crwd', resource_desc);
    this.keychain_files.product_files   = this.aviaService.hasAccess(session, 'np',  'solco_files',     'crwd', resource_desc);
    this.keychain_files.solco_files     = this.aviaService.hasAccess(session, 'np',  'solco_files',     'crwd', resource_desc);
    this.keychain_files.ws_files        = this.aviaService.hasAccess(session, 'ws',  'files',           'crwd', resource_desc);

    this.my_org = session.org;
    this.VERBOSE && console.log('File Service: initUserAcces():');
    for (let item of Object.keys(this.keychain_files)) {
      this.VERBOSE && console.log('Access Key: %s - C:%s, R:%s, W:%s, D:%s, E:%s', item, this.keychain_files[item].c, this.keychain_files[item].r, this.keychain_files[item].w, this.keychain_files[item].d, this.keychain_files[item].e);
    }
    this.determineWhichAccessKey();
  }

}


export class File_Filter_Obj {
  constructor(
    public active: boolean = false,
    public key:    string,
    public name:   string,
    public show:   boolean = false,
    public value:  any,
  ){ }
}
