<div class="h-100" *ngIf="false">
	<div class="container-fluid newsfeed-container pt-4 pb-0 px-4">
		<div class="row">
			<div class="col-sm-12 col-md-8">
				<app-avia-banner
					*ngIf="!loading && banner.id"
					[banner]="banner"
				></app-avia-banner>
				<app-avia-action-tiles class="pb-4"></app-avia-action-tiles>
				<app-feed
					*ngIf="feed_config"
					[config]="feed_config">
				</app-feed>
			</div>

			<!-- SIDEBAR -->
			<div class="d-none d-md-block col-4 pl-md-0">
				<div class="px-2" *ngIf='quicklinks'>
					<!-- <div class="location-container w-100">
						<div class="hero-image">
							<div class="p-4 pt-7 hero-text">
								<div class="text-wrap">We've changed quick links to pinned locations, to make it easier for you save your favorite pages in AVIA Connect. See "My Pinned Locations" under "My Connect".</div>
							</div>
						</div>
					</div> -->
					<app-avia-quicklinks [links]="quicklinks" (linksChanged)='linksUpdated()'></app-avia-quicklinks>
					<div class="pt-4">
						<app-suggested-topics [suggested_topics]="suggested_topics"></app-suggested-topics>
					</div>
					<div class="pt-4" *ngIf="session?.org?.type != 2 && session?.org?.type != 5">
						<app-suggested-connections></app-suggested-connections>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
