import { Injectable } from '@angular/core';

let marked = require('marked/lib/marked');

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
});

// Please see MediumEditorDirective (_components/medium.editor-directive.ts) for the JS medium editor implementation
export class MediumEditorHelper {
  // only to be used by medium editor - needs access to this   (use getHTML() instead!)
  htmlModel: string = null;
  markdown: string = null;
  VERBOSE: boolean = false;

  constructor() { }

  // This must be here to consistently update the markdown. onMeMarkdownUpdate will not work after subsequent edits
  // It needs to be reinstantiated every time.
  getEditorOptions(overrides) {
    return {
      extensions: {
        markdown: new MeMarkdown(md => this.onMeMarkdownUpdate( md ))
      },
      toolbar: {
        static: true,
        sticky: true,
        align: 'center',
        updateOnEmptySelection: true,
        // from here: https://github.com/yabwe/medium-editor/blob/master/src/js/defaults/buttons.js
        buttons: [
          {
            name: 'bold',
            action: 'bold',
            aria: 'bold',
            tagNames: ['b', 'strong'],
            style: {
              prop: 'font-weight',
              value: 'bolder|400|700|bold'
            },
            useQueryState: true,
            contentDefault: '<b>B</b>',
            contentFA: '<i class="fa fa-bold"></i>'
          },
          'italic',
          'underline',
          'orderedlist',
          'unorderedlist',
          {
            name: 'h1',
            action: 'append-h1',
            aria: 'header type one',
            tagNames: ['h1'],
            contentDefault: '<b>H1</b>',
            contentFA: '<i class="fa fa-header"><sup>1</sup>'
          },
          {
            name: 'anchor',
            contentDefault: 'Link'
          }
        ],
      },
      placeholder: {
          text: overrides.placeholder ? overrides.placeholder : 'Enter Text',
          hideOnClick: true
      },
      targetBlank: true
    };
  }

  getMarkdown() {
    this.VERBOSE && console.info( "getMd" + this.markdown );
    return this.markdown;
  }

  onMeMarkdownUpdate(md) {
    this.VERBOSE && console.info( "onMeMarkdownUpdate: " + md );
    this.markdown = md || '';
    // dont update htmlModel here, since it's already updated by medium (prevent ExpressionChangedAfterItHasBeenCheckedError)
  }

  ngOnDestroy() {
    this.markdown = undefined;
    this.htmlModel = undefined;
  }

  setMarkdown(md) {
    //console.log( `setMarkdown: ${md}` )
    this.VERBOSE && console.info( "setMd md" + md );
    this.markdown = md || '';

    let h = MediumEditorHelper.convertMarkdownToHTML(this.markdown);
    if (this.htmlModel !== h) // prevent triggering angular lifecycle changes if we dont have to
      this.htmlModel = h;
    this.VERBOSE && console.info( "setMd html" + this.htmlModel );
  }

  getHTML() {
    this.VERBOSE && console.info( "getHTML" + this.markdown );
    //console.log( `getHTML: ${this.htmlModel}` )
    return this.htmlModel;// MediumEditorHelper.convertMarkdownToHTML(this.markdown);
  }

  static convertMarkdownToText(md: string ) {
    if(!md) {
      return undefined;
    }
    //convert markdown to html
    let html = MediumEditorHelper.convertMarkdownToHTML(md);
    //convert html to text
    let e = document.createElement('div');
    e.innerHTML = html;
    return e.innerText ? e.innerText : e.textContent;
  }

  static convertMarkdownToHTML(md: string) {
    //console.log( "  - convertMarkdownToHTML" + md );
    // underlines are not valid markdown.  (there is _, * for <em> and __, ** for <strong>, but no underline)
    // Medium Editor achieves underlines by injecting HTML directly.  great.
    // but then marked will replace those with &lt;u&gt; and &lt;/u&gt;

    // hide the underlines from conversion...
    md = md.replace( /<u>([^]*?)<\/u>/g, "&AVIASTARTUNDERLINE;$1&AVIAENDUNDERLINE;" );
    md = md.replace( /<u style.*?bold[^>]*>([^]*?)<\/u>/g, "&AVIASTARTUNDERLINE;**$1**&AVIAENDUNDERLINE;" );
    md = md.replace( /<u style[^>]+>([^]*?)<\/u>/g, "&AVIASTARTUNDERLINE;$1&AVIAENDUNDERLINE;" );

    // MeMarkdownUpdate adds these in.  we dont want/need them in our html (kill them if any remain in the db).
    md = md.replace( /<span[^>]*>([^]*?)<\/span>/g, "$1" );

    //console.log( "  - convertMarkdownToHTML1" + md );
    let result = marked(md);
    //console.log( "  - convertMarkdownToHTML2:" + result );

    // restore the underlines...
    result = result.replace( /&AVIASTARTUNDERLINE;([^]*?)&AVIAENDUNDERLINE;/g, "<u>$1</u>" );

    // fix up links to open in new tab
    result = result.replace( /(<a href="[^"]+")(>)/g, "$1 target=\"_blank\"$2" );

    //console.log( "  - convertMarkdownToHTML3: " + result );

    return result;
  }
}
