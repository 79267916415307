import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AVIAConnectService } from '../../avia-connect.service';
import { Common } from './../../common';
import { Content_Source, Session } from '../../../class';

@Component({
  selector: 'app-add-content-logo-modal',
  templateUrl: './add-content-logo-modal.component.html',
  styleUrls: ['./add-content-logo-modal.component.scss']
})
export class AddContentLogoModalComponent implements OnInit {

  readonly VERBOSE = false;

  data: Content_Source = new Content_Source();

  tabs: any; // angular8: added to fix error when building prod: "Property does not exist"
  fc_fname: any; // angular8: added to fix error when building prod: "Property does not exist"

  @Input() //fullscreen
    get fullscreen():      boolean { return this._fullscreen }
    set fullscreen( value: boolean ) { this._fullscreen = value; }
    _fullscreen:           boolean = false;
  @Input() //hide_activate_btn
    get hide_activate_btn():      boolean { return this._hide_activate_btn; }
    set hide_activate_btn( value: boolean ) { this._hide_activate_btn = value; }
    _hide_activate_btn:           boolean = false;

  @Input() title_text:     string = 'Add a New Content Logo';

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save:   EventEmitter<any> = new EventEmitter();

  session: Session = new Session;

  // FILE
  error:          string;
  file:           any;
  file_uploaded:  boolean = false;
  progress:       number = 0.0;
  upload_started: boolean = false;
  current_upload_abort: () => any = undefined;

  // FORM
  fc_logo_link: AbstractControl;
  fc_source:    AbstractControl;
  file_form:    FormGroup;

  // MODAL
  @ViewChild('LogoAdd', { static: true }) logo_modal: NgbModal;
  modal_ref:                     NgbModalRef;

  constructor( private aviaService: AVIAConnectService, private fb: FormBuilder, private modalService: NgbModal ) {
    this.createForm();
  };

  // INIT
  async ngOnInit() {
    this.session = await this.aviaService.getSessionSupport();
  };

  createForm(): void {
    this.file_form = this.fb.group({
      logo_link:   ['', Validators.required],
      source:      '',
      created_by:  ''
    });

    this.fc_logo_link = this.file_form.get('logo_link');
    this.fc_source = this.file_form.get('source');
  };

  setupFormData(): void {
    let fields = {
      source:      this.data['source']    ? this.data['source']    : '',
      created_by:  this.data['created_by']      ? this.data['created_by']      : this.session['user']['id'] !== null ? this.session['user']['id'] : 0,
      logo_link:   this.data['logo_link'] ? this.data['logo_link'] : ''
    };

    this.file_form.reset( fields );
  };

  // NOTE: You can either set the Inputs 'data' and 'data_fallback' first, then call this method
  // OR, simply supply them with the calling of this method
  async open(data = this.data) {
    let options: NgbModalOptions = { 'backdrop': 'static', size: 'lg', windowClass: 'avia-modal-fullscreen' };

    this.modal_ref = this.modalService.open(this.logo_modal, options);
    this.setupFormData();
  };

  // SUBMIT
  onSubmit(): void {
    this.file_form.patchValue({ 'logo_link': Common.safeHttp(this.file_form.value.logo_link) });
    this.VERBOSE && console.log('AviaFileUploadComponent::onSubmit called with: ', this.file_form.value);
    this.save.emit( this.file_form.value );
    this.resetForm();
    this.modal_ref.close();
  };

  // FILE UPLOAD
  dropFile($event) {
    this.uploadfile($event.file);
  };

  fileChosenFromBrowse($event) {
    this.uploadfile($event.target.files[0]);
  };

  private async uploadfile(file) {
    this.file = file;
    this.file_form.patchValue({ 'source': this.file.source });

    if (this.file == undefined) { this.error = "choose a file"; return; }

    const s3filename = `${this.file.name}`;

    this.upload_started = true;

    const s3_upload = await this.aviaService.uploadToS3(
      s3filename,
      this.file.name,
      this.file,
      this.file.type,//'application/octet-stream',
      'content-logo',
      (s, p, err, abort) => {
        this.progress = (p / s);
        this.error = err;
        this.current_upload_abort = abort;
      });
    if (s3_upload && s3_upload.message == "success" && s3_upload.url && !!this.file) {
      this.file_uploaded = true;
      this.upload_started = false;
      this.file.link = s3_upload.url;
      this.file.s3_filename = s3_upload.file_name;
      this.file_form.patchValue({ 'logo_link': s3_upload.url });
    } else {
      this.error = "upload failed: " + s3_upload.info;
    }
  };

  // DESTROY
  async cancelAndClose() {
    if(this.file && this.file.link) {
      await this.aviaService.undoUploadToS3(Common.justFilename(this.file.link));
    }
    this.revert();
    this.cancel.emit();
    this.modal_ref.close();
  };

  async revert() {
    if(this.upload_started && !this.file_uploaded && this.current_upload_abort) {  // If we are in the middle of uploading a file (the xhr didn't finish on uploadToS3), abort
      this.current_upload_abort();
    }

    if(this.file && this.file.link) {
      if(this.progress) {
        await this.aviaService.undoUploadToS3(Common.justFilename(this.file.link));
      }
    }
    this.resetForm();
  };

  resetForm() {
    delete this.file;
    delete this.progress;
    this.file_uploaded = false;
    this.upload_started = false;
    this.setupFormData();
  }

  ngOnDestroy() {
    this.resetForm();
  }

}
