import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { AVIAConnectService } from './avia-connect.service';
import { Common } from './common';
import { AnalyticEvent } from '../class';


@Injectable()
export class AuthGuardService implements CanActivate {
  readonly VERBOSE: boolean = false;

  currentUrl: string; // available for anyone who wants to know the previous URL, they can ngOnInit() { this.previousUrl = _.clone( this.authGuard.previousUrl ); }
  previousUrl: string; // available for anyone who wants to know the previous URL, they can ngOnInit() { this.previousUrl = _.clone( this.authGuard.previousUrl ); }
  previousParams: Object; // available for anyone who wants to know the previous query params, they can ngOnInit() { this.previousUrl = _.clone( this.authGuard.previousParams ); }

  constructor( private aviaService: AVIAConnectService, private router: Router) {
    router.events
    .pipe( filter(event => event instanceof NavigationEnd) )
    .subscribe(e => {
      this.previousUrl = this.currentUrl;
      this.VERBOSE && console.log('prev:', this.previousUrl, e);
      this.VERBOSE && console.log(Common.queryStringToJSON());
      this.currentUrl = e['urlAfterRedirects'];
      this.previousParams = Common.queryStringToJSON();

      // ROUTE CHANGE LOGGING / ANALYTICS
      let obj = new AnalyticEvent(
        'route_change',
        {previous: this.previousUrl, current: this.currentUrl}
      );
      this.aviaService.createAnalyticEvent(obj);
      this.aviaService.newrelicStartInteraction();
      this.aviaService.newrelicSetURL( this.currentUrl );
      this.aviaService.universal_add_active = false;
      this.VERBOSE && console.log( 'AUTH GUARD SERVICE, CHANGE DETECTED:\n- PREVIOUS URL: ' + this.previousUrl + '\n- CURRENT URL: ' + this.currentUrl );
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.aviaService.currentRouteParams = route?.params;

    let url:          string  = state.url;
    let is_logged_in: boolean = await this.checkLogin( url );
    let has_access:   boolean = false;
    let access_key;
    let session;

    // category is a category name (see hasAccess(session, cat, res, op))
    let category = route.data && route.data.category ? route.data.category : '';
    let resource = route.data && route.data.resource ? route.data.resource : 'feature';
    let rd_path = route.data && route.data.rd_path ? route.data.rd_path : [];
    let op = route.data && route.data.op ? route.data.op : 'e';
    let rd = {};

    let wait = await this.aviaService.rerouteByLegacyRoute(route, state);

    if(
      url.includes('/ws') &&
      !url.includes('/channel') &&
      route.params &&
      route.params.id &&
      route.queryParams &&
      route.queryParams.openFile
    ) {
      await this.openWorkspaceFile(route)
    }

    if(
      url.includes('/ws') &&
      !url.includes('/channel') &&
      route.params &&
      route.params.id
    ) {
      let access_res = await this.aviaService.getGroupUserAccess(route.params.id)
      if(access_res && access_res.status === 200 && access_res.body) {
        if(access_res.body.user_access === 2 || access_res.body.org_access === 2 || access_res.body.avia_access) {
          let ch_id = this.getAccessId(access_res.body)
          this.router.navigate(['ws', 'channel', ch_id], { queryParams: {activeTab: 'Discussion'}})
        }
      }
    }

    if(
      url.includes('/intelligence') &&
      url.includes('/cl') &&
      url.includes('/search') &&
      route.queryParams &&
      route.queryParams.openFile
    ) {
      let id = parseInt( route.queryParams.openFile )
      let content_res = await this.aviaService.getContentById(id)

      if(content_res && content_res.status === 200 && content_res.body && content_res.body.length > 0 && content_res.body[0].permitted === 1) {
        let file = content_res.body[0]
        this.aviaService.openContentViewer(
          file.link,
          file.name,
          file.name,
          file.id,
          false,
          false
        )
      }
    }

    if(
      url.includes('/hs') &&
      url.includes('/inventory') &&
      route.params &&
      route.params.id &&
      route.params.inv_id
    ){
      let inv_item = await this.aviaService.getInventoryItem(route.params.inv_id)
      if(!(inv_item.status == 200)){
        this.router.navigate(['/start']);
      }
    }

    if( category !== '' && is_logged_in ) {
      session = await this.aviaService.getSessionSupport();
      let id = route.params.id ? route.params.id : route.queryParams.id ? route.queryParams.id : -1;

      if (
        (category === 'org' && resource === 'dashboard_feature' && session.org.id == id) ||
        (category === 'np'  && resource === 'solco_products'    && session.org.id == id) ||
        // (category === 'pulse'  && resource === 'configuration'    && session.org.id == id) ||
        (category === 'org' && resource === 'pulse'                                    )
      ) {
        rd = Common.objSafeRead(session, rd_path, {});
      }

      access_key = this.aviaService.hasAccess( session, category, resource, op, rd );
      this.VERBOSE && console.log( 'Expected role: ' + category );
      this.VERBOSE && console.log( 'Access Key: ', access_key );
      for (const key in access_key) {
        let op = access_key[key];
        if (op) {
          has_access = true;
          break;
        }
      }

      if (!has_access) {
        this.aviaService.newrelicSetAttribute( 'user', this.aviaService.user );
        this.aviaService.newrelicSetAttribute( 'no access', "true" );
        this.aviaService.newrelicEndInteraction();

        let reroute_url = ['/start'];
        // override_reroute_segments should be defined as an array containing path you want to reroute to
        // e.g. ['sc'] to reroute to /sc/:id for /sc/:id/products/:product_id
        // or ['dashboard', 'hs'] to reroute to /dashboard/hs/:id for /dashboard/hs/:id/priority/:priority_id
        // becasue this is passed in as route data you can't manually pass in :id
        if (route.data && route.data.override_reroute_segments) {
          reroute_url = [...route.data.override_reroute_segments]
          if (route.params.id) reroute_url.push(route.params.id)
        }
        this.VERBOSE && console.log( 'No access. Rerouting to /start' );
        this.router.navigate(reroute_url);
      }
      return has_access && is_logged_in;
    } else {
      return is_logged_in;
    }
  }

  getAccessId(res) {
    let access_id
    if(res.overview_ch.cu_status === 2 || res.avia_access){
      access_id = res.overview_ch.id
    } else if(res.user_access === 2) {
      access_id = res.group_res.find(ch => ch.cu_status === 2).id
    } else if(res.org_access === 2) {
      access_id = res.group_res.find(ch => ch.co_org_id != null).id
    }
    return access_id
  }

  async openWorkspaceFile(route: ActivatedRouteSnapshot) {
    let act_ch_p = await this.aviaService.getActivityChannels(route.params.id, {show_requestable: 1, is_overview: 1});
    let channels = act_ch_p.body;
    let overview = channels[0];
    this.router.navigate(["/ws/channel/", overview.id], { queryParams: route.queryParams } )

    return false;
  }


  async canLoad(route: Route): Promise<boolean> {
    let url = `/${route.path}`;
    return await this.checkLogin(url);
  }

  async checkLogin(url: string): Promise<boolean> {
    if ( this.aviaService.isLoggedIn() ) {
      if(this.aviaService.deviceInfo.browser === 'ie') {
        this.aviaService.browserProblem = true;
        this.aviaService.browserProblemLock = true;
        this.aviaService.browserProblemSevere = true;
        this.aviaService.browser_nag_dismissed = false;
      }
      localStorage.removeItem("redirectUrl");
      localStorage.removeItem("redirectUrlParams");
      await this.aviaService.checkEula();
      await this.aviaService.checkOnboarding();
      return true;
    }

    // Store the attempted URL for redirecting
    try {
      if(window.location.pathname.indexOf('/signin') !== 0) {
        this.aviaService.redirectUrl = window.location.pathname;
        localStorage["redirectUrl"] = this.aviaService.redirectUrl;
      }
    } catch (ex) {
      this.aviaService.redirectUrl = '/start';
    }

    if(location.search && location.search.length > 0) {
      try {
        let search = location.search.substring(1);
        this.aviaService.redirectUrlParams =  JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
        localStorage["redirectUrlParams"] = JSON.stringify(this.aviaService.redirectUrlParams);
      } catch (ex) {
        this.aviaService.redirectUrlParams = {};
      }
    }

    // Navigate to the login page
    if(this.aviaService.redirectUrlParams["sso"] != null) {
      location.href = `${this.aviaService.baseUrl}/sso/${this.aviaService.redirectUrlParams["sso"]}/callback`;
    } else {
      this.router.navigate(['/signin']);
    }

    return false;
  }

}
