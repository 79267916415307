<div *ngFor="let barLength of totalRows">
  <div class="avia-loader">
  <!-- Generic -->
      <div *ngIf="options.type === 'generic'">
        <div class="generic-load">
        </div>
      </div>
  <!-- TEXT -->
      <!-- <div *ngIf="options.type === 'text'">
        <div class="card animated-backg round p-4 mb-4">
          <div class="background-masker header-top"></div>
            <div class="background-masker header-left"></div>
            <div class="background-masker header-right"></div>
            <div class="background-masker header-bottom"></div>
            <div class="background-masker subheader-left"></div>
            <div class="background-masker subheader-right"></div>
            <div class="background-masker subheader-bottom"></div>
            <div class="background-masker content-top"></div>
            <div class="background-masker content-first-end"></div>
            <div class="background-masker content-second-line"></div>
            <div class="background-masker content-second-end"></div>
            <div class="background-masker content-third-line"></div>
            <div class="background-masker content-third-end"></div>
          </div>
      </div> -->
  <!-- IMAGE -->
      <div *ngIf="options.type === 'img'" [ngSwitch]="options.size">
        <div class="ph-item">
          <div class="ph-col-12">
            <div class="ph-picture"></div>
          </div>
        </div>
      </div>
  <!-- ROW -->
      <div *ngIf="options.type === 'row'">
        <div class="avia-row d-inline-flex w-100">
          <div class="side-strip bg-grey-light"></div>
          <div class="ph-row-item">
            <div class="ph-col-12"><div class="ph-row-row"><div [class]="barLength"></div></div></div>
          </div>
        </div>
      </div>
    <!-- LINE -->
    <div *ngIf="options.type === 'line'">
      <div class="ph-line-item">
        <div class="ph-col-12"><div class="ph-row"><div class="ph-col-12"></div></div></div>
      </div>
    </div>
  <!-- CARD -->
    <div *ngIf="options.type === 'card'">
      <div class="card">
        <div class="card-body">
          <div class="ph-card-item">
            <div class="ph-col-12">
              <div class="ph-card-row">
                <div class="ph-col-6 big"></div><div class="ph-col-6 empty big"></div>
                <div class="ph-col-9"></div> <div class="ph-col-3 empty"></div>
                <div class="ph-col-11"></div><div class="ph-col-1 empty"></div>
                <div class="ph-col-10"></div><div class="ph-col-2 empty"></div>
                <div class="ph-col-8"></div><div class="ph-col-4 empty"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CARD W/LOGO -->
    <div *ngIf="options.type === 'cardwlogo'">
      <div class="card">
        <div class="card-body">
          <div class="ph-card-item">
            <div class="ph-col-12">
              <div class="ph-picture"> </div>
              <div class="text-gray-300 load-text-height blokk">
                .. ...... ... ....... ....... ......
                ... ........ ..... .. ........ ........
                ..... ... ....... ... ....... ... .....

            </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!--  -->
  <div *ngIf="options.type === 'paragraph'"
    class="text-gray-300 load-text-height blokk"
  >
      .. ...... ... ....... ....... ......
      ... ........ ..... .. ........ ........
      ..... ... ....... ... ....... ... .....
      .. .. ...... ........... ....... ........
      ....... ... ....... ... .... ...... ....
      ...... ........ ........ ........ ... ..
  </div>
    <!-- END LOADERS -->
  </div>
</div>



