<div class="row justify-content-center pt-4">
	<div class="col-12 col-md-8 mt-4">
		<div class="heading-size-36 pb-4 text-center">Keys To Being Discovered</div>
		<div class="text-center font-weight-light m-auto col-12 col-lg-6 font-size-16 font-body">The badges below help validate your company and products on AVIA Connect. They also improve your search ranking.</div>
		<div class="py-6 text-center">
			<div class="row">
				<div class="col-md-3 col-6">
					<img class="lock-icon position-absolute" [src]="'/assets/badges/lock.svg'"/>
					<img class="badge-icon" [src]="'/assets/badges/badge-active.svg'"/>
					<div class="heading-size-14">Active</div>
					<div class="font-size-14 font-weight-light">You have populated your company and product profiles.</div>
				</div>
				<div class="col-md-3 col-6">
					<img class="lock-icon position-absolute" [src]="'/assets/badges/lock.svg'"/>
					<img class="badge-icon" [src]="'/assets/badges/badge-market-val.svg'"/>
					<div class="heading-size-14">Market Validated</div>
					<div class="font-size-14 font-weight-light">Your product has been implemented and you have provided positive references.</div>
				</div>
				<div class="col-md-3 col-6">
					<img class="lock-icon position-absolute" [src]="'/assets/badges/lock.svg'"/>
					<img class="badge-icon" [src]="'/assets/badges/badge-avia-vetted.svg'"/>
					<div class="heading-size-14">AVIA Vetted</div>
					<div class="font-size-14 font-weight-light">Your company and product have been vetted by AVIA and meets the needs of members.</div>
				</div>
				<div class="col-md-3 col-6">
					<img class="badge-icon" [src]="'/assets/badges/badge-empty-gray.svg'"/>
					<div class="heading-size-14">More to come</div>
				</div>
				<div class="col-12 mt-4">
					<div class="w-50 mx-auto font-size-16 font-weight-light">
						By populating your profile, your company will receive its first badge and will become an <span class="font-weight-bold">Active Profile</span>.
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
